import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppResolverErrorHandlerService } from '../services/resolver-error-handler.service';
import { StorageManager } from '../storage/storage.manager';

export class BaseResolver<T> {
  /**
   * The default error message that gets display when handleResolve fails
   */
  public errorMessage: string;

  /**
   * A reference to the StorageManager that all resolvers can access.
   */
  public StorageManager = StorageManager;

  /**
   * Default Constructor
   */
  constructor(public resolverErrorHandler: AppResolverErrorHandlerService) {
    this.errorMessage = 'There was an error getting data for that page!';
  }

  /**
   * Overrideable method. Use this to resolve whatever data you need in extended component.
   */
  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<T> {
    return null;
  }

  /**
   * Resolves data and handles whatever errors may happen
   */
  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<T | void> {
    return new Promise((resolve, reject) =>
      this.resolverErrorHandler.ngZone.runOutsideAngular(async () => {
        try {
          const results = await this.handleResolve(route, state);
          resolve(results);
        } catch (error) {
          reject(error);
          await this.resolverErrorHandler.handleError(error, this.errorMessage);
        }
      })
    );
  }
}
