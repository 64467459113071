import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { SchoolWideReportFiltersDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-schoolwide-report-actions',
  templateUrl: './schoolwide-report-actions.template.html',
  styleUrls: ['./schoolwide-report-actions.scss']
})
export class AppSchoolwideReportActions extends BaseComponent {
  @Input()
  public filters: SchoolWideReportFiltersDto;
  @Output()
  private filtersUpdated: EventEmitter<SchoolWideReportFiltersDto> =
    new EventEmitter<SchoolWideReportFiltersDto>();

  public constructor() {
    super();
  }

  public onFilterUpdated(field: string, data?: string | boolean) {
    this.filters[field] = data;
    this.filtersUpdated.emit(this.filters);
  }

  public searchFilterUpdated(data: string | undefined) {
    this.onFilterUpdated('text', data);
  }
}
