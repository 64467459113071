import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  PrivilegeEnum,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { Router } from '@angular/router';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { MenuAction } from '../../../../shared/menu/menu-action.interface';
import { commonViewStudentProfile } from '../../../../shared/student-card/common-student-card.helpers';
import { AppPrivilegesService } from '../../../auth/privileges.service';

@Component({
  selector: 'app-teacher-rosters-list-card',
  templateUrl: './teacher-rosters-list-card.template.html',
  styleUrls: ['./teacher-rosters-list-card.scss']
})
export class AppTeacherRostersListCard
  extends BaseTableComponent<StudentPerformanceDto>
  implements DoCheck, OnInit
{
  @Input() students: StudentPerformanceDto[];
  @Input() selectedStudentsIds: number[];
  @Output() selectStudent = new EventEmitter<number>();
  @Output() showPasswordResetModalEvent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() setSortFieldEvent: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() setSortDirectionEvent: EventEmitter<string> =
    new EventEmitter<string>();

  public bulkActionButtons: MenuAction[] = [];
  public sortActionButtons: MenuAction[] = [];
  public sortField: 'SORT_FIRST_NAME' | 'SORT_LAST_NAME' | 'SORT_EXT_ID';
  public sortDirection: 'Ascending' | 'Descending';

  constructor(
    public router: Router,
    public toastService: AppToastManagerService,
    privilegesService: AppPrivilegesService
  ) {
    super({ privilegesService });
  }

  ngOnInit() {
    this.sortDirection = 'Ascending';
    this.sortField = 'SORT_LAST_NAME';
  }

  ngDoCheck(): void {
    this.bulkActionButtons = [
      {
        key: 'RESET_PASSWORDS',
        title: `Reset Passwords (${this.selectedStudentsIds.length})`,
        hide:
          !this.hasPrivilege(PrivilegeEnum.RESET_STUDENT_PASSWORDS) ||
          !this.selectedStudentsIds?.length,
        disabled: !this.selectedStudentsIds?.length,
        onClick: () => this.showPasswordResetModalEvent.emit()
      }
    ];
    this.sortActionButtons = [
      {
        key: 'SORT_FIRST_NAME',
        title: 'First Name',
        onClick: () => this.setSortFieldEvent.emit('firstName')
      },
      {
        key: 'SORT_LAST_NAME',
        title: 'Last Name',
        onClick: () => this.setSortFieldEvent.emit('lastName')
      },
      {
        key: 'SORT_EXT_ID',
        title: 'Student ID',
        onClick: () => this.setSortFieldEvent.emit('studentExtId')
      }
    ];
  }

  viewStudentProfile(studentId: number): void {
    commonViewStudentProfile(this, studentId);
  }

  onSortDirectionClicked(): void {
    if (this.sortDirection === 'Ascending') {
      this.sortDirection = 'Descending';
    } else {
      this.sortDirection = 'Ascending';
    }
    this.setSortDirectionEvent.emit(this.sortDirection);
  }
}
