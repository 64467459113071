import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  BaseCommonTableComponent,
  ITemplateContext
} from '../../../../shared/tables/common-table/base-common-table.component';
import {
  BehaviorCodeNotificationStatus,
  CommunicationsReportFiltersDto,
  CommunicationsReportResponseDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { AppReportsService } from '../../reports.service';
import { MatDialog } from '@angular/material/dialog';
import { AppViewCommunicationDialogComponent } from '../view-communication-dialog/view-communication-dialog.component';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import { ActivatedRoute } from '@angular/router';

export enum CommunicationsReportTableColumns {
  STUDENT_EXT_ID = 'Student ID',
  STUDENT_NAME = 'Student Name',
  BEHAVIOR_CODE = 'Behavior Code',
  STATUS = 'Status',
  INFRACTION_NUMBER = 'Infraction #',
  CREATED_DATE = 'Created On',
  ACTIONS = 'Actions'
}

interface IActionsColumnContext {
  row: CommunicationsReportResponseDto;
}

@Component({
  selector: 'app-communications-report-table',
  templateUrl: './communications-report-table.template.html',
  styleUrls: ['./communications-report-table.scss']
})
export class AppCommunicationsReportTable
  extends BaseCommonTableComponent<
    CommunicationsReportResponseDto,
    CommunicationsReportTableColumns
  >
  implements OnInit, AfterViewInit
{
  @ViewChild('actionsColumn')
  public actionsColumn: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  public filters = new CommunicationsReportFiltersDto();
  public schoolId: number | null;
  public behaviorCodes: IDisplayData[];
  public columns;

  public constructor(
    public reportsService: AppReportsService,
    public route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    super();
    this.templates = {};
    this.columns = CommunicationsReportTableColumns;
  }

  public ngOnInit() {
    this.loadData();
  }

  public ngAfterViewInit(): void {
    if (this.actionsColumn) {
      this.templates['actionsColumn'] = this.actionsColumn;
    }
  }

  public loadData(): void {
    this.isLoading = true;
    this.schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    const data = this.route.snapshot.data.data;
    this.dataArr = data.report.results;
    this.behaviorCodes = data.behaviorCodeOptions;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.COMMUNICATIONS_REPORT
    );
    this.tableFilters.count = data.report.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    const today = new Date();
    const newFilters = new CommunicationsReportFiltersDto({
      schoolId: this.schoolId,
      startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      page: this.tableFilters?.page,
      itemsPerPage: this.tableFilters?.itemsPerPage
    });
    this.filters.mapFields(newFilters);
    // setup timer to refresh data once 5 seconds after the page is loaded
    setTimeout(() => {
      this.refreshEntries();
    }, 5000);
    this.isLoading = false;
  }

  public onFiltersUpdated(filters: CommunicationsReportFiltersDto) {
    this.filters = filters;
  }

  public async refreshEntries() {
    try {
      const entriesToRefresh = this.dataArr.filter(
        (entry) => entry.status === BehaviorCodeNotificationStatus.PENDING
      );
      if (!entriesToRefresh.length) {
        return;
      }
      this.isLoading = true;
      const entryIds = entriesToRefresh.map((entry) => entry.id);
      const freshEntries =
        await this.reportsService.refreshCommunicationsReport(
          this.schoolId,
          entryIds
        );
      freshEntries.forEach((entryUpdate) => {
        const index = this.dataArr.findIndex(
          (oldEntry) => oldEntry.id === entryUpdate.id
        );
        this.dataArr[index].status = entryUpdate.status;
      });
    } catch (error) {
      console.error('Error refreshing entries', error);
    } finally {
      this.isLoading = false;
    }
  }

  public async onInstantFiltersUpdated(
    filters: CommunicationsReportFiltersDto
  ) {
    this.onFiltersUpdated(filters);
    await this.applyFilters();
  }

  public async updateTableFilters(
    newTableFilters: CommunicationsReportFiltersDto & ITableFilters
  ): Promise<void> {
    this.filters.mapFields(newTableFilters);
    await this.applyFilters();
  }

  public async applyFilters(): Promise<void> {
    this.isLoading = true;
    const response = await this.reportsService.getCommunicationsReport(
      this.filters
    );
    this.tableFilters.count = response.options.totalItems;
    this.dataArr = response.results;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }

  public async viewEntry(entry: CommunicationsReportResponseDto) {
    try {
      this.isLoading = true;
      // get entry contents
      const [subject, body] =
        await this.reportsService.getNotificationEntryContent(entry);
      // open dialog to view entry
      this.dialog.open(AppViewCommunicationDialogComponent, {
        width: '800px',
        height: '600px',
        data: {
          subject: subject,
          body: body
        }
      });
    } catch (e) {
      console.error('Error viewing entry', e);
    } finally {
      this.isLoading = false;
    }
  }

  public getColumnValue(
    column: CommunicationsReportTableColumns,
    row: CommunicationsReportResponseDto
  ): any {
    switch (column) {
      case CommunicationsReportTableColumns.STUDENT_EXT_ID:
        return row.studentExtId;
      case CommunicationsReportTableColumns.STUDENT_NAME:
        return row.studentName;
      case CommunicationsReportTableColumns.BEHAVIOR_CODE:
        return row.behaviorCode;
      case CommunicationsReportTableColumns.STATUS:
        return row.status;
      case CommunicationsReportTableColumns.INFRACTION_NUMBER:
        return row.infractionNumber;
      case CommunicationsReportTableColumns.CREATED_DATE:
        const formatter = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        return formatter.format(new Date(row.createdDate));
      case CommunicationsReportTableColumns.ACTIONS:
        return {
          context: {
            row
          },
          template: this.templates.actionsColumn
        };
      default:
        return '';
    }
  }
}
