<form [formGroup]="sftpSettingsForm">
  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>
  <div class="twoColumnLayout">
    <div class="column">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Sftp Import Settings&nbsp;</mat-card-title>
          <fa-icon icon="info-circle" class="info-icon"></fa-icon>
        </mat-card-header>
        <mat-card-content class="settings-card-content mat-elevation-z3">
          <mat-button-toggle-group
            (change)="importScopeChange()"
            formControlName="isSchoolGroupLevel"
            class="button-toggle-group group-level-toggle">
            <mat-button-toggle value="true">Group Level</mat-button-toggle>
            <mat-button-toggle value="false">School Level</mat-button-toggle>
          </mat-button-toggle-group>
          <mat-form-field class="full-width-input">
            <mat-select
              [disabled]="importScopeSelected === false"
              formControlName="schoolGroupId"
              placeholder="School Group">
              <mat-option *ngFor="let group of schoolGroups" [value]="group.id">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <mat-select
              [disabled]="importScopeSelected === false || sftpSettings.isSchoolGroupLevel"
              formControlName="schoolId"
              placeholder="School">
              <mat-option *ngFor="let school of schools" [value]="school.id">
                {{ school.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="sftpAccounts && sftpAccounts.length > 0">
            <mat-chip-option class="account-chip" color="primary" selected>
              Account Found
            </mat-chip-option>
          </ng-container>
          <ng-container *ngIf="sftpAccounts && sftpAccounts.length === 0">
            <button
              class="new-account-button standard-button"
              mat-flat-button
              color="primary"
              (click)="createNewAccount()">
              Create New Account
            </button>
          </ng-container>
          <input type="hidden" formControlName="sftpAccountId" />
          <div>
            <mat-form-field class="full-width-input">
              <mat-select
                formControlName="importSubject"
                placeholder="Import Type"
                (selectionChange)="importTypeChanged($event)">
                <mat-option
                  *ngFor="let subject of importSubjects"
                  [value]="subject">
                  {{ subject | enumString }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="full-width-input">
              <mat-select
                formControlName="syncFrequency"
                placeholder="Import Frequency">
                <mat-option
                  *ngFor="let frequency of syncFrequencies"
                  [value]="frequency">
                  {{ frequency | enumString }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="full-width-input"
              float
              *ngIf="showStartDate()">
              <mat-label>Import Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="startDate" />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <app-utc-time-input
              class="full-width-input"
              *ngIf="showTime()"
              [date]="sftpSettings.startDate"
              formControlName="importTime"
              [displayTimezone]="timezone">
            </app-utc-time-input>
            <mat-form-field class="full-width-input" *ngIf="showDays()">
              <mat-select
                formControlName="importDays"
                placeholder="Select Days of the Week"
                multiple>
                <mat-option *ngFor="let type of weekDays" [value]="type.value">
                  {{type.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field class="full-width-input">
            <mat-chip-grid #chipGrid aria-label="Enter emails">
              <mat-chip-row
                *ngFor="let email of contactEmails"
                (removed)="removeEmail(email)"
                [editable]="true"
                (edited)="editEmail(email, $event)"
                [aria-description]="'press enter to edit ' + email.address">
                {{email.address}}
                <button
                  matChipRemove
                  [attr.aria-label]="'remove ' + email.address">
                  <fa-icon icon="times">Cancel</fa-icon>
                </button>
              </mat-chip-row>
              <input
                placeholder="New email address..."
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addEmail($event)" />
            </mat-chip-grid>
          </mat-form-field>
          <button
            class="save-settings-button standard-button"
            [matBadge]="formErrors.length"
            [matBadgeHidden]="formErrors.length === 0"
            [matBadgeColor]="'warn'"
            mat-flat-button
            color="primary"
            (click)="saveSettings()">
            Save Settings
          </button>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="column">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>File Upload&nbsp;</mat-card-title>
          <fa-icon icon="info-circle" class="info-icon"></fa-icon>
        </mat-card-header>
        <mat-card-content
          *ngIf="!hasBeenSaved()"
          class="mat-elevation-z3 not-saved-card">
          <h2>
            <fa-icon icon="exclamation-triangle" class="red-icon"></fa-icon>
            Please save settings before uploading a file
          </h2>
        </mat-card-content>
        <mat-card-content *ngIf="hasBeenSaved()" class="mat-elevation-z3">
          <div class="slide-toggle">
            <mat-checkbox
              [checked]="hasHeaders"
              color="primary"
              (change)="headerToggle($event)">
              Has Header
            </mat-checkbox>
            <mat-checkbox
              *ngIf="sftpSettings.importSubject === ImportSubject.STUDENT_IMPORT"
              color="primary"
              formControlName="removeMissingRecords">
              Remove Missing Records
            </mat-checkbox>
          </div>
          <mat-form-field *ngIf="showFileNameInput()" class="full-width-input">
            <input
              matInput
              formControlName="filePattern"
              placeholder="fileName.csv" />
          </mat-form-field>
          <div>
            <ng-container *ngIf="showFileUpload()">
              <app-file-upload (fileChanged)="handleFileChanged($event)">
              </app-file-upload>
            </ng-container>
            <button
              mat-flat-button
              color="primary"
              class="standard-button"
              (click)="getFromCloud()"
              [disabled]="isLoading"
              *ngIf="showGetFromCloud()">
              Upload From Cloud
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div>
    <div class="mapping-action-buttons">
      <button
        mat-flat-button
        class="standard-button"
        color="primary"
        [matBadge]="formErrors.length"
        [matBadgeHidden]="formErrors.length === 0"
        [matBadgeColor]="'warn'"
        (click)="saveSettings()">
        Save
      </button>
      <!-- only show or enable when updating settings, not on create -->
      <button
        *ngIf="showRunNow()"
        [disabled]="disableRunNow()"
        mat-flat-button
        class="standard-button import-button"
        [matBadge]="formErrors.length"
        [matBadgeHidden]="formErrors.length === 0"
        [matBadgeColor]="'warn'"
        (click)="saveAndQueue()">
        Save & Run Now
      </button>
    </div>
    <ng-container *ngIf="fileDataSource && fileDataSource.length > 0">
      <app-mapping-table
        [parsedFile]="fileDataSource"
        [(mappingConfig)]="mappingConfig"
        [columnHeaders]="fileDisplayedColumns">
      </app-mapping-table>
    </ng-container>
  </div>
</form>
