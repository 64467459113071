import { ICustomRoute } from '../../app-routing.module';
import { AuthGuard } from '../../route-guards/auth.guard';
import { AppTeacherRostersListComponent } from './teacher-rosters-list/teacher-rosters-list.component';
import { AppTeacherRostersListResolver } from './teacher-rosters-list/teacher-rosters-list.resolver';

export const AppTeachersRostersRoutes: ICustomRoute[] = [
  {
    path: 'dashboard/teacher',
    title: 'My Rosters',
    component: AppTeacherRostersListComponent,
    resolve: { data: AppTeacherRostersListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'My Rosters',
      subtitle: 'Select a period/roster to filter and track students',
      pageHeaderClass: 'teacher-rosters-page-header',
    }
  },
];
