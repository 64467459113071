import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  InterventionCreateDto,
  InterventionUpdateDto
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppInterventionsService } from '../interventions.service';
import { plainToInstance } from 'class-transformer';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { logger } from '../../../shared/logger';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';

@Component({
  selector: 'app-intervention-create-edit',
  templateUrl: './intervention-create-edit.template.html',
  styleUrls: ['./intervention-create-edit.scss']
})
export class AppInterventionCreateEditComponent
  extends BaseComponent
  implements AfterViewInit
{
  public intervention: InterventionCreateDto | InterventionUpdateDto;
  public interventionForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public isCreating: boolean;
  public interventionId: number;
  public behaviorCodeId: number;
  public pageHeaderSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    private interventionsService: AppInterventionsService,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const intervention = this.route.snapshot.data.data;
    this.interventionId = +this.route.snapshot.params.interventionId;
    this.behaviorCodeId = +this.route.snapshot.params.id;
    this.isCreating = !this.interventionId;
    if (this.isCreating) {
      this.intervention = plainToInstance(InterventionCreateDto, intervention, {
        exposeUnsetFields: true
      });
      this.pageHeaderService.changeTitle('Create Intervention');
    } else {
      this.intervention = plainToInstance(InterventionUpdateDto, intervention);
      this.pageHeaderService.changeTitle('Edit Intervention');
    }
    this.interventionForm = dtoToFormGroup(
      this.intervention,
      this.formBuilder,
      {
        mapId: !this.isCreating
      }
    );
    this.isLoading = false;
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.interventionForm.valueChanges.subscribe(async (value) => {
        if (this.isCreating) {
          this.intervention = await validateAndGetValue<InterventionCreateDto>(
            this.interventionForm,
            InterventionCreateDto
          );
        } else {
          this.intervention = await validateAndGetValue<InterventionUpdateDto>(
            this.interventionForm,
            InterventionUpdateDto
          );
        }
      })
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public async saveChanges(): Promise<void> {
    if (formCanSave(this.interventionForm, this.toastManager)) {
      try {
        if (this.intervention instanceof InterventionCreateDto) {
          const intervention =
            await this.interventionsService.createIntervention(
              this.intervention
            );
          this.intervention = plainToInstance(
            InterventionUpdateDto,
            intervention
          );
          await this.router.navigate(['/interventions']);
        } else {
          this.intervention =
            await this.interventionsService.updateIntervention(
              this.intervention,
              this.interventionId
            );
        }
        this.toastManager.success('Intervention saved successfully!');
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving intervention, please try again!'
        );
      }
    }
  }
}
