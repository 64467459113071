import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { AppAuthService } from './auth.service';
import { AppPrivilegesService } from './privileges.service';
import { BaseService } from 'src/app/shared/base-classes/base.service';
import { logger } from 'src/app/shared/logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginResponseDto } from '@whetstoneeducation/hero-common';

@Injectable({
  providedIn: 'root'
})
export class AppSessionHandlerService extends BaseService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(true);

  public isLoggingOut: boolean;

  public constructor(
    public router: Router,
    public authService: AppAuthService,
    public privilegesService: AppPrivilegesService,
    public toastManager: AppToastManagerService
  ) {
    super();
  }

  public async handleLogout(pageLoad?: boolean): Promise<void> {
    const isNotRunning = !this.isLoggingOut;
    const isLoggedIn = this.getIsLoggedIn();
    if (isNotRunning && isLoggedIn) {
      this.isLoggingOut = true;
      await this.callLogoutAPIMethod();
      await this.router.navigate(['/login']);
      this.setLoggedOutState();
      if (!pageLoad)
        this.toastManager.success(
          'You have been logged out. Please log in again!'
        );
      this.isLoggingOut = false;
    }
  }

  public async checkIfAuthorizedFull(): Promise<boolean> {
    try {
      if (this.checkIfAuthorizedQuick()) {
        try {
          await this.authService.getActiveSession();
        } catch (error) {
          logger.error(error);
          this.setLoggedOutState();
          await this.router.navigate(['/login']);
          return false;
        }
        return true;
      }
      return false;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }

  public checkIfAuthorizedQuick(): boolean {
    const loggedIn = this.getIsLoggedIn();

    const cached = this.StorageManager.getLoggedInUser();
    return loggedIn && !!cached;
  }

  public setLoggedOutState(): void {
    this.StorageManager.clearLoggedInUser();
    this.setIsLoggedOut();
  }

  public async handleHardReset(): Promise<void> {
    await this.callLogoutAPIMethod();
    this.StorageManager.clearAllStorage();
    window.location.pathname = '/login';
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  private async callLogoutAPIMethod(): Promise<void> {
    try {
      await this.authService.logout();
    } catch (error) {
      logger.error(error);
    }
  }

  public getIsLoggedIn(): boolean {
    return this.isLoggedInSubject.value;
  }

  public getIsLoggedInObservable(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }

  public setIsLoggedIn(): void {
    this.setIsLoggedInSubjectValue(true);
  }

  public setIsLoggedOut(): void {
    this.setIsLoggedInSubjectValue(false);
  }

  private setIsLoggedInSubjectValue(newValue: boolean): void {
    const currentValue = this.isLoggedInSubject.value;
    if (newValue !== currentValue) {
      this.isLoggedInSubject.next(newValue);
    }
  }

  public async handleLogin({
    session
  }: {
    session?: LoginResponseDto;
  } = {}): Promise<void> {
    // Load data needed for authenticated routes
    await this.loadLoggedInStateAndData(session);

    // Send our user to their home page
    await this.router.navigate(['/']);
  }

  public async loadLoggedInStateAndData(
    session?: LoginResponseDto
  ): Promise<void> {
    if (session) {
      if (!session.privileges) {
        session.privileges = session.roles.reduce(
          (privileges, role) => [...privileges, ...role.privileges],
          []
        );
      }
      this.StorageManager.saveUserInfo(session);
    }
    this.setIsLoggedIn();
  }
}
