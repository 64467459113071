import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import {
  BellScheduleResponseDto,
  PaginationResultsDto,
} from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppBellSchedulesService } from '../bell-schedules.service';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';

@Injectable({ providedIn: 'root' })
export class AppBellSchedulesListResolver extends BaseResolver<
  PaginationResultsDto<BellScheduleResponseDto>
> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public bellSchedulesService: AppBellSchedulesService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<
    PaginationResultsDto<BellScheduleResponseDto>
  > {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    if (schoolId) {
      const tableFilters = TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.BELL_SCHEDULES_LIST
      );
      return this.bellSchedulesService.getBellSchedulesList(
        schoolId,
        tableFilters
      );
    } else {
      return new PaginationResultsDto();
    }
  }
}
