import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import {
  SchoolWideReportFiltersDto,
  SchoolwideReportResponseDto
} from '@whetstoneeducation/hero-common';
import { BaseCommonTableComponent } from '../../../../shared/tables/common-table/base-common-table.component';
import { ActivatedRoute } from '@angular/router';
import { AppReportsService } from '../../reports.service';
import { AppPageHeaderService } from '../../../../shared/page-header/page-header.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { HeaderButtonAction } from '../../../../shared/page-header/header-button';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';

export enum SchoolwideReportTableColumns {
  STUDENT_ID = 'Student ID',
  STUDENT_NAME = 'Student Name',
  GRADE_LEVEL = 'Grade Level',
  AVAILABLE_POINTS = 'Available Points',
  TOTAL_POINTS = 'Total Points'
}

@Component({
  selector: 'app-schoolwide-report-table',
  templateUrl: './schoolwide-report-table.template.html',
  styleUrls: ['./schoolwide-report-table.scss']
})
export class AppSchoolwideReportTable
  extends BaseCommonTableComponent<
    SchoolwideReportResponseDto,
    SchoolwideReportTableColumns
  >
  implements AfterViewInit
{
  @ViewChild('tooltipHeader') tooltipHeader!: TemplateRef<any>;
  @ViewChild('pointsColumn') pointsColumn!: TemplateRef<any>;
  public filters: SchoolWideReportFiltersDto;
  private destroy$ = new Subject<void>();

  public columns;

  public constructor(
    public route: ActivatedRoute,
    public reportService: AppReportsService,
    private pageHeaderService: AppPageHeaderService,
    public confirmationDialog: AppConfirmationDialogService,
    private readonly toastService: AppToastManagerService
  ) {
    super();
    this.columns = SchoolwideReportTableColumns;
    this.templates = {};

    // subscribe to the PageHeaderService to listen for the EXPORT event
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: string) => {
        if (action === HeaderButtonAction.EXPORT) {
          const ref = this.confirmationDialog.openDialog({
            title: `Schoolwide Activity Export`,
            content:
              'Are you sure you want to export the schoolwide activity report?'
          });
          ref.afterClosed().subscribe(async (result: string) => {
            if (result === DEFAULT_DIALOG_RESULT.YES) {
              await this.reportService.getSchoolwidePointsReport(
                this.filters,
                true
              );
              this.toastService.success(
                'Export started, we will email you with a link to the file'
              );
            }
          });
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.tooltipHeader) {
      this.templates['tooltipHeader'] = this.tooltipHeader;
    }

    if (this.pointsColumn) {
      this.templates['pointsColumn'] = this.pointsColumn;
    }
  }

  public getColumnTitle(column: string): any {
    switch (column) {
      case SchoolwideReportTableColumns.AVAILABLE_POINTS:
        return {
          context: {
            title: 'Available Points',
            message: 'Total points including negatives'
          },
          template: this.templates.tooltipHeader
        };
      case SchoolwideReportTableColumns.TOTAL_POINTS:
        return {
          context: {
            title: 'Total Points',
            message: 'Total points with only positives'
          },
          template: this.templates.tooltipHeader
        };
      default:
        return column;
    }
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case SchoolwideReportTableColumns.STUDENT_ID:
        return row.studentId || 'N/A';
      case SchoolwideReportTableColumns.STUDENT_NAME:
        return row.studentName;
      case SchoolwideReportTableColumns.GRADE_LEVEL:
        return row.grade;
      case SchoolwideReportTableColumns.AVAILABLE_POINTS:
        return {
          context: {
            value: row.availablePoints || 0
          },
          template: this.templates.pointsColumn
        };
      case SchoolwideReportTableColumns.TOTAL_POINTS:
        return {
          context: {
            value: row.totalPoints || 0
          },
          template: this.templates.pointsColumn
        };
      default:
        return null;
    }
  }

  public ngOnInit() {
    this.loadData();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    this.isLoading = true;
    const currentUser = this.StorageManager.getLoggedInUser();
    const schoolId = currentUser.currentSchoolId;
    const data = this.route.snapshot.data.data;
    this.dataArr = data.results;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.SCHOOLWIDE_POINTS_REPORT
    );
    this.filters =
      this.reportService.getSchoolwideReportDefaultFilters(schoolId);
    this.tableFilters.count = data.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }

  public onFiltersUpdated(filters: any) {
    this.filters = filters;
  }

  public onInstantFiltersUpdated(filters: SchoolWideReportFiltersDto) {
    this.onFiltersUpdated(filters);
    this.applyFilters();
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.filters = new SchoolWideReportFiltersDto({
      ...this.filters,
      ...newTableFilters,
      active: false
    });

    await this.applyFilters();
  }

  public async applyFilters(): Promise<void> {
    this.isLoading = true;
    const response = await this.reportService.getSchoolwidePointsReport(
      this.filters
    );
    this.dataArr = response.results;
    this.tableFilters.count = response.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }
}
