import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../../shared/base-classes/base.component';
import { IStudentBehaviorHistoryFilter } from '../../../students-list-models/student-behavior-history-filters';
import { IDisplayData } from '@whetstoneeducation/hero-common';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-behavior-history-filters',
  templateUrl: './behavior-history-filters.component.html',
  styleUrls: ['./behavior-history-filters.component.scss']
})
export class BehaviorHistoryFiltersComponent extends BaseComponent {
  @Input() filters: IStudentBehaviorHistoryFilter;

  @Input() reactionOptions: IDisplayData[];

  @Input() behaviorCodeOptions: IDisplayData[];

  @Output() filtersUpdated: EventEmitter<IStudentBehaviorHistoryFilter>;

  constructor() {
    super();

    this.filtersUpdated = new EventEmitter<IStudentBehaviorHistoryFilter>();
  }

  public async onFiltersUpdated(
    field: string,
    event: MatOptionSelectionChange
  ) {
    if (event.isUserInput) {
      this.filters[field] = event.source.value;
      this.filtersUpdated.emit(this.filters);
    }
  }

  onStartDateUpdated(event: MatDatepickerInputEvent<Date>) {
    const startDate = event.target.value;
    // set start date to 12:00am
    startDate.setHours(0, 0, 0, 0);
    this.filters.startDate = startDate.getTime();
    this.filtersUpdated.emit(this.filters);
  }

  onEndDateUpdated(event: MatDatepickerInputEvent<Date>) {
    const endDate = event.target.value;
    // set end date to 11:59pm
    endDate.setHours(23, 59, 59, 999);
    this.filters.endDate = endDate.getTime();
    this.filtersUpdated.emit(this.filters);
  }
}
