<div class="mat-dialog-header">
  <div class="mat-dialog-title">
    Unsaved Changes
  </div>
</div>
<div mat-dialog-content>
  <div>
    Changes have been made, are you sure you want to discard these changes?
  </div>

  <div class="actions">
    <button mat-button (click)="close()">Cancel</button>
    <button
      mat-flat-button
      color="primary"
      (click)="goBack()">
      Continue
    </button>
  </div>
</div>
