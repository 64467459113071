import { Component, Inject, OnInit } from '@angular/core';
import {
  BehaviorCodeEntryUpdateNotesDto,
  PrivilegeEnum,
  StudentBehaviorHistoryResponseDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../../../shared/services/toast-manager.service';
import { AppBehaviorCodeEntryService } from '../../../../behavior-code-entry/behavior-code-entry.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../../../../shared/base-classes/base.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { AppPrivilegesService } from '../../../../auth/privileges.service';
import { validate } from 'class-validator';

@Component({
  selector: 'app-behavior-entry-notes-view-edit',
  templateUrl: './behavior-entry-notes-view-edit.template.html',
  styleUrls: ['./behavior-entry-notes-view-edit.component.scss']
})
export class BehaviorEntryNotesViewEditComponent
  extends BaseComponent
  implements OnInit
{
  public behaviorEntry: StudentBehaviorHistoryResponseDto;
  public notesControl = new FormControl<string>('');

  constructor(
    public toastService: AppToastManagerService,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    privilegesService: AppPrivilegesService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BehaviorEntryNotesViewEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      behaviorCodeEntry: StudentBehaviorHistoryResponseDto;
      onClose?: (notes: string) => void;
    }
  ) {
    super({ privilegesService });
  }

  public async ngOnInit(): Promise<void> {
    await this.loadData(this.data.behaviorCodeEntry);
  }

  public async loadData(
    behaviorCodeEntry: StudentBehaviorHistoryResponseDto
  ): Promise<void> {
    this.isLoading = true;
    this.behaviorEntry = behaviorCodeEntry;
    this.notesControl = new FormControl<string>(this.behaviorEntry.notes);
    this.isLoading = false;
  }

  public async submitBehaviorEntry(): Promise<void> {
    this.isLoading = true;
    const updateDto = new BehaviorCodeEntryUpdateNotesDto({
      notes: this.notesControl.value
    });
    // validate the notes length
    const validationResults = await validate(updateDto);
    if (validationResults.length) {
      const message =
        validationResults.length > 1
          ? validationResults
              .map((result) => {
                Object.values(result.constraints).join(' ');
              })
              .join(', ')
          : Object.values(validationResults[0].constraints).join(' ');
      this.toastService.error(message);
      this.isLoading = false;
      return;
    }
    const result =
      await this.behaviorCodeEntryService.updateBehaviorCodeEntryNotes(
        updateDto,
        this.behaviorEntry.behaviorEntryId
      );

    this.toastService.success('Behavior entry notes updated successfully!');

    this.data.onClose?.(result.notes);
    this.dialogRef.close();
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
