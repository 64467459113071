<div class="mat-dialog-header">
  <div class="mat-dialog-title">Edit Days</div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<!-- First, in a button toggle group, options "add" and "remove",
     then in a multi select days monday-sunday -->
<div mat-dialog-content>
  <ng-container *ngIf="!isLoading">
    <form [formGroup]="daysForm" novalidate>
      <div class="form-row">
        <mat-button-toggle-group
          (change)="onModeToggle($event)"
          formControlName="mode"
          name="mode"
          aria-label="Add or Remove Days">
          <mat-button-toggle value="add">Add</mat-button-toggle>
          <mat-button-toggle value="remove">Remove</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Days</mat-label>
          <mat-select formControlName="days" multiple>
            <mat-option *ngFor="let day of daysOptions" [value]="day.value">
              {{day.display}}
            </mat-option>
          </mat-select>
          <app-error-display
            [form]="daysForm"
            controlName="days"></app-error-display>
        </mat-form-field>
      </div>
      <div class="form-row">
        <button
          mat-flat-button
          class="secondary-button"
          (click)="save()"
          [disabled]="!daysForm.valid">
          Save
        </button>
        <button
          mat-flat-button
          class="outline-button"
          (click)="dialogRef.close()">
          Close
        </button>
      </div>
    </form>
  </ng-container>
</div>
