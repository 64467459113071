"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentCourseScheduleResponseDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
class StudentCourseScheduleResponseDto extends mappable_base_1.MappableBase {
    constructor(responseOptions) {
        super();
        if (responseOptions) {
            Object.assign(this, responseOptions);
        }
    }
    term;
    period;
    courseDescription;
    courseCode;
    roomName;
    section;
    instructorFirstName;
    instructorLastName;
    instructorCode;
    getFields() {
        return [
            'term',
            'period',
            'courseDescription',
            'courseCode',
            'section',
            'roomName',
            'instructorFirstName',
            'instructorLastName',
            'instructorCode'
        ];
    }
    /**
     * @public
     * @static
     * @function mapAll
     * @param entities {MappableBase[]}
     * @returns {StudentCourseScheduleResponseDto[]} - an array of student dtos with fields mapped from the input student array
     */
    static mapDto(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, StudentCourseScheduleResponseDto);
    }
}
exports.StudentCourseScheduleResponseDto = StudentCourseScheduleResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "term", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "period", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "courseDescription", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "courseCode", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "roomName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "section", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "instructorFirstName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "instructorLastName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCourseScheduleResponseDto.prototype, "instructorCode", void 0);
