import { BaseResolver } from '../../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../../shared/services/resolver-error-handler.service';
import {
  AppBellSchedulesService,
  IBellSchedulePeriodManagerResolverData
} from '../../bell-schedules.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppBellSchedulePeriodManagerResolver extends BaseResolver<IBellSchedulePeriodManagerResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public bellScheduleService: AppBellSchedulesService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<IBellSchedulePeriodManagerResolverData> {
    return await this.bellScheduleService.getBellSchedulePeriodManagerResolverData();
  }
}
