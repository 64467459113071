import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  BehaviorCodeTypeResponseDto,
  IDisplayData,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodeTypesListFilters } from './behavior-code-types-models/behavior-code-types-list-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppBehaviorCodeTypesService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async getBehaviorCodeTypesList(
    filters: IBehaviorCodeTypesListFilters
  ): Promise<BehaviorCodeTypeResponseDto[]> {
    return this.appClientDataService.execute<BehaviorCodeTypeResponseDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_TYPES_LIST,
      {
        queryParams: {
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          ...(filters.searchInput ? { name: filters.searchInput } : {}),
          ...(!filters.inactive ? { active: !filters.inactive } : {}),
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {})
        }
      }
    );
  }

  public async getBehaviorCodeTypesListDisplayData(
    schoolGroupId: number
  ): Promise<IDisplayData[]> {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_TYPES_LIST_DISPLAY_DATA,
      {
        pathParams: { schoolGroupId }
      }
    );
  }
}
