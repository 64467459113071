import { AppMySettingsComponent } from './my-settings.component';
import { AppMySettingsResolver } from './my-settings.resolver';
import { AuthGuard } from 'src/app/route-guards/auth.guard';
import { ICustomRoute } from 'src/app/app-routing.module';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

export const AppMySettingsRoutes: ICustomRoute[] = [
  {
    path: 'my-settings',
    title: 'My Settings',
    component: AppMySettingsComponent,
    resolve: { data: AppMySettingsResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'My Settings',
      buttons: [
        {
          name: 'Save',
          action: HeaderButtonAction.SAVE,
          style: 'secondary-button'
        }
      ]
    }
  }
];
