<div class="table-container mat-elevation-z2">
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="school-groups-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- name Column -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.name }} </mat-cell>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="{{columns.DESCRIPTION}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.DESCRIPTION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.description }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell
          *matHeaderCellDef
          class="action-column-header"
          mat-sort-header>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let schoolGroup">
          <button
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/school-groups/' + schoolGroup.id"
            options-id="EDIT_SCHOOL_GROUP"
            [option]="schoolGroup.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let item; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
