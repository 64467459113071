"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFilterDto = void 0;
const shared_1 = require("../shared");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const boolean_transform_1 = require("../../constants/boolean.transform");
class UserFilterDto extends shared_1.PaginationOptionsDto {
    constructor(userFilterOptions) {
        super();
        if (!userFilterOptions)
            return;
        this.firstName = userFilterOptions.firstName;
        this.lastName = userFilterOptions.lastName;
        this.email = userFilterOptions.email;
        this.schoolId = userFilterOptions.schoolId;
        this.schoolGroupId = userFilterOptions.schoolGroupId;
        this.active = userFilterOptions.active;
        this.instructorCode = userFilterOptions.instructorCode;
    }
    firstName;
    lastName;
    email;
    instructorCode;
    schoolId;
    schoolGroupId;
    active;
}
exports.UserFilterDto = UserFilterDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], UserFilterDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], UserFilterDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], UserFilterDto.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], UserFilterDto.prototype, "instructorCode", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], UserFilterDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], UserFilterDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], UserFilterDto.prototype, "active", void 0);
