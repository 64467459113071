"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInviteTemplateVariablesDto = void 0;
const base_invite_template_variables_dto_1 = require("./base-invite-template-variables.dto");
class UserInviteTemplateVariablesDto extends base_invite_template_variables_dto_1.BaseInviteTemplateVariablesDto {
    constructor(userInviteTemplateVariablesOptions) {
        super();
        Object.assign(this, userInviteTemplateVariablesOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return [...super.getFields(), 'firstName', 'lastName'];
    }
    firstName;
    lastName;
}
exports.UserInviteTemplateVariablesDto = UserInviteTemplateVariablesDto;
