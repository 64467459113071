<div class="table-container mat-elevation-z2">
  <div class="confirmation-message">
    <span class="check-icon">
      <fa-icon [icon]="'check-circle'"></fa-icon>
    </span>

    {{ dataSource.data.length }} students have been tracked successfully, you
    can still edit reactions
  </div>
  <div class="mat-table">
    <mat-table [dataSource]="dataSource" matSort matSortDisableClear>
      <ng-container matColumnDef="{{ columns.STUDENT_NAME }}">
        <mat-header-cell *matHeaderCellDef>
          {{ columns.STUDENT_NAME }}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          <app-student-profile-photo
            [selectedImage]="reactionEntry.profilePhotoUrl"
            [studentId]="reactionEntry.studentId"
            [hideProfileImageSelector]="true"
            [imageWidth]="40"
            [imageHeight]="40">
          </app-student-profile-photo>
          <div class="student-name">
            {{ reactionEntry.studentName }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.REACTION }}">
        <mat-header-cell *matHeaderCellDef>
          {{ columns.REACTION }}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{ reactionEntry.reactionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.REACTION_DATE }}">
        <mat-header-cell *matHeaderCellDef>
          {{ columns.REACTION_DATE }}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{
            reactionEntry.reactionDate
              ? DateUtil.convertTimestampToDisplayDate(
                  reactionEntry.reactionDate
                )
              : 'no scheduled reaction'
          }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.ACTIONS }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ columns.ACTIONS }}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          <ng-container>
            <button
              type="submit"
              mat-flat-button
              (click)="onEditReaction(reactionEntry)"
              options-id="COMPY_REACTION_ENTRY">
              <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
            </button>
            <button
              *ngIf="showPrintReactionButton"
              type="button"
              mat-flat-button
              (click)="onPrintReaction(reactionEntry)"
              options-id="PRINT_REACTION_ENTRY">
              <fa-icon [icon]="['fas', 'print']"></fa-icon>
            </button>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let code; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <app-pagination
    [tableFilters]="tableFilters"
    (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
</div>
