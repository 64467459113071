import { AfterViewInit, Component, Renderer2 } from "@angular/core";
import { Helpers } from "@whetstoneeducation/hero-common";


@Component({
  selector: 'app-google-translation',
  template: ``
})
export class GoogleTranslationComponent implements AfterViewInit {

  constructor(private renderer: Renderer2) {
    window.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
  }
  ngAfterViewInit(): void {
    this.loadGoogleTranslate();
    this.addScrollGoogleStyles();
  }

  loadGoogleTranslate() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    this.renderer.appendChild(document.head, script);
  }

  public googleTranslateElementInit() {
    if (!document.getElementById('google_translate_element')?.firstChild) {
      new window.google.translate.TranslateElement(
        { pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
         },
        'google_translate_element'
      );
    }
  }

  public addScrollGoogleStyles() {
    const googFrame = document.querySelector('.VIpgJd-ZVi9od-xl07Ob-OEVmcd') as HTMLIFrameElement | null;

    const applyStyles = () => {
      if (googFrame) {
        const googBody = googFrame.contentDocument.body;
        googFrame.style.width = '100%';

        if (googBody) {
          const stylesHtml = '<style>'
                    + '.VIpgJd-ZVi9od-vH1Gmf {'
                        + 'max-width: 100% !important;'
                        + 'overflow: scroll !important;'
                        + 'box-sizing: border-box !important;'
                        + 'height: auto !important;'
                    + '}'
                + '</style>';

          googBody.insertAdjacentHTML('afterbegin', stylesHtml);
        }
      }
    };

    // Debounce function to reduce the frequency of resize event handling
    const debouncedHandleResize = Helpers.debounce(() => applyStyles(), 300);

    if (googFrame) {
      applyStyles();
      window.addEventListener('resize', debouncedHandleResize);
    } else {
      setTimeout(this.addScrollGoogleStyles, 50);
    }
  }
}
