"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobStatus = void 0;
var JobStatus;
(function (JobStatus) {
    JobStatus["PENDING"] = "pending";
    JobStatus["PROCESSING"] = "processing";
    JobStatus["COMPLETED"] = "completed";
    JobStatus["COMPLETED_WITH_ERRORS"] = "complete-with-errors";
    JobStatus["FAILED"] = "failed";
    JobStatus["FAILED_WITH_ERRORS"] = "failed-with-errors";
    JobStatus["CANCELLED"] = "cancelled";
})(JobStatus || (exports.JobStatus = JobStatus = {}));
