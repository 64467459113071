import { Component, Input } from "@angular/core";

@Component({
  selector: "app-points-bubble",
  templateUrl: "./points-bubble.template.html",
  styleUrls: ["./points-bubble.scss"],
})
export class PointsBubbleComponent {
  @Input()
  public points: number;

  public totalPoints(): string {
    if (this.points < 0) {
      return `-${Math.abs(this.points)}`;
    } else {
      return `+${this.points}`;
    }
  }
}
