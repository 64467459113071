import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { BellScheduleResponseDto } from '@whetstoneeducation/hero-common';
import { BellSchedulesListTableColumns } from '../../bell-schedules-models/bell-schedules-list-table-columns.enum';

@Component({
  selector: 'app-bell-schedules-list-table',
  templateUrl: './bell-schedules-list-table.template.html',
  styleUrls: ['./bell-schedules-list-table.scss']
})
export class AppBellSchedulesListTableComponent extends BaseTableComponent<BellScheduleResponseDto> {
  @Input() showSelectOptions: boolean;

  public get displayedColumns() {
    const columns = Object.values(BellSchedulesListTableColumns);
    if (!this.showSelectOptions) {
      columns.shift();
    }
    return columns;
  }

  public columnValues = {
    [BellSchedulesListTableColumns.NAME]: ['name'],
    [BellSchedulesListTableColumns.DESCRIPTION]: ['description']
  };

  public columns = BellSchedulesListTableColumns;

  @Input()
  public selectedBellSchedules: BellScheduleResponseDto[] = [];

  @Output()
  public selectedBellSchedulesChanged: EventEmitter<BellScheduleResponseDto[]>;

  @Output()
  public bellScheduleSelected: EventEmitter<BellScheduleResponseDto>;

  constructor() {
    super();
    this.selectedBellSchedulesChanged = new EventEmitter<
      BellScheduleResponseDto[]
    >();
    this.bellScheduleSelected = new EventEmitter<BellScheduleResponseDto>();
  }

  public selectAllBellSchedules(): void {
    const allSelected =
      this.dataArr.length === this.selectedBellSchedules.length;
    if (allSelected) {
      this.selectedBellSchedules = [];
    } else {
      this.selectedBellSchedules = this.dataArr;
    }
    this.selectedBellSchedulesChanged.emit(this.selectedBellSchedules);
  }

  public selectBellSchedule(
    selectedBellSchedule: BellScheduleResponseDto
  ): void {
    const isSelected = !!this.selectedBellSchedules.find(
      (bellSchedule) => bellSchedule.id === selectedBellSchedule.id
    );
    if (isSelected) {
      this.selectedBellSchedules = this.selectedBellSchedules.filter(
        (bellSchedule) => bellSchedule.id !== selectedBellSchedule.id
      );
    } else {
      this.selectedBellSchedules.push(selectedBellSchedule);
    }
    this.selectedBellSchedulesChanged.emit(this.selectedBellSchedules);
  }

  public isSelected(bellScheduleId: number): boolean {
    return !!this.selectedBellSchedules.find(
      (bellSchedule) => bellSchedule.id === bellScheduleId
    );
  }
}
