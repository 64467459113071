"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportFrequency = void 0;
var ImportFrequency;
(function (ImportFrequency) {
    ImportFrequency["OneTime"] = "one-time";
    ImportFrequency["Daily"] = "daily";
    ImportFrequency["Weekly"] = "weekly";
    ImportFrequency["Monthly"] = "monthly";
})(ImportFrequency || (exports.ImportFrequency = ImportFrequency = {}));
