import { SubscriptionCreateDto, SubscriptionResponseDto, SubscriptionUpdateDto } from '@whetstoneeducation/hero-common';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { BaseService } from '../../shared/base-classes/base.service';
import { Injectable } from '@angular/core';
import { NotFoundError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppSchoolsSubscriptionService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService
  ) {
    super();
  }

  public async getSubscriptionBySchoolId(
    schoolId: number
  ): Promise<SubscriptionResponseDto> {
    try {
      return await this.appClientDataService.execute<SubscriptionResponseDto>(
        this.GET_ROUTES.SUBSCRIPTION_BY_SCHOOL_ID,
        {
          pathParams: {
            schoolId: schoolId,
          },
          fullResponse: true,
        },
        SubscriptionResponseDto
      );
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        return new SubscriptionResponseDto();
      }
      throw e;
    }
  }

  public async createSubscription(
    subscription: SubscriptionCreateDto
  ): Promise<SubscriptionResponseDto> {
    return this.appClientDataService.execute<SubscriptionResponseDto>(
      this.POST_ROUTES.CREATE_SUBSCRIPTION,
      {
        body: subscription,
        method: this.METHOD.POST,
      },
      SubscriptionResponseDto
    );
  }

  public async updateSubscription(
    schoolId: number,
    subscription: SubscriptionUpdateDto
  ): Promise<SubscriptionResponseDto> {
    return this.appClientDataService.execute<SubscriptionResponseDto>(
      this.PUT_ROUTES.UPDATE_SUBSCRIPTION,
      {
        body: subscription,
        pathParams: {
          schoolId: schoolId
        },
        method: this.METHOD.PUT,
      },
      SubscriptionResponseDto
    );
  }

}
