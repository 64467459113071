"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BellSchedulePeriodResponseDto = void 0;
const class_transformer_1 = require("class-transformer");
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
class BellSchedulePeriodResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.name = options.name;
        this.referenceCode = options.referenceCode;
        this.extPeriodCode = options.extPeriodCode;
        this.active = options.active;
        this.schoolId = options.schoolId;
    }
    getFields() {
        return ['id', 'name', 'referenceCode', 'extPeriodCode', 'schoolId'];
    }
    mapFields(source, skipNested = false, skipTransforms = false) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    id;
    name;
    referenceCode;
    extPeriodCode;
    active;
    schoolId;
    static mapBellSchedulePeriods(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, BellSchedulePeriodResponseDto);
    }
}
exports.BellSchedulePeriodResponseDto = BellSchedulePeriodResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BellSchedulePeriodResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], BellSchedulePeriodResponseDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], BellSchedulePeriodResponseDto.prototype, "referenceCode", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], BellSchedulePeriodResponseDto.prototype, "extPeriodCode", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], BellSchedulePeriodResponseDto.prototype, "active", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BellSchedulePeriodResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", Array)
], BellSchedulePeriodResponseDto, "mapBellSchedulePeriods", null);
