import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IBehaviorCodeInterventionsCreateEditResolverData } from '../behavior-code-interventions-models/behavior-code-interventions-create-edit-resolver-data.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppBehaviorCodeInterventionsService } from '../behavior-code-interventions.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppBehaviorCodeInterventionCreateEditResolver extends BaseResolver<IBehaviorCodeInterventionsCreateEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private behaviorCodeInterventionsService: AppBehaviorCodeInterventionsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IBehaviorCodeInterventionsCreateEditResolverData> {
    const behaviorCodeId = route.paramMap.get('id');
    const behaviorCodeReactionId = route.paramMap.get(
      'behaviorCodeInterventionId'
    );
    return this.behaviorCodeInterventionsService.getBehaviorCodeInterventionCreateEditResolverData(
      behaviorCodeId,
      behaviorCodeReactionId
    );
  }
}
