import { BaseComponent } from '../../../shared/base-classes/base.component';
import { SchoolResponseDto } from '@whetstoneeducation/hero-common';
import { ISchoolsListFilters } from './schools-list-models/schools-list-filters.interface';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSchoolsServices } from '../schools.service';
import { ISchoolsListResolverData } from './schools-list-models/schools-list-resolver-data.interface';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { logger } from '../../../shared/logger';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { Component, OnDestroy } from '@angular/core';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-schools-list',
  templateUrl: './schools-list.template.html',
  styleUrls: ['./schools-list.scss']
})
export class AppSchoolsListComponent
  extends BaseComponent
  implements OnDestroy
{
  public schools: SchoolResponseDto[];
  public filters: ISchoolsListFilters;
  private pageHeaderSubscription: Subscription;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public schoolsServices: AppSchoolsServices,
    public pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as ISchoolsListResolverData;
    this.schools = data.schools.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.schools.options.totalItems;
    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.CREATE) {
            await this.router.navigate(['schools', 'new']);
          }
        }
      );
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.filters.tableFilters = newTableFilters;
    await this.updateSchoolList(this.filters);
  }

  public async updateSchoolList(filters: ISchoolsListFilters): Promise<void> {
    try {
      this.isLoading = true;
      const newData = await this.schoolsServices.getSchoolsList(filters);
      this.schools = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving schools!');
      logger.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async updateFilters(newFilters: ISchoolsListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.SCHOOLS_LIST,
      this.filters.tableFilters
    );
    await this.updateSchoolList(this.filters);
    this.isLoading = false;
  }

  public async ngOnDestroy(): Promise<void> {
    this.pageHeaderSubscription.unsubscribe();
  }
}
