"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactionUpdateDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const daysOfWeek_enum_1 = require("../../enums/daysOfWeek.enum");
const boolean_transform_1 = require("../../constants/boolean.transform");
class ReactionUpdateDto extends mappable_base_1.MappableBase {
    constructor(reactionOptions) {
        super();
        if (!reactionOptions)
            return;
        Object.assign(this, reactionOptions);
    }
    getFields() {
        return [
            'id',
            'name',
            'description',
            'daysUntilReaction',
            'passMessage',
            'dailyCapacity',
            'complianceRequired',
            'removeScheduledReactions',
            'createScheduledReactions',
            'active'
        ];
    }
    name;
    description;
    daysUntilReaction;
    passMessage;
    dailyCapacity;
    complianceRequired;
    removeScheduledReactions;
    createScheduledReactions;
    active;
    removeDailyCapacity;
}
exports.ReactionUpdateDto = ReactionUpdateDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ReactionUpdateDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ReactionUpdateDto.prototype, "description", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], ReactionUpdateDto.prototype, "daysUntilReaction", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ReactionUpdateDto.prototype, "passMessage", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], ReactionUpdateDto.prototype, "dailyCapacity", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], ReactionUpdateDto.prototype, "complianceRequired", void 0);
__decorate([
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsEnum)(daysOfWeek_enum_1.DaysOfWeekEnum, { each: true }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], ReactionUpdateDto.prototype, "removeScheduledReactions", void 0);
__decorate([
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsEnum)(daysOfWeek_enum_1.DaysOfWeekEnum, { each: true }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], ReactionUpdateDto.prototype, "createScheduledReactions", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], ReactionUpdateDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], ReactionUpdateDto.prototype, "removeDailyCapacity", void 0);
