<div class="calendar-view-container report-container row container-fluid">
  <div
    class="report-left-pane smaller-mobile-padding col-xs-12 col-sm-10 offset-sm-1 col-md-4 offset-md-0 col-xl-3">
    <ng-container *ngIf="dropdownData">
      <app-calendar-view-list-filters
        [filters]="filters"
        (filtersUpdated)="updateFilters($event)"
        [dropdownData]="dropdownData" />
    </ng-container>
  </div>
  <div
    *ngIf="!isLoading"
    class="report-right-pane smaller-mobile-padding col-xs-12 col-sm-10 offset-sm-1 offset-md-0">
    <app-calendar-view-full
      [isLoading]="isLoading"
      [calendarDays]="calendarDays"
      [filters]="filters"
      (dateSelected)="handleDateSelected($event)" />
  </div>
</div>
f
