"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportMappingConfiguration = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const boolean_transform_1 = require("../../constants/boolean.transform");
const mapping_constraint_1 = require("./mapping.constraint");
class ImportMappingConfiguration {
    requiredColumns = [];
    availableColumns = [];
    mapping = {};
    hasHeaderRow = false;
    quotedColumns = false;
    constructor(options) {
        if (options)
            Object.assign(this, options);
    }
}
exports.ImportMappingConfiguration = ImportMappingConfiguration;
__decorate([
    (0, class_validator_1.IsArray)({ message: 'Required columns must be provided.' }),
    (0, class_validator_1.IsString)({ each: true, message: 'Each required column must be a string.' }),
    __metadata("design:type", Array)
], ImportMappingConfiguration.prototype, "requiredColumns", void 0);
__decorate([
    (0, class_validator_1.IsArray)({ message: 'Available columns must be provided.' }),
    (0, class_validator_1.IsString)({ each: true, message: 'Each available column must be a string.' }),
    __metadata("design:type", Array)
], ImportMappingConfiguration.prototype, "availableColumns", void 0);
__decorate([
    (0, class_validator_1.IsObject)({ message: 'Mapping must be provided.' }),
    (0, class_validator_1.Validate)(mapping_constraint_1.IsImportMappingConstraint),
    __metadata("design:type", Object)
], ImportMappingConfiguration.prototype, "mapping", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)({ message: 'Header row must be true or false.' }),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], ImportMappingConfiguration.prototype, "hasHeaderRow", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)({ message: 'Quoted columns must be true or false.' }),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], ImportMappingConfiguration.prototype, "quotedColumns", void 0);
