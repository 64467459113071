import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../../../app-material.module';
import { AppMultipleSelectComponent } from './multiple-select.component';

@NgModule({
  imports: [CommonModule, RouterModule, AppMaterialModule, FormsModule],
  declarations: [AppMultipleSelectComponent],
  providers: [],
  exports: [AppMultipleSelectComponent],
})
export class AppMultipleSelectModule {}
