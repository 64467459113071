<div class="table-container mat-elevation-z2">
  <app-pagination
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    [tableFilters]="tableFilters"
    (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  <div class="mat-table">
    <mat-table [dataSource]="dataSource" matSort matSortDisableClear>
      <ng-container matColumnDef="{{ columns.STUDENT_ID }}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.STUDENT_ID}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{ reactionEntry.studentExtId }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.STUDENT_NAME }}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.STUDENT_NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{ reactionEntry.studentName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.GRADE }}">
        <mat-header-cell *matHeaderCellDef> {{columns.GRADE}} </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{ reactionEntry.studentGrade }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.ENTRY_DATE }}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.ENTRY_DATE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{
          DateUtil.convertTimestampToDisplayDate(reactionEntry.behaviorEntryDate)
          }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.ENTRY_TIME }}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.ENTRY_TIME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{
          DateUtil.convertTimestampToDisplayTime(reactionEntry.behaviorEntryDate)
          }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.BEHAVIOR_CODE }}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.BEHAVIOR_CODE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{ reactionEntry.behaviorCodeName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.REACTION }}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.REACTION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{ reactionEntry.reactionName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.REACTION_DATE }}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.REACTION_DATE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          {{ reactionEntry.reactionDate ?
          DateUtil.convertTimestampToDisplayDate(reactionEntry.reactionDate) :
          '' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.COMPLY }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.COMPLY}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reactionEntry">
          <ng-container *ngIf="reactionEntry.compliedDate">
            {{
            DateUtil.convertTimestampToDisplayDate(reactionEntry.compliedDate)
            }}
          </ng-container>
          <ng-container *ngIf="!reactionEntry.compliedDate">
            <button
              type="submit"
              mat-flat-button
              class="secondary-button"
              (click)="comply(reactionEntry.id)"
              options-id="COMPY_REACTION_ENTRY">
              <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
            </button>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let code; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div
      *ngIf="tableFilters.count > tableFilters.itemsPerPage"
      class="table-filters-bottom">
      <app-pagination
        [tableFilters]="tableFilters"
        (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
    </div>
  </div>
</div>
