"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeReactionPossibleNextReactionOptionsResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class BehaviorCodeReactionPossibleNextReactionOptionsResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['reactions', 'reaction', 'scheduledReaction', 'scheduledReactions'];
    }
    reactions;
    reaction;
    scheduledReaction;
    scheduledReactions;
}
exports.BehaviorCodeReactionPossibleNextReactionOptionsResponseDto = BehaviorCodeReactionPossibleNextReactionOptionsResponseDto;
