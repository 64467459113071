"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfUpdateDto = void 0;
const class_validator_1 = require("class-validator");
const user_password_dto_1 = require("./user-password.dto");
const class_transformer_1 = require("class-transformer");
const enums_1 = require("../../enums");
const mappable_base_1 = require("../../mappable.base");
const boolean_transform_1 = require("../../constants/boolean.transform");
/**
 * @class SelfUpdateDto
 * @extends MappableBase
 * @description
 * Contains typical user fields.
 */
class SelfUpdateDto extends mappable_base_1.MappableBase {
    constructor(updateUserOptions) {
        super();
        if (!updateUserOptions)
            return;
        this.id = updateUserOptions.id;
        this.firstName = updateUserOptions.firstName;
        this.lastName = updateUserOptions.lastName;
        this.email = updateUserOptions.email;
        this.address = updateUserOptions.address;
        this.city = updateUserOptions.city;
        this.state = updateUserOptions.state;
        this.zip = updateUserOptions.zip;
        this.phone = updateUserOptions.phone;
        this.passwordInfo = updateUserOptions.passwordInfo;
        this.active = updateUserOptions.active;
        this.instructorCode = updateUserOptions.instructorCode;
        this.currentSchoolId = updateUserOptions.currentSchoolId;
        this.schoolGroupId = updateUserOptions.schoolGroupId;
    }
    id;
    email;
    firstName;
    lastName;
    passwordInfo;
    address;
    city;
    state;
    zip;
    phone;
    active;
    instructorCode;
    schoolGroupId;
    currentSchoolId;
    getFields() {
        return [
            'email',
            'active',
            'type',
            'firstName',
            'lastName',
            'address',
            'city',
            'state',
            'zip',
            'phone',
            'instructorCode',
            'schoolGroupId',
            'currentSchoolId'
        ];
    }
    mapFields(source) {
        super.mapFields(source);
        return this;
    }
}
exports.SelfUpdateDto = SelfUpdateDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SelfUpdateDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsEmail)(),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "email", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "firstName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "lastName", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => user_password_dto_1.UserPasswordDto),
    (0, class_validator_1.ValidateNested)({ message: 'password info validation failed' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", user_password_dto_1.UserPasswordDto)
], SelfUpdateDto.prototype, "passwordInfo", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "address", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "city", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.StateEnum),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "state", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "zip", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "phone", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], SelfUpdateDto.prototype, "active", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SelfUpdateDto.prototype, "instructorCode", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SelfUpdateDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SelfUpdateDto.prototype, "currentSchoolId", void 0);
