<div class="actions-container">
  <div>
    <app-filter-results-input
      (filterUpdated)="searchFilterUpdated($event)"></app-filter-results-input>
    <mat-slide-toggle
      [checked]="!this.filters.active"
      hideIcon
      (toggleChange)="this.onFilterUpdated('active', !this.filters.active)"
      [color]="'primary'"
      class="margin-right-class"
      >
      Show Deactivated Records
    </mat-slide-toggle>

    <mat-slide-toggle
      class="hide-toggle-icon"
      hideIcon
      [checked]="this.filters.yearToDateTotals"
      (toggleChange)="this.onFilterUpdated('yearToDateTotals', !this.filters.yearToDateTotals)"
      [color]="'primary'"
      >
      Show Year-To-Date Totals
    </mat-slide-toggle>
  </div>

  <!-- <div class="right-actions-container">
    <button type="button" mat-stroked-button color="primary"><span>EXPORT</span></button>
    <button mat-icon-button type="button">
      <fa-icon [icon]="downloadIcon"></fa-icon>
    </button>
  </div> -->
</div>
