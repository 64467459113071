import { AppMySettingsService } from './my-settings.service';
import { AppResolverErrorHandlerService } from 'src/app/shared/services/resolver-error-handler.service';
import { BaseResolver } from 'src/app/shared/base-classes/base.resolver';
import { Injectable } from '@angular/core';
import { IMySettingsResolverData } from './my-settings-resolver-data.interface';

@Injectable()
export class AppMySettingsResolver extends BaseResolver<IMySettingsResolverData> {
  constructor(
    public mySettingsService: AppMySettingsService,
    public resolverErrorHandler: AppResolverErrorHandlerService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<IMySettingsResolverData> {
    return await this.mySettingsService.getMySettingsData();
  }
}
