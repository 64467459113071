export const deleteRoutes = {
  LOGOUT: '/auth/logout',
  DELETE_BELL_SCHEDULE_PERIOD: '/bell-schedule-periods/:id',
  CLEAN_UP_BELL_SCHEDULE_PERIODS: '/bell-schedule-periods/clean-up/:schoolId',
  DEACTIVATE_USER: '/users/:id',
  DELETE_STUDENT_GROUP: '/student-groups/:id',
  DELETE_GUARDIAN: '/students/:id/remove-guardian/:guardianId',
  DELETE_BEHAVIOR_CODE_ENTRY: '/behavior-code-entries/:id',
  DELETE_SCHEDULED_REACTION: '/scheduled-reactions/:id',
  DELETE_EXPORT_SETTINGS: '/export/settings/:id',
  DELETE_IMPORT_SETTINGS: '/sftp/settings/:id',
  DELETE_BELL_SCHEDULE_PERIOD_TIME: '/bell-schedule-period-times/:id',
  DELETE_BEHAVIOR_CODE_NOTIFICATION: '/behavior-code-notifications/:id'
};

export type DeleteRoutes = typeof deleteRoutes;
