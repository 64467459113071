import { Component, Input } from '@angular/core';
import { AppAnimationFactory } from '../animation.factory';
import { AppMaterialModule } from '../../app-material.module';
import { FormGroup } from '@angular/forms';

@Component({
  standalone: true,
  imports: [AppMaterialModule],
  selector: 'app-error-display',
  template: `
    <ng-container
      *ngIf="
        form && ((form.get(controlName).invalid && form.get(controlName).touched) || (showOnErrors && form.get(controlName)?.errors?.length))
      ">
      <mat-error [@fadeInOut] class="app-validation-alert ng-animate">
        <ng-container
          *ngFor="let error of form.get(controlName).errors | keyvalue">
          *&nbsp;{{ parseError(error) }}<br />
        </ng-container>
      </mat-error>
    </ng-container>
    <ng-container *ngIf="errorMessages && errorMessages.length > 0">
      <mat-error [@fadeInOut] class="app-validation-alert ng-animate">
        <ng-container *ngFor="let error of errorMessages | keyvalue">
          *&nbsp;{{ parseError(error) }}<br />
        </ng-container>
      </mat-error>
    </ng-container>
  `,
  styles: [
    `
          .app-validation-alert {
            // background: linear-gradient(to bottom, rgba(255, 0, 0, 0.6) 15%, #ff0000 70%, rgba(255, 0, 0, 0.6));
            background-color: #ff0a00;
            color: #fff7e9;
            font-size: 80%;
            padding: 5px;
            margin-top: 10px;
            border-radius: 5px;
            box-shadow: 0 0 5px 0 #ff0a00;
          }
        `
  ],
  animations: [AppAnimationFactory.fadeInOut(250, 250)]
})
export class AppErrorDisplayComponent {
  @Input() form?: FormGroup;
  @Input() controlName: string;
  // if errorMessages are passed in, they will be used instead of the form's errors
  // this is useful for when the form control is not tied to a dto field directly
  // The errorMessages passed in should be the result of a call to the validate method from
  // class-validator. The error key is the name of the field to be read from the constraints array
  // object. If the error key is not passed in, the error key will be the controlName.
  @Input() errorMessages?: any;
  @Input() errorKey?: string;
  @Input() showOnErrors?: boolean = false;

  constructor() {}

  parseError(error: any): string {
    if (error.value && error.value.constraints) {
      const key = this.errorKey || this.controlName;
      const errors = error.value.constraints;
      return errors[key];
    } else if (error && error.value instanceof Object && error.value.text) {
      return `${error.value.text} is not valid here`;
    } else if (error && error.key == 'matDatepickerParse') {
      return 'must be a valid date';
    } else if (error && !(error.value instanceof Object) && error.value) {
      return error.value;
    }
  }
}
