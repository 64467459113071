import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { RouterLink } from '@angular/router';
import { AppInterventionsListComponent } from './interventions-list/interventions-list.component';
import { AppInterventionsListFiltersComponent } from './interventions-list/interventions-list-filters/interventions-list-filters.component';
import { AppInterventionsListTableComponent } from './interventions-list/interventions-list-table/interventions-list-table.component';
import { AppInterventionCreateEditComponent } from './interventions-create-edit/intervention-create-edit.component';
import { DirectiveModule } from '../../shared/directives/directive.module';

@NgModule({
  declarations: [
    AppInterventionsListComponent,
    AppInterventionsListFiltersComponent,
    AppInterventionsListTableComponent,
    AppInterventionCreateEditComponent,
  ],
  exports: [],
  imports: [
    AppMaterialModule,
    FontAwesomeModule,
    AppPaginationModule,
    ReactiveFormsModule,
    AppErrorDisplayComponent,
    RouterLink,
    DirectiveModule,
  ],
})
export class AppInterventionsModule {}
