<!-- Mat Select -->
<mat-select
  *ngIf="!useListStyle"
  (opened)="handleSelectOpened()"
  (closed)="handleSelectClosed()"
  [placeholder]="getSelectedString()"
  [value]="areAllOptionsSelected() ? [-1] : []"
  [disabled]="disabled"
  [ngClass]="{
    'form-control': true,
    'mat-select-error': (!selected?.length && !canBeEmpty) || errorMode,
    'empty-select': !selected?.length
  }"
  multiple
>
  <mat-select-trigger> {{ getSelectedString() }} </mat-select-trigger>

  <!-- Autocomplete Input -->
  <div *ngIf="useAutocomplete" class="autocomplete-input">
    <input
      #autocompleteInput
      class="form-control"
      [placeholder]="autocompletePlaceholder"
      [(ngModel)]="filterKey"
      (ngModelChange)="filterOptions()"
    />
  </div>

  <!-- Select All Toggle -->
  <mat-option
    (click)="toggleSelectAll()"
    [value]="-1"
    [ngClass]="{'hidden': disableSelectAll}"
    class="mat-primary"
  >
    Select All
  </mat-option>

  <!-- Options Loop -->
  <div
    *ngFor="let option of filteredOptions"
    (click)="toggleOption(option)"
    [ngClass]="{
      'option': true,
      'selected': selectedDictionary[option.value]
    }"
  >
    <div class="option-checkbox">
      <i class="fa fa-check"></i>
    </div>
    {{ option.display }}
    <i
      *ngIf="option.description"
      class="fa fa-question-circle clickable custom-popover"
      [appCustomPopover]
      data-toggle="popover"
      title="Settings Help Text"
      [attr.data-content]="option.description"
    ></i>
  </div>
</mat-select>

<!-- List Multiple Select -->
<div
  *ngIf="useListStyle"
  class="form-control multi-select-list"
  [class.scrolling-list]="scrollOverflow"
  [class.visible-scrollbar]="scrollOverflow"
>
  <!-- Control Buttons -->
  <div *ngIf="showListControlButtons" class="list-buttons">
    <!-- View Selected -->
    <button
      *ngIf="!viewingSelectedOptions"
      class="btn btn-outline-gray"
      (click)="viewSelected()"
    >
      View Selected
    </button>

    <button
      *ngIf="viewingSelectedOptions"
      class="btn btn-outline-gray"
      (click)="viewAll()"
    >
      View All
    </button>

    <!-- Select / Deselect All -->
    <button
      class="btn btn-outline-gray"
      (click)="selectAll(); viewAll(); handleListChanged()"
    >
      Select All
    </button>

    <!-- Deselect All -->
    <button
      class="btn btn-outline-gray"
      (click)="deselectAll(); viewAll(); handleListChanged()"
    >
      Clear
    </button>
  </div>

  <!-- Autocomplete Input -->
  <div *ngIf="useAutocomplete" class="autocomplete-input">
    <input
      class="form-control"
      [placeholder]="autocompletePlaceholder"
      [(ngModel)]="filterKey"
      (ngModelChange)="filterOptions()"
    />
  </div>

  <!-- Options Loop -->
  <ng-container *ngFor="let option of filteredOptions">
    <!-- Option Divider -->
    <div *ngIf="option.isDivider" class="option-divider">
      {{ option.display }}
    </div>

    <!-- Normal Option -->
    <div
      *ngIf="!option.isDivider"
      (click)="toggleOption(option); handleListChanged()"
      [ngClass]="{
      'option': true,
      'selected': selectedDictionary[option.value]
    }"
    >
      <div class="option-checkbox">
        <i class="fa fa-check"></i>
      </div>
      {{ option.display }}
      <i
        *ngIf="option.description"
        class="fa fa-question-circle clickable custom-popover"
        [appCustomPopover]
        data-toggle="popover"
        title="Settings Help Text"
        [attr.data-content]="option.description"
      ></i>
    </div>
  </ng-container>
</div>
