"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordResetTemplateVariablesDto = void 0;
const base_template_variables_dto_1 = require("../notification-templates/base-template-variables.dto");
class PasswordResetTemplateVariablesDto extends base_template_variables_dto_1.BaseTemplateVariablesDto {
    constructor(passwordResetTemplateVariablesOptions) {
        super();
        Object.assign(this, passwordResetTemplateVariablesOptions);
    }
    firstName;
    lastName;
    email;
    link;
    getFields() {
        return [...super.getFields(), 'firstName', 'lastName', 'email', 'link'];
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
}
exports.PasswordResetTemplateVariablesDto = PasswordResetTemplateVariablesDto;
