import { Component } from '@angular/core';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BellScheduleTeacherPeriodResponseDto,
  TeacherRostersByPeriodsFilterDto
} from '@whetstoneeducation/hero-common';
import { logger } from '../../../shared/logger';
import { MatDialog } from '@angular/material/dialog';
import { AppTeacherRostersService } from '../teacher-rosters.service';
import { AppStudentsService } from '../../students/students.service';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { bulkStudentPasswordResetModal } from '../../../shared/menu/common-menu-helpers/bulk-action.helpers';
import { TrackableStudentList } from '../../../shared/trackable-student-list/trackable-student-list.component';
import { AppPrivilegesService } from '../../auth/privileges.service';

@Component({
  selector: 'app-teacher-rosters-list',
  templateUrl: './teacher-rosters-list.template.html',
  styleUrls: ['./teacher-rosters-list.scss']
})
export class AppTeacherRostersListComponent extends TrackableStudentList {
  public filters: TeacherRostersByPeriodsFilterDto;
  public periods: BellScheduleTeacherPeriodResponseDto[];

  constructor(
    public toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public teacherRostersService: AppTeacherRostersService,
    public studentsService: AppStudentsService,
    public dialog: MatDialog,
    public confirmationDialogService: AppConfirmationDialogService,
    public privilegesService: AppPrivilegesService
  ) {
    super(
      dialog,
      confirmationDialogService,
      toastService,
      studentsService,
      privilegesService
    );
    this.loadData();
  }

  public loadData(): void {
    const { teacherPeriods, students } = this.route.snapshot.data.data;

    this.periods = teacherPeriods;

    if (students) {
      this.students = students.results;
      this.filters = this.teacherRostersService.getTeacherRostersDefaultFilters(
        {
          periodIds: students.options.periodIds
        }
      );
      this.setPeriodIdQuery();
      this.doSort();
    }
  }

  public async updateFilters(
    newFilters: TeacherRostersByPeriodsFilterDto
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    await this.updateStudentsList(this.filters);
    this.selectedStudentsIds = [];
    this.setPeriodIdQuery();
    this.isLoading = false;
  }

  private setPeriodIdQuery(): void {
    if (this.filters.periodIds) {
      this.router.navigate([], {
        queryParams: {
          periodId: this.filters.periodIds
        }
      });
    }
  }

  public async updateStudentsList(
    filters: TeacherRostersByPeriodsFilterDto
  ): Promise<void> {
    try {
      const newData =
        await this.teacherRostersService.getTeacherRosters(filters);
      this.students = newData.results;
      this.doSort();
    } catch (err) {
      this.toastService.error('Error retrieving students!');
      logger.error(err);
    }
  }
}
