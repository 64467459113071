"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationDetailsDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class PaginationDetailsDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.page = options.page;
        this.itemsPerPage = options.itemsPerPage;
        this.totalItems = options.totalItems;
        this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
        this.hasPrevious = this.page > 1;
        this.hasNext = this.page < this.totalPages;
        this.lastId = options?.lastId ?? 0;
    }
    getFields() {
        return [
            'page',
            'itemsPerPage',
            'totalItems',
            'lastId',
            'hasPrevious',
            'hasNext'
        ];
    }
    page;
    itemsPerPage;
    totalItems;
    totalPages;
    lastId;
    hasPrevious;
    hasNext;
}
exports.PaginationDetailsDto = PaginationDetailsDto;
