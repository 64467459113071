import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  PaginationResultsDto,
  StudentGroupCreateDto,
  StudentGroupResponseDto,
  StudentGroupUpdateDto
} from '@whetstoneeducation/hero-common';
import { AppStudentsService } from '../students/students.service';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { AppBellSchedulesService } from '../bell-schedules/bell-schedules.service';
import { IStudentGroupsCreateEditResolverData } from './student-groups-create-edit/student-groups-create-edit-resolver-data.interface';
import { ITableFilters } from '../../shared/tables/table-filters/table-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppStudentGroupsService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private studentsService: AppStudentsService,
    private bellSchedulesService: AppBellSchedulesService
  ) {
    super();
  }

  public async getStudentGroupById(
    id: number
  ): Promise<StudentGroupResponseDto> {
    return this.appClientDataService.execute<StudentGroupResponseDto>(
      this.GET_ROUTES.STUDENT_GROUP_BY_ID,
      { pathParams: { id } },
      StudentGroupResponseDto
    );
  }

  public async getStudentGroupCreateEditResolverData(
    id: string
  ): Promise<IStudentGroupsCreateEditResolverData> {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    let studentGroup;
    if (id === 'new') {
      studentGroup = new StudentGroupResponseDto();
    } else {
      studentGroup = await this.getStudentGroupById(+id);
    }
    const bellSchedulesCall = this.bellSchedulesService.getBellSchedulesList(
      schoolId,
      TableFilterOptions.getPageDefault(TableFiltersPageKeyEnum.STUDENTS_LIST)
    );
    const studentOptionsCall = this.studentsService.getStudentsList({
      schoolId,
      isInStudentGroup: false,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.STUDENTS_LIST
      )
    });

    return {
      studentGroup,
      bellScheduleOptions: await bellSchedulesCall,
      studentOptions: await studentOptionsCall
    };
  }

  public async createStudentGroup(
    studentGroup: StudentGroupCreateDto
  ): Promise<StudentGroupResponseDto> {
    return this.appClientDataService.execute<StudentGroupResponseDto>(
      this.POST_ROUTES.CREATE_STUDENT_GROUP,
      {
        body: studentGroup,
        method: this.METHOD.POST
      },
      StudentGroupResponseDto
    );
  }

  public async updateStudentGroup(
    studentGroup: StudentGroupUpdateDto,
    id: number
  ): Promise<StudentGroupResponseDto> {
    return this.appClientDataService.execute<StudentGroupResponseDto>(
      this.PUT_ROUTES.UPDATE_STUDENT_GROUP,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: studentGroup
      },
      StudentGroupResponseDto
    );
  }

  public async getStudentGroupsList(
    schoolId: number,
    tableFilters: ITableFilters
  ): Promise<PaginationResultsDto<StudentGroupResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<StudentGroupResponseDto>
    >(this.GET_ROUTES.STUDENT_GROUPS_LIST, {
      queryParams: {
        schoolId,
        ...(tableFilters.itemsPerPage
          ? { itemsPerPage: tableFilters.itemsPerPage }
          : {}),
        ...(tableFilters.page ? { page: tableFilters.page } : {})
      }
    });
  }

  public async getAllStudentGroups(
    schoolId: number
  ): Promise<StudentGroupResponseDto[]> {
    return this.appClientDataService.execute<StudentGroupResponseDto[]>(
      this.GET_ROUTES.STUDENT_GROUPS_LIST_UNPAGINATED,
      {
        queryParams: {
          ...(schoolId ? { schoolId } : {}),
        }
      }
    );
  }

  public async deleteStudentGroup(id: number) {
    return this.appClientDataService.execute(
      this.DELETE_ROUTES.DELETE_STUDENT_GROUP,
      {
        method: this.METHOD.DELETE,
        pathParams: { id }
      }
    );
  }
}
