import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { IBehaviorCodeReactionsFilters } from './behavior-code-reactions-models/behavior-code-reactions-filters.interface';
import {
  BehaviorCodeReactionCreateDto,
  BehaviorCodeReactionResponseDto,
  BehaviorCodeReactionUpdateDto,
  PaginationResultsDto,
  RelationDto
} from '@whetstoneeducation/hero-common';
import { Injectable } from '@angular/core';
import { AppReactionsService } from '../reactions/reactions.service';
import { plainToInstance } from 'class-transformer';
import { IBehaviorCodeReactionCreateEditResolverData } from './behavior-code-reactions-models/behavior-code-reaction-create-edit-resolver-data.interface';

@Injectable({
  providedIn: 'root'
})
export class AppBehaviorCodeReactionsService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private reactionsService: AppReactionsService
  ) {
    super();
  }

  public async getBehaviorCodeReactionsList(
    filters: IBehaviorCodeReactionsFilters
  ): Promise<PaginationResultsDto<BehaviorCodeReactionResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<BehaviorCodeReactionResponseDto>
    >(this.GET_ROUTES.BEHAVIOR_CODE_REACTIONS_LIST, {
      queryParams: {
        ...(filters.behaviorCodeId
          ? { behaviorCodeId: filters.behaviorCodeId }
          : {}),
        ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
        ...(filters.reactionId ? { reactionId: filters.reactionId } : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {}),
        ...(filters.active != null ? { active: filters.active } : {})
      }
    });
  }

  public async getBehaviorCodeReactionById(
    id: number
  ): Promise<BehaviorCodeReactionResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeReactionResponseDto>(
      this.GET_ROUTES.BEHAVIOR_CODE_REACTION_BY_ID,
      {
        pathParams: { id }
      }
    );
  }

  public async getBehaviorCodeReactionsCreateEditResolverData(
    behaviorCodeId: string,
    behaviorCodeReactionId: string
  ): Promise<IBehaviorCodeReactionCreateEditResolverData> {
    const user = this.StorageManager.getLoggedInUser();

    const reactionsCall = this.reactionsService.getReactionsList({
      schoolId: user.currentSchoolId,
      searchInput: null,
      tableFilters: {
        itemsPerPage: 1000,
        page: 1,
        count: null,
        active: null,
        direction: 'asc'
      },
      inactive: false
    });
    const behaviorCodeReactionCall =
      behaviorCodeReactionId !== 'new'
        ? this.getBehaviorCodeReactionById(+behaviorCodeReactionId)
        : null;

    const behaviorCodeReaction =
      behaviorCodeReactionId === 'new'
        ? plainToInstance(BehaviorCodeReactionResponseDto, {
            triggerCount: 0,
            printCopies: 0,
            reactionMultiplier: 1,
            points: 0,
            behaviorCodeId: +behaviorCodeId,
            schoolId: user.currentSchoolId,
            reaction: null,
            active: true
          })
        : await behaviorCodeReactionCall;

    const reactions = await reactionsCall;
    const reactionOptions = reactions.results.map((reaction) => {
      return { value: reaction.id, display: reaction.name };
    });

    return {
      reactionOptions,
      behaviorCodeReaction
    };
  }

  public async createBehaviorCodeReaction(
    behaviorCodeReaction: BehaviorCodeReactionCreateDto
  ): Promise<BehaviorCodeReactionResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeReactionResponseDto>(
      this.POST_ROUTES.CREATE_BEHAVIOR_CODE_REACTION,
      {
        method: this.METHOD.POST,
        body: behaviorCodeReaction
      }
    );
  }

  public async updateBehaviorCodeReaction(
    behaviorCodeReaction: BehaviorCodeReactionUpdateDto,
    id: number
  ): Promise<BehaviorCodeReactionResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeReactionResponseDto>(
      this.PUT_ROUTES.UPDATE_BEHAVIOR_CODE_REACTION,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: behaviorCodeReaction
      }
    );
  }
}
