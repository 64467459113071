import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  ITemplateContext
} from '../../../shared/tables/common-table/base-common-table.component';
import {
  DateUtil,
  ExportSettingsUpdateDto,
  LastExportsResponseDto,
} from '@whetstoneeducation/hero-common';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { AppExportService } from '../export.service';
import { AppConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';

export enum TopExportsColumns {
  EXPORT_NAME = 'Export Name',
  SAVE_TO = 'Save To',
  LAST_RUN = 'Last Run',
  SCHEDULE = 'Schedule',
  ACTIVE = 'Active',
  ACTIONS = 'Actions'
}

interface IActionsColumnContext {
  row: LastExportsResponseDto;
}

@Component({
  selector: 'app-last-exports',
  templateUrl: './last-exports.template.html',
  styleUrls: ['./last-exports.scss']
})
export class AppLastExportsComponent implements OnInit, AfterViewInit
{
  @ViewChild('actionsColumn')
  public actionsColumn: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  @ViewChild('activeSlideToggle')
  public activeSlideToggle: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  public columns: typeof TopExportsColumns = TopExportsColumns;
  public templates: { [key: string]: TemplateRef<any> } = {};
  public isLoading: boolean = false;

  dataArr: LastExportsResponseDto[]

  public constructor(
    public router: Router,
    public dialog: MatDialog,
    private readonly exportsService: AppExportService,
    private readonly toastService: AppToastManagerService
  ) {
  }

  public async ngOnInit() {
    await this.loadData();
  }

  public ngAfterViewInit(): void {
    if (this.actionsColumn) {
      this.templates['actionsColumn'] = this.actionsColumn;
    }

    if (this.activeSlideToggle) {
      this.templates['activeSlideToggle'] = this.activeSlideToggle;
    }
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    this.dataArr = await this.exportsService.getTopExports();
    this.isLoading = false;
  }

  public clickEditExport(row: LastExportsResponseDto): void {
    this.router.navigate(['export', 'settings', row.settingsId]);
  }

  public async clickDeleteExport(row: LastExportsResponseDto): Promise<void> {
    this.dialog.open(AppConfirmationDialogComponent, {
     data: {
      title: 'Are you sure you want to delete this export?',
      content: 'Deleting this export will permanently remove it. This action cannot be undone.',
      actions: [
        {
          key: 'cancel',
          label: 'Cancel',
          color: 'primary',
          classes: 'modal-delete-button'
        },
        {
          key: 'delete',
          label: 'Yes, I am sure',
          color: 'primary',
          classes: 'modal-submit-button',
          awaitAction: true,
          action: async () => {
            await this.exportsService.deleteExportSettings(row.settingsId);
            await this.loadData();
            this.toastService.success('Export deleted');
          }
        }
      ]
     }
    });
  }

  public async clickRunExport(row: LastExportsResponseDto): Promise<void> {
    await this.exportsService.runExport(row.settingsId);
    this.toastService.info('Export job started');
  }

  public getColumnValue(
    column: TopExportsColumns,
    row: LastExportsResponseDto
  ): any {
    switch (column) {
      case TopExportsColumns.EXPORT_NAME:
        return row.exportName  || 'N/A';
      case TopExportsColumns.SAVE_TO:
        return row.saveTo || 'N/A';
      case TopExportsColumns.LAST_RUN:
        if (!row.lastRunDate) {
          return 'N/A';
        }
        return `${DateUtil.convertTimestampToReadableDate(+row.lastRunDate)} at ${DateUtil.convertTimestampToDisplayTime(+row.lastRunDate)}`;
      case TopExportsColumns.SCHEDULE:
        const titleCasePipe = new TitleCasePipe();
        return titleCasePipe.transform(row.schedule.replace('-', ' '));
      case TopExportsColumns.ACTIONS:
        return {
          context: {
            row
          },
          template: this.templates.actionsColumn
        };
        case TopExportsColumns.ACTIVE:
        return {
          context: {
            row
          },
          template: this.templates.activeSlideToggle
        };
      default:
        return '';
    }
  }

  async changeActive(row: LastExportsResponseDto): Promise<void> {
    const settings = new ExportSettingsUpdateDto();
    row.active = !row.active;
    settings.id = row.settingsId;
    settings.active = row.active;
    await this.exportsService.updateExportSettings(settings);
  }
}
