import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { IComplianceListFilters } from '../compliance-list-models/compliance-list-filters.interface';
import { IDisplayData } from '@whetstoneeducation/hero-common';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-compliance-list-filters',
  templateUrl: './compliance-list-filter.template.html',
  styleUrls: ['./compliance-list-filter.scss']
})
export class AppComplianceListFiltersComponent extends BaseComponent {
  @Input() filters: IComplianceListFilters;

  @Input() reactionOptions: IDisplayData[];

  @Input() startDate: Date;

  @Input() endDate: Date;

  @Output() filtersUpdated: EventEmitter<IComplianceListFilters>;

  public complianceOptions: IDisplayData[] = [
    { value: false, display: 'Not Complied' },
    { value: true, display: 'Complied' },
    { value: undefined, display: 'All' }
  ];

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<IComplianceListFilters>();
  }

  public async onFiltersUpdated(
    field: string,
    event: MatOptionSelectionChange
  ) {
    if (event.isUserInput) {
      this.filters[field] = event.source.value;
      this.filtersUpdated.emit(this.filters);
    }
  }

  onStartDateUpdated(event: MatDatepickerInputEvent<Date>) {
    const startDate = event.target.value;
    // set start date to 12:00am
    startDate.setHours(0, 0, 0, 0);
    this.filters.startDate = startDate.getTime();
    this.filtersUpdated.emit(this.filters);
  }

  onEndDateUpdated(event: MatDatepickerInputEvent<Date>) {
    const endDate = event.target.value;
    // set end date to 11:59pm
    endDate.setHours(23, 59, 59, 999);
    this.filters.endDate = endDate.getTime();
    this.filtersUpdated.emit(this.filters);
  }
}
