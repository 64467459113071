import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  BehaviorCodeNotificationCreateDto,
  BehaviorCodeNotificationUpdateDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBehaviorCodeNotificationsService } from '../behavior-code-notifications.service';
import { plainToInstance } from 'class-transformer';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { IBehaviorCodeNotificationsCreateEditResolverData } from '../behavior-code-notifications-models/behavior-code-notifications-create-edit-resolver-data.interface';
import { logger } from '../../../shared/logger';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';

@Component({
  selector: 'app-behavior-code-notification-create-edit',
  templateUrl: './behavior-code-notifications-create-edit.template.html',
  styleUrls: ['./behavior-code-notifications-create-edit.scss']
})
export class AppBehaviorCodeNotificationsCreateEditComponent
  extends BaseComponent
  implements AfterViewInit
{
  public notificationOptions: IDisplayData[];
  public behaviorCodeId: number;
  public behaviorCodeNotification:
    | BehaviorCodeNotificationCreateDto
    | BehaviorCodeNotificationUpdateDto;
  public isCreating: boolean;
  public behaviorCodeNotificationForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public behaviorCodeNotificationId: number;
  public pageHeaderSubscription: Subscription;
  public isDefault: boolean = false;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public behaviorCodeNotificationService: AppBehaviorCodeNotificationsService,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data: IBehaviorCodeNotificationsCreateEditResolverData =
      this.route.snapshot.data.data;
    this.behaviorCodeNotificationId =
      +this.route.snapshot.params.behaviorCodeNotificationId;
    this.behaviorCodeId = +this.route.snapshot.params.id;
    this.notificationOptions = data.notificationOptions;
    const behaviorCodeNotification = data.behaviorCodeNotification;
    this.isCreating = !behaviorCodeNotification.id;
    if (this.isCreating) {
      this.behaviorCodeNotification = plainToInstance(
        BehaviorCodeNotificationCreateDto,
        behaviorCodeNotification,
        {
          exposeUnsetFields: true
        }
      );
      this.behaviorCodeNotification.triggerCount = 1;
    } else {
      this.behaviorCodeNotification = plainToInstance(
        BehaviorCodeNotificationUpdateDto,
        behaviorCodeNotification
      );
    }
    this.behaviorCodeNotificationForm = dtoToFormGroup(
      this.behaviorCodeNotification,
      this.formBuilder,
      {
        mapId: !this.isCreating
      }
    );

    if (this.behaviorCodeNotification.triggerCount === 0) {
      this.isDefault = true;
    }

    this.isLoading = false;
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.behaviorCodeNotificationForm.valueChanges.subscribe(
        async (value) => {
          if (this.isCreating) {
            this.behaviorCodeNotification =
              await validateAndGetValue<BehaviorCodeNotificationCreateDto>(
                this.behaviorCodeNotificationForm,
                BehaviorCodeNotificationCreateDto
              );
          } else {
            this.behaviorCodeNotification =
              await validateAndGetValue<BehaviorCodeNotificationUpdateDto>(
                this.behaviorCodeNotificationForm,
                BehaviorCodeNotificationUpdateDto
              );
          }
        }
      )
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public async saveChanges() {
    if (formCanSave(this.behaviorCodeNotificationForm, this.toastManager)) {
      try {
        if (
          this.behaviorCodeNotification instanceof
          BehaviorCodeNotificationCreateDto
        ) {
          const behaviorCodeNotification =
            await this.behaviorCodeNotificationService.createBehaviorCodeNotification(
              this.behaviorCodeNotification
            );
          this.behaviorCodeNotification = plainToInstance(
            BehaviorCodeNotificationCreateDto,
            behaviorCodeNotification
          );
          await this.router.navigate(['/behavior-codes', this.behaviorCodeId]);
        } else {
          this.behaviorCodeNotification =
            await this.behaviorCodeNotificationService.updateBehaviorCodeNotification(
              this.behaviorCodeNotification,
              this.behaviorCodeNotificationId
            );
        }
        this.toastManager.success(
          'Behavior Code Notification saved successfully!'
        );
      } catch (err) {
        logger.error(err);

        if (err?.status === 409) {
          this.toastManager.error(err.error.message);
        } else {
          this.toastManager.error(
            'There was an error saving Behavior Code Notification, please try again!'
          );
        }
      }
    }
  }
}
