<div class="students-list-content">
  <div class="no-school-or-school-group" *ngIf="!hasSelectedSchool">
    The students page can only be accessed for an individual school. Please
    switch to one of those and try again!
  </div>
  <div class="content" *ngIf="hasSelectedSchool">
    <app-students-list-filters
      [filters]="filters"
      (filtersUpdated)="updateFilters($event)"
      [selectedStudentIds]="selectedStudentsIds"
      (showFastTrackModalEvent)="showFastTrackModal()"
      (showTrackModalEvent)="showTrackModal()">
    </app-students-list-filters>
    <div class="students-list-container">
      <app-student-list-card
        [students]="students"
        [tableFilters]="filters.tableFilters"
        (showPasswordResetModalEvent)="showPasswordResetModal()"
        (showAllPasswordResetModalEvent)="showAllPasswordResetModal()"
        (showBulkStudentProfileUploadModal)="showBulkStudentProfileUploadModal()"
        (tableFiltersUpdated)="updateTableFilters($event)"
        (selectStudent)="toggleStudentSelected($event)"
        [selectedStudentsIds]="selectedStudentsIds">
      </app-student-list-card>
    </div>
  </div>
</div>
