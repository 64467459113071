"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BellScheduleResponseDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const bell_schedule_periods_1 = require("../bell-schedule-periods");
const bell_schedule_period_times_1 = require("../bell-schedule-period-times");
class BellScheduleResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.name = options.name;
        this.description = options.description;
        this.schoolId = options.schoolId;
    }
    getFields() {
        return ['id', 'name', 'description', 'schoolId', 'bellSchedulePeriods'];
    }
    id;
    name;
    description;
    schoolId;
    bellSchedulePeriodTimes;
    static mapBellSchedules(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, BellScheduleResponseDto);
    }
    getTransforms() {
        return [
            {
                destination: 'bellSchedulePeriodTimes',
                transform: (source) => {
                    // for each bell schedule period time, map the bell schedule period
                    const result = [];
                    const times = source.bellSchedulePeriodTimes;
                    if (!times)
                        return result;
                    for (const time of times) {
                        const bsptr = new bell_schedule_period_times_1.BellSchedulePeriodTimeResponseDto({
                            id: time.id,
                            start: time.start,
                            end: time.end,
                            bellSchedulePeriodId: time.bellSchedulePeriodId,
                            bellScheduleId: time.bellScheduleId
                        });
                        if (!time.bellSchedulePeriod) {
                            result.push(bsptr);
                            continue;
                        }
                        bsptr.bellSchedulePeriod = new bell_schedule_periods_1.BellSchedulePeriodResponseDto({
                            id: time.bellSchedulePeriod.id,
                            name: time.bellSchedulePeriod.name,
                            referenceCode: time.bellSchedulePeriod.referenceCode,
                            extPeriodCode: time.bellSchedulePeriod.extPeriodCode,
                            schoolId: time.bellSchedulePeriod.schoolId
                        });
                        result.push(bsptr);
                    }
                    return result;
                }
            }
        ];
    }
}
exports.BellScheduleResponseDto = BellScheduleResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BellScheduleResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], BellScheduleResponseDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], BellScheduleResponseDto.prototype, "description", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BellScheduleResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => bell_schedule_period_times_1.BellSchedulePeriodTimeResponseDto),
    __metadata("design:type", Array)
], BellScheduleResponseDto.prototype, "bellSchedulePeriodTimes", void 0);
