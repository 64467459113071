"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpAccountCreateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const is_group_level_decorator_1 = require("../shared/is-group-level.decorator");
const boolean_transform_1 = require("../../constants/boolean.transform");
class SftpAccountCreateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['schoolGroupId', 'isSchoolGroupLevel', 'schoolId'];
    }
    schoolGroupId;
    // School must be selected unless group level is true
    isSchoolGroupLevel;
    schoolId;
}
exports.SftpAccountCreateDto = SftpAccountCreateDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)({ message: 'You must select a School Group' }),
    __metadata("design:type", Number)
], SftpAccountCreateDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SftpAccountCreateDto.prototype, "isSchoolGroupLevel", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.Validate)(is_group_level_decorator_1.IsGroupLevelConstraint, {
        message: 'You must select a School unless Group Level sharing is enabled'
    }),
    __metadata("design:type", Number)
], SftpAccountCreateDto.prototype, "schoolId", void 0);
