"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportStatus = void 0;
var ExportStatus;
(function (ExportStatus) {
    ExportStatus["PENDING"] = "pending";
    ExportStatus["PROCESSING"] = "processing";
    ExportStatus["COMPLETED"] = "completed";
    ExportStatus["COMPLETED_WITH_ERRORS"] = "complete-with-errors";
    ExportStatus["FAILED"] = "Failed";
    ExportStatus["FAILED_WITH_ERRORS"] = "failed-with-errors";
    ExportStatus["CANCELLED"] = "cancelled";
})(ExportStatus || (exports.ExportStatus = ExportStatus = {}));
