import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  IDisplayData,
  NotificationTemplateFilterDto,
  NotificationTemplateResponseDto
} from '@whetstoneeducation/hero-common';
import { INotificationTemplateFilters } from './template-list-models/notification-template-list-filters.interface';
import { Subscription } from 'rxjs';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNotificationService } from '../notification.service';
import { INotificationTemplateListResolverData } from './template-list-models/notification-template-list-resolver-data.interface';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { logger } from '../../../shared/logger';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';

@Component({
  selector: 'app-notification-template-list',
  templateUrl: './notification-template-list.template.html',
  styleUrls: ['./notification-template-list.scss']
})
export class AppNotificationTemplateListComponent
  extends BaseComponent
  implements OnDestroy
{
  public notificationTemplates: NotificationTemplateResponseDto[];
  public filters: INotificationTemplateFilters;
  public schoolGroupOptions: IDisplayData[] = [];
  public schoolOptions: IDisplayData[] = [];
  public currentUser = this.StorageManager.getLoggedInUser();
  private pageHeaderSubscription: Subscription;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public notificationServices: AppNotificationService,
    public pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  private loadData(): void {
    const data = this.route.snapshot.data
      .data as INotificationTemplateListResolverData;
    this.notificationTemplates = data.paginationResults.results;
    this.filters = data.defaultFilters;
    this.setupFilterOptions();
    this.filters.tableFilters.count = data.paginationResults.options.totalItems;
    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.CREATE) {
            await this.router.navigate(['notification-templates', 'new']);
          }
        }
      );
  }

  private setupFilterOptions(): void {
    this.schoolGroupOptions = this.currentUser.schoolGroups.map((sg) => {
      return {
        value: sg.id,
        display: sg.name
      };
    });
    if (this.currentUser.schoolGroupId) {
      this.filters.schoolGroupId = this.currentUser.schoolGroupId;
      const schools = this.currentUser.schoolGroups.find(
        (sg) => sg.id === this.currentUser.schoolGroupId
      ).schools;
      this.schoolOptions = schools.map((s) => {
        return {
          value: s.id,
          display: s.name
        };
      });
    }
    if (this.currentUser.currentSchoolId) {
      this.filters.schoolId = this.currentUser.currentSchoolId;
    }
  }

  public async updateFilters(
    newFilters: INotificationTemplateFilters
  ): Promise<void> {
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.NOTIFICATION_SETTINGS_LIST,
      this.filters.tableFilters
    );
    await this.updateNotificationTemplateList(this.filters);
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.filters.tableFilters = newTableFilters;
    await this.updateNotificationTemplateList(this.filters);
    this.isLoading = false;
  }

  public async updateNotificationTemplateList(
    filters: INotificationTemplateFilters
  ): Promise<void> {
    try {
      this.isLoading = true;
      const results =
        await this.notificationServices.getTemplateList(filters);
      this.notificationTemplates = results.results;
      this.filters.tableFilters.count = results.options.totalItems;
    } catch (e) {
      logger.error(e);
      this.toastService.error('Error retrieving notifications template list!');
    } finally {
      this.isLoading = false;
    }
  }

  public async ngOnDestroy(): Promise<void> {
    this.pageHeaderSubscription.unsubscribe();
  }
}
