"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolGroupSettings = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
class SchoolGroupSettings extends mappable_base_1.MappableBase {
    constructor(settings) {
        super();
        if (!settings)
            return;
        Object.assign(this, settings);
    }
    getFields() {
        return ['timezone'];
    }
    timezone;
}
exports.SchoolGroupSettings = SchoolGroupSettings;
__decorate([
    (0, class_validator_1.IsString)({ message: 'School Group requires a valid timezone' }),
    (0, class_validator_1.IsNotEmpty)({ message: 'School Group requires a valid timezone' }),
    __metadata("design:type", String)
], SchoolGroupSettings.prototype, "timezone", void 0);
