import { ICustomRoute } from '../../app-routing.module';
import { AppReactionsListComponent } from './reactions-list/reactions-list.component';
import { AppReactionsListResolver } from './reactions-list/reactions-list.resolver';
import { AuthGuard } from '../../route-guards/auth.guard';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { AppReactionsCreateComponent } from './reactions-create/reactions-create.component';
import { AppReactionsCreateEditResolver } from './reactions-create/reactions-create.resolver';
import { AppReactionsEditComponent } from './reactions-edit/reactions-edit.component';
import { AppReactionsEditResolver } from './reactions-edit/reactions-edit.resolver';

export const AppReactionsRoutes: ICustomRoute[] = [
  {
    path: 'reactions',
    title: 'Reactions',
    component: AppReactionsListComponent,
    resolve: { data: AppReactionsListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Reactions',
      buttons: [
        {
          name: 'Manage Behavior Codes',
          normalizedRoute: 'behavior-codes',
          style: 'outline-button'
        },
        {
          name: 'Add',
          normalizedRoute: 'reactions/new',
          icon: 'plus-circle',
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'reactions/new',
    title: 'Reaction',
    component: AppReactionsCreateComponent,
    resolve: { data: AppReactionsCreateEditResolver },
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {
      title: 'Create Reaction',
      breadcrumbs: [
        {
          title: 'Reactions',
          path: 'reactions'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  },
  {
    path: 'reactions/:reactionId',
    title: 'Reaction',
    component: AppReactionsEditComponent,
    resolve: { data: AppReactionsEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Edit Reaction',
      breadcrumbs: [
        {
          title: 'Reactions',
          path: 'reactions'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  }
];
