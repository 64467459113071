import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { BehaviorCodeEntryResponseDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-confirmed-scheduled-reactions',
  templateUrl: './confirmed-scheduled-reactions.template.html',
  styleUrls: ['./confirmed-scheduled-reactions.scss']
})
export class AppConfirmedScheduledReactionsComponent extends BaseComponent {
  @Input() behaviorCodeEntry: BehaviorCodeEntryResponseDto;
  public constructor() {
    super();
  }
}
