"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsImportMappingConstraint = void 0;
const class_validator_1 = require("class-validator");
let IsImportMappingConstraint = class IsImportMappingConstraint {
    validate(mapping, args) {
        const object = args.object;
        // Check if all keys in the mapping are in the available columns
        const allKeysValid = Object.keys(mapping).every((key) => object.availableColumns.includes(key));
        // Check if all required columns are defined as keys in the mapping
        const allRequiredCovered = object.requiredColumns.every((required) => Object.keys(mapping).includes(required));
        return allKeysValid && allRequiredCovered;
    }
    defaultMessage(args) {
        if (args && args.object) {
            const missingColumns = args.object.requiredColumns.filter((required) => !Object.keys(args.object.mapping).includes(required));
            const extraColumns = Object.keys(args.object.mapping).filter((key) => !args.object.availableColumns.includes(key));
            let message = 'All required columns must be mapped and all keys defined in the mapping must be available columns.';
            if (missingColumns.length) {
                message += ` Missing columns: ${missingColumns.join(', ')}`;
            }
            if (extraColumns.length) {
                message += ` Extra columns: ${extraColumns.join(', ')}`;
            }
            return message;
        }
        return 'Mapping is not defined.';
    }
};
exports.IsImportMappingConstraint = IsImportMappingConstraint;
exports.IsImportMappingConstraint = IsImportMappingConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: 'isImportMapping', async: false })
], IsImportMappingConstraint);
