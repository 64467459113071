import { Component, OnInit } from '@angular/core';
import {
  DateUtil,
  PaginationOptionsDto,
  ReactionEntriesListResponseDto,
  ScheduledReactionResponseDto
} from '@whetstoneeducation/hero-common';
import { IComplianceListFilters } from '../compliance-list/compliance-list-models/compliance-list-filters.interface';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppComplianceService } from '../compliance.service';
import { ActivatedRoute } from '@angular/router';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { IComplianceListResolverData } from '../compliance-list/compliance-list-models/compliance-list-resolver-data.interface';
import { IComplianceByScheduledReactionListResolverData } from './compliance-by-scheduled-reaction.resolver';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';

@Component({
  selector: 'app-compliance-by-scheduled-reaction',
  templateUrl: './compliance-by-scheduled-reaction.template.html',
  styleUrls: ['./compliance-by-scheduled-reaction.scss']
})
export class AppComplianceByScheduledReactionComponent
  extends BaseComponent
  implements OnInit
{
  public reactionEntries: ReactionEntriesListResponseDto[];
  public tableFilters: ITableFilters;
  public hasSelectedSchool: boolean;
  public scheduledReaction: ScheduledReactionResponseDto;

  constructor(
    private toastService: AppToastManagerService,
    private appComplianceService: AppComplianceService,
    public route: ActivatedRoute,
    public pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public ngOnInit(): void {
    this.pageHeaderService.changeTitle(
      `${
        this.scheduledReaction.reaction.name
      } on ${DateUtil.convertTimestampToDisplayDate(
        new Date(this.scheduledReaction.calendarDay.date).getTime()
      )}`
    );
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data
      .data as IComplianceByScheduledReactionListResolverData;
    this.reactionEntries = data.reactionEntries.results;
    this.tableFilters = data.tableFilters;
    this.hasSelectedSchool =
      !!this.StorageManager.getLoggedInUser().currentSchoolId;
    this.scheduledReaction = data.scheduledReaction;

    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.tableFilters = newTableFilters;
    await this.updateReactionEntries(this.tableFilters);
    this.isLoading = false;
  }
  public async updateReactionEntries(filters: ITableFilters): Promise<void> {
    try {
      const reactionEntries =
        await this.appComplianceService.getReactionEntriesByScheduledReaction(
          this.scheduledReaction.id,
          filters
        );
      this.reactionEntries = reactionEntries.results;
      this.tableFilters.count = reactionEntries.options.totalItems;
    } catch (error) {
      this.toastService.error('Error updating reaction entries');
    }
  }
  public async reloadTable() {
    await this.updateReactionEntries(this.tableFilters);
  }
}
