import { DateUtil } from "@whetstoneeducation/hero-common";
import { ReactionPrintBuilder } from "./behavior-code-reaction-print-builder";
import ThermalPrinterEncoder from 'thermal-printer-encoder';


export class ReactionPrintReceiptBuilder extends ReactionPrintBuilder {

  constructor(languageType: string) {
    super(languageType);
  }

  protected setEncoder(languageType: string): void {
    this.encoder = new ThermalPrinterEncoder({language: languageType});
  }

  public initializeEncoder(): void {
    this.encoder.initialize();
  }

  public buildHeader(): ReactionPrintReceiptBuilder {
    this.encoder.bold(true).width(3).height(3).align('center').line(this.data.schoolName).width(1).height(1).bold(false);
    return this;
  }

  public buildSubHeader(): ReactionPrintReceiptBuilder {
    this.encoder.bold(true).width(2).height(2).align('center').line(this.data.behaviorCodeName).width(1).height(1).bold(false).newline();
    return this;
  }

  public buildStudentIdEntries(): ReactionPrintReceiptBuilder {
    this.encoder.align('left').line(`Student #ID: ${this.getStudentId()}`)
      .align('left').line(`Entries: ${this.data.behaviorEntries}`);

    return this;
  }

  public buildStudentName(): ReactionPrintReceiptBuilder {
    const [firstName, lastName] = this.getStudentNames();

    this.encoder.align('left').line(`First Name: ${firstName}`)
      .align('left').line(`Last Name: ${lastName}`);

    return this;
  }

  public buildEntryDateTime(): ReactionPrintReceiptBuilder {
    this.encoder.align('left').line(`Entry Time: ${DateUtil.convertTimestampToDisplayTime(this.data.behaviorEntryDate)}`)
      .align('left').line(`Entry Date: ${DateUtil.convertTimestampToReadableDate(this.data.behaviorEntryDate)}`);

    return this;
  }

  public buildPeriod(): ReactionPrintReceiptBuilder {
    this.encoder.align('left').line(`During: ${this.data.period ?? 'N/A'}`)
    return this;
  }

  public buildActionTypeDate(): ReactionPrintReceiptBuilder {
    this.encoder.align('left').line(`Action Type: ${this.data.behaviorReactionName}`)
      .align('left').line(`Action Date: ${DateUtil.convertTimestampToReadableDate(this.data.behaviorReactionDate)}`);

    return this;
  }

  public buildPoints(): ReactionPrintReceiptBuilder {
    this.encoder.newline().align('center').line(`Points: ${this.data.reactionPoints} Total Points: ${this.data.studentTotalPoints}`);
    return this;
  }

  public buildBarcode(): ReactionPrintReceiptBuilder {
    const studentExtId = this.data.studentExtId;
    if (studentExtId && studentExtId.length) {
      this.encoder.barcode(studentExtId, 'code128', 60);
    }

    return this;
  }

}
