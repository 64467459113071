import { Injectable } from '@angular/core';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppNotificationService } from '../notification.service';
import { INotificationTemplateListResolverData } from './template-list-models/notification-template-list-resolver-data.interface';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';

@Injectable({ providedIn: 'root' })
export class AppNotificationTemplateListResolver extends BaseResolver<INotificationTemplateListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public notificationService: AppNotificationService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<INotificationTemplateListResolverData> {
    return this.notificationService.getTemplateListPageResolverData();
  }
}
