import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorCodeResponseDto } from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../shared/logger';
import { IBehaviorCodesListFilters } from './behavior-codes-list-models/behavior-codes-list-filters.interface';
import { IBehaviorCodeListResolverData } from './behavior-codes-list-models/behavior-codes-list-resolver-data.interface';
import { AppBehaviorCodesService } from '../behavior-codes.service';

@Component({
  selector: 'app-behavior-codes-list',
  templateUrl: './behavior-codes-list.template.html',
  styleUrls: ['./behavior-codes-list.scss']
})
export class AppBehaviorCodesListComponent extends BaseComponent {
  public behaviorCodes: BehaviorCodeResponseDto[];

  public filters: IBehaviorCodesListFilters;

  public hasSelectedSchool: boolean;

  public reorderMode: boolean;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public behaviorCodesService: AppBehaviorCodesService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as IBehaviorCodeListResolverData;
    this.behaviorCodes = data.behaviorCodes.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.behaviorCodes.options.totalItems;
    this.hasSelectedSchool = !!this.filters.schoolId;
    this.reorderMode = false;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateBehaviorCodeList(this.filters);
    this.isLoading = false;
  }

  public async updateBehaviorCodeList(
    filters: IBehaviorCodesListFilters
  ): Promise<void> {
    try {
      const newData = await this.behaviorCodesService.getBehaviorCodeList(
        filters
      );
      this.behaviorCodes = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving behavior codes!');
      logger.error(err);
    }
  }

  public async updateFilters(
    newFilters: IBehaviorCodesListFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.BEHAVIOR_CODE_LIST,
      this.filters.tableFilters
    );
    await this.updateBehaviorCodeList(this.filters);
    this.isLoading = false;
  }

  public async reorderBehaviorCodes(reorderMode: boolean): Promise<void> {
    this.isLoading = true;
    if (reorderMode) {
      // starting reordering - get list of all behavior codes for school that are active, clear other filters
      if (this.filters.schoolId) {
        this.reorderMode = true;
        this.filters.tableFilters = {
          itemsPerPage: 1000,
          page: 1,
          count: null,
          active: null,
          direction: 'asc'
        };
        this.filters.inactive = false;
        this.filters.searchInput = null;
        const paginatedList =
          await this.behaviorCodesService.getBehaviorCodeList(this.filters);
        this.behaviorCodes = paginatedList.results;
        this.filters.tableFilters.count = paginatedList.options.totalItems;
      } else {
        this.toastService.error(
          'School must be selected to reorder Behavior Codes!'
        );
      }
    } else {
      this.reorderMode = false;
      // canceling reordering, get default paginated list
      this.filters =
        this.behaviorCodesService.getBehaviorCodesListDefaultFilters();
      const paginatedList = await this.behaviorCodesService.getBehaviorCodeList(
        this.filters
      );
      this.behaviorCodes = paginatedList.results;
      this.filters.tableFilters.count = paginatedList.options.totalItems;
    }
    this.isLoading = false;
  }
}
