<div class="table-container">
  <mat-table
    class="mat-elevation-z2"
    [dataSource]="dataSource"
    matSortDisableClear
    matSort>
    <ng-container *ngFor="let column of columnKeys" matColumnDef="{{column}}">
      <mat-header-cell *matHeaderCellDef>
        <ng-container
          *ngIf="getColumnTitle && getColumnTitle(column)?.template"
          [ngTemplateOutlet]="getColumnTitle(column).template"
          [ngTemplateOutletContext]="getColumnTitle(column).context"></ng-container>
        <div
          tabindex="0"
          [attr.aria-label]="'Header Cell: ' + (getColumnTitle ? getColumnTitle(column) : column)"
          *ngIf="!getColumnTitle || !getColumnTitle(column)?.template"
          >{{getColumnTitle ? getColumnTitle(column) : column}}</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container
          *ngIf="getColumnValue && getColumnValue(column, row)?.template"
          [ngTemplateOutlet]="getColumnValue(column, row).template"
          [ngTemplateOutletContext]="getColumnValue(column, row).context"></ng-container>
        <div
          tabindex="0"
          [attr.aria-label]="'Data Cell: ' + (getColumnValue ? getColumnValue(column, row) : column)"
          *ngIf="!getColumnValue || !getColumnValue(column, row)?.template"
          >{{getColumnValue ? getColumnValue(column, row) :
          column}}</div>
      </mat-cell>
    </ng-container>
    <mat-header-row tabindex="0" aria-label="Table Header Row" *matHeaderRowDef="displayedColumns;"> </mat-header-row>
    <mat-row tabindex="0" aria-label="Table Data Row" *matRowDef="let row; columns: displayedColumns;"> </mat-row>
  </mat-table>
</div>
<div
  tabindex="0"
  aria-label="Table Pagination Filters"
  *ngIf="tableFilters?.count > tableFilters?.itemsPerPage"
  class="table-filters-bottom">
  <app-pagination
    [tableFilters]="tableFilters"
    (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
</div>
