<mat-card appearance="outlined">
  <mat-card-header>
    <h3>Add Guardian</h3>
  </mat-card-header>
  <mat-card-content>
    <!-- Search guardian by email -->

    <mat-form-field class="full-width-input">
      <mat-label>Guardian Email</mat-label>
      <input #emailInput matInput name="email" />
    </mat-form-field>
    <div class="dialog-actions">
      <button mat-button (click)="closeDialog()">Cancel</button>
      <button mat-flat-button color="primary" (click)="saveGuardian()">
        Assign
      </button>
    </div>
  </mat-card-content>
</mat-card>
