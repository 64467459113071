<div class="printer-modal-header">
  <span class="printer-modal-title">{{title}}</span>
</div>

<div class="printer-modal-content">
  <span class="printer-modal-content-text"
    >Printer must be selected to be able to print.</span
  >
  <button
    *ngIf="!shouldSelectDevice"
    mat-flat-button
    type="button"
    color="primary"
    class="printer-action-button"
    (click)="useBrowserPrint()">
    BROWSER PRINT
  </button>

  <ng-container *ngIf="!isLoading">
    <mat-form-field *ngIf="shouldSelectDevice">
      <mat-select
        (selectionChange)="setSelectedDevice($event)"
        placeholder="Select">
        <mat-option
          *ngFor="let option of deviceOptions"
          [value]="option.productName">
          {{option.productName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="shouldSelectDevice" class="not-selected">
      <span>Not listed?</span>
      <span
        (click)="handleAllowConnectUsbDevice()"
        (keydown)="handleClickHereKeyDown($event)"
        >Click here</span
      >
    </div>

    <button
      *ngIf="!shouldSelectDevice"
      mat-flat-button
      type="button"
      color="primary"
      class="printer-action-button"
      (click)="handleAllowConnectUsbDevice()">
      SELECT PRINTER
    </button>

    <div class="printer-actions-wrapper" *ngIf="shouldSelectDevice">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        class="printer-action-button"
        (click)="onClose()">
        CANCEL
      </button>
      <button
        mat-flat-button
        color="primary"
        type="button"
        class="printer-action-button"
        (click)="saveSelectedDevice()">
        SAVE PRINTER
      </button>
    </div>
  </ng-container>
</div>
