"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeEntryDeactivateByDateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const isValidDate_decorator_1 = require("../shared/isValidDate.decorator");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const boolean_transform_1 = require("../../constants/boolean.transform");
const transform_config_1 = require("../../transform-config");
class BehaviorCodeEntryDeactivateByDateDto extends mappable_base_1.MappableBase {
    constructor(behaviorCodeEntryOptions) {
        super();
        if (!behaviorCodeEntryOptions)
            return;
        Object.assign(this, behaviorCodeEntryOptions);
    }
    getFields() {
        return ['schoolId', 'date', 'preserveReactions', 'behaviorCodeId'];
    }
    schoolId;
    behaviorCodeId;
    date;
    preserveReactions;
    getTransforms() {
        return [
            {
                destination: 'date',
                transform: transform_config_1.TransformConfig.transformDateToTimestamp('date'),
                sourceFields: ['date']
            }
        ];
    }
}
exports.BehaviorCodeEntryDeactivateByDateDto = BehaviorCodeEntryDeactivateByDateDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryDeactivateByDateDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryDeactivateByDateDto.prototype, "behaviorCodeId", void 0);
__decorate([
    (0, isValidDate_decorator_1.IsValidTimestamp)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryDeactivateByDateDto.prototype, "date", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], BehaviorCodeEntryDeactivateByDateDto.prototype, "preserveReactions", void 0);
