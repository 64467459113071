import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../base-classes/base.component';
import { SaveBarAction } from './save-bar-action.enum';
import { ISaveBarButton } from './save-bar-button.interface';
import { AppSaveBarService, ISaveBarOptions } from './save-bar.service';

/**
 * The SaveBar Component
 */
@Component({
  selector: 'app-save-bar',
  templateUrl: './save-bar.template.html',
  styleUrls: ['./save-bar.scss'],
})
export class AppSaveBarComponent extends BaseComponent implements OnDestroy {
  /**
   * Should we show our component
   */
  public show: boolean;

  /**
   * Subscription References
   */
  public visibilitySub: Subscription;
  public saveStatusSub: Subscription;

  /**
   * Config options
   */
  public buttons: ISaveBarButton[];
  public options?: ISaveBarOptions;

  /**
   * Default Constructor
   */
  public constructor(
    public saveBarService: AppSaveBarService,
    public renderer: Renderer2,
    @Inject(DOCUMENT)
    public document: Document
  ) {
    super();
    this.show = false;
    this.buttons = [];
    this.handleVisibilityChanges();
  }

  /**
   * Unsubscribe our subscriptions on component destruction
   */
  public ngOnDestroy(): void {
    if (this.visibilitySub) {
      this.visibilitySub.unsubscribe();
    }
    if (this.saveStatusSub) {
      this.saveStatusSub.unsubscribe();
    }
  }

  /**
   * Update our show status on any events fired from service
   */
  public handleVisibilityChanges(): void {
    this.saveBarService.visibilityChanged.subscribe(
      ({ show, buttons, options }) => {
        if (show) {
          this.enableSaveBar(buttons, options);
        } else {
          this.disableSaveBar();
        }
      }
    );
  }

  /**
   * Enables the save bar and resets any old data
   */
  public enableSaveBar(
    buttons: ISaveBarButton[],
    options?: ISaveBarOptions
  ): void {
    this.show = true;
    this.renderer.addClass(this.document.body, 'save-bar-enabled');

    // Load our config
    this.buttons = buttons;
    this.options = options;
  }

  /**
   * Disables the save bar
   */
  public disableSaveBar(): void {
    this.show = false;
    this.renderer.removeClass(this.document.body, 'save-bar-enabled');
  }

  /**
   * Buttons Methods. Pass the button type to the service's emitter.
   */
  public buttonPressed(action: SaveBarAction): void {
    this.saveBarService.buttonPressed.emit(action);
  }
}
