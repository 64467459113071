import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IStudentGroupsCreateEditResolverData } from './student-groups-create-edit-resolver-data.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppStudentGroupsService } from '../student-groups.service';

@Injectable({ providedIn: 'root' })
export class AppStudentGroupsCreateEditResolver extends BaseResolver<IStudentGroupsCreateEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private studentGroupsService: AppStudentGroupsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IStudentGroupsCreateEditResolverData> {
    const studentGroupId = route.paramMap.get('id');
    return this.studentGroupsService.getStudentGroupCreateEditResolverData(
      studentGroupId
    );
  }
}
