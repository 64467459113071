"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuardianInviteTemplateVariablesDto = void 0;
const base_invite_template_variables_dto_1 = require("./base-invite-template-variables.dto");
class GuardianInviteTemplateVariablesDto extends base_invite_template_variables_dto_1.BaseInviteTemplateVariablesDto {
    constructor(guardianInviteTemplateVariablesOptions) {
        super();
        Object.assign(this, guardianInviteTemplateVariablesOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return [...super.getFields(), 'student_firstName', 'student_lastName'];
    }
    student_firstName;
    student_lastName;
}
exports.GuardianInviteTemplateVariablesDto = GuardianInviteTemplateVariablesDto;
