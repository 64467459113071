<div class="account-view">
  <h2>SFTP Account</h2>
  <p>Host: sftp://{{ sftpHost }}</p>
  <p>Port: {{ sftpPort }}</p>
  <p>Account ID: {{ sftpAccount.accountId }}</p>
  <button
    *ngIf="!accountCredentials"
    mat-flat-button
    color="primary"
    class="standard-button"
    (click)="resetAccountPassword()">
    <fa-icon [icon]="['fas', 'key']"></fa-icon>
    Reset Password
  </button>
  <!-- buttons for downloading credentials and copy to clipboard, and close -->
  <div *ngIf="accountCredentials" class="button-row">
    <div class="account-password">
      Password: {{ accountCredentials.accountPassword }}
    </div>
    <button mat-flat-button color="primary" (click)="downloadCredentials()">
      <fa-icon [icon]="['fas', 'download']"></fa-icon>
      Download
    </button>
    <button mat-flat-button color="primary" (click)="copyCredentials()">
      <fa-icon [icon]="['fas', 'copy']"></fa-icon>
      Copy
    </button>
    <button
      *ngIf="credentialsSaved"
      mat-flat-button
      color="warn"
      class="standard-button"
      (click)="closeDialog()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
      Close
    </button>
  </div>
</div>
