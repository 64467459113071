import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import {
  PrivilegeEnum,
  StudentPerformanceDto,
  ValidationUtil
} from '@whetstoneeducation/hero-common';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { Router } from '@angular/router';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { MenuAction } from '../../../../shared/menu/menu-action.interface';
import { AppPrivilegesService } from '../../../auth/privileges.service';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import { commonViewStudentProfile } from '../../../../shared/student-card/common-student-card.helpers';

@Component({
  selector: 'app-student-list-card',
  templateUrl: './student-list-card.template.html',
  styleUrls: ['./student-list-card.scss']
})
export class AppStudentListCard
  extends BaseTableComponent<StudentPerformanceDto>
  implements DoCheck
{
  @Input() students: StudentPerformanceDto[];
  @Input() selectedStudentsIds: number[];
  @Input() tableFilters: ITableFilters;
  @Output() selectStudent = new EventEmitter<number>();
  @Output() showPasswordResetModalEvent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() showAllPasswordResetModalEvent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() showBulkStudentProfileUploadModal: EventEmitter<void> =
    new EventEmitter<void>();

  public bulkActionButtons: MenuAction[] = [];

  constructor(
    public router: Router,
    public toastService: AppToastManagerService,
    privilegesService: AppPrivilegesService
  ) {
    super({ privilegesService });
  }

  ngDoCheck(): void {
    this.bulkActionButtons = [
      {
        key: 'BULK_PROFILE_PHOTO_UPLOAD',
        title: `Upload Student Photos`,
        onClick: () => this.showBulkStudentProfileUploadModal.emit()
      },
      {
        key: 'RESET_PASSWORDS',
        title: `Reset Passwords (${this.selectedStudentsIds.length})`,
        hide:
          !this.hasPrivilege(PrivilegeEnum.RESET_STUDENT_PASSWORDS) ||
          !this.selectedStudentsIds?.length,
        disabled: !this.selectedStudentsIds?.length,
        onClick: () => this.showPasswordResetModalEvent.emit()
      },
      {
        key: 'RESET_ALL_PASSWORDS',
        title: `Reset All Passwords (${this.tableFilters.count})`,
        hide: !this.hasPrivilege(PrivilegeEnum.RESET_STUDENT_PASSWORDS),
        onClick: () => this.showAllPasswordResetModalEvent.emit()
      }
    ];
  }

  toggleStudentSelection(studentId: number): void {
    const { privileges, currentSchoolId } =
      this.StorageManager.getLoggedInUser();
    if (
      ValidationUtil.hasPrivilege(
        PrivilegeEnum.TRACK_BEHAVIOR,
        privileges,
        currentSchoolId
      )
    ) {
      this.selectStudent.emit(studentId);
    }
  }

  viewStudentProfile(studentId: number): void {
    commonViewStudentProfile(this, studentId);
  }
}
