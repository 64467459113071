"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimezoneDict = exports.TimezoneNames = void 0;
var TimezoneNames;
(function (TimezoneNames) {
    TimezoneNames["EASTERN_TIME"] = "Eastern Time";
    TimezoneNames["CENTRAL_TIME"] = "Central Time";
    TimezoneNames["MOUNTAIN_TIME"] = "Mountain Time";
    TimezoneNames["ARIZONA_TIME"] = "Arizona Time";
    TimezoneNames["PACIFIC_TIME"] = "Pacific Time";
    TimezoneNames["ALASKA_TIME"] = "Alaska Time";
    TimezoneNames["HAWAII_ALEUTIAN_TIME"] = "Hawaii-Aleutian Time";
    TimezoneNames["ATLANTIC_TIME"] = "Atlantic Time";
    TimezoneNames["SAMOA_TIME"] = "Samoa Time";
    TimezoneNames["CHAMORRO_TIME"] = "Chamorro Time";
})(TimezoneNames || (exports.TimezoneNames = TimezoneNames = {}));
exports.TimezoneDict = {
    [TimezoneNames.EASTERN_TIME]: {
        value: 'America/New_York',
        label: 'Eastern Time (e.g. New York)'
    },
    [TimezoneNames.CENTRAL_TIME]: {
        value: 'America/Chicago',
        label: 'Central Time (e.g. Chicago)'
    },
    [TimezoneNames.MOUNTAIN_TIME]: {
        value: 'America/Denver',
        label: 'Mountain Time (e.g. Denver)'
    },
    [TimezoneNames.ARIZONA_TIME]: {
        value: 'America/Phoenix',
        label: 'Arizona Time (e.g. Phoenix)'
    },
    [TimezoneNames.PACIFIC_TIME]: {
        value: 'America/Los_Angeles',
        label: 'Pacific Time (e.g. Los Angeles)'
    },
    [TimezoneNames.ALASKA_TIME]: {
        value: 'America/Anchorage',
        label: 'Alaska Time (e.g. Anchorage)'
    },
    [TimezoneNames.HAWAII_ALEUTIAN_TIME]: {
        value: 'Pacific/Honolulu',
        label: 'Hawaii-Aleutian Time (e.g. Honolulu)'
    },
    [TimezoneNames.ATLANTIC_TIME]: {
        value: 'America/Puerto_Rico',
        label: 'Atlantic Time (e.g. Puerto Rico)'
    },
    [TimezoneNames.SAMOA_TIME]: {
        value: 'Pacific/Pago_Pago',
        label: 'Samoa Time (e.g. Pago Pago)'
    },
    [TimezoneNames.CHAMORRO_TIME]: {
        value: 'Pacific/Guam',
        label: 'Chamorro Time (e.g. Guam)'
    }
};
