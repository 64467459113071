"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolActivityReportResponseDto = exports.SchoolActivityReportRowResponseDto = void 0;
const class_validator_1 = require("class-validator");
const shared_1 = require("../shared");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
class SchoolActivityReportRowResponseDto extends shared_1.PaginationOptionsDto {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return [
            'userId',
            'userName',
            'behaviorsTracked',
            'pointsIssued',
            'referrals'
        ];
    }
    userId;
    userName;
    behaviorsTracked;
    pointsIssued;
    referrals;
}
exports.SchoolActivityReportRowResponseDto = SchoolActivityReportRowResponseDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SchoolActivityReportRowResponseDto.prototype, "userId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SchoolActivityReportRowResponseDto.prototype, "userName", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SchoolActivityReportRowResponseDto.prototype, "behaviorsTracked", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SchoolActivityReportRowResponseDto.prototype, "pointsIssued", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SchoolActivityReportRowResponseDto.prototype, "referrals", void 0);
class SchoolActivityReportResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['results', 'totalCount', 'studentsCount'];
    }
    results;
    totalBehaviors;
    totalPoints;
    totalReferrals;
}
exports.SchoolActivityReportResponseDto = SchoolActivityReportResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], SchoolActivityReportResponseDto.prototype, "results", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Array)
], SchoolActivityReportResponseDto.prototype, "totalBehaviors", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SchoolActivityReportResponseDto.prototype, "totalPoints", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SchoolActivityReportResponseDto.prototype, "totalReferrals", void 0);
