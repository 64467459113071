import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISftpImportListFilters } from '../sftp-import-list-models/sftp-import-list-filters.interface';
import { ImportStatus } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-sftp-import-list-filters',
  templateUrl: './sftp-import-list-filters.template.html',
  styleUrls: ['./sftp-import-list-filters.scss']
})
export class AppSftpImportListFilters extends BaseComponent {
  @Input() filters: ISftpImportListFilters;
  @Output() filtersUpdated: EventEmitter<ISftpImportListFilters>;
  public importStatuses = Object.values(ImportStatus);
  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<ISftpImportListFilters>();
  }
}
