import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { StudentDetailResponseDto } from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppStudentsService } from '../students.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IStudentsCreateEditResolverData } from './students-create-edit-resolver-data.interface';
import { AppSchoolsServices } from '../../schools/schools.service';
import { AppStudentGroupsService } from '../../student-groups/student-groups.service';

@Injectable({ providedIn: 'root' })
export class AppStudentCreateEditResolver extends BaseResolver<IStudentsCreateEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public studentsService: AppStudentsService,
    public schoolsService: AppSchoolsServices,
    public studentGroupsService: AppStudentGroupsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IStudentsCreateEditResolverData> {
    const studentId = route.paramMap.get('id');
    const currentUser = this.StorageManager.getLoggedInUser();
    let student;
    if (studentId === 'new') {
      student = new StudentDetailResponseDto();
    } else {
      student = await this.studentsService.getStudentById(+studentId);
    }
    const schools = await this.schoolsService.getSchoolsOptions();
    return {
      student,
      schoolOptions: schools.map((school) => {
        return { value: school.id, display: school.name };
      })
    };
  }
}
