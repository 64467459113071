import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { IReactionsListFilters } from '../reactions-list-models/reactions-list-filters.interface';

@Component({
  selector: 'app-reactions-list-filters',
  templateUrl: './reactions-list-filters.template.html',
  styleUrls: ['./reactions-list-filters.scss'],
})
export class AppReactionsListFiltersComponent extends BaseComponent {
  @Input() filters: IReactionsListFilters;

  @Output() filtersUpdated: EventEmitter<IReactionsListFilters>;

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<IReactionsListFilters>();
  }
}
