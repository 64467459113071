import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { MathUtil } from '@whetstoneeducation/hero-common';
/**
 * Number pipe. Replace number with '-' if null. Cuts off decimal places if too many
 */
@Pipe({
  name: 'number',
})
export class AppNumberPipe extends DecimalPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  // @ts-ignore
  transform(value: number) {
    if (typeof value === 'string') {
      value = Number(value);
    }

    const isNumberType = MathUtil.isNumber(value);

    if (isNumberType) {
      return super.transform(value, '1.0-2');
    } else {
      return '-';
    }
  }
}
