"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportFrequency = void 0;
var ExportFrequency;
(function (ExportFrequency) {
    ExportFrequency["Hourly"] = "hourly";
    ExportFrequency["Daily"] = "daily";
    ExportFrequency["Weekly"] = "weekly";
    ExportFrequency["Monthly"] = "monthly";
})(ExportFrequency || (exports.ExportFrequency = ExportFrequency = {}));
