import { BaseService } from '../../shared/base-classes/base.service';
import { Injectable } from '@angular/core';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  CustomRostersResponseDto,
  CustomRostersUpdateDto,
  IRelation,
  StudentFilterDto
} from '@whetstoneeducation/hero-common';
import { ICustomRostersResolverData } from './custom-roster-models/custom-rosters-resolver-data.interface';
import { IStudentsListFilters } from '../students/students-list-models/students-list-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppCustomRostersService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  async getCustomRostersResolverData(
    id?: number
  ): Promise<ICustomRostersResolverData> {
    const customRosters = await this.getCustomRosters();
    if (customRosters.length) {
      const customRoster = id
        ? await this.getCustomRoster(id)
        : await this.getCustomRoster(customRosters[0].id);
      return {
        customRosters,
        customRoster
      };
    }
    return {
      customRosters: [],
      customRoster: null
    };
  }

  async getCustomRoster(
    id: number,
    filters?: IStudentsListFilters
  ): Promise<CustomRostersResponseDto> {
    return this.appClientDataService.execute<Promise<CustomRostersResponseDto>>(
      this.GET_ROUTES.CUSTOM_ROSTER_BY_ID,
      {
        pathParams: {
          id
        },
        queryParams: filters
          ? {
              ...(filters.firstName ? { firstName: filters.firstName } : {}),
              ...(filters.lastName ? { lastName: filters.lastName } : {}),
              ...(filters.grade ? { grade: filters.grade } : {}),
              ...(filters.studentExtId
                ? { studentExtId: filters.studentExtId }
                : {})
            }
          : {}
      }
    );
  }

  async getCustomRosters(): Promise<IRelation[]> {
    return this.appClientDataService.execute<Promise<IRelation[]>>(
      this.GET_ROUTES.CUSTOM_ROSTERS_LIST
    );
  }

  async createCustomRoster(name: string): Promise<IRelation> {
    return this.appClientDataService.execute<IRelation>(
      this.POST_ROUTES.CUSTOM_ROSTER,
      {
        method: this.METHOD.POST,
        body: {
          name
        }
      }
    );
  }

  async updateCustomRoster(
    customRosterId: number,
    update: CustomRostersUpdateDto
  ): Promise<void> {
    return this.appClientDataService.execute<void>(
      this.PATCH_ROUTES.UPDATE_CUSTOM_ROSTER,
      {
        method: this.METHOD.PATCH,
        pathParams: {
          id: customRosterId
        },
        body: update
      }
    );
  }
}
