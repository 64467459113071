import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';

@Component({
  selector: 'app-school-activity-report-summary-card',
  templateUrl: './school-activity-report-summary-card.template.html',
  styleUrls: ['./school-activity-report-summary-card.scss']
})
export class AppSchoolActivityReportSummaryCard extends BaseComponent {

  @Input()
  public totalBehaviorsCount: { name: string; totalBehaviors: number }[];
  @Input()
  public totalReferrals: number;
  @Input()
  public totalPoints: number;

  public splitTotalBehaviors: boolean = false;

  public constructor(
  ) {
    super();
  }

  public get totalBehaviors() {
    return this.totalBehaviorsCount.reduce((acc, curr) => acc + curr.totalBehaviors, 0);
  }

  public displaySplitBehaviors(): void {
    this.splitTotalBehaviors = !this.splitTotalBehaviors
  }
}
