<div class="accrued-report-filters-container">
  <mat-form-field>
    <mat-label>
      Behavior Code
    </mat-label>
    <mat-select
      (selectionChange)="onFilterUpdated('behaviorCodeId', $event); updateSelectedBehaviorCode()"
      placeholder="Behavior Code">
      <mat-option
        *ngFor="let option of behaviorCodeOptions"
        [value]="option.id">
          {{option.code}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Grade Level
    </mat-label>
    <mat-select
      (selectionChange)="onFilterUpdated('grade', $event)"
      placeholder="Grade Level">
      <mat-option
        *ngFor="let option of studentsGrades"
        [value]="option">
          {{option}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-label>Start Date</mat-label>
    <input
      placeholder="Start Date"
      matInput
      [matDatepicker]="startDatePicker"
      type="text"
      (dateChange)="onDateUpdated('startDate', $event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="filter-item">
    <mat-label>End Date</mat-label>
    <input
      placeholder="End Date"
      matInput
      [matDatepicker]="endDatePicker"
      type="text"
      (dateChange)="onDateUpdated('endDate', $event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Minimum count
    </mat-label>
    <input
      placeholder="Minimum count"
      matInput
      min="1"
      #minField
      (change)="onFilterUpdated('minCount', minField.value || null)"
      type="number"/>
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Maximum count
    </mat-label>
    <input
      placeholder="Maximum count"
      matInput
      min="1"
      #maxField
      (change)="onFilterUpdated('maxCount', maxField.value || null);"
      type="number"/>
  </mat-form-field>

  <button
    type="button"
    mat-flat-button
    (click)="onApplyFilters()"
    class="secondary-button"
    [disabled]="isLoading || loadingItems">
    Apply Filters
  </button>
</div>

<span *ngIf="!!selectedBehaviorCode" class="selected-behavior-code">
  {{ selectedBehaviorCode }}
</span>
