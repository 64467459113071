"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFormats = void 0;
var DateFormats;
(function (DateFormats) {
    // Custom Formats
    DateFormats["TABLE_DATE"] = "TABLE_DATE";
    DateFormats["FORM_DATE_TIME"] = "FORM_DATE_TIME";
    DateFormats["DATE_AGO"] = "DATE_AGO";
    // Date & Times
    DateFormats["FULL_DATE_TIME"] = "FULL_DATE_TIME";
    DateFormats["LONG_DATE_TIME"] = "LONG_DATE_TIME";
    DateFormats["MEDIUM_DATE_TIME"] = "MEDIUM_DATE_TIME";
    DateFormats["MEDIUM_DATE_SHORT_TIME"] = "MEDIUM_DATE_SHORT_TIME";
    DateFormats["SHORT_DATE_TIME"] = "SHORT_DATE_TIME";
    DateFormats["MINI_DATE_TIME"] = "MINI_DATE_TIME";
    DateFormats["MINI_DATE_MEDIUM_TIME"] = "MINI_DATE_MEDIUM_TIME";
    // Dates
    DateFormats["FULL_DATE"] = "FULL_DATE";
    DateFormats["LONG_DATE"] = "LONG_DATE";
    DateFormats["MEDIUM_DATE"] = "MEDIUM_DATE";
    DateFormats["SHORT_DATE"] = "SHORT_DATE";
    DateFormats["MINI_DATE"] = "MINI_DATE";
    // Times
    DateFormats["LONG_TIME"] = "LONG_TIME";
    DateFormats["MEDIUM_TIME"] = "MEDIUM_TIME";
    DateFormats["SHORT_TIME"] = "SHORT_TIME";
})(DateFormats || (exports.DateFormats = DateFormats = {}));
