import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISftpAccountListFilters } from '../sftp-account-list-models/sftp-account-list-filters.interface';

@Component({
  selector: 'app-sftp-account-list-filters',
  templateUrl: './sftp-account-list-filters.template.html',
  styleUrls: ['./sftp-account-list-filters.scss']
})
export class AppSftpAccountListFilters extends BaseComponent {
  @Input() filters: ISftpAccountListFilters;
  @Output() filtersUpdated: EventEmitter<ISftpAccountListFilters>;
  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<ISftpAccountListFilters>();
  }
}
