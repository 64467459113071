"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NestedDtoConfig = void 0;
const dtos_1 = require("./dtos");
class NestedDtoConfig {
    destField;
    destType;
    mapFunction;
    sourceField;
    constructor(options) {
        this.destField = options.destField;
        this.destType = options.destType;
        this.mapFunction = options.mapFunction;
        this.sourceField = options.sourceField;
    }
    static relationConfig(destField, sourceField) {
        return new NestedDtoConfig({
            destField: destField,
            destType: dtos_1.RelationDto,
            sourceField: sourceField
        });
    }
}
exports.NestedDtoConfig = NestedDtoConfig;
