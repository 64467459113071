<div class="guardian-dashboard-container">
  <div>
    <span>
      Please select a student below to track their progress throughout the school year. Here you can see their behavior history, including <span *ngFor="let code of behaviorCodes; let last = last" [style.color]="getCodeTypeColor(code)">
        {{formatLastHistoryElement(code, last) }}</span>
        behavior tracks; total points accumulated, daily bell schedules and any upcoming or past action
    </span>
  </div>
  <div *ngIf="!!students?.length">
    <div tabindex="0" [attr.aria-label]="student.firstName + ' ' + student.lastName" role="button" (keypress)="redirectToBehaviorHistory()" (click)="redirectToStudentProfile(student.id)"  class="student-container" *ngFor="let student of students">
      <app-student-header-info
      [studentId]="student.id"
      [selectedImage]="student.profilePhotoUrl"
      [firstName]="student.firstName"
      [lastName]="student.lastName"
      [studentExtId]="student.studentExtId"
      [grade]="student.grade"
      [studentGroupName]="student.groupName"
      [hideProfileImageSelector]="true">
      <app-points-bubble [points]="student.points ?? 0"></app-points-bubble>
    </app-student-header-info>
    </div>
  </div>
</div>
