import { AppSchoolCreateEditComponent } from './school-create-edit/school-create-edit.component';
import { AppSchoolResolver } from './school.resolver';
import { AuthGuard } from '../../route-guards/auth.guard';
import { ICustomRoute } from '../../app-routing.module';
import { AppSchoolsListComponent } from './schools-list/schools-list.component';
import { AppSchoolsListResolver } from './schools-list/schools-list.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { PrivilegeEnum } from '@whetstoneeducation/hero-common';
import { UserTypeEnum } from '@whetstoneeducation/hero-common';

export const AppSchoolsRoutes: ICustomRoute[] = [
  {
    path: 'schools/new',
    title: 'School',
    component: AppSchoolCreateEditComponent,
    resolve: { data: AppSchoolResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'School',
      description: 'Create School',
      breadcrumbs: [
        {
          path: '/schools',
          title: 'Schools'
        }
      ],
      buttons: [
        {
          name: 'Back',
          style: 'outline-button',
          action: HeaderButtonAction.BACK
        },
        {
          name: 'Save School',
          icon: 'check-circle',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        },
        {
          name: 'Subscription',
          icon: 'check-circle',
          style: 'success-button',
          action: HeaderButtonAction.EDIT_SUBSCRIPTION,
          privilege: PrivilegeEnum.INTERNAL_ADMIN
        }
      ]
    }
  },
  {
    path: 'schools/:id',
    title: 'School',
    component: AppSchoolCreateEditComponent,
    resolve: { data: AppSchoolResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'School',
      description: 'Edit School',
      breadcrumbs: [
        {
          path: '/schools',
          title: 'Schools'
        }
      ],
      buttons: [
        {
          name: 'Back',
          style: 'outline-button',
          action: HeaderButtonAction.BACK
        },
        {
          name: 'Save School',
          icon: 'check-circle',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        },
        {
          name: 'Deactivate Records',
          style: 'secondary-button',
          action: HeaderButtonAction.DEACTIVATE_RECORDS,
          privilege: PrivilegeEnum.DEACTIVATE_RECORDS
        },
        {
          name: 'Subscription',
          icon: 'check-circle',
          style: 'success-button',
          action: HeaderButtonAction.EDIT_SUBSCRIPTION,
          privilege: PrivilegeEnum.INTERNAL_ADMIN
        }
      ]
    }
  },
  {
    path: 'schools',
    title: 'Schools',
    component: AppSchoolsListComponent,
    resolve: { data: AppSchoolsListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Schools',
      description: 'All schools that exist in your school/group.',
      icon: 'school',
      buttons: [
        {
          name: 'Role Manager',
          normalizedRoute: 'roles',
          icon: 'id-badge',
          style: 'outline-button'
        },
        {
          name: 'Create School',
          action: HeaderButtonAction.CREATE,
          icon: 'plus-circle',
          style: 'secondary-button',
          userType: UserTypeEnum.INTERNAL_ADMIN
        }
      ]
    }
  }
];
