import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppSchoolGroupsService } from '../school-groups.service';
import { ISchoolGroupsListResolverData } from './school-groups-list-models/school-groups-list-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppSchoolGroupsListResolver extends BaseResolver<ISchoolGroupsListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public schoolGroupsService: AppSchoolGroupsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<ISchoolGroupsListResolverData> {
    return this.schoolGroupsService.getSchoolGroupsListPageResolverData();
  }
}
