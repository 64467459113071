import { NgModule } from '@angular/core';
import { AppBehaviorCodesListFiltersComponent } from './behavior-codes-list/behavior-codes-list-filters/behavior-codes-list-filters.component';
import { AppBehaviorCodesListTableComponent } from './behavior-codes-list/behavior-codes-list-table/behavior-codes-list-table.component';
import { AppBehaviorCodesListComponent } from './behavior-codes-list/behavior-codes-list.component';
import { AppMaterialModule } from '../../app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppBehaviorCodesCreateEditComponent } from './behavior-codes-create-edit/behavior-codes-create-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppBehaviorCodesReactionsTableComponent } from '../behavior-code-reactions/behavior-codes-reactions-table/behavior-codes-reactions-table.component';
import { AppBehaviorCodeInterventionsTableComponent } from '../behavior-code-interventions/behavior-code-interventions-table/behavior-code-interventions-table.component';
import { AppBehaviorCodeNotificationsTableComponent } from '../behavior-code-notifications/behavior-code-notifications-table/behavior-code-notifications-table.component';
import { RouterLink } from '@angular/router';
import { AppBehaviorCodeReactionCreateEditComponent } from '../behavior-code-reactions/behavior-code-reaction-create-edit/behavior-code-reaction-create-edit.component';
import { AppBehaviorCodeInterventionsCreateEditComponent } from '../behavior-code-interventions/behavior-code-interventions-create-edit/behavior-code-interventions-create-edit.component';
import { AppBehaviorCodeNotificationsCreateEditComponent } from '../behavior-code-notifications/behavior-code-notifications-create-edit/behavior-code-notifications-create-edit.component';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppDefaultReactionComponent } from './behavior-codes-create-edit/default-reaction/default-reaction.component';

@NgModule({
  declarations: [
    AppBehaviorCodesListFiltersComponent,
    AppBehaviorCodesListTableComponent,
    AppBehaviorCodesListComponent,
    AppBehaviorCodesCreateEditComponent,
    AppBehaviorCodesReactionsTableComponent,
    AppBehaviorCodeInterventionsTableComponent,
    AppBehaviorCodeNotificationsTableComponent,
    AppBehaviorCodeReactionCreateEditComponent,
    AppBehaviorCodeInterventionsCreateEditComponent,
    AppBehaviorCodeNotificationsCreateEditComponent,
    AppDefaultReactionComponent
  ],
  exports: [],
  imports: [
    AppMaterialModule,
    FontAwesomeModule,
    AppPaginationModule,
    ReactiveFormsModule,
    AppErrorDisplayComponent,
    FormsModule,
    RouterLink,
    DirectiveModule
  ]
})
export class AppBehaviorCodesModule {}
