import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppIncidentConfigComponent } from './incident-config/incident-config.component';
import { AppMaterialModule } from '../../app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IncidentConfigOptionCategoryMenuItemComponent } from './incident-config/incident-config-option-category-menu-item/incident-config-option-category-menu-item.component';
import { AppOptionCreateEditModalComponent } from './incident-config/option-create-edit-modal/option-create-edit-modal.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppIncidentConfigComponent,
    IncidentConfigOptionCategoryMenuItemComponent,
    AppOptionCreateEditModalComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    FontAwesomeModule,
    ReactiveFormsModule
  ]
})
export class AppIncidentModule {}
