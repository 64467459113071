import { logger } from 'src/app/shared/logger';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppToastManagerService } from './toast-manager.service';
import { BaseService } from '../base-classes/base.service';

/**
 * Service used to handle resolver errors.
 *    - Note: This is its own special file so we can easily add new service injections without changing a million files
 */
@Injectable({
  providedIn: 'root',
})
export class AppResolverErrorHandlerService extends BaseService {
  public constructor(
    public toastManager: AppToastManagerService,
    public router: Router,
    public ngZone: NgZone
  ) {
    super();
  }

  public async handleError(error: any, errorMessage: string): Promise<void> {
    if (error.status === 403) {
      await this.router.navigate(['/forbidden-error']);
    } else {
      await this.router.navigate(['/resolver-error']);
      logger.error(error);
      this.toastManager.error(errorMessage);
    }
  }
}
