import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { ICalendarViewFilters } from '../calendar-view-models/calendar-view-filters.interface';
import {
  ICalenderViewDropdownData,
  IDisplayData
} from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-calendar-view-list-filters',
  templateUrl: './calendar-view-list-filters.template.html',
  styleUrls: ['./calendar-view-list-filters.scss']
})
export class AppCalendarViewListFilterComponent extends BaseComponent {
  private _filters: ICalendarViewFilters;

  public reactionViewOptions = [
    { value: false, display: 'Bell Schedules' },
    { value: true, display: 'Reactions' }
  ];

  @Input()
  public set filters(filters: ICalendarViewFilters) {
    this._filters = { ...filters };
  }

  public get filters(): ICalendarViewFilters {
    return this._filters;
  }

  @Output() filtersUpdated: EventEmitter<ICalendarViewFilters>;

  @Input()
  dropdownData: ICalenderViewDropdownData;

  /**
   * Have our filters changed since last updated
   */
  public haveFiltersChanged: boolean;

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<ICalendarViewFilters>();
  }

  /**
   * Send our parent component our new set of filter values
   */
  public updateFilters(): void {
    this.filtersUpdated.emit(this.filters);
    this.haveFiltersChanged = false;
  }

  public parseStringIdToNumber(id: string): number {
    return parseInt(id, 10);
  }
}
