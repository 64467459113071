<mat-card appearance="outlined">
  <mat-card-header class="card-header">
    <mat-card-title>Today's Schedules</mat-card-title>
  </mat-card-header>
  <mat-card-content class="today-schedule-content">
    <ng-container *ngFor="let schedule of todaySchedules">
      <div class="schedules">
        <div class="blue-card-container" tabindex="0">
          <div class="student-group-name">
            <h2 tabindex="0">
              {{ schedule.studentGroup.name }}
            </h2>
          </div>
          <div class="right-elements">
              {{ schedule.bellSchedule.name }}
              <fa-icon class="bell-icon" [icon]="'bell'"></fa-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="twoColumnLayout">
      <div class="column">
      </div>
      <div class="column">
        <div class="edit-schedules">
          <button
            *ngIf="canEdit"
            mat-flat-button
            id="dashboardStudentTrackingButton"
            type="button"
            class="standard-button secondary-button"
            (click)="openChangeSchedule()">
            Edit Schedules
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
