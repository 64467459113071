import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { CommonModule, DatePipe } from '@angular/common';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppPipesModule } from '../../shared/pipes/pipes.module';
import { AppLastImportsExportsComponent } from './last-imports-exports.component';
import { AppImportModule } from '../imports/import.module';
import { AppExportModule } from '../exports/export.module';

@NgModule({
  declarations: [
    AppLastImportsExportsComponent
  ],
  exports: [],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppPaginationModule,
    AppPipesModule,
    CommonModule,
    DirectiveModule,
    FontAwesomeModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    RouterModule,
    AppImportModule,
    AppExportModule
  ],
  providers: [DatePipe]
})
export class AppImportsExportsModule {}
