import { Component } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { SftpImportResponseDto } from '@whetstoneeducation/hero-common';
import { SftpImportListColumns } from '../sftp-import-list-models/sftp-import-list-columns.enum';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppImportViewDialogComponent } from '../../import-view/import-view-dialog.component';
import { AppSftpService } from '../../sftp.service';

@Component({
  selector: 'app-sftp-import-list-table',
  templateUrl: './sftp-import-list-table.template.html',
  styleUrls: ['./sftp-import-list-table.scss']
})
export class AppSftpImportListTableComponent extends BaseTableComponent<SftpImportResponseDto> {
  public get displayedColumns() {
    return Object.values(SftpImportListColumns);
  }

  public columnValues = {
    [SftpImportListColumns.CREATED_DATE]: ['createdDate'],
    [SftpImportListColumns.SCHOOL_GROUP]: ['schoolGroup', 'name'],
    [SftpImportListColumns.SCHOOL]: ['school', 'name'],
    [SftpImportListColumns.IMPORT_SUBJECT]: ['importSubject'],
    [SftpImportListColumns.IMPORT_STATUS]: ['status'],
    [SftpImportListColumns.ACTIONS]: ['actions']
  };

  public columns = SftpImportListColumns;

  constructor(
    readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly sftpService: AppSftpService
  ) {
    super();
  }

  public async viewSftpImport(sftpImport: SftpImportResponseDto) {
    this.dialog.open(AppImportViewDialogComponent, {
      data: sftpImport,
      height: '700px',
      width: '900px'
    });
  }

  public async downloadImportFile(sftpImport: SftpImportResponseDto) {
    // download file as a string
    const response = await this.sftpService.downloadImportFile(
      sftpImport.sftpSettingsId
    );
    // convert response from base64
    const file = atob(response[0]);
    this.downloadFile(file, 'import-file.csv');
  }
}
