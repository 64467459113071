import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorCodeEntryResponseDto } from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../../../shared/services/toast-manager.service';
import { AppBehaviorCodeEntryService } from '../../../../behavior-code-entry/behavior-code-entry.service';

@Component({
  selector: 'app-delete-behavior-entry-confirm-modal',
  templateUrl: './delete-behavior-entry-confirm-modal.component.html',
  styleUrls: ['./delete-behavior-entry-confirm-modal.component.scss']
})
export class DeleteBehaviorEntryConfirmModalComponent implements OnInit {
  public isLoading = true;
  public behaviorEntry: BehaviorCodeEntryResponseDto;
  public behaviorCodeId: number;
  constructor(
    public toastService: AppToastManagerService,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    public dialogRef: MatDialogRef<DeleteBehaviorEntryConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      behaviorCodeEntryId: number;
      onClose?: () => void;
    }
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.behaviorEntry =
      await this.behaviorCodeEntryService.getBehaviorCodeEntry(
        this.data.behaviorCodeEntryId
      );
    this.isLoading = false;
  }

  public async deleteBehaviorEntry(): Promise<void> {
    try {
      await this.behaviorCodeEntryService.deleteBehaviorCodeEntry(
        this.data.behaviorCodeEntryId
      );
      this.toastService.success('Behavior Entry Deleted');
      if (this.data.onClose) {
        this.data.onClose();
      }
      this.dialogRef.close();
    } catch (error) {
      this.toastService.error(error);
    }
  }
}
