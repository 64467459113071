import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { AppStudentGroupsService } from '../student-groups.service';
import { IStudentGroupsCreateEditResolverData } from './student-groups-create-edit-resolver-data.interface';
import {
  PaginationResultsDto,
  StudentBasicResponseDto,
  StudentGroupCreateDto,
  StudentGroupUpdateDto,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { Subscription } from 'rxjs';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { MatDialog } from '@angular/material/dialog';
import { AppStudentGroupAddStudentPopUpComponent } from './student-group-add-student-pop-up/student-group-add-student-pop-up.component';
import { logger } from '../../../shared/logger';

@Component({
  selector: 'app-student-group-create-edit',
  templateUrl: './student-groups-create-edit.template.html',
  styleUrls: ['./student-groups-create-edit.scss']
})
export class AppStudentGroupCreateEditComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public id: number;
  public studentGroup: StudentGroupCreateDto | StudentGroupUpdateDto;
  public isCreating: boolean;
  public subscriptions: Subscription[] = [];
  public pageHeaderSubscription: Subscription;
  public studentGroupForm: FormGroup;
  public studentOptions: PaginationResultsDto<StudentPerformanceDto>;
  public selectedStudents: StudentPerformanceDto[];
  public allSelectedStudents: StudentPerformanceDto[];

  constructor(
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public router: Router,
    private pageHeaderService: AppPageHeaderService,
    private studentGroupsService: AppStudentGroupsService,
    private dialog: MatDialog
  ) {
    super();
    const resolverData: IStudentGroupsCreateEditResolverData =
      this.route.snapshot.data.data;
    this.loadData(resolverData);
  }

  public loadData(resolverData: IStudentGroupsCreateEditResolverData) {
    try {
      this.id = +this.route.snapshot.params.id;
      this.selectedStudents =
        (resolverData.studentGroup
          .students as any as StudentPerformanceDto[]) || [];
      this.allSelectedStudents = this.selectedStudents;
      if (!this.id) {
        const user = this.StorageManager.getLoggedInUser();
        this.studentGroup = new StudentGroupCreateDto().mapFields(
          resolverData.studentGroup
        );
        (this.studentGroup as StudentGroupCreateDto).schoolId =
          user.currentSchoolId;
      } else {
        this.studentGroup = new StudentGroupUpdateDto().mapFields(
          resolverData.studentGroup
        );
      }
      this.isCreating = !this.id;
      this.studentOptions = resolverData.studentOptions;
      this.studentGroupForm = dtoToFormGroup(
        this.studentGroup,
        this.formBuilder,
        { mapId: !this.isCreating, exclude: ['studentIds', 'bellScheduleIds'] }
      );
      if (this.studentGroup.name) {
        const title = 'Student Group: ';
        let subtitle = this.studentGroup.name;
        if (this.allSelectedStudents?.length) {
          subtitle += ' (' + this.allSelectedStudents?.length + ')';
        }
        this.pageHeaderService.changeTitle(title);
        this.pageHeaderService.changeSubtitle(subtitle);
      } else {
        this.pageHeaderService.changeTitle('Create Student Group');
      }
    } catch (e) {
      this.toastManager.error('Error loading Student Group.');
    }
  }

  public ngOnInit() {
    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.TOGGLE_VIEW) {
            // TODO: update avatar
          }
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
    this.subscriptions.push(
      this.studentGroupForm.valueChanges.subscribe(async () => {
        if (this.isCreating) {
          this.studentGroup = await validateAndGetValue<StudentGroupCreateDto>(
            this.studentGroupForm,
            StudentGroupCreateDto
          );
        } else {
          this.studentGroup = await validateAndGetValue<StudentGroupUpdateDto>(
            this.studentGroupForm,
            StudentGroupUpdateDto
          );
        }
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.pageHeaderSubscription) this.pageHeaderSubscription.unsubscribe();
  }

  public async saveChanges() {
    if (formCanSave(this.studentGroupForm, this.toastManager)) {
      try {
        if (this.studentGroup instanceof StudentGroupCreateDto) {
          const created = await this.studentGroupsService.createStudentGroup(
            this.studentGroup
          );
          this.toastManager.success('Student Group created successfully!');
          await this.router.navigate(['/student-groups']);
        } else {
          const updated = await this.studentGroupsService.updateStudentGroup(
            this.studentGroup,
            this.id
          );
          this.toastManager.success('Student Group saved successfully!');
          await this.router.navigate(['/student-groups']);
        }
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving Student Group, please try again!'
        );
      }
    }
  }

  public addStudents() {
    const ref = this.dialog.open(AppStudentGroupAddStudentPopUpComponent, {
      panelClass: 'default-dialog',
      data: {
        students: this.studentOptions,
        groupName: this.studentGroup.name,
        selectedStudents: this.allSelectedStudents || []
      }
    });
    ref.afterClosed().subscribe((addedStudents) => {
      this.allSelectedStudents = [
        ...new Set([
          ...(this.allSelectedStudents ? this.allSelectedStudents : []),
          ...addedStudents
        ])
      ];
      this.selectedStudents = this.allSelectedStudents;
      this.studentGroup.studentIds = this.allSelectedStudents.map(
        (student) => student.id
      );
      this.studentGroupForm.markAsTouched();
    });
  }

  public removeStudent(id: number) {
    this.selectedStudents = this.selectedStudents.filter(
      (student) => student.id !== id
    );
    this.allSelectedStudents = this.allSelectedStudents.filter(
      (student) => student.id !== id
    );
    this.studentGroup.studentIds = this.allSelectedStudents.map(
      (student) => student.id
    );
    this.studentGroupForm.markAsTouched();
  }

  public searchStudents(input: string) {
    if (input) {
      this.selectedStudents = this.allSelectedStudents.filter((student) => {
        const inputLowercased = input.toLowerCase();
        const fullName = (
          student.firstName +
          ' ' +
          student.lastName
        ).toLowerCase();
        return fullName.includes(inputLowercased);
      });
    } else {
      this.selectedStudents = this.allSelectedStudents;
    }
  }
}
