import { NgModule } from '@angular/core';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppStudentCreateEditComponent } from './students-create-edit/students-create-edit.component';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppStudentsListComponent } from './students-list/students-list.component';
import { AppStudentsListFiltersComponent } from './students-list/students-list-filters/students-list-filters.component';
import { AppStudentListCard } from './students-list/student-list-card/student-list-card.component';
import { AppBehaviorCodeEntryModule } from '../behavior-code-entry/behavior-code-entry.module';
import { AppStudentProfile } from './profile/student-profile.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BehaviorHistoryComponent } from './profile/behavior-history/behavior-history.component';
import { BehaviorHistoryFiltersComponent } from './profile/behavior-history/behavior-history-filters/behavior-history-filters.component';
import { BehaviorHistoryTableComponent } from './profile/behavior-history/behavior-history-table/behavior-history-table.component';
import { AppGuardianDialog } from './profile/guardian-dialog/guardian.dialog';
import { DeleteBehaviorEntryConfirmModalComponent } from './profile/behavior-history/delete-behavior-entry-confirm-modal/delete-behavior-entry-confirm-modal.component';
import { AppStudentCardModule } from '../../shared/student-card/student-card.module';
import { BehaviorHistoryEditModalComponent } from './profile/behavior-history/behavior-history-edit-modal/behavior-history-edit-modal.component';
import { ReactionEntryEditComponent } from './profile/behavior-history/behavior-history-edit-modal/reaction-entry-edit/reaction-entry-edit.component';
import { StudentInfoTabsComponent } from './profile/student-info-tabs/student-info-tabs.component';
import { StudentProfileInfoComponent } from './profile/student-profile-info/student-profile-info.component';
import { StudentProfileMetricsComponent } from './profile/student-profile-metrics/student-profile-metrics.component';
import { StudentGuardianContactsComponent } from './profile/student-guardian-contacts/student-guardian-contacts.component';
import { StudentDashboardComponent } from './profile/student-dashboard/student-dashboard.component';
import { StudentSchedulesComponent } from './profile/student-schedules/student-schedules.component';
import { AppTablesModule } from '../../shared/tables/tables.module';
import { AppStudentHeaderInfoModule } from '../../shared/student-header-info/student-header-info.module';
import { AppPointsBubbleModule } from '../../shared/points-bubble/points-bubble.module';
import { AppStudentAccountComponent } from './profile/student-account/student-account.component';
import { AppMenuComponent } from '../../shared/menu/menu.component';
import { BulkStudentProfilePhotoUploadComponent } from './bulk-profile-photo-upload/bulk-profile-photo-upload.component';
import { AppFileUploadModule } from '../../shared/file-upload/file-upload.module';
import { BehaviorEntryNotesViewEditComponent } from './profile/behavior-history/behavior-entry-notes-view-edit/behavior-entry-notes-view-edit.component';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    AppStudentCreateEditComponent,
    AppStudentsListComponent,
    AppStudentsListFiltersComponent,
    AppStudentListCard,
    AppStudentProfile,
    AppGuardianDialog,
    AppStudentAccountComponent,
    BehaviorHistoryComponent,
    BehaviorHistoryTableComponent,
    BehaviorHistoryFiltersComponent,
    BehaviorHistoryTableComponent,
    DeleteBehaviorEntryConfirmModalComponent,
    BehaviorHistoryEditModalComponent,
    ReactionEntryEditComponent,
    StudentInfoTabsComponent,
    StudentProfileInfoComponent,
    StudentProfileMetricsComponent,
    StudentGuardianContactsComponent,
    StudentDashboardComponent,
    StudentSchedulesComponent,
    BulkStudentProfilePhotoUploadComponent,
    BehaviorEntryNotesViewEditComponent
  ],
  exports: [AppStudentsListFiltersComponent, AppStudentListCard],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    AppStudentHeaderInfoModule,
    MatIconModule,
    AppPaginationModule,
    FontAwesomeModule,
    DirectiveModule,
    FormsModule,
    AppBehaviorCodeEntryModule,
    AppStudentCardModule,
    NgApexchartsModule,
    NgOptimizedImage,
    FontAwesomeModule,
    AppTablesModule,
    AppPointsBubbleModule,
    AppMenuComponent,
    AppFileUploadModule,
    EditorComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class AppStudentsModule {}
