import ThermalPrinterEncoder from 'thermal-printer-encoder';
import { StudentInfoPrintBuilder } from "./student-info-print-builder";

export class StudentInfoReceiptBuilder extends StudentInfoPrintBuilder {

  constructor(languageType: string) {
    super(languageType);
  }

  protected setEncoder(languageType: string): void {
    this.encoder = new ThermalPrinterEncoder({language: languageType});
  }

  public initializeEncoder(): void {
    this.encoder.initialize();
  }

  public buildStudentName(): StudentInfoReceiptBuilder {
    this.encoder.align('left').line(`First Name: ${this.data.firstName}`)
      .align('left').line(`Last Name: ${this.data.lastName}`);

    return this;
  }

  public buildStudentIdGrade(): StudentInfoReceiptBuilder {
    this.encoder.align('left').line(`Student ID#: ${this.getStudentId()}`)
      .align('left').line(`Grade: ${this.data.grade}`);

    return this;
  }


  public buildBarcode(): StudentInfoReceiptBuilder {
    const studentExtId = this.data.studentExtId;
    if (studentExtId && studentExtId.length) {
      this.encoder.barcode(studentExtId, 'code128', 60);
    }

    return this;
  }

}
