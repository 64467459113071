<div class="list-filters-content" tabindex="0" aria-label="Filters">
  <mat-form-field class="filter-item">
    <mat-label>Issued By Last Name</mat-label>
    <input
      data-test-id="ISSUED_BY_LAST_NAME_FILTER_INPUT"
      matInput
      [(ngModel)]="filters.reporter"
      (ngModelChange)="filtersUpdated.emit(filters)"
      aria-label="Issued By" />
  </mat-form-field>
  <mat-form-field *ngIf="behaviorCodeOptions.length" class="filter-item">
    <mat-select aria-label="Behavior Codes" placeholder="Behavior Code" id="BEHAVIOR_CODE_FILTER_OPTION">
      <mat-option
        aria-label="Behavior code option"
        *ngFor="let option of behaviorCodeOptions"
        options-id="BEHAVIOR_CODE_FILTER_OPTION"
        (onSelectionChange)="onFiltersUpdated('behaviorCodeId', $event)"
        [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="reactionOptions.length" class="filter-item">
    <mat-select aria-label="Reactions" placeholder="Reaction" id="REACTION_FILTER_OPTION">
      <mat-option
        aria-label="Reaction option"
        *ngFor="let option of reactionOptions"
        options-id="REACTION_FILTER_OPTION"
        (onSelectionChange)="onFiltersUpdated('reactionId', $event)"
        [value]="option.value">
        {{ option.display }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="filter-item">
    <mat-label>Start Date</mat-label>
    <input
      matInput
      [matDatepicker]="startDatePicker"
      type="text"
      (dateChange)="onStartDateUpdated($event)"
      aria-label="Start date picker"/>
    <mat-datepicker-toggle
      matSuffix
      aria-label="Start date picker toggle"
      [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="filter-item">
    <mat-label>End Date</mat-label>
    <input
      matInput
      [matDatepicker]="endDatePicker"
      type="text"
      (dateChange)="onEndDateUpdated($event)"
      aria-label="End date picker" />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"
      aria-label="End date picker toggle"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>
</div>
