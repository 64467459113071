import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  BehaviorCodeReactionCreateDto,
  BehaviorCodeReactionUpdateDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodeReactionCreateEditResolverData } from '../behavior-code-reactions-models/behavior-code-reaction-create-edit-resolver-data.interface';
import { plainToInstance } from 'class-transformer';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { ActivatedRoute, Router } from '@angular/router';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBehaviorCodeReactionsService } from '../behavior-code-reactions.service';
import { logger } from '../../../shared/logger';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';

@Component({
  selector: 'app-behavior-code-reaction-create-edit',
  templateUrl: './behavior-code-reaction-create-edit.template.html',
  styleUrls: ['./behavior-code-reaction-create-edit.scss']
})
export class AppBehaviorCodeReactionCreateEditComponent
  extends BaseComponent
  implements AfterViewInit
{
  public reactionOptions: IDisplayData[];
  public behaviorCodeId: number;
  public behaviorCodeReaction:
    | BehaviorCodeReactionCreateDto
    | BehaviorCodeReactionUpdateDto;
  public isCreating: boolean;
  public behaviorCodeReactionForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public behaviorCodeReactionId: number;
  public pageHeaderSubscription: Subscription;
  public isDefault: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public behaviorCodeReactionsService: AppBehaviorCodeReactionsService,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data: IBehaviorCodeReactionCreateEditResolverData =
      this.route.snapshot.data.data;
    this.behaviorCodeReactionId =
      +this.route.snapshot.params.behaviorCodeReactionId;
    this.behaviorCodeId = +this.route.snapshot.params.id;
    this.reactionOptions = data.reactionOptions;
    const behaviorCodeReaction = data.behaviorCodeReaction;
    this.isCreating = !behaviorCodeReaction.id;
    if (this.isCreating) {
      this.behaviorCodeReaction = plainToInstance(
        BehaviorCodeReactionCreateDto,
        behaviorCodeReaction,
        {
          exposeUnsetFields: true
        }
      );
      this.behaviorCodeReaction.triggerCount = 1;
    } else {
      this.behaviorCodeReaction = plainToInstance(
        BehaviorCodeReactionUpdateDto,
        behaviorCodeReaction
      );
    }
    this.behaviorCodeReactionForm = dtoToFormGroup(
      this.behaviorCodeReaction,
      this.formBuilder,
      {
        mapId: !this.isCreating
      }
    );
    if (this.behaviorCodeReaction.triggerCount === 0) {
      this.isDefault = true;
    }
    this.isLoading = false;
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.behaviorCodeReactionForm.valueChanges.subscribe(async (value) => {
        if (this.isCreating) {
          this.behaviorCodeReaction =
            await validateAndGetValue<BehaviorCodeReactionCreateDto>(
              this.behaviorCodeReactionForm,
              BehaviorCodeReactionCreateDto
            );
        } else {
          this.behaviorCodeReaction =
            await validateAndGetValue<BehaviorCodeReactionUpdateDto>(
              this.behaviorCodeReactionForm,
              BehaviorCodeReactionUpdateDto
            );
        }
      })
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public async saveChanges(): Promise<void> {
    if (formCanSave(this.behaviorCodeReactionForm, this.toastManager)) {
      try {
        if (
          this.behaviorCodeReaction instanceof BehaviorCodeReactionCreateDto
        ) {
          const behaviorCodeReaction =
            await this.behaviorCodeReactionsService.createBehaviorCodeReaction(
              this.behaviorCodeReaction
            );
          this.behaviorCodeReaction = plainToInstance(
            BehaviorCodeReactionUpdateDto,
            behaviorCodeReaction
          );
          await this.router.navigate(['/behavior-codes', this.behaviorCodeId]);
        } else {
          this.behaviorCodeReaction =
            await this.behaviorCodeReactionsService.updateBehaviorCodeReaction(
              this.behaviorCodeReaction,
              this.behaviorCodeReactionId
            );
        }
        this.toastManager.success('Behavior Code Reaction saved successfully!');
      } catch (err) {
        logger.error(err);
        if (err?.status === 409) {
          this.toastManager.error(err.error.message);
        } else {
          this.toastManager.error(
            'There was an error saving Behavior Code Reaction, please try again!'
          );
        }
      }
    }
  }
}
