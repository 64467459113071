<div class="summary-container">
  <div>
    <div class="total-behaviors-container blue-card-container">
      <ng-container *ngIf="!splitTotalBehaviors">
        <div class="total-behavior-counter-container">
          <span class="card-count">
            {{totalBehaviors}}
          </span>
          <span class="card-text">
            Total Behaviors
          </span>
        </div>
      </ng-container>
      <ng-container *ngIf="splitTotalBehaviors">
        <div class="total-behavior-counter-container" *ngFor="let behavior of totalBehaviorsCount">
          <span class="card-count">
            {{behavior.totalBehaviors}}
          </span>
          <span class="card-text">
            Total {{behavior.name  | titlecase}}
          </span>
        </div>
      </ng-container>
      <div class="more-info-ellipsis">
        <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
          <fa-icon [icon]="['fas', 'ellipsis-vertical']"></fa-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button type="button" mat-menu-item (click)="displaySplitBehaviors()">
            <span>{{splitTotalBehaviors ? 'Show Less': ' Show More'}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div>
    <div class="blue-card-container center-element column-direction">
      <span class="card-count">
        {{totalPoints}}
      </span>
      <span class="card-text">
        Total Points
      </span>
    </div>
  </div>
  <div>
    <div class="blue-card-container center-element column-direction">
      <span class="card-count">
        {{totalReferrals}}
      </span>
      <span class="card-text">
        Total Referrals
      </span>
    </div>
  </div>
</div>

