import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { CommonModule, DatePipe } from '@angular/common';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppFileUploadModule } from '../../shared/file-upload/file-upload.module';
import { AppPipesModule } from '../../shared/pipes/pipes.module';
import { AppExportSettingsCreateEdit } from './export-settings/export-settings-create-edit.component';
import { AppExportBuilderComponent } from './export-builder/export-builder.component';
import { AppExportBuilderTableComponent } from './export-builder/export-builder-table/export-builder-table.component';
import { AppLastExportsComponent } from './last-exports/last-exports.component';
import { AppTablesModule } from '../../shared/tables/tables.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppConfirmationDialogModule } from '../../shared/confirmation-dialog/confirmation-dialog.module';
import { AppInputsModule } from '../../shared/inputs/inputs.module';

@NgModule({
  declarations: [
    AppExportSettingsCreateEdit,
    AppExportBuilderComponent,
    AppExportBuilderTableComponent,
    AppLastExportsComponent
  ],
  exports: [AppExportSettingsCreateEdit, AppLastExportsComponent],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppPaginationModule,
    AppFileUploadModule,
    AppPipesModule,
    CommonModule,
    DirectiveModule,
    FontAwesomeModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    RouterModule,
    AppTablesModule,
    MatTooltipModule,
    AppConfirmationDialogModule,
    AppInputsModule
  ],
  providers: [DatePipe]
})
export class AppExportModule {}
