"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeResponseDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const shared_1 = require("../shared");
class BehaviorCodeResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.code = options.code;
        this.description = options.description;
        this.passMessage = options.passMessage;
        this.printPass = options.printPass;
        this.notificationTrigger = options.notificationTrigger;
        this.active = options.active;
        this.type = options.type;
        this.behaviorCodeTypeId = options.behaviorCodeTypeId;
        this.fastTrack = options.fastTrack;
        this.ordinal = options.ordinal;
        // this.notification = options.notification;
        this.school = options.school;
        this.externalReferenceCode = options.externalReferenceCode;
    }
    getFields() {
        return [
            'id',
            'code',
            'description',
            'passMessage',
            'printPass',
            'notificationTrigger',
            'active',
            'type',
            'behaviorCodeTypeId',
            'fastTrack',
            'ordinal',
            // 'notification',
            'externalReferenceCode',
            'school'
        ];
    }
    id;
    code;
    description;
    passMessage;
    printPass;
    notificationTrigger;
    active;
    type;
    behaviorCodeTypeId;
    fastTrack;
    ordinal;
    // @Expose()
    // notification: IRelationId;
    school;
    externalReferenceCode;
    static mapBehaviorCodes(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, BehaviorCodeResponseDto);
    }
}
exports.BehaviorCodeResponseDto = BehaviorCodeResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], BehaviorCodeResponseDto.prototype, "code", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], BehaviorCodeResponseDto.prototype, "description", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], BehaviorCodeResponseDto.prototype, "passMessage", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Boolean)
], BehaviorCodeResponseDto.prototype, "printPass", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeResponseDto.prototype, "notificationTrigger", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Boolean)
], BehaviorCodeResponseDto.prototype, "active", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], BehaviorCodeResponseDto.prototype, "type", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeResponseDto.prototype, "behaviorCodeTypeId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Boolean)
], BehaviorCodeResponseDto.prototype, "fastTrack", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], BehaviorCodeResponseDto.prototype, "ordinal", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", shared_1.RelationDto)
], BehaviorCodeResponseDto.prototype, "school", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], BehaviorCodeResponseDto.prototype, "externalReferenceCode", void 0);
