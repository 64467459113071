"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarDayResponseDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const isValidDate_decorator_1 = require("../shared/isValidDate.decorator");
const scheduled_reactions_1 = require("../scheduled-reactions");
const transform_config_1 = require("../../transform-config");
const shared_1 = require("../shared");
const student_group_bell_schedule_calendar_day_1 = require("../student-group-bell-schedule-calendar-day");
class CalendarDayResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.date = options.date;
        this.name = options.name;
        this.notes = options.notes;
        this.scheduledReactions = options.scheduledReactions;
    }
    getFields() {
        return [
            'id',
            'date',
            'name',
            'notes',
            'scheduledReactions',
            'studentGroupBellScheduleCalendarDays'
        ];
    }
    id;
    date;
    name;
    notes;
    scheduledReactions;
    studentGroupBellScheduleCalendarDays;
    static mapCalendarDays(entities) {
        return entities.map((entity) => {
            const calendarDay = new CalendarDayResponseDto();
            calendarDay.mapFields(entity);
            if (entity['studentGroupBellScheduleCalendarDays']) {
                calendarDay.studentGroupBellScheduleCalendarDays = entity['studentGroupBellScheduleCalendarDays'].map((studentGroupBellScheduleCalendarDay) => {
                    const studentGroupBellScheduleCalendarDayDto = new student_group_bell_schedule_calendar_day_1.StudentGroupBellScheduleCalendarDayResponse();
                    studentGroupBellScheduleCalendarDayDto.mapFields(studentGroupBellScheduleCalendarDay);
                    return studentGroupBellScheduleCalendarDayDto;
                });
            }
            if (entity['scheduledReactions']) {
                calendarDay.scheduledReactions = entity['scheduledReactions']
                    .filter((scheduledReaction) => {
                    const { reaction, reactionEntries } = scheduledReaction;
                    if (!reaction) {
                        return false;
                    }
                    const isInactiveAndEmpty = !reaction.active && reactionEntries.length === 0;
                    return !isInactiveAndEmpty;
                })
                    .map((scheduledReaction) => {
                    const scheduledReactionDto = new scheduled_reactions_1.ScheduledReactionResponseDto();
                    scheduledReactionDto.mapFields(scheduledReaction);
                    if (scheduledReaction['reaction']) {
                        const reactionDto = new shared_1.RelationDto();
                        reactionDto.mapFields(scheduledReaction['reaction']);
                        scheduledReactionDto.reaction = reactionDto;
                    }
                    return scheduledReactionDto;
                });
            }
            return calendarDay;
        });
    }
    getTransforms() {
        return [
            {
                destination: 'date',
                transform: transform_config_1.TransformConfig.transformDateToTimestamp('date')
            }
        ];
    }
}
exports.CalendarDayResponseDto = CalendarDayResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], CalendarDayResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, isValidDate_decorator_1.IsValidTimestamp)(),
    __metadata("design:type", Number)
], CalendarDayResponseDto.prototype, "date", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    __metadata("design:type", String)
], CalendarDayResponseDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], CalendarDayResponseDto.prototype, "notes", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], CalendarDayResponseDto.prototype, "scheduledReactions", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], CalendarDayResponseDto.prototype, "studentGroupBellScheduleCalendarDays", void 0);
