import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppMySettingsComponent } from './my-settings.component';
import { AppSaveBarModule } from '../../shared/save-bar/save-bar.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ResponsiveGridColumnsDirective } from '../../shared/responsive-grid-columns';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppPasswordDialogComponent } from '../../shared/password-dialog/password-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppColorProgressBarModule } from '../../shared/color-progress-bar/color-progress-bar.module';

@NgModule({
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppSaveBarModule,
    CommonModule,
    ReactiveFormsModule,
    ResponsiveGridColumnsDirective,
    RouterModule,
    DirectiveModule,
    FontAwesomeModule,
    AppColorProgressBarModule
  ],
  declarations: [AppMySettingsComponent, AppPasswordDialogComponent],
  exports: [AppMySettingsComponent]
})
export class AppMySettingsModule {}
