import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { BaseService } from '../../shared/base-classes/base.service';
import { Injectable } from '@angular/core';
import {
  LoginResponseDto,
  PaginationResultsDto,
  SchoolCreateDto,
  SchoolDetailResponseDto,
  SchoolResponseDto,
  SchoolTerm,
  SchoolUpdateDto
} from '@whetstoneeducation/hero-common';
import { ISchoolsListFilters } from './schools-list/schools-list-models/schools-list-filters.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../shared/logger';
import { ISchoolsListResolverData } from './schools-list/schools-list-models/schools-list-resolver-data.interface';

@Injectable({
  providedIn: 'root'
})
export class AppSchoolsServices extends BaseService {
  /**
   * School Services Constructor
   * @param appClientDataService
   * @param toastService
   */
  constructor(
    private appClientDataService: AppClientDataService,
    private toastService: AppToastManagerService
  ) {
    super();
  }

  public async getSchoolById(
    schoolId: number
  ): Promise<SchoolDetailResponseDto> {
    return await this.appClientDataService.execute<SchoolDetailResponseDto>(
      this.GET_ROUTES.SCHOOL_BY_ID,
      {
        pathParams: {
          id: schoolId
        }
      },
      SchoolDetailResponseDto
    );
  }

  public async getSchool(): Promise<SchoolResponseDto> {
    const userInfo: LoginResponseDto = this.StorageManager.getLoggedInUser();
    if (userInfo && userInfo.currentSchoolId) {
      const school = await this.getSchoolById(userInfo.currentSchoolId);
      return new SchoolResponseDto().mapFields(school);
    } else {
      this.toastService.warn('No district found! Are you logged in?');
      return null;
    }
  }

  public async getSchoolsOptions() {
    return this.appClientDataService.execute<SchoolResponseDto[]>(
      this.GET_ROUTES.SCHOOL_OPTIONS,
      {
        method: this.METHOD.GET
      },
      SchoolResponseDto
    );
  }

  public async getSchoolsList(
    filters: ISchoolsListFilters
  ): Promise<PaginationResultsDto<SchoolResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<SchoolResponseDto>
    >(
      this.GET_ROUTES.SCHOOL_LIST,
      {
        queryParams: {
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {}),
          ...(filters.searchInput
            ? {
                id: filters.searchInput,
                name: filters.searchInput,
                schoolGroupName: filters.searchInput,
                currentAcademicYear: filters.searchInput
              }
            : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {})
        }
      },
      SchoolResponseDto
    );
  }

  public getSchoolsListDefaultFilters(): ISchoolsListFilters {
    const defaultFilters: ISchoolsListFilters = {
      searchInput: null,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.SCHOOLS_LIST
      )
    };
    const currentUser = this.StorageManager.getLoggedInUser();
    if (currentUser.schoolGroupId)
      defaultFilters.schoolGroupId = currentUser.schoolGroupId;
    if (currentUser.currentSchoolId)
      defaultFilters.schoolId = currentUser.currentSchoolId;
    return defaultFilters;
  }

  public async getSchoolsListPageResolverData(): Promise<ISchoolsListResolverData> {
    try {
      const defaultFilters = this.getSchoolsListDefaultFilters();
      return {
        defaultFilters,
        schools: await this.getSchoolsList(defaultFilters)
      };
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading schools list');
      return null;
    }
  }

  public async createSchool(
    school: SchoolCreateDto
  ): Promise<SchoolDetailResponseDto> {
    return this.appClientDataService.execute<SchoolDetailResponseDto>(
      this.POST_ROUTES.CREATE_SCHOOL,
      {
        body: school,
        method: this.METHOD.POST
      },
      SchoolDetailResponseDto
    );
  }

  public async updateSchool(
    school: SchoolUpdateDto
  ): Promise<SchoolDetailResponseDto> {
    return this.appClientDataService.execute<SchoolDetailResponseDto>(
      this.PUT_ROUTES.UPDATE_SCHOOL,
      {
        body: school,
        method: this.METHOD.PUT,
        pathParams: {
          id: school.id
        }
      },
      SchoolDetailResponseDto
    );
  }

  public async getSchoolTerms(schoolId?: number): Promise<SchoolTerm[]> {
    return await this.appClientDataService.execute<SchoolTerm[]>(this.GET_ROUTES.SCHOOL_TERMS, {
      pathParams: {
        id: schoolId ?? this.getCurrentSchoolId(),
      },
    });
  }
}
