import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { IInterventionsListFilters } from './interventions-list/interventions-list-models/interventions-list-filters.interface';
import {
  InterventionCreateDto,
  InterventionResponseDto,
  InterventionUpdateDto,
  PaginationResultsDto,
} from '@whetstoneeducation/hero-common';
import { IReactionsListFilters } from '../reactions/reactions-list/reactions-list-models/reactions-list-filters.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';

@Injectable({
  providedIn: 'root',
})
export class AppInterventionsService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async getInterventionsList(
    filters: IInterventionsListFilters
  ): Promise<PaginationResultsDto<InterventionResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<InterventionResponseDto>
    >(this.GET_ROUTES.INTERVENTIONS_LIST, {
      queryParams: {
        ...(filters.searchInput
          ? { searchInput: filters.searchInput }
          : {}),
        ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {}),
        active: !filters.inactive,
      },
    });
  }

  public getInterventionsListDefaultFilters(): IInterventionsListFilters {
    const user = this.StorageManager.getLoggedInUser();
    return {
      searchInput: null,
      schoolId: user.currentSchoolId,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.INTERVENTIONS_LIST
      ),
      inactive: false,
    };
  }

  public async getInterventionById(
    id: number
  ): Promise<InterventionResponseDto> {
    return this.appClientDataService.execute<InterventionResponseDto>(
      this.GET_ROUTES.INTERVENTION_BY_ID,
      { pathParams: { id } }
    );
  }

  public async createIntervention(
    intervention: InterventionCreateDto
  ): Promise<InterventionResponseDto> {
    return this.appClientDataService.execute<InterventionResponseDto>(
      this.POST_ROUTES.CREATE_INTERVENTION,
      {
        method: this.METHOD.POST,
        body: intervention,
      }
    );
  }

  public async updateIntervention(
    intervention: InterventionUpdateDto,
    id: number
  ): Promise<InterventionResponseDto> {
    return this.appClientDataService.execute<InterventionResponseDto>(
      this.PATCH_ROUTES.EDIT_INTERVENTION,
      {
        method: this.METHOD.PATCH,
        pathParams: { id },
        body: intervention,
      }
    );
  }
}
