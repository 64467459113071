<div> <!--  class="table-container mat-elevation-z2" -->
  <div class="mat-table">
    <mat-table
      class="behavior-fields-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear
      cdkDropList
      (cdkDropListDropped)="dropped($event)">
      <!-- Reorder Column -->
      <ng-container matColumnDef="{{columns.REORDER}}">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let field">
          <fa-icon cdkDragHandle [icon]="['fas', 'bars']"></fa-icon>
        </mat-cell>
      </ng-container>

      <!-- Order Column -->
      <ng-container matColumnDef="{{columns.POSITION}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.POSITION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let field"> {{ field.order }} </mat-cell>
      </ng-container>

      <!-- field name Column -->
      <ng-container matColumnDef="{{columns.FIELDNAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.FIELDNAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let field"> {{ field.field }} </mat-cell>
      </ng-container>

      <!-- Column Header Column -->
      <ng-container matColumnDef="{{columns.COLUMNHEADER}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.COLUMNHEADER}}
        </mat-header-cell>
        <mat-cell *matCellDef="let field"> {{ field.header }} </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell class="action-column-header" *matHeaderCellDef>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let field">
          <button
            type="button"
            mat-flat-button
            class="action-button icon-button"
            options-id="EDIT_BEHAVIOR_field"
            (click)="removeField(field)">
            <fa-icon [icon]='["fas", "trash"]'></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row
        cdkDrag
        [cdkDragData]="field"
        *matRowDef="let field; columns: displayedColumns;"></mat-row>
        <ng-container matColumnDef="noData">
          <mat-footer-cell *matFooterCellDef>
            Add Fields to Start
          </mat-footer-cell>
        </ng-container>
        <mat-footer-row
          *matFooterRowDef="dataArr?.length || isLoading ? [] : ['noData']"
          class="empty-footer"
          [class.hidden-row]="dataArr?.length || isLoading"></mat-footer-row>
      
    </mat-table>
  </div>
</div>
