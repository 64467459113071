import { BaseService } from '../../shared/base-classes/base.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  PaginationResultsDto,
  RoleCreateDto,
  RoleResponseDto,
  RoleUpdateDto
} from '@whetstoneeducation/hero-common';
import { Injectable } from '@angular/core';
import { IRolesListFilters } from './roles-list/roles-list-models/roles-list-filters.interface';

@Injectable({ providedIn: 'root' })
export class AppRolesServices extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private toastService: AppToastManagerService
  ) {
    super();
  }

  public async getRoleById(roleId: number): Promise<RoleResponseDto> {
    return this.appClientDataService.execute<RoleResponseDto>(
      this.GET_ROUTES.ROLE_BY_ID,
      {
        pathParams: {
          id: roleId
        }
      },
      RoleResponseDto
    );
  }

  public async getRoles(
    filters: IRolesListFilters
  ): Promise<PaginationResultsDto<RoleResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<RoleResponseDto>
    >(
      this.GET_ROUTES.ROLE_LIST,
      {
        queryParams: {
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.name ? { name: filters.name } : {}),
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {})
        }
      },
      RoleResponseDto
    );
  }

  public async createRole(role: RoleCreateDto): Promise<RoleResponseDto> {
    return this.appClientDataService.execute<RoleResponseDto>(
      this.POST_ROUTES.CREATE_ROLE,
      {
        body: role,
        method: 'POST'
      },
      RoleResponseDto
    );
  }

  public async updateRole(role: RoleUpdateDto): Promise<RoleResponseDto> {
    return this.appClientDataService.execute<RoleResponseDto>(
      this.PUT_ROUTES.UPDATE_ROLE,
      {
        pathParams: {
          id: role.id
        },
        method: 'PUT',
        body: role
      },
      RoleResponseDto
    );
  }
}
