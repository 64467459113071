import { Component, DoCheck, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { IStudentsListFilters } from '../../students-list-models/students-list-filters.interface';
import { PrivilegeEnum } from '@whetstoneeducation/hero-common';
import { AppPrivilegesService } from '../../../auth/privileges.service';

@Component({
  selector: 'app-students-list-filters',
  templateUrl: './students-list-filters.template.html',
  styleUrls: ['./students-list-filters.scss']
})
export class AppStudentsListFiltersComponent extends BaseComponent {
  @Input() filters: IStudentsListFilters;
  @Input() showTrackButton: boolean;
  @Input() selectedStudentIds: number[];
  @Output() filtersUpdated: EventEmitter<IStudentsListFilters> =
    new EventEmitter<IStudentsListFilters>();
  @Output() showTrackModalEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() showFastTrackModalEvent: EventEmitter<void> =
    new EventEmitter<void>();


  constructor(privilegesService: AppPrivilegesService) {
    super({ privilegesService });
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
