import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IDisplayData } from '@whetstoneeducation/hero-common';

/**
 * Multiple Select Component. Abstracts SelectAll/DeselectAll for you, as well as displaying data.
 */
@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.template.html',
  styleUrls: ['./multiple-select.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppMultipleSelectComponent implements OnInit, OnChanges {
  /*
   **********************************************
   *                 Data I/O                   *
   **********************************************
   */

  /**
   * What options can we choose from
   */
  @Input()
  public options: IDisplayData[];

  /**
   * When autocomplete is working, the list of selectableData gets filtered into here.
   */
  public filteredOptions: IDisplayData[];

  /**
   * What values should be selected
   */
  @Input()
  public selected: any[];

  /**
   * What values should be selected. In a dictionary format for better performance
   */
  public selectedDictionary: { [value: string]: boolean };

  /**
   * On selected update, emit our selected values
   */
  @Output()
  public selectedUpdated: EventEmitter<any[]>;

  /*
   **********************************************
   *                  Config                    *
   **********************************************
   */

  /**
   * Label that goes on top of mat select
   * // TODO: This value isn't used in the template anywhere.
   */
  @Input()
  public label: string;

  /**
   * Displays when all selected
   */
  @Input()
  public allSelectedString: string;

  /**
   * Displays when none are selected
   */
  @Input()
  public noneSelectedString: string;

  /**
   * Display when there are no options to choose
   */
  @Input()
  public noneAvailableString: string;

  /**
   * The placeholder for the autocomplete input
   */
  @Input()
  public autocompletePlaceholder: string;

  /**
   * Should we update on empty selector, or throw errors and not update until fixed.
   */
  @Input()
  public canBeEmpty: boolean;

  /**
   * Select All By Default
   */
  @Input()
  public allByDefault: boolean;

  /**
   * Select None By Default
   */
  @Input()
  public noneByDefault: boolean;

  /**
   * If all items our selected, we should emit null, instead of a full list.
   */
  @Input()
  public allSelectedEqualsNull: boolean;

  /**
   * Should we disable the select all button
   */
  @Input()
  public disableSelectAll: boolean;

  /**
   * Should we use autocomplete
   */
  @Input()
  public useAutocomplete: boolean;

  /**
   * Should display our input in error mode
   */
  @Input()
  public errorMode: boolean;

  /**
   * Determines if the select should be disabled
   */
  @Input()
  public disabled: boolean;

  /*
   **********************************************
   *                List Config                 *
   **********************************************
   */

  /**
   * Display our options in a list instead of the typical dropdown style
   */
  @Input()
  public useListStyle: boolean;

  /**
   * Should we show our list control buttons (Show Selected / Select All / Clear )
   */
  @Input()
  public showListControlButtons: boolean;

  /**
   * Should we enforce a max height and let the user scroll through the list
   */
  @Input()
  public scrollOverflow: boolean;

  /*
   **********************************************
   *             Local Variables                *
   **********************************************
   */

  /**
   * Reference to the autocomplete input. Used to focus on open.
   */
  @ViewChild('autocompleteInput')
  public autocompleteInput: ElementRef;

  /**
   * The key we are filtering options by
   */
  public filterKey: string;

  /**
   * Are we viewing just selected options
   */
  public viewingSelectedOptions: boolean;

  /*
   **********************************************
   *             Lifecycle Methods              *
   **********************************************
   */

  /**
   * Default Constructor
   */
  constructor() {
    this.options = [];
    this.filteredOptions = [];
    this.selected = [];
    this.selectedDictionary = {};
    this.selectedUpdated = new EventEmitter<any[]>();

    this.label = '';
    this.allSelectedString = 'All Selected';
    this.noneSelectedString = 'None Selected';
    this.noneAvailableString = 'No Options Available';
    this.autocompletePlaceholder = 'Filter Options...';
    this.canBeEmpty = false;
    this.allByDefault = true;
    this.noneByDefault = false;
    this.allSelectedEqualsNull = true;
    this.disableSelectAll = false;
    this.useAutocomplete = true;
    this.useListStyle = false;
    this.showListControlButtons = true;
    this.scrollOverflow = true;

    this.filterKey = '';
    this.viewingSelectedOptions = false;
  }

  ngOnInit() {
    this.filterOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected) {
      const { previousValue, currentValue } = changes.selected;
      if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        this.selectedChanged();
      }
    }
    if (changes.options) {
      const { previousValue, currentValue } = changes.options;
      if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        this.optionsChanged();
      }
    }
  }

  /*
   **********************************************
   *             Change Data Methods            *
   **********************************************
   */

  /**
   * On selected changed, rebuild our dictionary and
   */
  public selectedChanged() {
    if (!this.selected) {
      if (this.allSelectedEqualsNull) {
        this.selected = this.options?.map((opt) => opt.value) || [];
        this.selectAll();
      } else {
        this.selected = [];
        this.deselectAll();
      }
    } else {
      const newSelectedDict = {};
      this.selected?.forEach((value) => {
        newSelectedDict[value] = true;
      });
      this.selectedDictionary = newSelectedDict;
    }
    // If viewing selected options, get the list which we are viewing again
    if (this.viewingSelectedOptions) {
      this.viewSelected();
    }
  }

  /**
   * On options changed, handle selecting/deselecting all options based on config
   */
  public optionsChanged() {
    if (this.allByDefault) {
      this.selected = this.options?.map((opt) => opt.value);
      this.selectAll();
    } else if (this.noneByDefault) {
      this.selected = [];
      this.deselectAll();
    } else {
      const newSelectedDictionary = {};
      this.selected?.forEach((selectedValue) => {
        newSelectedDictionary[selectedValue] = true;
      });
      this.selectedDictionary = newSelectedDictionary;
    }
    this.filterOptions();
  }

  /**
   * Toggle the included status of a specific option
   */
  public toggleOption(option: IDisplayData) {
    const isSelected = this.selectedDictionary[option.value];
    if (isSelected) {
      delete this.selectedDictionary[option.value];
    } else {
      this.selectedDictionary[option.value] = true;
    }
    // If viewing selected options, get the list which we are viewing again
    if (this.viewingSelectedOptions) {
      this.viewSelected();
    }
  }

  /**
   * Toggle between selected all and none selected
   */
  public toggleSelectAll() {
    if (this.areAllOptionsSelected()) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  /*
   **********************************************
   *                Emit Methods                *
   **********************************************
   */

  /**
   * On select opened, focus our input
   */
  public handleSelectOpened() {
    if (this.useAutocomplete) {
      this.autocompleteInput.nativeElement.focus();
      this.filterKey = '';
      this.filterOptions();
    }
  }

  /**
   * On close, if our data changed, send our new updates, and reset autocomplete filters
   */
  public handleSelectClosed() {
    const dataChanged =
      JSON.stringify(this.selected) !==
      JSON.stringify(this.getNewSelectedList());
    if (dataChanged) {
      this.emitData();
      this.selected = this.getNewSelectedList();
    }
    if (this.useAutocomplete) {
      this.filterKey = '';
      this.filterOptions();
    }
  }

  /**
   * On list changed, emit our new data if it changed
   */
  public handleListChanged() {
    const dataChanged =
      JSON.stringify(this.selected) !==
      JSON.stringify(this.getNewSelectedList());
    if (dataChanged) {
      this.emitData();
      this.selected = this.getNewSelectedList();
    }
  }

  /**
   * Emits our new data
   */
  public emitData() {
    if (this.allSelectedEqualsNull && this.areAllOptionsSelected()) {
      this.selectedUpdated.emit(null);
    } else {
      this.selectedUpdated.emit(this.getNewSelectedList());
    }
  }

  /*
   **********************************************
   *             Helper Methods                 *
   **********************************************
   */

  /**
   * Set our select array to empty.
   */
  public deselectAll(): void {
    this.selectedDictionary = {};
  }

  /**
   * Set our array to have SelectAll + All options
   */
  public selectAll(): void {
    const newSelectedDict = {};
    this.options?.forEach((option) => {
      newSelectedDict[option.value] = true;
    });
    this.selectedDictionary = newSelectedDict;
  }

  /**
   * View just our selected options
   */
  public viewSelected() {
    this.filteredOptions =
      this.options?.filter((option) => this.selectedDictionary[option.value]) ||
      [];
    this.viewingSelectedOptions = true;
  }

  /**
   * View all options
   */
  public viewAll() {
    this.filteredOptions = this.options || [];
    this.viewingSelectedOptions = false;
  }

  /**
   * Get our new selected list from our selected dictionary
   */
  public getNewSelectedList(): any[] {
    return Object.keys(this.selectedDictionary);
  }

  /**
   * Are all options selected
   */
  public areAllOptionsSelected() {
    return this.getNewSelectedList().length === this.options?.length;
  }

  /**
   * Should we display 'All Selected', 'None Selected', or '5 selected'
   */
  public getSelectedString(): string {
    if (this.areAllOptionsSelected()) {
      return this.allSelectedString;
    } else {
      const selectedCount = this.selected?.length || 0;
      if (selectedCount === 0) {
        return this.noneSelectedString;
      } else if (selectedCount === 1) {
        const selectedOptions = this.getSelectedOptions();
        return selectedOptions[0]?.display || 'Selected Not Found';
      } else {
        return selectedCount + ' Selected';
      }
    }
  }

  /**
   * Get a list of all of the options we have selected in their original IDisplayData format
   */
  public getSelectedOptions(): IDisplayData[] {
    return this.selected.map((selectedValue) =>
      this.options.find(
        (option) => option.value?.toString() === selectedValue?.toString()
      )
    );
  }

  /**
   * Filter our options based on our filterKey
   */
  public async filterOptions() {
    const filterValue = this.filterKey.toLowerCase();
    this.filteredOptions =
      this.options?.filter(
        (option) =>
          option.display &&
          option.display.toLowerCase().indexOf(filterValue) > -1
      ) || [];
    this.viewingSelectedOptions = false;
  }
}
