"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeEntryResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
class BehaviorCodeEntryResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'time',
            'notes',
            'externalCode',
            'rollbackDate',
            'student',
            'reporter',
            'schoolId',
            'behaviorCodeName',
            'behaviorCodeId',
            'behaviorCodeTypeId',
            'reactionEntries'
        ];
    }
    mapFields(source, skipNested = false, skipTransforms = false) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    id;
    time;
    notes;
    externalCode;
    rollbackDate;
    student;
    reporter;
    behaviorCodeName;
    behaviorCodeId;
    behaviorCodeTypeId;
    schoolId;
    reactionEntries;
    static mapBehaviorCodeEntry(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, BehaviorCodeEntryResponseDto);
    }
}
exports.BehaviorCodeEntryResponseDto = BehaviorCodeEntryResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryResponseDto.prototype, "time", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], BehaviorCodeEntryResponseDto.prototype, "notes", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], BehaviorCodeEntryResponseDto.prototype, "externalCode", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryResponseDto.prototype, "rollbackDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], BehaviorCodeEntryResponseDto.prototype, "student", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], BehaviorCodeEntryResponseDto.prototype, "reporter", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], BehaviorCodeEntryResponseDto.prototype, "behaviorCodeName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryResponseDto.prototype, "behaviorCodeId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryResponseDto.prototype, "behaviorCodeTypeId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], BehaviorCodeEntryResponseDto.prototype, "reactionEntries", void 0);
