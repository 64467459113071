<div>
  <mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Student Imports">
      <div>
        <apx-chart
          *ngIf="selectedTab === 0"
          id="sftpStudentSummaryChart"
          [series]="studentChartOptions.series"
          [chart]="studentChartOptions.chart"
          [xaxis]="studentChartOptions.xaxis"
          [dataLabels]="studentChartOptions.dataLabels"
          [plotOptions]="studentChartOptions.plotOptions"
          [responsive]="studentChartOptions.responsive"
          [legend]="studentChartOptions.legend"
          [fill]="studentChartOptions.fill"></apx-chart>
      </div>
    </mat-tab>
    <mat-tab label="User Imports">
      <apx-chart
        *ngIf="selectedTab === 1"
        id="sftpUserSummaryChart"
        [series]="userChartOptions.series"
        [chart]="userChartOptions.chart"
        [xaxis]="userChartOptions.xaxis"
        [dataLabels]="userChartOptions.dataLabels"
        [plotOptions]="userChartOptions.plotOptions"
        [responsive]="userChartOptions.responsive"
        [legend]="userChartOptions.legend"
        [fill]="userChartOptions.fill"></apx-chart>
    </mat-tab>
    <mat-tab label="Schedule Imports">
      <apx-chart
        *ngIf="selectedTab === 2"
        id="sftpScheduleSummaryChart"
        [series]="scheduleChartOptions.series"
        [chart]="scheduleChartOptions.chart"
        [xaxis]="scheduleChartOptions.xaxis"
        [dataLabels]="scheduleChartOptions.dataLabels"
        [plotOptions]="scheduleChartOptions.plotOptions"
        [responsive]="scheduleChartOptions.responsive"
        [legend]="scheduleChartOptions.legend"
        [fill]="scheduleChartOptions.fill"></apx-chart>
    </mat-tab>
  </mat-tab-group>
  <div class="import-list">
    <app-sftp-import-list></app-sftp-import-list>
  </div>
</div>
