import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { ISchoolGroupsListFilters } from '../school-groups-list-models/school-groups-list-filters.interface';

@Component({
selector: 'app-school-groups-list-filters',
templateUrl: './school-groups-list-filters.template.html',
styleUrls: ['./school-groups-list-filters.scss'],
})
export class AppSchoolGroupsListFiltersComponent extends BaseComponent {
  @Input() filters: ISchoolGroupsListFilters;

  @Output() filtersUpdated: EventEmitter<ISchoolGroupsListFilters>;

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<ISchoolGroupsListFilters>();
  }
}
