import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CalendarApi, CalendarOptions, DatesSetArg } from '@fullcalendar/core';
import { FullCalendarViewTypes } from './full-calendar-view-types.interface';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { AppTimezoneService } from 'src/app/shared/services/timezone.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { AppCalendarViewHtmlContentService } from './calendar-view-html-content.service';
import { CalendarDayResponseDto } from '@whetstoneeducation/hero-common';
import { AppCalendarService } from '../../calendar.service';
import { ICalendarViewFilters } from '../calendar-view-models/calendar-view-filters.interface';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../../shared/base-classes/base.component';

@Component({
  selector: 'app-calendar-view-full',
  templateUrl: './calendar-view-full.template.html',
  styleUrls: ['./calendar-full.scss']
})
export class AppCalendarViewFullComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  /**
   * Hold a ref to the full calendar, so we get the API to interact with
   */
  @ViewChild('calendarRef') calendarRef: FullCalendarComponent;
  public get calendarApi(): CalendarApi {
    return this.calendarRef?.getApi()?.view?.calendar;
  }

  @Input() public calendarDays: CalendarDayResponseDto[];
  @Input() public isLoading: boolean;
  @Input() public filters: ICalendarViewFilters;
  @Output() public dateSelected = new EventEmitter<number>();
  @Input() public showFilters: boolean;
  @Output() public updateFiltersVisibility = new EventEmitter<boolean>();
  @Input() viewType: FullCalendarViewTypes;
  @Output() public datesViewingUpdated = new EventEmitter<{
    endDate: number;
    startDate: number;
  }>();

  constructor(
    public timeZoneService: AppTimezoneService,
    public calendarViewHtmlContentService: AppCalendarViewHtmlContentService,
    public calendarService: AppCalendarService,
    public router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.calendarDays) {
      this.calendarOptions.events = this.calendarDays.reduce(
        (acc, calendarDay) => [
          ...acc,
          ...this.calendarService.calendarDayToFullCalendarEvents(
            calendarDay,
            this.filters
          )
        ],
        []
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.calendarDays) {
      this.calendarOptions.events = changes.calendarDays.currentValue.reduce(
        (acc, calendarDay) => [
          ...acc,
          ...this.calendarService.calendarDayToFullCalendarEvents(
            calendarDay,
            this.filters
          )
        ],
        []
      );
    }

    if (changes.filters) {
      this.calendarOptions.events = this.calendarDays.reduce(
        (acc, calendarDay) => [
          ...acc,
          ...this.calendarService.calendarDayToFullCalendarEvents(
            calendarDay,
            changes.filters.currentValue
          )
        ],
        []
      );
    }
  }

  public calendarOptions: CalendarOptions = {
    dateClick: ({ date }) => {
      this.dateSelected.emit(date.getTime());
    },
    weekends: true,
    editable: false,
    events: [],
    now: this.timeZoneService.convertTimezoneDateToLocalDate(new Date()),
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridWeek,dayGridMonth'
    },
    eventContent: (props) =>
      this.calendarViewHtmlContentService.getHtmlContent(props),
    eventClick: (event) => {
      this.dateSelected.emit(event.event.start.getTime());
    }
  };
}
