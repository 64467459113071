import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppReactionsListComponent } from './reactions-list/reactions-list.component';
import { AppReactionsListFiltersComponent } from './reactions-list/reactions-list-filters/reactions-list-filters.component';
import { AppReactionsListTableComponent } from './reactions-list/reactions-list-table/reactions-list-table.component';
import { AppReactionsCreateComponent } from './reactions-create/reactions-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { RouterLink } from '@angular/router';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppMultipleSelectModule } from '../../shared/dropdowns/multi-select/multiple-select.module';
import { AppReactionsEditComponent } from './reactions-edit/reactions-edit.component';
import { AppScheduledReactionTableComponent } from './scheduled-reactions-table/scheduled-reaction-table.component';
import { AppReactionsEditDaysComponent } from './reactions-edit-days/reaction-edit-days.component';
import { AppDuplicateReactionComponent } from './duplicate-reaction/duplicate-reaction.component';

@NgModule({
  declarations: [
    AppReactionsListComponent,
    AppReactionsListFiltersComponent,
    AppReactionsListTableComponent,
    AppReactionsCreateComponent,
    AppReactionsEditComponent,
    AppScheduledReactionTableComponent,
    AppReactionsEditDaysComponent,
    AppDuplicateReactionComponent,
  ],
  exports: [],
  imports: [
    AppMaterialModule,
    AppMultipleSelectModule,
    FontAwesomeModule,
    AppPaginationModule,
    ReactiveFormsModule,
    AppErrorDisplayComponent,
    RouterLink,
    DirectiveModule,
    FormsModule
  ]
})
export class AppReactionsModule {}
