import { Injectable } from "@angular/core";
import { PrinterService } from "../../../shared/printer/printer.service";
import { StudentDetailResponseDto } from "@whetstoneeducation/hero-common";
import { BasePrintService } from "../../../shared/printer/base-print.service";
import { StudentInfoPrintBuilder } from "./student-info-print-builder";
import { StudentInfoPrintBuilderFactory } from "./student-info-print-builder.factory";


@Injectable({
  providedIn: 'root'
})
export class StudentInfoPrintService extends BasePrintService<StudentInfoPrintBuilder, StudentDetailResponseDto> {

  constructor(
    private printerService: PrinterService
  ) {
    super({
      printer: printerService,
      factory: new StudentInfoPrintBuilderFactory(),
    });
  }

  protected getBuildPrintData(): StudentInfoPrintBuilder {
    return this.builder
      .buildStudentName()
      .buildStudentIdGrade()
      .buildBarcode();
  }
}
