import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../base-classes/base.component';
import { UserPasswordDto } from '@whetstoneeducation/hero-common';
import { Subscription } from 'rxjs';
import * as zxcvbn from 'zxcvbn';
import { AppToastManagerService } from '../services/toast-manager.service';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../validation/validation.util';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.template.html',
  styleUrls: ['./password-dialog.scss']
})
export class AppPasswordDialogComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public form: FormGroup;
  public passwordDto: UserPasswordDto;
  public email: string;
  public firstName: string;
  public lastName: string;
  public valueChangesSubscription: Subscription;
  public passwordStrength: number;
  public hidePassword: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AppPasswordDialogComponent>,
    private fb: FormBuilder,
    private toastService: AppToastManagerService
  ) {
    super();
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.passwordDto = new UserPasswordDto();
    this.form = dtoToFormGroup(this.passwordDto, this.fb);
  }

  ngAfterViewInit(): void {
    this.valueChangesSubscription = this.form.valueChanges.subscribe(
      async () => {
        this.passwordDto = await validateAndGetValue<UserPasswordDto>(
          this.form,
          UserPasswordDto
        );
      }
    );
  }

  async checkPasswordStrength(event: any) {
    // Get the current value from the event
    let password = event.target.value;
    let userInputs = [this.email, this.firstName, this.lastName];
    if (!password) password = '';
    let result = zxcvbn(password, userInputs);
    this.passwordStrength = result.score;
    if (password.length < 7) {
      this.form.controls['password'].setErrors(null);
      this.form.controls['passwordConfirm'].setErrors(null);
    }
  }

  getProgressBarColor() {
    if (this.passwordStrength <= 2) {
      return 'error';
    } else if (this.passwordStrength === 3) {
      return 'warn';
    } else {
      return 'success';
    }
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe();
  }

  save(): void {
    if (formCanSave(this.form, this.toastService)) {
      this.dialogRef.close(this.passwordDto);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
