import Handlebars from 'handlebars';
import { ReactionEntriesListResponseDto } from '@whetstoneeducation/hero-common';
import dayjs from 'dayjs';

export interface StudentAccountPrintOptions {
  onePerPage: boolean; // If true, print one entry per page; if false, print multiple entries per page
}

export function browserPrintSubjectBody(subject: string, body: string): void {
  const printWindow = window.open('', '', 'width=800,height=600');
  if (printWindow) {
    printWindow.document.open();
    printWindow.document.write(`
        <html lang="en-US">
          <head>
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
              body {
                font-family: 'Open Sans', sans-serif;
              }
            </style>
            <title>${subject}</title>
          </head>
          <body onload="window.print();window.close()">
            ${body}
          </body>
        </html>
      `);
    printWindow.document.close();
  } else {
    // show alert letting user know that popups are blocked
    alert(
      'Popups are blocked. Please allow popups for this site to enable printing.'
    );
    throw new Error('popups likely blocked');
  }
}

const studentLoginInstructions = `
    <div class="instructions">
      <p>To access your account, follow these steps:</p>
      <ol>
        <li>Visit <a href="https://hero.schoolmint.com">hero.schoolmint.com</a>.</li>
        <li>Enter your email: <strong>{{email}}</strong> and the password provided above.</li>
        <li>Click on the login button.</li>
        <li>Follow the instructions to complete the login process.</li>
      </ol>
    </div>`;

const studentPasswordResetStyling = `
  <style>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
    body {
      font-family: 'Open Sans', sans-serif;
    }
    @media print {
      .page-break {
        page-break-before: always;
      }
      .entry {
        margin-bottom: 20px;
        border-bottom: {{options.borderBottom}};
        padding-bottom: {{options.paddingBottom}};
      }
    }
    .instructions {
      font-size: 14px;
      margin-top: 20px;
    }
  </style>`;

export function printSingleStudentPassword(
  email: string,
  password: string
): void {
  const template = `
    <html lang="en-US">
      <head>
        <title>Account Information</title>
        ${studentPasswordResetStyling}
      </head>
      <body>
        <div class="entry">
          <h1>{{email}}</h1>
          <p>Your new password is: <strong>{{password}}</strong></p>
          ${studentLoginInstructions}
        </div>
      </body>
    </html>`;

  const data = {
    email,
    password,
    options: {
      borderBottom: 'none',
      paddingBottom: '20px'
    }
  };
  printHandlebarsTemplate(template, data);
}

export function printPass(reaction: ReactionEntriesListResponseDto): void {
  const styling = `
  <style>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128&family=Libre+Barcode+39+Extended+Text&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
    body {
      font-family: 'Open Sans', sans-serif;
    }
    @media print {
      .page-break {
        page-break-before: always;
      }
      .entry {
        margin-bottom: 20px;
        border-bottom: {{options.borderBottom}};
        padding-bottom: {{options.paddingBottom}};
      }
    }
    .barcode {
      margin-top: 20px;
      font-family: "Libre Barcode 39 Extended Text", system-ui;
      font-weight: 400;
      font-style: normal;
      font-size: 72px;
    }

  </style>`;

  const template = `
    <html lang="en-US">
      <head>
        <title>{{schoolName}}</title>
        ${styling}
      </head>
      <body>
        <div class="entry">
          <h1>{{schoolName}}</h1>
          <h2>{{behaviorCodeName}}</h2>
          <!-- table of student information -->
          <table>
            <tr>
              <td>Student ID#:</td>
              <td>{{studentExtId}}</td>
              <td>Entries:</td>
              <td>{{entries}}</td>
            </tr>
            <tr>
              <td>Student Name:</td>
              <td>{{studentName}}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Entry Time:</td>
              <td>{{entryTime}}</td>
              <td>Entry Date:</td>
              <td>{{entryDate}}</td>
            </tr>
            <tr>
              <td>During:</td>
              <td>{{period}}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Action Type:</td>
              <td>{{reactionName}}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Action Date:</td>
              <td>{{reactionDate}}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Points:{{points}}</td>
              <td>Total Points:{{totalPoints}}</td>
              <td></td>
            </tr>
          </table>
          <div class="barcode">
            <!-- barcode font use code39 -->
            <p>{{studentExtId}}</p>
          </div>
        </div>
      </body>
    </html>
    `;
  const data = {
    schoolName: reaction.schoolName,
    behaviorCodeName: reaction.behaviorCodeName,
    studentExtId: reaction.studentExtId,
    entries: reaction.behaviorEntries,
    studentName: reaction.studentName,
    entryTime: dayjs(reaction.behaviorEntryDate).format('h:mm A'), // Extract time in 24-hour format
    entryDate: dayjs(reaction.behaviorEntryDate).format('YYYY-MM-DD'), // Extract date in ISO format
    period: reaction.period,
    reactionName: reaction.reactionName,
    reactionDate: reaction.reactionDate,
    points: reaction.reactionPoints,
    totalPoints: reaction.studentTotalPoints,
    options: {
      borderBottom: 'none',
      paddingBottom: '20px'
    }
  };

  printHandlebarsTemplate(template, data, reaction.printCopies);
}

function printHandlebarsTemplate(
  rawTemplate: string,
  data: any,
  copies = 1
): void {
  const printWindow = window.open('', '', 'width=800,height=600');
  if (printWindow) {
    printWindow.document.open();
    // Compile the Handlebars template
    const compiledTemplate = Handlebars.compile(rawTemplate);

    // Generate the HTML content using the compiled template
    const documentContent = compiledTemplate(data);

    // Write the generated HTML content to the new window
    printWindow.document.write(documentContent);
    printWindow.document.close();

    // Trigger the print dialog once the content is loaded
    printWindow.onload = () => {
      for (let i = 0; i < copies; i++) {
        printWindow.print();
      }
      printWindow.close();
    };
  } else {
    // show alert letting user know that popups are blocked
    alert(
      'Popups are blocked. Please allow popups for this site to enable printing.'
    );
    throw new Error('popups likely blocked');
  }
}

export function printBulkStudentPasswords(
  emailPasswordMap: Map<string, string>,
  options: StudentAccountPrintOptions
): void {
  // Define the Handlebars template
  const template = `
        <html lang="en-US">
          <head>
            <title>Account Information</title>
            ${studentPasswordResetStyling}
          </head>
          <body>
            {{#each entries}}
              <div class="entry">
                <h1>{{email}}</h1>
                <p>Your new password is: <strong>{{password}}</strong></p>
                ${studentLoginInstructions}
              </div>
              {{#if ../options.pageBreak}}
                <div class="page-break"></div>
              {{/if}}
            {{/each}}
          </body>
        </html>`;

  const borderBottom = options.onePerPage ? 'none' : '1px dashed #000';
  const data = {
    // Convert the Map to an array of objects for Handlebars
    entries: Array.from(emailPasswordMap, ([email, password]) => ({
      email,
      password
    })),
    options: {
      ...options,
      borderBottom,
      paddingBottom: '40px',
      pageBreak: borderBottom === 'none'
    }
  };

  // Print the email template
  printHandlebarsTemplate(template, data);
}
