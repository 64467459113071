"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsName = void 0;
var SettingsName;
(function (SettingsName) {
    SettingsName["TIMEZONE"] = "timezone";
    SettingsName["COURSE_SCHEDULE_IMPORT_SEQUENCE"] = "course_schedule_import_sequence";
    SettingsName["STUDENT_IMPORT_SEQUENCE"] = "student_import_sequence";
    SettingsName["INCIDENT"] = "incident";
})(SettingsName || (exports.SettingsName = SettingsName = {}));
