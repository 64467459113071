import { logger } from 'src/app/shared/logger';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppWindowService } from 'src/app/shared/services/app-window.service';
import { AppUsersServices } from '../../pages/users/users.service';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppSettingsService } from '../services/settings.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';

const ZENDESK_APP_KEY = '2b95f3f3-5d43-487f-bd90-6504ffbed466';

// TODO need to add highestRole to walkme and zendesk
// TODO need custom form prefill id for zendesk

/**
 * Service used to get data for the SupportButtons component
 */
@Injectable({
  providedIn: 'root'
})
export class AppSupportButtonsService extends BaseService {
  /**
   * Default Constructor
   */
  public constructor(
    public settingsService: AppSettingsService,
    public usersService: AppUsersServices,
    public window: AppWindowService,
    public toastManager: AppToastManagerService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) public document: Document
  ) {
    super();
  }

  /**
   * Initialize walk me on our window object
   */
  public async initWalkMe(): Promise<void> {
    const user = this.StorageManager.getLoggedInUser();
    if (user) {
      const window = this.window.nativeWindow;
      if (!window.WalkMe) {
        window.WalkMeCommandObject = 'WalkMe';
        window.WalkMe = function () {
          if (!window.WalkMe.q) {
            window.WalkMe.q = [];
          }
          window.WalkMe.q.push(arguments);
        };

        const createdScript = this.document.createElement('script');
        const firstScript = this.document.getElementsByTagName('script')[0];
        createdScript.async = true;
        //dev and local get the testing walkme snippet whereas prod and QA get the live snippet
        createdScript.src =
          this.window.isProduction() || this.window.isQA()
            ? 'https://cdn.walkme.com/users/b1164b1c804b42ce8f602ebd870f7f51/walkme_b1164b1c804b42ce8f602ebd870f7f51_https.js'
            : 'https://cdn.walkme.com/users/b1164b1c804b42ce8f602ebd870f7f51/test/walkme_b1164b1c804b42ce8f602ebd870f7f51_https.js';
        firstScript.parentNode.insertBefore(createdScript, firstScript);
        window.WalkMe('user', user.id);
        window.WalkMe('identify', user.id, {
          email: user.email,
          name: user.firstName + ' ' + user.lastName,
          district: user.schoolGroup_name,
          districtId: user.schoolGroupId,
          school: user.currentSchool_name,
          schoolId: user.currentSchoolId,
          type: user.type
        });
        window.WalkMe('tag', {
          district: user.schoolGroup_name
        });
      }
    } else {
      logger.warn('Could not find logged in user for walk me');
    }
  }

  /**
   * Initialize Zendesk on our window object
   */
  public async initZendesk(isLoggedIn: boolean): Promise<void> {
    const document = this.document;
    const window = this.window.nativeWindow;
    const user = this.StorageManager.getLoggedInUser();
    // let highestRoleCategory = '';

    // if (isLoggedIn && user) {
    //   highestRoleCategory =
    //     await this.usersService.getOurHighestRoleCategoryInDistrict();
    // }

    // A helper function for us to make sure the widget has finished initializing
    const waitForWidgetInit = setInterval(function () {
      if (window.zE === undefined) {
        return;
      } else {
        setTimeout(() => {
          if (isLoggedIn) {
            if (user) {
              //give all identifying info to zendesk
              window.zE('messenger:set', 'conversationFields', [
                {
                  id: '24902316627995',
                  value: user.firstName + ' ' + user.lastName
                }, //name
                { id: '360041956252', value: '' + user.schoolGroup_name }, //district
                { id: '24902332209307', value: '' + user.currentSchool_name }, //school
                { id: '360041956272', value: '' + user.email } //email
              ]);
            }
          } else {
            window.zE('messenger', 'logoutUser');
          }
        }, 0);
        clearInterval(waitForWidgetInit);
      }
    }, 100);

    // If the widget is already loaded, don't load it again.
    if (window.zE === undefined) {
      const zendeskScript = document.createElement('script');
      zendeskScript.id = 'ze-snippet';
      zendeskScript.type = 'text/javascript';
      zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_APP_KEY}`;
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(zendeskScript, firstScript);
    }
  }

  public hideZendesk(): void {
    const window = this.window.nativeWindow;
    if (window.zE == undefined) {
      return;
    } else {
      window.zE('messenger', 'hide');
    }
  }

  public showZendesk(): void {
    const window = this.window.nativeWindow;
    if (window.zE == undefined) {
      return;
    } else {
      window.zE('messenger', 'show');
    }
  }

  //   /**
  //    * Open the Zendesk menu
  //    */
  //   public openZendesk(): void {
  //     const window = this.window.nativeWindow;
  //     if (window.zE) {
  //       window.zE('webWidget', 'show');
  //       window.zE('webWidget', 'toggle');
  //       window.zE('webWidget:on', 'close', () => {
  //         window.zE('webWidget', 'hide');
  //       });
  //     }
  //   }
}
