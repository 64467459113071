<div class="behavior-codes-list-content">
  <div class="no-school-or-school-group" *ngIf="!hasSelectedSchool">
    The Behavior Codes configuration page can only be accessed for an individual
    school. Please switch to one of those and try again!
  </div>
  <div class="content" *ngIf="hasSelectedSchool">
    <app-behavior-codes-list-filters
      *ngIf="!reorderMode"
      [filters]="filters"
      (filtersUpdated)="updateFilters($event)"></app-behavior-codes-list-filters>
    <app-behavior-codes-table
      [dataArr]="behaviorCodes"
      [hasSelectedSchool]="hasSelectedSchool"
      [isLoading]="isLoading"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"
      (reorderModeUpdated)="reorderBehaviorCodes($event)"></app-behavior-codes-table>
  </div>
</div>
