import { Injectable } from '@angular/core';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { BaseService } from '../../shared/base-classes/base.service';
import {
  IncidentOptionsResponseDto,
  OptionsCreateDto,
  OptionsUpdateDto
} from '@whetstoneeducation/hero-common';

@Injectable({
  providedIn: 'root'
})
export class AppIncidentService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async getIncidentConfig() {
    return this.appClientDataService.execute<IncidentOptionsResponseDto>(
      this.GET_ROUTES.INCIDENT_OPTIONS
    );
  }

  public createOption(option: OptionsCreateDto) {
    return this.appClientDataService.execute(this.POST_ROUTES.CREATE_OPTION, {
      method: this.METHOD.POST,
      body: option
    });
  }

  public updateOption(id: number, option: OptionsUpdateDto) {
    return this.appClientDataService.execute(this.PATCH_ROUTES.UPDATE_OPTION, {
      method: this.METHOD.PATCH,
      body: option,
      pathParams: { id }
    });
  }
}
