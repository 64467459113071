import { AuthGuard } from '../../route-guards/auth.guard';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { ICustomRoute } from '../../app-routing.module';
import { AppNotificationTemplateListComponent } from './template-list/notification-template-list.component';
import { AppNotificationTemplateListResolver } from './template-list/notification-template-list.resolver';
import { AppNotificationTemplateComponent } from './templates/notification-template.component';
import { AppNotificationTemplateResolver } from './templates/notification-template.resolver';

export const AppNotificationRoutes: ICustomRoute[] = [
  {
    path: 'notification-templates',
    title: 'Notification Template',
    component: AppNotificationTemplateListComponent,
    resolve: { data: AppNotificationTemplateListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Notification Template',
      buttons: [
        {
          name: 'Create',
          action: HeaderButtonAction.CREATE,
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'notification-templates/:id',
    title: 'Notification Template',
    component: AppNotificationTemplateComponent,
    resolve: { data: AppNotificationTemplateResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Notification Template',
      buttons: [
        {
          name: 'Save',
          action: HeaderButtonAction.SAVE,
          style: 'secondary-button'
        }
      ]
    }
  }
];
