import { AfterViewInit, Component } from '@angular/core';
import { AppCalendarService } from '../calendar.service';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { dtoToFormGroup } from '../../../shared/validation/validation.util';
import { DateUtil, SetUpYearDto } from '@whetstoneeducation/hero-common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';

@Component({
  selector: 'app-set-up-year',
  templateUrl: './set-up-year.template.html',
  styleUrls: ['./set-up-year.scss']
})
export class AppSetUpYear extends BaseComponent implements AfterViewInit {
  public setUpYearDto: SetUpYearDto;
  public setUpYearForm: FormGroup;
  public pageHeaderSubscription: Subscription;
  public valueChangesSubscription: Subscription;

  constructor(
    public calendarService: AppCalendarService,
    public formBuilder: FormBuilder,
    public toastService: AppToastManagerService,
    public router: Router,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.createForm();
  }

  public ngAfterViewInit(): void {
    this.valueChangesSubscription = this.setUpYearForm.valueChanges.subscribe(
      async () => {
        const { startDate, endDate } = this.setUpYearForm.value;
        if (startDate) this.setUpYearDto.startDate = startDate.getTime();
        if (endDate) this.setUpYearDto.endDate = endDate.getTime();
      }
    );
    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public createForm(): void {
    const { currentSchoolId } = this.StorageManager.getLoggedInUser();
    if (!currentSchoolId) {
      this.toastService.error('Please select a school to view');
      this.router.navigate(['/my-settings']);
    }
    const calendarDayMassCreateDto = new SetUpYearDto();
    this.setUpYearDto = calendarDayMassCreateDto;
    this.setUpYearDto.schoolId = currentSchoolId;
    calendarDayMassCreateDto.schoolId = currentSchoolId;
    this.setUpYearForm = dtoToFormGroup(
      calendarDayMassCreateDto,
      this.formBuilder
    );
  }

  private validateDates(): boolean {
    const { startDate, endDate } = this.setUpYearForm.value;
    if (!startDate) {
      this.setUpYearForm.controls['startDate'].setErrors({
        required: true,
        requiredMessage: 'Start date is required'
      });
      return false;
    }
    if (!endDate) {
      this.setUpYearForm.controls['endDate'].setErrors({
        required: true,
        requiredMessage: 'End date is required'
      });
      return false;
    }
    if (startDate && endDate && startDate > endDate) {
      this.setUpYearForm.controls['endDate'].setErrors({
        invalidDate: true,
        invalidDateMessage: 'End date must be after start date'
      });
      return false;
    }

    const startDateTimestamp = new Date(startDate).getTime();
    const endDateTimestamp = new Date(endDate).getTime();

    if (
      startDateTimestamp < DateUtil.getEarliestPossibleDate() ||
      startDateTimestamp > DateUtil.getLatestPossibleDate()
    ) {
      this.setUpYearForm.controls['startDate'].setErrors({
        invalidDate: true,
        invalidDateMessage: `Start date must be between ${DateUtil.convertTimestampToDisplayDate(
          DateUtil.getEarliestPossibleDate()
        )} and ${DateUtil.convertTimestampToDisplayDate(
          DateUtil.getLatestPossibleDate()
        )}`
      });
      return false;
    }

    if (
      endDateTimestamp < DateUtil.getEarliestPossibleDate() ||
      endDateTimestamp > DateUtil.getLatestPossibleDate()
    ) {
      this.setUpYearForm.controls['endDate'].setErrors({
        invalidDate: true,
        invalidDateMessage: `End date must be between ${DateUtil.convertTimestampToDisplayDate(
          DateUtil.getEarliestPossibleDate()
        )} and ${DateUtil.convertTimestampToDisplayDate(
          DateUtil.getLatestPossibleDate()
        )}`
      });
      return false;
    }
    return true;
  }

  private async saveChanges(): Promise<void> {
    if (this.validateDates()) {
      const response = await this.calendarService.setUpYear(this.setUpYearDto);
      this.toastService.success(response.message || 'Successfully saved');
      this.router.navigate(['/calendar']);
    } else {
      this.toastService.error('Please fix the errors on the page');
    }
  }
}
