import { Injectable } from '@angular/core';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppComplianceService } from '../compliance.service';
import { IComplianceListResolverData } from './compliance-list-models/compliance-list-resolver-data.interface';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';

@Injectable({ providedIn: 'root' })
export class AppComplianceListResolver extends BaseResolver<IComplianceListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public complianceService: AppComplianceService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve() {
    return this.complianceService.getComplianceListResolverData();
  }
}
