<form
  class="behavior-code-intervention"
  [formGroup]="behaviorCodeInterventionForm"
  novalidate>
  <mat-card
    appearance="outlined"
    class="behavior-code-intervention-form mat-elevation-z3">
    <mat-card-header>
      <mat-card-title>
        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
        Add Intervention to Behavior Code
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="behavior-code-intervention-form-content">
      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Intervention</mat-label>
          <mat-select formControlName="interventionId">
            <mat-option
              *ngFor="let type of interventionOptions"
              [value]="type.value">
              {{type.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Should happen after X entries</mat-label>
          <input matInput formControlName="triggerCount" type="number" />
          <app-error-display
            [form]="behaviorCodeInterventionForm"
            controlName="triggerCount"></app-error-display>
        </mat-form-field>
        <small class="help-text">
          This intervention will occur after this amount of tracks. This can
          happen in parallel with other actions.
        </small>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <app-error-display
            [form]="behaviorCodeInterventionForm"
            controlName="description"></app-error-display>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</form>
