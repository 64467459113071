import { Injectable } from '@angular/core';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppSftpService } from '../sftp.service';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ISftpSettingsListResolverData } from './sftp-settings-list-models/sftp-settings-list-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppSftpSettingsListResolver extends BaseResolver<ISftpSettingsListResolverData> {
  constructor(
    resolverErrorHandler: AppResolverErrorHandlerService,
    public sftpService: AppSftpService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ISftpSettingsListResolverData> {
    return this.sftpService.getSftpSettingsListPageResolverData();
  }
}
