import { ICustomRoute } from '../../app-routing.module';
import { AppStudentGroupCreateEditComponent } from './student-groups-create-edit/student-groups-create-edit.component';
import { AppStudentGroupsCreateEditResolver } from './student-groups-create-edit/student-groups-create-edit.resolver';
import { AuthGuard } from '../../route-guards/auth.guard';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { AppStudentGroupsListComponent } from './student-groups-list/student-groups-list.component';
import { AppStudentGroupsListResolver } from './student-groups-list/student-groups-list.resolver';

export const AppStudentGroupsRoutes: ICustomRoute[] = [
  {
    path: 'student-groups/:id',
    title: 'Student Group',
    component: AppStudentGroupCreateEditComponent,
    resolve: { data: AppStudentGroupsCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Student Group',
      overwriteTitle: false,
      buttons: [
        {
          name: 'SAVE',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  },
  {
    path: 'student-groups',
    title: 'Student Groups',
    component: AppStudentGroupsListComponent,
    resolve: { data: AppStudentGroupsListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Student Groups',
      buttons: [
        {
          name: 'ADD Student GROUP',
          style: 'secondary-button',
          normalizedRoute: 'student-groups/new',
          icon: 'plus-circle'
        }
      ]
    }
  }
];
