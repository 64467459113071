<mat-card appearance="outlined" *ngIf="!isLoading">
  <mat-card-title>{{behaviorCodeEntry.student.name}} </mat-card-title>
  <mat-card-content>
    <mat-label>{{behaviorCodeEntry.behaviorCodeName}}</mat-label>
    <ng-container
      *ngFor="let reactionEntry of behaviorCodeEntry.reactionEntries">
      <div>
        <div>Reaction: {{reactionEntry.reaction.name}}</div>

        <div *ngIf="reactionEntry.scheduledReaction">
          <div>Reaction Date: {{reactionEntry.scheduledReaction.name}}</div>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
