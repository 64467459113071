import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import {
  PaginationResultsDto,
  RoleResponseDto
} from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppRolesServices } from '../roles.service';
import { IRolesListFilters } from './roles-list-models/roles-list-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';

@Injectable({ providedIn: 'root' })
export class AppRolesListResolver extends BaseResolver<
  PaginationResultsDto<RoleResponseDto>
> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public rolesService: AppRolesServices
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<PaginationResultsDto<RoleResponseDto>> {
    const user = this.StorageManager.getLoggedInUser();
    const schoolGroupId = user.schoolGroupId;
    const schoolId = user.currentSchoolId;
    const filters: IRolesListFilters = {
      schoolId,
      schoolGroupId,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.ROLES_LIST
      )
    };

    return this.rolesService.getRoles(filters);
  }
}
