import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  BehaviorCodeInterventionCreateDto,
  BehaviorCodeInterventionUpdateDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBehaviorCodeInterventionsService } from '../behavior-code-interventions.service';
import { plainToInstance } from 'class-transformer';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { IBehaviorCodeInterventionsCreateEditResolverData } from '../behavior-code-interventions-models/behavior-code-interventions-create-edit-resolver-data.interface';
import { logger } from '../../../shared/logger';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';

@Component({
  selector: 'app-behavior-code-intervention-create-edit',
  templateUrl: './behavior-code-interventions-create-edit.template.html',
  styleUrls: ['./behavior-code-interventions-create-edit.scss']
})
export class AppBehaviorCodeInterventionsCreateEditComponent
  extends BaseComponent
  implements AfterViewInit
{
  public interventionOptions: IDisplayData[];
  public behaviorCodeId: number;
  public behaviorCodeIntervention:
    | BehaviorCodeInterventionCreateDto
    | BehaviorCodeInterventionUpdateDto;
  public isCreating: boolean;
  public behaviorCodeInterventionForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public behaviorCodeInterventionId: number;
  public pageHeaderSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public behaviorCodeInterventionsService: AppBehaviorCodeInterventionsService,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data: IBehaviorCodeInterventionsCreateEditResolverData =
      this.route.snapshot.data.data;
    this.behaviorCodeInterventionId =
      +this.route.snapshot.params.behaviorCodeInterventionId;
    this.behaviorCodeId = +this.route.snapshot.params.id;
    this.interventionOptions = data.interventionOptions;
    const behaviorCodeIntervention = data.behaviorCodeIntervention;
    this.isCreating = !behaviorCodeIntervention.id;
    if (this.isCreating) {
      this.behaviorCodeIntervention = plainToInstance(
        BehaviorCodeInterventionCreateDto,
        behaviorCodeIntervention,
        {
          exposeUnsetFields: true
        }
      );
    } else {
      this.behaviorCodeIntervention = plainToInstance(
        BehaviorCodeInterventionUpdateDto,
        behaviorCodeIntervention
      );
    }
    this.behaviorCodeInterventionForm = dtoToFormGroup(
      this.behaviorCodeIntervention,
      this.formBuilder,
      {
        mapId: !this.isCreating
      }
    );
    this.isLoading = false;
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.behaviorCodeInterventionForm.valueChanges.subscribe(
        async (value) => {
          if (this.isCreating) {
            this.behaviorCodeIntervention =
              await validateAndGetValue<BehaviorCodeInterventionCreateDto>(
                this.behaviorCodeInterventionForm,
                BehaviorCodeInterventionCreateDto
              );
          } else {
            this.behaviorCodeIntervention =
              await validateAndGetValue<BehaviorCodeInterventionUpdateDto>(
                this.behaviorCodeInterventionForm,
                BehaviorCodeInterventionUpdateDto
              );
          }
        }
      )
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public async saveChanges() {
    if (formCanSave(this.behaviorCodeInterventionForm, this.toastManager)) {
      try {
        if (
          this.behaviorCodeIntervention instanceof
          BehaviorCodeInterventionCreateDto
        ) {
          const behaviorCodeIntervention =
            await this.behaviorCodeInterventionsService.createBehaviorCodeIntervention(
              this.behaviorCodeIntervention
            );
          this.behaviorCodeIntervention = plainToInstance(
            BehaviorCodeInterventionUpdateDto,
            behaviorCodeIntervention
          );
          await this.router.navigate(['/behavior-codes', this.behaviorCodeId]);
        } else {
          this.behaviorCodeIntervention =
            await this.behaviorCodeInterventionsService.updateBehaviorCodeIntervention(
              this.behaviorCodeIntervention,
              this.behaviorCodeInterventionId
            );
        }
        this.toastManager.success(
          'Behavior Code Intervention saved successfully!'
        );
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving Behavior Code Intervention, please try again!'
        );
      }
    }
  }
}
