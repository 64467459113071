"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationTemplateCreateDto = exports.NotificationType = exports.NotificationTemplateBodyType = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const boolean_transform_1 = require("../../constants/boolean.transform");
// there is some kind of weird bug that is causing the import to fail
// so declaring the following enums here is a workaround
// import { NotificationTemplateBodyType, NotificationType } from '../../enums';
var NotificationTemplateBodyType;
(function (NotificationTemplateBodyType) {
    NotificationTemplateBodyType["HTML"] = "html";
    NotificationTemplateBodyType["TEXT"] = "text";
})(NotificationTemplateBodyType || (exports.NotificationTemplateBodyType = NotificationTemplateBodyType = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["PASSWORD_RESET"] = "password-reset";
    NotificationType["USER_INVITE"] = "user-invite";
    NotificationType["GUARDIAN_INVITE"] = "guardian-invite";
    NotificationType["BEHAVIOR_TRACKING"] = "behavior-tracking";
    NotificationType["IMPORT_NOTIFICATION"] = "import-notification";
    NotificationType["STUDENT_BULK_PHOTO_UPLOAD"] = "student-bulk-photo-upload";
    NotificationType["SECURE_DOWNLOAD"] = "secure-download";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
class NotificationTemplateCreateDto extends mappable_base_1.MappableBase {
    name;
    description;
    subject;
    body;
    bodyType;
    notificationType;
    isSchoolGroupLevel;
    isGlobal;
    schoolGroupId;
    schoolId;
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'name',
            'description',
            'subject',
            'body',
            'bodyType',
            'notificationType',
            'isSchoolGroupLevel',
            'isGlobal',
            'schoolGroupId',
            'schoolId'
        ];
    }
    mapFields(template) {
        return super.mapFields(template);
    }
}
exports.NotificationTemplateCreateDto = NotificationTemplateCreateDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(128),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], NotificationTemplateCreateDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], NotificationTemplateCreateDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(1000),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], NotificationTemplateCreateDto.prototype, "subject", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(65535),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], NotificationTemplateCreateDto.prototype, "body", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(NotificationTemplateBodyType),
    __metadata("design:type", String)
], NotificationTemplateCreateDto.prototype, "bodyType", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(NotificationType),
    __metadata("design:type", String)
], NotificationTemplateCreateDto.prototype, "notificationType", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], NotificationTemplateCreateDto.prototype, "isSchoolGroupLevel", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], NotificationTemplateCreateDto.prototype, "isGlobal", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], NotificationTemplateCreateDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], NotificationTemplateCreateDto.prototype, "schoolId", void 0);
