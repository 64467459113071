import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../base-classes/base.component';
import { ValidationErrors } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

export interface IFormControlMapping {
  [formControlName: string]: string; // form control display name
}

@Component({
  standalone: true,
  selector: 'app-validation-dialog',
  templateUrl: './validation-dialog.template.html',
  imports: [NgForOf, NgIf, MatButtonModule],
  styleUrls: ['./validation-dialog.scss']
})
export class AppValidationDialogComponent extends BaseComponent {
  public errors: ValidationErrors[];
  public formControlMapping: IFormControlMapping;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AppValidationDialogComponent>
  ) {
    super();
    this.errors = data.errors;
    this.formControlMapping = data.formControlMapping;
  }

  public getControlNames(): string[] {
    return Object.keys(this.formControlMapping);
  }

  public hasError(property: string): boolean {
    return this.errors.some((e) => e.property === property);
  }

  public getDisplayError(property: string): [string, string] {
    const error = this.errors.find((e) => e.property === property);
    if (!error) {
      return ['', ''];
    }
    if (error.constraints) {
      const constraints = Object.keys(error.constraints);
      const messages = constraints.map((key) => error.constraints[key]);
      const message = messages.join(' ');
      return [this.formControlMapping[property], message];
    } else if (error.children && error.children.length) {
      const messages = error.children.map((child) => {
        const constraints = Object.keys(child.constraints);
        return constraints.map((key) => child.constraints[key]);
      });
      const message = messages.join(' ');
      return [this.formControlMapping[property], message];
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
