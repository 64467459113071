import { BaseComponent } from '../../shared/base-classes/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-back-button-modal',
  templateUrl: './back-button-modal.template.html',
  styleUrls: ['./back-button-modal.scss']
})
export class AppBackButtonModalComponent
  extends BaseComponent
{
  public onClose: () => void;

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<AppBackButtonModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      navigateTo: string;
      onClose?: () => void;
    }
  ) {
    super();
    this.onClose = data.onClose;
  }

  public goBack(): void {
    this.router.navigate([this.data.navigateTo]);
    this.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
