<div class="mat-dialog-header">
  <div *ngIf="!isLoading" class="mat-dialog-title">
    Behavior Track: <span>{{ behaviorEntry.behaviorCodeName }}</span>
  </div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <ng-container
    *ngIf="
        !isLoading &&
        hasPrivilege(PrivilegeEnum.EDIT_BEHAVIOR_NOTES)
      ">
    <mat-form-field class="textarea-input">
      <mat-label>Notes</mat-label>
      <textarea
        class="textarea-input"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        [formControl]="notesControl"
        matInput
        placeholder="Notes"></textarea>
    </mat-form-field>
  </ng-container>
  <ng-container
    *ngIf="
        !isLoading &&
        !hasPrivilege(PrivilegeEnum.EDIT_BEHAVIOR_NOTES) &&
        hasPrivilege(PrivilegeEnum.VIEW_BEHAVIOR_NOTES)
      ">
    <!-- Note field -->
    {{ behaviorEntry.notes }}
  </ng-container>

  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      type="submit"
      mat-button
      class="modal-submit-button"
      (click)="submitBehaviorEntry()">
      Update
    </button>
  </div>
</div>
