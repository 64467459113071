import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../../app-material.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { AppSaveBarComponent } from './save-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    AppPipesModule,
    MatIconModule,
    MatButtonModule,
  ],
  declarations: [AppSaveBarComponent],
  exports: [AppSaveBarComponent],
})
export class AppSaveBarModule {}
