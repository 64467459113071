import { AppAuthComponent } from './auth.component';
import { ICustomRoute } from 'src/app/app-routing.module';
import { AppVerifiedLinkComponent } from './verified-link.component';

export const AppAuthRoutes: ICustomRoute[] = [
  {
    path: 'login',
    title: 'Login',
    component: AppAuthComponent
  },
  {
    path: 'verify-link',
    title: 'Verify Link',
    component: AppVerifiedLinkComponent
  }
];
