import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppExtendedDatePipe } from './date.pipe';
import { AppIfNullValuePipe } from './null-value.pipe';
import { AppNumberPipe } from './number.pipe';
import { AppEnumStringPipe } from './enum-string.pipe';
import { AppUpperUnderscorePipe } from './upper-underscore.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AppIfNullValuePipe,
    AppExtendedDatePipe,
    AppNumberPipe,
    AppEnumStringPipe,
    AppUpperUnderscorePipe
  ],
  providers: [
    AppIfNullValuePipe,
    AppExtendedDatePipe,
    AppNumberPipe,
    AppEnumStringPipe,
    AppUpperUnderscorePipe
  ],
  exports: [
    AppIfNullValuePipe,
    AppExtendedDatePipe,
    AppNumberPipe,
    AppEnumStringPipe,
    AppUpperUnderscorePipe
  ]
})
export class AppPipesModule {}
