<div class="mat-dialog-container">
  <div class="mat-dialog-header default-dialog-header">
    <div class="mat-dialog-title">
      {{ this.isCreating ? 'Create' : 'Edit' }} Bell Schedule Period
    </div>
  </div>
  <mat-dialog-content class="period-form-content">
    <form [formGroup]="periodForm">
      <!-- hidden input for schoolId -->
      <input type="hidden" formControlName="schoolId" />
      <div class="period-content">
        <div class="form-row">
          <mat-form-field class="full-width-input">
            <input matInput formControlName="name" placeholder="Name" />
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="full-width-input">
            <input
              matInput
              formControlName="referenceCode"
              placeholder="Ref. Code" />
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="full-width-input">
            <input
              matInput
              formControlName="extPeriodCode"
              placeholder="Ext. Code" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button color="primary" (click)="onSave()">
      <i class="fa fa-save"></i>
      Save
    </button>
    <button mat-flat-button color="primary" (click)="onClose()">
      <i class="fa fa-times"></i>
      Close
    </button>
  </mat-dialog-actions>
</div>
