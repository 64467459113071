"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsGroupLevelConstraint = void 0;
const class_validator_1 = require("class-validator");
let IsGroupLevelConstraint = class IsGroupLevelConstraint {
    validate(schoolId, args) {
        const relatedObject = args.object;
        // If it's a group level, schoolId should be null
        if (relatedObject.isSchoolGroupLevel) {
            return schoolId === null;
        }
        // If it's not a group level, schoolId should be positive
        return schoolId ? schoolId > 0 : false;
    }
    defaultMessage(args) {
        const relatedObject = args.object;
        if (relatedObject.isSchoolGroupLevel) {
            return 'schoolId must be null when Group Level sharing is enabled';
        }
        return 'You must select a School unless Group Level sharing is enabled';
    }
};
exports.IsGroupLevelConstraint = IsGroupLevelConstraint;
exports.IsGroupLevelConstraint = IsGroupLevelConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ async: false })
], IsGroupLevelConstraint);
