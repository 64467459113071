<div class="page-content">
  <form [formGroup]="interventionForm" novalidate>
    <div class="intervention-content">
      <mat-card
        appearance="outlined"
        class="intervention-form mat-elevation-z3">
        <mat-card-header>
          <mat-card-title>
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
            {{ isCreating ? "Create a New Intervention" : "Edit " +
            intervention.name }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="intervention-form-content">
          <div class="form-row">
            <div class="form-item name-input">
              <mat-form-field class="full-width-input">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" />
                <app-error-display
                  [form]="interventionForm"
                  controlName="name"></app-error-display>
              </mat-form-field>
            </div>
          </div>

          <div class="form-row">
            <div class="form-item description-input">
              <mat-form-field class="full-width-input">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description"> </textarea>
                <app-error-display
                  [form]="interventionForm"
                  controlName="description"></app-error-display>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>
