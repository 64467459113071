import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../../app-material.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppStudentCardComponent } from './student-card.component';
import { AppErrorDisplayComponent } from '../validation/error-display.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '../directives/directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    AppPipesModule,
    MatIconModule,
    MatButtonModule,
    AppErrorDisplayComponent,
    FontAwesomeModule,
    DirectiveModule,
    NgOptimizedImage
  ],
  declarations: [AppStudentCardComponent],
  exports: [AppStudentCardComponent],
})
export class AppStudentCardModule {}
