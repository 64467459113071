import { DOCUMENT } from '@angular/common';
import { logger } from 'src/app/shared/logger';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationEnd,
  QueryParamsHandling,
  Router
} from '@angular/router';
import { BaseComponent } from '../base-classes/base.component';
import { IRouteData } from '../route-data.interface';
import { AppSettingsService } from '../services/settings.service';
import { AppUrlParamsService } from '../services/url-params.service';
import { IBreadcrumb } from './breadcrumb.interface';
import { HeaderButtonAction, IHeaderButton } from './header-button';
import { AppPageHeaderService } from './page-header.service';
import { AppPrivilegesService } from 'src/app/pages/auth/privileges.service';
import { MatDialog } from '@angular/material/dialog';
import { IconName } from '@awesome.me/kit-e17f36da89/icons/modules';
import { PrinterService } from '../printer/printer.service';

/**
 * The Page Header Component
 */
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.template.html',
  styleUrls: ['./page-header.scss', './custom-page-header-import.scss']
})
export class AppPageHeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  /**
   * The page title
   */
  public title: string;

  /**
   * The page subtitle
   */
  public subtitle: string;

  public pageHeaderClass: string;

  /**
   * The page description
   */
  public description: string;

  /**
   * Icon to display before page title
   */
  public icon: IconName;

  /**
   * A reference to the subscription created by the router on init so we can destroy it later.
   */
  public subscriptionRef: any;

  /**
   * Any breadcrumbs data
   */
  public breadcrumbs: IBreadcrumb[];

  /**
   * The buttons that we should be displaying
   */
  public buttons: IHeaderButton[];

  /**
   * How do we want to handle our query params when navigating.
   */
  public queryParamsHandling: QueryParamsHandling;

  public useNonScrollablePageHeaderStyle: boolean;

  public showSavedReportsButton: boolean;

  /**
   * Default Constructor
   */
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public urlService: AppUrlParamsService,
    public dialog: MatDialog,
    private pageHeaderService: AppPageHeaderService,
    private privilegesService: AppPrivilegesService,
    public titleService: Title,
    public settingsService: AppSettingsService,
    public renderer: Renderer2,
    private printer: PrinterService,
    @Inject(DOCUMENT) public document
  ) {
    super({ urlService, privilegesService });
    this.title = null;
    this.subscriptionRef = null;
  }

  /**
   * Called initially when the component has loaded.
   */
  public async ngOnInit(): Promise<void> {
    // Subscribe to all router events
    this.subscriptionRef = this.router.events.subscribe((event: any) => {
      // Only respond to navigationEnd events
      if (event instanceof NavigationEnd) {
        // Check if we have data--
        let activeRoute: any = this.route;
        while (activeRoute.firstChild) {
          activeRoute = activeRoute.firstChild;
        }

        activeRoute.data.subscribe((data) => {
          this.loadRouteData(data);
        });
      }
    });
    // Subscribe to title change request from service
    this.pageHeaderService.titleChanged.subscribe(this.setTitle.bind(this));
    this.pageHeaderService.subtitleChanged.subscribe(
      this.setSubtitle.bind(this)
    );
    this.pageHeaderService.descriptionChanged.subscribe(
      (description: string) => {
        this.description = description;
      }
    );
    // Subscribe to the buttons change request from service
    this.pageHeaderService.buttonsChanged.subscribe(
      (buttons: IHeaderButton[]) => {
        this.buttons = this.getActivatedButtons(buttons);
      }
    );
    // Subscribe to breadcrumbs change request from service
    this.pageHeaderService.breadcrumbsChanged.subscribe(
      (breadcrumbs: IBreadcrumb[]) => {
        this.breadcrumbs = this.fixBreadcrumbs(breadcrumbs);
      }
    );
  }

  /**
   * If RouteData contains a title/description/breadcrumbs--set it here--otherwise, let's reset it
   */
  public loadRouteData(routeData: IRouteData) {
    try {
      if (routeData.overwriteTitle !== false) {
        this.setTitle(routeData?.title);
        this.setSubtitle(routeData?.subtitle);
      }
      this.pageHeaderClass = routeData.pageHeaderClass
        ? `page-header ${routeData.pageHeaderClass}`
        : 'page-header';
      this.description = routeData?.description;
      this.icon = routeData?.icon;
      this.queryParamsHandling = routeData?.queryParamsHandling || null;
      const showBreadcrumbs = routeData?.breadcrumbsUserType
        ? routeData?.breadcrumbsUserTypeCondition === 'not'
          ? !this.StorageManager.isUserType(
              routeData?.breadcrumbsUserType || []
            )
          : this.StorageManager.isUserType(routeData?.breadcrumbsUserType || [])
        : true;
      this.breadcrumbs = routeData?.breadcrumbs
        ? showBreadcrumbs && this.fixBreadcrumbs(routeData?.breadcrumbs)
        : null;
      if (routeData.overwriteButtons !== false) {
        this.buttons = routeData?.buttons
          ? this.getActivatedButtons(routeData?.buttons)
          : null;
        this.useNonScrollablePageHeaderStyle =
          routeData?.useNonScrollablePageHeaderStyle;
      }
    } catch (error) {
      logger.error('Error configuring page header', error);
    }
  }

  /**
   * Called when the component is being unloaded.
   */
  public ngOnDestroy(): void {
    this.subscriptionRef.unsubscribe();
  }

  /**
   * If the page has no title, then hide the page header component.
   */
  public showPageHeader(): boolean {
    return !this.title;
  }

  /**
   * Fix our buttons to have correct fields
   */
  public fixRoute(route: string) {
    // Find any /:param matches
    const regex = new RegExp('\\/:[(\\w|\\-)]+', 'g');
    const matches = regex.exec(route);

    // If we found matches, loop through them
    if (matches) {
      for (const match of matches) {
        // Cut of `/:` from our param
        const matchKey = match.slice(2);
        // Get our match's value from our url
        const matchValue = this.urlService.getPathParam(matchKey);
        // Replace `:param` in our button route, with the value from our url
        route = route.replace(match.slice(1), matchValue);
      }
    }

    return route;
  }

  /**
   * Fix our breadcrumbs to have correct routes
   */
  public fixBreadcrumbs(breadcrumbs: IBreadcrumb[]) {
    return breadcrumbs.map((breadcrumb) => {
      if (breadcrumb.path) {
        breadcrumb.normalizedPath = this.fixRoute(breadcrumb.path);
      }
      return breadcrumb;
    });
  }

  /**
   * Returns a list of header buttons that we have privileges for
   */
  public getActivatedButtons(buttons: IHeaderButton[]): IHeaderButton[] {
    return buttons
      .map((button) => {
        if (button.route) {
          button.normalizedRoute = this.fixRoute(button.route);
        }
        if (button.dropdownOptions) {
          button.dropdownOptions.forEach((dropdownOption) => {
            if (dropdownOption.route) {
              dropdownOption.normalizedRoute = this.fixRoute(
                dropdownOption.route
              );
            }
          });
        }
        return button;
      })
      .filter((button) => {
        let canAccess = true;
        if (button.requiresPrinter) {
          canAccess = canAccess && this.printer.usbAllowed;
        }
        if (button.userTypes) {
          canAccess = canAccess && this.isUserType(button.userTypes);
        } else if (button.userType) {
          canAccess = canAccess && this.isUserType([button.userType]);
        }
        if (button.privilege) {
          canAccess = canAccess && this.hasPrivilege(button.privilege);
        } else if (button.privileges) {
          canAccess =
            canAccess &&
            button.privileges.every((privilege) =>
              this.hasPrivilege(privilege)
            );
        }

        return canAccess;
      });
  }

  public emitHeaderEvent(action: HeaderButtonAction) {
    this.pageHeaderService.buttonAction(action);
  }

  private setTitle(title: string): void {
    if (title) {
      this.titleService.setTitle(`Hero | ${title}`);
      this.title = title;
    } else {
      this.title = '';
      this.titleService.setTitle('Hero');
    }
  }

  private setSubtitle(subtitle: string): void {
    if (subtitle) {
      this.subtitle = subtitle;
    } else {
      this.subtitle = '';
    }
  }
}
