import { logger } from 'src/app/shared/logger';
import { Injectable } from '@angular/core';
import Toastify from 'toastify-js';

/**
 * Constants for the types of severity we can use.
 */
enum ToastSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warn',
  ERROR = 'error',
}

/**
 * Additional options that allows you to overwrite the defaults if you want to.
 */
interface IToastOptions {
  destination?: string;
  duration?: number; // Overwrite the default delay
}

/**
 * Service used to deal with displaying, managing, clearing, and manipulating toast messages.
 */
@Injectable({
  providedIn: 'root',
})
export class AppToastManagerService {
  /**
   * Generates a new INFO toast message type.
   * @param message The message we want to display in the toast popup.
   * @param options Optional additional options for overwriting the defaults.
   */
  public info(message: string, options?: IToastOptions): void {
    const toast = AppToastManagerService.getDefault(
      ToastSeverity.INFO,
      message,
      options
    );

    Toastify(toast).showToast();
  }

  /**
   * Generates a new SUCCESS toast message type.
   * @param message The message we want to display in the toast popup.
   * @param options Optional additional options for overwriting the defaults.
   */
  public success(message: string, options?: IToastOptions): void {
    const toast = AppToastManagerService.getDefault(
      ToastSeverity.SUCCESS,
      message,
      options
    );

    Toastify(toast).showToast();
  }

  /**
   * Generates a new WARNING toast message type.
   * @param message The message we want to display in the toast popup.
   * @param options Optional additional options for overwriting the defaults.
   */
  public warn(message: string, options?: IToastOptions): void {
    const toast = AppToastManagerService.getDefault(
      ToastSeverity.WARNING,
      message,
      options
    );
    Toastify(toast).showToast();
  }

  /**
   * Generates a new ERROR toast message type.
   * @param message The message we want to display in the toast popup.
   * @param options Optional additional options for overwriting the defaults.
   */
  public error(text: string, options?: IToastOptions): void {
    const toast = AppToastManagerService.getDefault(
      ToastSeverity.ERROR,
      text,
      options
    );

    Toastify(toast).showToast();
  }

  /**
   * Given a severity type, will return the default associated toast title message.
   * @param severity The severity type for what this toast message is.
   */
  private static getDefault(
    severity: ToastSeverity,
    text,
    options: IToastOptions
  ): Toastify.Options {
    const defaultToast: Toastify.Options = {
      gravity: 'bottom',
      position: 'center',
      close: true,
      text,
    };
    if (severity === ToastSeverity.SUCCESS) {
      return {
        duration: 15000,
        ...defaultToast,
        ...options,
      };
    } else if (severity === ToastSeverity.INFO) {
      return {
        text: 'INFO',
        duration: 15000,
        ...defaultToast,
        ...options,
      };
    } else if (severity === ToastSeverity.WARNING) {
      return {
        text: 'WARNING',
        duration: 15000,
        ...defaultToast,
        ...options,
      };
    } else if (severity === ToastSeverity.ERROR) {
      return {
        text: 'ERROR',
        duration: 30000,
        ...defaultToast,
        ...options,
      };
    } else {
      return {
        text: 'SUCCESS',
        duration: 5000,
        ...defaultToast,
        ...options,
      };
    }
  }
}
