import { EventEmitter, Injectable } from '@angular/core';
import { ISetting } from '@whetstoneeducation/hero-common';
import { BaseService } from 'src/app/shared/base-classes/base.service';
import { logger } from 'src/app/shared/logger';
import { AppAuthService } from '../../pages/auth/auth.service';
import { AppErrorThrottlerService } from './error-throttler.service';
import { LocalStorageKeys } from '../storage/local-storage-keys.enum';
import { StorageManager } from '../storage/storage.manager';
import { AppClientDataService } from './client-data-service/app-client-data.service';
import { AppToastManagerService } from './toast-manager.service';

/**
 * Used as a standard way to make all HTTP calls for the application.
 * From here we can make sure we are passing the same default headers, etc.
 */
@Injectable({
  providedIn: 'root'
})
export class AppSettingsService extends BaseService {
  /**
   * A copy of our system settings stored cached in this root service
   */
  public cachedSystemSettings: ISetting;

  /**
   * Default Constructor
   */
  constructor(
    private appClientDataService: AppClientDataService,
    private toastService: AppToastManagerService,
    public authService: AppAuthService,
    public errorThrottler: AppErrorThrottlerService
  ) {
    super({ authService });
  }

  /**
   * Return the current settings for the active district.
   */
  public getSettings(): ISetting {
    if (this.cachedSystemSettings) {
      return this.cachedSystemSettings;
    } else {
      this.errorThrottler.addErrorMessage(
        'FATAL! System settings were fetched but have not been loaded yet! This should never happen!'
      );
      return null;
    }
  }

  /**
   * Return a fresh copy of the current settings for the active district.
   */
  public async getFreshSettings(): Promise<ISetting> {
    try {
      const districtId: number = this.StorageManager.getActiveSchoolGroupId();

      // Only continue if a district is set
      if (districtId) {
        return this.appClientDataService.execute(
          this.GET_ROUTES.SETTINGS_BY_SCHOOL_GROUP,
          {
            pathParams: {
              id: districtId
            }
          }
        );
      } else {
        this.toastService.warn('No district found! Are you logged in?');
      }
    } catch (err) {
      logger.error(err);
      return null;
    }
  }
}
