import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppSftpAccountListComponent } from './sftp-account-list/sftp-account-list.component';
import { AppSftpAccountListFilters } from './sftp-account-list/sftp-account-list-filters/sftp-account-list-filters.component';
import { AppSftpAccountListTableComponent } from './sftp-account-list/sftp-account-list-table/sftp-account-list-table.component';
import { AppSftpImportListComponent } from './sftp-import-list/sftp-import-list.component';
import { AppSftpImportListFilters } from './sftp-import-list/sftp-import-list-filters/sftp-import-list-filters.component';
import { AppSftpSettingsCreateEdit } from './sftp-settings/sftp-settings-create-edit.component';
import { AppSftpSummaryComponent } from './sftp-summary/sftp-summary.component';
import { CommonModule, DatePipe } from '@angular/common';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppSftpSettingsListTableComponent } from './sftp-settings-list/sftp-settings-list-table/sftp-settings-list-table.component';
import { AppSftpSettingsListFilters } from './sftp-settings-list/sftp-settings-list-filters/sftp-settings-list-filters.component';
import { AppSftpSettingsListComponent } from './sftp-settings-list/sftp-settings-list.component';
import { AppSftpImportListTableComponent } from './sftp-import-list/sftp-import-list-table/sftp-import-list-table.component';
import { AppSftpAccountCreateComponent } from './sftp-account-create/sftp-account-create.component';
import { AppSftpAccountViewComponent } from './sftp-account-view/sftp-account-view.component';
import { AppFileUploadModule } from '../../shared/file-upload/file-upload.module';
import { AppPipesModule } from '../../shared/pipes/pipes.module';
import { AppMappingTableComponent } from './mapping-configuration/mapping-table.component';
import { AppLastImportsComponent } from './last-imports/last-imports.component';
import { AppTablesModule } from '../../shared/tables/tables.module';
import { AppImportViewDialogComponent } from './import-view/import-view-dialog.component';
import { AppInputsModule } from '../../shared/inputs/inputs.module';

@NgModule({
  declarations: [
    AppSftpAccountCreateComponent,
    AppSftpAccountViewComponent,
    AppSftpAccountListComponent,
    AppSftpAccountListFilters,
    AppSftpAccountListTableComponent,
    AppSftpImportListComponent,
    AppSftpImportListFilters,
    AppSftpImportListTableComponent,
    AppSftpSettingsCreateEdit,
    AppSftpSettingsListComponent,
    AppSftpSettingsListFilters,
    AppSftpSettingsListTableComponent,
    AppSftpSummaryComponent,
    AppMappingTableComponent,
    AppLastImportsComponent,
    AppImportViewDialogComponent
  ],
  exports: [
    AppSftpAccountListComponent,
    AppSftpImportListComponent,
    AppSftpSettingsCreateEdit,
    AppSftpSettingsListComponent,
    AppSftpSummaryComponent,
    AppLastImportsComponent
  ],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppPaginationModule,
    AppFileUploadModule,
    AppPipesModule,
    CommonModule,
    DirectiveModule,
    FontAwesomeModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    RouterModule,
    AppTablesModule,
    AppInputsModule
  ],
  providers: [DatePipe]
})
export class AppImportModule {}
