import { Component } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { SchoolGroupResponseDto } from '@whetstoneeducation/hero-common';
import { SchoolGroupsListColumns } from '../school-groups-list-models/school-groups-list-columns.enum';
import { AppPrivilegesService } from '../../../auth/privileges.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppSchoolGroupsService } from '../../school-groups.service';

@Component({
  selector: 'app-school-groups-table',
  templateUrl: './school-groups-list-table.template.html',
  styleUrls: ['./school-groups-list-table.scss'],
})
export class AppSchoolGroupsListTableComponent extends BaseTableComponent<SchoolGroupResponseDto> {
  public get displayedColumns(): string[] {
    return Object.values(SchoolGroupsListColumns) as string[];
  }

  public columnValues = {

    [SchoolGroupsListColumns.NAME]: ["name"],

    [SchoolGroupsListColumns.DESCRIPTION]: ["description"],

  };



  public columns = SchoolGroupsListColumns;



  constructor(
    public privilegesService: AppPrivilegesService,
    public toastManager: AppToastManagerService,
    public schoolGroupsService: AppSchoolGroupsService
  ) {
    super({ privilegesService });

  }


}
