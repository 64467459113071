<div class="mat-dialog-header">
  <div class="mat-dialog-title">
    {{ isCreating ? 'Create Custom Roster' : 'Rename' }}
  </div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <mat-form-field class="full-width-input">
    <input matInput placeholder="Roster Name" [(ngModel)]="name" />
  </mat-form-field>
  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      type="submit"
      mat-button
      class="modal-submit-button"
      [disabled]="name === ''"
      (click)="handleSubmit()">
      {{ isCreating ? 'Create' : 'Rename' }}
    </button>
  </div>
</div>
