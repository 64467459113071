<div class="top-imports-exports-container">
  <div class="top-imports-exports-container-section">
    <div>
      <h2 class="section-title">Imports</h2>
      <button
        class="add-button"
        type="button"
        mat-flat-button
        (click)="clickAddNewImport()"
        >
        <div class="add-button-content">
          <fa-icon [icon]="'plus'" size="2x"></fa-icon>
          <span>IMPORT</span>
        </div>
      </button>
    </div>
    <app-last-imports />
  </div>
  <div class="top-imports-exports-container-section">
    <div>
      <h2 class="section-title">Exports</h2>
      <button
        class="add-button"
        type="button"
        mat-flat-button
        (click)="clickAddNewExport()"
        >
        <div class="add-button-content">
          <fa-icon [icon]="'plus'" size="2x"></fa-icon>
          <span>EXPORT</span>
        </div>
      </button>
    </div>
    <app-last-exports />
  </div>
</div>
