<div class="container">
  <div class="no-school" *ngIf="!schoolId">
    The Communications Report page can only be accessed for an individual school. Please select one and try again!
  </div>
  <app-communications-report-filters
    [loadingItems]="isLoading"
    [filters]="filters"
    (filtersUpdated)="onFiltersUpdated($event)"
    [behaviorCodes]="behaviorCodes"
    (applyFilters)="applyFilters()">
  </app-communications-report-filters>
  <app-base-common-table-portal [context]="this"></app-base-common-table-portal>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <span *ngIf="!(dataArr.length || isLoading)">
    There are no records to show
  </span>
</div>
<ng-template #actionsColumn let-row="row">
  <button
    mat-flat-button
    class="outline-button icon-button"
    (click)="viewEntry(row)">
    <fa-icon
      [icon]="['fas', 'eye']"
    ></fa-icon>
  </button>
</ng-template>
