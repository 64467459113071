"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationsReportResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class CommunicationsReportResponseDto extends mappable_base_1.MappableBase {
    /* Fields:
     *
     * Student Ext ID: string;
     * Student Name: string;
     * Behavior Code: string;
     * Status: string;
     * Infraction #;
     * Created Date: string;
     */
    studentExtId;
    studentName;
    behaviorCode;
    status;
    infractionNumber;
    createdDate;
    // other fields
    id;
    constructor(communicationsReportResponseDto) {
        super();
        if (communicationsReportResponseDto)
            Object.assign(this, communicationsReportResponseDto);
    }
    getFields() {
        return [
            'studentExtId',
            'studentName',
            'behaviorCode',
            'status',
            'infractionNumber',
            'createdDate',
            'totalCount',
            'id'
        ];
    }
}
exports.CommunicationsReportResponseDto = CommunicationsReportResponseDto;
