import { Component, Input, OnDestroy } from "@angular/core";
import { AppStudentsService } from "../../pages/students/students.service";
import { Observable, takeUntil, Subject } from "rxjs";
import { AppToastManagerService } from "../services/toast-manager.service";


@Component({
  selector: 'app-student-header-info',
  templateUrl: './student-header-info.template.html',
  styleUrls: ['./student-header-info.component.scss']
})
export class StudentHeaderInfoComponent {

  @Input()
  public firstName: string;

  @Input()
  public lastName: string;

  @Input()
  public studentExtId: string;

  @Input()
  public studentId: number;

  @Input()
  public grade: string;

  @Input()
  public studentGroupName?: string;

  @Input()
  selectedImage: string | ArrayBuffer | null;

  @Input()
  hideProfileImageSelector: boolean = false;

}
