import { Component, OnInit } from '@angular/core';
import { BaseTableComponent } from '../../../shared/tables/base-table/base-table';
import { BehaviorCodeNotificationsTableColumns } from '../behavior-code-notifications-models/behavior-code-notifications-table-columns.enum';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { BehaviorCodeNotificationUpdateDto } from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { DEFAULT_DIALOG_RESULT } from '../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { AppBehaviorCodeNotificationsService } from '../behavior-code-notifications.service';
@Component({
  selector: 'app-behavior-code-notifications-table',
  templateUrl: './behavior-code-notifications-table.template.html',
  styleUrls: ['./behavior-code-notifications-table.scss']
})
export class AppBehaviorCodeNotificationsTableComponent
  extends BaseTableComponent<any>
  implements OnInit
{
  public get displayedColumns() {
    return Object.values(BehaviorCodeNotificationsTableColumns);
  }

  public columnValues = {
    [BehaviorCodeNotificationsTableColumns.NAME]: ['notification', 'name'],
    [BehaviorCodeNotificationsTableColumns.DESCRIPTION]: ['description'],
    [BehaviorCodeNotificationsTableColumns.TRIGGER_AT]: ['triggerCount']
  };

  public showPagination: boolean;

  public columns = BehaviorCodeNotificationsTableColumns;
  constructor(
    public confirmationDialog: AppConfirmationDialogService,
    private toastManager: AppToastManagerService,
    public behaviorCodeNotificationService: AppBehaviorCodeNotificationsService
  ) {
    super();
  }

  public ngOnInit() {
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
  }

  public async deleteNotificationFromBehavior(
    row: BehaviorCodeNotificationUpdateDto
  ): Promise<void> {
    const notificationTemplateId = row.id;
    const ref = this.confirmationDialog.openDialog({
      title: `Remove Notification`,
      content: 'Are you sure you want to delete the notification?'
    });
    ref.afterClosed().subscribe(async (result: string) => {
      if (result === DEFAULT_DIALOG_RESULT.YES) {
        await this.behaviorCodeNotificationService.removeBehaviorNotification(
          notificationTemplateId
        );
        this.toastManager.success('Notification deleted successfully!');
        // remove notification from data array
        this.dataArr = this.dataArr.filter(
          (notification) => notification.id !== notificationTemplateId
        );
      }
    });
  }
}
