<div class="summary-container">
  <div>
    <div class="blue-card-container center-element column-direction">
      <span class="card-count">
        {{studentsCount}}
      </span>
      <span class="card-text">
       Total Students
      </span>
    </div>
  </div>
  <div>
    <div class="blue-card-container center-element column-direction">
      <span class="card-count">
        {{totalCount}}
      </span>
      <span class="card-text">
        Total Count
      </span>
    </div>
  </div>
</div>

