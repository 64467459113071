import { Injectable } from '@angular/core';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppSchoolsServices } from '../schools.service';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { ISchoolsListResolverData } from './schools-list-models/schools-list-resolver-data.interface';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppSchoolsListResolver extends BaseResolver<ISchoolsListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public schoolsService: AppSchoolsServices
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ISchoolsListResolverData> {
    return this.schoolsService.getSchoolsListPageResolverData();
  }
}
