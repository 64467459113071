import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  AccruedReportFiltersDto,
  AccruedReportRowResponseDto
} from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import {
  BaseCommonTableComponent,
  ITemplateContext
} from '../../../../shared/tables/common-table/base-common-table.component';
import { Subject, takeUntil } from 'rxjs';
import { HeaderButtonAction } from '../../../../shared/page-header/header-button';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';
import { ActivatedRoute } from '@angular/router';
import { AppReportsService } from '../../reports.service';
import { AppPageHeaderService } from '../../../../shared/page-header/page-header.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

export enum AccruedReportTableColumns {
  STUDENT_ID = 'Student ID',
  STUDENT_NAME = 'Student Name',
  GRADE = 'Grade Level',
  COUNT = 'Count',
  BEHAVIOR_CODE_DESCRIPTION = 'Behavior Code Description'
}

interface IActionsColumnContext {
  row: AccruedReportRowResponseDto;
}

@Component({
  selector: 'app-accrued-report-table',
  templateUrl: './accrued-report-table.template.html',
  styleUrls: ['./accrued-report-table.scss']
})
export class AppAccruedReportTable extends BaseCommonTableComponent<
  AccruedReportRowResponseDto,
  AccruedReportTableColumns
> {
  @ViewChild('actionsColumn')
  public actionsColumn: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  public columns;

  public filters: AccruedReportFiltersDto;
  public totalCount: number = 0;
  public studentsCount: number = 0;
  private destroy$ = new Subject<void>();

  public constructor(
    public route: ActivatedRoute,
    public reportService: AppReportsService,
    public pageHeaderService: AppPageHeaderService,
    public confirmationDialog: AppConfirmationDialogService,
    public toastService: AppToastManagerService
  ) {
    super();
    this.templates = {};
    this.columns = AccruedReportTableColumns;
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case AccruedReportTableColumns.STUDENT_ID:
        return row.studentId;
      case AccruedReportTableColumns.STUDENT_NAME:
        return row.studentName;
      case AccruedReportTableColumns.GRADE:
        return row.grade;
      case AccruedReportTableColumns.COUNT:
        return row.count;
      case AccruedReportTableColumns.BEHAVIOR_CODE_DESCRIPTION:
        return row.behaviorCodeDescription;
      default:
        return null;
    }
  }

  public ngOnInit() {
    this.loadData();

    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: string) => {
        if (action === HeaderButtonAction.EXPORT) {
          const ref = this.confirmationDialog.openDialog({
            title: `Accrued Records Report Export`,
            content:
              'Are you sure you want to export the accrued records report?'
          });
          ref.afterClosed().subscribe(async (result: string) => {
            if (result === DEFAULT_DIALOG_RESULT.YES) {
              await this.reportService.getAccruedReport(this.filters, true);
              this.toastService.success(
                'Export started, we will email you with a link to the file'
              );
            }
          });
        }
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    this.isLoading = true;
    const data = this.route.snapshot.data.data;
    this.dataArr = data.results[0].results;
    this.totalCount = data.results[0].totalCount;
    this.studentsCount = data.results[0].studentsCount;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.BEHAVIOR_CODE_ACCRUED_REPORT
    );
    this.filters = new AccruedReportFiltersDto({
      ...this.tableFilters,
      schoolId,
      active: true
    });
    this.tableFilters.count = data.options.totalItems;
    this.showPagination =
      this.tableFilters.itemsPerPage < this.tableFilters.count;
    this.isLoading = false;
  }

  public async onToggleFilterUpdated() {
    this.filters.active = !this.filters.active;
    this.onFiltersUpdated(this.filters);
    await this.applyFilters();
  }

  public onFiltersUpdated(filters: AccruedReportFiltersDto) {
    this.filters.mapFields(filters);
  }

  public async onInstantFiltersUpdated(filters: AccruedReportFiltersDto) {
    this.onFiltersUpdated(filters);
    await this.applyFilters();
  }

  public searchFilterUpdated(data: string | undefined) {
    this.filters['text'] = data;
    this.onFiltersUpdated(this.filters);
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    // todo: fix ITableFilters so "active" is a boolean or removed from the interface
    const newFilters = {
      ...newTableFilters,
      active: this.filters.active
    };
    this.filters = new AccruedReportFiltersDto({
      ...this.filters,
      ...newFilters
    });

    await this.applyFilters();
  }

  public async applyFilters(): Promise<void> {
    this.isLoading = true;
    const response = await this.reportService.getAccruedReport(this.filters);
    const data = response.results[0];
    this.dataArr = data.results;
    this.totalCount = data.totalCount;
    this.studentsCount = data.studentsCount;
    this.tableFilters.count = response.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }
}
