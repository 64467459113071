import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  BellScheduleTeacherPeriodResponseDto,
  StudentPerformanceDto,
  TeacherRostersByPeriodsFilterDto,
  TeacherRostersResponseDto
} from '@whetstoneeducation/hero-common';

@Injectable({
  providedIn: 'root'
})
export class AppTeacherRostersService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public getTeacherRostersDefaultFilters({
    periodIds
  }: {
    periodIds: number[];
  }): TeacherRostersByPeriodsFilterDto {
    const user = this.StorageManager.getLoggedInUser();

    return new TeacherRostersByPeriodsFilterDto({
      instructorCode: user.instructorCode,
      periodIds,
      schoolId: user.currentSchoolId
    });
  }

  public async getTeacherRosters(
    filters: TeacherRostersByPeriodsFilterDto
  ): Promise<TeacherRostersResponseDto<StudentPerformanceDto>> {
    return this.appClientDataService.execute<
      TeacherRostersResponseDto<StudentPerformanceDto>
    >(this.GET_ROUTES.TEACHER_ROSTERS, {
      queryParams: {
        ...(filters?.periodIds ? { periodIds: filters.periodIds } : {}),
        schoolId: filters.schoolId,
        instructorCode: filters.instructorCode
      }
    });
  }

  public async getTeacherRostersPeriods(): Promise<
    BellScheduleTeacherPeriodResponseDto[]
  > {
    const user = this.StorageManager.getLoggedInUser();

    if (!user.instructorCode) return [];

    return this.appClientDataService.execute(
      this.GET_ROUTES.TEACHER_ROSTERS_PERIODS,
      {
        pathParams: {
          instructorCode: user.instructorCode
        }
      }
    );
  }
}
