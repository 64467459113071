import { Injectable } from '@angular/core';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppCustomRostersService } from '../custom-rosters.service';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { ICustomRostersResolverData } from '../custom-roster-models/custom-rosters-resolver-data.interface';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppCustomRosterViewResolver extends BaseResolver<ICustomRostersResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public customRosterService: AppCustomRostersService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(route: ActivatedRouteSnapshot) {
    const id = route.queryParams.id;

    return this.customRosterService.getCustomRostersResolverData(
      id ? +id : undefined
    );
  }
}
