import { Injectable } from '@angular/core';
import { BaseService } from './shared/base-classes/base.service';
import { logger } from './shared/logger';
import { AppSessionHandlerService } from './pages/auth/session-handler.service';
import { AppAuthService } from './pages/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppDataInitService extends BaseService {
  public constructor(
    public sessionHandlerService: AppSessionHandlerService,
    public authService: AppAuthService
  ) {
    super();
  }

  public isVerifiedLinkRoute(): boolean {
    const url = new URL(window.location.href);
    const path = url.pathname;
    const queryParams = url.search;
    return path === '/verify-link' && queryParams.startsWith('?link=');
  }

  public async loadInitialAppData(): Promise<void> {
    try {
      if (this.isVerifiedLinkRoute()) {
        return;
      }

      if (!(await this.sessionHandlerService.checkIfAuthorizedFull())) {
        const session = await this.authService.getActiveSession();
        if (session)
          await this.sessionHandlerService.loadLoggedInStateAndData(session);
      }
      await this.loadCachedData();
    } catch (error) {
      logger.error(error);
      await this.sessionHandlerService.handleLogout(true);
    }
  }

  private async loadCachedData(): Promise<void> {
    const dataCalls: Array<Promise<any>> = [];
    const isLoggedIn = await this.sessionHandlerService.checkIfAuthorizedFull();
    if (isLoggedIn) {
      dataCalls.push(this.sessionHandlerService.loadLoggedInStateAndData());
    } else {
      this.sessionHandlerService.handleLogout(true);
    }

    await Promise.all(dataCalls);
  }
}
