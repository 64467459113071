<div class="mat-dialog-header">
  <div *ngIf="!isLoading" class="mat-dialog-title">Deactivate Records</div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <form [formGroup]="deactivateRecordsForm" *ngIf="!isLoading" novalidate>
    <mat-form-field class="full-width-input">
      <mat-label>Behavior Code</mat-label>
      <mat-select placeholder="All" formControlName="behaviorCodeId">
        <mat-option
          *ngFor="let option of behaviorCodeOptions"
          options-id="BEHAVIOR_CODE_OPTIONS"
          [value]="option.value"
          [option]="constantize(option.display)">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width-input">
      <mat-label>Start Date</mat-label>
      <input
        matInput
        [matDatepicker]="startDatePicker"
        formControlName="date" />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-slide-toggle [color]="'primary'" formControlName="preserveReactions">
      Preserve Reactions
    </mat-slide-toggle>
    <mat-form-field
      class="full-width-input"
      *ngIf="!isLoading && !!deactivateRecordsForm.controls['date'].value">
      <mat-label
        >Type {{ getSelectedBehaviorCodeConfirmPhrase() }} to confirm
      </mat-label>
      <input matInput [formControl]="confirmFormControl" />
    </mat-form-field>
  </form>

  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      type="submit"
      mat-button
      [class]="
        confirmed ? 'modal-submit-button' : 'modal-submit-button disabled'
      "
      (click)="submit()">
      Submit
    </button>
  </div>
</div>
