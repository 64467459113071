import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppSaveBarModule } from '../../shared/save-bar/save-bar.module';
import { AppSchoolGroupCreateEditComponent } from './school-group-create-edit.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppSchoolGroupsListComponent } from './school-groups-list/school-groups-list.component';
import { AppSchoolGroupsListTableComponent } from './school-groups-list/school-groups-list-table/school-groups-list-table.component';
import { AppSchoolGroupsListFiltersComponent } from './school-groups-list/school-groups-list-filters/school-groups-list-filters.component';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '../../shared/directives/directive.module';

@NgModule({
  declarations: [
    AppSchoolGroupCreateEditComponent,
    AppSchoolGroupsListComponent,
    AppSchoolGroupsListTableComponent,
    AppSchoolGroupsListFiltersComponent,
  ],
  exports: [AppSchoolGroupCreateEditComponent],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppSaveBarModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    AppPaginationModule,
    FontAwesomeModule,
    DirectiveModule
  ],
})
export class AppSchoolGroupsModule {}
