<div class="table-container mat-elevation-z2">
  <div class="table-filters-actions">
    <div><app-menu [containerStyles]="{'padding-left': '52px'}" [actions]="bulkActionButtons"></app-menu></div>
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="card-container">
    <app-student-card
      *ngFor="let student of students"
      [student]="student"
      [studentSelected]="selectedStudentsIds.includes(student.id)"
      [selectStudent]="selectStudent">
    </app-student-card>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
