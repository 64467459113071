"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const enums_1 = require("../../enums");
const school_groups_1 = require("../school-groups");
const transform_config_1 = require("../../transform-config");
const shared_1 = require("../shared");
class LoginResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    id;
    email;
    firstName;
    lastName;
    type;
    roles;
    privileges;
    currentSchoolId;
    schoolGroupId;
    currentSchool_name;
    schoolGroup_name;
    lastLogin;
    homepage;
    timezone;
    instructorCode;
    studentId;
    schoolGroups;
    students;
    passwordReset;
    settings = {};
    downloadFileUrl;
    mapFields(source) {
        super.mapFields(source);
        if (source.schoolGroup && !this.schoolGroups) {
            this.schoolGroups = [new school_groups_1.SchoolGroupResponseDto(source.schoolGroup)];
        }
        if (this.schoolGroupId &&
            this.schoolGroups &&
            Array.isArray(this.schoolGroups) &&
            this.schoolGroups.length > 0 &&
            source.schools &&
            Array.isArray(source.schools) &&
            source.schools.length > 0) {
            const schoolGroup = this.schoolGroups.find((sg) => sg.id === this.schoolGroupId);
            if (schoolGroup && source.schools.length > 0)
                schoolGroup.schools = source.schools.map((s) => {
                    return new shared_1.RelationDto({ id: s.id, name: s.name });
                });
        }
        if (source.student) {
            this.studentId = source.student.id;
        }
        return this;
    }
    mapUserDetailResponse(source) {
        source.getFields().forEach((field) => {
            if (this.getFields().includes(field)) {
                this[field] = source[field];
            }
        });
        this.settings = source.settings;
        return this;
    }
    getFields() {
        return [
            'id',
            'email',
            'firstName',
            'lastName',
            'type',
            'currentSchoolId',
            'schoolGroupId',
            'currentSchool_name',
            'schoolGroup_name',
            'lastLogin',
            'students',
            'instructorCode'
        ];
    }
    getTransforms() {
        return [
            {
                destination: 'privileges',
                transform: transform_config_1.TransformConfig.transformArray('roles', (sources) => {
                    const privileges = {};
                    for (const role of sources) {
                        if (role && role.privileges && Array.isArray(role.privileges))
                            switch (role.userType) {
                                case enums_1.UserTypeEnum.INTERNAL_ADMIN:
                                    privileges.internalAdmin = role.privileges.map((privilege) => privilege.name);
                                    break;
                                case enums_1.UserTypeEnum.GROUP_ADMIN:
                                    privileges.groupAdmin = role.privileges.map((privilege) => privilege.name);
                                    break;
                                default:
                                    if (role.schoolId) {
                                        privileges[role.schoolId] = role.privileges.map((privilege) => privilege.name);
                                    }
                            }
                    }
                    return privileges;
                })
            },
            {
                destination: 'roles',
                transform: transform_config_1.TransformConfig.transformArray('roles', (source) => {
                    return source.map((role) => {
                        return {
                            id: role.id,
                            name: role.name,
                            schoolId: role.schoolId,
                            privileges: role.privileges,
                            userType: enums_1.UserTypeEnum,
                            behaviorCodeTypes: role.behaviorCodeTypes
                        };
                    });
                })
            }
        ];
    }
}
exports.LoginResponseDto = LoginResponseDto;
