<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <div class="right-filters">
      <!-- Export Button -->
      <button mat-flat-button (click)="exportTable()">
        <fa-icon [icon]="['fas', 'upload']"></fa-icon> Export
      </button>
    </div>
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="school-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- ID Column -->
      <ng-container matColumnDef="{{columns.REFERENCE_ID}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.REFERENCE_ID}}
        </mat-header-cell>
        <mat-cell *matCellDef="let school">{{ school.referenceId }}</mat-cell>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let school">{{ school.name }}</mat-cell>
      </ng-container>
      <!-- City Column -->
      <ng-container matColumnDef="{{columns.SCHOOL_GROUP}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.SCHOOL_GROUP}}
        </mat-header-cell>
        <mat-cell *matCellDef="let school"
          >{{ school.schoolGroup_name }}</mat-cell
        >
      </ng-container>
      <!-- State Column -->
      <ng-container matColumnDef="{{columns.ACADEMIC_YEAR}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.ACADEMIC_YEAR}}
        </mat-header-cell>
        <mat-cell *matCellDef="let school"
          >{{ school.currentAcademicYear }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="{{columns.ACTIVE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.ACTIVE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let school">{{ school.active }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell
          *matHeaderCellDef
          class="action-column-header"
          mat-sort-header>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let school">
          <button
            mat-flat-button
            (click)="viewSchool(school)"
            class="action-button icon-button"
            options-id="EDIT_SCHOOL"
            [option]="school.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
