"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeAccess = exports.UserTypeEnum = void 0;
var UserTypeEnum;
(function (UserTypeEnum) {
    UserTypeEnum["STUDENT"] = "student";
    UserTypeEnum["GUARDIAN"] = "guardian";
    UserTypeEnum["TEACHER"] = "teacher";
    UserTypeEnum["SCHOOL"] = "school";
    UserTypeEnum["GROUP_ADMIN"] = "group-admin";
    UserTypeEnum["INTERNAL_ADMIN"] = "internal-admin";
})(UserTypeEnum || (exports.UserTypeEnum = UserTypeEnum = {}));
/**
 * List of user types with what other user types they cannot access
 * If not in the list, they cannot access any other user types
 */
exports.UserTypeAccess = {
    [UserTypeEnum.TEACHER]: [
        UserTypeEnum.SCHOOL,
        UserTypeEnum.GROUP_ADMIN,
        UserTypeEnum.INTERNAL_ADMIN
    ],
    [UserTypeEnum.SCHOOL]: [
        UserTypeEnum.GROUP_ADMIN,
        UserTypeEnum.INTERNAL_ADMIN
    ],
    [UserTypeEnum.GROUP_ADMIN]: [UserTypeEnum.INTERNAL_ADMIN],
    [UserTypeEnum.INTERNAL_ADMIN]: []
};
