import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppSaveBarModule } from '../../shared/save-bar/save-bar.module';
import { AppUserCreateEditComponent } from './user-create-edit.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppUsersListComponent } from './users-list/users-list.component';
import { AppUsersListTableComponent } from './users-list/users-list-table/users-list-table.component';
import { AppUsersListFilters } from './users-list/users-list-filters/users-list-filters.component';
import { MatIconModule } from '@angular/material/icon';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppBannersModule } from 'src/app/shared/banners/banners.module';
import { AppLinkDropdownModule } from '../../shared/link-dropdown/link-dropdown.module';

@NgModule({
  declarations: [
    AppUserCreateEditComponent,
    AppUsersListComponent,
    AppUsersListTableComponent,
    AppUsersListFilters
  ],
  exports: [AppUserCreateEditComponent],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppSaveBarModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    AppPaginationModule,
    FontAwesomeModule,
    DirectiveModule,
    AppBannersModule,
    AppLinkDropdownModule
  ]
})
export class AppUsersModule {}
