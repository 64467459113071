<form
  class="behavior-code-notification"
  [formGroup]="behaviorCodeNotificationForm"
  novalidate>
  <mat-card
    appearance="outlined"
    class="behavior-code-notification-form mat-elevation-z3">
    <mat-card-header>
      <mat-card-title *ngIf="!isDefault && isCreating">
        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
        Add Notification to Behavior Code
      </mat-card-title>
      <mat-card-title *ngIf="!isDefault && !isCreating">
        <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
        Edit Notification to Behavior Code
      </mat-card-title>
      <mat-card-title *ngIf="isDefault">
        <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
        Edit Default Notification to Behavior Code
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="behavior-code-intervention-form-content">
      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Notification</mat-label>
          <mat-select formControlName="notificationTemplateId">
            <mat-option
              *ngFor="let type of notificationOptions"
              [value]="type.value">
              {{type.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-row" *ngIf="!isDefault">
        <mat-form-field class="full-width-input">
          <mat-label>Should happen after X entries</mat-label>
          <input matInput formControlName="triggerCount" type="number" />
          <app-error-display
            [form]="behaviorCodeNotificationForm"
            controlName="triggerCount"></app-error-display>
        </mat-form-field>
        <small class="help-text">
          This notification will occur after this amount of tracks. This can
          happen in parallel with other actions. To set default, set value to 0.
        </small>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <app-error-display
            [form]="behaviorCodeNotificationForm"
            controlName="description"></app-error-display>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</form>
