"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledReactionResponseDto = void 0;
const class_transformer_1 = require("class-transformer");
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
class ScheduledReactionResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.calendarDay = options.calendarDay;
        this.capacity = options.capacity;
        this.reaction = options.reaction;
    }
    getFields() {
        return [
            'id',
            'calendarDay',
            'capacity',
            'students',
            'name',
            'reactionEntries'
        ];
    }
    id;
    calendarDay;
    capacity;
    name;
    reaction;
    reactionEntries;
    static mapReactions(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, ScheduledReactionResponseDto);
    }
}
exports.ScheduledReactionResponseDto = ScheduledReactionResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ScheduledReactionResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ScheduledReactionResponseDto.prototype, "calendarDay", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ScheduledReactionResponseDto.prototype, "capacity", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], ScheduledReactionResponseDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ScheduledReactionResponseDto.prototype, "reaction", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], ScheduledReactionResponseDto.prototype, "reactionEntries", void 0);
