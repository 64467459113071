import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  PaginationResultsDto,
  ReactionCreateDto,
  ReactionEditDaysDto,
  ReactionResponseDto,
  ReactionUpdateDto,
  ScheduledReactionResponseDto
} from '@whetstoneeducation/hero-common';
import { IReactionsListFilters } from './reactions-list/reactions-list-models/reactions-list-filters.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';

@Injectable({
  providedIn: 'root'
})
export class AppReactionsService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async editDays(
    reactionId: number,
    editDays: ReactionEditDaysDto
  ): Promise<ReactionResponseDto> {
    return this.appClientDataService.execute<ReactionResponseDto>(
      this.PATCH_ROUTES.EDIT_DAYS,
      {
        method: this.METHOD.PATCH,
        pathParams: { id: reactionId },
        body: editDays
      }
    );
  }

  public async getReactionsList(
    filters: IReactionsListFilters
  ): Promise<PaginationResultsDto<ReactionResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<ReactionResponseDto>
    >(this.GET_ROUTES.REACTIONS_LIST, {
      queryParams: {
        ...(filters.searchInput ? { searchInput: filters.searchInput } : {}),
        ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {}),
        active: !filters.inactive
      }
    });
  }

  public getReactionsListDefaultFilters(): IReactionsListFilters {
    const user = this.StorageManager.getLoggedInUser();
    return {
      searchInput: null,
      schoolId: user.currentSchoolId,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.REACTIONS_LIST
      ),
      inactive: false
    };
  }

  public getReactionById(id: number): Promise<ReactionResponseDto> {
    return this.appClientDataService.execute<ReactionResponseDto>(
      this.GET_ROUTES.REACTION_BY_ID,
      { pathParams: { id } }
    );
  }

  public async createReaction(
    reaction: ReactionCreateDto
  ): Promise<ReactionResponseDto> {
    return this.appClientDataService.execute<ReactionResponseDto>(
      this.POST_ROUTES.CREATE_REACTION,
      {
        method: this.METHOD.POST,
        body: reaction
      }
    );
  }

  public async updateReaction(
    reaction: ReactionUpdateDto,
    id: number
  ): Promise<ReactionResponseDto> {
    return this.appClientDataService.execute<ReactionResponseDto>(
      this.PUT_ROUTES.UPDATE_REACTION,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: reaction
      }
    );
  }

  public async getScheduledReactions(
    reactionId: number,
    filters: TableFilterOptions
  ): Promise<ScheduledReactionResponseDto[]> {
    return this.appClientDataService.execute<ScheduledReactionResponseDto[]>(
      this.GET_ROUTES.SCHEDULED_REACTIONS_LIST,
      { queryParams: { reactionId, ...filters } }
    );
  }
}
