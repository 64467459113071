import {
  LoginResponseDto,
  UserTypeAccess,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { LocalStorageKeys } from './local-storage-keys.enum';

/**
 * The StorageManager will handle all I/O of the web browser's local/session storage use for the application.
 * It provides easy and standardized access to all of the data that we need to store retrieve regularly.
 */
export class StorageManager {
  public static getLoggedInUser(): LoginResponseDto {
    const loggedInUser: string = localStorage.getItem(
      LocalStorageKeys.CURRENT_USER
    );
    if (loggedInUser) {
      return new LoginResponseDto(JSON.parse(loggedInUser));
    } else {
      return null;
    }
  }

  public static isUserType(types: UserTypeEnum[]): boolean {
    const user = StorageManager.getLoggedInUser();
    return user && types.includes(user.type);
  }

  public static hasAccessToType(types: UserTypeEnum[]): boolean {
    const userTypesAccess = StorageManager.getLoggedInUserTypesAccess();
    if (!userTypesAccess) return false;
    return types.some((type) => !userTypesAccess.includes(type));
  }

  /**
   * Get the id from the currently logged in user
   */
  public static getLoggedInUserId(): number {
    return StorageManager.getLoggedInUser()?.id;
  }

  /**
   * Get the id from the currently logged in user
   */
  public static getLoggedInUserName(): string {
    const user = StorageManager.getLoggedInUser();
    return user.firstName + ' ' + user.lastName;
  }

  /**
   * Get the type from the currently logged in user
   */
  public static getLoggedInUserType(): UserTypeEnum {
    return StorageManager.getLoggedInUser()?.type;
  }

  /**
   *
   * @returns An array of UserTypeEnum that the currently logged in user has no access to, or null
   * if the user has no access to any of the types
   */
  public static getLoggedInUserTypesAccess(): UserTypeEnum[] | null {
    const type = StorageManager.getLoggedInUserType();
    const typesAccess = UserTypeAccess[type];

    return typesAccess;
  }

  /**
   * Get the text that describes the timezone for our user
   */
  public static getLoggedInUserTimezone(): string {
    return StorageManager.getLoggedInUser()?.timezone;
  }

  /**
   * Removes all local/session storage key/value pairs.
   */
  public static clearAllStorage(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  public static clearLoggedInUser(): void {
    localStorage.removeItem(LocalStorageKeys.CURRENT_USER);
  }

  public static getActiveSchoolGroupId(): number {
    return StorageManager.getLoggedInUser()?.schoolGroupId;
  }

  public static getCurrentSchoolId(): number {
    const user = StorageManager.getLoggedInUser();
    if (user && user.currentSchoolId) {
      return user.currentSchoolId;
    } else {
      return null;
    }
  }

  public static saveUserInfo(sessionInfo: LoginResponseDto): void {
    localStorage.setItem(
      LocalStorageKeys.CURRENT_USER,
      JSON.stringify(sessionInfo)
    );
  }
}
