import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { logger } from '../logger';

export enum RegisteredStates {
  SFTP_IMPORT_LIST_FILTERS = 'sftpImportListFilters'
}

@Injectable({
  providedIn: 'root'
})
export class AppBasicStateService {
  private states: Map<string, BehaviorSubject<any>> = new Map();
  private defaultState = {};
  // Initialize filter state for a table
  initState(stateId: string, initialState: any) {
    if (!this.states.has(stateId)) {
      this.states.set(stateId, new BehaviorSubject<any>(initialState));
    } else {
      const message = `State already initialized for identifier: ${stateId}.`;
      logger.error(message);
      throw new Error(message);
    }
  }

  // For components to set a new filter state for a specific table
  setState(stateId: string, newState: any) {
    if (this.states.has(stateId)) {
      this.states.get(stateId).next(newState);
    } else {
      const message =
        'State not initialized for identifier: ' +
        `${stateId} while attempting to set new state.`;
      logger.error(message);
      throw new Error(message);
    }
  }

  getState(identifier: string): Observable<any> {
    if (this.states.has(identifier)) {
      return this.states.get(identifier).asObservable();
    } else {
      logger.warn(
        'No state found for identifier: ' +
          `${identifier} while attempting to retrieve.`
      );
      return of(this.defaultState);
    }
  }

  unregisterState(identifier: string) {
    if (this.states.has(identifier)) {
      this.states.delete(identifier);
    } else {
      logger.warn(
        'No state found for identifier: ' +
          `${identifier} while attempting to deregister.`
      );
    }
  }
}
