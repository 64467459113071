"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MappableBase = void 0;
class MappableBase {
    /**
     * @abstract
     * @function getFields
     * @returns string[]
     * @example ['fields'...]
     * @description array of fields that should be mapped into this object
     */
    getFields() {
        return [];
    }
    /**
     * @public
     * @function getDtos
     * @returns {string[]} an array of the child dto names to be mapped
     * @example ['field'...]
     * @description If there are child dtos implement this method to map their fields to the parent object.
     */
    getNested() {
        return [];
    }
    getTransforms() {
        return [];
    }
    /**
     * @public
     * @function mapNested
     * @param source {MappableBase} - the object from which to map fields
     * @description
     * This function maps the fields of the source to the nested fields of the object
     * implementing this class.
     * }
     */
    mapNested(source) {
        for (const nestedConfig of this.getNested()) {
            let destObject = this[nestedConfig.destField] instanceof nestedConfig.destType
                ? this[nestedConfig.destField]
                : new nestedConfig.destType();
            let sourceObject = nestedConfig.sourceField
                ? source[nestedConfig.sourceField]
                : source;
            if (sourceObject) {
                if (nestedConfig.mapFunction) {
                    destObject = nestedConfig.mapFunction(sourceObject, destObject);
                }
                else {
                    destObject.mapFields(sourceObject);
                }
                this[nestedConfig.destField] = destObject;
            }
        }
        return this;
    }
    mapTransforms(source) {
        const transforms = this.getTransforms();
        for (const transform of transforms) {
            const destField = transform.destination;
            let allFieldsExist = true;
            if (transform.sourceFields) {
                for (const sourceField of transform.sourceFields) {
                    if (!source[sourceField])
                        allFieldsExist = false;
                }
            }
            if (allFieldsExist)
                this[destField] = transform.transform(source);
        }
        return this;
    }
    /**
     * @public
     * @function mapFields
     * @param source {MappableBase} - the source from which to map fields
     * @param skipNested - whether to skip mapping nested fields
     * @param skipTransforms - whether to skip mapping transforms
     * @description
     * This function maps the fields of the entity to the source implementing this class.
     * It doesn't have to be implemented and called but that is recommended for type
     * safety.
     * @example
     * public mapFields(source: UserResponseDto | PostUserDto) {
     *   super.mapFields(source, null);
     * }
     */
    mapFields(source, skipNested = false, skipTransforms = false) {
        for (const field of this.getFields()) {
            if (!field.includes('_') && this.canReadSource(source[field]))
                this[field] = source[field];
            if (field.includes('_')) {
                const subFields = field.split('_');
                const parentField = subFields[0];
                const childField = subFields[1];
                if (source[parentField] &&
                    this.canReadSource(source[parentField][childField]))
                    this[field] = source[parentField][childField];
            }
        }
        if (!skipNested)
            this.mapNested(source);
        if (!skipTransforms)
            this.mapTransforms(source);
        return this;
    }
    canReadSource(value) {
        return value || value != null;
    }
    static mapAll(sources, ResultType) {
        return sources.map((source) => {
            const result = new ResultType();
            result.mapFields(source);
            return result;
        });
    }
}
exports.MappableBase = MappableBase;
