<form
  class="behavior-code-reaction"
  [formGroup]="behaviorCodeReactionForm"
  novalidate>
  <mat-card appearance="outlined" class="behavior-code-reaction-form mat-elevation-z3">
    <mat-card-header class="behavior-code-reaction-form-header card-header">
      <mat-card-title *ngIf="!isDefault">
        <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
        Add Reaction to Behavior Code
      </mat-card-title>
      <mat-card-title *ngIf="isDefault">
        <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
        Edit Default Reaction for Behavior Code
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="behavior-code-reaction-form-content">
      <div class="default-reaction-message" *ngIf="isDefault">
        The Default Reaction is assigned whenever a reaction is not explicitly
        defined for a given "Assign At" count.
      </div>
      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Reaction</mat-label>
          <mat-select formControlName="reactionId">
            <mat-option
              *ngFor="let type of reactionOptions"
              [value]="type.value">
              {{type.display}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-row" *ngIf="!isDefault">
        <mat-form-field class="full-width-input">
          <mat-label>Assign At: Should happen after X entries</mat-label>
          <input matInput formControlName="triggerCount" type="number" />
          <app-error-display
            [form]="behaviorCodeReactionForm"
            controlName="triggerCount"></app-error-display>
        </mat-form-field>
        <small class="help-text">
          This action will occur after this amount of tracks. Add a very high
          number if you are configuring a code where you want to use dropdown
          options.
        </small>
      </div>

      <mat-form-field *ngIf="isDefault" style="display: none">
        <input matInput formControlName="triggerCount" type="number" />
      </mat-form-field>

      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Remove or add points</mat-label>
          <input matInput formControlName="points" type="number" />
          <app-error-display
            [form]="behaviorCodeReactionForm"
            controlName="points"></app-error-display>
        </mat-form-field>
        <small class="help-text">
          This action will add or remove the selected amount of points from a
          tracked student. Use negative numbers for removing points.
        </small>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Repeat action X times when rule met</mat-label>
          <input matInput formControlName="reactionMultiplier" type="number" />
          <app-error-display
            [form]="behaviorCodeReactionForm"
            controlName="reactionMultiplier"></app-error-display>
        </mat-form-field>
        <small class="help-text">
          This will multiply the action by the number selected. For example, if
          you select 2 for a detention, the student will be given 2 detentions.
          This should not be changed in most cases.
        </small>
      </div>

      <div class="form-row">
        <mat-form-field class="full-width-input">
          <mat-label>Print X copies of each pass</mat-label>
          <input matInput formControlName="printCopies" type="number" />
          <app-error-display
            [form]="behaviorCodeReactionForm"
            controlName="printCopies"></app-error-display>
        </mat-form-field>
        <small class="help-text">
          This will determine how many passes are printed by default when this
          action is used.
        </small>
      </div>

      <div class="form-row" *ngIf="!isDefault">
        <mat-slide-toggle
          [color]="'primary'"
          class="filter-item"
          formControlName="active">
          Active
        </mat-slide-toggle>
      </div>
    </mat-card-content>
  </mat-card>
</form>
