import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../../app-material.module';
import { FormsModule } from '@angular/forms';
import { AppPipesModule } from '../pipes/pipes.module';
import { AppAuthModule } from '../../pages/auth/auth.module';
import { AppFileUploadComponent } from './file-upload.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    FormsModule,
    AppPipesModule,
    AppAuthModule
  ],
  declarations: [AppFileUploadComponent],
  exports: [AppFileUploadComponent]
})
export class AppFileUploadModule {}
