<div class="reactions-list-filters-content">
  <!-- Search -->
  <mat-form-field class="filter-item search-input">
    <mat-label>Search by name or message</mat-label>
    <fa-icon matPrefix [icon]="['fas', 'magnifying-glass']"></fa-icon>
    <input
      data-test-id="SEARCH_FILTER_INPUT"
      matInput
      (keyup.enter)="filters.searchInput = $event.target.value; filtersUpdated.emit(filters)" />
  </mat-form-field>

  <mat-slide-toggle
    [color]="'primary'"
    class="filter-item"
    (toggleChange)="filters.inactive = !filters.inactive; filtersUpdated.emit(filters)">
    Show Inactive
  </mat-slide-toggle>
</div>
