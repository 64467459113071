import { Pipe, PipeTransform } from "@angular/core";

/*
 * If a given text value is null or empty string, will return the default value specified in the pipe.
 * Takes an optional argument to default the value to if the specified value is null--otherwise uses the system default.
 * Usage:
 *   value | ifNull:'-'
 * Examples:
 *   {{ 'Whetstone Education' | ifNull:'-' }}
 *   formats to: 'Whetstone Education'
 *
 *   {{ null | ifNull:'A Company' }}
 *   formats to: 'A Company'
 *
 *   {{ null | ifNull }}
 *   formats to: '-'
 */
@Pipe({ name: "ifNull" })
export class AppIfNullValuePipe implements PipeTransform {
  transform(value: string, defaultValue?: string): string {
    if (value) {
      return value;
    } else {
      if (defaultValue) {
        return defaultValue;
      } else {
        return "-";
      }
    }
  }
}
