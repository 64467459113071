<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <div class="right-filters">
      <button mat-flat-button (click)="exportTable()">
        <fa-icon [icon]="['fas', 'upload']"></fa-icon>
        Export
      </button>
    </div>
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="notification-template-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.IS_GROUP_LEVEL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.IS_GROUP_LEVEL}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.isSchoolGroupLevel}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.SCHOOL_GROUP}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.SCHOOL_GROUP}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.schoolGroup_name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.SCHOOL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.SCHOOL}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.school_name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.BODY_TYPE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.BODY_TYPE}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.bodyType}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell class="action-column-header" *matHeaderCellDef
          >{{columns.ACTIONS}}</mat-header-cell
        >
        <mat-cell class="action-column-header" *matCellDef="let row">
          <button
            class="action-button icon-button"
            mat-flat-button
            (click)="editTemplate(row)">
            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
          </button>
          <button
            class="action-button icon-button"
            mat-flat-button
            (click)="deleteTemplate(row)">
            <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
