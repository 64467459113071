import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AppBehaviorCodesService } from '../../../behavior-codes/behavior-codes.service';
import { AccruedReportFiltersDto, BehaviorCodeResponseDto } from '@whetstoneeducation/hero-common';
import { AppStudentsService } from '../../../students/students.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-accrued-report-filters',
  templateUrl: './accrued-report-filters.template.html',
  styleUrls: ['./accrued-report-filters.scss']
})
export class AppAccruedReportFilters extends BaseComponent implements OnInit {
  public isLoading: boolean = true;
  public behaviorCodeOptions: BehaviorCodeResponseDto[] = [];
  public studentsGrades: number[] = [];
  public selectedBehaviorCode?: string = null;

  @Input()
  public filters: AccruedReportFiltersDto;
  @Output()
  private filtersUpdated: EventEmitter<AccruedReportFiltersDto> = new EventEmitter<AccruedReportFiltersDto>();
  @Output()
  private applyFilters: EventEmitter<void> = new EventEmitter();
  @Input()
  public loadingItems: boolean;

  public constructor(
    private readonly behaviorCodesService: AppBehaviorCodesService,
    private readonly studentsService: AppStudentsService
  ) {
    super();
  }


  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    const { currentSchoolId: schoolId } = this.StorageManager.getLoggedInUser();
    this.behaviorCodeOptions = await this.behaviorCodesService.getBehaviorCodeListUnpaginated({
      schoolId,
    });
    this.studentsGrades = await this.studentsService.getStudentGrades({ schoolId });

    this.isLoading = false;
  }

  public updateSelectedBehaviorCode(): void {
    this.selectedBehaviorCode = this.behaviorCodeOptions.find((option) => option.id == this.filters['behaviorCodeId'])?.code;
  }

  public onFilterUpdated(field: string, data?: MatSelectChange | number): void {
    const value = data instanceof MatSelectChange ? data.value : data;
    this.filters[field] = value || undefined;
    this.filtersUpdated.emit(this.filters);
  }

  public onDateUpdated(field: string, event: MatDatepickerInputEvent<Date>): void {
    const value = event.target.value;
    const date = !value ? null
    : field === 'endDate'
    ? value.setHours(23, 59, 59, 999)
    : value.setHours(0, 0, 0, 0);

    this.filters[field] = date ? value.getTime() : null;
    this.filtersUpdated.emit(this.filters);
  }

  public onApplyFilters(): void {
    this.applyFilters.emit();
  }
}
