export enum BehaviorHistoryTableColumnsEnum {
  DATE = 'Date',
  BEHAVIOR = 'Behavior',
  ACTION = 'Reaction',
  ACTION_DATE = 'Reaction Date',
  COMPLIED = 'Complied',
  POINTS = 'Points',
  ISSUED_BY = 'Issued By',
  ACTIVE = 'Active',
  ACTIONS = 'Actions'
}
