import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AccruedReportFiltersDto } from '@whetstoneeducation/hero-common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-school-activity-report-filters',
  templateUrl: './school-activity-report-filters.template.html',
  styleUrls: ['./school-activity-report-filters.scss']
})
export class AppSchoolActivityReportFilters extends BaseComponent {
  @Input()
  public filters: AccruedReportFiltersDto;
  @Output()
  private filtersUpdated: EventEmitter<AccruedReportFiltersDto> = new EventEmitter<AccruedReportFiltersDto>();
  @Output()
  private applyFilters: EventEmitter<void> = new EventEmitter();
  @Input()
  public loadingItems: boolean;

  public constructor(
  ) {
    super();
  }

  /**
   * Sets the hours to end of the day if it is endDate and to start of the day if it is startDate
   * If the value is null, then the date is null and no hours are set
   * @param field can be either startDate or endDate
   * @param event
   */
  public onDateUpdated(field: string, event: MatDatepickerInputEvent<Date>): void {
    const value = event.target.value;
    const date = !value ? null
    : field === 'endDate'
    ? value.setHours(23, 59, 59, 999)
    : value.setHours(0, 0, 0, 0);

    this.filters[field] = date ? value.getTime() : null;
    this.filtersUpdated.emit(this.filters);
  }

  public onApplyFilters(): void {
    this.applyFilters.emit();
  }
}
