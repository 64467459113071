import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnInit
} from '@angular/core';
import {
  PrivilegeEnum,
  StudentBasicResponseDto,
  StudentPerformanceDto,
  ValidationUtil
} from '@whetstoneeducation/hero-common';
import { Router } from '@angular/router';
import { logger } from '../logger';
import { AppToastManagerService } from '../services/toast-manager.service';
import { BaseComponent } from '../base-classes/base.component';

@Component({
  selector: 'app-student-card',
  templateUrl: './student-card.template.html',
  styleUrls: ['./student-card.scss']
})
export class AppStudentCardComponent extends BaseComponent implements OnInit {
  @Input() student: StudentPerformanceDto;
  @Input() studentSelected: boolean;
  @Input() selectStudent: EventEmitter<number>;

  selectedImage: string | ArrayBuffer | null = 'assets/img/ghost-user-icon.png';

  constructor(
    public router: Router,
    @Inject(AppToastManagerService)
    private toastService: AppToastManagerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedImage = this.student.profilePhotoUrl
      ? this.student.profilePhotoUrl
      : this.selectedImage;
  }

  toggleStudentSelection(studentId: number): void {
    const { privileges, currentSchoolId } =
      this.StorageManager.getLoggedInUser();
    if (
      ValidationUtil.hasPrivilege(
        PrivilegeEnum.TRACK_BEHAVIOR,
        privileges,
        currentSchoolId
      )
    ) {
      this.selectStudent.emit(studentId);
    }
  }

  viewStudentProfile(studentId: number): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/students/${studentId}/profile`])
    );
    // open student profile in new tab
    // instead of using the same tab
    const newTab = window.open(url, '_blank');
    if (!newTab || newTab.closed || typeof newTab.closed == 'undefined') {
      // new tab was blocked
      logger.error('New tab was blocked');
      this.toastService.warn(
        'New tab was blocked, to open student profile in a new tab please allow popups for this site. Navigation should proceed in 3 seconds.'
      );
      setTimeout(() => {
        this.router.navigate([`/students/${studentId}/profile`]);
      }, 3000);
    }
  }
}
