<div class="mat-dialog-header">
  <div class="mat-dialog-title">
    <ng-container *ngIf="behaviorEntry">
      Delete {{ behaviorEntry.behaviorCodeName }}
    </ng-container>
  </div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <div *ngIf="behaviorEntry" class="mat-dialog-content">
    <div class="dialog-content-message">
      <p class="content-text">Are you sure you want to delete this behavior?</p>
      <p>Actions attached to this tracking will also be deleted</p>
    </div>
    <div class="modal-action-row">
      <button
        type="button"
        mat-button
        class="modal-cancel-button"
        (click)="dialogRef.close()">
        Cancel
      </button>
      <button
        mat-button
        class="modal-delete-button"
        type="submit"
        (click)="deleteBehaviorEntry()">
        Yes, Delete
      </button>
    </div>
  </div>
</div>
