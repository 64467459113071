import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { SftpSettingsResponseDto } from '@whetstoneeducation/hero-common';
import { ISftpSettingsListFilters } from './sftp-settings-list-models/sftp-settings-list-filters.interface';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSftpService } from '../sftp.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { logger } from '../../../shared/logger';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sftp-settings-list',
  templateUrl: './sftp-settings-list.template.html',
  styleUrls: ['./sftp-settings-list.scss']
})
export class AppSftpSettingsListComponent
  extends BaseComponent
  implements OnDestroy
{
  public sftpSettings: SftpSettingsResponseDto[];
  public filters: ISftpSettingsListFilters;
  public isLoading: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public sftpService: AppSftpService,
    public pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action) => {
        if (action === HeaderButtonAction.CREATE) {
          await this.router.navigate(['sftp', 'settings', 'new']);
        }
        if (action === HeaderButtonAction.LIST_ACCOUNTS) {
          await this.router.navigate(['sftp', 'accounts']);
        }
      });
    const data = this.route.snapshot.data.data;
    this.sftpSettings = data.sftpSettings.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.sftpSettings.options.totalItems;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateSftpSettingsList(this.filters);
    this.isLoading = false;
  }

  public async updateSftpSettingsList(
    filters: ISftpSettingsListFilters
  ): Promise<void> {
    try {
      const newData = await this.sftpService.getSftpSettingsList(filters);
      this.sftpSettings = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error getting SFTP settings list');
    }
  }

  public async updateFilters(
    newFilters: ISftpSettingsListFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.SFTP_SETTINGS_LIST,
      this.filters.tableFilters
    );
    await this.updateSftpSettingsList(this.filters);
    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
