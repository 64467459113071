import { logger } from 'src/app/shared/logger';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { IPrivilege } from '@whetstoneeducation/hero-common';
import { AppAuthService } from '../pages/auth/auth.service';
import { AppToastManagerService } from '../shared/services/toast-manager.service';
import { AppSessionHandlerService } from '../pages/auth/session-handler.service';

/**
 * Determines if the user is logged in and if they should be able to go to the page based on their permissions.
 */
@Injectable()
export class AuthGuard  {
  public constructor(
    public toastManager: AppToastManagerService,
    public router: Router,
    public authService: AppAuthService,
    public sessionHandlerService: AppSessionHandlerService
  ) {}

  /**
   * Whether or not the user is allowed to access a given route. First checks
   * that the token exists and isn't expired. If the token is expired we try
   * to refresh it. Then checks if a role is required and the user has that role.
   */
  public async canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<boolean | UrlTree> {
    if (this.sessionHandlerService.getIsLoggedIn()) {
      return this.validatePrivilege(route);
    } else {
      if (this.sessionHandlerService.getIsLoggedIn()) {
        this.toastManager.success('Your session has expired.');
      }
      await this.sessionHandlerService.handleLogout();
      return this.router.parseUrl('/login');
    }
  }

  /**
   * Validate that a user has the required privilege to view a page
   * @param route The route we are trying to access
   */
  private validatePrivilege(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config on the data property
    const requiredPrivileges: IPrivilege[] = route.data.privileges;
    if (requiredPrivileges && requiredPrivileges.length) {
      // Check that the user has the privilege required for the given route
      const hasPrivilege = true;
      if (hasPrivilege) {
        return true;
      } else {
        logger.error(
          'FORBIDDEN: You do not have the required permissions to view this page.'
        );
        this.toastManager.warn('You do not have permission to view this page!');
        return false;
      }
    } else {
      return true;
    }
  }
}
