import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import {
  PaginationResultsDto,
  ReactionResponseDto,
  ScheduledReactionResponseDto,
} from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppReactionsService } from '../reactions.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IReactionEditResolverData } from './reaction-edit-resolver-data.interface';
import { TableFilterOptions } from 'src/app/shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from 'src/app/shared/tables/table-filters/table-filters-page-key.enum';

@Injectable({ providedIn: 'root' })
export class AppReactionsEditResolver extends BaseResolver<IReactionEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public reactionsService: AppReactionsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IReactionEditResolverData> {
    const id = route.paramMap.get('reactionId');
    const dataCalls = [
      this.reactionsService.getReactionById(+id),
      this.reactionsService.getScheduledReactions(
        +id,
        TableFilterOptions.getPageDefault(
          TableFiltersPageKeyEnum.SCHEDULED_REACTIONS_LIST
        )
      ),
    ];

    const [reaction, scheduledReactions] = (await Promise.all(dataCalls)) as [
      ReactionResponseDto,
      PaginationResultsDto<ScheduledReactionResponseDto>
    ];

    return {
      reaction,
      scheduledReactions: scheduledReactions.results,
    };
  }
}
