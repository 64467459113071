import { ReactionEntriesListResponseDto } from "@whetstoneeducation/hero-common";
import { PrintBuilder } from "../../../shared/printer/builder.utils";

export abstract class ReactionPrintBuilder extends PrintBuilder<ReactionEntriesListResponseDto> {

  abstract buildHeader(): ReactionPrintBuilder;

  abstract buildSubHeader(): ReactionPrintBuilder;

  abstract buildStudentIdEntries(): ReactionPrintBuilder;

  abstract buildStudentName(): ReactionPrintBuilder;

  abstract buildEntryDateTime(): ReactionPrintBuilder;

  abstract buildPeriod(): ReactionPrintBuilder;

  abstract buildActionTypeDate(): ReactionPrintBuilder;

  abstract buildPoints(): ReactionPrintBuilder;

  abstract buildBarcode(): ReactionPrintBuilder;

  protected getStudentNames(): string[] {
    return this.data.studentName.split(' ');
  }

  protected getStudentId(): string {
    return this.data.studentExtId?.length ? this.data.studentExtId : 'N/A';
  }

}
