<div class="notification-template-list-filters-content">
  <!-- isGlobal -->
  <mat-slide-toggle
    (change)="filters.isGlobal = $event.checked; filtersUpdated.emit(filters)"
    *ngIf="currentUser.type === UserTypeEnum.INTERNAL_ADMIN && !currentUser.schoolGroupId"
    [checked]="filters.isGlobal"
    class="filter-item">
    Global
  </mat-slide-toggle>
  <mat-slide-toggle
    (change)="filters.isSchoolGroupLevel = $event.checked; filtersUpdated.emit(filters)"
    *ngIf="!currentUser.currentSchoolId"
    [checked]="filters.isSchoolGroupLevel"
    class="filter-item">
    Group Level
  </mat-slide-toggle>
  <mat-form-field class="filter-item search-input">
    <mat-label>Body Type</mat-label>
    <mat-select
      (selectionChange)="filters.bodyType = $event.value; filtersUpdated.emit(filters)"
      [(value)]="filters.bodyType">
      <mat-option *ngFor="let type of bodyTypes" [value]="type">
        {{ type }}
      </mat-option>
      <mat-option [value]="null">All</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="filter-item search-input">
    <mat-label>Name</mat-label>
    <input
      (keyup.enter)="filters.name = $event.target.value; filtersUpdated.emit(filters)"
      matInput
      placeholder="Search by name" />
  </mat-form-field>
  <mat-form-field
    *ngIf="currentUser.type === UserTypeEnum.INTERNAL_ADMIN && !currentUser.schoolGroupId"
    class="filter-item">
    <mat-label>School Group</mat-label>
    <mat-select
      (selectionChange)="updateSchoolGroup($event)"
      [(value)]="filters.schoolGroupId">
      <mat-option
        *ngFor="let group of schoolGroupOptions"
        [value]="group.value">
        {{ group.display }}
      </mat-option>
      <mat-option [value]="null">All</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="!currentUser.currentSchoolId" class="filter-item">
    <mat-label>School</mat-label>
    <mat-select
      (selectionChange)="filters.schoolId = $event.value; filtersUpdated.emit(filters)"
      [(value)]="filters.schoolId">
      <mat-option *ngFor="let school of schoolOptions" [value]="school.value">
        {{ school.display }}
      </mat-option>
      <mat-option [value]="null">All</mat-option>
    </mat-select>
  </mat-form-field>
</div>
