import { ISchoolsListFilters } from '../schools-list-models/schools-list-filters.interface';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';

@Component({
  selector: 'app-schools-list-filters',
  templateUrl: './schools-list-filters.template.html',
  styleUrls: ['./schools-list-filters.scss'],
})
export class AppSchoolsListFilters extends BaseComponent {
  @Input() filters: ISchoolsListFilters;

  @Output() filtersUpdated: EventEmitter<ISchoolsListFilters>;

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<ISchoolsListFilters>();
  }
}
