"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolGroupResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const transform_config_1 = require("../../transform-config");
class SchoolGroupResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.name = options.name;
        this.schools = options.schools;
    }
    id;
    name;
    schools;
    mapFields(source) {
        return super.mapFields(source);
    }
    getFields() {
        // response objects include id field in mapping
        return ['id', 'name'];
    }
    getTransforms() {
        return [
            {
                destination: 'schools',
                transform: transform_config_1.TransformConfig.transformArray('schools', (source) => {
                    return source.map((school) => {
                        return {
                            id: school.id,
                            name: school.name
                        };
                    });
                })
            }
        ];
    }
}
exports.SchoolGroupResponseDto = SchoolGroupResponseDto;
