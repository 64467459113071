import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { AppMultipleSelectModule } from '../../shared/dropdowns/multi-select/multiple-select.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { RouterLink } from '@angular/router';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AppCommonTableComponent } from './common-table/common-table.component';
import { AppErrorDisplayComponent } from '../validation/error-display.component';
import { BaseCommonTablePortalComponent } from './common-table/base-common-table.portal-component';

@NgModule({
  declarations: [
    AppCommonTableComponent,
    BaseCommonTablePortalComponent
  ],
  exports: [AppCommonTableComponent, BaseCommonTablePortalComponent],
  imports: [
    AppMaterialModule,
    AppMultipleSelectModule,
    FontAwesomeModule,
    RouterLink,
    AppPaginationModule,
    AppErrorDisplayComponent,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: []
})
export class AppTablesModule {}
