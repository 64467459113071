import { NgModule } from '@angular/core';
import { AppComplianceListComponent } from './compliance-list/compliance-list.component';
import { AppComplianceListTableComponent } from './compliance-list/compliance-list-table/compliance-list-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppMaterialModule } from '../../app-material.module';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppComplianceListFiltersComponent } from './compliance-list/compliance-list-filter/compliance-list-filter.component';
import { AppComplianceByScheduledReactionComponent } from './compliance-by-scheduled-reaction/compliance-by-scheduled-reaction.component';
import { AppReportsModule } from '../reports/reports.module';

@NgModule({
  declarations: [
    AppComplianceListComponent,
    AppComplianceListFiltersComponent,
    AppComplianceByScheduledReactionComponent,
    AppComplianceListTableComponent
  ],
  exports: [],
  imports: [
    MatTableModule,
    MatSortModule,
    FontAwesomeModule,
    AppMaterialModule,
    DirectiveModule,
    AppPaginationModule,
    AppReportsModule
  ]
})
export class AppComplianceModule {}
