import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  DaysOfWeekEnum,
  IDisplayData,
  ReactionCreateDto,
  ReactionEditDaysDto,
  ReactionResponseDto,
  ReactionUpdateDto,
  ScheduledReactionResponseDto
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { plainToInstance } from 'class-transformer';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { logger } from '../../../shared/logger';
import { AppReactionsService } from '../reactions.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { MatDialog } from '@angular/material/dialog';
import { AppReactionsEditDaysComponent } from '../reactions-edit-days/reaction-edit-days.component';

@Component({
  selector: 'app-reaction-edit',
  templateUrl: './reactions-edit.template.html',
  styleUrls: ['./reactions-edit.scss']
})
export class AppReactionsEditComponent
  extends BaseComponent
  implements AfterViewInit
{
  public reaction: ReactionUpdateDto;
  public scheduledReactions: ScheduledReactionResponseDto[];
  public scheduleReactionsTableFilters: ITableFilters;
  public reactionForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public daysOptions: IDisplayData[];
  public reactionId: number;
  public behaviorCodeId: number;
  public pageHeaderSubscription: Subscription;
  public dailyCapacityRequired: boolean;
  public scheduledDays: DaysOfWeekEnum[];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public reactionsService: AppReactionsService,
    private pageHeaderService: AppPageHeaderService,
    private dialog: MatDialog
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const reaction = this.route.snapshot.data.data
      .reaction as ReactionResponseDto;
    this.scheduledReactions = this.route.snapshot.data.data.scheduledReactions;
    this.reactionId = +this.route.snapshot.params.reactionId;
    this.behaviorCodeId = +this.route.snapshot.params.id;
    this.scheduledDays = reaction.scheduledDays;

    this.dailyCapacityRequired = !!reaction.dailyCapacity;

    this.reaction = plainToInstance(ReactionUpdateDto, reaction);
    this.reactionForm = dtoToFormGroup(this.reaction, this.formBuilder);
    this.reactionForm.addControl(
      'dailyCapacityRequired',
      this.formBuilder.control(!!reaction.dailyCapacity)
    );
    this.setDaysOptions();
    this.isLoading = false;
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.reactionForm.valueChanges.subscribe(async (value) => {
        this.reaction = await validateAndGetValue<ReactionCreateDto>(
          this.reactionForm,
          ReactionCreateDto
        );
      })
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public async saveChanges(): Promise<void> {
    if (formCanSave(this.reactionForm, this.toastManager)) {
      try {
        if (
          this.reaction.dailyCapacity === 0 ||
          this.dailyCapacityRequired === false
        ) {
          this.reaction.removeDailyCapacity = true;
        }

        const response = await this.reactionsService.updateReaction(
          this.reaction,
          this.reactionId
        );

        this.reaction = new ReactionUpdateDto();
        this.reaction.mapFields(response);

        this.toastManager.success('Reaction saved successfully!');
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving Reaction, please try again!'
        );
      }
    }
  }

  public async updateScheduledReactionTableFilters(
    tableFilters: ITableFilters
  ) {
    this.isLoading = true;
    try {
      this.scheduleReactionsTableFilters = tableFilters;
      this.scheduledReactions =
        await this.reactionsService.getScheduledReactions(
          this.reactionId,
          tableFilters
        );
    } catch (err) {
      logger.error(err);
      this.toastManager.error('Unable to get reactions, please try again!');
    }
    this.isLoading = false;
  }

  public setDaysOptions(): void {
    this.daysOptions = [
      { display: 'Sunday', value: 1 },
      { display: 'Monday', value: 2 },
      { display: 'Tuesday', value: 3 },
      { display: 'Wednesday', value: 4 },
      { display: 'Thursday', value: 5 },
      { display: 'Friday', value: 6 },
      { display: 'Saturday', value: 7 }
    ];
  }

  public toggleDailyCapacityRequired(): void {
    this.dailyCapacityRequired = !this.dailyCapacityRequired;
    this.reactionForm.markAsTouched();
  }

  public async openEditDaysDialog(): Promise<void> {
    // scheduledDays comes in as a string array for some reason but we need
    // it as an enum array of DaysOfWeekEnum
    const days = this.scheduledDays.map((day) => +day);
    // open dialog for editing days
    this.dialog.open(AppReactionsEditDaysComponent, {
      width: '500px',
      data: {
        days: days,
        onClose: async (daysDto: ReactionEditDaysDto) => {
          const response = await this.reactionsService.editDays(
            this.reactionId,
            daysDto
          );
          this.scheduledDays = response.scheduledDays;
          this.reaction.mapFields(response);
        }
      }
    });
  }
}
