<div
  [ngClass]="pageHeaderClass"
  [class.hide-page-header]="showPageHeader()"
  [class.non-scrollable-style]="useNonScrollablePageHeaderStyle">
  <div class="title-button-container">
    <div class="page-title-container">
      <!-- Breadcrumbs / Title Container -->
      <div class="page-header-top">
        <!--// Active Page Title //-->
        <div *ngIf="title || subtitle">
          <h2 class="page-title" *ngIf="!!title">{{title}}</h2>
          <h2 class="page-title" *ngIf="!!subtitle">{{subtitle}}</h2>
        </div>
        <!--// Load the breadcrumbs in here //-->
        <div
          *ngIf="breadcrumbs"
          class="app-breadcrumbs-container align-self-end">
          <span *ngFor="let crumb of breadcrumbs; let i = index">
            <a
              class="breadcrumb-item text-secondary"
              [routerLink]="crumb.normalizedPath"
              [queryParamsHandling]="queryParamsHandling">
              <h2>{{crumb.title}}</h2>
            </a>
            <h2 *ngIf="i < breadcrumbs.length - 1" class="breadcrumb-separator">
              /
            </h2>
          </span>
        </div>
        <fa-icon
          class="page-header-icon"
          *ngIf="icon"
          [icon]="['fas', icon]"></fa-icon>
      </div>
    </div>
    <div *ngIf="buttons?.length" class="page-buttons-container">
      <app-page-header-button
        *ngFor="let button of buttons"
        [button]="button"
        (emitHeaderEvent)="emitHeaderEvent($event)"></app-page-header-button>

      <!-- Comments Button
    <app-comments-button
      *ngIf="commentsButtonConfig"
      [comments]="commentsButtonConfig.comments"
      [objectTargets]="commentsButtonConfig.objectTargets"
      [onObject]="commentsButtonConfig.onObject"
      [objectType]="commentsButtonConfig.objectType"
      [buttonClasses]="commentsButtonConfig.buttonClasses"
      [smallerButton]="commentsButtonConfig.smallerButton"
    ></app-comments-button> I don't think this component exists in Thor yet? -->
    </div>
  </div>
  <div class="page-description-container">
    <p *ngIf="description" class="page-description">{{description}}</p>
  </div>
</div>
