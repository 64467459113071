"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolCreateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const boolean_transform_1 = require("../../constants/boolean.transform");
const school_settings_dto_1 = require("./school-settings.dto");
const transform_config_1 = require("../../transform-config");
const nested_dto_config_1 = require("../../nested-dto-config");
class SchoolCreateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'name',
            'address',
            'city',
            'state',
            'country',
            'zip',
            'telephone',
            'fax',
            'principalName',
            'attendanceAdminName',
            'systemAdminName',
            'schoolType',
            'enablePushNotifications',
            'currentTerm',
            'currentAcademicYear',
            'schoolType',
            'referenceId'
        ];
    }
    name;
    address;
    city;
    state;
    country;
    zip;
    telephone;
    fax;
    principalName;
    attendanceAdminName;
    systemAdminName;
    schoolType;
    enablePushNotifications;
    currentTerm;
    currentAcademicYear;
    referenceId;
    settings;
    getNested() {
        return [nested_dto_config_1.NestedDtoConfig.relationConfig('settings', 'settings')];
    }
    getTransforms() {
        return [
            {
                destination: 'settings',
                transform: transform_config_1.TransformConfig.transformArray('settings', (source) => {
                    const settings = source.reduce((acc, setting) => {
                        acc[setting.setting] = setting.value;
                        return acc;
                    }, {});
                    return new school_settings_dto_1.SchoolSettings(settings);
                })
            }
        ];
    }
}
exports.SchoolCreateDto = SchoolCreateDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'School name is required' }),
    (0, class_validator_1.MaxLength)(100, { message: 'School name too long' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100, { message: 'Address too long' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "address", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(100, { message: 'City name too long' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "city", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(24, { message: 'State name too long' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "state", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(36, { message: 'Country name too long' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "country", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPostalCode)('US', { message: 'Zip code must be valid for the US' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "zip", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPhoneNumber)('US', { message: 'Phone number must be valid US format' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "telephone", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.ValidateIf)((schoolDetails) => schoolDetails.fax !== ''),
    (0, class_validator_1.IsPhoneNumber)('US', { message: 'Fax number must be valid US format' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "fax", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(50, { message: 'Principal name too long' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "principalName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.MaxLength)(50, { message: 'Attendance admin name too long' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "attendanceAdminName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50, { message: 'System admin name too long' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "systemAdminName", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], SchoolCreateDto.prototype, "schoolType", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], SchoolCreateDto.prototype, "enablePushNotifications", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MaxLength)(500, { message: 'Active term too long' }),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "currentTerm", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.MaxLength)(5, { message: 'Current academic year too long' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "currentAcademicYear", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'Reference Id is required' }),
    (0, class_validator_1.MaxLength)(50, { message: 'Reference Id too long' }),
    __metadata("design:type", String)
], SchoolCreateDto.prototype, "referenceId", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    (0, class_transformer_1.Type)(() => school_settings_dto_1.SchoolSettings),
    __metadata("design:type", school_settings_dto_1.SchoolSettings)
], SchoolCreateDto.prototype, "settings", void 0);
