import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppSaveBarModule } from 'src/app/shared/save-bar/save-bar.module';
import { AppSchoolCreateEditComponent } from './school-create-edit/school-create-edit.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NullIfBlankDirective } from '../../shared/directives/null-value.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { ResponsiveGridColumnsDirective } from '../../shared/responsive-grid-columns';
import { RouterModule } from '@angular/router';
import { AppSchoolsListFilters } from './schools-list/schools-list-filters/schools-list-filters.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppSchoolsListTableComponent } from './schools-list/schools-list-table/schools-list-table.component';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppSchoolsListComponent } from './schools-list/schools-list.component';
import { AppMySettingsModule } from '../my-settings/my-settings.module';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppSubscriptionCreateEditComponent } from './school-create-edit/subscription-create-edit/subscription-create-edit.component';
import { AppDeactivateRecordsModalComponent } from './school-create-edit/deactivate-records-modal/deactivate-records-modal.component';

@NgModule({
  declarations: [
    AppSchoolCreateEditComponent,
    AppSchoolsListFilters,
    AppSchoolsListTableComponent,
    AppSchoolsListComponent,
    AppSubscriptionCreateEditComponent,
    AppDeactivateRecordsModalComponent
  ],
  exports: [AppSchoolCreateEditComponent],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppSaveBarModule,
    CommonModule,
    NullIfBlankDirective,
    ReactiveFormsModule,
    ResponsiveGridColumnsDirective,
    RouterModule,
    FontAwesomeModule,
    AppPaginationModule,
    AppMySettingsModule,
    DirectiveModule
  ]
})
export class AppSchoolsModule {}
