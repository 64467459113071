<div class="mat-dialog-header">
  <div class="mat-dialog-title">Duplicate Reaction</div>
  <!-- <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon> -->
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <ng-container>
    <form [formGroup]="reactionForm" novalidate>
      <mat-form-field class="full-width-input">
        <mat-label>Reaction Name</mat-label>
        <input formControlName="name" matInput />
        <app-error-display
          [form]="reactionForm"
          controlName="name"></app-error-display>
      </mat-form-field>
    </form>
    <div class="modal-action-row">
      <button mat-button (click)="dialogRef.close()">Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="handleDuplicate()">
        Duplicate
      </button>
    </div>
  </ng-container>
</div>
