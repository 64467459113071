<div class="student-list-filters-container">
  <div class="students-list-filters-content">
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>First Name</mat-label>
      <input
        data-test-id="FIRST_NAME_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.firstName"
        (ngModelChange)="filtersUpdated.emit(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>Last Name</mat-label>
      <input
        data-test-id="LAST_NAME_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.lastName"
        (ngModelChange)="filtersUpdated.emit(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>Grade</mat-label>
      <input
        data-test-id="GRADE_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.grade"
        (ngModelChange)="filtersUpdated.emit(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>ID Number</mat-label>
      <input
        data-test-id="STUDENT_EXT_ID_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.studentExtId"
        (ngModelChange)="filtersUpdated.emit(filters)" />
    </mat-form-field>
  </div>
  <ng-container *ngIf="selectedStudentIds.length !== 0">
    <button
      *ngIf="hasPrivilege(PrivilegeEnum.TRACK_BEHAVIOR)"
      type="button"
      mat-flat-button
      class="secondary-button header-action-button standard-button"
      (click)="showTrackModalEvent.emit()"
      unique-id>
      Track Students {{selectedStudentIds.length}}
    </button>
    <button
      *ngIf="hasPrivilege(PrivilegeEnum.TRACK_BEHAVIOR)"
      type="button"
      mat-flat-button
      class="secondary-button header-action-button standard-button margin-left"
      (click)="showFastTrackModalEvent.emit()"
      unique-id>
      Fast Track
    </button>
  </ng-container>
</div>
