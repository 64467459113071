import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppResolverErrorHandlerService } from '../../shared/services/resolver-error-handler.service';
import { AppSchoolGroupsService } from './school-groups.service';
import { BaseResolver } from '../../shared/base-classes/base.resolver';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { SchoolGroupResponseDto } from '@whetstoneeducation/hero-common';

@Injectable({ providedIn: 'root' })
export class AppSchoolGroupResolver extends BaseResolver<SchoolGroupResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public schoolGroupsService: AppSchoolGroupsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SchoolGroupResponseDto> {
    const id = route.paramMap.get('id');
    if (id == 'new') {
      return plainToInstance(
        SchoolGroupResponseDto,
        {
          name: '',
        },
        {
          exposeUnsetFields: true,
        }
      );
    } else {
      const schoolGroup = await this.schoolGroupsService.getSchoolGroupById(
        parseInt(id)
      );
      return schoolGroup;
    }
  }
}
