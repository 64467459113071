"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportSettingsFilterDto = void 0;
const shared_1 = require("../shared");
const enums_1 = require("../../enums");
const class_validator_1 = require("class-validator");
const boolean_transform_1 = require("../../constants/boolean.transform");
const class_transformer_1 = require("class-transformer");
class ExportSettingsFilterDto extends shared_1.PaginationOptionsDto {
    constructor(exportSettingsFilterOptions) {
        super();
        if (exportSettingsFilterOptions && exportSettingsFilterOptions.schoolId)
            this.schoolId = exportSettingsFilterOptions.schoolId;
        if (exportSettingsFilterOptions &&
            exportSettingsFilterOptions.schoolGroupId)
            this.schoolGroupId = exportSettingsFilterOptions.schoolGroupId;
        if (exportSettingsFilterOptions && exportSettingsFilterOptions.groupLevel)
            this.groupLevel = exportSettingsFilterOptions.groupLevel;
        if (exportSettingsFilterOptions &&
            exportSettingsFilterOptions.sftpAccountId)
            this.sftpAccountId = exportSettingsFilterOptions.sftpAccountId;
    }
    schoolId;
    schoolGroupId;
    sftpAccountId;
    importSubject;
    groupLevel;
}
exports.ExportSettingsFilterDto = ExportSettingsFilterDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], ExportSettingsFilterDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], ExportSettingsFilterDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], ExportSettingsFilterDto.prototype, "sftpAccountId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.ImportSubject),
    __metadata("design:type", String)
], ExportSettingsFilterDto.prototype, "importSubject", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], ExportSettingsFilterDto.prototype, "groupLevel", void 0);
