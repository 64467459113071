<!-- Container -->
<div class="link-dropdown">
  <!-- Link -->
  <a
    *ngIf="!justShowContent"
    [routerLink]="null"
    queryParamsHandling="merge"
    (click)="isOpen = !isOpen"
    class="clickable">
    {{ text }}
  </a>

  <!-- Content Dropdown -->
  <div *ngIf="isOpen || justShowContent" class="content-dropdown">
    <ng-content></ng-content>
  </div>
</div>
