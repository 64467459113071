import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  BehaviorCodeNotificationCreateDto,
  BehaviorCodeNotificationResponseDto,
  BehaviorCodeNotificationUpdateDto,
  NotificationTemplateFilterDto,
  NotificationType,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodeNotificationsFilters } from './behavior-code-notifications-models/behavior-code-notifications-filters.interface';
import { AppNotificationService } from '../notifications/notification.service';
import { plainToInstance } from 'class-transformer';
import { IBehaviorCodeNotificationsCreateEditResolverData } from './behavior-code-notifications-models/behavior-code-notifications-create-edit-resolver-data.interface';
import { INotificationTemplateFilters } from '../notifications/template-list/template-list-models/notification-template-list-filters.interface';
import { ITableFilters } from '../../shared/tables/table-filters/table-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppBehaviorCodeNotificationsService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private notificationsService: AppNotificationService
  ) {
    super();
  }

  public async getBehaviorCodeNotificationsList(
    filters: IBehaviorCodeNotificationsFilters
  ): Promise<PaginationResultsDto<BehaviorCodeNotificationResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<BehaviorCodeNotificationResponseDto>
    >(this.GET_ROUTES.BEHAVIOR_CODE_NOTIFICATIONS_LIST, {
      queryParams: {
        ...(filters.searchInput ? { description: filters.searchInput } : {}),
        ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
        ...(filters.notificationId
          ? { notificationId: filters.notificationId }
          : {}),
        ...(filters.behaviorCodeId
          ? { behaviorCodeId: filters.behaviorCodeId }
          : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {})
      }
    });
  }

  public async getBehaviorCodeNotificationsById(
    id: number
  ): Promise<BehaviorCodeNotificationResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeNotificationResponseDto>(
      this.GET_ROUTES.BEHAVIOR_CODE_NOTIFICATIONS_BY_ID,
      {
        pathParams: { id }
      }
    );
  }

  public async getBehaviorCodeNotificationCreateEditResolverData(
    behaviorCodeId: string,
    behaviorCodeNotificationId: string
  ): Promise<IBehaviorCodeNotificationsCreateEditResolverData> {
    const user = this.StorageManager.getLoggedInUser();
    // need to add schoolId and isEnabled
    const filters = {
      schoolId: user.currentSchoolId,
      schoolGroupId: user.schoolGroupId,
      isEnabled: true,
      notificationType: NotificationType.BEHAVIOR_TRACKING,
      tableFilters: { itemsPerPage: 100 } as ITableFilters
    } as INotificationTemplateFilters;
    const notificationsCall =
      this.notificationsService.getTemplateList(filters);

    const behaviorCodeNotificationCall =
      behaviorCodeNotificationId !== 'new'
        ? this.getBehaviorCodeNotificationsById(+behaviorCodeNotificationId)
        : null;

    const notifications = await notificationsCall;
    const behaviorCodeNotification =
      behaviorCodeNotificationId === 'new'
        ? plainToInstance(BehaviorCodeNotificationResponseDto, {
            description: '',
            triggerCount: 0,
            behaviorCodeId: +behaviorCodeId,
            notificationId: null,
            schoolId: user.currentSchoolId
          })
        : await behaviorCodeNotificationCall;

    const notificationOptions = notifications.results.map((notification) => {
      return { display: notification.name, value: notification.id };
    });

    return {
      notificationOptions,
      behaviorCodeNotification
    };
  }

  public async createBehaviorCodeNotification(
    behaviorCodeNotification: BehaviorCodeNotificationCreateDto
  ): Promise<BehaviorCodeNotificationResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeNotificationResponseDto>(
      this.POST_ROUTES.CREATE_BEHAVIOR_CODE_NOTIFICATION,
      {
        method: this.METHOD.POST,
        body: behaviorCodeNotification
      }
    );
  }

  public async updateBehaviorCodeNotification(
    behaviorCodeNotification: BehaviorCodeNotificationUpdateDto,
    id: number
  ): Promise<BehaviorCodeNotificationResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeNotificationResponseDto>(
      this.PUT_ROUTES.UPDATE_BEHAVIOR_CODE_NOTIFICATION,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: behaviorCodeNotification
      }
    );
  }

  public async removeBehaviorNotification(
    id: number
  ): Promise<BehaviorCodeNotificationResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeNotificationResponseDto>(
      this.DELETE_ROUTES.DELETE_BEHAVIOR_CODE_NOTIFICATION,
      {
        method: this.METHOD.DELETE,
        pathParams: { id }
      }
    );
  }
}
