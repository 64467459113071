<div class="container">
  <app-schoolwide-report-filters
    [loadingItems]="isLoading"
    [filters]="filters"
    (filtersUpdated)="onFiltersUpdated($event)"
    (applyFilters)="applyFilters()">
  </app-schoolwide-report-filters>
  <app-schoolwide-report-actions
    (filtersUpdated)="onInstantFiltersUpdated($event)"
    [filters]="filters">
  </app-schoolwide-report-actions>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <app-base-common-table-portal [context]="this"></app-base-common-table-portal>

  <span *ngIf="!(dataArr.length || isLoading)">
    There are no records to show
  </span>
</div>

<ng-template #tooltipHeader let-message="message" let-title="title">
  <div [matTooltip]="message" matTooltipPosition="above" class="header-tooltip">
    <span>{{title}}</span>
    <fa-icon [icon]="['fas', 'circle-info']"></fa-icon>
  </div>
</ng-template>

<ng-template #pointsColumn let-value="value">
  <b class="points">{{value}}</b>
</ng-template>
