<div class="behavior-codes-list-content">
  <div class="no-school-or-school-group" *ngIf="!hasSelectedSchool">
    The Behavior Codes configuration page can only be accessed for an individual
    school. Please switch to one of those and try again!
  </div>
  <div class="content" *ngIf="hasSelectedSchool">
    <app-compliance-list-filters
      [filters]="filters"
      [startDate]="startDate"
      [endDate]="startDate"
      [reactionOptions]="dropdownData.reactions"
      (filtersUpdated)="updateFilters($event)"></app-compliance-list-filters>
    <app-compliance-list-table
      [dataArr]="reactionEntries"
      [reloadTable]="reloadTable.bind(this)"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-compliance-list-table>
  </div>
</div>
