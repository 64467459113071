"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportSettingsBasicResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const nested_dto_config_1 = require("../../nested-dto-config");
class ExportSettingsBasicResponseDto extends mappable_base_1.MappableBase {
    getFields() {
        return ['id', 'accountId', 'schoolGroupId', 'schoolId'];
    }
    mapFields(source) {
        super.mapFields(source);
        return this;
    }
    getNested() {
        return [
            nested_dto_config_1.NestedDtoConfig.relationConfig('schoolGroup', 'schoolGroup'),
            nested_dto_config_1.NestedDtoConfig.relationConfig('schools', 'schools'),
            nested_dto_config_1.NestedDtoConfig.relationConfig('behaviorCodes', 'behaviorCodes')
        ];
    }
    id;
    schoolId;
    schoolGroupId;
    schools;
    behaviorCodes;
    schoolGroup;
}
exports.ExportSettingsBasicResponseDto = ExportSettingsBasicResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ExportSettingsBasicResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ExportSettingsBasicResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], ExportSettingsBasicResponseDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], ExportSettingsBasicResponseDto.prototype, "schools", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], ExportSettingsBasicResponseDto.prototype, "behaviorCodes", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], ExportSettingsBasicResponseDto.prototype, "schoolGroup", void 0);
