import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppGuardiansService } from '../../guardians/guardians.service';
import { IStudentWithPoints } from '@whetstoneeducation/hero-common';

const codeTypeColors = {
  blue: '#2c89bf',
  red: '#ec5a5a',
  gray: '#8a96a0',
}

const codeTypeColorMapper = {
  positive: 'blue',
  negative: 'red',
  neutral: 'gray',
  tardy: 'red',
}

@Component({
  selector: 'app-guardian-dashboard',
  templateUrl: './guardian-dashboard.component.html',
  styleUrls: ['./guardian-dashboard.component.scss']
})
export class AppGuardianDashboardComponent
  extends BaseComponent
  implements OnInit
{

  public students: IStudentWithPoints[] = [];
  public behaviorCodes: string[] = [
    'positive',
    'negative',
    'tardy',
    'neutral',
  ]


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private guardiansService: AppGuardiansService
  ) {
    super();
  }

  async ngOnInit() {
    await this.loadData();
  }

  public async loadData() {
    const { noCalendar } = this.route.snapshot.data.data;
    if (noCalendar) {
      await this.router.navigate(['/contact-support']);
    }
    this.students = await this.guardiansService.getGuardianStudentsProfileInfo(this.StorageManager.getLoggedInUserId());
  }

  public formatLastHistoryElement(code: string, isLast: boolean): string {
    return `${isLast ? 'and' : ''} ${code}${isLast ? ' ' : ','}`
   }

   public getCodeTypeColor(name: string) {
    return codeTypeColorMapper[name] ? codeTypeColors[codeTypeColorMapper[name]] : '';
   }

   public redirectToStudentProfile(studentId: number) {
    this.router.navigate([`/students/${studentId}/profile`]);
   }
}
