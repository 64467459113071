<div class="sftp-import-list-filters-content">
  <mat-form-field class="filter-item search-input">
    <mat-label>Import Status</mat-label>
    <mat-select
      [(value)]="filters.importStatus"
      (selectionChange)="filters.importStatus = $event.value; filtersUpdated.emit(filters)">
      <!-- use option values from importStatus array,
      also use all option with empty value, all should be the default selected value -->
      <mat-option *ngFor="let status of importStatuses" [value]="status">
        {{ status }}
      </mat-option>
      <mat-option [value]="">All</mat-option>
    </mat-select>
  </mat-form-field>
</div>
