import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  BellScheduleCreateDto,
  BellSchedulePeriodCreateDto,
  BellSchedulePeriodResponseDto,
  BellSchedulePeriodTimeResponseDto,
  BellSchedulePeriodTimeUpdateDto,
  BellSchedulePeriodUpdateDto,
  BellScheduleResponseDto,
  BellScheduleUpdateDto,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../shared/tables/table-filters/table-filters.interface';

export interface IBellSchedulePeriodManagerResolverData {
  periods: BellSchedulePeriodResponseDto[];
}

@Injectable({
  providedIn: 'root'
})
export class AppBellSchedulesService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async getBellScheduleById(
    id: number
  ): Promise<BellScheduleResponseDto> {
    return this.appClientDataService.execute<BellScheduleResponseDto>(
      this.GET_ROUTES.BELL_SCHEDULE_BY_ID,
      { pathParams: { id } }
    );
  }

  public async createBellSchedule(
    bellSchedule: BellScheduleCreateDto
  ): Promise<BellScheduleResponseDto> {
    return this.appClientDataService.execute<BellScheduleResponseDto>(
      this.POST_ROUTES.CREATE_BELL_SCHEDULE,
      {
        method: this.METHOD.POST,
        body: bellSchedule
      }
    );
  }

  public async createBellSchedulePeriodTime(
    periodTime: BellSchedulePeriodCreateDto
  ): Promise<BellSchedulePeriodTimeResponseDto> {
    return this.appClientDataService.execute<BellSchedulePeriodTimeResponseDto>(
      this.POST_ROUTES.CREATE_BELL_SCHEDULE_PERIOD_TIME,
      {
        method: this.METHOD.POST,
        body: periodTime
      },
      BellSchedulePeriodTimeResponseDto
    );
  }

  public async deleteBellSchedulePeriodTime(id: number) {
    return this.appClientDataService.execute(
      this.DELETE_ROUTES.DELETE_BELL_SCHEDULE_PERIOD_TIME,
      {
        method: this.METHOD.DELETE,
        pathParams: { id }
      }
    );
  }

  public async updateBellSchedulePeriodTime(
    id: number,
    periodTime: BellSchedulePeriodTimeUpdateDto
  ): Promise<BellSchedulePeriodTimeResponseDto> {
    return this.appClientDataService.execute<BellSchedulePeriodTimeResponseDto>(
      this.PATCH_ROUTES.UPDATE_BELL_SCHEDULE_PERIOD_TIME,
      {
        method: this.METHOD.PATCH,
        pathParams: { id },
        body: periodTime
      },
      BellSchedulePeriodTimeResponseDto
    );
  }

  public async updateBellSchedule(
    id: number,
    bellSchedule: BellScheduleUpdateDto
  ): Promise<BellScheduleResponseDto> {
    return this.appClientDataService.execute<BellScheduleResponseDto>(
      this.PATCH_ROUTES.EDIT_BELL_SCHEDULE,
      {
        method: this.METHOD.PATCH,
        pathParams: { id },
        body: bellSchedule
      }
    );
  }

  public async getPeriodById(
    id: number
  ): Promise<BellSchedulePeriodResponseDto> {
    return this.appClientDataService.execute<BellSchedulePeriodResponseDto>(
      this.GET_ROUTES.BELL_SCHEDULE_PERIOD_BY_ID,
      { pathParams: { id } }
    );
  }

  public async createPeriod(
    period: BellSchedulePeriodCreateDto
  ): Promise<BellSchedulePeriodResponseDto> {
    return this.appClientDataService.execute<BellSchedulePeriodResponseDto>(
      this.POST_ROUTES.CREATE_BELL_SCHEDULE_PERIOD,
      {
        method: this.METHOD.POST,
        body: period
      }
    );
  }

  public async updatePeriod(
    id: number,
    period: BellSchedulePeriodUpdateDto
  ): Promise<BellSchedulePeriodResponseDto> {
    return this.appClientDataService.execute<BellSchedulePeriodResponseDto>(
      this.PUT_ROUTES.UPDATE_BELL_SCHEDULE_PERIOD,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: period
      }
    );
  }

  public async deletePeriod(id: number) {
    return this.appClientDataService.execute(
      this.DELETE_ROUTES.DELETE_BELL_SCHEDULE_PERIOD,
      {
        method: this.METHOD.DELETE,
        pathParams: { id }
      }
    );
  }

  public async cleanUpPeriods(schoolId: number) {
    return this.appClientDataService.execute<number[]>(
      this.DELETE_ROUTES.CLEAN_UP_BELL_SCHEDULE_PERIODS,
      {
        method: this.METHOD.DELETE,
        pathParams: { schoolId }
      }
    );
  }

  public async getBellSchedulePeriodManagerResolverData(): Promise<IBellSchedulePeriodManagerResolverData> {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    // get all bell schedule periods for this school
    const periods = await this.getPeriodsForSchool(schoolId);
    return { periods };
  }

  public async getPeriodsForSchool(
    schoolId: number
  ): Promise<BellSchedulePeriodResponseDto[]> {
    return this.appClientDataService.execute<BellSchedulePeriodResponseDto[]>(
      this.GET_ROUTES.BELL_SCHEDULE_PERIODS_LIST,
      { pathParams: { schoolId } }
    );
  }

  public async getBellSchedulesList(
    schoolId: number,
    tableFilters: ITableFilters,
    searchInput?: string
  ): Promise<PaginationResultsDto<BellScheduleResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<BellScheduleResponseDto>
    >(this.GET_ROUTES.BELL_SCHEDULES_LIST, {
      queryParams: {
        schoolId,
        ...(searchInput ? { name: searchInput } : {}),
        ...(tableFilters.itemsPerPage
          ? { itemsPerPage: tableFilters.itemsPerPage }
          : {}),
        ...(tableFilters.page ? { page: tableFilters.page } : {})
      }
    });
  }
}
