import { AppPrivilegesService } from '../auth/privileges.service';
import { AppSettingsService } from '../../shared/services/settings.service';
import { HomePageEnum, IDisplayData } from '@whetstoneeducation/hero-common';
import { IHomePageConfig } from './home-page-config.interface';

const configList: IHomePageConfig[] = [
  {
    key: HomePageEnum.DEFAULT,
    name: 'Home',
    path: ['/'],
  },
];

/**
 * Helper class for determining which home page a user should be loading.
 */
export class HomePageConfig {
  /**
   * Default route is no systemSetting home page found (and users is set to default)
   */
  public static defaultRoute = ['/me'];

  /**
   * Get the list of home page options we can choose from in IDisplayData format
   */
  public static getDisplayList(
    settingsService: AppSettingsService,
    privilegesService: AppPrivilegesService
  ): IDisplayData[] {
    const settings = settingsService.getSettings();

    return (
      configList
        // Only include enabled pages
        .filter((config) =>
          config.enabledFunc ? config.enabledFunc(settings) : true
        )
        // Only include pages we have access to see
        .filter((config) =>
          config.privilege ? privilegesService.hasPrivilege : true
        )
        // Convert to IDisplayData with correct translations
        .map((config) => ({
          display: config.name,
          value: config.key,
        }))
    );
  }

  /**
   * Gets the path that we should redirect to based on the pageId.
   * @param userPageKey Our home page's key
   * @param networkDefaultKey The networks' default home page key
   */
  public static getPath(
    userPageKey: HomePageEnum,
    networkDefaultKey
  ): string[] {
    // If we our page key is set to network default OR we don't have one. Use network default
    if (userPageKey === HomePageEnum.DEFAULT || !userPageKey) {
      userPageKey = networkDefaultKey;
    }

    // Find the config for the home page we want to go to
    const foundConfig = configList.find((config) => config.key === userPageKey);
    // If we can't find a valid config, use /me
    return foundConfig?.path || HomePageConfig.defaultRoute;
  }
}
