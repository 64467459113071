import {
  AfterViewInit,
  Directive,
  ElementRef,
  Host,
  Optional,
  Renderer2,
  Self
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../../environments/environment';

@Directive({
  selector: '[appCustomPagination]',
  standalone: true
})
export class CustomPaginationDirective implements AfterViewInit {
  constructor(
    @Host() @Self() @Optional() private readonly matPaginator: MatPaginator,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.customizePagination();
  }

  private customizePagination(): void {
    const nativeElement = this.elementRef.nativeElement;
    const firstButton = nativeElement.querySelector(
      '.mat-mdc-paginator-navigation-first'
    );
    const previousButton = nativeElement.querySelector(
      '.mat-mdc-paginator-navigation-previous'
    );
    const nextButton = nativeElement.querySelector(
      '.mat-mdc-paginator-navigation-next'
    );
    const lastButton = nativeElement.querySelector(
      '.mat-mdc-paginator-navigation-last'
    );
    const buttons = nativeElement.querySelectorAll(
      '.mat-mdc-icon-button > svg'
    );

    if (environment.environmentName !== 'prod') {
      lastButton.setAttribute('data-test-id', `last_pagination_button`);
    }
  }
}
