import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DEFAULT_DIALOG_RESULT,
  IConfirmationDialogOptions
} from './confirmation-dialog-options.interface';
import { AppConfirmationDialogComponent } from './confirmation-dialog.component';

/**
 * Used as a standard way to create confirmation dialogs.
 */
@Injectable({
  providedIn: 'root'
})
export class AppConfirmationDialogService {
  /**
   * Default Constructor
   * @param dialog Reference to the angular dialog service.
   */
  constructor(public dialog: MatDialog) {}

  /**
   * Open the dialog with the given options.
   * @param options The options to pass into the dialog.
   * @returns MatDialogRef<AppConfirmationDialogComponent> The dialog reference.
   */
  public openDialog(
    options: IConfirmationDialogOptions
  ): MatDialogRef<AppConfirmationDialogComponent> {
    return this.dialog.open(AppConfirmationDialogComponent, {
      width: options.width || '410px',
      height: options.height || 'auto',
      panelClass: 'default-dialog',
      data: options,
      id: options.id,
      disableClose: options.disableClose
    });
  }

  public confirm(options: IConfirmationDialogOptions): Promise<string> {
    return new Promise((resolve) => {
      const ref = this.openDialog(options);
      ref.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }

  /**
   *
   */
  public async confirmSimple(title: string, content: string): Promise<boolean> {
    const result = await this.confirm({
      title,
      content,
      actions: [
        {
          key: 'No',
          label: 'No',
          classes: 'btn btn-danger',
          icon: 'times-circle'
        },
        {
          key: DEFAULT_DIALOG_RESULT.YES,
          label: 'Yes',
          classes: 'btn btn-success',
          icon: 'check-circle'
        }
      ]
    });
    return result === DEFAULT_DIALOG_RESULT.YES;
  }

  public confirmDeleteItem(itemName: string): Promise<boolean> {
    return new Promise((resolve) => {
      const ref = this.openDialog({
        title: 'Delete ' + itemName + '?',
        content:
          'Are you sure you want to archive this? You will not be able to retrieve it later!'
      });
      ref.afterClosed().subscribe((result: DEFAULT_DIALOG_RESULT) => {
        if (result === DEFAULT_DIALOG_RESULT.YES) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
}
