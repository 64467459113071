"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultOptionCategoriesIncidentDisplayValue = exports.DefaultOptionCategoriesIncidentEnum = void 0;
var DefaultOptionCategoriesIncidentEnum;
(function (DefaultOptionCategoriesIncidentEnum) {
    DefaultOptionCategoriesIncidentEnum["INCIDENT_TYPES"] = "incident_types";
    DefaultOptionCategoriesIncidentEnum["INCIDENT_PLACES"] = "incident_places";
    DefaultOptionCategoriesIncidentEnum["INCIDENT_ROLES_AND_REFERRALS"] = "incident_roles_and_referrals";
    DefaultOptionCategoriesIncidentEnum["STUDENT_WITH_SPECIAL_NEEDS"] = "student_with_special_needs";
    DefaultOptionCategoriesIncidentEnum["MOTIVATION"] = "motivation";
    DefaultOptionCategoriesIncidentEnum["OTHERS_INVOLVED"] = "others_involved";
    DefaultOptionCategoriesIncidentEnum["BULLYING"] = "bullying";
    DefaultOptionCategoriesIncidentEnum["BASIS_OF_BULLYING"] = "basis_of_bullying";
    DefaultOptionCategoriesIncidentEnum["PREVIOUS_ACTION_TAKEN"] = "previous_action_taken";
    DefaultOptionCategoriesIncidentEnum["HERO_DISPOSITION"] = "hero_disposition";
    DefaultOptionCategoriesIncidentEnum["LOCAL_DISPOSITION"] = "local_disposition";
    DefaultOptionCategoriesIncidentEnum["STATE_DISPOSITION"] = "state_disposition";
})(DefaultOptionCategoriesIncidentEnum || (exports.DefaultOptionCategoriesIncidentEnum = DefaultOptionCategoriesIncidentEnum = {}));
exports.DefaultOptionCategoriesIncidentDisplayValue = {
    [DefaultOptionCategoriesIncidentEnum.INCIDENT_TYPES]: 'Incident Types',
    [DefaultOptionCategoriesIncidentEnum.INCIDENT_PLACES]: 'Incident Places',
    [DefaultOptionCategoriesIncidentEnum.INCIDENT_ROLES_AND_REFERRALS]: 'Incident Roles and Referrals',
    [DefaultOptionCategoriesIncidentEnum.STUDENT_WITH_SPECIAL_NEEDS]: 'Student with Special Needs',
    [DefaultOptionCategoriesIncidentEnum.MOTIVATION]: 'Motivation',
    [DefaultOptionCategoriesIncidentEnum.OTHERS_INVOLVED]: 'Others Involved',
    [DefaultOptionCategoriesIncidentEnum.BULLYING]: 'Bullying',
    [DefaultOptionCategoriesIncidentEnum.BASIS_OF_BULLYING]: 'Basis of Bullying',
    [DefaultOptionCategoriesIncidentEnum.PREVIOUS_ACTION_TAKEN]: 'Previous Action Taken',
    [DefaultOptionCategoriesIncidentEnum.HERO_DISPOSITION]: 'Hero Disposition',
    [DefaultOptionCategoriesIncidentEnum.LOCAL_DISPOSITION]: 'Local Disposition',
    [DefaultOptionCategoriesIncidentEnum.STATE_DISPOSITION]: 'State Disposition'
};
