import { AppRoleCreateEditComponent } from './roles-create-edit/role-create-edit.component';
import { AppRoleResolver } from './roles-create-edit/role-create-edit.resolver';
import { ICustomRoute } from '../../app-routing.module';
import { AppRolesListComponent } from './roles-list/roles-list.component';
import { AppRolesListResolver } from './roles-list/roles-list.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { SchoolGroupGuard } from '../../route-guards/school-group.guard';

export const AppRolesRoutes: ICustomRoute[] = [
  {
    path: 'roles/:id',
    title: 'Role',
    component: AppRoleCreateEditComponent,
    resolve: { data: AppRoleResolver },
    canActivate: [SchoolGroupGuard],
    data: {
      title: 'Role',
      description: 'Create or edit a role',
      buttons: [
        {
          name: 'Cancel',
          action: HeaderButtonAction.CANCEL,
          style: 'cancel-button'
        },
        {
          name: 'Save',
          action: HeaderButtonAction.SAVE,
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'roles',
    title: 'Roles',
    component: AppRolesListComponent,
    canActivate: [SchoolGroupGuard],
    resolve: { data: AppRolesListResolver },
    data: {
      title: 'Roles',
      buttons: [
        {
          name: 'Role',
          normalizedRoute: '/roles/new',
          icon: 'plus',
          style: 'secondary-button'
        }
      ]
    }
  }
];
