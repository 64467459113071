import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IncidentOptionsResponseDto } from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppIncidentService } from '../incident.service';

@Injectable({ providedIn: 'root' })
export class AppIncidentConfigResolver extends BaseResolver<IncidentOptionsResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public incidentService: AppIncidentService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(route): Promise<IncidentOptionsResponseDto> {
    return this.incidentService.getIncidentConfig();
  }
}
