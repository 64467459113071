import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  IDisplayData,
  StateEnum,
  StudentCreateDto,
  StudentDetailResponseDto,
  StudentUpdateDto
} from '@whetstoneeducation/hero-common';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { AppStudentsService } from '../students.service';
import { logger } from '../../../shared/logger';
import { IStudentsCreateEditResolverData } from './students-create-edit-resolver-data.interface';
import { AppStudentGroupsService } from '../../student-groups/student-groups.service';

@Component({
  selector: 'app-student-create-edit',
  templateUrl: './students-create-edit.template.html',
  styleUrls: ['./students-create-edit.scss']
})
export class AppStudentCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public id: number;
  public isCreating: boolean;
  public student: StudentCreateDto | StudentUpdateDto;
  public studentForm: FormGroup;
  public bellScheduleOptions: IDisplayData[];
  public createUser: boolean = false;

  public subscriptions: Subscription[] = [];

  public infoExpanded: boolean = true;
  public addressExpanded: boolean = false;
  public guardianExpanded: boolean = false;
  public demographicExpanded: boolean = false;

  public stateOptions: IDisplayData[] = Object.values(StateEnum).map(
    (value) => ({
      value,
      display: value
    })
  );
  public schoolOptions: IDisplayData[];
  public studentGroupOptions: IDisplayData[];

  constructor(
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public router: Router,
    private pageHeaderService: AppPageHeaderService,
    private studentsService: AppStudentsService,
    private studentGroupsService: AppStudentGroupsService
  ) {
    super();
    this.handleRouteChanges();
  }

  public async ngAfterViewInit() {
    await this.getStudentGroupOptions(this.student.schoolId);
    this.subscriptions.push(
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      )
    );

    this.subscriptions.push(
      this.studentForm.valueChanges.subscribe(async () => {
        if (this.isCreating) {
          this.student = await validateAndGetValue<StudentCreateDto>(
            this.studentForm,
            StudentCreateDto
          );
        } else {
          this.student = await validateAndGetValue<StudentUpdateDto>(
            this.studentForm,
            StudentUpdateDto
          );
        }
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public async loadData(
    resolverData: IStudentsCreateEditResolverData
  ): Promise<void> {
    this.isLoading = true;
    this.id = +this.route.snapshot.params.id;
    this.schoolOptions = resolverData.schoolOptions;
    this.isCreating = !this.id;
    if (this.isCreating) {
      this.student = new StudentCreateDto().mapFields(resolverData.student);
    } else {
      this.student = new StudentUpdateDto().mapFields(resolverData.student);
    }
    this.studentForm = dtoToFormGroup(this.student, this.formBuilder, {
      mapId: !this.isCreating,
      disable: !this.isCreating ? ['schoolId'] : []
    });
    this.isLoading = false;
  }

  public async saveChanges(): Promise<void> {
    if (formCanSave(this.studentForm, this.toastManager)) {
      try {
        if (this.student instanceof StudentCreateDto) {
          const created = await this.studentsService.createStudent(
            this.student
          );
          this.student = new StudentUpdateDto().mapFields(created);
          this.id = created.id;
          this.isCreating = false;
          await this.router.navigate(['/students', this.id]);
          this.toastManager.success('Student created successfully!');
        } else {
          const updated = await this.studentsService.updateStudent(
            this.student,
            this.id
          );
          this.student = new StudentUpdateDto().mapFields(updated);
          this.toastManager.success('Student updated successfully!');
        }
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving Student, please try again!'
        );
      }
    }
  }

  public handleRouteChanges() {
    this.subscriptions.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          const resolverData: IStudentsCreateEditResolverData =
            this.route.snapshot.data.data;
          this.loadData(resolverData);
        })
    );
  }

  public async getStudentGroupOptions(schoolId: number): Promise<void> {
    let schoolStudentGroups = [];
    if (schoolId) {
      schoolStudentGroups =
        await this.studentGroupsService.getAllStudentGroups(schoolId);
    }
    this.studentGroupOptions = schoolStudentGroups.map((group) => {
      return { value: group.id, display: group.name };
    });
  }
}
