"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionUpdateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const is_after_decorator_1 = require("../shared/is-after.decorator");
const boolean_transform_1 = require("../../constants/boolean.transform");
class SubscriptionUpdateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return [
            'name',
            'userLimit',
            'studentLimit',
            'canCreateBehaviorCodes',
            'canCreateInterventions',
            'canImportUsers',
            'canImportStudents',
            'startDate',
            'endDate',
            'active',
            'referenceId'
        ];
    }
    name;
    userLimit;
    studentLimit;
    canCreateBehaviorCodes;
    canCreateInterventions;
    canImportUsers;
    canImportStudents;
    startDate;
    endDate;
    active;
    referenceId;
}
exports.SubscriptionUpdateDto = SubscriptionUpdateDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'Name is required' }),
    __metadata("design:type", String)
], SubscriptionUpdateDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.Min)(1, { message: 'User limit must be greater than 0' }),
    __metadata("design:type", Number)
], SubscriptionUpdateDto.prototype, "userLimit", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.Min)(1, { message: 'Student limit must be greater than 0' }),
    __metadata("design:type", Number)
], SubscriptionUpdateDto.prototype, "studentLimit", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SubscriptionUpdateDto.prototype, "canCreateBehaviorCodes", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SubscriptionUpdateDto.prototype, "canCreateInterventions", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SubscriptionUpdateDto.prototype, "canImportUsers", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SubscriptionUpdateDto.prototype, "canImportStudents", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Transform)((params) => new Date(params.value)),
    (0, class_validator_1.IsDate)({ message: 'Start date must be a valid date' }),
    __metadata("design:type", Date)
], SubscriptionUpdateDto.prototype, "startDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Transform)((params) => new Date(params.value)),
    (0, class_validator_1.IsDate)({ message: 'End date must be a valid date' }),
    (0, is_after_decorator_1.IsAfter)('startDate'),
    __metadata("design:type", Date)
], SubscriptionUpdateDto.prototype, "endDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SubscriptionUpdateDto.prototype, "active", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.MaxLength)(50, { message: 'Reference ID must be less than 50 characters' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], SubscriptionUpdateDto.prototype, "referenceId", void 0);
