import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import {
  NotificationTemplateAllFilterDto,
  NotificationTemplateCreateDto,
  NotificationTemplateFilterDto,
  NotificationTemplateResponseDto,
  NotificationTemplateUpdateDto,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../shared/logger';
import { INotificationTemplateListResolverData } from './template-list/template-list-models/notification-template-list-resolver-data.interface';
import { INotificationTemplateFilters } from './template-list/template-list-models/notification-template-list-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private toastService: AppToastManagerService
  ) {
    super();
  }

  public async getTemplateById(
    id: number
  ): Promise<NotificationTemplateResponseDto> {
    return this.appClientDataService.execute<NotificationTemplateResponseDto>(
      this.GET_ROUTES.NOTIFICATION_TEMPLATE_BY_ID,
      {
        pathParams: { id: id }
      },
      NotificationTemplateResponseDto
    );
  }

  public async getAllTemplates(
    filters: NotificationTemplateAllFilterDto
  ): Promise<NotificationTemplateResponseDto[]> {
    return this.appClientDataService.execute<NotificationTemplateResponseDto[]>(
      this.GET_ROUTES.NOTIFICATION_TEMPLATE_ALL,
      {
        queryParams: this.buildQueryParams(filters, filters.getFields())
      },
      NotificationTemplateResponseDto
    );
  }

  public getTemplateListDefaultFilters(): INotificationTemplateFilters {
    const defaultFilters: INotificationTemplateFilters = {
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.NOTIFICATION_TEMPLATE_LIST
      ),
      schoolId: null,
      schoolGroupId: null
    };
    const currentUser = this.StorageManager.getLoggedInUser();
    if (currentUser.schoolGroupId)
      defaultFilters.schoolGroupId = currentUser.schoolGroupId;
    if (currentUser.currentSchoolId)
      defaultFilters.schoolId = currentUser.currentSchoolId;
    return defaultFilters;
  }

  public async getTemplateListPageResolverData(): Promise<INotificationTemplateListResolverData> {
    try {
      const defaultFilters = this.getTemplateListDefaultFilters();
      return {
        defaultFilters,
        paginationResults: await this.getTemplateList(defaultFilters)
      };
    } catch (error) {
      this.toastService.error('Error loading notification template list.');
      logger.error(error);
      return null;
    }
  }

  public async getTemplateList(
    filters: INotificationTemplateFilters
  ): Promise<PaginationResultsDto<NotificationTemplateResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<NotificationTemplateResponseDto>
    >(
      this.GET_ROUTES.NOTIFICATION_TEMPLATE_LIST,
      {
        queryParams: {
          ...(filters.name
            ? { name: filters.name }
            : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.schoolGroupId ? { schoolGroupId: filters.schoolGroupId } : {}),
          ...(filters.bodyType ? { bodyType: filters.bodyType } : {}),
          ...(filters.isGlobal ? { isGlobal: filters.isGlobal } : {}),
          ...(filters.isSchoolGroupLevel ? { isSchoolGroupLevel: filters.isSchoolGroupLevel } : {}),
          ...(filters.notificationType ? { notificationType: filters.notificationType } : {}),
          ...(filters.tableFilters?.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters?.page
            ? { page: filters.tableFilters.page }
            : {}),
        },
      },
      NotificationTemplateResponseDto
    );
  }

  public async createTemplate(
    template: NotificationTemplateCreateDto
  ): Promise<NotificationTemplateResponseDto> {
    return this.appClientDataService.execute<NotificationTemplateResponseDto>(
      this.POST_ROUTES.CREATE_NOTIFICATION_TEMPLATE,
      {
        method: 'POST',
        body: template
      },
      NotificationTemplateResponseDto
    );
  }

  public async updateTemplate(
    template: NotificationTemplateUpdateDto
  ): Promise<NotificationTemplateResponseDto> {
    return this.appClientDataService.execute<NotificationTemplateResponseDto>(
      this.PUT_ROUTES.UPDATE_NOTIFICATION_TEMPLATE,
      {
        method: 'PUT',
        pathParams: { id: template.id },
        body: template
      },
      NotificationTemplateResponseDto
    );
  }
}
