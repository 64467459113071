import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { IComplianceListResolverData } from './compliance-list/compliance-list-models/compliance-list-resolver-data.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { IComplianceListFilters } from './compliance-list/compliance-list-models/compliance-list-filters.interface';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  IDisplayData,
  PaginationResultsDto,
  ReactionEntriesListResponseDto,
  ReactionEntriesUpdateDto,
  ScheduledReactionResponseDto
} from '@whetstoneeducation/hero-common';
import { IComplianceByScheduledReactionListResolverData } from './compliance-by-scheduled-reaction/compliance-by-scheduled-reaction.resolver';
import { ITableFilters } from '../../shared/tables/table-filters/table-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppComplianceService extends BaseService {
  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  public async getComplianceListResolverData(): Promise<IComplianceListResolverData> {
    // set today as default start/end date
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const defaultFilters = {
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.COMPLIANCE_LIST
      ),
      onlyComplianceRequired: true,
      startDate: startDate.getTime(),
      endDate: endDate.getTime()
    };
    const reactionEntriesCall = await this.getReactionEntries(defaultFilters);
    const reactionsMapCall = await this.getReactionsDisplayData();

    const [reactionEntries, reactionsMap] = await Promise.all([
      reactionEntriesCall,
      reactionsMapCall
    ]);
    const reactions = Object.values(reactionsMap).reduce(
      (acc, curr) => acc.concat(curr),
      []
    );

    defaultFilters.tableFilters.count = reactionEntries.options.totalItems;
    return {
      defaultFilters,
      reactionEntries,
      dropdownData: {
        reactions: reactions
      },
      startDate,
      endDate
    };
  }

  public async getComplianceByScheduledReactionListResolverData(
    scheduledReactionId: number
  ): Promise<IComplianceByScheduledReactionListResolverData> {
    const tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.COMPLIANCE_BY_SCHEDULED_REACTION_LIST
    );

    const reactionEntriesCall =
      await this.getReactionEntriesByScheduledReaction(
        scheduledReactionId,
        tableFilters
      );
    const scheduledReactionCall =
      await this.getScheduledReaction(scheduledReactionId);

    const [reactionEntries, scheduledReaction] = await Promise.all([
      reactionEntriesCall,
      scheduledReactionCall
    ]);

    tableFilters.count = reactionEntries.options.totalItems;
    return {
      tableFilters,
      reactionEntries,
      scheduledReaction
    };
  }

  public async getReactionEntries(
    filters: IComplianceListFilters
  ): Promise<PaginationResultsDto<ReactionEntriesListResponseDto>> {
    return await this.appClientDataService.execute<
      Promise<PaginationResultsDto<ReactionEntriesListResponseDto>>
    >(this.GET_ROUTES.REACTION_ENTRY_LIST, {
      queryParams: {
        ...filters.tableFilters,
        ...(filters.searchInput ? { search: filters.searchInput } : {}),
        ...(filters.reactionId ? { reactionId: filters.reactionId } : {}),
        ...(filters.startDate ? { startDate: filters.startDate } : {}),
        ...(filters.endDate ? { endDate: filters.endDate } : {}),
        ...(filters.complied !== undefined
          ? { complied: filters.complied }
          : {}),
        ...(filters.onlyComplianceRequired
          ? { onlyComplianceRequired: filters.onlyComplianceRequired }
          : {})
      }
    });
  }
  public async getReactionEntriesByScheduledReaction(
    scheduledReactionId: number,
    filters: ITableFilters
  ): Promise<PaginationResultsDto<ReactionEntriesListResponseDto>> {
    return await this.appClientDataService.execute<
      Promise<PaginationResultsDto<ReactionEntriesListResponseDto>>
    >(this.GET_ROUTES.REACTION_ENTRY_LIST_BY_SCHEDULED_REACTION, {
      queryParams: {
        ...filters
      },
      pathParams: {
        scheduledReactionId
      }
    });
  }

  public async getReactionsDisplayData(): Promise<{
    [schoolId: number]: IDisplayData[];
  }> {
    return this.appClientDataService.execute<{
      [schoolId: number]: IDisplayData[];
    }>(this.GET_ROUTES.REACTIONS_LIST_DISPLAY_DATA, {
      method: this.METHOD.GET
    });
  }

  public async updateReactionEntry(
    id: number,
    dto: ReactionEntriesUpdateDto
  ): Promise<void> {
    await this.appClientDataService.execute(
      this.PUT_ROUTES.UPDATE_REACTION_ENTRY,
      {
        method: this.METHOD.PUT,
        body: dto,
        pathParams: {
          id
        }
      }
    );
  }

  public async getScheduledReaction(
    id: number
  ): Promise<ScheduledReactionResponseDto> {
    return this.appClientDataService.execute<ScheduledReactionResponseDto>(
      this.GET_ROUTES.SCHEDULED_REACTION_BY_ID,
      {
        method: this.METHOD.GET,
        pathParams: {
          id
        }
      }
    );
  }
}
