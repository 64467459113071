import { Injectable } from '@angular/core';
import { AppToastManagerService } from '../shared/services/toast-manager.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageManager } from '../shared/storage/storage.manager';

@Injectable()
export class SchoolGroupGuard {
  public constructor(
    public toastManager: AppToastManagerService,
    public router: Router
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot) {
    const schoolGroupId = StorageManager.getActiveSchoolGroupId();
    if (schoolGroupId) {
      return true;
    } else {
      this.toastManager.warn('Please select a school group.');
      return false;
    }
  }
}
