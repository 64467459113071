import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppSettingsService } from 'src/app/shared/services/settings.service';
import { AppAuthService } from '../../pages/auth/auth.service';
import { BaseService } from '../base-classes/base.service';
import { NavbarActions } from './navbar-models/navbar-actions.enum';
import { AppSessionHandlerService } from 'src/app/pages/auth/session-handler.service';

/**
 * Service used to manipulate the client navbar.
 */
@Injectable({
  providedIn: 'root',
})
export class AppNavbarService extends BaseService implements OnInit {
  /**
   * Outputs an event to close the navbar. (and the dropdown tabs)
   */
  public shouldCloseNavbar: Subject<void>;

  /**
   * Outputs an event to close just the navbar dropdown tabs.
   */
  public shouldCloseNavbarTabs: Subject<void>;

  /**
   * Outputs an event to tell the navbar that some data has changed, and its needs to reload data
   */
  public dataChanged: Subject<void>;

  /**
   * An event emitter that can be subscribed to that will listen for visibility changes for the navbar.
   */
  public visibilityChanged: BehaviorSubject<boolean>;

  /**
   * An event emitter that will tell subscribing methods when a navbar action has been pressed
   */
  public navbarActionPressed: Subject<NavbarActions>;

  public constructor(
    public authService: AppAuthService,
    public settingsService: AppSettingsService,
    public sessionHandlerService: AppSessionHandlerService
  ) {
    super({ authService, settingsService });
    this.shouldCloseNavbar = new Subject<void>();
    this.shouldCloseNavbarTabs = new Subject<void>();
    this.dataChanged = new Subject<void>();
    this.visibilityChanged = new BehaviorSubject<boolean>(false);
    this.navbarActionPressed = new Subject<NavbarActions>();
  }

  public ngOnInit(): void {
    this.sessionHandlerService
      .getIsLoggedInObservable()
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.updateData();
        }
      });
  }

  /**
   * Close the navbar and all of the dropdown tabs
   */
  public closeNavbar(): void {
    this.shouldCloseNavbar.next();
    this.shouldCloseNavbarTabs.next();
  }

  /**
   * Close just the dropdown tabs
   */
  public closeNavbarTabs(): void {
    this.shouldCloseNavbarTabs.next();
  }

  /**
   * Tells the navbar component to retrieve its data again.
   */
  public updateData(): void {
    this.dataChanged.next();
  }

  /**
   * Called on route changes to determine if we should show/hide the navbar based on a particular page.
   * By default the navbar will always be hidden--if we are NOT on the pages as described below, it will then be shown.
   * @param routePath The route path given by the Angular Router.
   */
  public setNavbarVisibilityForRoutePath(routePath: string): void {
    if (this.sessionHandlerService.getIsLoggedIn()) {
      const shouldShowNavbar = this.shouldShowNavbarForRoutePath(routePath);
      this.toggleVisibility(shouldShowNavbar);
    } else {
      this.toggleVisibility(false);
    }
  }

  private shouldShowNavbarForRoutePath(routePath: string): boolean {
    const navbarHiddenURLs: string[] = [
      '/login',
      '/login/district',
      '/login/school',
      '/login/forgot',
      '/login/create',
      '/auth/identity',
    ];
    return (
      !navbarHiddenURLs.includes(routePath) &&
      !navbarHiddenURLs.includes(window.location.pathname)
    );
  }

  private toggleVisibility(newValue?: boolean): void {
    const currentValue = this.visibilityChanged.value;
    if (newValue !== currentValue) {
      this.visibilityChanged.next(newValue);
    }
  }
}
