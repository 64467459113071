import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  IBehaviorEntryDailyActivityReportFilterDto,
  IDisplayData,
  ImportSubject
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodesListFilters } from '../../../behavior-codes/behavior-codes-list/behavior-codes-list-models/behavior-codes-list-filters.interface';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatOptionSelectionChange } from '@angular/material/core';
import { map, Observable, startWith, Subject, takeUntil, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';

@Component({
  selector: 'app-daily-activity-report-filters',
  templateUrl: './daily-activity-report-filter.template.html',
  styleUrls: ['./daily-activity-report-filter.scss']
})
export class AppDailyActivityReportFiltersComponent
  extends BaseComponent
  implements OnDestroy
{
  @Input() filters: IBehaviorEntryDailyActivityReportFilterDto;
  @Input() userOptions: IDisplayData[];
  @Input() behaviorCodeOptions: IDisplayData[];
  @Input() startDate: Date;

  userControl = new FormControl('');
  behaviorCodeControl = new FormControl('');
  showDeactivatedRecordsControl = new FormControl(false);
  previousUserId: number | null = null;
  previousBehaviorCodeId: number | null = null;
  private destroy$ = new Subject<void>();

  filteredUserOptions: Observable<IDisplayData[]>;
  filteredBehaviorCodeOptions: Observable<IDisplayData[]>;

  @Output()
  filtersUpdated: EventEmitter<IBehaviorEntryDailyActivityReportFilterDto>;
  public constructor() {
    super();
    this.filtersUpdated = new EventEmitter<IBehaviorCodesListFilters>();
    this.filteredUserOptions = this.userControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterUserOptions(value || '', null))
    );
    this.filteredBehaviorCodeOptions =
      this.behaviorCodeControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterBehaviorCodeOptions(value || '', null))
      );
    this.showDeactivatedRecordsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filters.showDeactivatedRecords =
          this.showDeactivatedRecordsControl.value;
        this.filtersUpdated.emit(this.filters);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Handle the scenario where the user types and filter the options or removes the option selected
  // for both text boxes behavior code and user selection
  ngOnInit(): void {
    this.behaviorCodeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        // If the value is empty
        if (value === '') {
          this.handleManualClearInput('behavior');
        } else {
          // If the value is not empty, proceed to filter the options
          this.filteredBehaviorCodeOptions = of(
            this._filterBehaviorCodeOptions(
              value,
              this.filters.behaviorCodeId
                ? this.filters.behaviorCodeId.toString()
                : null
            )
          );
        }
      });

    this.userControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        // If the value is empty
        if (value === '') {
          this.handleManualClearInput('user');
        } else {
          // If the value is not empty, proceed to filter the options
          this.filteredUserOptions = of(
            this._filterUserOptions(
              value,
              this.filters.userId ? this.filters.userId.toString() : null
            )
          );
        }
      });
  }

  onStartDateUpdated(event: MatDatepickerInputEvent<Date>) {
    // Set event value to the beginning of the day with dayjs
    this.filters.startDate = dayjs(event.target.value).startOf('day').toDate();
    this.filtersUpdated.emit(this.filters);
  }

  onEndDateUpdated(event: MatDatepickerInputEvent<Date>) {
    // Set event value to the end of the day with dayjs
    this.filters.endDate = dayjs(event.target.value).endOf('day').toDate();
    this.filtersUpdated.emit(this.filters);
  }

  handleUserSelection(event: MatOptionSelectionChange): void {
    // If it's not from user input return, this will solve the problem to avoid
    // displaying the current selection from the user
    if (!event.isUserInput) {
      return;
    }

    const value = event.source.value;
    const selectedOption = this.userOptions.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      // Store the previous user id
      const previousValue = this.filters.userId;
      // Set the new selected user
      this.filters.userId = value;

      // Display name of the selected option
      this.userControl.setValue(selectedOption.display);

      // Update the filtered options to exclude the newly selected option and avoid the check-mark to display
      this.filteredUserOptions = this.userControl.valueChanges.pipe(
        startWith(''),
        map((inputValue) =>
          this._filterUserOptions(
            inputValue || '',
            this.filters.userId ? this.filters.userId.toString() : null
          )
        )
      );
      this.previousUserId = previousValue;
      this.filtersUpdated.emit(this.filters);
    }
  }

  handleBehaviorCodeSelection(event: MatOptionSelectionChange) {
    // If it's not from user input return, this will solve the problem to avoid
    // displaying the current selection from the user
    if (!event.isUserInput) {
      return;
    }

    const value = event.source.value;
    const selectedOption = this.behaviorCodeOptions.find(
      (option) => option.value === value
    );

    if (selectedOption) {
      // Store the previous behavior code id
      const previousValue = this.filters.behaviorCodeId;
      // Set the new selected behavior code
      this.filters.behaviorCodeId = value;

      // Display name of the selected option
      this.behaviorCodeControl.setValue(selectedOption.display);

      // Update the filtered options to exclude the newly selected option and avoid the check-mark to display
      this.filteredBehaviorCodeOptions =
        this.behaviorCodeControl.valueChanges.pipe(
          startWith(''),
          map((inputValue) =>
            this._filterBehaviorCodeOptions(
              inputValue || '',
              this.filters.behaviorCodeId
                ? this.filters.behaviorCodeId.toString()
                : null
            )
          )
        );
      this.previousBehaviorCodeId = previousValue;
      this.filtersUpdated.emit(this.filters);
    }
  }

  // We will need to consider the scenario where the user removes the option manually from the behavior or user selection
  handleManualClearInput(controlType: 'behavior' | 'user'): void {
    if (controlType === 'behavior') {
      // Reset the options and restore the previously selected value
      if (!this.behaviorCodeControl.value) {
        if (this.previousBehaviorCodeId) {
          const previousOption = this.behaviorCodeOptions.find(
            (option) => option.value === this.previousBehaviorCodeId
          );
          if (previousOption) {
            if (
              !this.behaviorCodeOptions.some(
                (option) => option.value === previousOption.value
              )
            ) {
              this.behaviorCodeOptions.push(previousOption);
            }
          }
        }
        // Reset the filter to show all options again after the selection
        this.filteredBehaviorCodeOptions =
          this.behaviorCodeControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterBehaviorCodeOptions(value || '', null))
          );

        // Reset previous selection
        this.previousBehaviorCodeId = null;

        // Clear the behaviorCodeControl input field
        this.behaviorCodeControl.setValue('');
      }
    }

    if (controlType === 'user') {
      // Reset the options and restore the previously selected value
      if (!this.userControl.value) {
        if (this.previousUserId) {
          const previousUserOption = this.userOptions.find(
            (option) => option.value === this.previousUserId
          );
          if (previousUserOption) {
            if (
              !this.userOptions.some(
                (option) => option.value === previousUserOption.value
              )
            ) {
              this.userOptions.push(previousUserOption);
            }
          }
        }
        // Reset the filter to show all options again after the selection
        this.filteredUserOptions = this.userControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterUserOptions(value || '', null))
        );

        // Reset previous selection for user
        this.previousUserId = null;

        // Clear the userControl input field
        this.userControl.setValue('');
      }
    }
  }

  private _filterUserOptions(
    inputValue: string,
    selectedValue: string | null
  ): IDisplayData<any>[] {
    const filterValue = inputValue.toLowerCase();
    // Filter out the selected user from the list of options
    return this.userOptions.filter(
      (option) =>
        option.display.toLowerCase().includes(filterValue) &&
        (selectedValue === null || option.value.toString() !== selectedValue)
    );
  }

  private _filterBehaviorCodeOptions(
    value: string,
    selectedValue: string | null
  ): IDisplayData[] {
    const filterValue = value.toLowerCase();

    // Filter out the selected value from the list of options
    return this.behaviorCodeOptions.filter(
      (option) =>
        option.display.toLowerCase().includes(filterValue) &&
        (selectedValue === null || option.value.toString() !== selectedValue)
    );
  }

  clearSelection(field: 'behaviorCode' | 'user'): void {
    if (field === 'behaviorCode') {
      // Clear the form control value for behaviorCodeControl
      this.behaviorCodeControl.setValue('');
      this.filters.behaviorCodeId = null;

      // Re-add the previously selected behavior code to the options list if it exists and avoid duplicates
      if (this.previousBehaviorCodeId) {
        const previousOption = this.behaviorCodeOptions.find(
          (option) => option.value === this.previousBehaviorCodeId
        );
        if (previousOption) {
          if (
            !this.behaviorCodeOptions.some(
              (option) => option.value === previousOption.value
            )
          ) {
            this.behaviorCodeOptions.push(previousOption);
          }
        }
      }

      // Reset the filter to show all options again
      this.filteredBehaviorCodeOptions =
        this.behaviorCodeControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterBehaviorCodeOptions(value || '', null))
        );
      this.previousBehaviorCodeId = null;
    } else if (field === 'user') {
      // Clear the form control value for userControl
      this.userControl.setValue('');
      this.filters.userId = null;

      // Re-add the previously selected user to the options list if it exists and avoid duplicates
      if (this.previousUserId) {
        const previousUser = this.userOptions.find(
          (option) => option.value === this.previousUserId
        );
        if (previousUser) {
          if (
            !this.userOptions.some(
              (option) => option.value === previousUser.value
            )
          ) {
            this.userOptions.push(previousUser);
          }
        }
      }

      // Reset the filter to show all options again
      this.filteredUserOptions = this.userControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterUserOptions(value || '', null))
      );
      this.previousUserId = null;
    }
    this.filtersUpdated.emit(this.filters);
  }

  protected readonly ImportSubject = ImportSubject;
}
