import { BaseComponent } from '../../shared/base-classes/base.component';
import {
  IRelation,
  LoginResponseDto,
  SchoolGroupResponseDto,
  SftpAccountExistingDto
} from '@whetstoneeducation/hero-common';
import { AppSftpService } from './sftp.service';

export class SftpAccountExistingBase extends BaseComponent {
  public isLoading = false;
  public accountFilters: SftpAccountExistingDto;
  public lastAccountFilters: SftpAccountExistingDto;
  public accountExists = false;
  public filtersReady = false;
  public schoolGroups: SchoolGroupResponseDto[];
  public schools: IRelation[];
  protected currentUser: LoginResponseDto;

  constructor(protected readonly sftpService: AppSftpService) {
    super();
  }

  public loadData(): void {
    this.isLoading = true;
    this.currentUser = this.StorageManager.getLoggedInUser();
    this.schoolGroups = this.currentUser.schoolGroups;
    this.schools = this.currentUser.schoolGroups[0].schools;

    this.accountFilters = new SftpAccountExistingDto({
      schoolId: null,
      schoolGroupId: this.schoolGroups[0].id,
      groupLevel: false
    });
    this.isLoading = false;
  }

  schoolGroupChanged(): void {
    this.schools = this.schoolGroups.find(
      (group) => group.id === this.accountFilters.schoolGroupId
    )?.schools;
  }

  public async findExistingAccount(): Promise<void> {
    this.isLoading = true;
    const response = await this.sftpService.getExistingSftpAccount(
      this.accountFilters
    );
    this.accountExists = response.accountExists;
    this.isLoading = false;
  }
}
