import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { Injectable } from '@angular/core';
import {
  PaginationResultsDto,
  SchoolGroupCreateDto,
  SchoolGroupFilterDto,
  SchoolGroupResponseDto,
  SchoolGroupUpdateDto
} from '@whetstoneeducation/hero-common';
import { ISchoolsListFilters } from '../schools/schools-list/schools-list-models/schools-list-filters.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../shared/logger';
import { ISchoolGroupsListResolverData } from './school-groups-list/school-groups-list-models/school-groups-list-resolver-data.interface';
import { ISchoolGroupsListFilters } from './school-groups-list/school-groups-list-models/school-groups-list-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class AppSchoolGroupsService extends BaseService {
  /**
   * School Groups Services Constructor
   * @param appClientDataService
   * @param toastService
   */
  constructor(
    private appClientDataService: AppClientDataService,
    private toastService: AppToastManagerService
  ) {
    super();
  }

  /**
   * Get all school groups
   * @param {SchoolGroupFilterDto} filters - filters to apply to the query
   * @returns {Promise<any>} - list of school groups
   */
  public async getSchoolGroupOptions(
    filters: SchoolGroupFilterDto
  ): Promise<PaginationResultsDto<SchoolGroupResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<SchoolGroupResponseDto>
    >(
      this.GET_ROUTES.SCHOOL_GROUP_LIST,
      {
        queryParams: {
          ...(filters.itemsPerPage
            ? { itemsPerPage: filters.itemsPerPage }
            : {}),
          ...(filters.page ? { page: filters.page } : {})
        }
      },
      SchoolGroupResponseDto
    );
  }

  public async getSchoolGroupsList(
    filters: ISchoolsListFilters
  ): Promise<PaginationResultsDto<SchoolGroupResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<SchoolGroupResponseDto>
    >(
      this.GET_ROUTES.SCHOOL_GROUP_LIST,
      {
        queryParams: {
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {}),
          ...(filters.searchInput
            ? {
                id: filters.searchInput,
                name: filters.searchInput
              }
            : {})
        }
      },
      SchoolGroupResponseDto
    );
  }

  public getSchoolGroupsListDefaultFilters(): ISchoolGroupsListFilters {
    return {
      searchInput: null,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.SCHOOL_GROUPS_LIST
      )
    };
  }

  public async getSchoolGroupsListPageResolverData(): Promise<ISchoolGroupsListResolverData> {
    try {
      const defaultFilters = this.getSchoolGroupsListDefaultFilters();
      return {
        defaultFilters,
        schoolGroups: await this.getSchoolGroupsList(defaultFilters)
      };
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading schools list');
      return null;
    }
  }

  /**
   * Get school group by id
   * @param {number} schoolGroupId - id of the school group
   * @returns {Promise<any>} - school group
   */
  public async getSchoolGroupById(
    schoolGroupId: number
  ): Promise<SchoolGroupResponseDto> {
    return this.appClientDataService.execute<any>(
      this.GET_ROUTES.SCHOOL_GROUP,
      {
        pathParams: {
          id: schoolGroupId
        }
      },
      null
    );
  }

  /**
   * Create a school group
   * @param {SchoolGroupCreateDto} schoolGroup - school group to create
   * @returns {Promise<any>} - created school group
   */
  public async createSchoolGroup(
    schoolGroup: SchoolGroupCreateDto
  ): Promise<SchoolGroupResponseDto> {
    return this.appClientDataService.execute<any>(
      this.POST_ROUTES.CREATE_SCHOOL_GROUP,
      {
        body: schoolGroup,
        method: this.METHOD.POST
      },
      SchoolGroupResponseDto
    );
  }

  /**
   * Update a school group
   * @param {SchoolGroupUpdateDto} schoolGroup - school group to update
   * @returns {Promise<any>} - updated school group
   */
  public async updateSchoolGroup(
    schoolGroup: SchoolGroupUpdateDto
  ): Promise<SchoolGroupResponseDto> {
    return this.appClientDataService.execute<any>(
      this.PUT_ROUTES.UPDATE_SCHOOL_GROUP,
      {
        body: schoolGroup,
        method: this.METHOD.PUT,
        pathParams: {
          id: schoolGroup.id
        }
      },
      SchoolGroupResponseDto
    );
  }
}
