"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanStringTransform = void 0;
// Define the transformation function
const BooleanStringTransform = ({ value }) => {
    if (value === 'true')
        return true;
    if (value === 'false')
        return false;
    return value;
};
exports.BooleanStringTransform = BooleanStringTransform;
