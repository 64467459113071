<div *ngIf="!loading" class="behavior-history-content">
  <app-behavior-history-filters
    [filters]="filters"
    [behaviorCodeOptions]="behaviorCodeOptions"
    [reactionOptions]="reactionOptions"
    (filtersUpdated)="updateFilters($event)"></app-behavior-history-filters>

  <div class="behavior-history-container">
    <app-behavior-history-table
      [openDeleteModal]="openDeleteModal.bind(this)"
      [openEditModal]="openEditModal.bind(this)"
      [onViewNotes]="onViewNotes.bind(this)"
      [hasNotes]="hasNotes.bind(this)"
      [dataArr]="studentBehaviorHistories"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="
        updateTableFilters($event)
      "></app-behavior-history-table>
  </div>
</div>
