import { Component, Input, OnInit } from '@angular/core';
import {
  IDisplayData,
  StudentBehaviorHistoryResponseDto
} from '@whetstoneeducation/hero-common';
import { IStudentBehaviorHistoryFilter } from '../../students-list-models/student-behavior-history-filters';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AppStudentsService } from '../../students.service';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../../shared/logger';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { DeleteBehaviorEntryConfirmModalComponent } from './delete-behavior-entry-confirm-modal/delete-behavior-entry-confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorHistoryEditModalComponent } from './behavior-history-edit-modal/behavior-history-edit-modal.component';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { BehaviorEntryNotesViewEditComponent } from './behavior-entry-notes-view-edit/behavior-entry-notes-view-edit.component';

@Component({
  selector: 'app-behavior-history',
  templateUrl: './behavior-history.component.html',
  styleUrls: ['./behavior-history.component.scss']
})
export class BehaviorHistoryComponent extends BaseComponent implements OnInit {
  @Input() studentId: number;
  studentBehaviorHistories: StudentBehaviorHistoryResponseDto[];
  reactionOptions: IDisplayData[];
  behaviorCodeOptions: IDisplayData[];
  filters: IStudentBehaviorHistoryFilter;
  loading: boolean = false;
  constructor(
    public studentsService: AppStudentsService,
    public toastService: AppToastManagerService,
    public confirmService: AppConfirmationDialogService,
    public dialog: MatDialog
  ) {
    super();
  }

  async ngOnInit() {
    this.loading = true;
    const calls = [];
    this.filters = {
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.STUDENT_BEHAVIOR_HISTORY_LIST
      )
    };
    calls.push(
      this.studentsService.getStudentBehaviorHistory(
        this.studentId,
        this.filters
      )
    );

    const user = this.StorageManager.getLoggedInUser();

    if (user.currentSchoolId) {
      calls.push(this.studentsService.getReactionOptions());
      calls.push(
        this.studentsService.getBehaviorCodeOptions(user.currentSchoolId)
      );
    }

    const [data, reactionOptions, behaviorCodeOptions] =
      await Promise.all(calls);

    this.reactionOptions =
      reactionOptions && reactionOptions[user.currentSchoolId]
        ? reactionOptions[user.currentSchoolId]
        : [];
    this.behaviorCodeOptions = behaviorCodeOptions ? behaviorCodeOptions : [];
    this.studentBehaviorHistories = data.results;
    this.filters.tableFilters.count = data.options.totalItems;

    this.loading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = {
      ...this.filters,
      ...newTableFilters
    };
    await this.updateBehaviorHistories(this.filters);
    this.isLoading = false;
  }

  public async updateFilters(
    newFilters: IStudentBehaviorHistoryFilter
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters = {
      ...this.filters,
      tableFilters: TableFilterOptions.resetTableFilters(
        TableFiltersPageKeyEnum.STUDENTS_LIST,
        this.filters.tableFilters
      )
    };
    await this.updateBehaviorHistories(this.filters);
    this.isLoading = false;
  }

  public async updateBehaviorHistories(
    filters: IStudentBehaviorHistoryFilter
  ): Promise<void> {
    try {
      const newData = await this.studentsService.getStudentBehaviorHistory(
        this.studentId,
        filters
      );
      this.studentBehaviorHistories = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving students!');
      logger.error(err);
    }
  }

  public openDeleteModal(behaviorCodeEntryId: number): void {
    this.dialog.open(DeleteBehaviorEntryConfirmModalComponent, {
      width: '600px',
      panelClass: 'default-dialog',
      data: {
        behaviorCodeEntryId,
        onClose: async () => {
          await this.updateBehaviorHistories(this.filters);
        }
      }
    });
  }

  public openEditModal(behaviorCodeEntryId: number): void {
    this.dialog.open(BehaviorHistoryEditModalComponent, {
      width: '600px',
      panelClass: 'default-dialog',
      data: {
        behaviorCodeEntryId,
        onClose: async () => {
          await this.updateBehaviorHistories(this.filters);
        }
      }
    });
  }

  public hasNotes(behaviorCodeId: number): boolean {
    return this.studentBehaviorHistories.some(
      (current) =>
        current.behaviorEntryId === behaviorCodeId &&
        current.notes !== null &&
        current.notes !== undefined &&
        current.notes.length > 0
    );
  }

  public onViewNotes(behaviorEntryId: number): void {
    // find notes for the behaviorEntry
    const behaviorEntry = this.studentBehaviorHistories.find(
      (current) => current.behaviorEntryId === behaviorEntryId
    );

    // open an edit dialog with the notes

    const ref = this.dialog.open(BehaviorEntryNotesViewEditComponent, {
      width: '600px',
      height: '360px',
      panelClass: 'default-dialog',
      data: {
        behaviorCodeEntry: behaviorEntry,
        onClose: async (notes: string) => {
          behaviorEntry.notes = notes;
        }
      }
    });
  }
}
