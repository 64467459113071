import { getRoutes, GetRoutes } from './get.routes';
import { postRoutes, PostRoutes } from './post.routes';
import { putRoutes, PutRoutes } from './put.routes';
import { deleteRoutes, DeleteRoutes } from './delete.routes';
import { patchRoutes, PatchRoutes } from './patch.routes';

export class APIRoutesConfig {
  /**
   * Returns a configuration object that has a mapping of all routes that the application has access to by default.
   */
  public static getRoutes(): GetRoutes {
    return {
      ...getRoutes,
    };
  }

  public static postRoutes(): PostRoutes {
    return {
      ...postRoutes,
    };
  }

  public static putRoutes(): PutRoutes {
    return {
      ...putRoutes,
    };
  }

  public static deleteRoutes(): DeleteRoutes {
    return {
      ...deleteRoutes,
    };
  }

  public static patchRoutes(): PatchRoutes {
    return {
      ...patchRoutes,
    };
  }
}
