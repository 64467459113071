"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TryArray = void 0;
const class_transformer_1 = require("class-transformer");
function TryArray() {
    return function (target, propertyName) {
        (0, class_transformer_1.Transform)(({ value }) => {
            // If value is a single number (not comma-separated), return it wrapped in an array
            if (typeof value === 'number') {
                return [value];
            }
            // If value is a comma-separated string, split it into an array of numbers
            if (typeof value === 'string') {
                return value.split(',').map(Number);
            }
            // If value is already an array, return it as is
            return value;
        })(target, propertyName);
    };
}
exports.TryArray = TryArray;
