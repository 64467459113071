import { BaseTableComponent } from '../base-table/base-table';
import { Directive, Input, TemplateRef } from '@angular/core';

type ColumnsType = { [s: string]: string };

export interface ITemplateContext<T> {
  context: T;
  template: TemplateRef<T>;
}

/* @class BaseCommonTableComponent<R, C>
 * @description Abstracts Table for you,
 * as well as displaying data. This component is meant to be used across
 * all tables.
 * @abstract Abstract class for Common Table Component.
 * @template R: Type of row data
 * @template C: Type of column data
 * @extends BaseTableComponent<R>
 *
 */
@Directive()
export abstract class BaseCommonTableComponent<R, C> extends BaseTableComponent<R>  {
  /* @property templates
   * @description Templates for the table
   * This is an object with keys as column names and values as TemplateRefs.
   * These can be used to display custom content in table cells or headers
   * in the overrideable functions getColumnValue and getColumnTitle.
   */
  @Input() templates?: { [key: string]: TemplateRef<any> };
  @Input() showPagination: boolean;
  @Input()
  public abstract columns: C;

  public constructor() {
    super();
  }

  /* @function displayedColumns()
   * @description Get the displayed columns for the table. This function
   * can be overridden if some columns should be conditionally displayed.
   * @returns string[]: The displayed columns
   */
  public get displayedColumns(): string[] {
    return Object.values(this.columns);
  }

  public get columnKeys(): string[] {
    return Object.keys(this.columns).map((key) => this.columns[key]);
  }

  /* @function getColumnTitle
   * @description Get the title of a column. This function can be overridden to
   * provide custom column title logic.
   * @param column: string: The column to get the title for
   */
  public getColumnTitle(column: string): any {
    return column;
  }

  /* @function getColumnValue
   * @description Get the value of a column for a row. This function can be
   * overridden to provide custom column value logic.
   * @param column: string: The column to get the value for
   * @param row: R: The row to get the column value from
   */
  public getColumnValue(column: string, row: R): any {
    return row[column];
  }
}
