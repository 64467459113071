import { NgModule } from '@angular/core';
import { UniqueIdDirective } from './unique-id.directive';
import { OptionsIdDirective } from './options-id.directive';
import { ListIdDirective } from './list-id.directive';

@NgModule({
  declarations: [
    UniqueIdDirective,
    OptionsIdDirective,
    ListIdDirective
  ],
  exports: [
    UniqueIdDirective,
    OptionsIdDirective,
    ListIdDirective
  ]
})
export class DirectiveModule { }
