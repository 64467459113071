<div class="container">
  <div class="no-school" *ngIf="!schoolId">
    The Daily Activity Report page can only be accessed for an individual
    school. Please select one and try again!
  </div>
  <app-daily-activity-report-filters
    [filters]="filters"
    (filtersUpdated)="onFiltersUpdated($event)"
    [userOptions]="userOptions"
    [behaviorCodeOptions]="behaviorCodes"
    [startDate]="startDate">
  </app-daily-activity-report-filters>
  <app-base-common-table-portal [context]="this"></app-base-common-table-portal>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>
</div>
