<div class="table-container mat-elevation-z2">
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-top">
    <app-pagination
      *ngIf="!showSelectOptions"
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="bell-schedules-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- Select Column -->
      <ng-container matColumnDef="{{columns.SELECT}}">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [checked]="dataArr?.length && selectedBellSchedules.length === dataArr.length"
            (change)="selectAllBellSchedules()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            [checked]="isSelected(row.id)"
            (change)="selectBellSchedule(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="{{columns.DESCRIPTION}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.DESCRIPTION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.description }} </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell class="action-column-header" *matHeaderCellDef>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let row">
          <button
            *ngIf="!showSelectOptions"
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/bell-schedules/' + row.id.toString()"
            options-id="EDIT_BELL_SCHEDULE"
            [option]="row.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
          <button
            *ngIf="showSelectOptions"
            mat-flat-button
            class="action-button icon-button"
            options-id="ADD_BELL_SCHEDULE"
            [option]="row.id"
            (click)="bellScheduleSelected.emit(row)">
            ADD BELL SCHEDULE
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div
      *ngIf="tableFilters.count > tableFilters.itemsPerPage"
      class="table-filters-bottom">
      <app-pagination
        [tableFilters]="tableFilters"
        (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
    </div>
  </div>
</div>
