import { Component, OnInit } from '@angular/core';
import { ScheduledReactionResponseDto } from '@whetstoneeducation/hero-common';
import { BaseTableComponent } from '../../../shared/tables/base-table/base-table';
import { ScheduledReactionTableColumns } from '../../calendar/calendar-view/calendar-view-table/scheduled-reaction-table-columns.enum';
import { Router } from '@angular/router';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-scheduled-reaction-table',
  templateUrl: './scheduled-reaction-table.template.html',
  styleUrls: ['./scheduled-reaction-table.scss']
})
export class AppScheduledReactionTableComponent
  extends BaseTableComponent<ScheduledReactionResponseDto>
  implements OnInit
{
  public showPagination: boolean;

  public columns = ScheduledReactionTableColumns;

  public columnValues = {
    [ScheduledReactionTableColumns.DATE]: ['date'],
    [ScheduledReactionTableColumns.CAPACITY]: ['capacity']
  };

  public get displayedColumns() {
    return Object.values(ScheduledReactionTableColumns);
  }

  constructor(
    public toastManager: AppToastManagerService,
    public router: Router
  ) {
    super();
  }

  public ngOnInit() {
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
  }
}
