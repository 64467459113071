import { DateUtil } from "@whetstoneeducation/hero-common";
import { ZplCommands } from "../../../shared/printer/commands/zpl";
import { StudentInfoPrintBuilder } from "./student-info-print-builder";

export class StudentInfoZplBuilder extends StudentInfoPrintBuilder {

  constructor() {
    super();
  }

  public startCommand(): string {
    return ZplCommands.start + ZplCommands.endline;
  }

  public finishCommand(): string {
    return ZplCommands.finish;
  }

  public buildStudentName(): StudentInfoPrintBuilder {
    this.addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 50))
      .addCommand(ZplCommands.field_data('First Name:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 50))
      .addCommand(ZplCommands.field_data(this.data.firstName))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(350, 50))
      .addCommand(ZplCommands.field_data('Last Name:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(550, 50))
      .addCommand(ZplCommands.field_data(this.data.lastName))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildStudentIdGrade(): StudentInfoPrintBuilder {
    this.addCommand(ZplCommands.change_font(0, 15))
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 90))
      .addCommand(ZplCommands.field_data('Student ID#:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 90))
      .addCommand(ZplCommands.field_data(this.getStudentId()))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(350, 90))
      .addCommand(ZplCommands.field_data('Grade:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(550, 90))
      .addCommand(ZplCommands.field_data(this.data.grade))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildBarcode(): StudentInfoPrintBuilder {
    const studentExtId = this.data.studentExtId;

    if (studentExtId && studentExtId.length) {
      const initialXAxis = 315;
      const movePoints = studentExtId.length * 10;
      const xOrigin = initialXAxis - movePoints < 0 ? 0 : initialXAxis - movePoints;

      this.addCommand(ZplCommands.field_origin(xOrigin, 130))
        .addCommand(ZplCommands.barcode(125))
        .addCommand(ZplCommands.field_data(studentExtId))
        .addCommand(ZplCommands.field_separator);
    }

    return this;
  }

}
