<div class="mat-table">
  <mat-table
    class="period-list-table"
    [dataSource]="dataSource"
    matSort
    matSortDisableClear>
    <!-- Name Column -->
    <ng-container matColumnDef="{{columns.PERIOD_NAME}}">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{columns.PERIOD_NAME}}
      </mat-header-cell>
      <mat-cell *matCellDef="let period">
        {{ period.bellSchedulePeriod.name }}
      </mat-cell>
    </ng-container>

    <!-- Start Time Column -->
    <ng-container matColumnDef="{{columns.START_TIME}}">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{columns.START_TIME}}
      </mat-header-cell>
      <mat-cell *matCellDef="let period">
        {{ formatTime(period.start) }}
      </mat-cell>
    </ng-container>

    <!-- End Time Column -->
    <ng-container matColumnDef="{{columns.END_TIME}}">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{columns.END_TIME}}
      </mat-header-cell>
      <mat-cell *matCellDef="let period">
        {{ formatTime(period.end) }}
      </mat-cell>
    </ng-container>

    <!-- Ref Code Column -->
    <ng-container matColumnDef="{{columns.REF_CODE}}">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{columns.REF_CODE}}
      </mat-header-cell>
      <mat-cell *matCellDef="let period">
        {{ period.bellSchedulePeriod.referenceCode }}
      </mat-cell>
    </ng-container>

    <!-- Ext Code Column -->
    <ng-container matColumnDef="{{columns.EXT_CODE}}">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{columns.EXT_CODE}}
      </mat-header-cell>
      <mat-cell *matCellDef="let period">
        {{ period.bellSchedulePeriod.extPeriodCode }}
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="{{columns.ACTIONS}}">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let period">
        <button
          mat-flat-button
          class="action-button icon-button"
          (click)="onEdit(period)">
          <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
        </button>
        <button
          mat-flat-button
          class="action-button icon-button"
          (click)="onDelete(period)">
          <fa-icon [icon]="['fas', 'trash']"></fa-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
    <mat-row *matRowDef="let period; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
