"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentBasicResponseDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
class StudentBasicResponseDto extends mappable_base_1.MappableBase {
    constructor(responseOptions) {
        super();
        if (responseOptions) {
            Object.assign(this, responseOptions);
        }
    }
    id;
    userId;
    schoolId;
    firstName;
    lastName;
    middleInitial;
    birthDate;
    referenceId;
    flagMessage;
    homeRoom;
    grade;
    profilePhotoUrl;
    studentExtId;
    getFields() {
        return [
            'id',
            'userId',
            'firstName',
            'lastName',
            'middleInitial',
            'birthDate',
            'referenceId',
            'flagMessage',
            'grade',
            'homeroom',
            'studentExtId',
            'schoolId'
        ];
    }
    /**
     * @public
     * @static
     * @function mapAll
     * @param entities {MappableBase[]}
     * @returns {StudentBasicResponseDto[]} - an array of student dtos with fields mapped from the input student array
     */
    static mapDto(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, StudentBasicResponseDto);
    }
}
exports.StudentBasicResponseDto = StudentBasicResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], StudentBasicResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], StudentBasicResponseDto.prototype, "userId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], StudentBasicResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "firstName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "lastName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "middleInitial", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "birthDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "referenceId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "flagMessage", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "homeRoom", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "grade", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "profilePhotoUrl", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentBasicResponseDto.prototype, "studentExtId", void 0);
