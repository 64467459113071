"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobFrequency = void 0;
var JobFrequency;
(function (JobFrequency) {
    JobFrequency["OneTime"] = "one-time";
    JobFrequency["Hourly"] = "hourly";
    JobFrequency["Daily"] = "daily";
    JobFrequency["Weekly"] = "weekly";
    JobFrequency["Monthly"] = "monthly";
})(JobFrequency || (exports.JobFrequency = JobFrequency = {}));
