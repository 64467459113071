<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <div class="right-filters">
      <!-- Export Button -->
      <button mat-flat-button (click)="exportTable()">
        <fa-icon [icon]="['fas', 'upload']"></fa-icon> Export
      </button>
    </div>
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="user-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- ID Column -->
      <ng-container matColumnDef="{{columns.ID}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.ID}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.id}} </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="{{columns.EMAIL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.EMAIL}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
      </ng-container>

      <!-- Last Name Column -->
      <ng-container matColumnDef="{{columns.LAST}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.LAST}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.lastName}} </mat-cell>
      </ng-container>

      <!-- First Name Column -->
      <ng-container matColumnDef="{{columns.FIRST}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.FIRST}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.firstName}} </mat-cell>
      </ng-container>

      <!-- School Column -->
      <ng-container matColumnDef="{{columns.SCHOOL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.SCHOOL}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">
          <app-link-dropdown
            [text]="'View Schools'"
            [justShowContent]="(user.schools?.length || 0) < 4">
            <div *ngFor="let school of user.schools">{{ school.name }}</div>
          </app-link-dropdown>
        </mat-cell>
      </ng-container>

      <!-- School Group Column -->
      <ng-container matColumnDef="{{columns.SCHOOL_GROUP}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.SCHOOL_GROUP}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.schoolGroup?.name}} </mat-cell>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="{{columns.ROLE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.ROLE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user">
          <ng-container *ngFor="let role of user?.roles">
            <div class="role">{{ role?.name }}</div>
          </ng-container>
        </mat-cell>
      </ng-container>

      <!-- Instructor Code Column -->
      <ng-container matColumnDef="{{columns.INSTRUCTOR_CODE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.INSTRUCTOR_CODE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.instructorCode}} </mat-cell>
      </ng-container>

      <!-- Role Column -->
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell
          class="action-column-header"
          *matHeaderCellDef
          mat-sort-header>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let user">
          <button
            *ngIf="canManage"
            options-id="EDIT_USER"
            [option]="user.id"
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/users/' + user.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
          <button
            *ngIf="canManage && user.active"
            options-id="DELETE_USER"
            [option]="user.id"
            mat-flat-button
            class="action-button icon-button"
            (click)="confirmDeactivate(user)">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
          <button
            *ngIf="canManage && !user.active"
            mat-flat-button
            class="action-button icon-button"
            options-id="REACTIVATE_USER"
            (click)="reactivateUser(user, user.id)">
            <fa-icon [icon]="['fas', 'rotate']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let user; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
