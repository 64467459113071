import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppReactionsService } from '../reactions.service';
import { IReactionsListResolverData } from './reactions-list-models/reactions-list-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppReactionsListResolver extends BaseResolver<IReactionsListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private reactionsService: AppReactionsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<IReactionsListResolverData> {
    const defaultFilters =
      this.reactionsService.getReactionsListDefaultFilters();
    return {
      defaultFilters,
      reactions: await this.reactionsService.getReactionsList(defaultFilters),
    };
  }
}
