import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { StudentHeaderInfoComponent } from './student-header-info.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppStudentProfilePhotoModule } from "../student-profile-photo/student-profile-photo.module";

@NgModule({
  imports: [CommonModule, AppMaterialModule, FontAwesomeModule, NgOptimizedImage, AppStudentProfilePhotoModule],
  declarations: [StudentHeaderInfoComponent],
  exports: [StudentHeaderInfoComponent],
})
export class AppStudentHeaderInfoModule {}
