import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { ICustomRoute } from '../../app-routing.module';
import { AppExportSettingsCreateEdit } from './export-settings/export-settings-create-edit.component';
import { AppExportSettingsResolver } from './export-settings/export-settings.resolver';

export const AppExportRoutes: ICustomRoute[] = [
  {
    path: 'export/settings/:id',
    title: 'New Export',
    component: AppExportSettingsCreateEdit,
    resolve: { data: AppExportSettingsResolver },
    data: {
      title: 'Export Settings',
      description: 'Create a new SFTP Export.',
      breadcrumbs: [],
      buttons: []
    }
  }
];
