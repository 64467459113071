import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppMaterialModule } from 'src/app/app-material.module';
import { AppPaginationModule } from 'src/app/shared/tables/pagination/pagination.module';
import { AppErrorDisplayComponent } from 'src/app/shared/validation/error-display.component';
import { AppCalendarCreateEditComponent } from './calendar-day/calendar-day-create-edit/calendar-day-create-edit.component';
import { AppCalendarViewListFilterComponent } from './calendar-view/calendar-view-list-filters/calendar-view-list-filters.component';
import { AppCalendarViewTableComponent } from './calendar-view/calendar-view-table/calendar-view-table.component';
import { AppCalendarViewComponent } from './calendar-view/calendar-view.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AppCalendarViewFullComponent } from './calendar-view/calendar-view-full/calendar-view-full.component';
import { AppMultipleSelectModule } from 'src/app/shared/dropdowns/multi-select/multiple-select.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AppSetUpYear } from './set-up-year/set-up-year.component';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { CalendarDayBellScheduleManageModalComponent } from './calendar-day-bell-schedule-manage-modal/calendar-day-bell-schedule-manage-modal.component';
import { RouterLink } from '@angular/router';
import { CalendarDayScheduledReactionManageModalComponent } from './calendar-day-scheduled-reaction-manage-modal/calendar-day-scheduled-reaction-manage-modal.component';

@NgModule({
  declarations: [
    AppCalendarViewComponent,
    AppCalendarViewListFilterComponent,
    AppCalendarViewTableComponent,
    AppCalendarCreateEditComponent,
    AppCalendarViewFullComponent,
    AppSetUpYear,
    CalendarDayBellScheduleManageModalComponent,
    CalendarDayScheduledReactionManageModalComponent
  ],
  exports: [],
  imports: [
    AppMaterialModule,
    AppMultipleSelectModule,
    FontAwesomeModule,
    AppPaginationModule,
    ReactiveFormsModule,
    AppErrorDisplayComponent,
    FormsModule,
    FullCalendarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DirectiveModule,
    RouterLink
  ]
})
export class AppCalendarModule {}
