import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[options-id]'
})
export class OptionsIdDirective implements OnInit {
  @Input('options-id') groupId: string;
  @Input() option: any;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (environment.environmentName === "prod") return;
    if (this.groupId && this.option != null) {
      this.el.nativeElement.setAttribute('data-test-id', `${this.groupId}_${this.option}`);
    } else {
      console.error('options-id directive requires a group id');
    }
  }
}
