"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultUserRoleNames = void 0;
var DefaultUserRoleNames;
(function (DefaultUserRoleNames) {
    DefaultUserRoleNames["SCHOOL"] = "School Admin";
    DefaultUserRoleNames["TEACHER"] = "Teacher";
    DefaultUserRoleNames["STUDENT"] = "Student";
    DefaultUserRoleNames["GUARDIAN"] = "Guardian";
    DefaultUserRoleNames["GROUP_ADMIN"] = "Group Admin";
    DefaultUserRoleNames["SCHOOL_STAFF"] = "School Staff";
})(DefaultUserRoleNames || (exports.DefaultUserRoleNames = DefaultUserRoleNames = {}));
