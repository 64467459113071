import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { StudentGroupResponseDto } from '@whetstoneeducation/hero-common';
import { Router } from '@angular/router';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-student-groups-list-card',
  templateUrl: './student-groups-list-card.template.html',
  styleUrls: ['./student-groups-list-card.scss']
})
export class AppStudentGroupsListCardComponent extends BaseTableComponent<StudentGroupResponseDto> {
  @Input() public studentGroups: StudentGroupResponseDto[];
  @Output() public studentGroupRemoved: EventEmitter<StudentGroupResponseDto>;

  constructor(
    public router: Router,
    private toastService: AppToastManagerService
  ) {
    super();
    this.studentGroupRemoved = new EventEmitter<StudentGroupResponseDto>();
  }
}
