import { AppSftpAccountListComponent } from './sftp-account-list/sftp-account-list.component';
import { AppSftpAccountsListResolver } from './sftp-account-list/sftp-account-list.resolver';
import { AppSftpSettingsListResolver } from './sftp-settings-list/sftp-settings-list.resolver';
import { AppSftpSummaryComponent } from './sftp-summary/sftp-summary.component';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { ICustomRoute } from '../../app-routing.module';
import { AppSftpImportListResolver } from './sftp-import-list/sftp-import-list.resolver';
import { AppSftpSettingsListComponent } from './sftp-settings-list/sftp-settings-list.component';
import { AppSftpSettingsCreateEdit } from './sftp-settings/sftp-settings-create-edit.component';
import { AppSftpSettingsResolver } from './sftp-settings/sftp-settings.resolver';

export const AppImportRoutes: ICustomRoute[] = [
  {
    path: 'import/summary',
    title: 'Import Summary',
    component: AppSftpSummaryComponent,
    resolve: { data: AppSftpImportListResolver },
    data: {
      title: 'Import',
      description: 'Summary of imports.',
      icon: 'upload',
      buttons: [
        {
          name: 'Accounts',
          action: HeaderButtonAction.LIST_ACCOUNTS,
          icon: 'key',
          style: 'outline-button'
        },
        {
          name: 'Settings',
          action: HeaderButtonAction.LIST_SETTINGS,
          icon: 'gear',
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'sftp/accounts',
    title: 'SFTP Accounts',
    component: AppSftpAccountListComponent,
    resolve: { data: AppSftpAccountsListResolver },
    data: {
      title: 'SFTP Accounts',
      description: 'Manage SFTP accounts.',
      icon: 'key',
      buttons: [
        {
          name: 'Settings',
          action: HeaderButtonAction.LIST_SETTINGS,
          icon: 'gear',
          style: 'outline-button'
        },
        {
          name: 'New Account',
          action: HeaderButtonAction.CREATE,
          icon: 'plus',
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'sftp/settings',
    title: 'SFTP Settings',
    component: AppSftpSettingsListComponent,
    resolve: { data: AppSftpSettingsListResolver },
    data: {
      title: 'SFTP Settings',
      description: 'Manage SFTP settings.',
      icon: 'gear',
      buttons: [
        {
          name: 'Accounts',
          action: HeaderButtonAction.LIST_ACCOUNTS,
          icon: 'key',
          style: 'outline-button'
        },
        {
          name: 'New Import',
          action: HeaderButtonAction.CREATE,
          icon: 'plus',
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'sftp/settings/new',
    title: 'New Import',
    component: AppSftpSettingsCreateEdit,
    resolve: { data: AppSftpSettingsResolver },
    data: {
      title: 'Import Settings',
      description: 'Create a new SFTP import.',
      breadcrumbs: [
        { path: '/import/summary', title: 'Summary' },
        { path: '/sftp/settings', title: 'Settings' }
      ],
      buttons: []
    }
  },
  {
    path: 'sftp/settings/:id',
    title: 'New Import',
    component: AppSftpSettingsCreateEdit,
    resolve: { data: AppSftpSettingsResolver },
    data: {
      title: 'Import Settings',
      description: 'Create a new SFTP import.',
      breadcrumbs: [
        { path: '/import/summary', title: 'Summary' },
        { path: '/sftp/settings', title: 'Settings' }
      ],
      buttons: []
    }
  }
];
