"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authorizeSchool = exports.authorizeSchoolGroup = exports.isAdmin = exports.authorizeSchoolAndGroup = void 0;
const enums_1 = require("../enums");
/**
 * Checks if a user has permission to access a school or district.
 *
 * @param {number | undefined} schoolId - The ID of the school to check access for.
 * @param {number | undefined} districtId - The ID of the district to check access for.
 * @param {LoginResponseDto} loginResponse - The user's login response.
 * @returns {boolean} - `true` if the user has permission to access the requested resource, `false` otherwise.
 */
function authorizeSchoolAndGroup(schoolId, districtId, loginResponse) {
    // Check if user has access to the school group
    if (districtId != null &&
        loginResponse != null &&
        !authorizeSchoolGroup(districtId, loginResponse)) {
        return false;
    }
    // Check if user has access to the school
    if (schoolId != null && !authorizeSchool(schoolId, loginResponse)) {
        return false;
    }
    if (districtId == null && schoolId == null && !isAdmin(loginResponse)) {
        return false;
    }
    // If all checks pass, the user has access to the requested school or district
    return true;
}
exports.authorizeSchoolAndGroup = authorizeSchoolAndGroup;
/**
 * Convenience function to check if a user is an internal admin user.
 * @param {LoginResponseDto} loginResponse - The user's login response.
 */
function isAdmin(loginResponse) {
    return loginResponse.type === enums_1.UserTypeEnum.INTERNAL_ADMIN;
}
exports.isAdmin = isAdmin;
/**
 * Checks if a user has permission to access a school group.
 * Recommended to use this function instead of type === 'admin' checks
 * to keep the logic in one place.
 *
 * @param {number} districtId - The ID of the district to check access for.
 * @param {LoginResponseDto} loginResponse - The user to check access for.
 * @returns {boolean} - `true` if the user has permission to access the requested school group, `false` otherwise.
 */
function authorizeSchoolGroup(districtId, loginResponse) {
    // Check if user is an internal admin user
    if (isAdmin(loginResponse)) {
        return true;
    }
    const userSchoolGroupIds = loginResponse?.schoolGroups.map((sg) => sg.id);
    return userSchoolGroupIds?.includes(districtId) ?? false;
}
exports.authorizeSchoolGroup = authorizeSchoolGroup;
/**
 * Checks if a user has permission to access a school.
 *
 * @param {number} schoolId - The ID of the district to check access for.
 * @param {LoginResponseDto} loginResponse - The user to check access for.
 * @returns {boolean} - `true` if the user has permission to access the requested school, `false` otherwise.
 */
function authorizeSchool(schoolId, loginResponse) {
    // Check if user is an internal admin user
    if (isAdmin(loginResponse)) {
        return true;
    }
    const userSchoolIds = loginResponse?.schoolGroups.flatMap((sg) => sg.schools.map((s) => s.id));
    return userSchoolIds?.includes(schoolId) ?? false;
}
exports.authorizeSchool = authorizeSchool;
