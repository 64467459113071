import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { RouterLink } from '@angular/router';
import { AppBellSchedulesCreateEditComponent } from './bell-schedules-create-edit/bell-schedules-create-edit.component';
import { AppPeriodsListTableComponent } from './periods/periods-list-table/periods-list-table.component';
import { AppBellSchedulesListComponent } from './bell-schedules-list/bell-schedules-list.component';
import { AppBellSchedulesListTableComponent } from './bell-schedules-list/bell-schedules-list-table/bell-schedules-list-table.component';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppBellSchedulePeriodManagerComponent } from './periods/periods-manager/bell-schedule-period-manager.component';
import { AppTablesModule } from '../../shared/tables/tables.module';
import { AppBellSchedulePeriodCreateComponent } from './periods/periods-manager/bell-schedule-period-dialog/bell-schedule-period-create.dialog';
import { AppBellSchedulePeriodTimeComponent } from './bell-schedule-period-time-dialog/bell-schedule-period-time.dialog';
import { AppInputsModule } from '../../shared/inputs/inputs.module';

@NgModule({
  declarations: [
    AppBellSchedulesCreateEditComponent,
    AppPeriodsListTableComponent,
    AppBellSchedulesListComponent,
    AppBellSchedulesListTableComponent,
    AppBellSchedulePeriodManagerComponent,
    AppBellSchedulePeriodCreateComponent,
    AppBellSchedulePeriodTimeComponent
  ],
  exports: [AppBellSchedulesListTableComponent],
  imports: [
    AppMaterialModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AppErrorDisplayComponent,
    FormsModule,
    RouterLink,
    DirectiveModule,
    AppPaginationModule,
    AppTablesModule,
    AppInputsModule
  ]
})
export class AppBellSchedulesModule {}
