"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrinterConfigurationSaveDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const string_transform_1 = require("../../constants/string.transform");
class PrinterConfigurationSaveDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        Object.assign(this, options);
    }
    getFields() {
        return [
            'productId',
            'productName',
            'vendorId',
            'manufacturerName',
            'serialNumber',
            'languageType',
            'userId'
        ];
    }
    productId;
    productName;
    vendorId;
    manufacturerName;
    serialNumber;
    languageType;
    userId;
}
exports.PrinterConfigurationSaveDto = PrinterConfigurationSaveDto;
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PrinterConfigurationSaveDto.prototype, "productId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Transform)(string_transform_1.StringToLowercaseTransform),
    __metadata("design:type", String)
], PrinterConfigurationSaveDto.prototype, "productName", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PrinterConfigurationSaveDto.prototype, "vendorId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Transform)(string_transform_1.StringToLowercaseTransform),
    __metadata("design:type", String)
], PrinterConfigurationSaveDto.prototype, "manufacturerName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], PrinterConfigurationSaveDto.prototype, "serialNumber", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Transform)(string_transform_1.StringToLowercaseTransform),
    __metadata("design:type", String)
], PrinterConfigurationSaveDto.prototype, "languageType", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PrinterConfigurationSaveDto.prototype, "userId", void 0);
