<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <div class="right-filters">
      <button mat-flat-button (click)="exportTable()">
        <fa-icon [icon]="['fas', 'upload']"></fa-icon> Export
      </button>
    </div>
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)">
    </app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="sftp-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <ng-container matColumnDef="{{columns.SCHOOL_GROUP}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.SCHOOL_GROUP}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.schoolGroup?.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.SCHOOL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.SCHOOL}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.school?.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.GROUP_LEVEL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.GROUP_LEVEL}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.isSchoolGroupLevel}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.IMPORT_SUBJECT }}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.IMPORT_SUBJECT }}</mat-header-cell
        >
        <mat-cell *matCellDef="let row"> {{row.importSubject}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.AUTOMATIC_IMPORT_ENABLED}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.AUTOMATIC_IMPORT_ENABLED}}</mat-header-cell
        >
        <mat-cell *matCellDef="let row">
          {{row.automaticImportEnabled}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell *matHeaderCellDef>{{columns.ACTIONS}}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-flat-button
            class="action-button icon-button"
            (click)="editSftpSettings(row)"
            options-id="EDIT_SFTP_SETTINGS">
            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
          </button>
          <!--          <button-->
          <!--            mat-flat-button-->
          <!--            class="action-button icon-button"-->
          <!--            (click)="disableAutomaticImport(row)"-->
          <!--            options-id="DISABLE_AUTOMATIC_IMPORT">-->
          <!--            <fa-icon [icon]="['fas', 'stop']"></fa-icon>-->
          <!--          </button>-->
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)">
    </app-pagination>
  </div>
</div>
