import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IBehaviorCodeListResolverData } from './behavior-codes-list-models/behavior-codes-list-resolver-data.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppBehaviorCodesService } from '../behavior-codes.service';

@Injectable({ providedIn: 'root' })
export class AppBehaviorCodesListResolver extends BaseResolver<IBehaviorCodeListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public behaviorCodesService: AppBehaviorCodesService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<IBehaviorCodeListResolverData> {
    return this.behaviorCodesService.getBehaviorCodesListPageResolverData();
  }
}
