import { Component, OnInit, OnDestroy } from '@angular/core';
import { Event, Router, NavigationEnd } from '@angular/router';
import { LoginResponseDto } from '@whetstoneeducation/hero-common';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { logger } from 'src/app/shared/logger';
import { AppNavbarActionsService } from './navbar-actions.service';
import { AppNavbarConfigService } from './navbar-config.service';
import { INavbarConfig } from './navbar-models/navbar-config.interface';
import { AppNavbarService } from './navbar.service';

/**
 * The main navbar component that should show on most pages under most circumstances.
 */
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.template.html',
  styleUrls: ['./navbar.scss']
})
export class AppNavbarComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  /**
   * The username of the user that is currently logged in.
   */
  public username: string;

  /**
   * The district of the user that is currently logged in.
   */
  public district: string;

  /**
   * Should we show district for this user.
   * Users with one district won't need to see this.
   */
  public showDistrict: boolean;

  /**
   * Whether or not the navbar should be displaying.
   */
  public show: boolean;

  /**
   * Whether or not the collapsible navbar is open.
   */
  public open: boolean;
  /**
   * Should we show our search bar
   */
  public showSearchBar: boolean;

  /**
   * The config we use to display our navbar
   */
  public navbarConfig: INavbarConfig;

  /**
   * References to our subscriptions
   */
  public dataChangedSubscription: Subscription;
  public visibilitySubscription: Subscription;
  public navbarActionsHandlerSubscription: Subscription;
  public navbarActionsSubscription: Subscription;

  /**
   * Default Constructor
   */
  constructor(
    public navbarService: AppNavbarService,
    public router: Router,
    public navbarConfigService: AppNavbarConfigService,
    public navbarActionsService: AppNavbarActionsService,
  ) {
    super();
    this.username = 'Username';
    this.district = 'District';
    this.showDistrict = true;
    this.show = false;
    this.open = false;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.navbarService.setNavbarVisibilityForRoutePath(event.url);
      }
    });
    this.navbarService.shouldCloseNavbar.subscribe(() => {
      this.closeNavbar();
      this.closeSearchBar();
    });
    this.navbarService.shouldCloseNavbarTabs.subscribe(() => {
      this.closeSearchBar();
    });
    this.navbarActionsHandlerSubscription =
      this.navbarActionsService.handleNavbarActions();

  }

  /**
   * Called initially when the components loads.
   */
  public async ngOnInit(): Promise<void> {
    this.handleVisibilityChanged();
    this.handleDataChanged();
  }

  /**
   * Called when the component is unloaded.
   */
  public ngOnDestroy(): void {
    this.visibilitySubscription.unsubscribe();
    this.dataChangedSubscription.unsubscribe();
    this.navbarActionsHandlerSubscription.unsubscribe();
    this.navbarActionsSubscription.unsubscribe();
  }

  /*
   **********************************************
   *            Visibility Methods              *
   **********************************************
   */

  /**
   * Subscribe to the visibility changed event
   */
  public handleVisibilityChanged() {
    this.visibilitySubscription =
      this.navbarService.visibilityChanged.subscribe(
        (value) => {
          this.show = value;

          // If turning on navbar, let's reload all of our data
          if (this.show) {
            void this.getData();
          }
        },
        (err) => {
          logger.error(err);
        }
      );
  }

  /**
   * Toggles the open status of our navbar
   * (Doesn't actually do anything, just records its open/closed status)
   */
  public toggleNavbar(): void {
    this.open = !this.open;
    this.navbarService.closeNavbarTabs();
  }

  /**
   * Close our navbar if it is open
   */
  public closeNavbar() {
    if (this.open) {
      this.open = false;
    }
  }

  /**
   * Close our search bar if it is open
   */
  public closeSearchBar() {
    if (this.showSearchBar) {
      this.showSearchBar = false;
    }
  }

  /*
   **********************************************
   *          Data Load / Reload Methods        *
   **********************************************
   */

  /**
   * Subscribes to the dataChanged event and updates data on changes
   */
  public handleDataChanged() {
    this.dataChangedSubscription = this.navbarService.dataChanged.subscribe(
      () => this.getData()
    );
  }

  /**
   * Get all of the data needed for our navbar
   */
  public async getData() {
    this.setActiveUserInfo();

    const navbarConfigCall = this.navbarConfigService.getNavbarConfig();

    this.navbarConfig = await navbarConfigCall;
  }

  /**
   * Sets the username and district name of the user that is currently logged in.
   */
  private setActiveUserInfo(): void {
    // Retrieve the logged in user if there is one.
    const user: LoginResponseDto = this.StorageManager.getLoggedInUser();
    if (user) {
      this.username = user.firstName + ' ' + user.lastName;
    }
  }

  public goHome() {
    this.router.navigate(['/']);
  }
}
