import { ICustomRoute } from 'src/app/app-routing.module';
import { AuthGuard } from 'src/app/route-guards/auth.guard';
import { HeaderButtonAction } from 'src/app/shared/page-header/header-button';
import { AppCalendarCreateEditComponent } from './calendar-day/calendar-day-create-edit/calendar-day-create-edit.component';
import { AppCalendarCreateEditResolver } from './calendar-day/calendar-day-create-edit/calendar-day-create-edit.resolver';
import { AppCalendarViewComponent } from './calendar-view/calendar-view.component';
import { AppCalendarViewResolver } from './calendar-view/calendar-view.resolver';
import { AppSetUpYear } from './set-up-year/set-up-year.component';

export const AppCalendarDayRoutes: ICustomRoute[] = [
  {
    path: 'calendar',
    title: 'Calendar',
    component: AppCalendarViewComponent,
    resolve: { data: AppCalendarViewResolver },
    canActivate: [AuthGuard]
  },
  {
    path: 'calendar/set-up-year',
    title: 'Set Up Academic Year',
    component: AppSetUpYear,
    canActivate: [AuthGuard],
    data: {
      title: 'Create',
      icon: 'pencil',
      breadcrumbs: [
        {
          path: '/calendar',
          title: 'Calendar'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'success-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  },
  {
    path: 'calendar/:id',
    title: 'Calendar Day',
    component: AppCalendarCreateEditComponent,
    resolve: { data: AppCalendarCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Edit',
      icon: 'pencil',
      breadcrumbs: [
        {
          path: '/calendar',
          title: 'Calendar'
        }
      ]
    }
  }
];
