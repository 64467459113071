import { Injectable } from '@angular/core';
import { EventContentArg } from '@fullcalendar/core';
import { IRelation } from '@whetstoneeducation/hero-common';
import { BaseService } from 'src/app/shared/base-classes/base.service';

@Injectable({
  providedIn: 'root'
})
export class AppCalendarViewHtmlContentService extends BaseService {
  public constructor() {
    super();
  }

  public getHtmlContent(props: EventContentArg): { html: string } {
    const { type } = props.event.extendedProps;
    if (type === 'bellSchedule') {
      return {
        html: this.getStudentBellScheduleHtmlContent(
          props.event.extendedProps.data
        )
      };
    }

    if (type === 'scheduledReaction') {
      return {
        html: this.getStudentScheduledReactionHtmlContent(
          props.event.extendedProps.data
        )
      };
    }
  }

  private getStudentBellScheduleHtmlContent(
    studentBellSchedule: IRelation
  ): string {
    return `<div style="overflow: hidden">
              ${studentBellSchedule.name}
            </div>`;
  }

  private getStudentScheduledReactionHtmlContent(
    scheduledReaction: IRelation
  ): string {
    return `<div style="overflow: hidden">
               ${scheduledReaction.name}
            </div>`;
  }
}
