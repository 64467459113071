import { MatTable } from '@angular/material/table';
import { HtmlParser } from '@whetstoneeducation/hero-common';
import { Parser } from '@json2csv/plainjs';

/**
 * Class used to convert a mat table to a CSV
 */
export class MatTable2CSV {
  /**
   * Convert our table in view to a CSV.
   * - If you want to hide a column from exports add this class to your template
   *     <mat-header-cell *matHeaderCellDef class="hidden-export">Name</mat-header-cell>
   */
  public async convertToCSV(table: MatTable<any>) {
    const tableNativeElement = (table as any)._elementRef.nativeElement;

    // Make sure we are using the right table (if there are two tables on page)
    let tableElement;
    const htmlCollections: HTMLCollectionOf<HTMLElement> =
      document.getElementsByClassName('mat-table') as any;
    for (let index = 0; index < htmlCollections.length; index++) {
      if (htmlCollections[index].innerText === tableNativeElement.innerText) {
        tableElement = htmlCollections[index];
      }
    }

    if (tableElement) {
      const { headerDict, headers } = this.getHeaderDict(tableElement);
      const rows = this.getRows(tableElement, headerDict);
      const opts = { fields: headers };

      try {
        const parser = new Parser(opts);
        const csv = parser.parse(rows);
        const hiddenElement = document.createElement('a');
        hiddenElement.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'export.csv';
        hiddenElement.click();
      } catch (err) {
        throw new Error('Failed to parse csv!');
      }
    } else {
      throw new Error('Could not find table element!');
    }
  }

  /**
   * Get a list of enabled/non-hidden headers for our csv
   */
  private getHeaderDict(tableElement: Element): {
    headerDict: { [columnKey: string]: string };
    headers: string[];
  } {
    const headerDict: { [columnKey: string]: string } = {};
    const headers: string[] = [];

    // Get a list of header rows from our table
    const tableHeaderRowArr =
      tableElement.getElementsByClassName('mat-mdc-header-row');
    const headerRow = tableHeaderRowArr[0];
    const rowColumnArr = headerRow.getElementsByClassName(
      'mat-mdc-header-cell'
    );

    // For each header row, add its column name, if its a valid header
    for (
      let columnIndex = 0;
      columnIndex < rowColumnArr.length;
      columnIndex++
    ) {
      const columnElement = rowColumnArr[columnIndex];

      const classList = columnElement.className.split(/\s+/);
      const hiddenFromExport = classList.includes('hidden-export');
      const foundNameClass = classList.find((classString) =>
        classString.includes('mat-column-')
      );

      if (!hiddenFromExport && foundNameClass) {
        const html = columnElement.innerHTML;
        const columnName = foundNameClass.slice(11);
        const columnText = HtmlParser.stripTextFromHTML(html);

        headerDict[columnName] = columnText;
        headers.push(columnText);
      }
    }
    return {
      headerDict,
      headers,
    };
  }

  /**
   * Get a list of row objects for our csv
   */
  private getRows(
    tableElement: Element,
    headerDict: { [columnKey: string]: string }
  ): Array<{
    [columnName: string]: string;
  }> {
    const rows: Array<{ [columnName: string]: string }> = [];

    // Get a list of rows from our table
    const tableRowCollection =
      tableElement.getElementsByClassName('mat-mdc-row');

    // For each row, create a row object with all of our column data
    for (let rowIndex = 0; rowIndex < tableRowCollection.length; rowIndex++) {
      const tableRowElement = tableRowCollection[rowIndex];

      const row: { [columnName: string]: string } = {};
      const cellCollection =
        tableRowElement.getElementsByClassName('mat-mdc-cell');

      // For each column, figure out its column name && value
      for (
        let cellIndex = 0;
        cellIndex < cellCollection.length;
        cellIndex += 1
      ) {
        const cellElement = cellCollection[cellIndex];

        const classList = cellElement.className.split(/\s+/);
        const foundNameClass = classList.find((classString) =>
          classString.includes('mat-column-')
        );

        if (foundNameClass) {
          const columnKey = foundNameClass.slice(11);
          const columnText = headerDict[columnKey];
          const html = cellElement.innerHTML;
          row[columnText] = HtmlParser.stripTextFromHTML(html);
        }
      }
      rows.push(row);
    }
    return rows;
  }
}
