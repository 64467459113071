import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppBehaviorCodeReactionsService } from '../behavior-code-reactions.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IBehaviorCodeReactionCreateEditResolverData } from '../behavior-code-reactions-models/behavior-code-reaction-create-edit-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppBehaviorCodeReactionCreateEditResolver extends BaseResolver<IBehaviorCodeReactionCreateEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public behaviorCodeReactionsService: AppBehaviorCodeReactionsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IBehaviorCodeReactionCreateEditResolverData> {
    const behaviorCodeId = route.paramMap.get('id');
    const behaviorCodeReactionId = route.paramMap.get('behaviorCodeReactionId');
    return this.behaviorCodeReactionsService.getBehaviorCodeReactionsCreateEditResolverData(
      behaviorCodeId,
      behaviorCodeReactionId
    );
  }
}
