<div class="page-content">
  <form [formGroup]="reactionForm" novalidate>
    <div class="reaction-content">
      <mat-card appearance="outlined" class="reaction-form mat-elevation-z3">
        <mat-card-header>
          <mat-card-title>
            <fa-icon [icon]="['fas', 'tower-observation']"></fa-icon>
            Edit Reaction
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="reaction-form-content">
          <div class="form-row">
            <div class="form-item name-input">
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" />
                <app-error-display
                  [form]="reactionForm"
                  controlName="name"></app-error-display>
              </mat-form-field>
              <small>
                Choose a short but clear name to identify the reaction. These
                will be global and can be applied to any Behavior Code.
              </small>
            </div>

            <div class="form-item pass-message-input">
              <mat-form-field>
                <mat-label>Message On Pass</mat-label>
                <input matInput formControlName="passMessage" />
                <app-error-display
                  [form]="reactionForm"
                  controlName="passMessage"></app-error-display>
              </mat-form-field>
              <small>
                This message will appear on any passes issued with this
                reaction.
              </small>
            </div>
          </div>

          <div class="form-row">
            <div class="form-item">
              <mat-slide-toggle
                [color]="'primary'"
                formControlName="complianceRequired"
                (toggleChange)="reaction.complianceRequired = !reaction.complianceRequired">
                Enable Compliance Reaction
              </mat-slide-toggle>
              <small
                >Turn this ON if this reaction includes an activity that must be
                complied with, such as a detention or an administrative
                meeting.</small
              >
            </div>
          </div>

          <div class="form-row" *ngIf="reaction.complianceRequired">
            <mat-form-field class="compliance-rules-input">
              <mat-label>Day(s) until reaction begin</mat-label>
              <input
                matInput
                formControlName="daysUntilReaction"
                [type]="'number'" />
              <app-error-display
                [form]="reactionForm"
                controlName="daysUntilReaction"></app-error-display>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-slide-toggle
              *ngIf="reaction.complianceRequired"
              [color]="'primary'"
              [checked]="dailyCapacityRequired"
              (change)="toggleDailyCapacityRequired()">
              Set a Limit Number of Students
            </mat-slide-toggle>
          </div>
          <div class="form-row">
            <mat-form-field
              *ngIf="dailyCapacityRequired && reaction.complianceRequired"
              class="compliance-rules-input">
              <mat-label>Limit number of students to</mat-label>
              <input
                matInput
                formControlName="dailyCapacity"
                [type]="'number'" />
              <app-error-display
                [form]="reactionForm"
                controlName="dailyCapacity"></app-error-display>
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="reaction.complianceRequired">
            <button
              mat-flat-button
              class="secondary-button edit-days-button"
              (click)="openEditDaysDialog()">
              Edit Days
            </button>
          </div>

          <!-- TODO: add back when ready for mass update to scheduled -->

          <!-- <div class="form-row">
            <mat-form-field class="full-width-input">
              <mat-label>Add Reactions to these days</mat-label>
              <mat-select formControlName="createScheduledReactions" multiple>
                <mat-option
                  *ngFor="let type of daysOptions"
                  [value]="type.value"
                >
                  {{type.display}}
                </mat-option>
              </mat-select>
              <app-error-display
                [form]="reactionForm"
                controlName="createScheduledReactions"
              ></app-error-display>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="full-width-input">
              <mat-label>Remove Reactions to these days</mat-label>
              <mat-select formControlName="removeScheduledReactions" multiple>
                <mat-option
                  *ngFor="let type of daysOptions"
                  [value]="type.value"
                >
                  {{type.display}}
                </mat-option>
              </mat-select>
              <app-error-display
                [form]="reactionForm"
                controlName="removeScheduledReactions"
              ></app-error-display>
            </mat-form-field>
          </div> -->

          <!-- <app-scheduled-reaction-table
            [dataArr]="scheduledReactions"
            [isLoading]="isLoading"
            [tableFilters]="scheduleReactionsTableFilters"
            (tableFiltersUpdated)="update($event)"
          ></app-scheduled-reaction-table> -->
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>
