import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppPageHeaderButtonComponent } from './page-header-button/page-header-button.component';
import { AppPageHeaderComponent } from './page-header.component';
import { AppPageHeaderService } from './page-header.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { DirectiveModule } from '../directives/directive.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    MatButtonModule,
    DirectiveModule,
  ],
  declarations: [AppPageHeaderComponent, AppPageHeaderButtonComponent],
  providers: [AppPageHeaderService],
  exports: [AppPageHeaderComponent],
})
export class AppPageHeaderModule {}
