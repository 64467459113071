<div class="notification-template-edit">
  <form [formGroup]="templateForm">
    <!-- School Group, School select dropdowns -->
    <ng-container *ngIf="!currentUser.schoolGroupId">
      <mat-form-field class="full-width-input">
        <mat-label>School Group</mat-label>
        <mat-select
          formControlName="schoolGroupId"
          (selectionChange)="schoolGroupChanged($event)">
          <mat-option
            *ngFor="let schoolGroup of schoolGroups"
            [value]="schoolGroup.value">
            {{ schoolGroup.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!currentUser.currentSchoolId">
      <mat-form-field class="full-width-input">
        <mat-label>School</mat-label>
        <mat-select formControlName="schoolId">
          <mat-option *ngFor="let school of schools" [value]="school.value">
            {{ school.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <mat-form-field class="full-width-input">
      <mat-label>Template Name</mat-label>
      <input matInput formControlName="name" placeholder="Enter name" />
    </mat-form-field>
    <!-- Description -->
    <mat-form-field class="full-width-input">
      <mat-label>Description</mat-label>
      <input
        matInput
        formControlName="description"
        placeholder="Enter description" />
    </mat-form-field>
    <mat-form-field class="full-width-input">
      <mat-label>Body type</mat-label>
      <mat-select formControlName="bodyType">
        <mat-option *ngFor="let bodyType of bodyTypes" [value]="bodyType">
          {{ bodyType }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="full-width-input">
      <mat-label>Notification Type</mat-label>
      <mat-select
        (selectionChange)="notificationTypeChanged($event)"
        formControlName="notificationType">
        <mat-option
          *ngFor="let notificationType of notificationTypes"
          [value]="notificationType">
          {{ notificationType }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-chip-set class="template-variable-chip-set">
      <mat-chip
        *ngFor="let variable of availableVariables"
        class="variable-chip"
        (click)="insertSubjectVariable(variable)"
        >{{variable}}</mat-chip
      >
    </mat-chip-set>
    <mat-form-field class="full-width-input">
      <mat-label>Subject</mat-label>
      <input
        #subjectInput
        matInput
        formControlName="subject"
        placeholder="Enter subject" />
    </mat-form-field>
    <mat-chip-set class="template-variable-chip-set">
      <mat-chip
        *ngFor="let variable of availableVariables"
        class="variable-chip"
        (click)="insertBodyVariable(variable)"
        >{{variable}}</mat-chip
      >
    </mat-chip-set>
    <editor
      id="bodyInput"
      formControlName="body"
      [init]="{ plugins: 'lists link table help wordcount', base_url: '/tinymce', suffix: '.min', promotion: false}"></editor>
  </form>
</div>
