import { DateUtil } from "@whetstoneeducation/hero-common";
import { ReactionPrintBuilder } from "./behavior-code-reaction-print-builder";
import { ZplCommands } from "../../../shared/printer/commands/zpl";

export class ReactionPrintZplBuilder extends ReactionPrintBuilder {

  constructor() {
    super();
  }

  public startCommand(): string {
    return ZplCommands.start + ZplCommands.endline;
  }

  public finishCommand(): string {
    return ZplCommands.finish;
  }

  public buildHeader(): ReactionPrintZplBuilder {
    this.addCommand(ZplCommands.change_font(0, 40))
      .addCommand(ZplCommands.label_width(800))
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 800, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 50))
      .addCommand(ZplCommands.field_data(this.data.schoolName))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildSubHeader(): ReactionPrintBuilder {
    this.addCommand(ZplCommands.change_font(0, 28))
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 800, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 90))
      .addCommand(ZplCommands.field_data(this.data.behaviorCodeName))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildStudentIdEntries(): ReactionPrintBuilder {
    this.addCommand(ZplCommands.change_font(0, 15))
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 130))
      .addCommand(ZplCommands.field_data('Student ID#:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 130))
      .addCommand(ZplCommands.field_data(this.getStudentId()))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(350, 130))
      .addCommand(ZplCommands.field_data('Entries:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(550, 130))
      .addCommand(ZplCommands.field_data(this.data.behaviorEntries))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildStudentName(): ReactionPrintBuilder {
    const [firstName, lastName] = this.getStudentNames();

    this.addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 150))
      .addCommand(ZplCommands.field_data('First Name:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 150))
      .addCommand(ZplCommands.field_data(firstName))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(350, 150))
      .addCommand(ZplCommands.field_data('Last Name:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(550, 150))
      .addCommand(ZplCommands.field_data(lastName))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildEntryDateTime(): ReactionPrintBuilder {
    this.addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 170))
      .addCommand(ZplCommands.field_data('Entry Time:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 170))
      .addCommand(ZplCommands.field_data(DateUtil.convertTimestampToDisplayTime(this.data.behaviorEntryDate)))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(350, 170))
      .addCommand(ZplCommands.field_data('Entry Date:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(550, 170))
      .addCommand(ZplCommands.field_data(DateUtil.convertTimestampToReadableDate(this.data.behaviorEntryDate)))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildPeriod(): ReactionPrintBuilder {
    this.addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 190))
      .addCommand(ZplCommands.field_data('During:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 190))
      .addCommand(ZplCommands.field_data(this.data.period ?? 'N/A'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline)
    return this;
  }

  public buildActionTypeDate(): ReactionPrintBuilder {
    this.addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 210))
      .addCommand(ZplCommands.field_data('Action Type:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 210))
      .addCommand(ZplCommands.field_data(this.data.behaviorReactionName))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(null, 230))
      .addCommand(ZplCommands.field_data('Action Date:'))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 200, 5, 0))
      .addCommand(ZplCommands.field_origin(200, 230))
      .addCommand(ZplCommands.field_data(DateUtil.convertTimestampToReadableDate(this.data.behaviorReactionDate)))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
    return this;
  }

  public buildPoints(): ReactionPrintBuilder {
    this.addCommand(ZplCommands.field_block('center', 400, 5, 0))
      .addCommand(ZplCommands.field_origin(100, 300))
      .addCommand(ZplCommands.field_data(`Points: ${this.data.reactionPoints}`))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.field_block('center', 400, 5, 0))
      .addCommand(ZplCommands.field_origin(250, 300))
      .addCommand(ZplCommands.field_data(`Total Points: ${this.data.studentTotalPoints}`))
      .addCommand(ZplCommands.field_separator)
      .addCommand(ZplCommands.endline)
      .addCommand(ZplCommands.endline);
    return this;
  }

  public buildBarcode(): ReactionPrintBuilder {
    const studentExtId = this.data.studentExtId;

    if (studentExtId && studentExtId.length) {
      const initialXAxis = 315;
      const movePoints = studentExtId.length * 10;
      const xOrigin = initialXAxis - movePoints < 0 ? 0 : initialXAxis - movePoints;

      this.addCommand(ZplCommands.field_origin(xOrigin, 325))
        .addCommand(ZplCommands.barcode(125))
        .addCommand(ZplCommands.field_data(studentExtId))
        .addCommand(ZplCommands.field_separator);
    }

    return this;
  }

}
