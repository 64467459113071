import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { ISftpImportListResolverData } from './sftp-import-list-models/sftp-import-list-resolver-data.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppSftpService } from '../sftp.service';

@Injectable({ providedIn: 'root' })
export class AppSftpImportListResolver extends BaseResolver<ISftpImportListResolverData> {
  constructor(
    resolverErrorHandler: AppResolverErrorHandlerService,
    public sftpService: AppSftpService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<ISftpImportListResolverData> {
    return this.sftpService.getSftpImportListPageResolverData();
  }
}
