<div class="student-dashboard-container">
  <div *ngIf="!isLoading">
    <div
      class="student-last-month-history-container"
      tabindex="0"
      aria-label="Last 30 days summary"
      role="group">
      <span>Over the last 30 days, you had</span>
      <span
        tabindex="0"
        aria-label="Over the last 30 days you had no tracks"
        role="complementary"
        *ngIf="!historyData.lastMonthHistory.length">
        no tracks</span
      >
      <span
        tabindex="0"
        [attr.aria-label]="'Over the last 30 days you had ' + formatLastHistoryElement(type, last, historyData.lastMonthHistory?.length)"
        role="complementary"
        *ngFor="let type of historyData.lastMonthHistory; let last = last;"
        [style.color]="getCodeTypeColor(type.name)">
        {{formatLastHistoryElement(type, last,
        historyData.lastMonthHistory?.length) }}</span
      >
    </div>
    <div
      class="summary-container"
      tabindex="0"
      aria-label="Totals Summary"
      role="group">
      <div
        class="blue-card-container"
        tabindex="0"
        [attr.aria-label]="(historyData.summary.totalPoints > -1 ? '+' : '') + (historyData.summary.totalPoints || 0) + ' Total Points'"
        role="complementary">
        <span
          >{{historyData.summary.totalPoints > -1 ? '+' :
          ''}}{{historyData.summary.totalPoints || 0 }}</span
        >
        <span>Total Points</span>
      </div>
      <div
        class="blue-card-container"
        tabindex="0"
        [attr.aria-label]="historyData.summary.behaviorTracks + ' Total Behavior Tracks'"
        role="complementary">
        <span>{{historyData.summary.behaviorTracks}}</span>
        <span>Behavior Tracks</span>
      </div>
      <div
        class="blue-card-container"
        tabindex="0"
        [attr.aria-label]="historyData.summary.scheduledReactions + ' Total Actions'"
        role="complementary">
        <span>{{historyData.summary.scheduledReactions}}</span>
        <span>Actions</span>
      </div>
    </div>
    <div class="student-history-behavior-cards">
      <mat-card
        appearance="outlined"
        class="student-profile-detail"
        tabindex="0"
        aria-label="Latest Behavior Tracks"
        role="group">
        <mat-card-header class="button-card-header">
          <div class="header-title-container">
            <h2>LATEST BEHAVIOR TRACKS</h2>
          </div>
        </mat-card-header>
        <mat-card-content class="card-content">
          <div class="last-tracks">
            <div
              tabindex="0"
              role="complementary"
              [ngClass]="{
                'blue-card-container': track.points > -1,
                'red-card-container': track.points < 0}"
              [attr.aria-label]="(track.points > -1 ? '+' : '') + track.points + ' ' + track.behaviorCodeName + ' on ' + convertToDisplayDateTime(track.date)"
              *ngFor="let track of historyData.latestBehaviorTracks">
              <span
                >{{track.points > -1 ? '+' : ''}}{{track.points}}
                {{track.behaviorCodeName}}</span
              >
              <span>{{convertToDisplayDateTime(track.date)}}</span>
            </div>
            <span
              tabindex="0"
              aria-label="No behavior tracks yet"
              role="complementary"
              class="not-actions"
              *ngIf="!historyData.latestBehaviorTracks.length"
              >No behavior tracks yet</span
            >
          </div>
          <a
            tabindex="0"
            aria-label="View All Behavior Tracks"
            role="button"
            (keypress)="redirectToBehaviorHistory()"
            class="redirect-link"
            (click)="redirectToBehaviorHistory()">
            <span>View All Behavior Tracks</span>
            <fa-icon [icon]="['fas', 'angle-right']" size="lg"></fa-icon>
          </a>
        </mat-card-content>
      </mat-card>
      <mat-card
        aria-label="Latest Actions"
        tabindex="0"
        role="group"
        appearance="outlined"
        class="student-profile-detail"
        [style.height.px]="null">
        <mat-card-header class="button-card-header">
          <div class="header-title-container">
            <h2>LATEST ACTIONS</h2>
          </div>
        </mat-card-header>
        <mat-card-content class="card-content">
          <div class="student-last-actions-history-container">
            <span
              tabindex="0"
              aria-label="Upcoming Actions"
              role="complementary"
              class="last-actions-title"
              >Upcoming</span
            >
            <div
              tabindex="0"
              role="complementary"
              [attr.aria-label]="historyData.upcomingAction.name + ' on ' + convertTimestampToReadableDate(historyData.upcomingAction?.date)"
              class="red-card-container last-actions"
              *ngIf="!!historyData.upcomingAction">
              <span>{{historyData.upcomingAction.name}}</span>
              <span
                >{{convertTimestampToReadableDate(historyData.upcomingAction.date)}}</span
              >
            </div>
            <span
              tabindex="0"
              aria-label="No upcoming actions yet"
              role="complementary"
              class="not-actions"
              *ngIf="!historyData.upcomingAction"
              >No upcoming actions yet</span
            >
            <span
              tabindex="0"
              aria-label="History Actions"
              role="complementary"
              class="last-actions-title"
              [style.margin-top.px]="10"
              >History</span
            >
            <span
              tabindex="0"
              aria-label="No reactions yet"
              role="complementary"
              class="not-actions"
              *ngIf="!historyData.lastActions.length"
              >No reactions yet</span
            >
            <div
              tabindex="0"
              [attr.aria-label]="action.name + ' on ' + (action.compliedDate ? 'Complied on ' +  convertToDisplayDate(action.compliedDate) : convertToDisplayDateTime(action.date))"
              role="complementary"
              class="last-actions"
              [ngClass]="{ 'red-card-container': !action.compliedDate, 'blue-card-container': !!action.compliedDate }"
              *ngFor="let action of historyData.lastActions">
              <span>{{action.name}}</span>
              <span
                >{{action.compliedDate ? 'Complied on ' +
                convertToDisplayDate(action.compliedDate) :
                convertToDisplayDateTime(action.date)}}</span
              >
            </div>
          </div>
          <a
            tabindex="0"
            aria-label="View All Actions"
            role="button"
            class="redirect-link">
            <span>View All Actions</span>
            <fa-icon [icon]="['fas', 'angle-right']" size="lg"></fa-icon>
          </a>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
