import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSftpService } from '../sftp.service';
import {
  SftpAccountCreateDto,
  SftpAccountExistingDto
} from '@whetstoneeducation/hero-common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  dtoToFormGroup,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { SftpAccountCredentialsBase } from '../sftp-account-credentials.base';

@Component({
  selector: 'app-sftp-account-create',
  templateUrl: './sftp-account-create.template.html',
  styleUrls: ['./sftp-account-create.scss']
})
export class AppSftpAccountCreateComponent
  extends SftpAccountCredentialsBase
  implements AfterViewInit, OnDestroy
{
  public filterForm: FormGroup;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AppSftpAccountCreateComponent>,
    public readonly route: ActivatedRoute,
    public readonly toastService: AppToastManagerService,
    sftpService: AppSftpService,
    formBuilder: FormBuilder
  ) {
    super(sftpService);
    this.loadData();
    this.filterForm = dtoToFormGroup(this.accountFilters, formBuilder, {
      updateOn: 'change'
    });
  }

  ngAfterViewInit(): void {
    this.filterForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        // copy filters to lastFilters
        this.lastAccountFilters = new SftpAccountExistingDto(
          this.accountFilters
        );
        this.accountFilters = await validateAndGetValue<SftpAccountExistingDto>(
          this.filterForm,
          SftpAccountExistingDto
        );
        if (
          this.lastAccountFilters.schoolGroupId !==
          this.accountFilters.schoolGroupId
        ) {
          super.schoolGroupChanged();
        }
        const schoolSelect = this.filterForm.get('schoolId');
        if (schoolSelect && !schoolSelect.value) {
          this.accountFilters.schoolId = null;
          this.filtersReady = false;
        }
        if (this.accountFilters.groupLevel) {
          schoolSelect.setValue(null, { emitEvent: false });
          this.accountFilters.schoolId = null;
        }
        if (
          this.filterForm.valid &&
          ((this.accountFilters.schoolGroupId &&
            this.accountFilters.schoolId) ||
            (this.accountFilters.groupLevel &&
              this.accountFilters.schoolGroupId))
        ) {
          this.filtersReady = true;
          await this.findExistingAccount();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    super.loadData();
  }

  public async createAccount(): Promise<void> {
    this.isLoading = true;
    const accountInformation = new SftpAccountCreateDto({
      schoolId: this.accountFilters.schoolId,
      schoolGroupId: this.accountFilters.schoolGroupId,
      isSchoolGroupLevel: this.accountFilters.groupLevel
    });
    this.accountCredentials =
      await this.sftpService.createSftpAccount(accountInformation);
    this.isLoading = false;
    if (this.accountCredentials?.accountId) {
      this.toastService.success('SFTP account created successfully');
    } else {
      this.toastService.error('Error creating SFTP account');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
