"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CourseScheduleImportDefaultMapping = exports.CourseScheduleImportColumns = exports.UserImportDefaultMapping = exports.UserImportColumns = exports.StudentImportDefaultMapping = exports.StudentImportColumns = void 0;
const dtos_1 = require("../dtos");
var StudentImportColumns;
(function (StudentImportColumns) {
    StudentImportColumns["STUDENT_ID"] = "studentId";
    StudentImportColumns["FIRST_NAME"] = "firstName";
    StudentImportColumns["LAST_NAME"] = "lastName";
    StudentImportColumns["GRADE"] = "grade";
    StudentImportColumns["MIDDLE_INITIAL"] = "middleInitial";
    StudentImportColumns["BIRTH_DATE"] = "birthDate";
    StudentImportColumns["ADDRESS"] = "address";
    StudentImportColumns["CITY"] = "city";
    StudentImportColumns["STATE"] = "state";
    StudentImportColumns["COUNTRY"] = "country";
    StudentImportColumns["ZIP"] = "zip";
    StudentImportColumns["GENDER"] = "gender";
    StudentImportColumns["ETHNIC_GROUP"] = "ethnicGroup";
    StudentImportColumns["STUDENT_EMAIL"] = "studentEmail";
    StudentImportColumns["STUDENT_LANGUAGE"] = "studentLanguage";
    StudentImportColumns["TEACHER"] = "teacher";
    StudentImportColumns["HOME_PHONE"] = "homePhone";
    StudentImportColumns["WORK_PHONE"] = "workPhone";
    StudentImportColumns["CONTACT_PHONE"] = "contactPhone";
    StudentImportColumns["GUARDIAN"] = "guardian";
    StudentImportColumns["GUARDIAN_EMAIL"] = "guardianEmail";
    StudentImportColumns["EMERGENCY_CONTACT"] = "emergencyContact";
    StudentImportColumns["NOTES"] = "notes";
    StudentImportColumns["HOMEROOM"] = "homeroom";
    StudentImportColumns["ACTIVE"] = "active";
    StudentImportColumns["SCHOOL_ID"] = "schoolId";
    StudentImportColumns["STUDENT_GROUP"] = "studentGroup";
    // VEHICLE_ID = 'vehicleId',
    StudentImportColumns["REFERENCE_ID"] = "referenceId";
    // PROXY = 'proxy',
    // RFID_ICLASS = 'rfidIclass',
    // FLAG_MESSAGE = 'flagMessage',
    // PICTURE = 'picture',
    StudentImportColumns["SEND_LETTERS_VIA_EMAIL"] = "sendLettersViaEmail";
    // SMALL_PICTURE = 'smallPicture'
})(StudentImportColumns || (exports.StudentImportColumns = StudentImportColumns = {}));
exports.StudentImportDefaultMapping = new dtos_1.ImportMappingConfiguration({
    hasHeaderRow: true,
    quotedColumns: true,
    availableColumns: Object.keys(StudentImportColumns).map((key) => StudentImportColumns[key]),
    requiredColumns: [
        StudentImportColumns.STUDENT_ID,
        StudentImportColumns.FIRST_NAME,
        StudentImportColumns.LAST_NAME,
        StudentImportColumns.GRADE
    ],
    mapping: {}
});
var UserImportColumns;
(function (UserImportColumns) {
    UserImportColumns["EMAIL"] = "email";
    UserImportColumns["FIRST_NAME"] = "firstName";
    UserImportColumns["LAST_NAME"] = "lastName";
    UserImportColumns["TYPE"] = "type";
    UserImportColumns["ROLE"] = "role";
    UserImportColumns["SCHOOL_ID"] = "schoolId";
    UserImportColumns["INSTRUCTOR_CODE"] = "instructorCode";
    UserImportColumns["ADDRESS"] = "address";
    UserImportColumns["CITY"] = "city";
    UserImportColumns["STATE"] = "state";
    UserImportColumns["ZIP"] = "zip";
    UserImportColumns["PHONE"] = "phone";
    UserImportColumns["HOMEPAGE"] = "homepage";
    UserImportColumns["TIMEZONE"] = "timezone";
})(UserImportColumns || (exports.UserImportColumns = UserImportColumns = {}));
exports.UserImportDefaultMapping = new dtos_1.ImportMappingConfiguration({
    hasHeaderRow: true,
    quotedColumns: true,
    availableColumns: Object.keys(UserImportColumns).map((key) => UserImportColumns[key]),
    requiredColumns: [
        UserImportColumns.EMAIL,
        UserImportColumns.FIRST_NAME,
        UserImportColumns.LAST_NAME,
        UserImportColumns.TYPE,
        UserImportColumns.ROLE,
        UserImportColumns.INSTRUCTOR_CODE
    ],
    mapping: {}
});
var CourseScheduleImportColumns;
(function (CourseScheduleImportColumns) {
    CourseScheduleImportColumns["PERIOD"] = "period";
    CourseScheduleImportColumns["COURSE_CODE"] = "courseCode";
    CourseScheduleImportColumns["COURSE_DESCRIPTION"] = "courseDescription";
    CourseScheduleImportColumns["STUDENT_ID"] = "studentExtId";
    CourseScheduleImportColumns["REFERENCE_ID"] = "referenceId";
    CourseScheduleImportColumns["TERM"] = "term";
    CourseScheduleImportColumns["COURSE_NUMBER"] = "courseNumber";
    CourseScheduleImportColumns["SCHOOL_ID"] = "schoolId";
    CourseScheduleImportColumns["INSTRUCTOR_FIRST_NAME"] = "instructorFirstName";
    CourseScheduleImportColumns["INSTRUCTOR_LAST_NAME"] = "instructorLastName";
    CourseScheduleImportColumns["SECTION"] = "section";
    CourseScheduleImportColumns["INSTRUCTOR_CODE"] = "instructorCode";
    CourseScheduleImportColumns["DAY"] = "day";
})(CourseScheduleImportColumns || (exports.CourseScheduleImportColumns = CourseScheduleImportColumns = {}));
exports.CourseScheduleImportDefaultMapping = new dtos_1.ImportMappingConfiguration({
    hasHeaderRow: true,
    quotedColumns: true,
    availableColumns: Object.keys(CourseScheduleImportColumns).map((key) => CourseScheduleImportColumns[key]),
    requiredColumns: [
        CourseScheduleImportColumns.PERIOD,
        CourseScheduleImportColumns.STUDENT_ID,
        CourseScheduleImportColumns.TERM,
        CourseScheduleImportColumns.INSTRUCTOR_CODE
    ],
    mapping: {}
});
