import { ICustomRoute } from '../../app-routing.module';
import { AppBellSchedulesCreateEditComponent } from './bell-schedules-create-edit/bell-schedules-create-edit.component';
import { AuthGuard } from '../../route-guards/auth.guard';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { AppBellSchedulesCreateEditResolver } from './bell-schedules-create-edit/bell-schedules-create-edit.resolver';
import { AppBellSchedulesListComponent } from './bell-schedules-list/bell-schedules-list.component';
import { AppBellSchedulesListResolver } from './bell-schedules-list/bell-schedules-list.resolver';
import { AppBellSchedulePeriodManagerComponent } from './periods/periods-manager/bell-schedule-period-manager.component';
import { AppBellSchedulePeriodManagerResolver } from './periods/periods-manager/bell-schedule-period-manager.resolver';

export const AppBellSchedulesRoutes: ICustomRoute[] = [
  {
    path: 'bell-schedules/:id',
    title: 'Bell Schedule',
    component: AppBellSchedulesCreateEditComponent,
    resolve: { data: AppBellSchedulesCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Edit',
      icon: 'pencil',
      breadcrumbs: [
        {
          path: 'bell-schedules',
          title: 'Bell Schedules'
        }
      ],
      buttons: [
        {
          name: 'Manage Periods',
          normalizedRoute: '/bell-schedule-period-manager',
          style: 'outline-button'
        },
        {
          name: 'Save',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  },
  {
    path: 'bell-schedules',
    title: 'Bell Schedules',
    component: AppBellSchedulesListComponent,
    resolve: { data: AppBellSchedulesListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Bell Schedules',
      buttons: [
        {
          name: 'Manage Periods',
          normalizedRoute: '/bell-schedule-period-manager',
          style: 'outline-button'
        },
        {
          name: 'Bell Schedule',
          normalizedRoute: '/bell-schedules/new',
          icon: 'plus-circle',
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'bell-schedule-period-manager',
    title: 'Periods',
    component: AppBellSchedulePeriodManagerComponent,
    resolve: { data: AppBellSchedulePeriodManagerResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Periods',
      buttons: [
        {
          name: 'Back',
          style: 'outline-button',
          action: HeaderButtonAction.BACK
        },
        {
          name: 'Clean Up',
          style: 'outline-button',
          action: HeaderButtonAction.CLEAR
        },
        {
          name: 'Create Period',
          action: HeaderButtonAction.CREATE,
          style: 'secondary-button'
        }
      ]
    }
  }
];
