import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppRolesServices } from '../roles.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  IDisplayData,
  IPrivilege,
  IPrivilegeCategory,
  PrivilegeCategory,
  PrivilegeDict,
  PrivilegeEnum,
  PrivilegeSection,
  RoleCreateDto,
  RoleUpdateDto,
  SettingsName
} from '@whetstoneeducation/hero-common';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { FormBuilder, FormGroup } from '@angular/forms';
import { plainToInstance } from 'class-transformer';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { IRoleCreateEditResolverDataInterface } from './role-create-edit-resolver-data.interface';

@Component({
  selector: 'app-role-create-edit',
  templateUrl: './role-create-edit.template.html',
  styleUrls: ['./role-create-edit.styles.scss']
})
export class AppRoleCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  public role: RoleCreateDto | RoleUpdateDto;
  public roleForm: FormGroup;
  private subscriptions: Subscription[] = [];
  public isUpdate: boolean;
  public schools: IDisplayData[];
  public privileges: IDisplayData[] = Object.values(PrivilegeEnum).map(
    (value) => ({
      value,
      display: value
    })
  );
  public userTypeOptions: IDisplayData[] = [];
  public behaviorCodeTypeOptions: IDisplayData[] = [];

  public privilegeCategories: {
    [key: string]: {
      [key: string]: IPrivilegeCategory;
    };
  };
  public privilegeCategoryOptions: IDisplayData[];
  public selectedPrivilegeCategory: PrivilegeCategory =
    PrivilegeCategory.CONFIGURATION;

  constructor(
    public formBuilder: FormBuilder,
    private toastManager: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    private pageHeaderService: AppPageHeaderService,
    private rolesService: AppRolesServices
  ) {
    super();
    this.setupSaveButton();

    this.loadData(this.route.snapshot.data.data);
    const user = this.StorageManager.getLoggedInUser();
    if (user.schoolGroupId && !user.currentSchoolId) {
      this.schools = user.schoolGroups[0].schools.map((s) => ({
        value: s.id,
        display: s.name
      }));
    } else if (user.currentSchoolId) {
      // schools list is single school, found on the user schoolGroups relation via currentSchoolId
      const school = user.schoolGroups[0].schools.find(
        (s) => s.id === user.currentSchoolId
      );
      this.schools = [{ value: school.id, display: school.name }];
    } else if (!user.schoolGroupId && !user.currentSchoolId) {
      this.schools = user.schoolGroups
        .map((sg) => sg.schools)
        .reduce((acc, val) => acc.concat(val), [])
        .map((s) => ({ value: s.id, display: s.name }));
    }
  }
  ngOnInit(): void {
    this.userTypeOptions = this.getUserTypeDisplayOptions();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.roleForm.valueChanges.subscribe(async () => {
        if (this.isUpdate)
          this.role = await validateAndGetValue<RoleUpdateDto>(
            this.roleForm,
            RoleUpdateDto
          );
        else
          this.role = await validateAndGetValue<RoleCreateDto>(
            this.roleForm,
            RoleCreateDto
          );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  loadData({ role, behaviorCodeTypes }: IRoleCreateEditResolverDataInterface) {
    try {
      this.behaviorCodeTypeOptions = behaviorCodeTypes;
      if (!role.id) {
        this.isUpdate = false;
        this.role = plainToInstance(RoleCreateDto, role);
      } else {
        this.isUpdate = true;
        this.role = new RoleUpdateDto().mapFields(role);
      }

      this.roleForm = dtoToFormGroup(this.role, this.formBuilder);
      this.roleForm.controls['behaviorCodeTypes'].setValue(
        role.behaviorCodeTypes
          ? role.behaviorCodeTypes.map((bT) => bT.id)
          : null
      );

      this.roleForm.removeControl('privileges');

      // add controls that are missing from update DTO and disable them
      if (this.isUpdate) {
        this.roleForm.addControl(
          'userType',
          this.formBuilder.control(role.userType)
        );

        this.roleForm.get('schoolId').disable();
        this.roleForm.get('userType').disable();
      }
      const privileges = role.privileges;

      this.setUpPrivilegeCategories(privileges);
    } catch (e) {
      this.toastManager.error('Error loading role.');
    }
  }

  private setUpPrivilegeCategories(selectedPrivileges: IPrivilege[]) {
    const selectedPrivilegesNames = selectedPrivileges.map(
      (privilege) => privilege.name
    );
    this.privilegeCategories = Object.values(PrivilegeDict).reduce(
      (acc, privilege) => {
        const category = privilege.category;
        if (!category) return acc;
        if (!acc[category]) {
          if (privilege.section) {
            acc[category] = {
              [privilege.section]: {
                name: privilege.section,
                checked: selectedPrivilegesNames.includes(
                  privilege.name as PrivilegeEnum
                ),
                section: privilege.section,
                privileges: [
                  {
                    value: privilege.name,
                    display: privilege.display,
                    checked: selectedPrivilegesNames.includes(
                      privilege.name as PrivilegeEnum
                    ),
                    description: privilege.description
                  }
                ]
              }
            };
          }
        } else {
          if (privilege.section) {
            if (!acc[category][privilege.section]) {
              acc[category][privilege.section] = {
                name: privilege.section,
                checked: selectedPrivilegesNames.includes(
                  privilege.name as PrivilegeEnum
                ),
                section: privilege.section,
                privileges: [
                  {
                    value: privilege.name,
                    display: privilege.display,
                    checked: selectedPrivilegesNames.includes(
                      privilege.name as PrivilegeEnum
                    ),
                    description: privilege.description
                  }
                ]
              };
            } else {
              acc[category][privilege.section].privileges.push({
                value: privilege.name,
                display: privilege.display,
                checked: selectedPrivilegesNames.includes(
                  privilege.name as PrivilegeEnum
                ),
                description: privilege.description
              });
              acc[category][privilege.section].checked = acc[category][
                privilege.section
              ].privileges.reduce((acc, val) => acc && val.checked, true);
            }
          }
        }
        return acc;
      },
      {}
    );
    this.privilegeCategoryOptions = Object.keys(this.privilegeCategories)
      .map((category) => ({
        value: category,
        display: category
      }))
      .sort((a, b) => a.display.localeCompare(b.display));
  }

  private setupSaveButton() {
    this.subscriptions.push(
      this.pageHeaderService.buttonAction$.subscribe(async (action) => {
        if (action === HeaderButtonAction.SAVE && formCanSave(this.roleForm)) {
          await this.saveRole();
          await this.router.navigate(['roles']);
        }
        if (action === HeaderButtonAction.CANCEL) {
          await this.router.navigate(['roles']);
        }
      })
    );
  }

  private async saveRole() {
    try {
      this.role.privileges = this.getPermissionsToSave();
      if (this.role instanceof RoleUpdateDto) {
        await this.rolesService.updateRole(this.role);
      } else {
        await this.rolesService.createRole(this.role);
        await this.router.navigate(['roles']);
      }
      this.toastManager.success('Role saved.');
    } catch (e) {
      this.toastManager.error('Error saving role.');
    }
  }

  public onCategoryChange(category: PrivilegeCategory) {
    this.selectedPrivilegeCategory = category;
  }

  public categoryIsChecked() {
    return Object.values(
      this.privilegeCategories[this.selectedPrivilegeCategory]
    ).reduce((acc, val) => acc && val.checked, true);
  }

  public setAllCategory(checked: boolean) {
    Object.values(
      this.privilegeCategories[this.selectedPrivilegeCategory]
    ).forEach((section) => {
      section.checked = checked;
      section.privileges.forEach((p) => (p.checked = checked));
    });
  }

  public sectionIsChecked(section: string) {
    return this.privilegeCategories[this.selectedPrivilegeCategory][
      section
    ].privileges.reduce((acc, val) => acc && val.checked, true);
  }

  public checkSettings(privilegeCategory: IPrivilegeCategory) {
    if (privilegeCategory.section === PrivilegeSection.INCIDENT) {
      return this.hasSetting(SettingsName.INCIDENT);
    }
    return true;
  }

  public setAllSection(checked: boolean, section: string) {
    this.privilegeCategories[this.selectedPrivilegeCategory][section].checked =
      checked;
    this.privilegeCategories[this.selectedPrivilegeCategory][
      section
    ].privileges.forEach((p) => (p.checked = checked));
  }

  public sectionsToDisplay() {
    return Object.values(
      this.privilegeCategories[this.selectedPrivilegeCategory]
    );
  }

  private getPermissionsToSave() {
    const permissions: PrivilegeEnum[] = [PrivilegeEnum.DEFAULT];
    Object.values(this.privilegeCategories).forEach((category) => {
      Object.values(category).forEach((section) => {
        section.privileges.forEach((privilege) => {
          if (privilege.checked) {
            permissions.push(privilege.value as PrivilegeEnum);
          }
        });
      });
    });

    return permissions;
  }

  protected readonly Object = Object;
}
