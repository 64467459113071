"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportStatus = void 0;
var ImportStatus;
(function (ImportStatus) {
    ImportStatus["PENDING"] = "pending";
    ImportStatus["PROCESSING"] = "processing";
    ImportStatus["COMPLETED"] = "completed";
    ImportStatus["COMPLETED_WITH_ERRORS"] = "complete-with-errors";
    ImportStatus["FAILED"] = "Failed";
    ImportStatus["FAILED_WITH_ERRORS"] = "failed-with-errors";
    ImportStatus["CANCELLED"] = "cancelled";
})(ImportStatus || (exports.ImportStatus = ImportStatus = {}));
