import { Component } from "@angular/core";
import { AppBehaviorCodeEntryCreateModalComponent } from "../../behavior-code-entry/behavior-code-entry-create-modal/behavior-code-entry-create-modal.component";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject, takeUntil } from "rxjs";
import { AppToastManagerService } from "../../../shared/services/toast-manager.service";
import { AppStudentsService } from "../students.service";

@Component({
  selector: 'app-bulk-student-profile-photo-upload',
  templateUrl: './bulk-student-profile-photo-upload.template.html',
  styleUrls: ['./bulk-student-profile-photo-upload.scss']
})
export class BulkStudentProfilePhotoUploadComponent {

  public allowedFileExtensions = ['.zip'];
  private destroy$: Subject<void> = new Subject<void>();
  private file?: File = null


  constructor(
    public dialogRef: MatDialogRef<AppBehaviorCodeEntryCreateModalComponent>,
    private readonly toastService: AppToastManagerService,
    private readonly studentsService: AppStudentsService
  ) {}


  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  public handleFileChanged(file?: File): void {
    this.file = file;
  }

  uploadFile() {
    if (!this.file) {
      this.toastService.error('No valid file selected');
      return;
    };

    const reader = new FileReader();

    reader.onload = async (e) => {
      const uploadResult: Observable<Object> = await this.studentsService.bulkUploadStudentPhoto(this.file);

      if (uploadResult) {
        uploadResult.pipe(takeUntil(this.destroy$)).subscribe((result) => {

        if (result['status'] === 'success') {
          this.toastService.success('Started uploading profile images');
          this.dialogRef.close();
        } else {
          this.toastService.error('Error uploading profile images');
        }
        });
      } else {
        this.toastService.error('Error uploading profile images');
      }
    };

    reader.readAsDataURL(this.file);
  }


}
