"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterventionResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const boolean_transform_1 = require("../../constants/boolean.transform");
class InterventionResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.name = options.name;
        this.description = options.description;
        this.schoolId = options.schoolId;
        this.active = options.active;
    }
    getFields() {
        return ['id', 'name', 'description', 'schoolId', 'active'];
    }
    id;
    name;
    description;
    schoolId;
    active;
    static mapInterventions(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, InterventionResponseDto);
    }
}
exports.InterventionResponseDto = InterventionResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], InterventionResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], InterventionResponseDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], InterventionResponseDto.prototype, "description", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], InterventionResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], InterventionResponseDto.prototype, "active", void 0);
