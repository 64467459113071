import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { StudentBasicResponseDto } from '@whetstoneeducation/hero-common';
import { StudentGroupStudentListTableColumns } from './StudentGroupStudentListTableColumns.enum';
import { b } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-student-group-student-list',
  templateUrl: './student-group-student-list.template.html',
  styleUrls: ['student-group-student-list.scss']
})
export class AppStudentGroupStudentListComponent extends BaseTableComponent<StudentBasicResponseDto> {
  public get displayedColumns() {
    return this.showSelectOptions
      ? [
          StudentGroupStudentListTableColumns.SELECT,
          StudentGroupStudentListTableColumns.NAME,
          StudentGroupStudentListTableColumns.STUDENT_ID,
          StudentGroupStudentListTableColumns.GRADE,
          StudentGroupStudentListTableColumns.ACTION
        ]
      : [
          StudentGroupStudentListTableColumns.NAME,
          StudentGroupStudentListTableColumns.STUDENT_ID,
          StudentGroupStudentListTableColumns.GRADE,
          StudentGroupStudentListTableColumns.ACTION
        ];
  }

  public columns = StudentGroupStudentListTableColumns;

  @Input()
  public selectedStudents: StudentBasicResponseDto[] = [];

  @Input()
  public showSelectOptions: boolean;

  @Input()
  public showTableHeader: boolean = true;

  @Output()
  public selectedStudentsChanged: EventEmitter<StudentBasicResponseDto[]>;

  @Output()
  public studentSelected: EventEmitter<StudentBasicResponseDto>;

  @Output()
  public studentRemoved: EventEmitter<number>;

  constructor() {
    super();
    this.selectedStudentsChanged = new EventEmitter<
      StudentBasicResponseDto[]
    >();
    this.studentSelected = new EventEmitter<StudentBasicResponseDto>();
    this.studentRemoved = new EventEmitter<number>();
  }

  public selectAllStudents(): void {
    const allSelected = this.dataArr.length === this.selectedStudents.length;
    if (allSelected) {
      this.selectedStudents = [];
    } else {
      this.selectedStudents = this.dataArr;
    }
    this.selectedStudentsChanged.emit(this.selectedStudents);
  }

  public selectStudent(selectedStudent: StudentBasicResponseDto): void {
    const isSelected = !!this.selectedStudents.find(
      (student) => student.id === selectedStudent.id
    );
    if (isSelected) {
      this.selectedStudents = this.selectedStudents.filter(
        (student) => student.id !== selectedStudent.id
      );
    } else {
      this.selectedStudents.push(selectedStudent);
    }
    this.selectedStudentsChanged.emit(this.selectedStudents);
  }

  public isSelected(studentId: number): boolean {
    return !!this.selectedStudents.find((student) => student.id === studentId);
  }
}
