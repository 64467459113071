"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationUtil = void 0;
class ValidationUtil {
    static hasPrivilege(privilege, userPrivileges, schoolId) {
        if (userPrivileges.internalAdmin) {
            return userPrivileges.internalAdmin.includes(privilege);
        }
        if (userPrivileges.groupAdmin) {
            return userPrivileges.groupAdmin.includes(privilege);
        }
        if (schoolId) {
            return userPrivileges[schoolId].includes(privilege);
        }
        else {
            throw new Error('No school id provided for non-global role user');
        }
    }
    static getCurrentSchoolPrivileges(user) {
        if (user.privileges.internalAdmin) {
            return user.privileges.internalAdmin;
        }
        if (user.privileges.groupAdmin) {
            return user.privileges.groupAdmin;
        }
        if (user.privileges[user.currentSchoolId]) {
            return user.privileges[user.currentSchoolId];
        }
        else {
            throw new Error('No privileges found for current school');
        }
    }
}
exports.ValidationUtil = ValidationUtil;
