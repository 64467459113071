import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  PaginationResultsDto,
  RoleResponseDto,
} from '@whetstoneeducation/hero-common';
import { ActivatedRoute } from '@angular/router';
import { AppRolesServices } from '../roles.service';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { logger } from '../../../shared/logger';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.template.html',
  styleUrls: ['./roles-list.scss'],
})
export class AppRolesListComponent extends BaseComponent {
  public roles: RoleResponseDto[];
  public tableFilters: ITableFilters;
  public schoolId: number;

  constructor(
    public route: ActivatedRoute,
    public rolesService: AppRolesServices,
    private toastService: AppToastManagerService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    const data = this.route.snapshot.data
      .data as PaginationResultsDto<RoleResponseDto>;
    this.roles = data.results;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.ROLES_LIST
    );
    this.schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    this.tableFilters.count = data.options?.totalItems;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.tableFilters = newTableFilters;
    await this.updateRolesList();
    this.isLoading = false;
  }

  public async updateRolesList(): Promise<void> {
    try {
      const newData = await this.rolesService.getRoles({
        tableFilters: this.tableFilters,
        schoolId: this.schoolId,
      });
      this.roles = newData.results;
      this.tableFilters.count = newData.options?.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving roles!');
      logger.error(err);
    }
  }
}
