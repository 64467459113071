import { ActivatedRoute, Params } from '@angular/router';
import { AppAuthService } from '../auth/auth.service';
import { AppHomeService } from './home.service';
import { Component, OnInit } from '@angular/core';

/**
 * The Home Component
 */
@Component({
  selector: 'app-home',
  template: '',
})
export class AppHomeComponent implements OnInit {
  public constructor(
    private appHomeService: AppHomeService,
    public authService: AppAuthService,
    public route: ActivatedRoute
  ) {}

  /**
   * Get User from database. Get preferred home page from user object. Go there.
   */
  public ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams: Params) => {
      void this.appHomeService.goHome();
    });
  }
}
