<div class="scroll-container">
  <app-common-table
    [getColumnValue]="getColumnValue"
    [columns]="columns"
    [templates]="templates"
    [dataArr]="dataArr">
  </app-common-table>
</div>

<ng-template #actionsColumn let-row="row">
  <div class="action-buttons">
    <div
      class="tooltip-container"
      matTooltip="Export is not active"
      matTooltipPosition="above"
      [matTooltipDisabled]="!!row.active"
      >
      <button
        type="button"
        mat-flat-button
        [disabled]="!row.active"
        class="outline-button"
        (click)="clickRunExport(row)">
        RUN
      </button>
    </div>
    <button
    type="button"
    mat-flat-button
    class="outline-button"
    (click)="clickEditExport(row)">
    <fa-icon
      [icon]="['fas', 'pen']"
    ></fa-icon>
  </button>
  <button
    type="button"
    mat-flat-button
    class="outline-button"
    (click)="clickDeleteExport(row)">
    <fa-icon
      [icon]="['fas', 'trash']"
    ></fa-icon>
  </button>
  </div>
</ng-template>

<ng-template #activeSlideToggle let-row="row">
  <mat-slide-toggle
    hideIcon
    [checked]="!!row.active"
    color="primary"
    (toggleChange)="changeActive(row)"
    >
  </mat-slide-toggle>
</ng-template>
