import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import {
  PaginationResultsDto,
  StudentGroupResponseDto
} from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppStudentGroupsService } from '../student-groups.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';

@Injectable({ providedIn: 'root' })
export class AppStudentGroupsListResolver extends BaseResolver<
  PaginationResultsDto<StudentGroupResponseDto>
> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private studentGroupsService: AppStudentGroupsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<
    PaginationResultsDto<StudentGroupResponseDto>
  > {
    const user = this.StorageManager.getLoggedInUser();
    const tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.STUDENT_GROUP_LIST
    );
    return this.studentGroupsService.getStudentGroupsList(
      user.currentSchoolId,
      tableFilters
    );
  }
}
