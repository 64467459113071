"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BellSchedulePeriodTimeResponseDto = void 0;
const bell_schedule_period_time_create_dto_1 = require("./bell-schedule-period-time-create.dto");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const bell_schedule_periods_1 = require("../bell-schedule-periods");
class BellSchedulePeriodTimeResponseDto extends bell_schedule_period_time_create_dto_1.BellSchedulePeriodTimeCreateDto {
    constructor(options) {
        super(options);
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['id', ...super.getFields()];
    }
    mapFields(source, skipNested = false, skipTransforms = false) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    id;
    // This field can only be mapped when the BellSchedulePeriodTimeResponseDto is
    // nested within a BellScheduleResponseDto instance at this time.
    bellSchedulePeriod;
}
exports.BellSchedulePeriodTimeResponseDto = BellSchedulePeriodTimeResponseDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BellSchedulePeriodTimeResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => bell_schedule_periods_1.BellSchedulePeriodResponseDto),
    __metadata("design:type", bell_schedule_periods_1.BellSchedulePeriodResponseDto)
], BellSchedulePeriodTimeResponseDto.prototype, "bellSchedulePeriod", void 0);
