"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeNotificationStatus = void 0;
var BehaviorCodeNotificationStatus;
(function (BehaviorCodeNotificationStatus) {
    BehaviorCodeNotificationStatus["SENT"] = "sent";
    BehaviorCodeNotificationStatus["FAILED"] = "failed";
    BehaviorCodeNotificationStatus["PENDING"] = "pending";
    BehaviorCodeNotificationStatus["POST"] = "post";
})(BehaviorCodeNotificationStatus || (exports.BehaviorCodeNotificationStatus = BehaviorCodeNotificationStatus = {}));
