import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBackButtonModalComponent } from './back-button-modal.component';
import { AppMaterialModule } from '../../app-material.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    FontAwesomeModule,
  ],
  declarations: [AppBackButtonModalComponent],
  exports: [AppBackButtonModalComponent],
  providers: [],
})
export class AppBackButtonModalModule {}
