import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppStudentsService } from '../../students/students.service';
import { IStudentsListFilters } from '../../students/students-list-models/students-list-filters.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentPerformanceDto } from '@whetstoneeducation/hero-common';
import { environment } from '../../../../environments/environment';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { DEFAULT_DIALOG_RESULT } from '../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { AppCalendarService } from '../../calendar/calendar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-district-dashboard',
  templateUrl: './district-dash.template.html',
  styleUrls: ['./district-dash.scss']
})
export class AppDistrictDashComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  filters: IStudentsListFilters;
  topPerformers: StudentPerformanceDto[];
  outOfAcademicYear = false;
  // TODO: rework schedules after school groups - THOR-380
  todaySchedules: any[];
  public headerSubscription: Subscription;
  public constructor(
    public studentsService: AppStudentsService,
    public router: Router,
    public route: ActivatedRoute,
    public pageHeaderService: AppPageHeaderService,
    private confirmationDialog: AppConfirmationDialogService,
    public calendarService: AppCalendarService
  ) {
    super();
    this.filters = this.studentsService.getStudentsListDefaultFilters({});
    this.headerSubscription = this.pageHeaderService.buttonAction$.subscribe(
      async (action: HeaderButtonAction) => {
        if (action === HeaderButtonAction.NAVIGATE) {
          this.openKiosk();
        }
      }
    );
  }

  public async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  public ngOnDestroy(): void {
    this.headerSubscription.unsubscribe();
  }

  async loadData() {
    const { topPerformers, todaySchedules, noCalendar, outOfAcademicYear } =
      this.route.snapshot.data.data;
    if (noCalendar) {
      await this.router.navigate(['/calendar']);
      return;
    }

    if (outOfAcademicYear) {
      this.todaySchedules = [];
      this.outOfAcademicYear = true;
    }

    this.topPerformers = topPerformers;
    this.todaySchedules = todaySchedules;
  }

  public openKiosk(): void {
    const kioskURLs = {
      dev: 'https://dev.kiosk.hero.schoolmint.com',
      qa: 'https://qa.kiosk.hero.schoolmint.com',
      prod: 'https://kiosk.hero.schoolmint.com/login'
    };
    if (kioskURLs[environment.environmentName]) {
      window.open(kioskURLs[environment.environmentName], '_blank');
    } else {
      this.confirmationDialog.openDialog({
        title: 'Kiosk App Not Available',
        content: 'Kiosk App has not been configured for this environment.',
        actions: [
          {
            key: DEFAULT_DIALOG_RESULT.YES,
            label: 'OK'
          }
        ]
      });
    }
  }

  public async onStudentFiltersChanged(studentFilters: IStudentsListFilters) {
    this.filters = studentFilters;
  }

  public async handleStudentSearch() {
    if (
      this.filters.firstName ||
      this.filters.lastName ||
      this.filters.grade ||
      this.filters.studentExtId
    ) {
      await this.router.navigate(['/students'], {
        queryParams: this.filters
      });
    }
  }

  public async updateTodaySchedule(update: boolean) {
    if (update) {
      const user = this.StorageManager.getLoggedInUser();
      const todaySchedules = await this.calendarService.getTodaySchedule(
        user.currentSchoolId
      );
      this.todaySchedules = todaySchedules.studentGroupBellScheduleCalendarDays;
    }
  }
}
