import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { SchoolActivityReportFiltersDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-school-activity-report-actions',
  templateUrl: './school-activity-report-actions.template.html',
  styleUrls: ['./school-activity-report-actions.scss']
})
export class AppSchoolActivityReportActions extends BaseComponent {
  @Input()
  public filters: SchoolActivityReportFiltersDto;
  @Output()
  private filtersUpdated: EventEmitter<SchoolActivityReportFiltersDto> =
    new EventEmitter<SchoolActivityReportFiltersDto>();

  public constructor() {
    super();
  }

  public onFilterUpdated(field: string, data?: string | boolean) {
    this.filters[field] = data;
    this.filtersUpdated.emit(this.filters);
  }

  public searchFilterUpdated(data: string | undefined) {
    this.onFilterUpdated('text', data);
  }
}
