import { BaseTableComponent } from '../base-table/base-table';
import { Component, Input, TemplateRef } from '@angular/core';

type ColumnsType = { [s: string]: string };

/**
 * Common Table Component. Abstracts Table for you, as well as displaying data.
 * This component is meant to be used across all tables
 */
@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.template.html',
  styleUrls: ['./common-table.scss']
})
export class AppCommonTableComponent extends BaseTableComponent<any>  {
  @Input() getColumnValue?: (column: string, row: any) => any;
  @Input() getColumnTitle?: (column: string) => any;
  @Input() templates?: { [key: string]: TemplateRef<any> };
  @Input() showPagination: boolean = false;
  @Input() columns: ColumnsType;

  public get displayedColumns(): string[] {
    return Object.values(this.columns);
  }

  public get columnKeys(): string[] {
    return Object.keys(this.columns).map((key) => this.columns[key]);
  }

  public constructor() {
    super();
  }

}
