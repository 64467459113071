import { PrinterConfigurationResponseDto } from "@whetstoneeducation/hero-common";
import { logger } from '../../shared/logger';
import { AppToastManagerService } from "../services/toast-manager.service";

abstract class SdkDevicePrint<T> {
  protected printerConfiguration?: PrinterConfigurationResponseDto;
  protected toastService: AppToastManagerService;

  constructor(toastService: AppToastManagerService, printerConfiguration?: PrinterConfigurationResponseDto) {
    this.printerConfiguration = printerConfiguration;
    this.toastService = toastService;
  }

  abstract performSdkDevicePrint(data: T);

}

export class DymoSdkDevicePrint extends SdkDevicePrint<string> {
  private printers = [];
  constructor(toastService: AppToastManagerService, printerConfiguration?: PrinterConfigurationResponseDto) {
    super(toastService, printerConfiguration);
  }

  private loadPrinters() {
    try {
      // Load printers
     this.printers = dymo.label.framework.getPrinters()?.byIndex;
    } catch (err) {
      logger.error(err);
    }
  }

  performSdkDevicePrint(data: string) {
    if (!dymo) {
      this.toastService.error('We were not able to set DYMO printers, please contact support');
      logger.error('Dymo SDK not found');
      return;
    }
    this.loadPrinters();
    const label = dymo.label.framework.openLabelXml(data);

    if (this.printers.length == 0) {
      this.toastService.error("DYMO printer selected, but not correctly set on your machine");
      this.toastService.info('Please download the drivers or diagnose and reset with dymo connect app');
      return;
    }

    const validPrinter = this.printers.find(printer => printer.printerType == "LabelWriterPrinter");

    if (!validPrinter) {
      this.toastService.error('Must use a DYMO LabelWriterPrinter, please set one and try again');
      return;
    }

    label.print(validPrinter.name);
  }

}
