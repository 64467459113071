import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SchoolGroupResponseDto } from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../shared/logger';
import { ISchoolGroupsListFilters } from './school-groups-list-models/school-groups-list-filters.interface';
import { ISchoolGroupsListResolverData } from './school-groups-list-models/school-groups-list-resolver-data.interface';
import { AppSchoolGroupsService } from '../school-groups.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-school-groups-list',
  templateUrl: './school-groups-list.template.html',
  styleUrls: ['./school-groups-list.scss'],
})
export class AppSchoolGroupsListComponent extends BaseComponent {
  public schoolGroups: SchoolGroupResponseDto[];
  public filters: ISchoolGroupsListFilters;
  private pageHeaderSubscription: Subscription;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public schoolGroupsService: AppSchoolGroupsService,
    public pageHeaderService: AppPageHeaderService,
    public router: Router
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as ISchoolGroupsListResolverData;
    this.schoolGroups = data.schoolGroups.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.schoolGroups.options.totalItems;
    this.pageHeaderSubscription = this.pageHeaderService.buttonAction$.subscribe(
      async (action: HeaderButtonAction) => {
        if (action === HeaderButtonAction.CREATE) {
          await this.router.navigate(['school-groups', 'new']);
        }
      }
    );
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateSchoolGroupsList(this.filters);
    this.isLoading = false;
  }

  public async updateSchoolGroupsList(
    filters: ISchoolGroupsListFilters
  ): Promise<void> {
    try {
      const newData = await this.schoolGroupsService.getSchoolGroupsList(
        filters
      );
      this.schoolGroups = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving users!');
      logger.error(err);
    }
  }

  public async updateFilters(
    newFilters: ISchoolGroupsListFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.SCHOOL_GROUPS_LIST,
      this.filters.tableFilters
    );
    await this.updateSchoolGroupsList(this.filters);
    this.isLoading = false;
  }

  public async ngOnDestroy(): Promise<void> {
    this.pageHeaderSubscription.unsubscribe();
  }
}
