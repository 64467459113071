import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumString'
})
export class AppEnumStringPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    // Split the string by hyphen, capitalize the first letter of each part, and join them with a space
    return value
      .split('-')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  }
}
