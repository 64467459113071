import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  PaginationResultsDto,
  StudentGroupResponseDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppStudentGroupsService } from '../student-groups.service';
import { ActivatedRoute } from '@angular/router';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { DEFAULT_DIALOG_RESULT } from '../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';

@Component({
  selector: 'app-student-groups-list',
  templateUrl: './student-groups-list.template.html',
  styleUrls: ['./student-groups-list.scss']
})
export class AppStudentGroupsListComponent extends BaseComponent {
  public studentGroups: StudentGroupResponseDto[];
  public tableFilters: ITableFilters;

  constructor(
    public toastService: AppToastManagerService,
    public route: ActivatedRoute,
    private studentGroupsService: AppStudentGroupsService,
    private confirmationDialog: AppConfirmationDialogService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    const data = this.route.snapshot.data.data;
    this.studentGroups = data.results;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.STUDENT_GROUP_LIST
    );
    this.tableFilters.count = data.options.totalItems;
  }

  public async deleteStudentGroup(
    group: StudentGroupResponseDto
  ): Promise<void> {
    const ref = this.confirmationDialog.openDialog({
      title: 'Delete Student Group',
      content:
        'Are you sure you want to delete ' +
        group.name +
        '? This action cannot be reversed.'
    });
    ref.afterClosed().subscribe(async (result: string) => {
      if (result === DEFAULT_DIALOG_RESULT.YES) {
        this.isLoading = true;
        await this.studentGroupsService.deleteStudentGroup(group.id);
        await this.updateStudentGroupsList();
        this.isLoading = false;
      }
    });
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.tableFilters = newTableFilters;
    await this.updateStudentGroupsList();
    this.isLoading = false;
  }

  private async updateStudentGroupsList(): Promise<void> {
    const newData = await this.studentGroupsService.getStudentGroupsList(
      this.StorageManager.getLoggedInUser().currentSchoolId,
      this.tableFilters
    );
    this.studentGroups = newData.results;
    this.tableFilters.count = newData.options.totalItems;
  }
}
