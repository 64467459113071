"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationTemplateAllFilterDto = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const boolean_transform_1 = require("../../constants/boolean.transform");
class NotificationTemplateAllFilterDto extends mappable_base_1.MappableBase {
    isSchoolGroupLevel;
    isGlobal;
    schoolGroupId;
    schoolId;
    constructor(notificationTemplateFilterOptions) {
        super();
        if (notificationTemplateFilterOptions)
            Object.assign(this, notificationTemplateFilterOptions);
    }
    getFields() {
        return [
            'isSchoolGroupLevel',
            'isGlobal',
            'schoolGroupId',
            'schoolId',
            ...super.getFields()
        ];
    }
}
exports.NotificationTemplateAllFilterDto = NotificationTemplateAllFilterDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], NotificationTemplateAllFilterDto.prototype, "isSchoolGroupLevel", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], NotificationTemplateAllFilterDto.prototype, "isGlobal", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], NotificationTemplateAllFilterDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], NotificationTemplateAllFilterDto.prototype, "schoolId", void 0);
