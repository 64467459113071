<div class="interventions-list-content">
  <app-interventions-list-filters
    [filters]="filters"
    (filtersUpdated)="updateFilters($event)"
  ></app-interventions-list-filters>
  <app-interventions-list-table
    [dataArr]="interventions"
    [behaviorCodeId]="behaviorCodeId"
    [isLoading]="isLoading"
    [tableFilters]="filters.tableFilters"
    (tableFiltersUpdated)="updateTableFilters($event)"
    (listUpdated)="updateInterventionsList(filters)"
  ></app-interventions-list-table>
</div>
