"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpSettingsCreateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const enums_1 = require("../../enums");
const boolean_transform_1 = require("../../constants/boolean.transform");
const shared_1 = require("../shared");
class SftpSettingsCreateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return [
            'schoolId',
            'schoolGroupId',
            'syncFrequency',
            'startDate',
            'importSubject',
            'contactEmails',
            'sftpAccountId',
            'isSchoolGroupLevel',
            'importTime',
            'importDays',
            'removeMissingRecords'
        ];
    }
    mapFields(source) {
        super.mapFields(source);
        return this;
    }
    isSchoolGroupLevel;
    schoolGroupId;
    schoolId;
    sftpAccountId;
    syncFrequency;
    importSubject;
    startDate;
    contactEmails;
    importDays;
    importTime;
    removeMissingRecords;
}
exports.SftpSettingsCreateDto = SftpSettingsCreateDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)({ message: 'Group or school level must be selected' }),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SftpSettingsCreateDto.prototype, "isSchoolGroupLevel", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsPositive)({ message: 'School group must be selected.' }),
    __metadata("design:type", Number)
], SftpSettingsCreateDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsPositive)({ message: 'School ID must be a positive number.' }),
    __metadata("design:type", Number)
], SftpSettingsCreateDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)({}, {
        message: 'No SFTP Account was found for the selected school or group. Please make sure the school or group has an SFTP account configured.'
    }),
    __metadata("design:type", Number)
], SftpSettingsCreateDto.prototype, "sftpAccountId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsEnum)(enums_1.ImportFrequency, { message: 'You must select a sync frequency.' }),
    __metadata("design:type", String)
], SftpSettingsCreateDto.prototype, "syncFrequency", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsEnum)(enums_1.ImportSubject, { message: 'You must select an import subject.' }),
    __metadata("design:type", String)
], SftpSettingsCreateDto.prototype, "importSubject", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsDate)({ message: 'Please provide a valid start date.' }),
    (0, class_transformer_1.Transform)(({ value }) => new Date(value)),
    __metadata("design:type", Date)
], SftpSettingsCreateDto.prototype, "startDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNotEmpty)({ message: 'Please provide at least one contact email.' }),
    __metadata("design:type", String)
], SftpSettingsCreateDto.prototype, "contactEmails", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, shared_1.ExportDays)('syncFrequency'),
    __metadata("design:type", Object)
], SftpSettingsCreateDto.prototype, "importDays", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, shared_1.ExportTime)('importTime', 'syncFrequency', {
        message: 'Invalid time. Must be HH:MM AM/PM format. Time cannot be between 10PM and 12PM CST.'
    }),
    __metadata("design:type", String)
], SftpSettingsCreateDto.prototype, "importTime", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], SftpSettingsCreateDto.prototype, "removeMissingRecords", void 0);
