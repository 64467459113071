<form *ngIf="formGroup" [formGroup]="formGroup">
  <div>
    <div class="mapping-table-container">
      <table mat-table [dataSource]="transposedFile" class="mapping-data-table">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef class="top-left-th">
            Position
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [ngClass]="{'bottom-left-td': i === transposedFile.length - 1}">
            {{i + 1}}
          </td>
        </ng-container>
        <ng-container matColumnDef="header">
          <th mat-header-cell *matHeaderCellDef>Header</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{columnHeaders[i]}}
          </td>
        </ng-container>
        <ng-container matColumnDef="data1">
          <th mat-header-cell *matHeaderCellDef>Sample Data 1</th>
          <td mat-cell *matCellDef="let element">{{element['data1']}}</td>
        </ng-container>
        <ng-container matColumnDef="data2">
          <th mat-header-cell *matHeaderCellDef>Sample Data 2</th>
          <td mat-cell *matCellDef="let element">{{element['data2']}}</td>
        </ng-container>
        <ng-container matColumnDef="data3">
          <th mat-header-cell *matHeaderCellDef>Sample Data 3</th>
          <td mat-cell *matCellDef="let element">{{element['data3']}}</td>
        </ng-container>
        <ng-container matColumnDef="data4">
          <th mat-header-cell *matHeaderCellDef>Sample Data 4</th>
          <td mat-cell *matCellDef="let element">{{element['data4']}}</td>
        </ng-container>
        <ng-container matColumnDef="fieldSelect">
          <th mat-header-cell *matHeaderCellDef class="top-right-th">
            Hero Fields
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            [ngClass]="{'bottom-right-td': i === transposedFile.length - 1}">
            <mat-form-field class="field-select-container">
              <input
                matInput
                [matAutocomplete]="autoHeroFields"
                [formControl]="fieldControl[i]"
                (input)="filterAvailableColumns(i, fieldControl[i].value)" />
              <mat-autocomplete
                #autoHeroFields="matAutocomplete"
                (optionSelected)="onOptionSelected(i, $event)">
                <mat-option
                  *ngFor="let field of filteredColumns[i]"
                  [value]="field">
                  <div class="option-content">
                    <span class="field-text">{{ field }}</span>
                    <span
                      class="required-asterisk"
                      *ngIf="isRequiredField(field)"
                      >*</span
                    >
                  </div>
                </mat-option>
              </mat-autocomplete>
              <fa-icon
                *ngIf="fieldControl[i].value"
                [icon]="['fas', 'trash']"
                (click)="clearSelection(i, columnHeaders[i], $event)"
                class="trash-icon"></fa-icon>
            </mat-form-field>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="['position','header', 'data1', 'data2', 'data3', 'data4', 'fieldSelect']"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['position','header', 'data1', 'data2', 'data3', 'data4', 'fieldSelect']"></tr>
      </table>
    </div>
  </div>
</form>
