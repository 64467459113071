import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperUnderscoreString'
})
export class AppUpperUnderscorePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    // Split the string by underscore, capitalize the first letter of each part, and join them with a space
    return value
      .split('_')
      .map(
        (part) =>
          part.toLowerCase().charAt(0).toUpperCase() +
          part.slice(1).toLowerCase()
      )
      .join(' ');
  }
}
