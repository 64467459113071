<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <app-pagination
      *ngIf="tableFilters.count > tableFilters.itemsPerPage"
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="sftp-account-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <ng-container matColumnDef="{{columns.SCHOOL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.SCHOOL}}</mat-header-cell
        >
        <mat-cell *matCellDef="let sftpAccount">
          {{sftpAccount.school ? sftpAccount.school.name : 'none' }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="{{columns.SCHOOL_GROUP}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.SCHOOL_GROUP}}</mat-header-cell
        >
        <mat-cell *matCellDef="let sftpAccount">
          {{sftpAccount.schoolGroup.name}}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="{{columns.GROUP_LEVEL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >{{columns.GROUP_LEVEL}}</mat-header-cell
        >
        <mat-cell *matCellDef="let sftpAccount">
          {{ sftpAccount.isSchoolGroupLevel ? 'Yes' : 'No'}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell *matHeaderCellDef>{{columns.ACTIONS}}</mat-header-cell>
        <mat-cell *matCellDef="let sftpAccount">
          <button
            mat-flat-button
            (click)="viewSftpAccount(sftpAccount)"
            class="action-button icon-button">
            <fa-icon [icon]='["fas", "eye"]'></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let sftpAccount; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
