import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  IDisplayData,
  LoginResponseDto,
  NotificationTemplateBodyType,
  NotificationTemplateCreateDto,
  NotificationTemplateResponseDto,
  NotificationTemplateUpdateDto,
  NotificationType,
  TemplateVariableMapping
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNotificationService } from '../notification.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import {
  dtoToFormGroup,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { StorageManager } from '../../../shared/storage/storage.manager';
import { MatSelectChange } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import { logger } from '../../../shared/logger';
import { validate } from 'class-validator';
import tinymce from 'tinymce';

@Component({
  selector: 'app-notification-template',
  templateUrl: './notification-template.template.html',
  styleUrls: ['./notification-template.scss']
})
export class AppNotificationTemplateComponent
  extends BaseComponent
  implements OnDestroy, AfterViewInit
{
  public template:
    | NotificationTemplateCreateDto
    | NotificationTemplateUpdateDto;
  public isCreating: boolean;
  public templateForm: FormGroup;
  public schoolGroups: IDisplayData[] = [];
  public schools: IDisplayData[] = [];
  public currentUser: LoginResponseDto;
  public bodyTypes: string[] = Object.values(NotificationTemplateBodyType);
  public notificationTypes: NotificationType[] = [
    NotificationType.IMPORT_NOTIFICATION,
    NotificationType.BEHAVIOR_TRACKING
  ];
  public availableVariables =
    TemplateVariableMapping[NotificationType.USER_INVITE];
  public destroy$: Subject<void> = new Subject<void>();

  @ViewChild('subjectInput') subjectInput!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public notificationService: AppNotificationService,
    public pageHeaderService: AppPageHeaderService
  ) {
    super();

    this.loadData(this.route.snapshot.data.data);
    this.templateForm = dtoToFormGroup(this.template, this.formBuilder);
  }

  private loadData(template: NotificationTemplateResponseDto): void {
    this.currentUser = StorageManager.getLoggedInUser();
    if (template.id) {
      this.template = new NotificationTemplateUpdateDto().mapFields(template);
      this.isCreating = false;
    } else {
      this.template = new NotificationTemplateCreateDto().mapFields(template);
      this.isCreating = true;
    }
    if (this.currentUser.schoolGroups) {
      this.schoolGroups = this.currentUser.schoolGroups.map((sg) => ({
        value: sg.id,
        display: sg.name
      }));
    }
    if (this.currentUser.schoolGroupId) {
      this.updateSchoolsList(this.currentUser.schoolGroupId);
    } else if (this.currentUser.schoolGroups.length > 0) {
      this.updateSchoolsList(this.currentUser.schoolGroups[0].id);
    }
  }

  public schoolGroupChanged(event: MatSelectChange): void {
    const schoolGroupId = event.value;
    this.updateSchoolsList(schoolGroupId);
  }

  private updateSchoolsList(schoolGroupId: number) {
    this.schools = this.currentUser.schoolGroups
      .find((sg) => sg.id === schoolGroupId)
      .schools.map((s) => ({ value: s.id, display: s.name }));
  }

  public insertSubjectVariable(variable: string): void {
    const inputElement = this.subjectInput.nativeElement;
    this.insertAtCursor(inputElement, this.formatVariable(variable));
    // Update the form control value to reflect the changes in the view.
    this.templateForm.controls[this.subjectInput.nativeElement].setValue(
      inputElement.value
    );
  }

  public insertBodyVariable(variable: string): void {
    const editor = tinymce.activeEditor;
    if (editor) {
      editor.selection.setContent(this.formatVariable(variable));
    }
  }

  private formatVariable(variable: string): string {
    return `{{${variable}}}`;
  }

  private insertAtCursor(input: HTMLInputElement, textToInsert: string): void {
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const before = input.value.substring(0, startPos);
    const after = input.value.substring(endPos, input.value.length);
    input.value = before + textToInsert + after;
    input.selectionStart = input.selectionEnd = startPos + textToInsert.length;
    input.focus();

    // Update the form control value to reflect the changes in the view.
    this.templateForm.controls[input.name].setValue(input.value);
  }

  public async ngAfterViewInit(): Promise<void> {
    this.templateForm.get('bodyType').setValue(this.template.bodyType);
    this.templateForm
      .get('notificationType')
      .setValue(this.template.notificationType);
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action) => {
        if (action === 'save') {
          await this.saveTemplate();
        }
      });
    this.templateForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (this.isCreating) {
          this.template =
            await validateAndGetValue<NotificationTemplateCreateDto>(
              this.templateForm,
              NotificationTemplateCreateDto
            );
        } else {
          this.template =
            await validateAndGetValue<NotificationTemplateUpdateDto>(
              this.templateForm,
              NotificationTemplateUpdateDto
            );
        }
      });
  }

  public notificationTypeChanged(event: MatSelectChange): void {
    this.availableVariables = TemplateVariableMapping[event.value];
  }

  public async saveTemplate() {
    const errors = await validate(this.template);
    if (errors.length === 0) {
      const template = this.templateForm.value;
      if (this.template.bodyType === NotificationTemplateBodyType.TEXT) {
        template.body = tinymce.activeEditor.getContent({ format: 'text' });
      }
      if (this.isCreating) {
        if (this.currentUser.schoolGroupId) {
          template.schoolGroupId = this.currentUser.schoolGroupId;
        }
        if (this.currentUser.currentSchoolId) {
          template.schoolId = this.currentUser.currentSchoolId;
        }
        this.notificationService.createTemplate(template).then((response) => {
          logger.debug('Template created successfully', response);
          this.toastService.success('Template created successfully');
          this.router.navigate(['notification-templates']);
        });
      } else {
        this.notificationService
          .updateTemplate(template as NotificationTemplateUpdateDto)
          .then(
            (response) => {
              logger.debug('Template updated successfully', response);
              this.toastService.success('Template updated successfully');
              this.router.navigate(['notification-templates']);
            },
            (error) => {
              logger.error(error);
              this.toastService.error('Error updating template!');
            }
          );
      }
    } else {
      this.toastService.error('Please fill out all required fields');
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
