import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CalendarDayCreateDto,
  CalendarDayResponseDto,
  CalendarDayUpdateDto,
} from '@whetstoneeducation/hero-common';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { ICalendarDayCreateEditResolverData } from '../calendar-day-models/calendar-day-create-edit-resolver-data.interface';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue,
} from 'src/app/shared/validation/validation.util';
import { AppCalendarService } from '../../calendar.service';
import { logger } from 'src/app/shared/logger';
import { CalendarDayEditCreateFormDto } from '../calendar-day-models/calendary-day-edit-create-form.dto';
import { AppPageHeaderService } from '../../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../../shared/page-header/header-button';
import { IFormError } from '../../../../shared/validation/form-error.interface';

@Component({
  selector: 'app-calendar-create-edit',
  templateUrl: './calendar-day-create-edit.template.html',
  styleUrls: ['./calendar-day-create-edit.scss'],
})
export class AppCalendarCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public calendarDay: CalendarDayEditCreateFormDto;
  public isCreating: boolean;
  public calendarDayForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public id: number;
  public schoolId: number;
  public pageHeaderSubscription: Subscription;
  public errors: IFormError[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public calendarService: AppCalendarService,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    const resolverData: ICalendarDayCreateEditResolverData =
      this.route.snapshot.data.data;
    this.loadData(resolverData);
    this.calendarDayForm = dtoToFormGroup(this.calendarDay, formBuilder, {
      exclude: ['id'],
      ...(!this.isCreating && { disable: ['date'] }),
    });
    if (this.isCreating) this.calendarDayForm.markAllAsTouched();
  }

  public ngAfterViewInit(): void {
    this.subscriptions.push(
      this.calendarDayForm.valueChanges.subscribe(async () => {
        this.calendarDay =
          await validateAndGetValue<CalendarDayEditCreateFormDto>(
            this.calendarDayForm,
            CalendarDayEditCreateFormDto
          );
      })
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public loadData(resolverData: ICalendarDayCreateEditResolverData): void {
    this.isLoading = true;
    this.id = +this.route.snapshot.params.id;
    this.isCreating = !this.id;
    // THIS IS HARD CODED FOR NOW REMEMBER TO CHANGE IT
    this.schoolId = resolverData.schoolId || 1;
    if (this.isCreating) {
      this.calendarDay = new CalendarDayEditCreateFormDto({
        date: new Date(),
        name: '',
        notes: '',
      });
    } else {
      this.calendarDay = this.responseDtoToFormDto(resolverData.calendarDay);
    }
    this.isLoading = false;
  }

  public async saveChanges(): Promise<void> {
    if (formCanSave(this.calendarDayForm, this.toastManager)) {
      try {
        if (this.isCreating) {
          const createDto = new CalendarDayCreateDto();
          createDto.mapFields(this.calendarDay);
          createDto.schoolId = this.schoolId;
          const data = await this.calendarService.createCalendarDay(createDto);
          await this.router.navigate(['calendar', data.id]);
        } else {
          const updateDto = new CalendarDayUpdateDto(this.calendarDay);
          const responseDto = await this.calendarService.updateCalendarDay(
            updateDto,
            this.id
          );
          this.calendarDay = this.responseDtoToFormDto(responseDto);
        }
        this.toastManager.success('Calendar Day saved successfully');
      } catch (error) {
        if (
          error.error &&
          error.error.message === 'Calendar day already exists'
        ) {
          this.calendarDayForm.controls.date.setErrors({
            alreadyExists: 'date already exists',
          });
        }
        logger.error(error);
        this.toastManager.error(
          'There was an error saving Calendar Day, please try again!'
        );
      }
    }
  }

  private responseDtoToFormDto(
    dto: CalendarDayResponseDto
  ): CalendarDayEditCreateFormDto {
    return new CalendarDayEditCreateFormDto({
      ...dto,
      date: new Date(dto.date),
    });
  }
}
