import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DefaultOptionCategoriesIncidentEnum } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-incident-config-option-category-menu-item',
  templateUrl: './incident-config-option-category-menu-item.component.html',
  styleUrls: ['./incident-config-option-category-menu-item.component.scss']
})
export class IncidentConfigOptionCategoryMenuItemComponent {
  @Input() public value: DefaultOptionCategoriesIncidentEnum;
  @Input() public display: string;
  @Input() public selected: boolean;
  @Output()
  public onCategoryChange: EventEmitter<DefaultOptionCategoriesIncidentEnum> =
    new EventEmitter<DefaultOptionCategoriesIncidentEnum>();
}
