<div class="error-list-container">
  <ng-container *ngFor="let control of getControlNames()">
    <ng-container *ngIf="hasError(control)">
      <div class="error-item-container">
        <div class="control-name">{{ getDisplayError(control)[0] }}</div>
        <div class="error-message">{{ getDisplayError(control)[1] }}</div>
      </div>
    </ng-container>
  </ng-container>
</div>
<button
  mat-flat-button
  color="primary"
  class="close-errors-button"
  (click)="closeDialog()">
  Close
</button>
