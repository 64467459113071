import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  CalendarDayResponseDto,
  DateUtil,
  ScheduledReactionResponseDto
} from '@whetstoneeducation/hero-common';
import { AppCalendarService } from '../calendar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar-day-scheduled-reaction-manage-modal',
  templateUrl: './calendar-day-scheduled-reaction-manage-modal.component.html',
  styleUrls: ['./calendar-day-scheduled-reaction-manage-modal.component.scss']
})
export class CalendarDayScheduledReactionManageModalComponent
  extends BaseComponent
  implements OnInit
{
  public calendarDay: CalendarDayResponseDto;
  public scheduledReactions: ScheduledReactionResponseDto[];
  constructor(
    public calendarService: AppCalendarService,
    public toastService: AppToastManagerService,
    public dialogRef: MatDialogRef<CalendarDayScheduledReactionManageModalComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      timestamp: number;
      onClose?: () => void;
    }
  ) {
    super();
  }

  async ngOnInit() {
    this.calendarDay = await this.calendarService.getCalendarDayByTimestamp(
      this.data.timestamp
    );
  }

  async handleDelete(id: number) {
    await this.calendarService.deleteScheduledReaction(id);
    this.toastService.success('Scheduled Reaction Deleted');
    this.data?.onClose?.();
    this.dialogRef.close();
  }

  async navigateToCompliance(id: number) {
    await this.router.navigate(['/compliance/scheduled-reaction/', id]);
    this.dialogRef.close();
  }

  async navigateToReactions() {
    await this.router.navigate(['reactions']);
    this.dialogRef.close();
  }

  protected readonly DateUtil = DateUtil;

  getScheduleReactionContentClassname(index: number) {
    if (index === 0) {
      return 'scheduled-reaction-content first-scheduled-reaction-content';
    } else if (index === this.calendarDay.scheduledReactions.length - 1) {
      return 'scheduled-reaction-content last-scheduled-reaction-content';
    }
    return 'scheduled-reaction-content';
  }
}
