import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import {
  BehaviorCodeResponseDto,
  ReactionEntriesListResponseDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { IBehaviorCodeEntryConfirmReactionsResolverData } from './behavior-code-entry-confirm-reaction-models/behavior-code-entry-confirm-reactions-resolver-data.interface';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { ComplianceListColumnsEnum } from '../../compliance/compliance-list/compliance-list-models/compliance-list-column.enum';
import { AppBehaviorCodeEntryService } from '../behavior-code-entry.service';
import { MatDialog } from '@angular/material/dialog';
import { AppBehaviorCodeEntryEditReactionModalComponent } from '../behavior-code-entry-edit-reaction-modal/behavior-code-entry-edit-reaction-modal.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { ReactionPrintService } from '../behavior-code-entry-reactions-printer/behavior-code-reaction-print.service';
import { PrinterService } from '../../../shared/printer/printer.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { printPass } from '../../../shared/printer/browser-print.util';

@Component({
  selector: 'app-behavior-code-entry-confirm-reactions',
  templateUrl: './behavior-code-entry-confirm-reactions.component.html',
  styleUrls: ['./behavior-code-entry-confirm-reactions.component.scss']
})
export class BehaviorCodeEntryConfirmReactionsComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public reactionEntries: ReactionEntriesListResponseDto[];
  public tableFilters: ITableFilters;
  public behaviorCodeEntriesIds: number[];
  public behaviorCodes: Map<number, BehaviorCodeResponseDto>;
  public pageHeaderSubscription: Subscription;
  public browserPrint: boolean = false;
  public destroy$ = new Subject<void>();

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    private pageHeaderService: AppPageHeaderService,
    private dialog: MatDialog,
    private reactionPrinter: ReactionPrintService,
    private printer: PrinterService,
    private location: Location
  ) {
    super();
    this.loadData();
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: string) => {
        if (action === HeaderButtonAction.BACK) {
          this.location.back();
        }
      });
  }
  async ngAfterViewInit(): Promise<void> {
    // currently we only see this screen when there is one behavior tracked
    // this would support multiple in theory but that isn't implemented right there
    const behaviorCode = this.behaviorCodes.get(
      this.reactionEntries[0].behaviorCodeId
    );
    if (behaviorCode.printPass) {
      const dialog = await this.printer.initializeDevice();
      if (dialog) {
        dialog.afterClosed().subscribe((browserPrint) => {
          this.browserPrint = browserPrint;
          this.printSingleReaction();
        });
      } else {
        this.printSingleReaction();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data
      .data as IBehaviorCodeEntryConfirmReactionsResolverData;
    this.reactionEntries = data.reactionEntries.results;
    this.tableFilters = data.tableFilters;
    this.behaviorCodeEntriesIds = data.behaviorCodeEntriesIds;
    this.behaviorCodes = data.behaviorCodes;

    const behaviorCodeName = this.reactionEntries[0]?.behaviorCodeName;

    this.pageHeaderService.changeTitle(`Confirm Reactions`);

    if (behaviorCodeName) {
      this.pageHeaderService.changeSubtitle(`${behaviorCodeName}`);
    }
  }

  private printSingleReaction() {
    if (this.reactionEntries.length === 1 && this.printer.usbAvailable) {
      try {
        for (let i = 0; i < this.reactionEntries[0].printCopies; i++) {
          this.reactionPrinter.setData(this.reactionEntries[0]).print();
        }
      } catch (error) {
        this.toastService.error('Error printing reaction, printer not found');
      }
    } else if (!this.printer.usbAllowed || this.browserPrint) {
      printPass(this.reactionEntries[0]);
    }
  }

  protected readonly columns = ComplianceListColumnsEnum;

  public get displayedColumns() {
    return Object.values(ComplianceListColumnsEnum);
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.tableFilters = newTableFilters;
    await this.updateReactionEntries(this.tableFilters);
    this.isLoading = false;
  }

  public async updateReactionEntries(filters: ITableFilters): Promise<void> {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    const response =
      await this.behaviorCodeEntryService.getBehaviorCodeEntriesByIds(
        this.behaviorCodeEntriesIds,
        schoolId,
        filters,
        true
      );
    this.reactionEntries = response.results;
  }

  public async reloadTable(): Promise<void> {
    this.isLoading = true;
    await this.updateReactionEntries(this.tableFilters);
    this.isLoading = false;
  }

  public openEditReactionModal(
    reactionEntry: ReactionEntriesListResponseDto
  ): void {
    this.dialog.open(AppBehaviorCodeEntryEditReactionModalComponent, {
      data: {
        reactionEntry,
        onClose: async () => {
          await this.reloadTable();
          this.dialog.closeAll();
        }
      },
      width: '500px',
      height: 'auto'
    });
  }
}
