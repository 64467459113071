import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { AppUsersServices } from './users.service';
import { BaseComponent } from '../../shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFormError } from '../../shared/validation/form-error.interface';
import { Subscription } from 'rxjs';
/**
 * Validation imports
 */
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../shared/validation/validation.util';
/**
 * Hero imports
 */
import {
  IDisplayData,
  LoginResponseDto,
  RoleResponseDto,
  SchoolGroupResponseDto,
  StateEnum,
  UserCreateDto,
  UserDetailResponseDto,
  UserTypeEnum,
  UserUpdateDto
} from '@whetstoneeducation/hero-common';
import { AppPasswordDialogComponent } from '../../shared/password-dialog/password-dialog.component';
import { AppMySettingsService } from '../my-settings/my-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { AppPageHeaderService } from '../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

@Component({
  selector: 'app-user-create-edit',
  templateUrl: './user-create-edit.template.html',
  styleUrls: ['./user-create-edit.scss']
})
export class AppUserCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  /**
   * Instance variables
   */
  public availableRoles: RoleResponseDto[] = [];
  public availableSchoolGroups: SchoolGroupResponseDto[] = [];
  public isUpdate: boolean;
  public errors: IFormError[] = [];
  private lastSelectedSchoolIds: number[] = [];
  public roles: IDisplayData<{ schoolId: number, userType: UserTypeEnum }>[];
  public schoolGroups: IDisplayData[] = [];
  public schools: IDisplayData[];
  public subscriptions: Subscription[] = [];
  public user: UserCreateDto | UserUpdateDto;
  private formBuilder: FormBuilder;
  public userForm: FormGroup;
  private userRolesLoaded: boolean = false;
  public userId: number;
  private initPromise: Promise<void>;
  public stateOptions: IDisplayData[] = Object.values(StateEnum).map(
    (value) => ({
      value,
      display: value
    })
  );

  public userTypes: IDisplayData[] = [];

  constructor(
    formBuilder: FormBuilder,
    private toastService: AppToastManagerService,
    private mySettingsService: AppMySettingsService,
    public route: ActivatedRoute,
    public router: Router,
    public pageHeaderService: AppPageHeaderService,
    public usersServices: AppUsersServices,
    public passwordDialog: MatDialog
  ) {
    super({ route, router });
    this.setupSaveButton();
    this.formBuilder = formBuilder;
  }

  ngOnInit() {
    this.initPromise = this.loadData(this.route.snapshot.data.data);
    this.userForm = dtoToFormGroup(this.user, this.formBuilder, {
      mapId: this.isUpdate,
      exclude: ['passwordInfo']
    });

    this.userTypes = this.getUserTypeDisplayOptions().filter(
      // remove student and guardian user types as they have other creation flows
      (userType) =>
        ![UserTypeEnum.STUDENT, UserTypeEnum.GUARDIAN].includes(userType.value)
    );
  }

  get selectedSchools(): IDisplayData[] {
    const selectedSchoolIds = this.userForm.get('schools').value;
    return this.schools?.filter((school) =>
      selectedSchoolIds.includes(school.value)
    );
  }

  get teacherUserTypeSelected(): boolean {
    return this.userForm.get('type').value === UserTypeEnum.TEACHER;
  }

  async ngAfterViewInit() {
    await this.initPromise;
    const schoolGroupControl = this.userForm.get('schoolGroupId');
    this.subscriptions.push(
      schoolGroupControl.valueChanges.subscribe(
        async (schoolGroupId: number) => {
          const schoolGroup = this.availableSchoolGroups.find(
            (schoolGroup) => schoolGroup.id === schoolGroupId
          );
          if (schoolGroup) {
            const availableSchools = schoolGroup.schools;
            this.schools = availableSchools.map((school) => ({
              value: school.id,
              display: school.name
            }));
          }
        }
      )
    );
    schoolGroupControl.setValue(this.user?.schoolGroupId);
    const schoolsControl = this.userForm.get('schools');
    const rolesControl = this.userForm.get('roles');
    this.subscriptions.push(
      schoolsControl.valueChanges.subscribe(async (schools: number[]) => {
        this.addSchoolRolesControls(schools);

        if (schools.length) {
          this.roles = this.availableRoles
            .filter((role) => {
              if (!role.schoolId) return true;
              return schools.includes(role.schoolId);
            })
            .map((role) => ({
              value: role.id,
              display: `${role.name}`,
              data: {
                schoolId: role.schoolId,
                userType: role.userType
              }
            }));

          if (this.isUpdate && !this.userRolesLoaded) {
            const userSchoolRoles = this.roles
              .filter((role) => this.user.roles.includes(role.value))
              .reduce((acc, role) => {
                const key = `school${role.data.schoolId}Roles`;
                const currentRoles = acc[key];
                acc[key] = currentRoles
                  ? [...currentRoles, role.value]
                  : [role.value];
                return acc;
              }, {});
            this.moveUserSchoolRolesToSchoolRoles(userSchoolRoles);
            this.userRolesLoaded = true;
          }
        } else {
          this.roles = undefined;
        }
        this.moveSchoolRolesToRolesField(schools);
        schools.forEach((schoolId) => {
          const schoolRolesControl = this.userForm.get(
            `school${schoolId}Roles`
          );
          this.subscriptions.push(
            schoolRolesControl.valueChanges.subscribe(async () => {
              this.moveSchoolRolesToRolesField();
            })
          );
        });
      })
    );
    schoolsControl.setValue(this.user.schools);
    this.subscriptions.push(
      this.userForm.valueChanges.subscribe(async () => {
        if (this.isUpdate)
          this.user = await validateAndGetValue<UserUpdateDto>(
            this.userForm,
            UserUpdateDto
          );
        else
          this.user = await validateAndGetValue<UserCreateDto>(
            this.userForm,
            UserCreateDto
          );
      })
    );
  }

  private addSchoolRolesControls(schoolIds: number[]): void {
    const newSelectedSchools = schoolIds.filter(
      (schoolId) => !this.lastSelectedSchoolIds.includes(schoolId)
    );
    const unselectedSchools = this.lastSelectedSchoolIds.filter(
      (schoolId) => !schoolIds.includes(schoolId)
    );
    newSelectedSchools.forEach((newSchoolSelected) =>
      this.userForm.addControl(
        `school${newSchoolSelected}Roles`,
        this.formBuilder.control<number[]>([])
      )
    );
    unselectedSchools.forEach((unselectedSchool) =>
      this.userForm.removeControl(`school${unselectedSchool}Roles`)
    );
    this.lastSelectedSchoolIds = schoolIds;
  }

  private moveSchoolRolesToRolesField(schoolIds?: number[]): void {
    const schools = schoolIds ?? this.lastSelectedSchoolIds;
    const newRoles = schools.reduce((roles, schoolId) => {
      const key = `school${schoolId}Roles`;
      const schoolRoles = this.userForm.get(key).value;
      return [...roles, ...schoolRoles];
    }, []);
    this.userForm.get('roles').setValue(newRoles);
  }

  private moveUserSchoolRolesToSchoolRoles(userSchoolRoles): void {
    const keys = Object.keys(userSchoolRoles);
    keys.forEach((schoolRoleKey) => {
      const control = this.userForm.get(schoolRoleKey);
      control.setValue(userSchoolRoles[schoolRoleKey]);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public setupSaveButton() {
    this.subscriptions.push(
      this.pageHeaderService.buttonAction$.subscribe(async (action) => {
        if (
          action === HeaderButtonAction.SAVE &&
          formCanSave(this.userForm, this.toastService)
        ) {
          if (!this.teacherUserTypeSelected) {
            this.user.instructorCode = null;
          }

          await this.saveUser(this.user);
        }
      })
    );
  }

  public async setPassword() {
    this.passwordDialog
      .open(AppPasswordDialogComponent, {
        width: '500px',
        data: {
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (this.user instanceof UserCreateDto) return;
        if (result) {
          const localUser = this.user as UserUpdateDto;
          this.mySettingsService
            .setPassword(this.userId, result)
            .then(() => {
              this.toastService.success('Password updated successfully!');
            })
            .catch(() => {
              this.toastService.error(
                'An error occurred when attempting to update your password!'
              );
            });
        }
      });
  }

  public async loadData(data: UserDetailResponseDto) {
    try {
      this.userId = +this.route.snapshot.params.id;
      if (!this.userId) this.user = new UserCreateDto().mapFields(data);
      else {
        this.user = new UserUpdateDto().mapFields(data);
      }
      this.isUpdate = this.user instanceof UserUpdateDto;
      const loggedInUser: LoginResponseDto =
        this.StorageManager.getLoggedInUser();
      this.availableSchoolGroups = loggedInUser.schoolGroups;
      await this.setAvailableRoles();
      this.schoolGroups = loggedInUser.schoolGroups.map((schoolGroup) => ({
        value: schoolGroup.id,
        display: schoolGroup.name
      }));
    } catch (e) {
      this.toastService.error('An error occurred while loading the user!');
    }
  }

  public async setAvailableRoles() {
    const currentUser = this.StorageManager.getLoggedInUser();
    this.availableRoles = await this.usersServices.getRoleOptions(
      currentUser.id
    );
  }

  public getSchoolRoles(
    schoolId: number
  ): IDisplayData<{ schoolId: number }>[] {
    return this.roles.filter(
      (role) => (role.data.schoolId === schoolId || !role.data.schoolId) && role.data.userType === this.user.type
    );
  }

  public showRoles(): boolean {
    return (!!this.schools && !!this.roles && !!this.user.type);
  }

  public async saveUser(user: UserCreateDto | UserUpdateDto) {
    try {
      if (user instanceof UserCreateDto) {
        await this.usersServices.createUser(user);
        this.toastService.success('User created successfully!');
      } else {
        await this.usersServices.updateUser(user, this.userId);
        this.toastService.success('User updated successfully!');
      }
      await this.router.navigate(['/users']);
    } catch (e) {
      this.toastService.error('An error occurred while saving the user!');
    }
  }
}
