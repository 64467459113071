import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  DaysOfWeekEnum,
  IDisplayData,
  ReactionEditDaysDto
} from '@whetstoneeducation/hero-common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  dtoToFormGroup,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-reaction-edit-days',
  templateUrl: './reaction-edit-days.template.html',
  styleUrls: ['./reaction-edit-days.scss']
})
export class AppReactionsEditDaysComponent extends BaseComponent {
  public isLoading = false;
  public daysForm: FormGroup;
  public daysDto: ReactionEditDaysDto;
  public daysOptions: IDisplayData[] = [
    { display: 'Sunday', value: DaysOfWeekEnum.SUNDAY },
    { display: 'Monday', value: DaysOfWeekEnum.MONDAY },
    { display: 'Tuesday', value: DaysOfWeekEnum.TUESDAY },
    { display: 'Wednesday', value: DaysOfWeekEnum.WEDNESDAY },
    { display: 'Thursday', value: DaysOfWeekEnum.THURSDAY },
    { display: 'Friday', value: DaysOfWeekEnum.FRIDAY },
    { display: 'Saturday', value: DaysOfWeekEnum.SATURDAY }
  ];
  public onClose: () => void;
  public currentDays: DaysOfWeekEnum[] = [];

  constructor(
    public dialogRef: MatDialogRef<AppReactionsEditDaysComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      days: DaysOfWeekEnum[];
      onClose?: (days: ReactionEditDaysDto) => void;
    },
    public formBuilder: FormBuilder
  ) {
    super();
    this.daysDto = new ReactionEditDaysDto({ days: this.data.days });
    this.daysForm = dtoToFormGroup(this.daysDto, formBuilder);
    this.currentDays = this.daysDto.days;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public async onModeToggle(event: MatButtonToggleChange): Promise<void> {
    const mode = event.value;
    if (mode === 'add') {
      this.daysDto.days = this.currentDays;
    } else {
      this.daysDto.days = [];
    }
    this.daysDto.mode = mode;
    this.daysForm = dtoToFormGroup(this.daysDto, this.formBuilder);
  }

  public async save(): Promise<void> {
    const result = await validateAndGetValue(
      this.daysForm,
      ReactionEditDaysDto
    );
    this.data.onClose(result);
    this.dialogRef.close();
  }
}
