<div class="mat-dialog-header">
  <div class="mat-dialog-title">Import Details</div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<mat-dialog-content class="import-view-details">
  <div class="import-view-details-header">
    <div class="import-view-details-items">
      <div class="import-view-details-item">
        <span class="import-view-details-item-label">School Group:</span>
        <span class="import-view-details-item-value">
          {{import.schoolGroup.name}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span class="import-view-details-item-label">School:</span>
        <span class="import-view-details-item-value">
          {{import.school?.name || 'Group Level'}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span class="import-view-details-item-label">Import Subject:</span>
        <span class="import-view-details-item-value">
          {{import.importSubject}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span class="import-view-details-item-label">Status:</span>
        <span class="import-view-details-item-value">{{import.status}}</span>
      </div>
      <div class="import-view-details-item">
        <span
          matTooltip="Number of lines in file, includes header row"
          class="import-view-details-item-label"
          >Rows in file:</span
        >
        <span class="import-view-details-item-value">
          {{import.recordsFound}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span
          matTooltip="Records that didn't exist in the database"
          class="import-view-details-item-label"
          >New Records:</span
        >
        <span class="import-view-details-item-value">
          {{import.recordsCreated}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span
          matTooltip="Records that already existed in the database"
          class="import-view-details-item-label"
          >Existing Records:</span
        >
        <span class="import-view-details-item-value">
          {{import.recordsUpdated}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span
          matTooltip="Total records that were successfully created or updated"
          class="import-view-details-item-label"
          >Records Saved:</span
        >
        <span class="import-view-details-item-value">
          {{import.recordsImported}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span class="import-view-details-item-label">Records Removed:</span>
        <span class="import-view-details-item-value">
          {{import.recordsRemoved}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span
          matTooltip="Number of records that failed to save"
          class="import-view-details-item-label"
          >Failed saves:</span
        >
        <span class="import-view-details-item-value">
          {{import.recordsFailed}}
        </span>
      </div>
      <div class="import-view-details-item">
        <span class="import-view-details-item-label"> Errors Reported: </span>
        <span class="import-view-details-item-value">
          {{importErrors.length}}
        </span>
      </div>
    </div>
    <button
      *ngIf="import.isLatest"
      mat-flat-button
      matTooltip="Download Import Report"
      (click)="downloadImportReport(import)"
      class="outline-button icon-button download-button">
      <fa-icon [icon]="['fas', 'download']"></fa-icon>
    </button>
  </div>
  <div class="import-view-details-error-list">
    <ng-container *ngFor="let error of importErrors">
      <div class="import-view-details-error">
        <div class="import-view-details-error-item">
          <span class="import-view-details-item-label">Error Type:</span>
          <span class="import-view-details-item-value">{{error.type}}</span>
        </div>
        <div class="import-view-details-error-item">
          <span class="import-view-details-item-label">Error Message:</span>
          <span class="import-view-details-item-value">{{error.message}}</span>
        </div>
        <div
          *ngIf="error?.lineNumbers?.length"
          class="import-view-details-error-item">
          <span class="import-view-details-item-label">Rows Effected</span>
          <span class="import-view-details-item-value"
            >{{error?.lineNumbers?.length}}</span
          >
        </div>
        <ng-container *ngFor="let record of error.records">
          <span class="import-view-error-record-link">
            <a
              href="javascript:void(0)"
              [matTooltip]="getRecordPreview(record)"
              (click)="viewErrorRecord(record)">
              View Record
            </a>
          </span>
          <!-- Show size of error.record in bytes/kb -->
          <span *ngIf="record"> {{getRecordSize(record)}} </span></ng-container
        >
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
