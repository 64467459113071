<div class="mat-dialog-header">
  <div class="mat-dialog-title">Fast Track</div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <div class="selected-students">
    <span class="behavior-tracking-student-count">
      Selected: {{ getStudentSelectedText() }}
    </span>
  </div>

  <div *ngIf="behaviorCodes" class="behavior-code-tiles">
    <ng-container *ngFor="let behaviorCode of behaviorCodes">
      <div
        [class]="getBehaviorCodeTileClass(behaviorCode)"
        (click)="toggleBehaviorCode(behaviorCode.id)">
        {{ behaviorCode.code }}
      </div>
    </ng-container>
  </div>
  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      [disabled]="selectedBehaviorCodeIds.length === 0"
      type="submit"
      mat-button
      class="modal-submit-button"
      (click)="submit()">
      Fast Track
    </button>
  </div>
</div>
