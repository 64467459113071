"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentUpdateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
const enums_1 = require("../../enums");
const boolean_transform_1 = require("../../constants/boolean.transform");
const class_transformer_1 = require("class-transformer");
const class_transformer_2 = require("class-transformer");
class StudentUpdateDto extends mappable_base_1.MappableBase {
    constructor(updateStudentOptions) {
        super();
        if (!updateStudentOptions)
            return;
        this.firstName = updateStudentOptions.firstName;
        this.lastName = updateStudentOptions.lastName;
        this.grade = updateStudentOptions.grade;
        this.schoolId = updateStudentOptions.schoolId;
        this.middleInitial = updateStudentOptions.middleInitial;
        this.referenceId = updateStudentOptions.referenceId;
        this.homeroom = updateStudentOptions.homeroom;
        this.address = updateStudentOptions.address;
        this.city = updateStudentOptions.city;
        this.state = updateStudentOptions.state;
        this.zip = updateStudentOptions.zip;
        this.gender = updateStudentOptions.gender;
        this.ethnicGroup = updateStudentOptions.ethnicGroup;
        this.studentLanguage = updateStudentOptions.studentLanguage;
        this.teacher = updateStudentOptions.teacher;
        this.homePhone = updateStudentOptions.homePhone;
        this.workPhone = updateStudentOptions.workPhone;
        this.contactPhone = updateStudentOptions.contactPhone;
        this.guardian = updateStudentOptions.guardian;
        this.guardianEmail = updateStudentOptions.guardianEmail;
        this.emergencyContact = updateStudentOptions.emergencyContact;
        this.notes = updateStudentOptions.notes;
        this.active = updateStudentOptions.active;
        this.studentExtId = updateStudentOptions.studentExtId;
        this.birthDate = updateStudentOptions.birthDate;
        this.flagMessage = updateStudentOptions.flagMessage;
        this.sendLettersViaEmail = updateStudentOptions.sendLettersViaEmail;
        this.studentGroupId = updateStudentOptions.studentGroupId;
    }
    getFields() {
        return [
            'firstName',
            'lastName',
            'grade',
            'schoolId',
            'middleInitial',
            'referenceId',
            'homeroom',
            'address',
            'city',
            'state',
            'zip',
            'gender',
            'ethnicGroup',
            'studentLanguage',
            'teacher',
            'homePhone',
            'workPhone',
            'contactPhone',
            'guardian',
            'guardianEmail',
            'emergencyContact',
            'notes',
            'studentExtId',
            'birthDate',
            'flagMessage',
            'sendLettersViaEmail',
            'studentGroupId'
        ];
    }
    firstName;
    lastName;
    middleInitial;
    birthDate;
    referenceId;
    homeroom;
    grade;
    schoolId;
    address;
    city;
    state;
    zip;
    homePhone;
    workPhone;
    contactPhone;
    gender;
    ethnicGroup;
    studentLanguage;
    teacher;
    guardian;
    guardianEmail;
    emergencyContact;
    notes;
    active;
    studentExtId;
    flagMessage;
    sendLettersViaEmail;
    studentGroupId;
    mapFields(studentDetailResponse) {
        super.mapFields(studentDetailResponse);
        return this;
    }
}
exports.StudentUpdateDto = StudentUpdateDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "middleInitial", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "birthDate", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "referenceId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "homeroom", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "grade", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], StudentUpdateDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "city", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.StateEnum),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "state", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "zip", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "homePhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "workPhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "contactPhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "gender", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "ethnicGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "studentLanguage", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "teacher", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "guardian", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "guardianEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "emergencyContact", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "notes", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], StudentUpdateDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "studentExtId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentUpdateDto.prototype, "flagMessage", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], StudentUpdateDto.prototype, "sendLettersViaEmail", void 0);
__decorate([
    (0, class_transformer_2.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], StudentUpdateDto.prototype, "studentGroupId", void 0);
