import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import {
  CourseScheduleImportDefaultMapping,
  Helpers,
  ImportFrequency,
  ImportMappingConfiguration,
  ImportSubject,
  SftpAccountExistingDto,
  SftpAccountResponseDto,
  SftpSettingsCreateDto,
  SftpSettingsResponseDto,
  SftpSettingsUpdateDto,
  StudentImportDefaultMapping,
  UserImportDefaultMapping
} from '@whetstoneeducation/hero-common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppSftpService } from '../sftp.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  dtoToFormGroup,
  getValue
} from '../../../shared/validation/validation.util';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { SftpAccountExistingBase } from '../sftp-account-existing-base';
import Papa from 'papaparse';
import { logger } from '../../../shared/logger';
import { MatDialog } from '@angular/material/dialog';
import { AppSftpAccountCreateComponent } from '../sftp-account-create/sftp-account-create.component';
import { validate, ValidationError } from 'class-validator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppValidationDialogComponent } from '../../../shared/validation/validation-dialog/validation-dialog.component';
import { CronDaysOfWeek } from '../../../shared/constants/cronDaysOfWeek';
import { MatSelectChange } from '@angular/material/select';

export interface Email {
  address: string;
}

@Component({
  styleUrls: ['./sftp-settings.scss'],
  selector: 'app-sftp-settings',
  templateUrl: 'sftp-settings.template.html'
})
export class AppSftpSettingsCreateEdit
  extends SftpAccountExistingBase
  implements AfterViewInit, OnDestroy
{
  readonly separatorKeysCodes = [186 /* semicolon keycode */] as const;
  public contactEmails: Email[] = [];
  public sftpSettings: SftpSettingsCreateDto | SftpSettingsUpdateDto;
  public sftpAccounts: SftpAccountResponseDto[];
  public sftpSettingsForm: FormGroup;
  public syncFrequencies = Object.values(ImportFrequency);
  public importSubjects = Object.values(ImportSubject);
  public fileDataSource: string[][] = [];
  public fileDisplayedColumns: string[] = [];
  public hasHeaders = false;
  public mappingConfig: ImportMappingConfiguration;
  // setting to true here and false in ngAfterViewInit to
  // trigger binding on first load
  public importScopeSelected: boolean = true;
  public formErrors: ValidationError[] = [];
  private settingsId?: number = null;
  private parsedResults: string[][];
  private destroy$: Subject<void> = new Subject<void>();
  private importFile: File;
  readonly weekDays = CronDaysOfWeek;
  public timezone: string;

  constructor(
    public readonly route: ActivatedRoute,
    public readonly formBuilder: FormBuilder,
    public readonly toastService: AppToastManagerService,
    public readonly sftpService: AppSftpService,
    public readonly pageHeaderService: AppPageHeaderService,
    public router: Router,
    private dialog: MatDialog
  ) {
    super(sftpService);
    this.loadData();
    this.setInitialValues().then(() => {
      logger.debug('Initial values set');
    });
    this.sftpSettingsForm = dtoToFormGroup(
      this.sftpSettings,
      this.formBuilder,
      {
        updateOn: 'change',
        exclude: ['contactEmails']
      }
    );
  }

  private async setInitialValues(): Promise<void> {
    // setting this here to false to trigger binding on first load
    // otherwise the value isn't picked up on render
    this.importScopeSelected =
      this.sftpSettings.constructor === SftpSettingsCreateDto;

    if (
      this.sftpSettings.constructor === SftpSettingsUpdateDto &&
      this.sftpSettings.mappingConfig
    ) {
      this.settingsId = this.sftpSettings.id;
      this.mappingConfig = this.sftpSettings.mappingConfig;
      if (this.mappingConfig.availableColumns.length === 0) {
        this.setImportSubject(this.sftpSettings.importSubject);
      }

      this.accountFilters = new SftpAccountExistingDto({
        schoolGroupId: this.sftpSettings.schoolGroupId,
        schoolId: this.sftpSettings.schoolId,
        groupLevel: this.sftpSettings.isSchoolGroupLevel
      });
      await this.updateSftpAccounts();
    }
    this.hasHeaders = this.mappingConfig && this.mappingConfig.hasHeaderRow;
    this.populateContactEmailsFromDto();
  }

  private async setValue(): Promise<void> {
    // pull form values into settings dto
    if (this.sftpSettings.constructor === SftpSettingsUpdateDto) {
      const result = await getValue<SftpSettingsUpdateDto>(
        this.sftpSettingsForm,
        SftpSettingsUpdateDto
      );
      this.sftpSettings.mapFields(result);
    } else {
      this.sftpSettings = await getValue<SftpSettingsCreateDto>(
        this.sftpSettingsForm,
        SftpSettingsCreateDto
      );
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    // this isn't being selected properly on load for some reason
    this.sftpSettingsForm
      .get('isSchoolGroupLevel')
      .setValue(this.sftpSettings.isSchoolGroupLevel?.toString(), {
        emitEvent: false
      });
    this.sftpSettingsForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value) => {
        // save current filter values before updating them
        this.lastAccountFilters = new SftpAccountExistingDto(
          this.accountFilters
        );

        if (this.sftpSettings.sftpAccountId) {
          this.sftpSettingsForm
            .get('sftpAccountId')
            .setValue(this.sftpSettings.sftpAccountId, { emitEvent: false });
        }

        if (
          this.sftpSettings.syncFrequency === ImportFrequency.OneTime ||
          this.sftpSettingsForm.get('syncFrequency').value ===
            ImportFrequency.OneTime
        ) {
          this.sftpSettingsForm
            .get('startDate')
            .setValue(new Date(), { emitEvent: false });
        }

        // parse contact emails to ; separated string
        this.setContactEmailsFromInput();
        await this.setValue();

        this.accountFilters = new SftpAccountExistingDto({
          schoolGroupId: this.sftpSettings.schoolGroupId,
          schoolId: this.sftpSettings.schoolId,
          groupLevel: this.sftpSettings.isSchoolGroupLevel
        });
        // update school select options if school group changes
        if (
          this.lastAccountFilters.schoolGroupId !==
          this.accountFilters.schoolGroupId
        ) {
          super.schoolGroupChanged();
        }
        const schoolSelect = this.sftpSettingsForm.get('schoolId');
        if (schoolSelect && !schoolSelect.value) {
          this.accountFilters.schoolId = null;
          this.filtersReady = false;
        }
        // if group level set school select to null
        if (this.accountFilters.groupLevel) {
          this.sftpSettings.schoolId = null;
          schoolSelect.setValue(null, { emitEvent: false });
          this.accountFilters.schoolId = null;
        }
        // Check filter values, mark ready and fetch data
        if (
          (this.accountFilters.schoolGroupId && this.accountFilters.schoolId) ||
          (this.accountFilters.groupLevel && this.accountFilters.schoolGroupId)
        ) {
          this.filtersReady = true;
          // Only fetch new data if filters have changed
          if (
            this.accountFilters.schoolId !== this.lastAccountFilters.schoolId ||
            this.accountFilters.schoolGroupId !==
              this.lastAccountFilters.schoolGroupId ||
            this.accountFilters.groupLevel !==
              this.lastAccountFilters.groupLevel
          ) {
            await this.updateSftpAccounts();
          }
        }
        // to support the error dialog
        this.setErrors(await validate(this.sftpSettings));
      });
  }

  public importScopeChange() {
    this.importScopeSelected = true;
  }

  public showFileNameInput(): boolean {
    return (
      this.sftpSettings.syncFrequency &&
      this.sftpSettings.syncFrequency != ImportFrequency.OneTime
    );
  }

  public showGetFromCloud(): boolean {
    if (this.sftpSettings.constructor === SftpSettingsUpdateDto) {
      return (
        this.sftpSettings.filePattern &&
        this.sftpSettings.syncFrequency != ImportFrequency.OneTime
      );
    } else {
      return false;
    }
  }

  private setErrors(errors: ValidationError[], filterErrors?: string[]): void {
    if (filterErrors) {
      errors = errors.filter((error) => !filterErrors.includes(error.property));
    }
    this.formErrors = errors;
  }

  public showFileUpload(): boolean {
    return this.sftpSettings.syncFrequency == ImportFrequency.OneTime;
  }

  public hasBeenSaved(): boolean {
    return this.settingsId !== null;
  }

  public async accountClicked(event: MouseEvent): Promise<void> {
    const accountId = this.sftpAccounts[0].accountId;
    if (accountId) {
      await navigator.clipboard.writeText(accountId);
      this.toastService.success('Account ID copied to clipboard');
    }

    event.stopPropagation();
  }

  private async updateSftpAccounts(): Promise<void> {
    this.sftpAccounts = await this.sftpService.getMatchingSftpAccounts(
      this.accountFilters
    );
    this.sftpSettings.sftpAccountId = this.sftpAccounts[0]?.id;
  }

  public importTypeChanged(event: MatSelectChange): void {
    this.setImportSubject(event.value);
  }

  private setImportSubject(value: ImportSubject): void {
    switch (value) {
      case ImportSubject.STUDENT_IMPORT:
        this.mappingConfig = StudentImportDefaultMapping;
        break;
      case ImportSubject.USER_IMPORT:
        this.mappingConfig = UserImportDefaultMapping;
        break;
      case ImportSubject.SCHEDULE_IMPORT:
        this.mappingConfig = CourseScheduleImportDefaultMapping;
        break;
      default:
        this.mappingConfig = null;
    }
    if (this.sftpSettings.constructor === SftpSettingsUpdateDto) {
      this.sftpSettings.mappingConfig = this.mappingConfig;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public createNewAccount(): void {
    this.dialog
      .open(AppSftpAccountCreateComponent, {
        width: '500px',
        disableClose: true
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result) => {
        if (result) {
          console.log('dialog closed with result');
        }
        await this.updateSftpAccounts();
      });
  }

  public loadData(): void {
    super.loadData();
    this.isLoading = true;
    const data = this.route.snapshot.data.data as SftpSettingsResponseDto;
    if (data.id) {
      this.sftpSettings = new SftpSettingsUpdateDto(data);
    } else {
      this.sftpSettings = new SftpSettingsCreateDto(data);
    }
    this.currentUser = this.StorageManager.getLoggedInUser();
    this.schoolGroups = this.currentUser.schoolGroups;
    this.schools = this.currentUser.schoolGroups[0].schools;
    const user = this.StorageManager.getLoggedInUser();
    this.timezone = user.settings.timezone;
    this.isLoading = false;
  }

  public handleFileChanged(file: File): void {
    if (!file || !(this.sftpSettings.constructor === SftpSettingsUpdateDto)) {
      logger.error('No file selected or settings not yet saved.');
      return;
    }
    this.sftpSettings.filePattern = file.name;
    validate(this.sftpSettings).then((errors) => {
      if (errors.length === 0) {
        this.setErrors([]);
      } else {
        // filter mappingConfig errors
        this.setErrors(errors, ['mappingConfig']);
      }
    });
    this.importFile = file;
    const chunkSize = 512 * 1024; // 512KB chunk size
    const chunk = file.slice(0, chunkSize); // Taking only the first chunk of the file

    const reader = new FileReader();
    const component = this;

    reader.onload = () => {
      const chunkContent = reader.result as string;
      // Parse the chunkContent with PapaParse
      Papa.parse<string[]>(chunkContent, {
        complete: function (results) {
          component.parsedResults = results.data;
          component.populateFileDataSource(component, component.parsedResults);
        }
      });
    };

    reader.onerror = (error) => {
      logger.error('Error reading file:', error);
      this.toastService.error('Error reading file');
    };

    reader.readAsText(chunk);
  }

  public async getFromCloud() {
    try {
      if (this.sftpSettings.constructor === SftpSettingsUpdateDto) {
        this.isLoading = true;
        const fileResponse = await this.sftpService.getCloudFile(
          this.sftpSettings.sftpAccountId,
          this.sftpSettings.filePattern
        );
        if (fileResponse.status === 404) {
          logger.error(
            `File not found on SFTP account ${this.sftpSettings.sftpAccountId} with name ${this.sftpSettings.filePattern}`
          );
          this.toastService.error('File not found on SFTP account');
        }
        const fileString = fileResponse.file;
        const newFile = new File([fileString], this.sftpSettings.filePattern);
        this.handleFileChanged(newFile);
        this.isLoading = false;
      } else {
        logger.error('Settings not yet saved');
      }
    } catch (error) {
      const message = 'Could not get file from cloud';
      logger.error(message, error);
      this.toastService.error(message);
      this.isLoading = false;
    }
  }

  public populateFileDataSource(
    component: AppSftpSettingsCreateEdit,
    results: string[][]
  ): void {
    if (component.hasHeaders) {
      component.fileDisplayedColumns = results[0];
      component.fileDataSource = results.slice(1, 11);
    } else {
      component.fileDisplayedColumns = Array.from(
        { length: results[0].length },
        (_, index) => Helpers.generateColumnName(index + 1)
      );
      component.fileDataSource = results.slice(0, 10);
    }
  }

  // Convert semicolon-delimited string to array of email addresses
  private populateContactEmailsFromDto(): void {
    this.contactEmails = this.sftpSettings.contactEmails
      ? this.sftpSettings.contactEmails
          .split(';')
          .map((email) => ({ address: email.trim() }))
      : [];
  }

  private setContactEmailsFromInput(): void {
    this.sftpSettings.contactEmails = this.contactEmails
      .map((email) => email.address)
      .join('; ');
    validate(this.sftpSettings).then((errors) => {
      this.setErrors(errors, ['mappingConfig']);
    });
  }

  public addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our email
    if (value) {
      this.contactEmails.push({ address: value });
    }

    this.setContactEmailsFromInput();

    // Clear the input value
    event.chipInput!.clear();
  }

  public removeEmail(email: Email): void {
    const index = this.contactEmails.indexOf(email);

    if (index >= 0) {
      this.contactEmails.splice(index, 1);
    }

    this.setContactEmailsFromInput();
  }

  public editEmail(email: Email, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove email if it no longer has an address
    if (!value) {
      this.removeEmail(email);
      return;
    }

    // Edit existing email
    const index = this.contactEmails.indexOf(email);
    if (index >= 0) {
      this.contactEmails[index].address = value;
    }

    this.setContactEmailsFromInput();
  }

  private showErrorDialog(): void {
    this.dialog.open(AppValidationDialogComponent, {
      disableClose: true,
      data: {
        errors: this.formErrors,
        formControlMapping: {
          sftpAccountId: 'SFTP Account',
          syncFrequency: 'Sync Frequency',
          importSubject: 'Import Subject',
          startDate: 'Start Date',
          filePattern: 'File Pattern',
          removeMissingRecords: 'Remove Missing Records',
          contactEmails: 'Contact Emails',
          isSchoolGroupLevel: 'Is Group or School Level',
          mappingConfig: 'Mapping Configuration',
          importTime: 'Import Time',
          importDays: 'Import Days'
        }
      }
    });
    this.dialog.afterAllClosed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setErrors([]);
    });
  }

  public async validateSettings(): Promise<boolean> {
    this.setErrors(await validate(this.sftpSettings));

    if (this.formErrors.length > 0) {
      // display errors in a dialog
      this.showErrorDialog();
      return false;
    }
    return true;
  }

  public async saveSettings(): Promise<void> {
    if (
      this.sftpSettings &&
      !this.sftpSettings.sftpAccountId &&
      this.sftpAccounts &&
      this.sftpAccounts.length > 0
    ) {
      this.sftpSettings.sftpAccountId = this.sftpAccounts[0].id;
    }

    //set the mappingConfig if this is an update rather than a create
    if (this.sftpSettings.constructor === SftpSettingsUpdateDto) {
      this.mappingConfig.hasHeaderRow = this.hasHeaders;
      this.sftpSettings.mappingConfig = this.mappingConfig;
    }

    if (!(await this.validateSettings())) {
      return;
    }

    this.isLoading = true;
    try {
      if (this.sftpSettings.constructor === SftpSettingsCreateDto) {
        const result = await this.sftpService.createSftpSettings(
          this.sftpSettings
        );
        this.settingsId = result.id;
        this.sftpSettings = new SftpSettingsUpdateDto().mapFields(result);
        this.sftpSettingsForm = dtoToFormGroup(
          this.sftpSettings,
          this.formBuilder,
          {
            updateOn: 'change',
            exclude: ['contactEmails']
          }
        );
        // this isn't being selected properly on load for some reason
        this.sftpSettingsForm
          .get('isSchoolGroupLevel')
          .setValue(this.sftpSettings.isSchoolGroupLevel?.toString(), {
            emitEvent: false
          });
        await this.setInitialValues();
        await this.router.navigate(['sftp/settings', result.id]);
      } else {
        const result = await this.sftpService.updateSftpSettings(
          this.sftpSettings as SftpSettingsUpdateDto
        );
        this.sftpSettings = new SftpSettingsUpdateDto(result);
        this.settingsId = result.id;
      }

      this.toastService.success('SFTP settings saved successfully');
      this.isLoading = false;
    } catch (err) {
      this.toastService.error('Error saving SFTP settings');
      this.isLoading = false;
    }
  }

  public async uploadFile() {
    await this.saveSettings();
    if (!(await this.validateSettings())) {
      return;
    }
    const uploadResult: Observable<Object> =
      await this.sftpService.uploadFileImport(this.settingsId, this.importFile);
    if (uploadResult) {
      uploadResult.pipe(takeUntil(this.destroy$)).subscribe((result) => {
        this.toastService.success('File uploaded successfully');
      });
    } else {
      this.toastService.error('Error uploading file');
    }
  }

  public async runImport() {
    await this.saveSettings();
    if (!(await this.validateSettings())) {
      return;
    }

    if (this.settingsId) {
      const result = await this.sftpService.runImport(this.settingsId);
      if (result) {
        this.toastService.success('Import queued!');
        return;
      }
    }
    this.toastService.error('Error running import.');
  }

  public async saveAndQueue() {
    if (this.sftpSettings.syncFrequency == ImportFrequency.OneTime) {
      await this.uploadFile();
    } else {
      await this.runImport();
    }
  }

  public showRunNow(): boolean {
    return (
      this.sftpSettings.constructor === SftpSettingsUpdateDto &&
      !!this.settingsId
    );
  }

  public columnClicked(columnIndex: number): void {
    // Mat menu opens the menu, this is a placeholder for now
  }

  public applyTransform(columnIndex: number): void {
    // Add your transformation logic here
  }

  public headerToggle(event: MatCheckboxChange): void {
    this.hasHeaders = event.checked;
    this.populateFileDataSource(this, this.parsedResults);
  }

  public showTime(): boolean {
    const show = [
      ImportFrequency.Daily,
      ImportFrequency.Weekly,
      ImportFrequency.Monthly
    ];
    return show.includes(this.sftpSettings.syncFrequency);
  }

  public showDays(): boolean {
    const show = [ImportFrequency.Weekly];
    return show.includes(this.sftpSettings.syncFrequency);
  }

  public showStartDate(): boolean {
    return (
      this.sftpSettings.syncFrequency &&
      this.sftpSettings.syncFrequency != ImportFrequency.OneTime
    );
  }

  public disableRunNow(): boolean {
    return (
      (this.sftpSettings.syncFrequency == ImportFrequency.OneTime &&
        this.fileDataSource.length === 0) ||
      this.formErrors.length > 0
    );
  }

  protected readonly ImportFrequency = ImportFrequency;
  protected readonly ImportSubject = ImportSubject;
}
