"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseInviteTemplateVariablesDto = void 0;
const notification_templates_1 = require("../notification-templates");
class BaseInviteTemplateVariablesDto extends notification_templates_1.BaseTemplateVariablesDto {
    constructor(userInviteTemplateVariablesOptions) {
        super();
        Object.assign(this, userInviteTemplateVariablesOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return [...super.getFields(), 'email', 'link'];
    }
    link;
    email;
}
exports.BaseInviteTemplateVariablesDto = BaseInviteTemplateVariablesDto;
