import { Component, Input } from "@angular/core";
import { MenuAction } from "./menu-action.interface";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { CommonModule } from "@angular/common";
import { AppMaterialModule } from "../../app-material.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.template.html',
  styleUrls: ['./menu.scss'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FontAwesomeModule, MatButtonModule, MatMenuModule],
})
export class AppMenuComponent {

  @Input()
  public actions: MenuAction[] = [];

  @Input()
  public title: string = "Bulk Operations";

  @Input() containerStyles: string = '';


  trackByAction(index: number, action: MenuAction) {
    return action.key;
  }
}
