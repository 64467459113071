import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { UserTypeEnum } from '@whetstoneeducation/hero-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AppDashboardComponent extends BaseComponent implements OnInit {
  public showError = false;
  constructor(public router: Router) {
    super();
  }

  async ngOnInit() {
    try {
      const { type } = this.StorageManager.getLoggedInUser();
      if (type === UserTypeEnum.GROUP_ADMIN) {
        await this.router.navigate(['/dashboard/school']);
      } else if (type === UserTypeEnum.SCHOOL) {
        await this.router.navigate(['/dashboard/school']);
      } else if (type === UserTypeEnum.GUARDIAN) {
        await this.router.navigate(['/dashboard/guardian']);
      } else if (type === UserTypeEnum.INTERNAL_ADMIN) {
        await this.router.navigate(['/dashboard/school']);
      } else if (type === UserTypeEnum.TEACHER) {
        await this.router.navigate(['/dashboard/teacher']);
      } else if (type === UserTypeEnum.STUDENT) {
        await this.router.navigate(['/dashboard/student']);
      }
    } catch (e) {
      console.error(e);
    }

    this.showError = true;
  }
}
