<div class="twoColumnLayout">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>Track a Student</mat-card-title>
    </mat-card-header>
    <mat-card-content class="dashboard-container-content">
      <app-students-list-filters
        [selectedStudentIds]="[]"
        [showTrackButton]="false"
        [filters]="filters"
        (filtersUpdated)="onStudentFiltersChanged($event)" />
      <button
        mat-flat-button
        id="dashboardStudentTrackingButton"
        type="button"
        class="standard-button secondary-button"
        (click)="handleStudentSearch()">
        Search
      </button>
    </mat-card-content>
  </mat-card>
  <ng-container *ngIf="outOfAcademicYear">
    <mat-card appearance="outlined">
      <mat-card-header class="card-header">
        <mat-card-title>Today's Schedules</mat-card-title>
      </mat-card-header>
      <mat-card-content class="contact-support-academic-calendar">
        No Schedule for today, Please contact support to expand your academic
        calendar
      </mat-card-content>
    </mat-card>
  </ng-container>
  <ng-container *ngIf="!outOfAcademicYear">
    <app-today-schedule
      [todaySchedules]="todaySchedules"
      (scheduleChanged)="updateTodaySchedule($event)" />
  </ng-container>
</div>

<div class="singleColumnLayout">
  <app-student-top-performers
    [topPerformers]="topPerformers"></app-student-top-performers>
</div>
