import { ResolveFn } from '@angular/router';
import {
  AccruedReportResponseDto,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { inject } from '@angular/core';
import { AppReportsService } from '../reports.service';

export const accruedReportResolver: ResolveFn<
  PaginationResultsDto<AccruedReportResponseDto>
> = async (route, state) => {
  return inject(
    AppReportsService
  ).getAccruedReport();
};
