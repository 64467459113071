import { AppStudentListCard } from '../../pages/students/students-list/student-list-card/student-list-card.component';
import { AppTeacherRostersListCard } from '../../pages/teacher-rosters/teacher-rosters-list/teacher-rosters-list-card/teacher-rosters-list-card.component';
import { logger } from '../logger';

export function commonViewStudentProfile(
  context: AppStudentListCard | AppTeacherRostersListCard,
  studentId: number
): void {
  const url = context.router.serializeUrl(
    context.router.createUrlTree([`/students/${studentId}/profile`])
  );
  // open student profile in new tab
  // instead of using the same tab
  const newTab = window.open(url, '_blank');
  if (!newTab || newTab.closed || typeof newTab.closed == 'undefined') {
    // new tab was blocked
    logger.error('New tab was blocked');
    context.toastService.warn(
      'New tab was blocked, to open student profile in a new tab please allow popups for this site. Navigation should proceed in 3 seconds.'
    );
    setTimeout(() => {
      context.router.navigate([`/students/${studentId}/profile`]);
    }, 3000);
  }
}
