"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentBehaviorHistoryResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
class StudentBehaviorHistoryResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'date',
            'behaviorCodeName',
            'reactionName',
            'reactionDate',
            'complied',
            'compliedRequired',
            'points',
            'reporter',
            'behaviorEntryId',
            'behaviorCodeId',
            'active',
            'cancelled',
            'notes'
        ];
    }
    id;
    date;
    behaviorCodeName;
    reactionName;
    reactionDate;
    compliedDate;
    compliedRequired;
    points;
    reporter;
    behaviorEntryId;
    behaviorCodeId;
    active;
    notes;
    cancelled;
}
exports.StudentBehaviorHistoryResponseDto = StudentBehaviorHistoryResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentBehaviorHistoryResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentBehaviorHistoryResponseDto.prototype, "date", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], StudentBehaviorHistoryResponseDto.prototype, "behaviorCodeName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], StudentBehaviorHistoryResponseDto.prototype, "reactionName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentBehaviorHistoryResponseDto.prototype, "reactionDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentBehaviorHistoryResponseDto.prototype, "compliedDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Boolean)
], StudentBehaviorHistoryResponseDto.prototype, "compliedRequired", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentBehaviorHistoryResponseDto.prototype, "points", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], StudentBehaviorHistoryResponseDto.prototype, "reporter", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentBehaviorHistoryResponseDto.prototype, "behaviorEntryId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentBehaviorHistoryResponseDto.prototype, "behaviorCodeId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Boolean)
], StudentBehaviorHistoryResponseDto.prototype, "active", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], StudentBehaviorHistoryResponseDto.prototype, "notes", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Boolean)
], StudentBehaviorHistoryResponseDto.prototype, "cancelled", void 0);
