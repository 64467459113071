import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBehaviorCodeEntryService } from '../behavior-code-entry.service';
import {
  BehaviorCodeEntryCreateDto,
  BehaviorCodeResponseDto,
  StudentBasicResponseDto
} from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-fast-track-modal',
  templateUrl: './fast-track-modal.component.html',
  styleUrls: ['./fast-track-modal.scss']
})
export class FastTrackModalComponent extends BaseComponent implements OnInit {
  public isLoading = true;
  public behaviorCodes: BehaviorCodeResponseDto[];
  public selectedBehaviorCodeIds: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<FastTrackModalComponent>,
    public toastService: AppToastManagerService,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      students: StudentBasicResponseDto[];
      onClose?: () => void;
    }
  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      this.behaviorCodes =
        await this.behaviorCodeEntryService.getFastTrackBehaviorCodes();
    } catch (error) {
      this.toastService.error('Error loading data');
    }
    this.isLoading = false;
  }

  toggleBehaviorCode(behaviorCodeId: number) {
    if (this.selectedBehaviorCodeIds.includes(behaviorCodeId)) {
      this.selectedBehaviorCodeIds = this.selectedBehaviorCodeIds.filter(
        (id) => id !== behaviorCodeId
      );
    } else {
      this.selectedBehaviorCodeIds.push(behaviorCodeId);
    }
  }

  getBehaviorCodeTileClass(behaviorCode: BehaviorCodeResponseDto) {
    const type = behaviorCode.type.name.toLowerCase();

    const selected = this.selectedBehaviorCodeIds.includes(behaviorCode.id);
    return 'behavior-code-tile ' + type + (selected ? '-selected' : '');
  }

  async submit() {
    this.isLoading = true;
    try {
      const schoolId = this.StorageManager.getCurrentSchoolId();
      const behaviorCodesToCreate: BehaviorCodeEntryCreateDto[] =
        this.selectedBehaviorCodeIds.reduce((acc, id) => {
          acc.push(
            ...this.data.students.map(
              (student) =>
                new BehaviorCodeEntryCreateDto({
                  notes: '',
                  behaviorCodeId: id,
                  studentId: student.id,
                  schoolId,
                  time: Date.now()
                })
            )
          );
          return acc;
        }, []);

      await this.behaviorCodeEntryService.batchCreateBehaviorCodeEntries(
        behaviorCodesToCreate
      );

      this.toastService.success(
        this.data.students.length + ' students have been tracked'
      );
      this.dialogRef.close();
      if (this.data.onClose) {
        this.data.onClose();
      }
    } catch (error) {
      this.toastService.error('Error creating behavior code entries');
    }
    this.isLoading = false;
  }

  getStudentSelectedText() {
    if (!this.data.students || this.data.students.length === 0) return '';

    const [first, second] = this.data.students;
    return this.data.students.length === 1
      ? this.getStudentName(first)
      : `${this.getStudentName(first)}${
          second ? ' and ' + this.getStudentName(second) : ''
        }${
          this.data.students.length > 2
            ? ', ' + (this.data.students.length - 2) + ' more'
            : ''
        }`;
  }

  getStudentName(student: StudentBasicResponseDto) {
    return student.firstName + ' ' + student.lastName;
  }
}
