import { IFormError } from './form-error.interface';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { validate, ValidationError } from 'class-validator';
import { MappableBase } from '@whetstoneeducation/hero-common';
import { ClassConstructor, plainToInstance } from 'class-transformer';
import { AppToastManagerService } from '../services/toast-manager.service';

/**
 * @interface DtoToFormGroupOptions
 * @description Options for the dtoToFormGroup function
 * @property {boolean} mapId - If true, the id property will be mapped to the form group. Do non set this value if the id property is already in the objects field list.
 * @property {exclude} string[] - An array of property names to exclude from the form group
 * @property {disable} string[] - An array of property names to disable on the form group
 * @property {boolean} touched - If true, the form group will be marked as touched.
 * @property {string} updateOn - The updateOn value for the form group [default: 'blur'] [options: 'blur', 'change', 'submit']
 */
export interface DtoToFormGroupOptions {
  exclude?: string[];
  mapId?: boolean;
  disable?: string[];
  touched?: boolean;
  updateOn?: 'blur' | 'change' | 'submit';
}
/**
 * Update the errors on a form based on the validation errors returned from the server.
 * @param {ValidationError[]} formErrors - An array of validation errors returned from the server.
 * @param {AbstractControl} form - The form group or control where the errors will be displayed.
 */
export function updateFormErrors(
  formErrors: ValidationError[],
  form: AbstractControl
): void {
  formErrors.forEach((formError) => {
    const control = form.get(formError.property);
    if (control) {
      control.setErrors(formError.constraints);
    }
  });
}

/**
 * Map an error response from the server to a generalized error that can be displayed through the toast manager
 * or other means.
 * @param {any} response - The error response from the server.
 * @returns {IFormError[]} An array of form errors to be displayed on the form.
 */
// TODO: This is not really wired up yet.
//  It still needs supporting code in the components and probably some refactoring.
export function mapApiErrorResponse(response: any): IFormError[] {
  const formErrors: IFormError[] = [];

  for (const message of response.message) {
    const [inputName, errorMessage] = message.split(' ');
    formErrors.push({
      inputName: inputName.substring(0, inputName.indexOf('.')),
      message: errorMessage
    });
  }

  return formErrors;
}

/**
 * Touch all invalid form controls so that their errors will be displayed.
 * @param {FormGroup} form - The form group to touch the controls on.
 */
export function displayFormErrors(form: FormGroup): void {
  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);
    if (control.invalid) {
      control.markAsTouched();
    }
  });
}

/**
 * Convert a data transfer object (DTO) to a form group that can be used in an Angular template-driven form.
 * @param {MappableBase} dto - The DTO to convert to a form group.
 * @param {FormBuilder} formBuilder - The Angular FormBuilder service used to create the form group.
 * @param {DtoToFormGroupOptions} options - Options for the conversion.
 * @returns {FormGroup} The resulting form group.
 */
export function dtoToFormGroup(
  dto: MappableBase,
  formBuilder: FormBuilder,
  options?: DtoToFormGroupOptions
): FormGroup {
  const group = {};
  let fields = dto.getFields();
  if (dto.getTransforms().length > 0)
    dto.getTransforms().map((transform) => fields.push(transform.destination));
  if (options?.mapId) fields.push('id');
  if (options?.exclude)
    fields = fields.filter((field) => !options.exclude.includes(field));

  fields.forEach((field) => {
    group[field] = formBuilder.control(
      {
        value: dto[field],
        disabled: options?.disable?.includes(field)
      },
      {
        updateOn: options?.updateOn ? options.updateOn : 'blur'
      }
    );
  });
  let nested = dto.getNested();
  if (options?.exclude)
    nested = nested.filter(
      (field) => !options.exclude.includes(field.destField)
    );
  nested.forEach((subfield) => {
    if (dto[subfield.destField])
      group[subfield.destField] = dtoToFormGroup(
        dto[subfield.destField],
        formBuilder
      );
  });

  return formBuilder.group(group);
}

/**
 * Validate a form and update the form with any validation errors returning
 * a DTO with the values from the form.
 * @param {FormGroup} form - The form group to update with validation errors.
 * @param {ClassConstructor<T>} dtoClass - The class constructor for the data transfer object (DTO) that the form value will be mapped to.
 * @param skip - An array of property names to exclude from the validation.
 * @returns {T} The validated DTO.
 */
export async function validateAndGetValue<T extends MappableBase>(
  form: FormGroup,
  dtoClass: ClassConstructor<T>,
  skip: string[] = []
): Promise<T> {
  const dto = await getValue<T>(form, dtoClass);
  validate(dto).then((errors) => {
    errors = errors.filter((error) => !skip.includes(error.property));
    updateFormErrors(errors, form);
  });
  return dto;
}

/**
 * Validate a form and update the form with any validation errors returning
 * a DTO with the values from the form.
 * @param {FormGroup} form - The form group to update with validation errors.
 * @param {ClassConstructor<T>} dtoClass - The class constructor for the data transfer object (DTO) that the form value will be mapped to.
 * @param skip - An array of property names to exclude from the transformation.
 * @returns {T} The validated DTO.
 */
export async function getValue<T extends MappableBase>(
  form: FormGroup,
  dtoClass: ClassConstructor<T>
): Promise<T> {
  return plainToInstance<T, any>(dtoClass, form.value);
}

/**
 * Check if a form is ready to be saved. Will also show errors if form is invalid.
 * @param {FormGroup} form - The form to check if it can be saved.
 * @param {AppToastManagerService} [toastService] - The service used to show errors on form.
 * @param detectTouch
 * @returns {boolean} true if form is ready to save, false otherwise.
 */
export function formCanSave(
  form: FormGroup,
  toastService?: AppToastManagerService,
  detectTouch = true
): boolean {
  const notify = !!toastService;
  if (detectTouch && notify && !form.touched) {
    toastService.error(
      'There is nothing to save, please make changes before saving.'
    );
    return false;
  }
  if (form.invalid && form.touched) {
    displayFormErrors(form);
    if (notify)
      toastService.error(
        'Please correct the errors on the form before saving.'
      );
    return false;
  }

  for (const control of Object.values(form.controls)) {
    if (
      control.touched &&
      control.value &&
      control.value instanceof String &&
      control.value.trim() === ''
    ) {
      if (notify) toastService.error('Required fields must not be blank.');
      return false;
    }
  }

  return true;
}
export function formsTouched(
  forms: FormGroup[],
  toastService?: AppToastManagerService
): boolean {
  const touched = forms.some((form) => form.touched);
  const notify = !!toastService;
  if (notify && !touched) {
    toastService.error(
      'There is nothing to save, please make changes before saving.'
    );
  }
  return touched;
}
