import { Component, Input } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import {
  ReactionCreateDto,
  ReactionEntriesListResponseDto,
  ReactionResponseDto,
  ReactionUpdateDto
} from '@whetstoneeducation/hero-common';
import { ReactionsListColumns } from '../reactions-list-models/reactions-list-columns.enum';
import { AppPrivilegesService } from '../../../auth/privileges.service';
import { plainToInstance } from 'class-transformer';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { logger } from '../../../../shared/logger';
import { AppReactionsService } from '../../reactions.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppDuplicateReactionComponent } from '../../duplicate-reaction/duplicate-reaction.component';

@Component({
  selector: 'app-reactions-list-table',
  templateUrl: './reactions-list-table.template.html',
  styleUrls: ['./reactions-list-table.scss']
})
export class AppReactionsListTableComponent extends BaseTableComponent<ReactionEntriesListResponseDto> {
  @Input() behaviorCodeId: number;
  @Input() reloadTable: () => Promise<void>;

  public get displayedColumns() {
    return Object.values(ReactionsListColumns);
  }

  public columnValues = {
    [ReactionsListColumns.NAME]: ['name'],
    [ReactionsListColumns.PASS_MESSAGE]: ['passMessage']
  };

  public columns = ReactionsListColumns;

  constructor(
    public privilegesService: AppPrivilegesService,
    public toastManager: AppToastManagerService,
    public reactionService: AppReactionsService,
    public router: Router,
    private dialog: MatDialog,
  ) {
    super({ privilegesService });
  }

  public async toggleActive(reaction: ReactionResponseDto, active: boolean) {
    this.isLoading = true;
    try {
      const updateReaction: ReactionUpdateDto =
        new ReactionUpdateDto().mapFields(reaction);
      updateReaction.active = active;
      await this.reactionService.updateReaction(updateReaction, reaction.id);
      this.dataArr = this.dataArr.filter((data) => data.id !== reaction.id);
      this.toastManager.success(
        `Reaction "${reaction.name}" ${
          active ? 'reactivated' : 'deactivated'
        } successfully!`
      );
    } catch (err) {
      logger.error(err);
      this.toastManager.error(
        'There was an error deactivating this intervention, please try again!'
      );
    }
    this.isLoading = false;
  }

  public async cloneReaction(reaction: ReactionResponseDto) {
    const newReaction = plainToInstance(ReactionCreateDto, reaction, {
      exposeUnsetFields: true
    });
    newReaction.name = reaction.name + ' (Clone)';
    newReaction.scheduledDays = newReaction.scheduledDays?.map(Number) ?? [];
    this.dialog.open(AppDuplicateReactionComponent, {
      data: {
        newReaction,
        onClose: async () => {
          await this.reloadTable();
          this.dialog.closeAll();
        }
      },
      width: '500px',
      height: 'auto'
    });
  }
}
