import { PrinterConfigurationResponseDto } from '@whetstoneeducation/hero-common';
import { BuilderFactory } from '../../../shared/printer/builder.factory';
import { ReactionPrintBuilder } from './behavior-code-reaction-print-builder';
import { ReactionPrintReceiptBuilder } from './behavior-code-reaction-print-receipt-builder';
import { ReactionPrintZplBuilder } from './behavior-code-reaction-print-zpl-builder';
import { ReactionPrintDymoBuilder } from './behavior-code-reaction-print-dymo-builder';
import { ReactionPrintEzplBuilder } from './behavior-code-reaction-print-ezpl-builder';

const buildersMapper = {
  'star-prnt': () => new ReactionPrintReceiptBuilder('star-prnt'),
  'zpl': () => new ReactionPrintZplBuilder(),
  'ezpl': () => new ReactionPrintEzplBuilder(),
  'esc-pos': () => new ReactionPrintReceiptBuilder('esc-pos'),
  'dymo': () => new ReactionPrintDymoBuilder(),
};

export class BehaviorCodeReactionPrintBuilderFactory extends BuilderFactory<ReactionPrintBuilder> {

  constructor(printerData?: PrinterConfigurationResponseDto) {
    super(printerData);
    this.buildersMapper = buildersMapper;
  }
}
