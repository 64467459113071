import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { IUsersListFilters } from '../users-list-models/users-list-filters.interface';

@Component({
  selector: 'app-users-list-filters',
  templateUrl: './users-list-filters.template.html',
  styleUrls: ['./users-list-filters.scss'],
})
export class AppUsersListFilters extends BaseComponent {
  @Input() filters: IUsersListFilters;

  @Output() filtersUpdated: EventEmitter<IUsersListFilters>;

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<IUsersListFilters>();
  }
}
