import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { IBehaviorCodesListFilters } from '../behavior-codes-list-models/behavior-codes-list-filters.interface';

@Component({
  selector: 'app-behavior-codes-list-filters',
  templateUrl: './behavior-codes-list-filters.template.html',
  styleUrls: ['./behavior-codes-list-filters.scss'],
})
export class AppBehaviorCodesListFiltersComponent extends BaseComponent {
  @Input() filters: IBehaviorCodesListFilters;

  @Output() filtersUpdated: EventEmitter<IBehaviorCodesListFilters>;

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<IBehaviorCodesListFilters>();
  }
}
