import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import {
  PaginationResultsDto,
  StudentBasicResponseDto,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppStudentsService } from '../../../students/students.service';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../../shared/logger';

@Component({
  selector: 'app-student-group-add-student-pop-up',
  templateUrl: './student-group-add-student-pop-up.template.html',
  styleUrls: ['./student-group-add-student-pop-up.scss']
})
export class AppStudentGroupAddStudentPopUpComponent extends BaseComponent {
  public students: StudentPerformanceDto[];
  public tableFilters: ITableFilters;
  public schoolId: number;
  public groupName: string;
  public filters: {
    firstName?: string;
    lastName?: string;
    grade?: string;
  };
  public selectedStudents: StudentPerformanceDto[];

  constructor(
    public dialogRef: MatDialogRef<AppStudentGroupAddStudentPopUpComponent>,
    public studentsService: AppStudentsService,
    public toastService: AppToastManagerService,
    @Inject(MAT_DIALOG_DATA)
    public props: {
      students: PaginationResultsDto<StudentPerformanceDto>;
      selectedStudents: StudentPerformanceDto[];
      groupName?: string;
    }
  ) {
    super();
    this.students = props.students.results;
    this.students = props.students.results;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.STUDENTS_LIST
    );
    this.tableFilters.count = this.props.students.options.totalItems;
    this.schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    this.groupName = this.props.groupName;
    this.selectedStudents = this.props.selectedStudents;
    this.filters = {};
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.tableFilters = newTableFilters;
    await this.updateStudentsList();
    this.isLoading = false;
  }

  public async updateFilters(): Promise<void> {
    this.isLoading = true;
    await this.updateStudentsList();
    this.isLoading = false;
  }

  public async updateStudentsList(): Promise<void> {
    try {
      const newData = await this.studentsService.getStudentsList({
        firstName: this.filters.firstName,
        lastName: this.filters.lastName,
        schoolId: this.schoolId,
        grade: this.filters.grade,
        tableFilters: this.tableFilters,
        isInStudentGroup: false
      });
      this.students = newData.results;
      this.tableFilters.count = newData.options?.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving bell schedules!');
      logger.error(err);
    }
  }
}
