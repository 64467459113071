<form [formGroup]="exportSettingsForm">
  <div class="export-settings-container">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          Export Settings&nbsp;
          <fa-icon icon="info-circle" class="info-icon"></fa-icon>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="settings-card-content mat-elevation-z3">
        <div>
          <mat-form-field class="full-width-input">
            <mat-label>File Name</mat-label>
            <input formControlName="fileName" matInput />
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <mat-select
              formControlName="fileDelimiter"
              placeholder="Select Delimiter">
              <mat-option
                *ngFor="let delimiter of delimiters"
                [value]="delimiter.value">
                {{ delimiter.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <mat-chip-grid #chipGrid aria-label="Enter emails">
              <mat-chip-row
                *ngFor="let email of contactEmails"
                (removed)="removeEmail(email)"
                [editable]="true"
                (edited)="editEmail(email, $event)"
                [aria-description]="'press enter to edit ' + email.address">
                {{email.address}}
                <button
                  matChipRemove
                  [attr.aria-label]="'remove ' + email.address">
                  <fa-icon icon="times">Cancel</fa-icon>
                </button>
              </mat-chip-row>
              <input
                placeholder="New email address..."
                [matChipInputFor]="chipGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addEmail($event)" />
            </mat-chip-grid>
          </mat-form-field>
          <mat-checkbox
            class="full-width-input"
            [checked]="includeHeader"
            color="primary"
            (change)="headerToggle($event)">
            Include Column Headers
          </mat-checkbox>
          <ng-container *ngIf="sftpAccounts && sftpAccounts.length > 0">
            <mat-chip-option class="account-chip" color="primary" selected>
              SFTP Account Found</mat-chip-option
            >
          </ng-container>
          <ng-container *ngIf="sftpAccounts && sftpAccounts.length === 0">
            <button
              class="new-account-button standard-button"
              mat-flat-button
              color="primary"
              (click)="createNewAccount()">
              Create New SFTP Account
            </button>
          </ng-container>
        </div>
        <div>
          <mat-form-field class="full-width-input">
            <mat-label>Select Schools</mat-label>
            <mat-select multiple formControlName="schoolIds">
              <mat-option
                *ngFor="let school of schoolsOptions"
                [value]="school.value"
                options-id="schools"
                [option]="constantize(school.display)">
                {{school.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="full-width-input"
            *ngIf="schoolsSelected && !!behaviorCodeOptions?.length">
            <mat-label>Select Codes</mat-label>
            <mat-select multiple formControlName="behaviorCodeIds">
              <mat-option
                *ngFor="let behaviorCodeOption of behaviorCodeOptions"
                [value]="behaviorCodeOption.value"
                options-id="behaviorCodes"
                [option]="constantize(behaviorCodeOption.display)">
                {{behaviorCodeOption.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <mat-label>Record Options</mat-label>
            <mat-select
              formControlName="exportCodeTimeFilter"
              placeholder="Record Options">
              <mat-option
                *ngFor="let time of exportCodeTimes | keyvalue"
                [value]="time.key">
                {{ time.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="full-width-input" float>
            <mat-label>Export Start Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="startDate" />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <mat-select
              formControlName="exportInterval"
              placeholder="Export Interval">
              <mat-option
                *ngFor="let frequency of exportFrequencies"
                [value]="frequency">
                {{ frequency | enumString }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-utc-time-input
            placeholder="Export Time"
            class="full-width-input"
            *ngIf="showTime()"
            [date]="exportSettings.startDate"
            formControlName="exportTime"
            [displayTimezone]="timezone">
          </app-utc-time-input>

          <div class="form-row" *ngIf="showStartEndTime()">
            <app-utc-time-input
              placeholder="Start Time"
              class="half-width-input"
              [date]="exportSettings.startDate"
              formControlName="exportTime"
              [displayTimezone]="timezone">
            </app-utc-time-input>
            <app-utc-time-input
              placeholder="End Time"
              class="half-width-input"
              [date]="exportSettings.startDate"
              formControlName="exportEndTime"
              [displayTimezone]="timezone">
            </app-utc-time-input>
          </div>
          <mat-form-field class="full-width-input" *ngIf="showDays()">
            <mat-select
              formControlName="exportDays"
              placeholder="Select Days of the Week"
              multiple>
              <mat-option *ngFor="let type of weekDays" [value]="type.value">
                {{type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="twoColumnLayout">
    <div class="column">
      <div class="header">Build Your Export</div>
    </div>
    <div class="column">
      <div class="mapping-action-buttons">
        <button
          mat-flat-button
          class="standard-button"
          color="primary"
          [matBadge]="formErrors.length"
          [matBadgeHidden]="formErrors.length === 0"
          [matBadgeColor]="'warn'"
          (click)="runExport()">
          Save & Run Now
        </button>
        <button
          class="standard-button import-button"
          mat-flat-button
          (click)="saveSettings()">
          Save Export
        </button>
      </div>
    </div>
  </div>
  <ng-container>
    <app-export-builder
      [fieldList]="exportFieldList"
      [exportFieldsConfig]="exportFieldsConfig"
      (onExportFieldsChange)="updateExportFields($event)"></app-export-builder>
  </ng-container>
</form>
