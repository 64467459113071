"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeRangeConstraint = exports.TimeRange = void 0;
const class_validator_1 = require("class-validator");
function TimeRange(startTimeProperty, validationOptions) {
    return (object, propertyName) => {
        (0, class_validator_1.registerDecorator)({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [startTimeProperty],
            validator: TimeRangeConstraint
        });
    };
}
exports.TimeRange = TimeRange;
/**
 * @description Custom validator for time range.
 * To be used on the end time field of a range.
 * The validation will fail if the end time is before the start time.
 * @param endTimeValue - The end time string to validate
 * @param args - The field name of the start time in this range
 * @usageNotes
 * ```ts
 * @TimeRange('startTime')
 * endTime: string;
 * ```
 */
let TimeRangeConstraint = class TimeRangeConstraint {
    validate(endTimeValue, args) {
        const [startTimeProperty] = args.constraints;
        const startTimeValue = args.object[startTimeProperty];
        if (!startTimeValue || !endTimeValue) {
            return false;
        }
        // Convert times to a comparable 24-hour format (without AM/PM)
        const startTime = this.convertTo24HourFormat(startTimeValue);
        const endTime = this.convertTo24HourFormat(endTimeValue);
        return startTime < endTime;
    }
    convertTo24HourFormat(time) {
        const [timePart, period] = time.split(' ');
        let [hours, minutes] = timePart.split(':').map(Number);
        if (period === 'PM' && hours !== 12) {
            hours += 12;
        }
        if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        return hours * 60 + minutes; // Return total minutes for easier comparison
    }
    defaultMessage() {
        return 'End time must be after start time.';
    }
};
exports.TimeRangeConstraint = TimeRangeConstraint;
exports.TimeRangeConstraint = TimeRangeConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: 'TimeRange' })
], TimeRangeConstraint);
