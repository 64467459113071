<form [formGroup]="bellScheduleForm" novalidate>
  <div class="twoColumnLayout bell-schedule-create-edit-content">
    <div class="column">
      <mat-card
        appearance="outlined"
        class="bell-schedule-form mat-elevation-z3">
        <mat-card-header>
          <mat-card-title>
            <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
            Bell Schedule Info
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="bell-schedule-form-content">
          <div class="form-row">
            <mat-form-field class="full-width-input">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
            <app-error-display
              class="error-display"
              controlName="name"
              [form]="bellScheduleForm"></app-error-display>
          </div>
          <div class="form-row">
            <mat-form-field class="full-width-input">
              <mat-label>Description</mat-label>
              <input matInput formControlName="description" />
            </mat-form-field>
            <app-error-display
              class="error-display"
              controlName="description"
              [form]="bellScheduleForm"></app-error-display>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="column" *ngIf="!isCreating">
      <mat-card appearance="outlined" class="period-table mat-elevation-z3">
        <mat-card-header class="period-table-header card-header">
          <mat-card-title>
            <fa-icon [icon]="['fas', 'bell']"></fa-icon>
            Period Details
          </mat-card-title>
          <button
            mat-flat-button
            class="secondary-button header-action-button"
            (click)="onAddPeriod()">
            <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add Period
          </button>
        </mat-card-header>
        <mat-card-content class="period-table-content">
          <app-periods-list-table
            (periodDeleted)="onPeriodDeleted($event)"
            [dataArr]="periodTimes"></app-periods-list-table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
