import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  Input,
  TemplateRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { BaseComponent } from "../base-classes/base.component";

/**
 * The ErrorBoundary Component. Catches any render ours in our app. If found, show fallback content
 */
@Component({
  selector: "app-error-boundary",
  templateUrl: "./error-boundary.template.html",
  styleUrls: ["./error-boundary.scss"],
})
export class AppErrorBoundaryComponent
  extends BaseComponent
  implements DoCheck {
  @Input() componentTemplate: TemplateRef<any>;
  @Input() redirectToErrorPage: boolean;
  public showFallbackContent: boolean;

  public constructor(
    public changeDetector: ChangeDetectorRef,
    public router: Router
  ) {
    super({});
    this.changeDetector.detach();
  }

  public ngDoCheck(): void {
    try {
      this.changeDetector.detectChanges();
    } catch (error) {
      this.handleRenderError();
    }
  }

  public handleRenderError(): void {
    if (this.redirectToErrorPage) {
      this.router.navigate(["/page-render-error"]);
    } else {
      this.showFallbackContent = true;
    }
  }
}
