<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="bell-schedules-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- Select Column -->
      <ng-container matColumnDef="{{columns.SELECT}}">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            [checked]="selectedStudents.length === dataArr.length"
            (change)="selectAllStudents()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            [checked]="isSelected(row.id)"
            (change)="selectStudent(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <app-student-profile-photo
            *ngIf="!showTableHeader"
            [selectedImage]="row.profilePhotoUrl"
            [studentId]="row.id"
            [hideProfileImageSelector]="true"
            [imageWidth]="50"
            [imageHeight]="50">
          </app-student-profile-photo>
          {{ row.firstName }} {{ row.lastName}}
        </mat-cell>
      </ng-container>

      <!-- Ref ID Column -->
      <ng-container matColumnDef="{{columns.STUDENT_ID}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.STUDENT_ID}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ !showTableHeader && row.referenceId ? "ID:" : "" }} {{
          row.referenceId }}</mat-cell
        >
      </ng-container>

      <!-- Grade Column -->
      <ng-container matColumnDef="{{columns.GRADE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.GRADE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ !showTableHeader && row.grade ? "Grade:" : "" }} {{ row.grade }}
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="{{columns.ACTION}}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.ACTION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="action-buttons-container">
            <button
              *ngIf="showSelectOptions"
              mat-flat-button
              class="outline-button action-buttons"
              options-id="ADD_BELL_SCHEDULE"
              [option]="row.id"
              (click)="studentSelected.emit(row)">
              ADD STUDENT
            </button>
            <button
              *ngIf="!showSelectOptions"
              mat-flat-button
              class="outline-button action-buttons"
              options-id="REMOVE_STUDENT"
              [option]="row.id"
              (click)="studentRemoved.emit(row.id)">
              REMOVE
            </button>
            <button
              *ngIf="!showSelectOptions"
              mat-flat-button
              class="outline-button action-buttons"
              options-id="VIEW_PROFILE"
              [option]="row.id"
              [routerLink]="'/students/' + row.id.toString() + '/profile'">
              PROFILE
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns;"
        [class.hidden]="!showTableHeader"></mat-header-row>
      <mat-row
        cdkDrag
        [cdkDragData]="row"
        *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div class="table-filters-bottom">
      <app-pagination
        [tableFilters]="tableFilters"
        (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
    </div>
  </div>
</div>
