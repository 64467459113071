<div class="mat-dialog-header">
  <div class="mat-dialog-title">Subscription Information</div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <ng-container *ngIf="!isLoading">
    <form [formGroup]="subscriptionForm" novalidate>
      <mat-form-field class="full-width-input">
        <mat-label>User Limit</mat-label>
        <input formControlName="userLimit" matInput type="number" />
        <app-error-display
          [form]="subscriptionForm"
          controlName="userLimit"></app-error-display>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <mat-label>Student Limit</mat-label>
        <input formControlName="studentLimit" matInput type="number" />
        <app-error-display
          [form]="subscriptionForm"
          controlName="studentLimit"></app-error-display>
      </mat-form-field>
      <mat-slide-toggle
        class="slide-toggle-row"
        formControlName="canCreateBehaviorCodes"
        id="canCreateBehaviorCodes"
        >Custom Behavior Codes</mat-slide-toggle
      >
      <!-- can create interventions -->
      <mat-slide-toggle
        class="slide-toggle-row"
        formControlName="canCreateInterventions"
        id="canCreateInterventions"
        >Custom Interventions</mat-slide-toggle
      >
      <!-- can import users -->
      <mat-slide-toggle
        class="slide-toggle-row"
        formControlName="canImportUsers"
        id="canImportUsers"
        >Import Users</mat-slide-toggle
      >
      <!-- can import students -->
      <mat-slide-toggle
        class="slide-toggle-row"
        formControlName="canImportStudents"
        id="canImportStudents"
        >Import Students</mat-slide-toggle
      >
      <!-- active -->
      <mat-slide-toggle
        *ngIf="isUpdateSubscription()"
        class="slide-toggle-row"
        formControlName="active"
        id="active"
        >Active</mat-slide-toggle
      >
      <!-- start date -->
      <mat-form-field class="full-width-input">
        <mat-label>Start Date</mat-label>
        <input
          formControlName="startDate"
          matInput
          [matDatepicker]="startDatePicker"
          type="text" />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <app-error-display
          [form]="subscriptionForm"
          controlName="startDate"></app-error-display>
      </mat-form-field>
      <!-- end date -->
      <mat-form-field class="full-width-input">
        <mat-label>End Date</mat-label>
        <input
          formControlName="endDate"
          matInput
          [matDatepicker]="endDatePicker"
          type="text" />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <app-error-display
          [form]="subscriptionForm"
          controlName="endDate"></app-error-display>
      </mat-form-field>
    </form>
    <div class="modal-action-row">
      <button mat-button (click)="dialogRef.close()">Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        (click)="handleSave()">
        Save
      </button>
    </div>
  </ng-container>
</div>
