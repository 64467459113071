<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="roles-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- Name Column -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
      </ng-container>

      <!-- School Column -->
      <ng-container matColumnDef="{{columns.SCHOOL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.SCHOOL}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.school_name }} </mat-cell>
      </ng-container>

      <ng-container class="action-row" matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell class="action-column-header" *matHeaderCellDef>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-cell" *matCellDef="let row">
          <button
            *ngIf="!notEditableUserTypes.includes(row.userType)"
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/roles/' + row.id.toString()"
            options-id="EDIT_ROLE"
            [option]="row.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
          <button
            *ngIf="!notEditableUserTypes.includes(row.userType)"
            mat-flat-button
            class="action-button icon-button"
            options-id="DELETE_ROLE"
            (click)="toastService.warn('delete not implemented yet')"
            [option]="row.id">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let code; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
