<form [formGroup]="userForm" novalidate>
  <input type="hidden" formControlName="id" />
  <div class="twoColumnLayout">
    <div class="column">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>Name and Email</mat-card-title>
        </mat-card-header>
        <!-- User Info Content -->
        <mat-card-content class="mat-elevation-z3">
          <!-- Email -->
          <mat-form-field class="full-width-input">
            <mat-label>Email</mat-label>
            <input matInput type="text" formControlName="email" />
            <app-error-display
              [form]="userForm"
              controlName="email"></app-error-display>
          </mat-form-field>
          <!-- Name -->
          <mat-form-field class="full-width-input">
            <mat-label>First Name</mat-label>
            <input matInput type="text" formControlName="firstName" />
            <app-error-display
              [form]="userForm"
              controlName="firstName"></app-error-display>
          </mat-form-field>
          <!-- Name -->
          <mat-form-field class="full-width-input">
            <mat-label>Last Name</mat-label>
            <input matInput type="text" formControlName="lastName" />
            <app-error-display
              [form]="userForm"
              controlName="lastName"></app-error-display>
          </mat-form-field>
          <button
            mat-flat-button
            (click)="setPassword()"
            class="secondary-button"
            options-id="SET_PASSWORD"
            [option]="user.id">
            <fa-icon [icon]="['fas','key']"></fa-icon>
            Set Password
          </button>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="column">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>School Preferences</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-elevation-z3">
          <div class="user-school-selections">
            <!-- School Group -->
            <mat-form-field class="full-width-input">
              <mat-label>School Group</mat-label>
              <mat-select
                formControlName="schoolGroupId"
                (selectionChange)="updateSchoolOptions($event)">
                <mat-option
                  *ngFor="let group of schoolGroupOptions"
                  [value]="group.value"
                  [option]="constantize(group.display)"
                  options-id="schoolGroup">
                  {{ group.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- School -->
            <mat-form-field class="full-width-input">
              <mat-label>School</mat-label>
              <mat-select formControlName="currentSchoolId">
                <mat-option
                  *ngFor="let school of schoolOptions"
                  [value]="school.value"
                  [option]="constantize(school.display)"
                  options-id="school">
                  {{ school.display }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button
            *ngIf="currentUser.type === UserTypeEnum.INTERNAL_ADMIN || currentUser.type === UserTypeEnum.GROUP_ADMIN"
            mat-flat-button
            class="secondary-button"
            (click)="clearSchoolSettings()">
            Clear School Settings
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined">
        <!-- User Info -->
        <mat-card-header>
          <mat-card-title>Contact Information</mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-elevation-z3">
          <!-- Contact Info Header -->
          <!-- Contact Info Content -->
          <div class="report-content-form">
            <!-- Address -->
            <mat-form-field class="full-width-input">
              <mat-label>Address</mat-label>
              <input matInput type="text" formControlName="address" />
              <app-error-display
                [form]="userForm"
                controlName="address"></app-error-display>
            </mat-form-field>
            <!-- State -->
            <mat-form-field class="full-width-input">
              <mat-label>State</mat-label>
              <mat-select formControlName="state" [placeholder]="user.state">
                <mat-option
                  *ngFor="let state of stateOptions"
                  [value]="state.value"
                  [option]="state.value"
                  options-id="state">
                  {{ state.display }}
                </mat-option>
              </mat-select>
              <app-error-display
                [form]="userForm"
                controlName="state"></app-error-display>
            </mat-form-field>
            <!-- City -->
            <mat-form-field class="full-width-input">
              <mat-label>City</mat-label>
              <input matInput type="text" formControlName="city" />
              <app-error-display
                [form]="userForm"
                controlName="city"></app-error-display>
            </mat-form-field>
            <!-- Zip -->
            <mat-form-field class="full-width-input">
              <mat-label> Zip</mat-label>
              <input matInput type="text" formControlName="zip" />
              <app-error-display
                [form]="userForm"
                controlName="zip"></app-error-display>
            </mat-form-field>
            <!-- Phone -->
            <mat-form-field class="full-width-input">
              <mat-label> Phone</mat-label>
              <input matInput type="text" formControlName="phone" />
              <app-error-display
                [form]="userForm"
                controlName="phone"></app-error-display>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
