<app-common-table
  tabindex="0"
  aria-label="Student schedules table"
  *ngIf="!loadingData && dataArr.length"
  [getColumnValue]="getColumnValue"
  [showPagination]="showPagination"
  [columns]="columns"
  [tableFilters]="tableFilters"
  [dataArr]="dataArr"
  (tableFiltersUpdated)="updateTableFilters($event)">
</app-common-table>

<mat-progress-bar
  *ngIf="loadingData"
  class="app-loading-bar"
  color="primary"
  mode="indeterminate">
</mat-progress-bar>

<span class="no-data-message" *ngIf="!(dataArr.length || loadingData)" aria-label="No records to show">
  There are no records to show
</span>
