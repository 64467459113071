import { Component, EventEmitter, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { UsersListColumns } from '../users-list-models/users-list-columns.enum';
import {
  PrivilegeEnum,
  UserBasicResponseDto,
  UserUpdateDto
} from '@whetstoneeducation/hero-common';
import { AppPrivilegesService } from '../../../auth/privileges.service';
import { AppUsersServices } from '../../users.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';

@Component({
  selector: 'app-users-list-table',
  templateUrl: './users-list-table.template.html',
  styleUrls: ['./users-list-table.scss']
})
export class AppUsersListTableComponent extends BaseTableComponent<UserBasicResponseDto> {
  public canManage = this.hasPrivilege(PrivilegeEnum.MANAGE_USERS);

  @Output() updateTable = new EventEmitter<void>();
  public get displayedColumns() {
    return Object.values(UsersListColumns);
  }

  public columnValues = {
    First: ['firstName'],
    Last: ['lastName'],
    ID: ['id'],
    Email: ['email'],
    'Instructor Code': ['instructorCode'],
    School: ['school', 'name'],
    'School Group': ['schoolGroup', 'name'],
    Roles: ['roles', 'name']
  };

  public columns = UsersListColumns;

  constructor(
    public privilegesService: AppPrivilegesService,
    public userServices: AppUsersServices,
    public toastService: AppToastManagerService,
    public confirmationDialog: AppConfirmationDialogService
  ) {
    super({
      privilegesService
    });
  }

  public async confirmDeactivate(user: UserBasicResponseDto): Promise<void> {
    const ref = this.confirmationDialog.openDialog({
      title: `Deactivate User ${user.firstName} ${user.lastName}`,
      content:
        'Are you sure you want to deactivate this User? This will remove their access to the system.'
    });
    ref.afterClosed().subscribe(async (result: string) => {
      if (result === DEFAULT_DIALOG_RESULT.YES) {
        await this.deactivateUser(user.id);
      }
    });
  }

  public async deactivateUser(userId: number): Promise<void> {
    try {
      await this.userServices.deactivateUser(userId);
      this.updateTable.emit();
    } catch (err) {
      this.toastService.error('Error deactivating user!');
    }
  }

  public async reactivateUser(
    user: UserUpdateDto,
    userId: number
  ): Promise<void> {
    try {
      await this.userServices.reactivateUser(userId);
      this.updateTable.emit();
    } catch (err) {
      user.active = false;
      this.toastService.error('Error reactivating user!');
    }
  }
}
