<h2>Set Password</h2>
<form class="user-password-update" [formGroup]="form">
  <mat-form-field class="full-width-input">
    <input
      matInput
      [type]="hidePassword ? 'password' : 'text'"
      placeholder="Password"
      formControlName="password"
      (keyup)="checkPasswordStrength($event)"
      (paste)="checkPasswordStrength($event)"
      (input)="checkPasswordStrength($event)"
    >
    <fa-icon
      matSuffix
      [icon]="hidePassword ? 'eye' : 'eye-slash'"
      (click)="hidePassword = !hidePassword"></fa-icon>
    <mat-hint>password strength</mat-hint>
  </mat-form-field>
  <app-color-progress-bar [value]="passwordStrength" [maxValue]="4"></app-color-progress-bar>
  <app-error-display [form]="form" controlName="password"></app-error-display>
  <mat-form-field class="full-width-input confirm-password">
    <input
      matInput
      [type]="hidePassword ? 'password' : 'text'"
      placeholder="Confirm Password"
      formControlName="passwordConfirm"
      (keyup.enter)="save()"
    >
  </mat-form-field>
  <app-error-display [form]="form" controlName="passwordConfirm"></app-error-display>
</form>
<div class="dialog-actions">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()">Save</button>
</div>
