<!-- Navbar Container -->
<mat-toolbar *ngIf="show && !isLoading" color="accent">
  <!-- Left Navbar Container -->
  <div class="navbar-left">
    <!-- Hero Logo -->
    <div class="hero-logo" tabindex="0" aria-label="SchoolMint Hero Logo" role="button" (click)="goHome()" (keypress)="goHome()">
      <img
        ngSrc="assets/img/hero-logo.png"
        alt="Hero Logo"
        height="22"
        width="234" />
    </div>
    <!-- Expand / Collapse Container -->
    <ng-container *ngFor="let navItem of navbarConfig?.leftNavbar">
      <ng-container *ngIf="navItem?.enabled !== false">
        <ng-container *ngIf="navItem?.dropdownItems" class="test">
          <button
            [attr.aria-label]="navItem?.ariaLabel"
            *ngIf="navItem?.text || navItem?.icon"
            options-id="NAVBAR_LEFT"
            [option]="constantize(navItem?.text || 'blank_nav')"
            mat-button
            [matMenuTriggerFor]="subMenu">
            <fa-icon *ngIf="navItem?.icon" [icon]="navItem.icon"></fa-icon>
            <span *ngIf="navItem?.text">{{navItem.text}}</span>
            <fa-icon
              [icon]="['fas', 'caret-down']"
              class="fa-caret-down"></fa-icon>
          </button>
          <mat-menu #subMenu="matMenu" [overlapTrigger]="false">
            <div class="navbar-menu">
              <ng-container *ngFor="let subItem of navItem?.dropdownItems">
                <div *ngIf="subItem?.enabled">
                  <button
                    [attr.aria-label]="subItem?.ariaLabel"
                    *ngIf="subItem?.route && (subItem?.text || subItem?.icon)"
                    mat-menu-item
                    [options-id]="constantize(navItem?.text || 'blank_nav')"
                    [option]="constantize(subItem?.text || 'blank_nav')"
                    [routerLink]="subItem?.route"
                    class="test3">
                    <fa-icon
                      *ngIf="subItem?.icon"
                      [icon]="subItem.icon"></fa-icon>
                    <span *ngIf="subItem?.text">{{subItem.text}}</span>
                  </button>
                  <button
                    [attr.aria-label]="subItem?.ariaLabel"
                    *ngIf="subItem?.action && (subItem?.text || subItem?.icon)"
                    mat-menu-item
                    (click)="navbarService.navbarActionPressed.next(subItem.action)">
                    <fa-icon
                      *ngIf="subItem?.icon"
                      [icon]="subItem.icon"></fa-icon>
                    <span *ngIf="subItem?.text">{{subItem.text}}</span>
                  </button>
                </div>
              </ng-container>
            </div>
          </mat-menu>
        </ng-container>
        <button
          [attr.aria-label]="navItem?.ariaLabel"
          *ngIf="!navItem?.dropdownItems && navItem?.route && (navItem?.text || navItem?.icon)"
          mat-button
          [routerLink]="navItem?.route">
          <fa-icon *ngIf="navItem?.icon" [icon]="['fas', navItem.icon]"></fa-icon>
          <span *ngIf="navItem?.text">{{navItem.text}}</span>
        </button>
        <button
          *ngIf="!navItem?.dropdownItems && navItem?.action && (navItem?.text || navItem?.icon)"
          mat-button
          (click)="navbarService.navbarActionPressed.next(navItem.action)">
          <fa-icon *ngIf="navItem?.icon" [icon]="navItem.icon"></fa-icon>
          <span *ngIf="navItem?.text">{{navItem.text}}</span>
        </button>
      </ng-container>
    </ng-container>
  </div>
  <!-- Spacer - this separates the left and right side of the navbar -->
  <span class="fill-spacer"></span>
  <!-- Right Navbar Container -->
  <div class="navbar-right">
    <ng-container *ngFor="let navItem of navbarConfig?.rightNavbar">
      <ng-container *ngIf="navItem.enabled !== false">
        <ng-container *ngIf="navItem?.dropdownItems">
          <button
            [attr.aria-label]="navItem?.ariaLabel"
            *ngIf="navItem?.text || navItem?.icon"
            mat-button
            [matMenuTriggerFor]="subMenu">
            <div class="navbar-right-button-content-wrapper">
              <div>
                <fa-icon *ngIf="navItem?.icon" [icon]="navItem.icon"></fa-icon>
              </div>
              <div>
                <span *ngIf="navItem?.text" class="navbar-button-top-text"
                  >{{navItem.text}}</span
                >
                <div
                  *ngIf="navItem?.bottomText"
                  class="navbar-button-bottom-text">
                  {{ navItem?.bottomText }}
                </div>
              </div>
            </div>
          </button>
          <mat-menu #subMenu="matMenu" class="customNavbarMenu">
            <div class="navbar-menu">
              <ng-container *ngFor="let subItem of navItem?.dropdownItems">
                <button
                  [attr.aria-label]="subItem?.ariaLabel"
                  *ngIf="subItem?.route && (subItem?.text || subItem?.icon)"
                  mat-menu-item
                  [routerLink]="subItem?.route">
                  <fa-icon
                    *ngIf="subItem?.icon"
                    [icon]="subItem.icon"></fa-icon>
                  <span *ngIf="subItem?.text">{{subItem.text}}</span>
                </button>
                <button
                  [attr.aria-label]="subItem?.ariaLabel"
                  mat-menu-item
                  *ngIf="subItem?.action && (subItem?.text || subItem?.icon)"
                  (click)="navbarService.navbarActionPressed.next(subItem.action)">
                  <fa-icon
                    *ngIf="subItem?.icon"
                    [icon]="subItem.icon"></fa-icon>
                  <span *ngIf="subItem?.text">{{subItem.text}}</span>
                </button>
              </ng-container>
            </div>
          </mat-menu>
        </ng-container>
        <button
          [attr.aria-label]="navItem?.ariaLabel"
          *ngIf="!navItem?.dropdownItems && navItem?.route && (navItem?.text || navItem?.icon)"
          mat-button
          [routerLink]="navItem?.route"
          class="navbar-right-button">
          <fa-icon *ngIf="navItem?.icon" [icon]="navItem.icon"></fa-icon>
          <span *ngIf="navItem?.text">{{navItem.text}}</span>
        </button>
        <button
          [attr.aria-label]="navItem?.ariaLabel"
          *ngIf="!navItem?.dropdownItems && navItem?.action && (navItem?.text || navItem?.icon)"
          mat-button
          (click)="navbarService.navbarActionPressed.next(navItem.action)"
          class="navbar-right-button">
          <fa-icon *ngIf="navItem?.icon" [icon]="navItem.icon" [size]="navItem?.size">
          </fa-icon>
          <span *ngIf="navItem?.text">{{navItem.text}}</span>
          <div *ngIf="navItem.icon === 'language'"
            id="google_translate_element"
            role="region"
            aria-live="polite">
          </div>
        </button>
      </ng-container>
    </ng-container>
  </div>
  <app-google-translation></app-google-translation>
</mat-toolbar>
