"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpAccountExistsResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class SftpAccountExistsResponseDto extends mappable_base_1.MappableBase {
    constructor(exists = false) {
        super();
        this.accountExists = exists;
    }
    getFields() {
        return ['accountExists'];
    }
    mapFields(source, skipNested = false, skipTransforms = false) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    accountExists;
}
exports.SftpAccountExistsResponseDto = SftpAccountExistsResponseDto;
