"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpSettingsUpdateDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const sftp_settings_create_dto_1 = require("./sftp-settings-create.dto");
const boolean_transform_1 = require("../../constants/boolean.transform");
const import_mapping_configuration_dto_1 = require("./import-mapping-configuration.dto");
class SftpSettingsUpdateDto extends sftp_settings_create_dto_1.SftpSettingsCreateDto {
    constructor(options) {
        super();
        Object.assign(this, options);
    }
    getFields() {
        return [
            'schoolId',
            'schoolGroupId',
            'syncFrequency',
            'startDate',
            'importSubject',
            'contactEmails',
            'sftpAccountId',
            'isSchoolGroupLevel',
            'id',
            'mappingConfig',
            'filePattern',
            'removeMissingRecords',
            'active',
            'importTime',
            'importDays'
        ];
    }
    mapFields(source) {
        super.mapFields(source);
        return this;
    }
    id;
    filePattern;
    active;
    mappingConfig;
}
exports.SftpSettingsUpdateDto = SftpSettingsUpdateDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)({ message: 'Settings not yet saved or ID not set.' }),
    __metadata("design:type", Number)
], SftpSettingsUpdateDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)({ message: 'File name pattern cannot be blank.' }),
    (0, class_validator_1.Matches)(/^[a-zA-Z0-9_.-]*$/, {
        message: 'File name must only contain letters, numbers, underscores, dashes, or periods. Spaces are not allowed.'
    }),
    __metadata("design:type", String)
], SftpSettingsUpdateDto.prototype, "filePattern", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SftpSettingsUpdateDto.prototype, "active", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.ValidateNested)({ message: 'Valid mapping configuration required.' }),
    __metadata("design:type", import_mapping_configuration_dto_1.ImportMappingConfiguration)
], SftpSettingsUpdateDto.prototype, "mappingConfig", void 0);
