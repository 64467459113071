"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MathUtil = void 0;
/**
 * Util class for various math functions
 */
class MathUtil {
    /**
     * Is this value a number? (Not null, NaN, undefined, true, false, '1', [], {}, or anything but number)
     */
    static isNumber(value) {
        return !isNaN(value) && typeof value === 'number' && value !== Infinity;
    }
    /**
     * Convert a string into a number. If not a valid number, returns null
     */
    static getNumberFromString(value, defaultIfNotNumber = null) {
        const number = Number(value);
        if (MathUtil.isNumber(number) && value !== '') {
            return number;
        }
        else {
            return defaultIfNotNumber;
        }
    }
}
exports.MathUtil = MathUtil;
