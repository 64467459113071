<div class="mat-dialog-header">
  <div class="mat-dialog-title">Add Students</div>
  <fa-icon (click)="handleClose()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <div class="first-row">
    <div class="custom-roster-name">
      {{ customRoster.name }}
    </div>
    <div class="students-added">{{ studentsAdded.length }} students added</div>
  </div>
  <div class="filter-row">
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>First Name</mat-label>
      <input
        data-test-id="FIRST_NAME_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.firstName"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>Last Name</mat-label>
      <input
        data-test-id="LAST_NAME_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.lastName"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>Grade</mat-label>
      <input
        data-test-id="GRADE_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.grade"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
    <mat-form-field class="filter-item input" subscriptSizing="dynamic">
      <mat-label>ID Number</mat-label>
      <input
        data-test-id="STUDENT_EXT_ID_FILTER_INPUT"
        matInput
        [(ngModel)]="filters.studentExtId"
        (ngModelChange)="updateFilters(filters)" />
    </mat-form-field>
  </div>
  <div *ngIf="students" class="mat-table">
    <app-pagination
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
    <mat-table [dataSource]="students">
      <ng-container matColumnDef="{{ columns.STUDENT_NAME }}">
        <mat-header-cell *matHeaderCellDef>
          {{ columns.STUDENT_NAME }}
        </mat-header-cell>
        <mat-cell *matCellDef="let student">
          {{ student.firstName }} {{ student.lastName }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.STUDENT_ID }}">
        <mat-header-cell *matHeaderCellDef>
          {{ columns.STUDENT_ID }}
        </mat-header-cell>
        <mat-cell *matCellDef="let student">
          {{ student.studentExtId }}
        </mat-cell> </ng-container
      ><ng-container matColumnDef="{{ columns.GRADE }}">
        <mat-header-cell *matHeaderCellDef>
          {{ columns.GRADE }}
        </mat-header-cell>
        <mat-cell *matCellDef="let student">
          {{ student.grade }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{ columns.ACTION }}">
        <mat-header-cell *matHeaderCellDef> Add </mat-header-cell>
        <mat-cell *matCellDef="let student">
          <ng-container>
            <button
              type="submit"
              mat-flat-button
              class="secondary-button"
              (click)="addStudent(student.id)"
              options-id="ADD_STUDENT_TO_ROSTER">
              <fa-icon [icon]="['fas', 'plus']"></fa-icon>
            </button>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let code; columns: displayedColumns"></mat-row>
    </mat-table>
    <app-pagination
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
