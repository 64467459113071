import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { ImportMappingConfiguration } from '@whetstoneeducation/hero-common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { validate } from 'class-validator';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-mapping-table',
  templateUrl: './mapping-table.template.html',
  styleUrls: ['./mapping-table.scss']
})
export class AppMappingTableComponent
  extends BaseComponent
  implements AfterViewInit, OnChanges
{
  selectedValues: any[] = []; // Array to track selected values for each dropdown
  @Input() public parsedFile: string[][] = [];
  @Input() public columnHeaders: string[] = [];
  @Input() public mappingConfig: ImportMappingConfiguration =
    new ImportMappingConfiguration();
  @Output() mappingConfigChange =
    new EventEmitter<ImportMappingConfiguration>();

  public transposedFile: {}[] = [];
  public headerMapping = {};
  public formGroup: FormGroup;
  public availableColumnsDict: { [index: number]: string[] } = {};
  public filteredColumns: string[][] = [];
  public fieldControl: FormControl[] = [];

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.columnHeaders.forEach((_, index) => {
      this.fieldControl.push(new FormControl());
      this.filteredColumns[index] = [...this.availableColumnsDict[index]];
    });
  }

  ngOnChanges() {
    if (this.columnHeaders && this.columnHeaders.length > 0) {
      this.createFormGroup();
    }
  }

  createFormGroup() {
    const formControls = {};
    this.columnHeaders.forEach((header, index) => {
      formControls['fieldSelect' + index] = new FormControl();
      this.availableColumnsDict[index] = [
        ...this.mappingConfig.availableColumns
      ];
      this.filteredColumns[index] = [...this.mappingConfig.availableColumns];
    });

    this.formGroup = this.formBuilder.group(formControls);
  }

  public async ngAfterViewInit() {
    this.transposeParsedFile();
    await this.populateHeaderMapping();
    Object.keys(this.headerMapping).forEach((key) => {
      if (this.headerMapping[key] !== undefined) {
        this.formGroup
          .get('fieldSelect' + key)
          .setValue(this.headerMapping[key]);
      }
    });
  }

  private async populateHeaderMapping(): Promise<void> {
    // populate select dropdowns with the current mapping
    const mappingErrors = await validate(this.mappingConfig);
    if (mappingErrors.length === 0) {
      // map header row to column indices
      const headerRow = this.columnHeaders;
      headerRow.forEach((header, index) => {
        // set header index to the key of the mappingConfig.mapping that matches the value of the header
        const entry = Object.entries(this.mappingConfig.mapping).find(
          (entry) => entry[1] === header
        );
        if (entry) {
          this.headerMapping[index] = entry[0];
        }
      });
    }
  }

  private transposeParsedFile(): void {
    // Calculate the maximum row length
    const maxLength = this.parsedFile.reduce(
      (max, row) => Math.max(max, row.length),
      0
    );

    // Initialize a new array based on the maximum length (columns after transpose)
    const transposed = Array.from({ length: maxLength }, (_, colIndex) =>
      this.parsedFile.map((row) => row[colIndex] || '')
    );

    // Convert each row into an object
    this.transposedFile = transposed.map((row) => {
      const rowObject = {};
      ['data1', 'data2', 'data3', 'data4'].forEach((header, index) => {
        rowObject[header] = row[index];
      });
      return rowObject;
    });
  }

  public isRequiredField(column: string): boolean {
    return this.mappingConfig.requiredColumns.includes(column);
  }

  // This method is called when the user types in the input field
  public filterAvailableColumns(i: number, value: string): void {
    const currentValue = this.fieldControl[i].value; // Get the current input value

    // Check if the user has cleared the input
    if (currentValue === '') {
      const clearedValue = this.selectedValues[i];

      if (clearedValue) {
        // Remove the cleared value from mappingConfig.mapping
        let mappingUpdated = false;
        if (this.mappingConfig.hasHeaderRow) {
          const key = Object.keys(this.mappingConfig.mapping).find(
            (key) => this.mappingConfig.mapping[key] === this.columnHeaders[i]
          );
          if (key) {
            delete this.mappingConfig.mapping[clearedValue];
            mappingUpdated = true;
          }
        } else {
          const key = Object.keys(this.mappingConfig.mapping).find(
            (key) => this.mappingConfig.mapping[key] === i
          );
          if (key) {
            delete this.mappingConfig.mapping[clearedValue];
            mappingUpdated = true;
          }
        }

        // Add the cleared value back to available columns for other dropdowns
        this.addSelectionToAvailableColumn(clearedValue);

        // Reset the selected value for this index
        this.selectedValues[i] = null;

        // Reset the text box
        this.fieldControl[i].setValue('');

        // Reset to show all options again
        this.columnHeaders.forEach((_, index) => {
          this.filterAvailableColumns(index, '');
        });

        if (mappingUpdated) {
          this.mappingConfigChange.emit(this.mappingConfig);
        }
      }
    } else {
      // If no input, show all available columns except selected ones
      this.filteredColumns[i] = this.availableColumnsDict[i].filter(
        (column) =>
          column.toLowerCase().includes(value.toLowerCase()) &&
          !this.selectedValues.includes(column)
      );
    }
  }

  public clearSelection(i: number, header: string, event: MouseEvent): void {
    // This stops the event from propagating to the parent (selectionChange)
    event.stopPropagation();
    const clearedValue = this.selectedValues[i];
    this.selectedValues[i] = null;

    if (clearedValue !== null && clearedValue !== undefined) {
      let mappingUpdated = false;

      // Remove the cleared value from mappingConfig.mapping
      if (this.mappingConfig.hasHeaderRow) {
        const key = Object.keys(this.mappingConfig.mapping).find(
          (key) => this.mappingConfig.mapping[key] === header
        );
        if (key) {
          delete this.mappingConfig.mapping[clearedValue];
          mappingUpdated = true;
        }
      } else {
        const key = Object.keys(this.mappingConfig.mapping).find(
          (key) => this.mappingConfig.mapping[key] === i
        );
      }

      // Add the cleared value back to available columns for other dropdowns
      this.addSelectionToAvailableColumn(clearedValue);

      // Reset the input field value
      this.fieldControl[i].setValue('');

      // Reset to show all options again
      this.columnHeaders.forEach((_, index) => {
        this.filterAvailableColumns(index, '');
      });

      if (mappingUpdated) {
        this.mappingConfigChange.emit(this.mappingConfig);
      }
    } else {
      // If the cleared value is not valid, reset
      this.fieldControl[i].setValue('');
    }
  }

  public onOptionSelected(
    i: number,
    event: MatAutocompleteSelectedEvent
  ): void {
    const selectedValue = event.option.value;
    const previousSelection = this.selectedValues[i];

    // If there's a previous selection, add it back to available columns
    // and remove the new selection from available columns in all other dropdowns
    if (previousSelection && previousSelection !== selectedValue) {
      this.addSelectionToAvailableColumn(previousSelection);

      this.updateAvailableColumns(selectedValue, i);
    }

    this.selectedValues[i] = selectedValue;

    // Update the mappingConfig.mapping with the new selection
    if (this.mappingConfig.hasHeaderRow) {
      this.mappingConfig.mapping[selectedValue] = this.columnHeaders[i];
    } else {
      this.mappingConfig.mapping[selectedValue] = i;
    }

    // Remove the selected value from available columns in all other dropdowns
    this.updateAvailableColumns(selectedValue, i);

    // Reset to show all options again
    this.columnHeaders.forEach((_, index) => {
      this.filterAvailableColumns(index, '');
    });

    this.mappingConfigChange.emit(this.mappingConfig);
  }

  public updateAvailableColumns(selectedValue: string, index: number): void {
    Object.keys(this.availableColumnsDict).forEach((key) => {
      const idx = parseInt(key);
      // Remove the selected value from all other dropdowns' options
      if (idx !== index) {
        this.availableColumnsDict[idx] = this.availableColumnsDict[idx].filter(
          (column) => column !== selectedValue
        );
      }
    });
  }

  public addSelectionToAvailableColumn(mappingValue: string) {
    if (mappingValue == null) return;

    // Loop through all the keys and add the value back to each one
    Object.keys(this.availableColumnsDict).forEach((key) => {
      const index = parseInt(key); // Convert key to integer (index)
      const columnArray = this.availableColumnsDict[index];

      // If the value is not already in the dropdown, add it
      if (!columnArray.includes(mappingValue)) {
        this.availableColumnsDict[index] = [mappingValue, ...columnArray];
      }
    });

    // Emit the updated configuration after modifying availableColumnsDict
    this.mappingConfigChange.emit(this.mappingConfig);
  }

  public removeSelectionFromAvailableColumn(
    mappingValue: string,
    currentColumnIndex: number
  ) {
    // Only proceed if mappingValue is valid (not null or undefined)
    if (mappingValue == null) return;

    if (Object.keys(this.mappingConfig.mapping).includes(mappingValue)) {
      Object.keys(this.availableColumnsDict).forEach((key) => {
        const index = parseInt(key);
        if (index === currentColumnIndex) {
          return;
        }
        this.availableColumnsDict[index] = this.availableColumnsDict[
          index
        ].filter((column) => column !== mappingValue);
      });
    } else {
      // If mappingValue doesn't exist, add it to the available columns (but not null values)
      Object.keys(this.availableColumnsDict).forEach((key) => {
        const index = parseInt(key);
        if (
          mappingValue &&
          !this.availableColumnsDict[index].includes(mappingValue)
        ) {
          this.availableColumnsDict[index] = [
            mappingValue,
            ...this.availableColumnsDict[index]
          ];
        }
      });
    }
  }
}
