import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { ReactionResponseDto } from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppReactionsService } from '../reactions.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { plainToInstance } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class AppReactionsCreateEditResolver extends BaseResolver<ReactionResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public reactionsService: AppReactionsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<ReactionResponseDto> {
    const user = this.StorageManager.getLoggedInUser();
    return plainToInstance(ReactionResponseDto, {
      name: null,
      description: '',
      daysUntilReaction: 0,
      passMessage: null,
      dailyCapacity: 1,
      complianceRequired: false,
      schoolId: user.currentSchoolId,
    });
  }
}
