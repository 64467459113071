import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  ExportSettingsCreateDto,
  ExportSettingsResponseDto,
  ExportSettingsUpdateDto,
  LastExportsResponseDto,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../shared/logger';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { IExportSettingsListResolverData } from './export-models/export-settings-list-resolver-data.interface';
import { ISftpSettingsListFilters } from '../imports/sftp-settings-list/sftp-settings-list-models/sftp-settings-list-filters.interface';

@Injectable({ providedIn: 'root' })
export class AppExportService extends BaseService {
  constructor(
    private readonly appClientDataService: AppClientDataService,
    private readonly toastService: AppToastManagerService
  ) {
    super();
  }

  public async getExportSettingsListPageResolverData(): Promise<IExportSettingsListResolverData> {
    try {
      const defaultFilters: ISftpSettingsListFilters = {
        tableFilters: TableFilterOptions.getPageDefault(
          TableFiltersPageKeyEnum.EXPORT_SETTINGS_LIST
        )
      };
      const exportSettings = await this.getExportSettingsList(defaultFilters);
      return {
        defaultFilters,
        exportSettings
      };
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading export settings');
    }
  }

  public async getExportSettingsList(
    filters: ISftpSettingsListFilters
  ): Promise<PaginationResultsDto<ExportSettingsResponseDto>> {
    return await this.appClientDataService.execute<
      PaginationResultsDto<ExportSettingsResponseDto>
    >(
      this.GET_ROUTES.EXPORT_SETTINGS_LIST,
      {
        queryParams: {
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {}),
          ...(filters.groupLevel ? { groupLevel: filters.groupLevel } : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {})
        }
      },
      ExportSettingsResponseDto
    );
  }

  public async createExportSettings(
    settingsInformation: ExportSettingsCreateDto
  ): Promise<ExportSettingsResponseDto> {
    return await this.appClientDataService.execute<ExportSettingsResponseDto>(
      this.POST_ROUTES.CREATE_EXPORT_SETTINGS,
      {
        method: 'POST',
        body: settingsInformation
      },
      ExportSettingsResponseDto
    );
  }

  public async updateExportSettings(
    settingsInformation: ExportSettingsUpdateDto
  ): Promise<ExportSettingsResponseDto> {
    return await this.appClientDataService.execute<ExportSettingsResponseDto>(
      this.POST_ROUTES.UPDATE_EXPORT_SETTINGS,
      {
        method: 'POST',
        body: settingsInformation,
        pathParams: {
          id: settingsInformation.id
        }
      },
      ExportSettingsResponseDto
    );
  }

  public async deleteExportSettings(id: number) {
    return await this.appClientDataService.execute(
      this.DELETE_ROUTES.DELETE_EXPORT_SETTINGS,
      {
        method: 'DELETE',
        pathParams: {
          id
        }
      }
    );
  }

  public async getExportSettings(
    exportSettingsId: number
  ): Promise<ExportSettingsResponseDto> {
    return await this.appClientDataService.execute<ExportSettingsResponseDto>(
      this.GET_ROUTES.EXPORT_SETTINGS_BY_ID,
      {
        pathParams: {
          id: exportSettingsId
        }
      },
      ExportSettingsResponseDto
    );
  }

  public async runExport(exportSettingsId: number): Promise<void> {
    await this.appClientDataService.execute<ExportSettingsResponseDto>(
      this.GET_ROUTES.RUN_EXPORT_BY_ID,
      {
        pathParams: {
          id: exportSettingsId
        }
      },
      ExportSettingsResponseDto
    );
  }

  public async getTopExports(): Promise<LastExportsResponseDto[]> {
    return await this.appClientDataService.execute<LastExportsResponseDto[]>(
      this.GET_ROUTES.TOP_EXPORTS
    );
  }
}
