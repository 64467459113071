<div class="page-content">
  <div class="manage-roster-filters">
    <mat-form-field class="full-width-input">
      <input
        matInput
        placeholder="Search"
        [(ngModel)]="search"
        (ngModelChange)="filter()" />
    </mat-form-field>
    <mat-form-field class="full-width-input">
      <input
        matInput
        placeholder="Grade"
        [(ngModel)]="grade"
        (ngModelChange)="filter()" />
    </mat-form-field>
  </div>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  <mat-table [dataSource]="students">
    <ng-container matColumnDef="{{ columns.STUDENT_NAME }}">
      <mat-header-cell *matHeaderCellDef>
        {{ columns.STUDENT_NAME }}
      </mat-header-cell>
      <mat-cell *matCellDef="let student">
        {{ student.firstName }} {{ student.lastName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{ columns.STUDENT_ID }}">
      <mat-header-cell *matHeaderCellDef>
        {{ columns.STUDENT_ID }}
      </mat-header-cell>
      <mat-cell *matCellDef="let student">
        {{ student.studentExtId }}
      </mat-cell> </ng-container
    ><ng-container matColumnDef="{{ columns.GRADE }}">
      <mat-header-cell *matHeaderCellDef>
        {{ columns.GRADE }}
      </mat-header-cell>
      <mat-cell *matCellDef="let student">
        {{ student.grade }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{ columns.ACTION }}">
      <mat-header-cell *matHeaderCellDef> Remove </mat-header-cell>
      <mat-cell *matCellDef="let student">
        <button
          type="submit"
          mat-flat-button
          class="secondary-button"
          (click)="removeStudent(student.id)"
          options-id="ADD_STUDENT_TO_ROSTER">
          <fa-icon [icon]="['fas', 'minus']"></fa-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let code; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
