import {
  PrivilegeEnum,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { AppBehaviorCodeEntryCreateModalComponent } from '../../pages/behavior-code-entry/behavior-code-entry-create-modal/behavior-code-entry-create-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FastTrackModalComponent } from '../../pages/behavior-code-entry/fast-track-modal/fast-track-modal.component';
import { bulkStudentPasswordResetModal } from '../menu/common-menu-helpers/bulk-action.helpers';
import { AppConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { AppToastManagerService } from '../services/toast-manager.service';
import { BaseComponent } from '../base-classes/base.component';
import { AppStudentsService } from '../../pages/students/students.service';
import { AppPrivilegesService } from '../../pages/auth/privileges.service';
import { BulkStudentProfilePhotoUploadComponent } from '../../pages/students/bulk-profile-photo-upload/bulk-profile-photo-upload.component';

export class TrackableStudentList extends BaseComponent {
  public students: StudentPerformanceDto[] = [];
  public selectedStudentsIds: number[] = [];
  public sortField: 'firstName' | 'lastName' | 'studentExtId';
  public sortDirection: 'Ascending' | 'Descending' = 'Ascending';

  constructor(
    public dialog: MatDialog,
    public confirmationDialogService: AppConfirmationDialogService,
    public toastService: AppToastManagerService,
    public studentsService: AppStudentsService,
    public privilegesService: AppPrivilegesService
  ) {
    super({ privilegesService });
  }

  public toggleStudentSelected(studentId: number): void {
    const user = this.StorageManager.getLoggedInUser();
    if (!user.currentSchoolId) {
      this.toastService.error(
        'Please select a school to track students behavior'
      );
    } else if (this.selectedStudentsIds.includes(studentId)) {
      this.removeStudentFromSelected(studentId);
    } else {
      this.addStudentToSelected(studentId);
    }
  }

  public handleSelectAll(): void {
    if (this.selectedStudentsIds.length > 0) {
      this.deselectStudents();
    } else {
      this.selectAllStudents();
    }
  }

  public selectAllStudents(): void {
    this.selectedStudentsIds = this.students.map((student) => student.id);
  }

  public deselectStudents(): void {
    this.selectedStudentsIds = [];
  }

  private addStudentToSelected(studentId: number): void {
    this.selectedStudentsIds.push(studentId);
  }

  private removeStudentFromSelected(studentId: number): void {
    this.selectedStudentsIds = this.selectedStudentsIds.filter(
      (id) => id !== studentId
    );
  }

  public showBulkStudentProfileUploadModal() {
    this.dialog.open(BulkStudentProfilePhotoUploadComponent, {
      width: '600px',
      height: '600px',
      panelClass: 'default-dialog'
    });
  }

  public showTrackModal() {
    this.dialog.open(AppBehaviorCodeEntryCreateModalComponent, {
      width: '600px',
      panelClass: 'default-dialog',
      data: {
        studentIds: this.selectedStudentsIds
      }
    });

    this.dialog.afterAllClosed.subscribe(() => {
      this.selectedStudentsIds = [];
    });
  }

  public showFastTrackModal() {
    this.dialog.open(FastTrackModalComponent, {
      width: '600px',
      panelClass: 'default-dialog',
      data: {
        students: this.students.filter((student) =>
          this.selectedStudentsIds.includes(student.id)
        )
      }
    });
    this.dialog.afterAllClosed.subscribe(() => {
      this.selectedStudentsIds = [];
    });
  }

  public async showPasswordResetModal(): Promise<void> {
    // The reduce method allows us to accumulate the valid userIds in one pass
    const mappedUserIds = this.students.reduce<number[]>((acc, student) => {
      // Apply the same logic from filter: check if student is selected and has a userId
      if (this.selectedStudentsIds.includes(student.id) && student.userId) {
        acc.push(student.userId); // Same mapping logic as in map
      }
      return acc;
    }, []);
    if (mappedUserIds.length === 0) {
      this.toastService.error('No user accounts found');
      return;
    }
    bulkStudentPasswordResetModal(this, mappedUserIds);
  }

  public setSortField(
    sortField: 'firstName' | 'lastName' | 'studentExtId'
  ): void {
    this.sortField = sortField;
    this.doSort();
  }

  public doSort(): void {
    // Sort students by sortField and sortDirection
    this.students = this.students.sort((a, b) => {
      const fieldA = a[this.sortField];
      const fieldB = b[this.sortField];

      // Handle undefined or null values
      const valueA = fieldA !== undefined && fieldA !== null ? fieldA : '';
      const valueB = fieldB !== undefined && fieldB !== null ? fieldB : '';

      let comparison = 0;

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        // Use localeCompare for string comparison
        comparison = valueA.localeCompare(valueB);
      } else {
        // For numbers or other types, use simple comparison
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        } else {
          comparison = 0;
        }
      }

      // Reverse the comparison result if sortDirection is 'Descending'
      return this.sortDirection === 'Ascending' ? comparison : -comparison;
    });
  }

  onSortDirectionClicked(): void {
    if (this.sortDirection === 'Ascending') {
      this.sortDirection = 'Descending';
    } else {
      this.sortDirection = 'Ascending';
    }
    this.doSort();
  }
}
