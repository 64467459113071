import { ICustomRoute } from '../../app-routing.module';
import { AuthGuard } from '../../route-guards/auth.guard';
import { AppInterventionsListResolver } from './interventions-list/interventions-list.resolver';
import { AppInterventionsListComponent } from './interventions-list/interventions-list.component';
import { AppInterventionCreateEditComponent } from './interventions-create-edit/intervention-create-edit.component';
import { AppInterventionCreateEditResolver } from './interventions-create-edit/intervention-create-edit.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

export const AppInterventionsRoutes: ICustomRoute[] = [
  {
    path: 'interventions',
    title: 'Interventions',
    component: AppInterventionsListComponent,
    resolve: { data: AppInterventionsListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Interventions',
      buttons: [
        {
          name: 'Manage Behavior Codes',
          normalizedRoute: 'behavior-codes',
          color: 'primary'
        },
        {
          name: 'Add',
          normalizedRoute: 'interventions/new',
          icon: 'plus-circle',
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'interventions/:interventionId',
    title: 'Edit Intervention',
    component: AppInterventionCreateEditComponent,
    resolve: { data: AppInterventionCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      overwriteTitle: false,
      breadcrumbs: [
        {
          title: 'Interventions',
          path: 'interventions'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  }
];
