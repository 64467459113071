import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppBehaviorCodesService } from '../behavior-codes.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IBehaviorCodesCreateEditResolverData } from './behavior-codes-create-edit-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppBehaviorCodesCreateEditResolver extends BaseResolver<IBehaviorCodesCreateEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public behaviorCodesService: AppBehaviorCodesService
  ) {
    super(resolverErrorHandler);
  }
  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IBehaviorCodesCreateEditResolverData> {
    const id = route.paramMap.get('id');
    return this.behaviorCodesService.getBehaviorCodeCreateEditResolverData(id);
  }
}
