<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <div class="right-filters" *ngIf="hasPrivilege(P.MANAGE_BEHAVIOR_CODE)">
      <!-- Reorder Button -->
      <button
        mat-flat-button
        *ngIf="!reorderMode"
        class="secondary-button small-button"
        (click)="reorder()"
        unique-id>
        <fa-icon [icon]="['fas', 'up-down-left-right']"></fa-icon> Reorder
      </button>
      <!-- Save Order Button -->
      <button
        mat-flat-button
        *ngIf="reorderMode"
        class="success-button small-button"
        (click)="saveOrder()"
        unique-id>
        <fa-icon [icon]="['fas', 'check-circle']"></fa-icon> Save
      </button>
      <button
        mat-flat-button
        *ngIf="reorderMode"
        color="warn"
        class="small-button"
        (click)="reorder()"
        unique-id>
        <fa-icon [icon]="['fas', 'times-circle']"></fa-icon> Cancel
      </button>
    </div>
    <app-pagination
      *ngIf="!reorderMode"
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="behavior-codes-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear
      cdkDropList
      (cdkDropListDropped)="dropped($event)">
      <!-- Reorder Column -->
      <ng-container matColumnDef="{{columns.REDORDER}}">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let code">
          <fa-icon cdkDragHandle [icon]="['fas', 'bars']"></fa-icon>
        </mat-cell>
      </ng-container>

      <!-- Order Column -->
      <ng-container matColumnDef="{{columns.ORDER}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.ORDER}}
        </mat-header-cell>
        <mat-cell *matCellDef="let code"> {{ code.ordinal + 1 }} </mat-cell>
      </ng-container>

      <!-- Code Column -->
      <ng-container matColumnDef="{{columns.CODE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.CODE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let code"> {{ code.code }} </mat-cell>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="{{columns.DESCRIPTION}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.DESCRIPTION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let code"> {{ code.description }} </mat-cell>
      </ng-container>

      <!-- School Column -->
      <ng-container matColumnDef="{{columns.SCHOOL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.SCHOOL}}
        </mat-header-cell>
        <mat-cell *matCellDef="let code"> {{ code.school?.name }} </mat-cell>
      </ng-container>

      <!-- Print Pass Column -->
      <ng-container matColumnDef="{{columns.PRINT_PASS}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.PRINT_PASS}}
        </mat-header-cell>
        <mat-cell *matCellDef="let code"> {{ code.printPass }} </mat-cell>
      </ng-container>

      <!-- Active Column -->
      <ng-container matColumnDef="{{columns.ACTIVE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.ACTIVE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let code"> {{ code.active }} </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell class="action-column-header" *matHeaderCellDef>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let code">
          <button
            type="submit"
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/behavior-codes/' + code.id.toString()"
            options-id="EDIT_BEHAVIOR_CODE"
            [option]="code.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row
        [cdkDragDisabled]="!reorderMode"
        cdkDrag
        [cdkDragData]="code"
        *matRowDef="let code; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      *ngIf="!reorderMode"
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
