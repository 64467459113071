import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { AppRoleCreateEditComponent } from './roles-create-edit/role-create-edit.component';
import { AppSaveBarModule } from '../../shared/save-bar/save-bar.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppRolesListComponent } from './roles-list/roles-list.component';
import { AppRolesListTableComponent } from './roles-list/roles-list-table/roles-list-table.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppRoleCreateEditComponent,
    AppRolesListComponent,
    AppRolesListTableComponent
  ],
  exports: [AppRoleCreateEditComponent],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    AppSaveBarModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    DirectiveModule,
    FontAwesomeModule,
    AppPaginationModule,
    FormsModule,
    MatTooltipModule
  ]
})
export class AppRolesModule {}
