"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTemplateVariablesDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class BaseTemplateVariablesDto extends mappable_base_1.MappableBase {
    constructor(baseTemplateVariablesOptions) {
        super();
        Object.assign(this, baseTemplateVariablesOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return ['school_name', 'schoolGroup_name'];
    }
    school_name;
    schoolGroup_name;
    registration_id;
    account_id;
    behavior_code_id;
    behavior_code_notification_id;
}
exports.BaseTemplateVariablesDto = BaseTemplateVariablesDto;
