<div class="container">
  <div class="bulk-container">
    <app-menu [actions]="bulkActionButtons"></app-menu>
  </div>
  <div class="right-container">
    <div class="sort-container">
      <app-menu title="Sort By" [actions]="sortActionButtons"></app-menu>
    </div>
    <button
      mat-flat-button
      class="outline-button"
      (click)="onSortDirectionClicked()">
      {{ sortDirection }}
    </button>
  </div>
</div>

<div class="card-container">
  <app-student-card
    class="card-container"
    *ngFor="let student of students"
    [student]="student"
    [studentSelected]="selectedStudentsIds.includes(student.id)"
    [selectStudent]="selectStudent">
  </app-student-card>
</div>
