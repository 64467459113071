import { AppDistrictDashComponent } from './district-dashboard/district-dash.component';
import { AppMaterialModule } from 'src/app/app-material.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppStudentsModule } from '../students/students.module';
import { StudentTopPerformersComponent } from './district-dashboard/student-top-performers/student-top-performers.component';
import { TodayScheduleComponent } from './district-dashboard/today-schedule/today-schedule.component';
import { AppCalendarModule } from '../calendar/calendar.module';
import { RouterLink } from '@angular/router';
import { AppDashboardComponent } from './dashboard/dashboard.component';
import { AppGuardianDashboardComponent } from './guardian-dashboard/guardian-dashboard.component';
import { AppStudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { AppTeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { AppContactSupportComponent } from './contact-support/contact-support.component';
import { AppStudentHeaderInfoModule } from '../../shared/student-header-info/student-header-info.module';
import { AppPointsBubbleModule } from '../../shared/points-bubble/points-bubble.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppStudentProfilePhotoModule } from '../../shared/student-profile-photo/student-profile-photo.module';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    AppStudentsModule,
    AppCalendarModule,
    NgOptimizedImage,
    RouterLink,
    AppStudentHeaderInfoModule,
    AppStudentProfilePhotoModule,
    AppPointsBubbleModule,
    FontAwesomeModule
  ],
  declarations: [
    AppDistrictDashComponent,
    StudentTopPerformersComponent,
    TodayScheduleComponent,
    AppDashboardComponent,
    AppGuardianDashboardComponent,
    AppStudentDashboardComponent,
    AppTeacherDashboardComponent,
    AppContactSupportComponent,
  ],
  exports: [AppDistrictDashComponent]
})
export class AppDashboardModule {}
