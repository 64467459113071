import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AppTeacherRostersService } from '../teacher-rosters.service';
import { ITeacherRostersData } from './teacher-rosters-list-data.interface';

@Injectable({ providedIn: 'root' })
export class AppTeacherRostersListResolver extends BaseResolver<
  ITeacherRostersData
> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private teacherRostersService: AppTeacherRostersService,
    private route: ActivatedRoute
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<ITeacherRostersData> {
    const { queryParams } = route;
    const periodIds = queryParams.periodId;
    const teacherPeriods = await this.teacherRostersService.getTeacherRostersPeriods();
    const periodIdFilter = periodIds ? periodIds : teacherPeriods.length ? [teacherPeriods[0].id] : [];

    const defaultFilters = periodIdFilter.length ?
      this.teacherRostersService.getTeacherRostersDefaultFilters({
        ...queryParams,
        periodIds: periodIdFilter,
      }) : null;

    const students = defaultFilters
      ? await this.teacherRostersService.getTeacherRosters(defaultFilters)
      : null

      return {
        teacherPeriods,
        students,
      };
  }
}
