"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecureDownloadTemplateVariablesDto = void 0;
const base_invite_template_variables_dto_1 = require("./base-invite-template-variables.dto");
class SecureDownloadTemplateVariablesDto extends base_invite_template_variables_dto_1.BaseInviteTemplateVariablesDto {
    constructor(secureDownloadTemplateVariablesOptions) {
        super();
        Object.assign(this, secureDownloadTemplateVariablesOptions);
    }
    getFields() {
        return [...super.getFields(), 'fileDescription'];
    }
    fileDescription;
}
exports.SecureDownloadTemplateVariablesDto = SecureDownloadTemplateVariablesDto;
