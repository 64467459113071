import { AppAuthService } from '../auth/auth.service';
import { AppLoadingBarService } from 'src/app/shared/services/loading-bar.service';
import { AppSettingsService } from '../../shared/services/settings.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { AppUsersServices } from '../users/users.service';
import { BaseService } from '../../shared/base-classes/base.service';
import { HomePageConfig } from './home-page.config';
import { Injectable } from '@angular/core';
import { logger } from 'src/app/shared/logger';
import { Router } from '@angular/router';
import { LoginResponseDto } from '@whetstoneeducation/hero-common';

/**
 * Service used to determine the home page a user should be redirected to.
 */
@Injectable({
  providedIn: 'root'
})
export class AppHomeService extends BaseService {
  public constructor(
    public appUsersService: AppUsersServices,
    public authService: AppAuthService,
    public loadingBar: AppLoadingBarService,
    public router: Router,
    public settingsService: AppSettingsService,
    public toastManager: AppToastManagerService
  ) {
    super({ settingsService });
  }

  /**
   * If our users homepage exists, send them to the right route.
   * If not, send them to the my settings page, where they can change theirs.
   */
  public async goHome(): Promise<void> {
    this.loadingBar.show();
    const homePath = await this.getHome();
    if (homePath) {
      await this.router.navigate(homePath);
    }
    this.loadingBar.hide();
  }

  public async getHome(): Promise<string[] | null> {
    try {
      const user: LoginResponseDto = this.StorageManager.getLoggedInUser();
      if (!user) return null;
      let homepage = user.homepage;
      if (!homepage) {
        return ['/dashboard'];
      }

      return HomePageConfig.getPath(homepage, this.systemSettings.homeDash);
    } catch (error) {
      logger.error(error);
      this.toastManager.warn(
        'An error occurred when attempting to load the home page!'
      );
      return ['/'];
    }
  }
}
