<!-- Header -->
<div class="mat-dialog-header">
  <!-- Title -->
  <div class="mat-dialog-title">
    <span class="group-name">{{ groupName ? groupName+':' : '' }} </span>
    <span class="title">Add Bell Schedule(s)</span>
  </div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>

<!-- Content -->
<mat-dialog-content class="m-0 p-0 mat-dialog-content">
  <div class="buttons-and-filters">
    <mat-form-field class="filter-item search-input">
      <input
        [placeholder]="'Search by name'"
        data-test-id="SEARCH_FILTER_INPUT"
        matInput
        (keyup.enter)="updateSearchFilter($event.target.value)" />
      <fa-icon
        class="search-icon"
        matSuffix
        [icon]="['fas', 'magnifying-glass']"></fa-icon>
    </mat-form-field>
    <!-- Search -->
    <button
      mat-flat-button
      (click)="dialogRef.close(selectedBellSchedules)"
      class="secondary-button standard-button">
      ADD SELECTED BELL SCHEDULES
    </button>
  </div>
  <app-bell-schedules-list-table
    [dataArr]="bellSchedules"
    [tableFilters]="tableFilters"
    (tableFiltersUpdated)="updateTableFilters($event)"
    [showSelectOptions]="true"
    [selectedBellSchedules]="selectedBellSchedules"
    (selectedBellSchedulesChanged)="selectedBellSchedules = $event"
    (bellScheduleSelected)="dialogRef.close($event)"></app-bell-schedules-list-table>
</mat-dialog-content>
