<div class="teacher-rosters-list-filters-content">
  <div>
    <button
      class="period-bubble-button"
      type="button"
      *ngFor="let period of teacherPeriods"
      (click)="updatePeriodFilter(period.id)"
      [class.selected-period-bubble-button]="isPeriodSelected(period.id)">
      <fa-icon
        class="selected-period-icon"
        *ngIf="isPeriodSelected(period.id)"
        [icon]="['fas', 'check']"></fa-icon>
      <span>{{period.name | uppercase}}</span>
    </button>
  </div>

  <!-- button to select all students -->
  <button
    *ngIf="selectedStudentsIds === 0"
    type="button"
    mat-flat-button
    class="secondary-button header-action-button select-all-button"
    (click)="selectAllStudentsEvent.emit()">
    Select All
  </button>

  <button
    *ngIf="selectedStudentsIds !== 0"
    type="button"
    mat-flat-button
    class="secondary-button header-action-button select-all-button"
    (click)="deselectStudentsEvent.emit()">
    De-select All
  </button>

  <button
    *ngIf="hasPrivilege(PrivilegeEnum.TRACK_BEHAVIOR) && !!selectedStudentsIds"
    type="button"
    mat-flat-button
    class="secondary-button header-action-button track-button"
    (click)="showTrackModalEvent.emit()"
    unique-id>
    Track Students {{selectedStudentsIds}}
  </button>

  <button
    *ngIf="hasPrivilege(PrivilegeEnum.TRACK_BEHAVIOR) && !!selectedStudentsIds"
    type="button"
    mat-flat-button
    class="secondary-button header-action-button standard-button margin-left"
    (click)="showFastTrackModalEvent.emit()"
    unique-id>
    Fast Track
  </button>
</div>
