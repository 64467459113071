export enum ComplianceListColumnsEnum {
  STUDENT_ID = 'Student Id',
  STUDENT_NAME = 'Student Name',
  GRADE = 'Grade',
  ENTRY_DATE = 'Entry Date',
  ENTRY_TIME = 'Entry Time',
  BEHAVIOR_CODE = 'Behavior Code',
  REACTION = 'Reaction',
  REACTION_DATE = 'Reaction Date',
  COMPLY = 'Comply'
}
