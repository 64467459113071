<div class="mat-dialog-header">
  <div *ngIf="!isLoading" class="mat-dialog-title">
    Behavior Track: <span>{{ behaviorEntry.behaviorCodeName }}</span>
  </div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <ng-container>
    <mat-form-field *ngIf="!isLoading" class="full-width-input">
      <mat-select
        placeholder="Select an option"
        id="EDIT_BEHAVIOR_CODE"
        [formControl]="behaviorCodeControl">
        <mat-option
          *ngFor="let option of behaviorCodeOptions"
          options-id="EDIT_BEHAVIOR_CODE"
          [value]="option.value">
          {{ option.display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="!behaviorCodeChanged">
      <app-reaction-entry-edit
        *ngFor="
          let reactionEntry of behaviorEntry?.reactionEntries;
          let i = index
        "
        [isOnlyReaction]="behaviorEntry.reactionEntries.length === 1"
        [index]="i"
        [reactionEntry]="reactionEntry"
        [studentId]="behaviorEntry.student.id"
        [behaviorCodeReactionOptions]="behaviorCodeReactionOptions"
        [takenScheduledReactionIds]="takenScheduledReactionIds"
        (reactionEntryUpdated)="reactionEntryUpdated($event)"
        (reactionEntryEditModalLoaded)="
          handleReactionEntryEditLoading(reactionEntry.id, $event)
        "></app-reaction-entry-edit>
    </ng-container>

    <div class="modal-action-row">
      <button
        type="button"
        mat-button
        class="modal-cancel-button"
        (click)="dialogRef.close()">
        Cancel
      </button>
      <button
        *ngIf="!needsToConfirm || behaviorCodeChanged || reactionEntryChanged"
        [disabled]="
          !(behaviorCodeChanged || reactionEntriesToUpdate.length > 0)
        "
        type="submit"
        mat-button
        class="modal-submit-button"
        (click)="submitBehaviorEntry()">
        Update
      </button>
      <button
        *ngIf="needsToConfirm && !(behaviorCodeChanged || reactionEntryChanged)"
        type="submit"
        mat-button
        class="modal-submit-button success-button"
        (click)="confirmBehaviorEntry()">
        Confirm
      </button>
    </div>
  </ng-container>
</div>
