import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BaseComponent } from '../../../../../../shared/base-classes/base.component';
import {
  IDisplayData,
  ReactionEntriesResponseDto,
  RelationDto
} from '@whetstoneeducation/hero-common';
import { AppBehaviorCodeEntryService } from '../../../../../behavior-code-entry/behavior-code-entry.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { IReactionEntryUpdatedPayload } from '../models/reaction-entry-updated-payload.interface';
import { Subscription } from 'rxjs';

export function ScheduledReactionsRequiredValidator(
  scheduledReactionOptions: IDisplayData[]
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (scheduledReactionOptions.length > 0) {
      if (!!value) {
        return { scheduledReactionRequired: true }; // Validation error
      }
    } else {
      return null;
    }
  };
}

@Component({
  selector: 'app-reaction-entry-edit',
  templateUrl: './reaction-entry-edit.component.html',
  styleUrls: ['./reaction-entry-edit.component.scss']
})
export class ReactionEntryEditComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() isOnlyReaction: boolean;
  @Input() index: number;
  @Input() reactionEntry: ReactionEntriesResponseDto;
  @Input() studentId: number;
  @Input() behaviorCodeReactionOptions: IDisplayData[];
  @Input() takenScheduledReactionIds: number[];
  @Output() reactionEntryUpdated =
    new EventEmitter<IReactionEntryUpdatedPayload>();
  @Output() reactionEntryEditModalLoaded = new EventEmitter<boolean>();

  public scheduledReactionOptions: IDisplayData[];
  public form: FormGroup;
  public valueChangesSubscriptions: Subscription[] = [];

  constructor(
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    public formBuilder: FormBuilder
  ) {
    super();
  }

  public async ngOnInit() {
    this.isLoading = true;
    this.scheduledReactionOptions = await this.behaviorCodeEntryService
      .getScheduledReactionsOptions(
        this.reactionEntry.behaviorCodeReactionId,
        this.studentId
      )
      .then((options) =>
        options
          .filter(
            (option) => !this.takenScheduledReactionIds.includes(option.id)
          )
          .map(
            (option: RelationDto): IDisplayData => ({
              display: option.name,
              value: option.id
            })
          )
      );
    if (this.reactionEntry.scheduledReaction) {
      this.scheduledReactionOptions.push({
        display: this.reactionEntry.scheduledReaction.name,
        value: this.reactionEntry.scheduledReaction.id
      });
    }
    const group: any = {
      behaviorCodeReactionId: new FormControl<number>(
        this.reactionEntry.behaviorCodeReactionId,
        [Validators.required]
      )
    };

    if (this.reactionEntry?.scheduledReaction?.id) {
      group.scheduledReactionId = new FormControl<number>(
        this.reactionEntry.scheduledReaction.id,
        [Validators.required]
      );
    }
    this.form = this.formBuilder.group(group);

    const behaviorCodeReactionIdControl = this.form.get(
      'behaviorCodeReactionId'
    );
    this.valueChangesSubscriptions.push(
      behaviorCodeReactionIdControl.valueChanges.subscribe(async (value) => {
        //emit that there is a change so that we can update the submit button and then we can emit the actual change in a bit
        this.reactionEntryUpdated.emit({
          reactionEntryId: this.reactionEntry.id,
          behaviorCodeReactionId:
            this.form.controls.behaviorCodeReactionId.value,
          scheduledReactionId: this.form.controls.scheduledReactionId.value
        });
        // get new scheduled reaction options for new behavior code reaction
        this.scheduledReactionOptions = (
          await this.behaviorCodeEntryService.getScheduledReactionsOptions(
            value,
            this.studentId
          )
        ).map(
          (option: RelationDto): IDisplayData => ({
            display: option.name,
            value: option.id
          })
        );

        // if scheduled reactions exist set to first options, else clear
        if (this.scheduledReactionOptions.length) {
          this.form.controls.scheduledReactionId.setValue(
            this.scheduledReactionOptions[0].value
          );
        } else {
          this.form.controls.scheduledReactionId.reset();
        }

        this.reactionEntryUpdated.emit({
          reactionEntryId: this.reactionEntry.id,
          behaviorCodeReactionId: value,
          scheduledReactionId: this.form.controls.scheduledReactionId.value
        });
      })
    );

    const scheduleReactionIdControl = this.form.get('scheduledReactionId');

    if (scheduleReactionIdControl) {
      this.valueChangesSubscriptions.push(
        scheduleReactionIdControl.valueChanges.subscribe((value) => {
          this.reactionEntryUpdated.emit({
            reactionEntryId: this.reactionEntry.id,
            behaviorCodeReactionId:
              this.form.controls.behaviorCodeReactionId.value,
            scheduledReactionId: value
          });
        })
      );
    }

    this.reactionEntryEditModalLoaded.emit(true);
    this.isLoading = false;
  }

  public ngOnDestroy() {
    this.valueChangesSubscriptions.forEach((sub) => sub.unsubscribe());
  }
}
