"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DaysOfWeekEnum = void 0;
var DaysOfWeekEnum;
(function (DaysOfWeekEnum) {
    DaysOfWeekEnum[DaysOfWeekEnum["SUNDAY"] = 1] = "SUNDAY";
    DaysOfWeekEnum[DaysOfWeekEnum["MONDAY"] = 2] = "MONDAY";
    DaysOfWeekEnum[DaysOfWeekEnum["TUESDAY"] = 3] = "TUESDAY";
    DaysOfWeekEnum[DaysOfWeekEnum["WEDNESDAY"] = 4] = "WEDNESDAY";
    DaysOfWeekEnum[DaysOfWeekEnum["THURSDAY"] = 5] = "THURSDAY";
    DaysOfWeekEnum[DaysOfWeekEnum["FRIDAY"] = 6] = "FRIDAY";
    DaysOfWeekEnum[DaysOfWeekEnum["SATURDAY"] = 7] = "SATURDAY";
})(DaysOfWeekEnum || (exports.DaysOfWeekEnum = DaysOfWeekEnum = {}));
