<div class="mat-dialog-header">
  <div class="mat-dialog-title">{{ data?.option ? 'Edit' : 'Add' }} Option</div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <mat-form-field class="full-width-input">
    <mat-label>Option Name</mat-label>
    <input matInput placeholder="Option Name" [formControl]="nameControl" />
  </mat-form-field>

  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      [disabled]="nameControl.invalid"
      type="submit"
      mat-button
      class="modal-submit-button"
      (click)="submit()">
      Save
    </button>
  </div>
</div>
