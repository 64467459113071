import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ExportField, ExportFieldMapping } from '@whetstoneeducation/hero-common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isEmpty, validate } from 'class-validator';
import {ExportBuilderRow} from '../export-models/export-builder-row';
import { AppExportBuilderTableComponent } from './export-builder-table/export-builder-table.component';

@Component({
  selector: 'app-export-builder',
  templateUrl: './export-builder.template.html',
  styleUrls: ['./export-builder.scss'],
})
export class AppExportBuilderComponent
  extends BaseComponent
  implements AfterViewInit
{
  @Input() public fieldList: any = {};
  @Input() public exportFieldsConfig: any = {};
  @Output() onExportFieldsChange = new EventEmitter<any>();

  @ViewChild(AppExportBuilderTableComponent)
  public exportTable: AppExportBuilderTableComponent;

  public transposedFile: {}[] = [];
  public headerMapping = {};
  public formGroup: FormGroup;
  public exportFields: ExportFieldMapping = new ExportFieldMapping();

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public updateExportFieldsConfig() {
    this.onExportFieldsChange.emit(this.exportFields);
  }

  public updateListOrder() {
    this.exportTable.dataArr.forEach((field, index) => {
      field.order = index + 1;
   });

   this.exportFields.exportFieldList.forEach((field, index) => {
      field.order = index + 1;
   });
  }
  
  public removeField(field: ExportField) {
    this.exportFields.exportFieldList = this.exportFields.exportFieldList.filter(
      (exportField) => exportField !== field
    );
    // re-order list
    this.updateListOrder();
    this.updateExportFieldsConfig();
  }

  /**
   * Move our item to where it was dropped, then re-render our table
  //  */
  public async addToExport(fields: any, field: ExportBuilderRow) {
    const exportField = new ExportField({
      field: field.value,
      header: field.label,
      model: fields.header,
      order: this.exportFields.exportFieldList?.length + 1
    });
    field.enabled = false;
    
    this.exportFields.exportFieldList.push(exportField);
    this.exportTable.dataArr = this.exportFields.exportFieldList;

    this.updateExportFieldsConfig();
  }

  public async ngAfterViewInit() {
    if (!isEmpty(this.exportFieldsConfig)) {
      this.exportFields = this.exportFieldsConfig;
      this.exportTable.dataArr = this.exportFields.exportFieldList;
    }
  }
}
