import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { InterventionResponseDto } from '@whetstoneeducation/hero-common';
import { IInterventionsListFilters } from './interventions-list-models/interventions-list-filters.interface';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute } from '@angular/router';
import { AppInterventionsService } from '../interventions.service';
import { IInterventionsListResolverData } from './interventions-list-models/interventions-list-resolver-data.interface';
import { logger } from '../../../shared/logger';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';

@Component({
  selector: 'app-interventions-list',
  templateUrl: './interventions-list.template.html',
  styleUrls: ['./interventions-list.scss']
})
export class AppInterventionsListComponent extends BaseComponent {
  public interventions: InterventionResponseDto[];
  public filters: IInterventionsListFilters;
  public behaviorCodeId: number;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public interventionsService: AppInterventionsService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data
      .data as IInterventionsListResolverData;
    this.behaviorCodeId = this.route.snapshot.params.id;
    this.interventions = data.interventions.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.interventions.options.totalItems;
    this.isLoading = false;
  }

  public async updateInterventionsList(
    filters: IInterventionsListFilters
  ): Promise<void> {
    try {
      const newData = await this.interventionsService.getInterventionsList(
        filters
      );
      this.interventions = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving interventions!');
      logger.error(err);
    }
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateInterventionsList(this.filters);
    this.isLoading = false;
  }

  public async updateFilters(
    newFilters: IInterventionsListFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.INTERVENTIONS_LIST,
      this.filters.tableFilters
    );
    await this.updateInterventionsList(this.filters);
    this.isLoading = false;
  }
}
