import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppCustomRostersService } from '../custom-rosters.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import {
  CustomRostersResponseDto,
  CustomRostersUpdateDto
} from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-create-edit-custom-roster-modal',
  templateUrl: './create-edit-custom-roster-modal.component.html',
  styleUrls: ['./create-edit-custom-roster-modal.component.scss']
})
export class AppCreateEditCustomRosterModalComponent extends BaseComponent {
  public name: string;
  public isCreating: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<AppCreateEditCustomRosterModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customRoster?: CustomRostersResponseDto;
      onClose: (id: number) => Promise<void>;
    },
    public customRostersService: AppCustomRostersService,
    public toastService: AppToastManagerService
  ) {
    super();
    this.isCreating = !this.data.customRoster;
    if (!this.isCreating) {
      this.name = this.data.customRoster.name;
    }
  }

  public async handleSubmit() {
    this.isLoading = true;
    try {
      if (!this.name) {
        this.toastService.error('Please enter a name for the custom roster.');
        this.isLoading = false;
        return;
      }
      if (this.isCreating) {
        const customRoster = await this.customRostersService.createCustomRoster(
          this.name
        );
        await this.data.onClose(customRoster.id);
      } else {
        await this.customRostersService.updateCustomRoster(
          this.data.customRoster.id,
          new CustomRostersUpdateDto({
            name: this.name
          })
        );
        await this.data.onClose(this.data.customRoster.id);
      }

      this.dialogRef.close();
    } catch (error) {
      this.toastService.error(
        'Failed to create custom roster. Please try again.'
      );
    }

    this.isLoading = false;
  }
}
