<div class="mat-dialog-container">
  <div class="mat-dialog-header">
    <div class="mat-dialog-title">
      Bulk Student Profile Photo Upload
    </div>
    <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
  </div>
  <div mat-dialog-content>
    <span>Rules for uploading profile photos:</span>
    <ul>
      <li>You must upload the photos in a zip file</li>
      <li>Each photo must be named with the student id</li>
      <li>The zip file size must be less or equal to 100MB</li>
    </ul>
    <span>The process will take some time, we will email you when it is complete</span>
    <app-file-upload [allowedExtensions]="allowedFileExtensions" [maxMBFileSize]="100" (fileChanged)="handleFileChanged($event)">
    </app-file-upload>
    <button class="outline-button header-action-button standard-button upload-button" mat-button (click)="uploadFile()" type="button">Start uploading photos</button>
  </div>
</div>
