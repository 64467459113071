<div class="school-groups-list-content">
  
  <div class="content"
  
  >
    <app-school-groups-list-filters
      [filters]="filters"
      (filtersUpdated)="updateFilters($event)"
    ></app-school-groups-list-filters>
    <app-school-groups-table
      [dataArr]="schoolGroups"
      
      [isLoading]="isLoading"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"
    ></app-school-groups-table>
  </div>
</div>
