import { BuilderFactory } from './builder.factory';
import { PrintBuilder } from './builder.utils';
import { PrinterService } from './printer.service';

interface IBasePrintServiceParams<TBuilder extends PrintBuilder<TData>, TData> {
  printer: PrinterService,
  builder?: TBuilder,
  factory?: BuilderFactory<TBuilder>,
}

export abstract class BasePrintService<TBuilder extends PrintBuilder<TData>, TData> {
  private printer: PrinterService;
  protected builder: TBuilder;
  protected factory?: BuilderFactory<TBuilder>

  constructor(params: IBasePrintServiceParams<TBuilder, TData>) {
    this.printer = params.printer;
    this.factory = params.factory;
    this.builder = params.builder;
    this.setBuilder();
  }

  protected setBuilder() {
    if (this.factory) {
      this.factory.setPrinterData(this.printer.configuration);
    }
    this.builder = this.factory
      ? this.factory.getBuilder()
      : this.builder;
  }

  protected buildPrintData(): string | Uint8Array | null {
    if (! this.builder) return null;

    return this.builder.useEncoder
      ? this.getBuildPrintData().getEncoderResult()
      : this.getBuildPrintData().getCommandsResult()
  }

  protected abstract getBuildPrintData(): TBuilder;

  public setData(data: TData): BasePrintService<TBuilder, TData>  {
    this.setBuilder();
    this.builder?.setData(data);
    return this;
  }

  public print(): void {
    if (this.printer.usbAllowed) {
      if (this.printer.lastDeviceChanged && this.builder) {
        const currentData = this.builder.getData();
        this.setData(currentData);
        this.printer.updateLastDeviceChanged();
      }
      const result = this.buildPrintData();

      this.printer.addToPrintQueue(result);
    }
  }
}
