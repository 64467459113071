import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBellSchedulesService } from '../bell-schedules.service';
import {
  BellScheduleResponseDto,
  PaginationResultsDto,
} from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../shared/logger';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bell-schedules-list',
  templateUrl: 'bell-schedules-list.template.html',
  styleUrls: ['./bell-schedules-list.scss'],
})
export class AppBellSchedulesListComponent extends BaseComponent {
  public bellSchedules: BellScheduleResponseDto[];
  public tableFilters: ITableFilters;
  public hasSelectedSchool: boolean;
  private schoolId: number;

  constructor(
    public route: ActivatedRoute,
    private toastService: AppToastManagerService,
    private bellSchedulesService: AppBellSchedulesService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data
      .data as PaginationResultsDto<BellScheduleResponseDto>;
    this.bellSchedules = data.results;
    this.schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    this.hasSelectedSchool = !!this.schoolId;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.BELL_SCHEDULES_LIST
    );
    this.tableFilters.count = data.options?.totalItems;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.tableFilters = newTableFilters;
    await this.updateBellSchedulesList();
    this.isLoading = false;
  }

  public async updateBellSchedulesList(): Promise<void> {
    try {
      const newData = await this.bellSchedulesService.getBellSchedulesList(
        this.schoolId,
        this.tableFilters
      );
      this.bellSchedules = newData.results;
      this.tableFilters.count = newData.options?.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving bell schedules!');
      logger.error(err);
    }
  }
}
