"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeNotificationResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const shared_1 = require("../shared");
const nested_dto_config_1 = require("../../nested-dto-config");
class BehaviorCodeNotificationResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.description = options.description;
        this.triggerCount = options.triggerCount;
        this.behaviorCode = options.behaviorCode;
        this.notification = options.notification;
        this.school = options.school;
        this.notificationTemplateId = options.notificationTemplateId;
        this.schoolId = options.schoolId;
    }
    getFields() {
        return [
            'id',
            'description',
            'triggerCount',
            'behaviorCode',
            'notification',
            'notificationTemplateId',
            'schoolId'
        ];
    }
    id;
    description;
    triggerCount;
    behaviorCode;
    notification;
    school;
    notificationTemplateId;
    schoolId;
    getNested() {
        return [
            nested_dto_config_1.NestedDtoConfig.relationConfig('school', 'school'),
            nested_dto_config_1.NestedDtoConfig.relationConfig('behaviorCode', 'behaviorCode'),
            nested_dto_config_1.NestedDtoConfig.relationConfig('notification', 'notificationTemplates')
        ];
    }
    mapFields(notification) {
        super.mapFields(notification);
        return this;
    }
    static mapBehaviorCodeNotifications(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, BehaviorCodeNotificationResponseDto);
    }
}
exports.BehaviorCodeNotificationResponseDto = BehaviorCodeNotificationResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeNotificationResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], BehaviorCodeNotificationResponseDto.prototype, "description", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeNotificationResponseDto.prototype, "triggerCount", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", shared_1.RelationDto)
], BehaviorCodeNotificationResponseDto.prototype, "behaviorCode", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", shared_1.RelationDto)
], BehaviorCodeNotificationResponseDto.prototype, "notification", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", shared_1.RelationDto)
], BehaviorCodeNotificationResponseDto.prototype, "school", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeNotificationResponseDto.prototype, "notificationTemplateId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeNotificationResponseDto.prototype, "schoolId", void 0);
