<div class="default-dialog">
  <div class="mat-dialog-container">
    <div class="mat-dialog-header default-dialog-header" *ngIf="title">
      <div class="mat-dialog-title">{{title}}</div>
    </div>
    <mat-dialog-content>
      <div class="confirmation-dialog">
        <ng-container> {{ content }} </ng-container>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="mat-dialog-actions">
      <mat-form-field class="type-to-confirm" *ngIf="typeToConfirm">
        <input
          matInput
          [placeholder]="typeToConfirm"
          (change)="onTypeToConfirmChange($event)"
          (keyup.enter)="onCheckConfirmAction($event)" />
      </mat-form-field>
      <button
        mat-flat-button
        *ngFor="let action of actions"
        [ngClass]="getClasses(action)"
        [color]="action.color"
        (click)="onAction(action.key)">
        <i *ngIf="action.icon" class="fa {{action.icon}}"></i>
        <fa-icon *ngIf="action.icon" [icon]="['fas', action.icon]"></fa-icon>
        {{action.label}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
