"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarDayCreateDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const isValidDate_decorator_1 = require("../shared/isValidDate.decorator");
const transform_config_1 = require("../../transform-config");
class CalendarDayCreateDto extends mappable_base_1.MappableBase {
    constructor(calenderDayCreateOptions) {
        super();
        if (!calenderDayCreateOptions)
            return;
        this.date = calenderDayCreateOptions.date;
        this.schoolId = calenderDayCreateOptions.schoolId;
        this.name = calenderDayCreateOptions.name;
        this.notes = calenderDayCreateOptions.notes;
    }
    getFields() {
        return ['name', 'notes', 'date'];
    }
    date;
    schoolId;
    name;
    notes;
    getTransforms() {
        return [
            {
                destination: 'date',
                transform: transform_config_1.TransformConfig.transformDateToTimestamp('date'),
                sourceFields: ['date']
            }
        ];
    }
}
exports.CalendarDayCreateDto = CalendarDayCreateDto;
__decorate([
    (0, isValidDate_decorator_1.IsValidTimestamp)(),
    __metadata("design:type", Number)
], CalendarDayCreateDto.prototype, "date", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], CalendarDayCreateDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], CalendarDayCreateDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], CalendarDayCreateDto.prototype, "notes", void 0);
