import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppResolverErrorHandlerService } from '../../shared/services/resolver-error-handler.service';
import { AppUsersServices } from './users.service';
import { BaseResolver } from '../../shared/base-classes/base.resolver';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { UserDetailResponseDto } from '@whetstoneeducation/hero-common';

@Injectable({ providedIn: 'root' })
export class AppUserResolver extends BaseResolver<UserDetailResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public usersService: AppUsersServices
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<UserDetailResponseDto> {
    const id = route.paramMap.get('id');
    if (id == 'new') {
      return plainToInstance(
        UserDetailResponseDto,
        {
          active: true,
          address: '',
          city: '',
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          roles: [],
          schoolGroupId: 1,
          schoolId: 1,
          state: null,
          type: '',
          zip: ''
        },
        {
          exposeUnsetFields: true
        }
      );
    } else {
      const user = await this.usersService.getUserById(parseInt(id));
      return user;
    }
  }
}
