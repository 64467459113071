import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

export interface IResponsiveGridColumns {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const RESPONSIVE_GRID_PRESETS = {
  TWO_COLUMN: { xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }
};

@Directive({
  selector: '[responsiveCols]',
  standalone: true
})
export class ResponsiveGridColumnsDirective implements OnInit, OnDestroy {
  private responsiveCols: IResponsiveGridColumns = {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 6,
    xl: 8
  };
  private breakpointSubscription: Subscription;

  public get cols(): IResponsiveGridColumns {
    return this.responsiveCols;
  }

  @Input('responsiveCols')
  public set cols(value: IResponsiveGridColumns) {
    if (value && value instanceof Object) {
      this.responsiveCols = value;
    }
  }

  public constructor(
    private grid: MatGridList,
    private breakpointObserver: BreakpointObserver
  ) {
    if (this.grid != null) {
      this.grid.cols = this.responsiveCols.md;
    }
  }

  public ngOnInit(): void {
    if (this.grid != null) {
      this.grid.cols = this.responsiveCols.sm;
      this.breakpointSubscription = this.breakpointObserver
        .observe([
          Breakpoints.XSmall,
          Breakpoints.Small,
          Breakpoints.Medium,
          Breakpoints.Large,
          Breakpoints.XLarge,
          Breakpoints.Web,
          Breakpoints.WebLandscape,
          Breakpoints.WebPortrait,
          Breakpoints.Handset
        ])
        .subscribe((result) => {
          if (result.breakpoints[Breakpoints.XSmall])
            this.grid.cols = this.responsiveCols.xs;
          if (result.breakpoints[Breakpoints.Small])
            this.grid.cols = this.responsiveCols.sm;
          if (result.breakpoints[Breakpoints.Medium])
            this.grid.cols = this.responsiveCols.md;
          if (result.breakpoints[Breakpoints.Large])
            this.grid.cols = this.responsiveCols.lg;
          if (result.breakpoints[Breakpoints.XLarge])
            this.grid.cols = this.responsiveCols.xl;
          if (result.breakpoints[Breakpoints.Web])
            this.grid.cols = this.responsiveCols.xl;
          if (result.breakpoints[Breakpoints.WebLandscape])
            this.grid.cols = this.responsiveCols.xl;
          if (result.breakpoints[Breakpoints.WebPortrait])
            this.grid.cols = this.responsiveCols.lg;
          if (result.breakpoints[Breakpoints.Handset])
            this.grid.cols = this.responsiveCols.xs;
        });
    }
  }

  public ngOnDestroy(): void {
    if (this.breakpointSubscription != null)
      this.breakpointSubscription.unsubscribe();
  }
}
