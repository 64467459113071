export enum BehaviorCodesListColumns {
  REDORDER = 'Reorder',
  ORDER = 'Order',
  CODE = 'Code',
  DESCRIPTION = 'Description',
  SCHOOL = 'School',
  PRINT_PASS = 'Print Pass',
  ACTIVE = 'Active',
  ACTIONS = 'Actions',
}
