"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPasswordDto = void 0;
const class_validator_1 = require("class-validator");
const shared_1 = require("../shared");
const class_transformer_1 = require("class-transformer");
const mappable_base_1 = require("../../mappable.base");
class UserPasswordDto extends mappable_base_1.MappableBase {
    constructor(userPasswordOptions) {
        super();
        if (!userPasswordOptions)
            return;
        this.password = userPasswordOptions.password;
        this.passwordConfirm = userPasswordOptions.passwordConfirm;
    }
    /**
     * ##password
     *
     * The password field must satisfy the following constraints:
     * - Minimum length of 14 characters.
     * - Maximum length of 24 characters.
     * - Must contain at least one digit.
     * - Must contain at least one uppercase letter.
     * - Must contain at least one lowercase letter.
     * - Must contain one of the following special characters: !#$%&*:=?@^_|~
     * - Must not contain any characters outside the following list:
     *   0-9, a-z, A-Z, !#$%&*:=?@^_|~
     *
     * Regular expression breakdown:
     * - (?=.*\d) Positive lookahead to ensure at least one digit.
     * - (?=.*[A-Z]) Positive lookahead to ensure at least one uppercase letter.
     * - (?=.*[a-z]) Positive lookahead to ensure at least one lowercase letter.
     * - (?=.*[!#$%&*:=?@^_|~]) Positive lookahead to ensure at least one of the special characters from your list.
     * - (?![^\dA-Za-z!#$%&*:=?@^_|~]) Negative lookahead to disallow any characters outside your list.
     * - .* Match any character (except newline), zero or more times.
     * - $ End of the line anchor.
     *
     * @type {string}
     * @property {string} password The user's password.
     * @throws {Error} Throws an error with a detailed message if the password is too weak.
     */
    password;
    /**
     * ##password confirmation
     * @type {string}
     * @property {string} passwordConfirm The user's password confirmation.
     * Throws an error with a message if the password
     * confirmation does not match the password.
     */
    passwordConfirm;
    getFields() {
        return ['password', 'passwordConfirm'];
    }
}
exports.UserPasswordDto = UserPasswordDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.MinLength)(14),
    (0, class_validator_1.MaxLength)(24),
    (0, class_validator_1.Matches)(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!#$%&*:=?@^_|~])(?![^\dA-Za-z!#$%&*:=?@^_|~]).*$/, {
        message: 'Password is too weak. It must contain at least one lower case letter, ' +
            'one upper case letter, one number, one special character, and can only ' +
            'include the following special characters: !#$%&*:=?@^_|~'
    }),
    __metadata("design:type", String)
], UserPasswordDto.prototype, "password", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, shared_1.Match)('password', { message: 'password confirmation must match' }),
    __metadata("design:type", String)
], UserPasswordDto.prototype, "passwordConfirm", void 0);
