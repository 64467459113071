import { EventEmitter, Injectable, Output } from '@angular/core';

/**
 * Service used to toggle the status of our application loading bar
 */
@Injectable({
  providedIn: 'root'
})
export class AppLoadingBarService {
  /**
   * Emits whenever our isLoading status should be changed
   */
  @Output()
  public loadingStatusChanged: EventEmitter<boolean>;

  /**
   * Default Constructor
   */
  constructor() {
    this.loadingStatusChanged = new EventEmitter<boolean>();
  }

  /**
   * Toggles the isLoading status of application loading boar
   */
  public setIsLoading(isLoading: boolean) {
    this.loadingStatusChanged.emit(isLoading);
  }

  /**
   * Hides the loading bar
   */
  public hide() {
    this.setIsLoading(false);
  }

  /**
   * Shows the loading bar
   */
  public show() {
    this.setIsLoading(true);
  }
}
