import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { WarningBannerComponent } from './warning-banner/warning-banner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    FontAwesomeModule,
  ],
  declarations: [WarningBannerComponent],
  exports: [WarningBannerComponent],
})
export class AppBannersModule {}
