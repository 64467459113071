"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CronDaysOfWeekEnum = void 0;
var CronDaysOfWeekEnum;
(function (CronDaysOfWeekEnum) {
    CronDaysOfWeekEnum[CronDaysOfWeekEnum["SUNDAY"] = 0] = "SUNDAY";
    CronDaysOfWeekEnum[CronDaysOfWeekEnum["MONDAY"] = 1] = "MONDAY";
    CronDaysOfWeekEnum[CronDaysOfWeekEnum["TUESDAY"] = 2] = "TUESDAY";
    CronDaysOfWeekEnum[CronDaysOfWeekEnum["WEDNESDAY"] = 3] = "WEDNESDAY";
    CronDaysOfWeekEnum[CronDaysOfWeekEnum["THURSDAY"] = 4] = "THURSDAY";
    CronDaysOfWeekEnum[CronDaysOfWeekEnum["FRIDAY"] = 5] = "FRIDAY";
    CronDaysOfWeekEnum[CronDaysOfWeekEnum["SATURDAY"] = 6] = "SATURDAY";
})(CronDaysOfWeekEnum || (exports.CronDaysOfWeekEnum = CronDaysOfWeekEnum = {}));
