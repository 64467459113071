import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStudentsService } from '../../students.service';
import { DateUtil } from '@whetstoneeducation/hero-common';
import { PROFILE_TABS } from '../student-profile-tabs.enum';

const codeTypeColors = {
  blue: '#2c89bf',
  red: '#ec5a5a',
  gray: '#8a96a0'
};

const codeTypeColorMapper = {
  positive: 'blue',
  negative: 'red',
  neutral: 'gray',
  tardy: 'red'
};

@Component({
  selector: 'app-student-dashboard',
  templateUrl: 'student-dashboard.template.html',
  styleUrls: ['./student-dashboard.scss']
})
export class StudentDashboardComponent implements OnInit {
  @Input()
  public studentId: number;

  @Output()
  public changeTab = new EventEmitter<PROFILE_TABS>();

  public historyData?: any = null;

  public isLoading: boolean = false;

  constructor(private studentsService: AppStudentsService) {}

  async ngOnInit() {
    this.isLoading = true;
    this.historyData =
      await this.studentsService.getStudentBehaviorLatestHistory(
        this.studentId
      );
    this.isLoading = false;
  }

  public formatLastHistoryElement(
    type: { name: string; count: number },
    isLast: boolean,
    count: number
  ): string {
    return `${isLast ? `${count > 1 ? 'and' : ''}` : ''} ${type.count} ${type.name.toLowerCase()}${isLast ? ' tracks.' : ','}`;
  }

  public getCodeTypeColor(name: string) {
    return codeTypeColorMapper[name.toLowerCase()]
      ? codeTypeColors[codeTypeColorMapper[name.toLowerCase()]]
      : '';
  }

  public convertToDisplayDateTime(dateMilliseconds: number) {
    return `${DateUtil.convertTimestampToDisplayDate(+dateMilliseconds)} at ${DateUtil.convertTimestampToDisplayTime(+dateMilliseconds)}`;
  }

  public convertToDisplayDate(dateMilliseconds: number) {
    return DateUtil.convertTimestampToDisplayDate(+dateMilliseconds);
  }

  public convertTimestampToReadableDate(dateMilliseconds: number) {
    return `${DateUtil.convertTimestampToReadableDate(+dateMilliseconds)} at ${DateUtil.convertTimestampToDisplayTime(+dateMilliseconds)}`;
  }

  public redirectToBehaviorHistory() {
    this.changeTab.emit(PROFILE_TABS.BEHAVIOR_HISTORY);
  }
}
