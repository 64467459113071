<div class="table-container mat-elevation-z2">
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="reactions-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- Name Column -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reaction"> {{ reaction.name }} </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="{{columns.PASS_MESSAGE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.PASS_MESSAGE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let reaction">
          {{ reaction.passMessage }}
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell class="action-column-header" *matHeaderCellDef>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let reaction">
          <button
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/reactions/' + reaction.id.toString()"
            options-id="EDIT_REACTION"
            [option]="reaction.id">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
          <button
            mat-flat-button
            class="action-button icon-button"
            (click)="cloneReaction(reaction)"
            options-id="CLONE_REACTION"
            [option]="reaction.id">
            <fa-icon [icon]="['fas', 'clone']"></fa-icon>
          </button>
          <button
            *ngIf="reaction.active"
            mat-flat-button
            class="action-button icon-button"
            (click)="toggleActive(reaction, false)"
            options-id="DELETE_REACTION"
            [option]="reaction.id">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
          <button
            *ngIf="!reaction.active"
            mat-flat-button
            class="action-button icon-button"
            options-id="REACTIVATE_REACTION"
            (click)="toggleActive(reaction, true)"
            [option]="reaction.id">
            <fa-icon [icon]="['fas', 'rotate']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let reaction; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
