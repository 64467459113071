"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentDetailResponseDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const enums_1 = require("../../enums");
const transform_config_1 = require("../../transform-config");
const boolean_transform_1 = require("../../constants/boolean.transform");
const class_transformer_1 = require("class-transformer");
class StudentDetailResponseDto extends mappable_base_1.MappableBase {
    constructor(responseOptions) {
        super();
        if (responseOptions)
            Object.assign(this, responseOptions);
    }
    id;
    firstName;
    lastName;
    middleInitial;
    birthDate;
    referenceId;
    flagMessage;
    address;
    city;
    state;
    zip;
    ethnicGroup;
    gender;
    studentLanguage;
    teacher;
    courseDescription;
    grade;
    homePhone;
    workPhone;
    contactPhone;
    emergencyContact;
    notes;
    homeroom;
    schoolId;
    studentExtId;
    studentGroupId;
    sendLettersViaEmail;
    profilePhotoUrl;
    guardians;
    studentGroup;
    getFields() {
        return [
            'id',
            'firstName',
            'lastName',
            'middleInitial',
            'birthDate',
            'referenceId',
            'flagMessage',
            'address',
            'city',
            'state',
            'zip',
            'ethnicGroup',
            'gender',
            'studentLanguage',
            'teacher',
            'grade',
            'homePhone',
            'workPhone',
            'contactPhone',
            'emergencyContact',
            'notes',
            'homeroom',
            'schoolId',
            'studentExtId',
            'studentGroupId',
            'sendLettersViaEmail',
            'studentGroup'
        ];
    }
    getTransforms() {
        return [
            {
                destination: 'guardians',
                sourceFields: ['guardians'],
                transform: transform_config_1.TransformConfig.transformArray('guardians', (source) => {
                    return source.map((guardian) => {
                        return {
                            id: guardian.id,
                            firstName: guardian.firstName,
                            lastName: guardian.lastName,
                            email: guardian.email,
                            address: guardian.userDetail?.address,
                            city: guardian.userDetail?.city,
                            state: guardian.userDetail?.state,
                            zip: guardian.userDetail?.zip,
                            phone: guardian.userDetail?.phone
                        };
                    });
                })
            },
            {
                // TODO: This appears to be broken and unused, should probably be removed
                destination: 'courseDescription',
                sourceFields: ['courseSchedules'],
                transform: transform_config_1.TransformConfig.transformArray('courseSchedules', (source) => {
                    if (!source) {
                        return null;
                    }
                    const values = source?.filter((value) => !!value?.bellSchedulePeriod);
                    return values?.length ? values[0]?.courseDescription : null;
                })
            }
        ];
    }
    mapFields(source) {
        if (source.studentDetail) {
            super.mapFields(source.studentDetail, true, true);
        }
        super.mapFields(source);
        return this;
    }
    /**
     * @public
     * @static
     * @function mapAll
     * @param entities {MappableBase[]}
     * @returns {UserResponseDto[]} - an array of user dtos with fields mapped from the input user array
     */
    static mapDto(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, StudentDetailResponseDto);
    }
}
exports.StudentDetailResponseDto = StudentDetailResponseDto;
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], StudentDetailResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(10),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "middleInitial", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "birthDate", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "referenceId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "flagMessage", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "city", void 0);
__decorate([
    (0, class_validator_1.IsEnum)(enums_1.StateEnum),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "state", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(15),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "zip", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "ethnicGroup", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(15),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "gender", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "studentLanguage", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "teacher", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "courseDescription", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(15),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "grade", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(20),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "homePhone", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(20),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "workPhone", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(20),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "contactPhone", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "emergencyContact", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "notes", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "homeroom", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], StudentDetailResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "studentExtId", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], StudentDetailResponseDto.prototype, "studentGroupId", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], StudentDetailResponseDto.prototype, "sendLettersViaEmail", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentDetailResponseDto.prototype, "profilePhotoUrl", void 0);
