<form [formGroup]="studentForm" novalidate>
  <div class="student-form">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
          Student Info
        </mat-card-title>
        <div class="card-header-buttons">
          <fa-icon
            [icon]="infoExpanded ? ['fas', 'circle-minus'] : ['fas',
        'plus-circle']"
            (click)="infoExpanded = !infoExpanded"></fa-icon>
        </div>
      </mat-card-header>
      <mat-card-content class="mat-elevation-z3" *ngIf="infoExpanded">
        <div class="form-row">
          <mat-form-field class="third-width-input">
            <mat-label>First Name<span> *</span></mat-label>
            <input formControlName="firstName" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="firstName"></app-error-display>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>Middle Initial</mat-label>
            <input formControlName="middleInitial" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="middleInitial"></app-error-display>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>Last Name<span> *</span></mat-label>
            <input formControlName="lastName" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="lastName"></app-error-display>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-input">
          <mat-label>School<span> *</span></mat-label>
          <mat-select
            formControlName="schoolId"
            unique-id
            (selectionChange)="getStudentGroupOptions($event.value)">
            <mat-option
              *ngFor="let school of schoolOptions"
              [value]="school.value"
              options-id="schoolOptions"
              [option]="constantize(school.display)">
              {{school.display}}
            </mat-option>
          </mat-select>
          <app-error-display
            [form]="studentForm"
            controlName="schoolId"></app-error-display>
        </mat-form-field>

        <div class="form-row">
          <mat-form-field class="half-width-input">
            <mat-label>Grade<span> *</span></mat-label>
            <input formControlName="grade" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="grade"></app-error-display>
          </mat-form-field>

          <mat-form-field class="half-width-input">
            <mat-label>Homeroom</mat-label>
            <input formControlName="homeroom" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="homeroom"></app-error-display>
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="half-width-input">
            <mat-label>External ID<span> *</span></mat-label>
            <input formControlName="studentExtId" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="studentExtId"></app-error-display>
          </mat-form-field>

          <mat-form-field class="half-width-input">
            <mat-label>Reference ID</mat-label>
            <input formControlName="referenceId" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="referenceId"></app-error-display>
          </mat-form-field>
        </div>

        <mat-form-field class="full-width-input">
          <mat-label>Teacher</mat-label>
          <input formControlName="teacher" matInput />
          <app-error-display
            [form]="studentForm"
            controlName="teacher"></app-error-display>
        </mat-form-field>

        <mat-form-field class="full-width-input">
          <mat-label>Student Group</mat-label>
          <mat-select formControlName="studentGroupId" unique-id>
            <mat-option
              *ngFor="let group of studentGroupOptions"
              [value]="group.value"
              options-id="studentGroupOptions"
              [option]="constantize(group.display)">
              {{group.display}}
            </mat-option>
          </mat-select>
          <app-error-display
            [form]="studentForm"
            controlName="studentGroupId"></app-error-display>
        </mat-form-field>

        <mat-form-field class="full-width-input">
          <mat-label>Notes</mat-label>
          <input formControlName="notes" matInput />
          <app-error-display
            [form]="studentForm"
            controlName="notes"></app-error-display>
        </mat-form-field>

        <mat-form-field class="full-width-input">
          <mat-label>Flag Message</mat-label>
          <input formControlName="flagMessage" matInput />
          <app-error-display
            [form]="studentForm"
            controlName="flagMessage"></app-error-display>
        </mat-form-field>

        <div class="form-row">
          <div class="form-item">
            <mat-slide-toggle
              [color]="'primary'"
              formControlName="sendLettersViaEmail"
              (toggleChange)="student.sendLettersViaEmail = !student.sendLettersViaEmail">
              Send Letters Via Email
            </mat-slide-toggle>
          </div>
        </div>

        <div class="form-row" *ngIf="isCreating">
          <div class="form-item">
            <mat-slide-toggle
              [color]="'primary'"
              (toggleChange)="createUser = !createUser">
              Create User for Student
            </mat-slide-toggle>
          </div>
        </div>

        <mat-form-field
          class="full-width-input"
          *ngIf="createUser && isCreating">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput />
          <app-error-display
            [form]="studentForm"
            controlName="email"></app-error-display>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-header class="card-header">
        <mat-card-title> Student Address </mat-card-title>
        <div class="card-header-buttons">
          <fa-icon
            [icon]="addressExpanded ? ['fas', 'circle-minus'] : ['fas',
        'plus-circle']"
            (click)="addressExpanded = !addressExpanded"></fa-icon>
        </div>
      </mat-card-header>
      <mat-card-content class="mat-elevation-z3" *ngIf="addressExpanded">
        <mat-form-field class="full-width-input">
          <mat-label>Address</mat-label>
          <input formControlName="address" matInput placeholder="Address" />
          <app-error-display
            [form]="studentForm"
            controlName="address"></app-error-display>
        </mat-form-field>

        <div class="form-row">
          <mat-form-field class="third-width-input">
            <mat-label>City</mat-label>
            <input formControlName="city" matInput placeholder="City" />
            <app-error-display
              [form]="studentForm"
              controlName="city"></app-error-display>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" unique-id>
              <mat-option
                *ngFor="let state of stateOptions"
                [value]="state.value"
                options-id="stateOptions"
                [option]="constantize(state.display)">
                {{state.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>Zip</mat-label>
            <input formControlName="zip" matInput placeholder="Zip" />
            <app-error-display
              [form]="studentForm"
              controlName="zip"></app-error-display>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header class="card-header">
        <mat-card-title> Contact Info </mat-card-title>
        <div class="card-header-buttons">
          <fa-icon
            [icon]="guardianExpanded ? ['fas', 'circle-minus'] : ['fas',
        'plus-circle']"
            (click)="guardianExpanded = !guardianExpanded"></fa-icon>
        </div>
      </mat-card-header>
      <mat-card-content class="mat-elevation-z3" *ngIf="guardianExpanded">
        <mat-form-field class="full-width-input">
          <mat-label>Emergency Contact</mat-label>
          <input formControlName="emergencyContact" matInput />
          <app-error-display
            [form]="studentForm"
            controlName="emergencyContact"></app-error-display>
        </mat-form-field>

        <div class="form-row">
          <mat-form-field class="third-width-input">
            <mat-label>Home Phone</mat-label>
            <input formControlName="homePhone" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="homePhone"></app-error-display>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>Work Phone</mat-label>
            <input formControlName="workPhone" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="workPhone"></app-error-display>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>Contact Phone</mat-label>
            <input formControlName="contactPhone" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="contactPhone"></app-error-display>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-header class="card-header">
        <mat-card-title> Student Demographic Info </mat-card-title>
        <div class="card-header-buttons">
          <fa-icon
            [icon]="demographicExpanded ? ['fas', 'circle-minus'] : ['fas',
        'plus-circle']"
            (click)="demographicExpanded = !demographicExpanded"></fa-icon>
        </div>
      </mat-card-header>
      <mat-card-content class="mat-elevation-z3" *ngIf="demographicExpanded">
        <div class="form-row">
          <mat-form-field class="third-width-input">
            <mat-label>Ethnic Group</mat-label>
            <input formControlName="ethnicGroup" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="ethnicGroup"></app-error-display>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>Gender</mat-label>
            <input formControlName="gender" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="gender"></app-error-display>
          </mat-form-field>

          <mat-form-field class="third-width-input">
            <mat-label>Student Language</mat-label>
            <input formControlName="studentLanguage" matInput />
            <app-error-display
              [form]="studentForm"
              controlName="studentLanguage"></app-error-display>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>
