import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { AppConfirmationDialogComponent } from './confirmation-dialog.component';
import { AppConfirmationDialogService } from './confirmation-dialog.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, AppMaterialModule, FontAwesomeModule],
  declarations: [AppConfirmationDialogComponent],
  exports: [AppConfirmationDialogComponent],
  providers: [AppConfirmationDialogService],
})
export class AppConfirmationDialogModule {}
