"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpSettingsResponseDto = void 0;
const sftp_settings_update_dto_1 = require("./sftp-settings-update.dto");
class SftpSettingsResponseDto extends sftp_settings_update_dto_1.SftpSettingsUpdateDto {
    getFields() {
        const fields = super.getFields();
        return [...fields];
    }
    mapFields(source) {
        super.mapFields(source);
        return this;
    }
}
exports.SftpSettingsResponseDto = SftpSettingsResponseDto;
