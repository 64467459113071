import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppNavbarComponent } from './navbar.component';
import { AppNavbarService } from './navbar.service';
import { AppMaterialModule } from '../../app-material.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DirectiveModule } from '../directives/directive.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgOptimizedImage } from '@angular/common';
import { GoogleTranslationComponent } from './google-translation/google-translation.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    DirectiveModule,
    FontAwesomeModule,
    NgOptimizedImage
  ],
  declarations: [AppNavbarComponent, GoogleTranslationComponent],
  exports: [AppNavbarComponent],
  providers: [AppNavbarService],
})
export class AppNavbarModule {}
