import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { IInterventionsListFilters } from '../interventions-list-models/interventions-list-filters.interface';

@Component({
  selector: 'app-interventions-list-filters',
  templateUrl: './interventions-list-filters.template.html',
  styleUrls: ['./interventions-list-filters.scss'],
})
export class AppInterventionsListFiltersComponent extends BaseComponent {
  @Input() filters: IInterventionsListFilters;

  @Output() filtersUpdated: EventEmitter<IInterventionsListFilters>;

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<IInterventionsListFilters>();
  }
}
