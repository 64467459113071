<div class="mat-dialog-header">
  <div class="mat-dialog-title">
    {{title}}
    <span class="behavior-tracking-student-count">
      {{students.length}} {{students.length > 1 ? "Students Selected" : "Student
      Selected"}}
    </span>
  </div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <ng-container *ngIf="!isLoading && behaviorCodeTypeOptions?.length">
    <mat-form-field class="full-width-input">
      <mat-select
        placeholder="Select an option"
        [formControl]="behaviorCodeTypeIdControl">
        <mat-option
          *ngFor="let option of behaviorCodeTypeOptions"
          [value]="option.id">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="!isLoading && behaviorCodeOptions?.length">
    <mat-form-field class="full-width-input">
      <mat-select
        placeholder="Select an option"
        [formControl]="behaviorCodeIdControl">
        <mat-option
          *ngFor="let option of behaviorCodeOptions"
          [value]="option.id">
          {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container
    *ngIf="!isLoading && behaviorCodeOptions?.length && hasPrivilege(PrivilegeEnum.CREATE_BEHAVIOR_NOTES)">
    <!-- Note field -->
    <mat-form-field class="text-area">
      <mat-label>Notes</mat-label>
      <textarea
        class="text-area"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        [formControl]="notesControl"
        matInput
        placeholder="Notes"></textarea>
    </mat-form-field>
  </ng-container>

  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close();">
      Cancel
    </button>
    <button
      [disabled]="behaviorCodeIdControl.invalid"
      type="submit"
      mat-button
      class="modal-submit-button"
      (click)="submit()">
      Track
    </button>
  </div>
</div>
