<form [formGroup]="schoolGroupForm" novalidate class="twoColumnLayout">
  <mat-card appearance="outlined" class="column">
    <mat-card-header>
      <mat-card-title>{{ isUpdate ? "Edit Name" : "Create New"}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-form-field class="full-width-input">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" placeholder="Name"/>
        </mat-form-field>
        <app-error-display
          controlName="name"
          [form]="schoolGroupForm"
        ></app-error-display>
      </div>
      <div formGroupName="settings">
        <mat-form-field class="filter-item full-width-input">
          <mat-label>Timezone</mat-label>
          <mat-select formControlName="timezone" placeholder="Select Timezone">
            <mat-option *ngFor="let option of timezoneOptions" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-error-display
        [showOnErrors]="true"
        controlName="timezone"
        [form]="schoolGroupForm.get('settings')"
      ></app-error-display>
      </div>
    </mat-card-content>
  </mat-card>
</form>
