"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DaysOfWeekEnumString = void 0;
var DaysOfWeekEnumString;
(function (DaysOfWeekEnumString) {
    DaysOfWeekEnumString["SUNDAY"] = "1";
    DaysOfWeekEnumString["MONDAY"] = "2";
    DaysOfWeekEnumString["TUESDAY"] = "3";
    DaysOfWeekEnumString["WEDNESDAY"] = "4";
    DaysOfWeekEnumString["THURSDAY"] = "5";
    DaysOfWeekEnumString["FRIDAY"] = "6";
    DaysOfWeekEnumString["SATURDAY"] = "7";
})(DaysOfWeekEnumString || (exports.DaysOfWeekEnumString = DaysOfWeekEnumString = {}));
