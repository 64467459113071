import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { SchoolResponseDto } from '@whetstoneeducation/hero-common';
import { Component } from '@angular/core';
import { SchoolsListColumns } from '../schools-list-models/schools-list-columns.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schools-list-table',
  templateUrl: './schools-list-table.template.html',
  styleUrls: ['./schools-list-table.scss']
})
export class AppSchoolsListTableComponent extends BaseTableComponent<SchoolResponseDto> {
  public get displayedColumns() {
    return Object.values(SchoolsListColumns);
  }

  public columnValues = {
    [SchoolsListColumns.REFERENCE_ID]: ['referenceId'],
    [SchoolsListColumns.NAME]: ['name'],
    [SchoolsListColumns.SCHOOL_GROUP]: ['schoolGroupName'],
    [SchoolsListColumns.ACADEMIC_YEAR]: ['currentAcademicYear'],
    [SchoolsListColumns.ACTIVE]: ['active']
  };

  public columns = SchoolsListColumns;

  constructor(readonly router: Router) {
    super();
  }

  public async deactivateSchool(schoolId: number): Promise<void> {}

  public async viewSchool(school) {
    await this.router.navigate(['schools', school.id]);
  }
}
