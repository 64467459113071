import { ICustomRoute } from '../../app-routing.module';
import { AuthGuard } from '../../route-guards/auth.guard';
import { AppComplianceByScheduledReactionListResolver } from './compliance-by-scheduled-reaction/compliance-by-scheduled-reaction.resolver';
import { AppComplianceByScheduledReactionComponent } from './compliance-by-scheduled-reaction/compliance-by-scheduled-reaction.component';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

export const AppComplianceRoutes: ICustomRoute[] = [
  {
    path: 'compliance/scheduled-reaction/:scheduledReactionId',
    title: 'Compliance',
    component: AppComplianceByScheduledReactionComponent,
    canActivate: [AuthGuard],
    resolve: { data: AppComplianceByScheduledReactionListResolver }
  }
];
