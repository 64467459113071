<div class="teacher-rosters-list-content">
  <app-teacher-rosters-list-filters
    *ngIf="!!periods.length"
    [filters]="filters"
    [teacherPeriods]="periods"
    [selectedStudentsIds]="selectedStudentsIds.length"
    (filtersUpdated)="updateFilters($event)"
    (selectAllStudentsEvent)="selectAllStudents()"
    (deselectStudentsEvent)="deselectStudents()"
    (showFastTrackModalEvent)="showFastTrackModal()"
    (showTrackModalEvent)="showTrackModal()">
  </app-teacher-rosters-list-filters>

  <app-teacher-rosters-list-card
    [students]="students"
    *ngIf="!!students.length && !isLoading"
    [tableFilters]="filters.tableFilters"
    (selectStudent)="toggleStudentSelected($event)"
    (showPasswordResetModalEvent)="showPasswordResetModal()"
    (setSortDirectionEvent)="setSortDirection($event)"
    (setSortFieldEvent)="setSortField($event)"
    [selectedStudentsIds]="selectedStudentsIds">
  </app-teacher-rosters-list-card>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <span *ngIf="!(students.length || periods.length || isLoading)">
    There are no active periods/rosters with students
  </span>
</div>
