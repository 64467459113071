<div class="table-container mat-elevation-z2">
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="matt-table">
    <mat-table
      class="calendar-view-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <ng-container matColumnDef="{{columns.DATE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.DATE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let scheduledReaction">
          {{this.getFormattedDate(scheduledReaction.calendarDay.date)}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.CAPACITY}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let scheduledReaction"
          >{{scheduledReaction.name}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row
        *matRowDef="let calendarDays; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
