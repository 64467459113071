import { ICustomRoute } from '../../app-routing.module';
import { AppBehaviorCodesListComponent } from './behavior-codes-list/behavior-codes-list.component';
import { AuthGuard } from '../../route-guards/auth.guard';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { AppBehaviorCodesListResolver } from './behavior-codes-list/behavior-codes-list.resolver';
import { AppBehaviorCodesCreateEditComponent } from './behavior-codes-create-edit/behavior-codes-create-edit.component';
import { AppBehaviorCodesCreateEditResolver } from './behavior-codes-create-edit/behavior-codes-create-edit.resolver';
import { AppBehaviorCodeReactionCreateEditComponent } from '../behavior-code-reactions/behavior-code-reaction-create-edit/behavior-code-reaction-create-edit.component';
import { AppBehaviorCodeReactionCreateEditResolver } from '../behavior-code-reactions/behavior-code-reaction-create-edit/behavior-code-reaction-create-edit.resolver';
import { AppBehaviorCodeInterventionCreateEditResolver } from '../behavior-code-interventions/behavior-code-interventions-create-edit/behavior-code-interventions-create-edit.resolver';
import { AppBehaviorCodeInterventionsCreateEditComponent } from '../behavior-code-interventions/behavior-code-interventions-create-edit/behavior-code-interventions-create-edit.component';
import { AppBehaviorCodeNotificationCreateEditResolver } from '../behavior-code-notifications/behavior-code-notifications-create-edit/behavior-code-notifications-create-edit.resolver';
import { AppBehaviorCodeNotificationsCreateEditComponent } from '../behavior-code-notifications/behavior-code-notifications-create-edit/behavior-code-notifications-create-edit.component';
import { PrivilegeEnum } from '@whetstoneeducation/hero-common';

// TODO: replace icon
export const AppBehaviorCodesRoutes: ICustomRoute[] = [
  {
    path: 'behavior-codes',
    title: 'Behavior Codes',
    component: AppBehaviorCodesListComponent,
    resolve: { data: AppBehaviorCodesListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Behavior Codes',
      buttons: [
        {
          name: 'Create Behavior Code',
          normalizedRoute: '/behavior-codes/new',
          icon: 'plus-circle',
          style: 'secondary-button',
          privilege: PrivilegeEnum.MANAGE_BEHAVIOR_CODE
        }
      ]
    }
  },
  {
    path: 'behavior-codes/:id',
    title: 'Behavior Code',
    component: AppBehaviorCodesCreateEditComponent,
    resolve: { data: AppBehaviorCodesCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Edit',
      icon: 'pencil',
      breadcrumbs: [
        {
          path: '/behavior-codes',
          title: 'Behavior Codes'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  },
  {
    path: 'behavior-codes/:id/behavior-code-reaction/:behaviorCodeReactionId',
    title: 'Behavior Code Reaction',
    component: AppBehaviorCodeReactionCreateEditComponent,
    resolve: { data: AppBehaviorCodeReactionCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Add Reaction',
      icon: 'pencil',
      breadcrumbs: [
        {
          path: '/behavior-codes/:id',
          title: 'Behavior Codes'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  },
  {
    path: 'behavior-codes/:id/behavior-code-interventions/:behaviorCodeInterventionId',
    title: 'Behavior Code Intervention',
    component: AppBehaviorCodeInterventionsCreateEditComponent,
    resolve: { data: AppBehaviorCodeInterventionCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Add Intervention',
      icon: 'pencil',
      breadcrumbs: [
        {
          path: '/behavior-codes/:id',
          title: 'Behavior Codes'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'success-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  },
  {
    path: 'behavior-codes/:id/behavior-code-notifications/:behaviorCodeNotificationId',
    title: 'Behavior Code Notification',
    component: AppBehaviorCodeNotificationsCreateEditComponent,
    resolve: { data: AppBehaviorCodeNotificationCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Add Notification',
      icon: 'pencil',
      breadcrumbs: [
        {
          path: '/behavior-codes/:id',
          title: 'Behavior Codes'
        }
      ],
      buttons: [
        {
          name: 'Save',
          style: 'success-button',
          action: HeaderButtonAction.SAVE
        }
      ]
    }
  }
];
