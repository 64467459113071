import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-color-progress-bar',
  templateUrl: './color-progressbar.template.html',
  styleUrls: ['./color-progressbar.scss']
})
export class AppColorProgressBarComponent implements AfterViewInit, OnChanges {
  @Input() value: number;
  @Input() maxValue: number;
  public barColor: string;

  constructor() {}

  ngAfterViewInit() {
    this.setBarColor();
  }

  ngOnChanges() {
    this.setBarColor();
  }

  setBarColor() {
    const percentage = this.value / this.maxValue;
    if (percentage == 0) {
      // zero set to default background theme color
      this.barColor = 'progress-bar-empty';
    } else if (percentage >= 0.75) {
      this.barColor = 'progress-bar-success';
    } else if (percentage >= 0.5) {
      this.barColor = 'progress-bar-warn';
    } else {
      this.barColor = 'progress-bar-error';
    }
  }
}
