import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute } from '@angular/router';
import { AppUsersServices } from '../users.service';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { IUsersListResolverData } from './users-list-models/users-list-resolver-data.interface';
import { IUsersListFilters } from './users-list-models/users-list-filters.interface';
import { logger } from '../../../shared/logger';
import { UserBasicResponseDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.template.html',
  styleUrls: ['./users-lists.scss'],
})
export class AppUsersListComponent extends BaseComponent {
  public users: UserBasicResponseDto[];
  public filters: IUsersListFilters;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public usersServices: AppUsersServices
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as IUsersListResolverData;
    this.users = data.users.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.users.options.totalItems;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateUserList(this.filters);
    this.isLoading = false;
  }

  public async updateUserList(filters: IUsersListFilters): Promise<void> {
    try {
      const newData = await this.usersServices.getUsersList(filters);
      this.users = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving users!');
      logger.error(err);
    }
  }

  public async updateFilters(newFilters: IUsersListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.USERS_LIST,
      this.filters.tableFilters
    );
    await this.updateUserList(this.filters);
    this.isLoading = false;
  }
}
