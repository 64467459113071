"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Helpers = void 0;
class Helpers {
    /**
     * Debounce function to reduce the frequency of event handling
     * @param {func} func
     * @param {number} delay
     * @returns a debounced function
     */
    static debounce(func, delay) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }
    /**
     * Generates an Excel-style column label (e.g., "A", "B", ..., "Z", "AA", "AB") for a given positive integer.
     *
     * This method converts a 1-based index (`n`) into the corresponding column label used in Excel, where 1 maps to "A",
     * 2 maps to "B", ..., 26 maps to "Z", 27 maps to "AA", and so forth.
     *
     * @param {number} n - The 1-based index to convert to an Excel column label. Must be a positive integer.
     * @returns {string} - The corresponding Excel column label for the provided index.
     *
     * @example
     * // Single-letter columns
     * console.log(Helpers.generateColumnName(1)); // "A"
     * console.log(Helpers.generateColumnName(26)); // "Z"
     *
     * // Multi-letter columns
     * console.log(Helpers.generateColumnName(27)); // "AA"
     * console.log(Helpers.generateColumnName(28)); // "AB"
     * console.log(Helpers.generateColumnName(52)); // "AZ"
     * console.log(Helpers.generateColumnName(701)); // "ZY"
     *
     * const headerRow = Array.from(
     *   { length: 3 },
     *   (_, index) => Helpers.generateColumnName(index + 1)
     * );
     * console.log(headerRow); // ["A", "B", "C"]
     */
    static generateColumnName(n) {
        let columnName = '';
        while (n > 0) {
            // Shift n from 1-based to 0-based to align with character code calculations
            n--;
            // Calculate the remainder for the current position (0 for 'A' to 25 for 'Z')
            const remainder = n % 26;
            // Convert remainder to corresponding ASCII character and prepend to columnName
            columnName = String.fromCharCode(65 + remainder) + columnName;
            // Move to the next position by dividing by 26 and flooring the result
            n = Math.floor(n / 26);
        }
        return columnName;
    }
}
exports.Helpers = Helpers;
