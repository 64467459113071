<div class="sftp-list-content">
  <div class="content">
    <app-sftp-import-list-filters
      [filters]="filters"
      (filtersUpdated)="updateFilters($event)"></app-sftp-import-list-filters>
    <app-sftp-import-list-table
      [dataArr]="sftpImports"
      [isLoading]="isLoading"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-sftp-import-list-table>
  </div>
</div>
