import { NgModule } from "@angular/core";
import { PointsBubbleComponent } from "./points-bubble.component";
import { CommonModule } from "@angular/common";
import { AppMaterialModule } from "../../app-material.module";

@NgModule({
  declarations: [PointsBubbleComponent],
  exports: [PointsBubbleComponent],
  imports: [CommonModule, AppMaterialModule]
})
export class AppPointsBubbleModule {

}
