import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IInterventionsListResolverData } from './interventions-list-models/interventions-list-resolver-data.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppInterventionsService } from '../interventions.service';

@Injectable({ providedIn: 'root' })
export class AppInterventionsListResolver extends BaseResolver<IInterventionsListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private interventionsService: AppInterventionsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<IInterventionsListResolverData> {
    const defaultFilters =
      this.interventionsService.getInterventionsListDefaultFilters();
    return {
      defaultFilters,
      interventions: await this.interventionsService.getInterventionsList(
        defaultFilters
      ),
    };
  }
}
