<div class="table-container mat-elevation-z2">
  <div class="table-filters-top">
    <div class="right-filters">
      <button mat-flat-button (click)="exportTable()">
        <fa-icon [icon]="['fas', 'upload']"></fa-icon> Export
      </button>
    </div>
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="sftp-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <ng-container matColumnDef="{{columns.CREATED_DATE}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.CREATED_DATE}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.createdAt | date: 'MM/DD/YYYY' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.SCHOOL}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.SCHOOL}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.school?.name || 'N/A' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.SCHOOL_GROUP}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.SCHOOL_GROUP}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.schoolGroup.name }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.IMPORT_SUBJECT}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.IMPORT_SUBJECT}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.importSubject }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.IMPORT_STATUS}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.IMPORT_STATUS}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.status }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell *matHeaderCellDef>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-flat-button
            (click)="viewSftpImport(row)"
            class="action-button icon-button">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
          </button>
          <button
            *ngIf="row.isLatest"
            mat-flat-button
            matTooltip="Download Import File"
            class="action-button icon-button"
            (click)="downloadImportFile(row)">
            <fa-icon [icon]="['fas', 'download']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
