import { AppAuthService } from '../auth/auth.service';
import { AppClientDataService } from 'src/app/shared/services/client-data-service/app-client-data.service';
import { AppUsersServices } from '../users/users.service';
import { BaseService } from 'src/app/shared/base-classes/base.service';
import { Injectable } from '@angular/core';
import {
  LoginResponseDto,
  SchoolGroupFilterDto,
  SelfUpdateDto,
  UserDetailResponseDto,
  UserPasswordDto,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { AppSchoolGroupsService } from '../school-groups/school-groups.service';
import { AppSchoolsServices } from '../schools/schools.service';
import { IMySettingsResolverData } from './my-settings-resolver-data.interface';
import { AppSessionHandlerService } from '../auth/session-handler.service';
import { AppNavbarService } from '../../shared/navbar/navbar.service';

@Injectable({
  providedIn: 'root'
})
export class AppMySettingsService extends BaseService {
  public constructor(
    public authService: AppAuthService,
    public clientDataService: AppClientDataService,
    public sessionHandlerService: AppSessionHandlerService,
    public usersService: AppUsersServices,
    public schoolGroupsService: AppSchoolGroupsService,
    public schoolService: AppSchoolsServices,
    private navbarService: AppNavbarService,
  ) {
    super();
  }

  public async getMySettingsData(): Promise<IMySettingsResolverData> {
    const currentUser = this.StorageManager.getLoggedInUser();
    if (currentUser.type === UserTypeEnum.INTERNAL_ADMIN) {
      return this.fetchSettingsData(currentUser);
    } else {
      const userCall = this.usersService.getUser();
      return {
        user: await userCall,
        schools: await this.schoolService.getSchoolsOptions(),
        schoolGroups: currentUser.schoolGroups
      };
    }
  }

  private async fetchSettingsData(
    currentUser: LoginResponseDto
  ): Promise<IMySettingsResolverData> {
    const userCall = this.usersService.getUser();
    const schoolGroupsFilter = new SchoolGroupFilterDto({
      page: 1,
      itemsPerPage: 1000
    });
    const schoolGroupsCall =
      this.schoolGroupsService.getSchoolGroupOptions(schoolGroupsFilter);
    const schoolsCall = this.schoolService.getSchoolsList({
      schoolGroupId: currentUser.schoolGroupId
        ? currentUser.schoolGroupId
        : null,
      searchInput: null,
      tableFilters: {
        count: 0,
        page: 1,
        itemsPerPage: 1000,
        active: 'Name',
        direction: 'asc'
      }
    });
    return {
      user: await userCall,
      schools: (await schoolsCall).results,
      schoolGroups: (await schoolGroupsCall).results
    };
  }

  public async saveSettings(updateSelfDto: SelfUpdateDto): Promise<void> {
    try {
      const updatedUser: UserDetailResponseDto =
        await this.clientDataService.execute<UserDetailResponseDto>(
          this.PATCH_ROUTES.EDIT_SELF,
          {
            method: this.METHOD.PATCH,
            pathParams: {
              id: updateSelfDto.id
            },
            body: updateSelfDto
          }
        );
      const currentUser = this.StorageManager.getLoggedInUser();
      currentUser.mapUserDetailResponse(new UserDetailResponseDto(updatedUser));
      this.StorageManager.saveUserInfo(currentUser);
      this.updateNavBar();
    } catch (error) {
    }
  }

  public async setPassword(id: number, updatePasswordDto: UserPasswordDto) {
    await this.clientDataService.execute(
      this.PATCH_ROUTES.PASSWORD_BY_USER_ID,
      {
        method: this.METHOD.PATCH,
        body: updatePasswordDto,
        pathParams: {
          id: id
        }
      }
    );
  }

  public updateNavBar() {
    this.navbarService.updateData();
  }
}
