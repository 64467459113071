import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { StudentProfileResponseDto, UserTypeEnum } from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppStudentsService } from '../students.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppStudentProfileResolver extends BaseResolver<StudentProfileResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private studentsService: AppStudentsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<StudentProfileResponseDto> {
    const studentId = route.paramMap.get('id');
    // if student user and no student id, show error page
    if (!studentId && this.StorageManager.getLoggedInUserType() == UserTypeEnum.STUDENT && !this.StorageManager.getLoggedInUser().studentId) {
      return null;
    }
    return await this.studentsService.getStudentProfile(studentId ? parseInt(studentId): null);

  }
}
