import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  BellScheduleCreateDto,
  BellSchedulePeriodTimeResponseDto,
  BellScheduleResponseDto,
  BellScheduleUpdateDto
} from '@whetstoneeducation/hero-common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBellSchedulesService } from '../bell-schedules.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { logger } from '../../../shared/logger';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { AppBellSchedulePeriodTimeComponent } from '../bell-schedule-period-time-dialog/bell-schedule-period-time.dialog';

@Component({
  selector: 'app-bell-schedules-create-edit',
  templateUrl: './bell-schedules-create-edit.template.html',
  styleUrls: ['./bell-schedules-create-edit.scss']
})
export class AppBellSchedulesCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public id: number;
  public bellSchedule: BellScheduleCreateDto | BellScheduleUpdateDto;
  public isCreating: boolean;
  public bellScheduleForm: FormGroup;
  public routeSubscription: Subscription;
  public periodTimes: BellSchedulePeriodTimeResponseDto[];
  private destroy$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public router: Router,
    public dialog: MatDialog,
    private bellSchedulesService: AppBellSchedulesService,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    const resolverData: BellScheduleResponseDto = this.route.snapshot.data.data;
    this.loadData(resolverData);
    this.handleRouteChanges();
  }

  public ngAfterViewInit() {
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: HeaderButtonAction) => {
        if (action === HeaderButtonAction.SAVE) {
          await this.saveChanges();
        }
      });

    this.bellScheduleForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (this.isCreating) {
          this.bellSchedule = await validateAndGetValue<BellScheduleCreateDto>(
            this.bellScheduleForm,
            BellScheduleCreateDto
          );
        } else {
          this.bellSchedule = await validateAndGetValue<BellScheduleUpdateDto>(
            this.bellScheduleForm,
            BellScheduleUpdateDto
          );
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(); // Emits a value to signal completion of the observable
    this.destroy$.complete(); // Completes the Subject and ensures proper cleanup
  }

  public loadData(data: BellScheduleResponseDto): void {
    this.id = +this.route.snapshot.params.id;
    if (!this.id) {
      const user = this.StorageManager.getLoggedInUser();
      this.bellSchedule = new BellScheduleCreateDto().mapFields(data);
      (this.bellSchedule as BellScheduleCreateDto).schoolId =
        user.currentSchoolId;
    } else {
      this.bellSchedule = new BellScheduleUpdateDto().mapFields(data);
    }
    this.periodTimes = data.bellSchedulePeriodTimes;
    this.isCreating = !this.id;
    this.bellScheduleForm = dtoToFormGroup(
      this.bellSchedule,
      this.formBuilder,
      { mapId: !this.isCreating }
    );
  }

  public async onPeriodDeleted(period: BellSchedulePeriodTimeResponseDto) {
    // remove from the periodTimes list
    this.periodTimes = this.periodTimes.filter((pt) => pt.id !== period.id);
  }

  public async onAddPeriod() {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    // load all available periods
    let periods = await this.bellSchedulesService.getPeriodsForSchool(schoolId);
    // remove periods that are already in the bell schedule
    periods = periods.filter(
      (period) =>
        !this.periodTimes.find((pt) => pt.bellSchedulePeriodId === period.id)
    );

    // open dialog to create new period time
    const ref = this.dialog.open(AppBellSchedulePeriodTimeComponent, {
      height: '600px',
      width: '800px',
      data: {
        periods,
        periodTime: new BellSchedulePeriodTimeResponseDto(),
        bellScheduleId: this.id
      }
    });

    // handle dialog close
    ref
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result) => {
        if (result && result.periodTime) {
          // save new period time
          const created =
            await this.bellSchedulesService.createBellSchedulePeriodTime(
              result.periodTime
            );
          this.periodTimes = [...this.periodTimes, created];
        }
      });
  }

  public async saveChanges(): Promise<void> {
    if (formCanSave(this.bellScheduleForm, this.toastManager)) {
      try {
        if (this.bellSchedule instanceof BellScheduleCreateDto) {
          const created = await this.bellSchedulesService.createBellSchedule(
            this.bellSchedule
          );
          this.id = created.id;
          this.bellSchedule = new BellScheduleUpdateDto().mapFields(created);
          await this.router.navigate(['/bell-schedules', created.id]);
          this.toastManager.success('Bell Schedule created successfully!');
        } else {
          const updated = await this.bellSchedulesService.updateBellSchedule(
            this.id,
            this.bellSchedule
          );
          this.bellSchedule = new BellScheduleUpdateDto().mapFields(updated);
          this.toastManager.success('Bell Schedule updated successfully!');
        }
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving Bell Schedule, please try again!'
        );
      }
    }
  }

  public handleRouteChanges() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const resolverData: BellScheduleResponseDto =
          this.route.snapshot.data.data;
        this.loadData(resolverData);
      });
  }
}
