import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppUsersServices } from '../users.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IUsersListResolverData } from './users-list-models/users-list-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppUsersListResolver extends BaseResolver<IUsersListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public usersService: AppUsersServices
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IUsersListResolverData> {
    return this.usersService.getUsersListPageResolverData();
  }
}
