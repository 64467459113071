import { AppStudentGroupCreateEditComponent } from './student-groups-create-edit/student-groups-create-edit.component';
import { AppMaterialModule } from '../../app-material.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { NgModule } from '@angular/core';
import { AppStudentGroupAddBellSchedulePopUpComponent } from './student-groups-create-edit/student-group-add-bell-schedule-pop-up/student-group-add-bell-schedule-pop-up.component';
import { AppBellSchedulesModule } from '../bell-schedules/bell-schedules.module';
import { AppStudentGroupAddStudentPopUpComponent } from './student-groups-create-edit/student-group-add-student-pop-up/student-group-add-student-pop-up.component';
import { AppStudentGroupStudentListComponent } from './student-groups-create-edit/student-group-student-list/student-group-student-list.component';
import { AppStudentGroupsListComponent } from './student-groups-list/student-groups-list.component';
import { AppStudentGroupsListCardComponent } from './student-groups-list/student-groups-list-card/student-groups-list-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { AppStudentProfilePhotoModule } from '../../shared/student-profile-photo/student-profile-photo.module';

@NgModule({
  declarations: [
    AppStudentGroupCreateEditComponent,
    AppStudentGroupAddBellSchedulePopUpComponent,
    AppStudentGroupAddStudentPopUpComponent,
    AppStudentGroupStudentListComponent,
    AppStudentGroupsListComponent,
    AppStudentGroupsListCardComponent
  ],
  exports: [],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    AppPaginationModule,
    AppStudentProfilePhotoModule,
    FontAwesomeModule,
    DirectiveModule,
    FormsModule,
    NgOptimizedImage,
    AppBellSchedulesModule,
    MatMenuModule
  ]
})
export class AppStudentGroupsModule {}
