<div class="sftp-settings-list-content">
  <div class="content">
    <app-sftp-settings-list-filters
      [filters]="filters"
      (filtersUpdated)="updateFilters($event)">
    </app-sftp-settings-list-filters>
    <app-sftp-settings-list-table
      [dataArr]="sftpSettings"
      [isLoading]="isLoading"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)">
    </app-sftp-settings-list-table>
  </div>
</div>
