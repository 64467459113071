<ng-container *ngIf="!isLoading">
  <form [formGroup]="form">
    <div [class]="isOnlyReaction ? 'only-reaction-row' : 'reaction-row'">
      <mat-form-field
        [class]="isOnlyReaction ? 'full-width-input' : 'half-width-input'">
        <mat-select
          [placeholder]="
            isOnlyReaction ? 'Select An Option' : 'Reaction' + (index + 1)
          "
          formControlName="behaviorCodeReactionId">
          <mat-option
            *ngFor="let option of behaviorCodeReactionOptions"
            options-id="BEHAVIOR_CODE_REACTION_ID"
            [value]="option.value">
            {{ option.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="!!form.controls.scheduledReactionId"
        [class]="isOnlyReaction ? 'full-width-input' : 'half-width-input'">
        <mat-select
          placeholder="Select an option"
          formControlName="scheduledReactionId">
          <mat-option
            options-id="EDIT_SCHEDULE_ID"
            *ngFor="let option of scheduledReactionOptions"
            [value]="option.value">
            {{ option.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</ng-container>
