"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeNotificationTemplateVariablesDto = void 0;
const base_template_variables_dto_1 = require("../notification-templates/base-template-variables.dto");
class BehaviorCodeNotificationTemplateVariablesDto extends base_template_variables_dto_1.BaseTemplateVariablesDto {
    constructor(userInviteTemplateVariablesOptions) {
        super();
        Object.assign(this, userInviteTemplateVariablesOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return [
            ...super.getFields(),
            'firstName',
            'lastName',
            'historyReportTable'
        ];
    }
    firstName;
    lastName;
    historyReportTable;
}
exports.BehaviorCodeNotificationTemplateVariablesDto = BehaviorCodeNotificationTemplateVariablesDto;
