import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SftpSettingsListColumns } from '../sftp-settings-list-models/sftp-settings-list-columns.enum';
import { SftpSettingsResponseDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-sftp-settings-list-table',
  templateUrl: './sftp-settings-list-table.template.html',
  styleUrls: ['./sftp-settings-list-table.scss']
})
export class AppSftpSettingsListTableComponent extends BaseTableComponent<SftpSettingsResponseDto> {
  public get displayedColumns() {
    return Object.values(SftpSettingsListColumns);
  }

  public columnValues = {
    [SftpSettingsListColumns.SCHOOL_GROUP]: ['schoolGroup', 'name'],
    [SftpSettingsListColumns.SCHOOL]: ['school', 'name'],
    [SftpSettingsListColumns.GROUP_LEVEL]: ['isSchoolGroupLevel'],
    [SftpSettingsListColumns.IMPORT_SUBJECT]: ['importSubject'],
    [SftpSettingsListColumns.ACTIONS]: ['actions']
  };

  public columns = SftpSettingsListColumns;

  constructor(readonly router: Router) {
    super();
  }

  public async editSftpSettings(sftpSettings) {
    await this.router.navigate(['sftp', 'settings', sftpSettings.id]);
  }

  public async deactivateSftpSettings(sftpSettingsId: number): Promise<void> {}

  public async disableAutomaticImport(sftpSettings) {
    // disable import
  }
}
