<div *ngIf="!!actions.length" [ngStyle]="containerStyles">
  <button
    class="outline-button header-action-button standard-button"
    type="button"
    mat-button
    [matMenuTriggerFor]="menu">
    {{title}}&nbsp;<fa-icon [icon]='["fas", "caret-down"]'></fa-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div *ngFor="let action of actions; trackBy: trackByAction">
      <button
        mat-menu-item
        *ngIf="!action.hide"
        type="button"
        [disabled]="action.disabled"
        (click)="action.onClick()">
        {{action.title}}
      </button>
    </div>
  </mat-menu>
</div>
