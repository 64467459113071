"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectUtil = void 0;
class ObjectUtil {
    /**
     * Drilling down an object using an array of properties
     */
    static getNestedPropValue(obj, propArr) {
        // If we have no more props left to drill, return our object
        if (propArr && propArr.length === 0) {
            return obj;
        }
        // Get the value from the first prop in our list of props
        const prop = propArr && propArr[0];
        const value = prop && obj[prop];
        if (value || value === "" || value === 0) {
            // If our value is an array, return an array of values
            if (Array.isArray(value)) {
                return value.map((val) => ObjectUtil.getNestedPropValue(val, propArr.slice(1)));
            }
            else {
                return ObjectUtil.getNestedPropValue(value, propArr.slice(1));
            }
        }
        else {
            // If we couldn't find a value, abandon drilling for prop value
            return null;
        }
    }
}
exports.ObjectUtil = ObjectUtil;
