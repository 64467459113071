import { Component, OnInit } from '@angular/core';
import { BaseTableComponent } from '../../../shared/tables/base-table/base-table';
import { BehaviorCodeInterventionsTableColumns } from '../behavior-code-interventions-models/behavior-code-interventions-table-columns.enum';

@Component({
  selector: 'app-behavior-code-interventions-table',
  templateUrl: './behavior-code-interventions-table.template.html',
  styleUrls: ['./behavior-code-interventions-table.scss']
})
export class AppBehaviorCodeInterventionsTableComponent
  extends BaseTableComponent<any>
  implements OnInit
{
  public get displayedColumns() {
    return Object.values(BehaviorCodeInterventionsTableColumns);
  }

  public columnValues = {
    [BehaviorCodeInterventionsTableColumns.NAME]: ['intervention', 'name'],
    [BehaviorCodeInterventionsTableColumns.DESCRIPTION]: ['description'],
    [BehaviorCodeInterventionsTableColumns.TRIGGER_AT]: ['triggerCount']
  };

  public showPagination: boolean;

  public columns = BehaviorCodeInterventionsTableColumns;
  constructor() {
    super();
  }

  public ngOnInit() {
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
  }
}
