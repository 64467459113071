import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBehaviorCodeEntryService } from '../behavior-code-entry.service';
import {
  BehaviorCodeEntryCreateDto,
  BehaviorCodeEntryResponseDto,
  IRelation,
  PrivilegeEnum,
  StudentDetailResponseDto
} from '@whetstoneeducation/hero-common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { IBehaviorCodeEntryCreateFormControlData } from './behavior-code-entry-create.models';
import { AppStudentsService } from '../../students/students.service';
import { Router } from '@angular/router';
import { AppPrivilegesService } from '../../auth/privileges.service';

@Component({
  selector: 'app-behavior-code-entry-create-modal',
  templateUrl: './behavior-code-entry-create-modal.template.html',
  styleUrls: ['./behavior-code-entry-create-modal.scss']
})
export class AppBehaviorCodeEntryCreateModalComponent
  extends BaseComponent
  implements OnInit
{
  public title: string = 'Behavior Tracking: ';
  public students: StudentDetailResponseDto[] = [];
  public behaviorCodeEntries: BehaviorCodeEntryResponseDto[] = [];
  public isLoading = false;
  public behaviorCodeTypeOptions: IRelation[];
  public behaviorCodeOptions: IRelation[];
  public behaviorCodeTypeIdControl = new FormControl<number>(
    null,
    Validators.required
  );
  public behaviorCodeIdControl = new FormControl<number>(
    null,
    Validators.required
  );
  public notesControl = new FormControl<string>('');

  public behaviorCodeEntryForms: FormGroup<IBehaviorCodeEntryCreateFormControlData>[];

  public onClose: () => void;

  constructor(
    public toastService: AppToastManagerService,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    public studentsService: AppStudentsService,
    public privilegesService: AppPrivilegesService,
    public dialogRef: MatDialogRef<AppBehaviorCodeEntryCreateModalComponent>,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      studentIds: number[];
      onClose?: () => void;
    },
    public router: Router
  ) {
    super({ privilegesService });
    this.data.studentIds.map((studentId) => {
      this.studentsService
        .getStudentById(studentId)
        .then((student) => {
          this.students.push(student);
        })
        .catch((error) => {
          this.toastService.error(error.message);
          this.dialogRef.close();
        });
    });
    if (this.data.onClose) {
      this.onClose = this.data.onClose;
    }
  }

  async ngOnInit() {
    this.isLoading = true;
    try {
      const user = this.StorageManager.getLoggedInUser();
      this.behaviorCodeTypeOptions =
        await this.behaviorCodeEntryService.getBehaviorCodeTypesWithBehaviorCodes(
          user.schoolGroupId
        );
    } catch (error) {
      this.toastService.error(error.message);
      this.dialogRef.close();
    }

    this.isLoading = false;
    // clear out student data if this changes
    this.behaviorCodeTypeIdControl.valueChanges.subscribe(async (value) => {
      this.isLoading = true;
      try {
        this.behaviorCodeOptions =
          await this.behaviorCodeEntryService.getBehaviorCodesByType(value);
        this.title = 'Select a Behavior Code: ';
      } catch (error) {
        this.toastService.error(error.message);
        this.dialogRef.close();
      }
      this.isLoading = false;
    });

    this.behaviorCodeIdControl.valueChanges.subscribe(async (value) => {
      this.isLoading = true;
      this.behaviorCodeEntryForms = this.students.map((student) => {
        return this.formBuilder.group({
          behaviorCodeControl: [
            this.behaviorCodeOptions.find(
              (behaviorCode) => behaviorCode.id === value
            ),
            Validators.required
          ],
          studentControl: [student, Validators.required],
          // placeholder value for now, will replace when select component loads
          reactionControl: [
            {
              id: -1,
              name: 'Loading...'
            },
            Validators.required
          ],
          scheduledReactionControl: [
            {
              id: -1,
              name: 'Loading...'
            }
          ],
          dateControl: [new Date(), Validators.required],
          notesControl: [this.notesControl.value]
        });
      });
      this.title = 'Confirm Reactions: ';
      this.isLoading = false;
    });
  }

  async submit() {
    this.isLoading = true;
    try {
      const entries = this.behaviorCodeEntryForms.map((form) => {
        return new BehaviorCodeEntryCreateDto({
          notes: this.notesControl.value,
          studentId: form.controls.studentControl.value.id,
          behaviorCodeId: form.controls.behaviorCodeControl.value.id,
          time: form.controls.dateControl.value.getTime(),
          schoolId: this.StorageManager.getLoggedInUser().currentSchoolId
        });
      });

      this.behaviorCodeEntries =
        await this.behaviorCodeEntryService.batchCreateBehaviorCodeEntries(
          entries
        );

      await this.router.navigate(['behavior-code-entries/confirm-reactions'], {
        queryParams: {
          behaviorCodeEntryIds: this.behaviorCodeEntries
            .map((entry) => entry.id)
            .join(',')
        }
      });
    } catch (error) {
      this.toastService.error(error.message);
    }

    this.isLoading = false;

    this.dialogRef.close();
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
