"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsRequired = exports.IS_REQUIRED = void 0;
const class_validator_1 = require("class-validator");
require("reflect-metadata");
exports.IS_REQUIRED = Symbol('isRequired');
function IsRequired() {
    return (target, propertyKey) => {
        Reflect.defineMetadata(exports.IS_REQUIRED, true, target, propertyKey);
        (0, class_validator_1.Validate)((0, class_validator_1.IsNotEmpty)(), {
            message: `${propertyKey.toString()} is required`
        })(target, propertyKey);
    };
}
exports.IsRequired = IsRequired;
