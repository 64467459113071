import { ICustomRoute } from '../../app-routing.module';
import { AppCustomRostersViewComponent } from './custom-rosters-view/custom-rosters-view.component';
import { AuthGuard } from '../../route-guards/auth.guard';
import { AppCustomRosterViewResolver } from './custom-rosters-view/custom-rosters-view.resolver';
import { AppCustomRosterManageComponent } from './custom-roster-manage/custom-roster-manage.component';
import { AppCustomRosterManageResolver } from './custom-roster-manage/custom-roster-manage.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

export const AppCustomRostersRoutes: ICustomRoute[] = [
  {
    path: 'custom-roster/:id',
    component: AppCustomRosterManageComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: AppCustomRosterManageResolver
    },
    data: {
      title: 'Manage Roster',
      buttons: [
        {
          name: 'Add Students',
          style: 'success-button',
          action: HeaderButtonAction.ADD_STUDENTS
        },
        {
          name: 'Rename',
          style: 'secondary-button',
          action: HeaderButtonAction.EDIT
        },
        {
          name: 'Go Back',
          style: 'secondary-button',
          action: HeaderButtonAction.NAVIGATE
        }
      ]
    }
  },
  {
    path: 'custom-rosters',
    component: AppCustomRostersViewComponent,
    canActivate: [AuthGuard],
    resolve: {
      data: AppCustomRosterViewResolver
    },
    data: {
      title: 'My Custom Rosters'
    }
  }
];
