import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDisplayData } from '@whetstoneeducation/hero-common';
import { BaseComponent } from '../../../../shared/base-classes/base.component';

@Component({
  selector: 'app-default-reaction',
  templateUrl: './default-reaction.html',
  styleUrls: ['./default-reaction.scss']
})
export class AppDefaultReactionComponent extends BaseComponent {
  @Input() defaultBehaviorCodeReactionForm: FormGroup;
  @Input() reactionOptions: IDisplayData[];
  @Input() title: string;

  constructor() {
    super();
  }
}
