<form>
    <div class="twoColumnLayout">
        <div class="column">
            <div class="field-list-container" *ngFor="let fields of fieldList">
               <div class="header">
                {{fields.header}}
               </div>
                <mat-card appearance="outlined">
                    <mat-card-content>
                      <mat-list>
                        <div
                        class="field-name"
                        *ngFor="let field of fields.fields"
                        (click)="addToExport(fields, field)"
                        (keydown.enter)="addToExport(fields, field)">
                        {{field.label}}
                        <div class="add-icon"><fa-icon [icon]='["fas", "plus"]'></fa-icon></div>
                        </div>
                      </mat-list>
                    </mat-card-content>
                  </mat-card>
            </div>
        </div>
        <div class="column-right">
            <div class="builder-table-container">
                <app-export-builder-table
                [dataArr]="exportFields.exportFieldList"
                (fieldRemoved)="removeField($event)"
                ></app-export-builder-table>
            </div>
        </div>
    </div>
  </form>
  