<div class="notification-template-list-content">
  <div class="content">
    <app-notification-template-list-filters
      [schoolGroupOptions]="schoolGroupOptions"
      [schoolOptions]="schoolOptions"
      [filters]="filters"
      (filtersUpdated)="updateFilters($event)">
    </app-notification-template-list-filters>
    <app-notification-template-list-table
      [dataArr]="notificationTemplates"
      [tableFilters]="filters.tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)">
    </app-notification-template-list-table>
  </div>
</div>
