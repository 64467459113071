import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import {
  InterventionCreateDto,
  InterventionResponseDto,
  InterventionUpdateDto
} from '@whetstoneeducation/hero-common';
import { InterventionsListColumns } from '../interventions-list-models/interventions-list-columns.enum';
import { logger } from '../../../../shared/logger';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppInterventionsService } from '../../interventions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-interventions-list-table',
  templateUrl: './interventions-list-table.template.html',
  styleUrls: ['interventions-list-table.scss']
})
export class AppInterventionsListTableComponent extends BaseTableComponent<InterventionResponseDto> {
  @Input() behaviorCodeId: number;
  @Output() listUpdated: EventEmitter<void>;

  public get displayedColumns() {
    return Object.values(InterventionsListColumns);
  }

  public columnValues = {
    [InterventionsListColumns.NAME]: ['name'],
    [InterventionsListColumns.DESCRIPTION]: ['description']
  };

  public columns = InterventionsListColumns;

  constructor(
    public toastManager: AppToastManagerService,
    public router: Router,
    private interventionService: AppInterventionsService
  ) {
    super();
    this.listUpdated = new EventEmitter<void>();
  }

  public async clone(intervention: InterventionResponseDto) {
    this.isLoading = true;
    try {
      intervention.name = intervention.name + ' (Clone)';
      const newIntervention = new InterventionCreateDto().mapFields(
        intervention
      );
      const savedIntervention =
        await this.interventionService.createIntervention(
          newIntervention as InterventionCreateDto
        );
      this.listUpdated.emit();
      this.toastManager.success(
        `Intervention "${intervention.name}" created successfully!`
      );
    } catch (err) {
      logger.error(err);
      this.toastManager.error(
        'There was an error cloning this intervention, please try again!'
      );
    }
    this.isLoading = false;
  }

  public async toggleActive(
    intervention: InterventionResponseDto,
    active: boolean
  ) {
    this.isLoading = true;
    try {
      const updateIntervention: InterventionUpdateDto =
        new InterventionUpdateDto().mapFields(intervention);
      updateIntervention.active = active;
      await this.interventionService.updateIntervention(
        updateIntervention,
        intervention.id
      );
      this.listUpdated.emit();
      this.toastManager.success(
        `Intervention "${intervention.name}" ${
          active ? 'reactivated' : 'deactivated'
        } successfully!`
      );
    } catch (err) {
      logger.error(err);
      this.toastManager.error(
        'There was an error deactivating this intervention, please try again!'
      );
    }
    this.isLoading = false;
  }
}
