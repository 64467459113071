import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { browserPrintSubjectBody } from '../../../../shared/printer/browser-print.util';

export interface IViewCommunicationDialogData {
  subject: string;
  body: string;
}

@Component({
  selector: 'app-view-communication-dialog',
  templateUrl: './view-communication-dialog.template.html',
  styleUrls: ['./view-communication-dialog.scss']
})
export class AppViewCommunicationDialogComponent {
  public subject: string;
  public body: string;

  public constructor(
    public dialogRef: MatDialogRef<AppViewCommunicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IViewCommunicationDialogData
  ) {
    if (data) {
      this.subject = data.subject;
      this.body = data.body;
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onPrint(): void {
    browserPrintSubjectBody(this.subject, this.body);
  }
}
