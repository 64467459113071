import { Component, Input } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import {
  DateUtil,
  ReactionEntriesResponseDto,
  ReactionEntriesUpdateDto
} from '@whetstoneeducation/hero-common';
import { ComplianceListColumnsEnum } from '../compliance-list-models/compliance-list-column.enum';
import { AppPrivilegesService } from '../../../auth/privileges.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppComplianceService } from '../../compliance.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-compliance-list-table',
  templateUrl: './compliance-list-table.template.html',
  styleUrls: ['./compliance-list-table.scss']
})
export class AppComplianceListTableComponent extends BaseTableComponent<ReactionEntriesResponseDto> {
  @Input() behaviorCodeId: number;

  @Input() reloadTable: () => Promise<void>;

  public get displayedColumns() {
    return Object.values(ComplianceListColumnsEnum);
  }

  public columnValues = {
    [ComplianceListColumnsEnum.STUDENT_NAME]: ['Student Name'],
    [ComplianceListColumnsEnum.STUDENT_ID]: ['Student ID'],
    [ComplianceListColumnsEnum.GRADE]: ['Grade'],
    [ComplianceListColumnsEnum.ENTRY_DATE]: ['Date'],
    [ComplianceListColumnsEnum.ENTRY_TIME]: ['Time'],
    [ComplianceListColumnsEnum.BEHAVIOR_CODE]: ['Behavior Code'],
    [ComplianceListColumnsEnum.REACTION]: ['Reaction'],
    [ComplianceListColumnsEnum.REACTION_DATE]: ['Reaction Date'],
    [ComplianceListColumnsEnum.COMPLY]: ['Complied']
  };

  public columns = ComplianceListColumnsEnum;

  constructor(
    public privilegesService: AppPrivilegesService,
    public toastManager: AppToastManagerService,
    public complianceService: AppComplianceService,
    public router: Router
  ) {
    super({ privilegesService });
  }

  public async comply(reactionEntryId: number) {
    this.isLoading = true;
    const dto = new ReactionEntriesUpdateDto({
      compliedDate: new Date().getTime()
    });
    await this.complianceService.updateReactionEntry(reactionEntryId, dto);
    await this.reloadTable();
    this.isLoading = false;
  }

  protected readonly DateUtil = DateUtil;
}
