import { ICustomRoute } from '../../app-routing.module';
import { BehaviorCodeEntryConfirmReactionsComponent } from './behavior-code-entry-confirm-reactions/behavior-code-entry-confirm-reactions.component';
import { AppBehaviorCodeEntriesConfirmReactionsResolver } from './behavior-code-entry-confirm-reactions/behavior-code-entry-confirm-reactions.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

export const AppBehaviorEntryRoutes: ICustomRoute[] = [
  {
    path: 'behavior-code-entries/confirm-reactions',
    title: 'Confirm Behavior Code Entries Reactions',
    component: BehaviorCodeEntryConfirmReactionsComponent,
    resolve: { data: AppBehaviorCodeEntriesConfirmReactionsResolver },
    data: {
      overwriteTitle: false,
      buttons: [
        {
          name: 'BACK',
          style: 'secondary-button',
          action: HeaderButtonAction.BACK
        }
      ]
    }
  }
];
