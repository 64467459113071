import { SftpAccountResponseDto } from '@whetstoneeducation/hero-common';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppSftpService } from '../sftp.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ISftpAccountListFilters } from './sftp-account-list-models/sftp-account-list-filters.interface';
import { Subject, takeUntil } from 'rxjs';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { logger } from '../../../shared/logger';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { MatDialog } from '@angular/material/dialog';
import { AppSftpAccountCreateComponent } from '../sftp-account-create/sftp-account-create.component';

@Component({
  selector: 'app-sftp-account-list',
  templateUrl: './sftp-account-list.template.html',
  styleUrls: ['./sftp-account-list.scss']
})
export class AppSftpAccountListComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public sftpAccounts: SftpAccountResponseDto[];
  public schoolId: number;
  public schoolGroupId: number;
  public groupLevel: boolean;
  public filters: ISftpAccountListFilters = {
    tableFilters: TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.SFTP_ACCOUNTS_LIST
    )
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public sftpServices: AppSftpService,
    public pageHeaderService: AppPageHeaderService,
    public matDialog: MatDialog
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data;
    this.sftpAccounts = data.sftpAccounts.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.sftpAccounts.options.totalItems;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateSftpAccountList();
    this.isLoading = false;
  }

  public async updateSftpAccountList(): Promise<void> {
    try {
      this.isLoading = true;
      const newData = await this.sftpServices.getSftpAccountsList(this.filters);
      this.sftpAccounts = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
      this.isLoading = false;
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading SFTP accounts');
    }
  }

  public async updateFilters(
    newFilters: ISftpAccountListFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.SFTP_ACCOUNTS_LIST,
      this.filters.tableFilters
    );
    await this.updateSftpAccountList();
    this.isLoading = false;
  }

  public async ngAfterViewInit(): Promise<void> {
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action) => {
        if (action === HeaderButtonAction.CREATE) {
          // open mat dialog for account create
          this.matDialog
            .open(AppSftpAccountCreateComponent, { width: '500px' })
            .afterClosed()
            .subscribe(async (result) => {
              await this.updateSftpAccountList();
            });
        }
        if (action === HeaderButtonAction.LIST_SETTINGS) {
          await this.router.navigate(['sftp', 'settings']);
        }
      });
  }

  public async ngOnDestroy(): Promise<void> {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
