import { BulkUserPasswordUpdateDto } from '@whetstoneeducation/hero-common';
import { printBulkStudentPasswords } from '../../printer/browser-print.util';
import { logger } from '../../logger';
import { AppStudentsListComponent } from '../../../pages/students/students-list/students-list.component';
import { AppTeacherRostersListComponent } from '../../../pages/teacher-rosters/teacher-rosters-list/teacher-rosters-list.component';
import { AppCustomRostersViewComponent } from '../../../pages/custom-rosters/custom-rosters-view/custom-rosters-view.component';
import { TrackableStudentList } from '../../trackable-student-list/trackable-student-list.component';

export function bulkStudentPasswordResetModal(
  context:
    | AppStudentsListComponent
    | AppTeacherRostersListComponent
    | AppCustomRostersViewComponent
    | TrackableStudentList,
  mappedUserIds: number[]
): void {
  const message = `Are you sure you want to reset the passwords for these students?
    ${mappedUserIds.length} of the ${context.selectedStudentsIds.length} selected students have associated user accounts.`;
  const ref = context.confirmationDialogService.openDialog({
    title: 'Reset Passwords',
    content: message,
    actions: [
      {
        key: 'print-combined',
        label: 'Paper Saver',
        classes: 'standard-button outline-button'
      },
      {
        key: 'print-individual',
        label: 'Print On Separate Pages',
        classes: 'standard-button outline-button'
      },
      {
        key: 'no',
        label: 'No',
        classes: 'standard-button secondary-button'
      }
    ],
    width: '600px',
    height: '220px'
  });
  ref.afterClosed().subscribe(async (result) => {
    if (!result || result === 'no') {
      return;
    }
    const onePerPage = result === 'print-individual';
    context.isLoading = true;
    try {
      const result = await context.studentsService.updateStudentsPasswords(
        new BulkUserPasswordUpdateDto({
          userIds: mappedUserIds
        })
      );
      context.toastService.success('Passwords reset successfully!');
      const emailPasswordMap = new Map<string, string>();
      // iterate the result keys and set the email and password to the map
      Object.keys(result.newPasswordData).forEach((key) => {
        emailPasswordMap.set(key, result.newPasswordData[key]);
      });
      printBulkStudentPasswords(emailPasswordMap, {
        onePerPage
      });
    } catch (error) {
      if (error.message == 'popups likely blocked') {
        context.toastService.error(
          'Error resetting passwords! Please enable popups and try again.'
        );
      } else {
        context.toastService.error('Error resetting passwords!');
        logger.error(error);
      }
    } finally {
      context.isLoading = false;
    }
  });
}
