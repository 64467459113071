<div class="mat-dialog-header">
  <div *ngIf="!isLoading" class="mat-dialog-title">
    {{ DateUtil.convertTimestampToDisplayDate(calendarDay.date) }}:
    <span class="title-emphasis">Reactions</span>
  </div>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <div *ngIf="!isLoading" class="schedule-reaction-list">
    <div
      *ngFor="
        let scheduledReaction of calendarDay.scheduledReactions;
        index as i
      ">
      <div [class]="getScheduleReactionContentClassname(i)">
        <div class="scheduled-reaction-name">
          {{ scheduledReaction.reaction.name }}
        </div>
        <div class="scheduled-reaction-actions">
          <button
            type="button"
            mat-flat-button
            class="action-button"
            (click)="navigateToCompliance(scheduledReaction.id)">
            COMPLIANCE
          </button>
          <button
            *ngIf="scheduledReaction.reactionEntries.length === 0"
            type="button"
            mat-flat-button
            class="action-button icon-button"
            (click)="handleDelete(scheduledReaction.id)">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="modal-action-row">
      <button
        type="button"
        mat-button
        class="modal-submit-button"
        (click)="navigateToReactions()">
        MANAGE REACTIONS
      </button>
      <button
        type="button"
        mat-button
        class="modal-cancel-button"
        (click)="dialogRef.close()">
        Close
      </button>
    </div>
  </div>
</div>
