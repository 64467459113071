<ng-container *ngIf="profile.studentDetail.guardians.length === 0" tabindex="0">
  <div class="no-guardians" tabindex="0" aria-label="No guardians panel">
    <h2>No Guardians Found</h2>
    <p>
      There are no guardians associated with this student. Please add a guardian
      to continue.
    </p>
    <button
      aria-label="Add Guardian"
      *ngIf="!isStudent"
      type="button"
      mat-button
      color="primary"
      (click)="addGuardian()"
      class="add-guardian-button">
      <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;Guardian
    </button>
  </div>
</ng-container>
<ng-container *ngIf="profile.studentDetail.guardians.length > 0" tabindex="0">
  <div class="guardians-list" tabindex="0">
    <button
      *ngIf="!isStudent"
      type="button"
      mat-button
      color="primary"
      (click)="addGuardian()"
      class="add-guardian-button"
      aria-label="Add guardian">
      <fa-icon [icon]="['fas', 'plus']"></fa-icon>&nbsp;Guardian
    </button>
    <div
      class="student-profile-details guardian-card-grid"
      aria-label="Student Guardians">
      <mat-card
        appearance="outlined"
        *ngFor="let guardian of profile.studentDetail.guardians"
        class="student-profile-detail"
        aria-label="Student Guardian"
        tabindex="0">
        <mat-card-header class="button-card-header">
          <div
            class="header-title-container"
            tabindex="0"
            [attr.aria-label]="'Guardian name: ' + guardian.firstName + ' ' + guardian.lastName">
            <h2>{{guardian.firstName}} {{guardian.lastName}}</h2>
          </div>
          <div class="card-header-buttons">
            <fa-icon
              [icon]="['fas', 'user']"
              (click)="viewGuardian(guardian.id)"
              class="guardian-action-icon"
              role="button"
              tabindex="0"
              aria-label="View guardian">
            </fa-icon>
          </div>
          <div class="guardian-icon-container">
            <fa-icon
              [icon]="['fas', 'trash']"
              (click)="removeGuardian(guardian.id)"
              class="guardian-action-icon"
              tabindex="0"
              role="button"
              aria-label="Remove guardian">
            </fa-icon>
          </div>
        </mat-card-header>
        <mat-card-content
          class="guardian-contact-info"
          aria-label="Guardian information">
          <!-- student.guardians -->
          <div class="guardian-column">
            <div class="student-profile-detail-row">
              <div class="student-profile-detail-label">Name:</div>
              <div
                class="student-profile-detail-value"
                tabindex="0"
                [attr.aria-label]="'Guardian Name: ' + guardian.firstName + ' ' + guardian.lastName"
                (click)="!isStudent && viewGuardian(guardian.id)"
                aria-label="View guardian"
                (keypress)="!isStudent && viewGuardian(guardian.id)">
                <span class="guardian-link"
                  >{{guardian.firstName}} {{guardian.lastName}}</span
                >
              </div>
            </div>
            <div class="student-profile-detail-row">
              <div class="student-profile-detail-label">Email:</div>
              <div class="student-profile-detail-value">
                <a
                  class="guardian-link"
                  [attr.aria-label]="'Guardian Email: ' + guardian.email"
                  href="mailto:{{guardian.email}}"
                  >{{guardian.email}}</a
                >
              </div>
            </div>
            <div class="student-profile-detail-row">
              <div class="student-profile-detail-label">Phone:</div>
              <div
                class="student-profile-detail-value"
                tabindex="0"
                [attr.aria-label]="'Guardian Phone: ' + (guardian.phone || 'N/A')">
                {{guardian.phone || 'N/A' }}
              </div>
            </div>
            <div class="student-profile-detail-row">
              <div class="student-profile-detail-label">Address:</div>
              <div
                class="student-profile-detail-value"
                tabindex="0"
                [attr.aria-label]="'Guardian Address: ' + getGuardianAddress(guardian)">
                {{getGuardianAddress(guardian)}}
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-container>
