import { Component, Input, OnInit } from '@angular/core';
import {
  PaginationOptionsDto,
  StudentCourseScheduleResponseDto
} from '@whetstoneeducation/hero-common';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';
import { AppStudentsService } from '../../students.service';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';

export enum StudentCourseScheduleTableColumns {
  TERM = 'Term',
  PERIOD = 'Period',
  COURSE_NAME = 'Course Name',
  COURSE_NUMBER = 'Course Number',
  SECTION = 'Section',
  INSTRUCTOR_CODE = 'Instructor Code',
  INSTRUCTOR = 'Instructor',
  ROOM = 'Room'
}

@Component({
  selector: 'app-student-schedules',
  templateUrl: './student-schedules.template.html',
  styleUrls: ['./student-schedules.scss']
})
export class StudentSchedulesComponent
  extends BaseTableComponent<StudentCourseScheduleResponseDto>
  implements OnInit
{
  @Input()
  public studentId: number;
  public showPagination: boolean;
  public loadingData: boolean = false;
  public filters: PaginationOptionsDto;

  public columns: typeof StudentCourseScheduleTableColumns =
    StudentCourseScheduleTableColumns;

  public constructor(private studentService: AppStudentsService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.loadingData = true;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.STUDENT_COURSE_SCHEDULE_REPORT
    );
    this.filters = new PaginationOptionsDto({
      ...this.tableFilters
    });
    const data = await this.studentService.getStudentCourseSchedules(
      this.studentId,
      this.filters
    );
    this.dataArr = data.results;
    this.tableFilters.count = data.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.loadingData = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.filters = new PaginationOptionsDto({
      ...newTableFilters
    });

    await this.applyFilters();
  }

  public async applyFilters(): Promise<void> {
    this.isLoading = true;
    const data = await this.studentService.getStudentCourseSchedules(
      this.studentId,
      this.filters
    );
    this.dataArr = data.results;
    this.tableFilters.count = data.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case StudentCourseScheduleTableColumns.TERM:
        return row.term.termName;
      case StudentCourseScheduleTableColumns.PERIOD:
        return row.period;
      case StudentCourseScheduleTableColumns.COURSE_NAME:
        return row.courseDescription;
      case StudentCourseScheduleTableColumns.COURSE_NUMBER:
        return row.courseCode;
      case StudentCourseScheduleTableColumns.SECTION:
        return row.section;
      case StudentCourseScheduleTableColumns.INSTRUCTOR_CODE:
        return row.instructorCode;
      case StudentCourseScheduleTableColumns.INSTRUCTOR:
        return `${row.instructorFirstName} ${row.instructorLastName}`;
      case StudentCourseScheduleTableColumns.ROOM:
        return row.roomName;
      default:
        return null;
    }
  }
}
