import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import {
  BellSchedulePeriodResponseDto,
  BellSchedulePeriodTimeCreateDto,
  BellSchedulePeriodTimeResponseDto,
  BellSchedulePeriodTimeUpdateDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  dtoToFormGroup,
  getValue
} from '../../../shared/validation/validation.util';
import { MatOptionSelectionChange } from '@angular/material/core';
import { validate } from 'class-validator';
import { AppValidationDialogComponent } from '../../../shared/validation/validation-dialog/validation-dialog.component';

export interface IBellSchedulePeriodTimeDialogData {
  periodTime: BellSchedulePeriodTimeResponseDto;
  periods: BellSchedulePeriodResponseDto[];
  bellScheduleId: number;
}

@Component({
  selector: 'app-bell-schedule-period-create',
  templateUrl: './bell-schedule-period-time.template.html',
  styleUrls: ['./bell-schedule-period-time.scss']
})
export class AppBellSchedulePeriodTimeComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public periodTime:
    | BellSchedulePeriodTimeCreateDto
    | BellSchedulePeriodTimeUpdateDto;
  public periods: BellSchedulePeriodResponseDto[];
  public periodOptions: IDisplayData[];
  public periodForm: FormGroup;
  public periodRefCode = new FormControl('');
  public filteredPeriods: Observable<IDisplayData[]>;
  public isCreating: boolean;
  public id: number;
  public timezone: string;
  public today = new Date();
  private destroy$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public toastManager: AppToastManagerService,
    public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppBellSchedulePeriodTimeComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: IBellSchedulePeriodTimeDialogData
  ) {
    super();
    if (!data.periods.length) {
      this.toastManager.error(
        'All periods have been assigned to this bell schedule. No periods available to select from.'
      );
      this.dialogRef.close();
    }
    this.timezone = this.StorageManager.getLoggedInUserTimezone();
    this.filteredPeriods = this.periodRefCode.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterPeriods(value || ''))
    );
    this.loadData(data);
  }

  public loadData(data: IBellSchedulePeriodTimeDialogData) {
    if (data && data.periodTime) {
      this.id = data.periodTime.id;
    }
    if (!this.id) {
      this.isCreating = true;
      this.periodTime = new BellSchedulePeriodTimeCreateDto().mapFields(
        data.periodTime
      );
      if (this.periodTime instanceof BellSchedulePeriodTimeCreateDto) {
        this.periodTime.bellScheduleId = data.bellScheduleId;
      }
    } else {
      this.isCreating = false;
      this.periodTime = new BellSchedulePeriodTimeUpdateDto().mapFields(
        data.periodTime
      );
    }
    this.periods = data.periods;
    this.periodOptions = this.periods.map((period) => {
      return {
        value: period.referenceCode,
        display: period.referenceCode
      };
    });

    this.periodForm = dtoToFormGroup(this.periodTime, this.formBuilder, {
      updateOn: 'change'
    });
  }

  public ngAfterViewInit() {
    this.periodForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value) => {
        if (this.isCreating) {
          const values = await getValue<BellSchedulePeriodTimeCreateDto>(
            this.periodForm,
            BellSchedulePeriodTimeCreateDto
          );
          this.periodTime.mapFields(values);
        } else {
          const values = await getValue<BellSchedulePeriodTimeUpdateDto>(
            this.periodForm,
            BellSchedulePeriodTimeUpdateDto
          );
          this.periodTime.mapFields(values);
        }
      });
  }

  public ngOnDestroy() {}

  public async saveChanges() {
    // validate period time dto
    const errors = await validate(this.periodTime);

    if (errors.length > 0) {
      this.dialog.open(AppValidationDialogComponent, {
        data: {
          errors,
          formControlMapping: {
            bellSchedulePeriodId: 'Period',
            start: 'Start Time',
            end: 'End Time'
          }
        }
      });

      return;
    }

    this.dialogRef.close({
      id: this.id,
      periodTime: this.periodTime
    });
  }

  public async onPeriodSelected(event: MatOptionSelectionChange) {
    if (this.isCreating) {
      // find period id by reference code in periods
      const periodId = this.periods.find(
        (period) => period.referenceCode === event.source.value
      ).id;
      // guard was needed for compiler error
      if (!(this.periodTime instanceof BellSchedulePeriodTimeUpdateDto)) {
        this.periodTime.bellSchedulePeriodId = periodId;
        this.periodForm.get('bellSchedulePeriodId').setValue(periodId);
      }
    }
  }

  public async onSave() {
    await this.saveChanges();
  }

  public async onClose() {
    this.dialogRef.close();
  }

  private _filterPeriods(value: string): IDisplayData[] {
    const filterValue = value.toLowerCase();
    return this.periodOptions.filter((option) =>
      option.display.toLowerCase().includes(filterValue)
    );
  }
}
