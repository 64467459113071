import { Directive, ElementRef, OnInit } from '@angular/core';
import { hashString } from './hash-id.helper';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[unique-id]',
})
export class UniqueIdDirective implements OnInit {
  constructor(public el: ElementRef) {}

  ngOnInit(): void {
    if (environment.environmentName === "prod") return;
    const uniqueId = this.generateUniqueId(this.el.nativeElement);
    this.el.nativeElement.setAttribute('data-test-id', uniqueId);
  }


  generateUniqueId(element: HTMLElement): string {
    const xpath = this.getXPath(element);
    return this.hashId(xpath);
  }

  getXPath(element: HTMLElement): string {
    let xpath = '';
    let currentElement = element;

    while (currentElement) {
      const tagName = currentElement.tagName.toLowerCase();
      if (currentElement.parentElement) {
        const siblings = Array.from(currentElement.parentElement.children);
        const index = siblings.filter(sibling => sibling.tagName.toLowerCase() === tagName).indexOf(currentElement) + 1;
        xpath = `/${tagName}[${index}]${xpath}`;
      } else {
        xpath = `/${tagName}${xpath}`;
      }
      currentElement = currentElement.parentElement;
    }

    return xpath;
  }

  hashId(str: string): string {
    return `unique_id_${hashString(str)}`;
  }
}
