"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPreferencesResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class UserPreferencesResponseDto extends mappable_base_1.MappableBase {
    constructor(responseOptions) {
        super();
        if (!responseOptions)
            return;
        this.homepage = responseOptions.homepage;
    }
    homepage;
    getFields() {
        return ['homepage'];
    }
}
exports.UserPreferencesResponseDto = UserPreferencesResponseDto;
