"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultStudentWithSpecialNeedsOptionsEnum = exports.DefaultIncidentRolesAndBullyingOptionsEnum = void 0;
var DefaultIncidentRolesAndBullyingOptionsEnum;
(function (DefaultIncidentRolesAndBullyingOptionsEnum) {
    DefaultIncidentRolesAndBullyingOptionsEnum["AGGRESSOR"] = "Aggressor";
    DefaultIncidentRolesAndBullyingOptionsEnum["WITNESS"] = "Witness";
    DefaultIncidentRolesAndBullyingOptionsEnum["VICTIM"] = "Victim";
})(DefaultIncidentRolesAndBullyingOptionsEnum || (exports.DefaultIncidentRolesAndBullyingOptionsEnum = DefaultIncidentRolesAndBullyingOptionsEnum = {}));
var DefaultStudentWithSpecialNeedsOptionsEnum;
(function (DefaultStudentWithSpecialNeedsOptionsEnum) {
    DefaultStudentWithSpecialNeedsOptionsEnum["NO"] = "No";
    DefaultStudentWithSpecialNeedsOptionsEnum["YES"] = "Yes";
})(DefaultStudentWithSpecialNeedsOptionsEnum || (exports.DefaultStudentWithSpecialNeedsOptionsEnum = DefaultStudentWithSpecialNeedsOptionsEnum = {}));
