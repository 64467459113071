import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  BulkUserPasswordResponseDto,
  BulkUserPasswordUpdateDto,
  IDisplayData,
  PaginationOptionsDto,
  PaginationResultsDto,
  StudentBasicResponseDto,
  StudentBehaviorHistoryResponseDto,
  StudentCourseScheduleResponseDto,
  StudentCreateDto,
  StudentDetailResponseDto,
  StudentPerformanceDto,
  StudentProfileResponseDto,
  StudentUpdateDto
} from '@whetstoneeducation/hero-common';
import { IStudentsListFilters } from './students-list-models/students-list-filters.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { IStudentBehaviorHistoryFilter } from './students-list-models/student-behavior-history-filters';
import { IStudentListResolverData } from './students-list-models/student-list-resolver-data.interface';
import { AppCalendarService } from '../calendar/calendar.service';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../env.service';

@Injectable({
  providedIn: 'root'
})
export class AppStudentsService extends BaseService {
  private readonly API_GATEWAY_URL: string;
  constructor(
    private appClientDataService: AppClientDataService,
    private calendarService: AppCalendarService,
    private readonly http: HttpClient,
    private readonly envService: EnvService
  ) {
    super();
    this.API_GATEWAY_URL = this.envService.apiGatewayUrl;
  }

  public async getStudentProfile(
    studentId?: number
  ): Promise<StudentProfileResponseDto> {
    const id = studentId ?? this.StorageManager.getLoggedInUser().studentId;
    return this.appClientDataService.execute<StudentProfileResponseDto>(
      this.GET_ROUTES.STUDENT_PROFILE,
      { pathParams: { id } },
      StudentProfileResponseDto
    );
  }

  public async getStudentById(id: number): Promise<StudentDetailResponseDto> {
    return this.appClientDataService.execute<StudentDetailResponseDto>(
      this.GET_ROUTES.STUDENT_BY_ID,
      { pathParams: { id } },
      StudentDetailResponseDto
    );
  }

  public async getStudentGrades(filterOptions: any): Promise<number[]> {
    return this.appClientDataService.execute<number[]>(
      this.GET_ROUTES.STUDENT_GRADES,
      {
        queryParams: {
          ...(filterOptions.schoolId
            ? { schoolId: filterOptions.schoolId }
            : {})
        }
      }
    );
  }

  public async createStudent(
    student: StudentCreateDto
  ): Promise<StudentDetailResponseDto> {
    return this.appClientDataService.execute<StudentDetailResponseDto>(
      this.POST_ROUTES.CREATE_STUDENT,
      {
        body: student,
        method: this.METHOD.POST
      },
      StudentDetailResponseDto
    );
  }

  public async updateStudent(
    student: StudentUpdateDto,
    id: number
  ): Promise<StudentDetailResponseDto> {
    return this.appClientDataService.execute<StudentDetailResponseDto>(
      this.PUT_ROUTES.UPDATE_STUDENT,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: student
      },
      StudentDetailResponseDto
    );
  }

  public async updateStudentPassword(
    userId: number
  ): Promise<{ newPassword: string }> {
    return this.appClientDataService.execute<{ newPassword: string }>(
      this.PATCH_ROUTES.UPDATE_STUDENT_PASSWORD,
      {
        method: this.METHOD.PATCH,
        pathParams: { id: userId }
      }
    );
  }

  public async updateStudentsPasswords(
    userPasswords: BulkUserPasswordUpdateDto
  ): Promise<BulkUserPasswordResponseDto> {
    return this.appClientDataService.execute<BulkUserPasswordResponseDto>(
      this.PATCH_ROUTES.BULK_UPDATE_STUDENT_PASSWORD,
      {
        method: this.METHOD.PATCH,
        queryParams: { userIds: userPasswords.userIds }
      },
      BulkUserPasswordResponseDto
    );
  }

  public async updateAllStudentsPasswords(
    filters: IStudentsListFilters
  ): Promise<BulkUserPasswordResponseDto> {
    return this.appClientDataService.execute<BulkUserPasswordResponseDto>(
      this.PATCH_ROUTES.BULK_UPDATE_ALL_STUDENTS_PASSWORD,
      {
        method: this.METHOD.PATCH,
        queryParams: {
          ...(filters.email ? { email: filters.email } : {}),
          ...(filters.firstName ? { firstName: filters.firstName } : {}),
          ...(filters.lastName ? { lastName: filters.lastName } : {}),
          ...(filters.grade ? { grade: filters.grade } : {}),
          ...(filters.homeroom ? { homeroom: filters.homeroom } : {}),
          ...(filters.studentExtId
            ? { studentExtId: filters.studentExtId }
            : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.isInStudentGroup === true ||
          filters.isInStudentGroup === false
            ? { isInStudentGroup: filters.isInStudentGroup }
            : {})
        }
      },
      BulkUserPasswordResponseDto
    );
  }

  public async removeGuardian(
    studentId: number,
    guardianId: number
  ): Promise<StudentDetailResponseDto> {
    return this.appClientDataService.execute<StudentDetailResponseDto>(
      this.DELETE_ROUTES.DELETE_GUARDIAN,
      {
        method: this.METHOD.DELETE,
        pathParams: { id: studentId, guardianId }
      },
      StudentDetailResponseDto
    );
  }

  public async assignGuardian(
    studentId: number,
    guardianEmail: string
  ): Promise<StudentDetailResponseDto> {
    return this.appClientDataService.execute<StudentDetailResponseDto>(
      this.PATCH_ROUTES.ASSIGN_GUARDIAN,
      {
        method: this.METHOD.PATCH,
        pathParams: { id: studentId },
        queryParams: { guardianEmail }
      },
      StudentDetailResponseDto
    );
  }

  public async createStudentGuardian(
    studentId: number,
    guardianEmail: string
  ): Promise<StudentDetailResponseDto> {
    return this.appClientDataService.execute<StudentDetailResponseDto>(
      this.POST_ROUTES.CREATE_STUDENT_GUARDIAN,
      {
        method: this.METHOD.POST,
        pathParams: { id: studentId },
        queryParams: { guardianEmail }
      },
      StudentDetailResponseDto
    );
  }

  public async getStudentListResolverData(
    filters: IStudentsListFilters
  ): Promise<IStudentListResolverData> {
    const students = await this.getStudentsList(filters);

    try {
      const validCalendar =
        await this.calendarService.getCalendarDayByTimestamp(
          new Date().getTime()
        );

      return {
        students,
        validCalendar: !!validCalendar
      };
    } catch (error) {
      if (error.status === 404) {
        return {
          students,
          validCalendar: false
        };
      }
    }
  }

  public async getStudentsList(
    filters: IStudentsListFilters
  ): Promise<PaginationResultsDto<StudentPerformanceDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<StudentPerformanceDto>
    >(this.GET_ROUTES.STUDENTS_LIST, {
      queryParams: {
        ...(filters.email ? { email: filters.email } : {}),
        ...(filters.firstName ? { firstName: filters.firstName } : {}),
        ...(filters.lastName ? { lastName: filters.lastName } : {}),
        ...(filters.grade ? { grade: filters.grade } : {}),
        ...(filters.homeroom ? { homeroom: filters.homeroom } : {}),
        ...(filters.studentExtId ? { studentExtId: filters.studentExtId } : {}),
        ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {}),
        ...(filters.tableFilters.lastId
          ? { lastId: filters.tableFilters.lastId }
          : {}),
        ...(filters.isInStudentGroup === true ||
        filters.isInStudentGroup === false
          ? { isInStudentGroup: filters.isInStudentGroup }
          : {}),
        ...(filters.customRosterId
          ? { customRosterId: filters.customRosterId }
          : {})
      }
    });
  }

  public getStudentsListDefaultFilters({
    firstName,
    lastName,
    grade,
    studentExtId
  }: {
    firstName?: string;
    lastName?: string;
    grade?: string;
    studentExtId?: string;
  }): IStudentsListFilters {
    const user = this.StorageManager.getLoggedInUser();
    const defaultFilters: IStudentsListFilters = {
      schoolId: user.currentSchoolId,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.STUDENTS_LIST
      )
    };

    if (firstName) defaultFilters.firstName = firstName;
    if (lastName) defaultFilters.lastName = lastName;
    if (grade) defaultFilters.grade = grade;
    if (studentExtId) defaultFilters.studentExtId = studentExtId;

    return defaultFilters;
  }

  public async getStudentBehaviorHistory(
    studentId: number,
    filters: IStudentBehaviorHistoryFilter
  ): Promise<PaginationResultsDto<StudentBehaviorHistoryResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<StudentBehaviorHistoryResponseDto>
    >(this.GET_ROUTES.BEHAVIOR_HISTORY, {
      pathParams: { id: studentId },
      queryParams: {
        ...(filters.reporter ? { reporter: filters.reporter } : {}),
        ...(filters.startDate ? { startDate: filters.startDate } : {}),
        ...(filters.endDate ? { endDate: filters.endDate } : {}),
        ...(filters.behaviorCodeId
          ? { behaviorCodeId: filters.behaviorCodeId }
          : {}),
        ...(filters.reactionId ? { reactionId: filters.reactionId } : {}),
        ...filters.tableFilters
      }
    });
  }

  public async getStudentCourseSchedules(
    studentId: number,
    filters: PaginationOptionsDto
  ) {
    return this.appClientDataService.execute<
      PaginationResultsDto<StudentCourseScheduleResponseDto>
    >(this.GET_ROUTES.STUDENT_COURSE_SCHEDULES, {
      pathParams: { id: studentId },
      queryParams: {
        ...(filters.itemsPerPage ? { itemsPerPage: filters.itemsPerPage } : {}),
        ...(filters.page ? { page: filters.page } : {})
      }
    });
  }

  public async getReporterOptions(schoolId: number): Promise<IDisplayData[]> {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.REPORTER_OPTIONS,
      {
        method: this.METHOD.GET,
        pathParams: {
          schoolId
        }
      }
    );
  }

  public async getBehaviorCodeOptions(
    schoolId: number
  ): Promise<IDisplayData[]> {
    return this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_DROPDOWN,
      {
        method: this.METHOD.GET,
        pathParams: {
          schoolId
        }
      }
    );
  }

  public async getReactionOptions(): Promise<{
    [schoolId: number]: IDisplayData[];
  }> {
    return this.appClientDataService.execute<{
      [schoolId: number]: IDisplayData[];
    }>(this.GET_ROUTES.REACTIONS_LIST_DISPLAY_DATA, {
      method: this.METHOD.GET
    });
  }

  public async getStudentBehaviorLatestHistory(
    studentId: number
  ): Promise<any> {
    return this.appClientDataService.execute<
      PaginationResultsDto<StudentBehaviorHistoryResponseDto>
    >(this.GET_ROUTES.BEHAVIOR_LATEST_HISTORY, {
      pathParams: { id: studentId }
    });
  }

  public async uploadStudentPhoto(studentId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      this.appClientDataService.setUrlPathParameters(
        `${this.API_GATEWAY_URL}${this.POST_ROUTES.UPLOAD_STUDENT_PHOTO}`,
        { id: studentId }
      ),
      formData,
      { headers: { Version: '1' }, withCredentials: true }
    );
  }

  public async bulkUploadStudentPhoto(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      this.appClientDataService.setUrlPathParameters(
        `${this.API_GATEWAY_URL}${this.POST_ROUTES.BULK_UPLOAD_STUDENT_PHOTO}`,
        {}
      ),
      formData,
      { headers: { Version: '1' }, withCredentials: true }
    );
  }
}
