import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../app-material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppNotificationTemplateComponent } from './templates/notification-template.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppSaveBarModule } from '../../shared/save-bar/save-bar.module';
import { AppNotificationTemplateListFilters } from './template-list/template-list-filters/notification-template-list-filters.component';
import { AppNotificationTemplateListTableComponent } from './template-list/template-list-table/notification-template-list-table.component';
import { AppNotificationTemplateListComponent } from './template-list/notification-template-list.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
  imports: [
    CommonModule,
    AppMaterialModule,
    RouterModule,
    FontAwesomeModule,
    DirectiveModule,
    ReactiveFormsModule,
    AppPaginationModule,
    AppErrorDisplayComponent,
    AppSaveBarModule,
    EditorModule
  ],
  declarations: [
    AppNotificationTemplateComponent,
    AppNotificationTemplateListFilters,
    AppNotificationTemplateListTableComponent,
    AppNotificationTemplateListComponent
  ],
  exports: [AppNotificationTemplateComponent],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class AppNotificationModule {}
