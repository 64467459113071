import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import {
  BellScheduleTeacherPeriodResponseDto,
  PrivilegeEnum,
  TeacherRostersFilterOptionsDto
} from '@whetstoneeducation/hero-common';
import { AppPrivilegesService } from '../../../auth/privileges.service';

@Component({
  selector: 'app-teacher-rosters-list-filters',
  templateUrl: './teacher-rosters-list-filters.template.html',
  styleUrls: ['./teacher-rosters-list-filters.scss']
})
export class AppTeacherRostersListFilters extends BaseComponent {
  @Input() filters: TeacherRostersFilterOptionsDto;
  @Input() selectedStudentsIds: number;
  @Input() teacherPeriods: BellScheduleTeacherPeriodResponseDto[];
  @Output() filtersUpdated: EventEmitter<TeacherRostersFilterOptionsDto> =
    new EventEmitter<TeacherRostersFilterOptionsDto>();
  @Output() showTrackModalEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectAllStudentsEvent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() deselectStudentsEvent: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() showFastTrackModalEvent: EventEmitter<void> =
    new EventEmitter<void>();

  constructor(privilegesService: AppPrivilegesService) {
    super({ privilegesService });
  }

  public updatePeriodFilter(periodId: number): void {
    const currentPeriods = this.filters.periodIds;
    const periodIds = currentPeriods.includes(periodId)
      ? currentPeriods.filter((period) => period !== periodId)
      : currentPeriods.concat(periodId);

    if (periodIds.length) {
      this.filtersUpdated.emit(
        new TeacherRostersFilterOptionsDto({ ...this.filters, periodIds })
      );
    }
  }

  public isPeriodSelected(periodId: number): boolean {
    return this.filters?.periodIds.includes(periodId);
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
