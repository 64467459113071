import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import {
  BehaviorCodeNotificationStatus,
  CommunicationsReportFiltersDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import {
  MatDatepicker,
  MatDatepickerInput
} from '@angular/material/datepicker';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-communications-report-filters',
  templateUrl: './communications-report-filters.template.html',
  styleUrls: ['./communications-report-filters.scss']
})
export class AppCommunicationsReportFilters
  extends BaseComponent
  implements AfterViewInit
{
  @Input()
  public filters: CommunicationsReportFiltersDto;
  @Output()
  private filtersUpdated: EventEmitter<CommunicationsReportFiltersDto> =
    new EventEmitter<CommunicationsReportFiltersDto>();
  @Output()
  private applyFilters: EventEmitter<void> = new EventEmitter();
  @Input()
  public loadingItems: boolean;
  @Input()
  public behaviorCodes: IDisplayData[];

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('statusSelect') statusSelect: ElementRef<MatSelect>;
  @ViewChild('behaviorCodeSelect') behaviorCodeSelect: ElementRef<MatSelect>;
  @ViewChild('startDatePicker') startDatePicker: ElementRef<
    MatDatepicker<MatDatepickerInput<any>>
  >;
  @ViewChild('endDatePicker') endDatePicker: ElementRef<
    MatDatepicker<MatDatepickerInput<any>>
  >;

  public filtersChanged: boolean = false;
  public filtersCleared: boolean = true;

  public statuses = [
    BehaviorCodeNotificationStatus.PENDING,
    BehaviorCodeNotificationStatus.SENT,
    BehaviorCodeNotificationStatus.FAILED
  ];

  public constructor() {
    super();
  }

  public ngAfterViewInit(): void {
    // we start with a default date of the beginning of the current month
    this.startDatePicker['datepickerInput']['value'] = this.filters.startDate;
  }

  public onSearchUpdated(event: Event): void {
    if (!event.target['value']) return;
    this.filters.searchString = event.target['value'];
    this.filtersUpdated.emit(this.filters);
    this.onFiltersChanged();
  }

  public onBehaviorCodeUpdated(event: MatSelectChange): void {
    if (!event.value) return;
    this.filters.behaviorCodeId = event.value;
    this.filtersUpdated.emit(this.filters);
    this.onFiltersChanged();
  }

  public onStatusUpdated(event: MatSelectChange): void {
    if (!event.value) return;
    this.filters.status = event.value;
    this.filtersUpdated.emit(this.filters);
    this.onFiltersChanged();
  }

  public onFiltersChanged(): void {
    this.filtersChanged = true;
    this.filtersCleared = false;
  }

  public onDateTouched(name: 'startDate' | 'endDate'): void {
    // Get the date value
    let date: Date | null = null;

    if (name === 'startDate') {
      date = this.startDatePicker['datepickerInput']['value'];
    } else {
      date = this.endDatePicker['datepickerInput']['value'];
    }

    // Validate and assign the date to the filters
    if (date && !isNaN(new Date(date).getTime())) {
      this.onDateUpdated(name, date);
    } else {
      console.warn(`Invalid date value for ${name}:`, date);
    }
  }

  public onDateUpdated(name: 'startDate' | 'endDate', value: Date): void {
    if (value) {
      const date = new Date(value);
      if (name === 'startDate') {
        // beginning of the day
        date.setHours(0, 0, 0, 0);
      } else {
        // end of the day
        date.setHours(23, 59, 59, 999);
      }
      this.filters[name] = date;
      this.filtersUpdated.emit(this.filters);
      this.onFiltersChanged();
    }
  }

  public onApplyFilters(): void {
    this.applyFilters.emit();
    this.filtersChanged = false;
  }

  public onClearFilters(): void {
    this.searchInput.nativeElement.value = '';
    this.statusSelect['value'] = null;
    this.behaviorCodeSelect['value'] = null;
    // beginning of current month
    const today = new Date();
    const bom = new Date(today.getFullYear(), today.getMonth(), 1);
    bom.setHours(0, 0, 0, 0);
    this.startDatePicker['datepickerInput']['value'] = bom;
    this.endDatePicker['datepickerInput']['value'] = '';

    const newFilters = new CommunicationsReportFiltersDto();
    newFilters.schoolId = this.filters.schoolId;
    newFilters.startDate = bom;
    newFilters.itemsPerPage = this.filters.itemsPerPage;
    newFilters.page = this.filters.page;
    this.filters = newFilters;

    this.filtersUpdated.emit(this.filters);
    // must wait for the filters to be updated before applying them
    this.applyFilters.emit();
    this.filtersChanged = false;
    this.filtersCleared = true;
  }
}
