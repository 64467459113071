"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportTimeConstraint = exports.ExportTime = void 0;
const class_validator_1 = require("class-validator");
const enums_1 = require("../../enums");
function ExportTime(property, frequencyProperty, validationOptions) {
    return (object, propertyName) => {
        (0, class_validator_1.registerDecorator)({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [frequencyProperty, property],
            validator: ExportTimeConstraint
        });
    };
}
exports.ExportTime = ExportTime;
let ExportTimeConstraint = class ExportTimeConstraint {
    validate(value, args) {
        const [frequencyPropertyName, thisFieldName] = args.constraints;
        const frequencyValue = args.object[frequencyPropertyName];
        let valid = true;
        switch (thisFieldName) {
            case 'importTime':
                if ([
                    enums_1.ImportFrequency.Daily,
                    enums_1.ImportFrequency.Weekly,
                    enums_1.ImportFrequency.Monthly
                ].includes(frequencyValue)) {
                    valid = this.validateTime(value);
                }
                break;
            case 'exportTime':
                if ([
                    enums_1.ExportFrequency.Hourly,
                    enums_1.ExportFrequency.Daily,
                    enums_1.ExportFrequency.Weekly,
                    enums_1.ExportFrequency.Monthly
                ].includes(frequencyValue)) {
                    valid = this.validateTime(value);
                }
                break;
            case 'exportEndTime':
                if ([enums_1.ExportFrequency.Hourly].includes(frequencyValue)) {
                    valid = this.validateTime(value);
                }
                break;
            default:
                break;
        }
        return valid; // value;
    }
    validateTime(value) {
        // Regular expression to check if the input is a valid time in HH:mm or HH:mm:ss format
        const isValidFormat = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
        if (!isValidFormat) {
            return false; // Return false if the format is invalid
        }
        // Extract hours and minutes to check if the time is between 22:00 and 23:59
        const [hours, minutes] = value.split(':').map(Number);
        // Check if the time is between 22:00 and 23:59
        // TODO: support timezones, this is a manual offset that does
        // not account for daylight savings or other timezones besides CST
        return !(hours === 3 || (hours === 4 && minutes <= 59));
    }
    defaultMessage(args) {
        const options = args.constraints[1];
        return options.message || 'Time is invalid';
    }
};
exports.ExportTimeConstraint = ExportTimeConstraint;
exports.ExportTimeConstraint = ExportTimeConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: 'ExportTime' })
], ExportTimeConstraint);
