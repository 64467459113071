import { Component, Input, SimpleChanges } from '@angular/core';
import { AppWindowService } from 'src/app/shared/services/app-window.service';
// import { AppIsLoggedInService } from "../../pages/auth/is-logged-in.service";
import { logger } from 'src/app/shared/logger';
// import { AppTranslateService } from "../../util/translate.service";
import { AppSettingsService } from '../services/settings.service';
import { BaseComponent } from '../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { AppSupportButtonsService } from './support-buttons.service';
import { AppSessionHandlerService } from 'src/app/pages/auth/session-handler.service';

/**
 * The Support Buttons Component
 */
@Component({
  selector: 'app-support-buttons',
  templateUrl: './support-buttons.template.html',
  styleUrls: ['./support-buttons.scss']
})
export class AppSupportButtons extends BaseComponent {
  public isLoggedIn: boolean;
  
  @Input() showWidgets: boolean;
  

  public constructor(
    public settingsService: AppSettingsService,
    public window: AppWindowService,
    public supportButtonsService: AppSupportButtonsService,
    public toastManager: AppToastManagerService,
    public sessionHandlerService: AppSessionHandlerService
  ) {
    super();

    if (this.showWidgets) {
      this.initialize();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleToggleShowWidgets(changes.showWidgets.currentValue);
  }

  public initialize() : void {
    this.sessionHandlerService
        .getIsLoggedInObservable()
        .subscribe((isLoggedIn) => {
          this.isLoggedIn = isLoggedIn;
          void this.initSupportButtons(isLoggedIn);
        });
  }

  public handleToggleShowWidgets(show: boolean): void {
    if (show) {
      this.supportButtonsService.showZendesk();
      this.initialize();
    } else {
      this.supportButtonsService.hideZendesk();
    }
  }

  public async initSupportButtons(isLoggedIn: boolean): Promise<void> {
    try {
      if (this.showWidgets) {
        if (isLoggedIn) {
          await Promise.all([
            this.supportButtonsService.initWalkMe(),
            this.supportButtonsService.initZendesk(true)
          ]);
        } else {
          await this.supportButtonsService.initZendesk(false);
        }
      }
    } catch (error) {
      logger.error(error);
      this.toastManager.error(
        'There was an error initializing our support buttons!'
      );
    }
  }
}
