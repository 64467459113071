import { Injectable } from "@angular/core";
import { BaseService } from "../base-classes/base.service";
import { PrinterConfigurationResponseDto, PrinterConfigurationSaveDto } from "@whetstoneeducation/hero-common";
import { AppClientDataService } from "../services/client-data-service/app-client-data.service";
import { Router } from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class PrinterConfigurationService extends BaseService {

  constructor(private appClientDataService: AppClientDataService) {
      super();
    }

  public async getPrinterConfiguration(printerData: PrinterConfigurationSaveDto):
    Promise<PrinterConfigurationResponseDto> {
    return this.appClientDataService.execute<PrinterConfigurationResponseDto>(
      this.POST_ROUTES.PRINTER_CONFIGURATION,
      {
        method: this.METHOD.POST,
        body: printerData,
      }
    );
  }
}
