import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import {
  PrivilegeEnum,
  StudentProfileResponseDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppStudentsService } from '../../students.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { logger } from '../../../../shared/logger';
import { printSingleStudentPassword } from '../../../../shared/printer/browser-print.util';
import { AppPrivilegesService } from '../../../auth/privileges.service';

@Component({
  selector: 'app-student-account',
  templateUrl: './student-account.template.html',
  styleUrls: ['./student-account.scss']
})
export class AppStudentAccountComponent extends BaseComponent {
  @Input()
  public profile: StudentProfileResponseDto;

  constructor(
    private confirmationDialogService: AppConfirmationDialogService,
    private studentService: AppStudentsService,
    private toastService: AppToastManagerService,
    privilegesService: AppPrivilegesService
  ) {
    super({ privilegesService });
  }

  async resetPassword() {
    let newPassword = '';
    try {
      const result = await this.studentService.updateStudentPassword(
        this.profile.userId
      );
      if (result?.newPassword) {
        newPassword = result.newPassword;
        this.toastService.success('Password updated successfully!');
      }
    } catch (error) {
      logger.error(
        'An error occurred when attempting to update your password!',
        error
      );
    } finally {
      if (!newPassword) {
        this.toastService.error(
          'An error occurred when attempting to update your password!'
        );
      } else {
        // display student email and new password in generic confirmation dialog
        // with button that will print the password
        const ref = this.confirmationDialogService.openDialog({
          title: 'Password Reset',
          content: `The new password for ${this.profile.userDetail.email} is ${newPassword}`,
          actions: [
            {
              key: 'print',
              label: 'Print',
              classes: 'standard-button secondary-button'
            }
          ]
        });
        ref.afterClosed().subscribe((result) => {
          if (result === 'print') {
            // open a new window with the password displayed and a print dialog
            printSingleStudentPassword(
              this.profile.userDetail.email,
              newPassword
            );
          }
        });
      }
    }
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
