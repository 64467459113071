import { ReactionEntriesListResponseDto, StudentDetailResponseDto, StudentProfileResponseDto } from "@whetstoneeducation/hero-common";
import { PrintBuilder } from "../../../shared/printer/builder.utils";

export abstract class StudentInfoPrintBuilder extends PrintBuilder<StudentDetailResponseDto> {

  abstract buildStudentName(): StudentInfoPrintBuilder;

  abstract buildStudentIdGrade(): StudentInfoPrintBuilder;

  abstract buildBarcode(): StudentInfoPrintBuilder;

  protected getStudentId(): string {
    return this.data.studentExtId?.length ? this.data.studentExtId : 'N/A';
  }

}
