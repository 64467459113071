import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorCodeEntryResponseDto,
  BehaviorCodeEntryUpdateDto,
  IDisplayData,
  PrivilegeEnum,
  ReactionEntriesUpdateDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../../../shared/services/toast-manager.service';
import { AppBehaviorCodeEntryService } from '../../../../behavior-code-entry/behavior-code-entry.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../../../../../shared/base-classes/base.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IReactionEntryUpdatedPayload } from './models/reaction-entry-updated-payload.interface';
import { AppPrivilegesService } from '../../../../auth/privileges.service';

@Component({
  selector: 'app-behavior-history-edit-modal',
  templateUrl: './behavior-history-edit-modal.component.html',
  styleUrls: ['./behavior-history-edit-modal.component.scss']
})
export class BehaviorHistoryEditModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public behaviorEntry: BehaviorCodeEntryResponseDto;
  public behaviorCodeOptions: IDisplayData[];
  public behaviorCodeReactionOptions: IDisplayData[];
  public behaviorCodeControl: FormControl<number>;
  public notesControl = new FormControl<string>('');
  public behaviorCodeFormSubscription: Subscription;
  public behaviorCodeChanged = false;
  public reactionEntryChanged = false;
  public needsToConfirm = false;
  public reactionEntriesToUpdate: {
    reactionEntryId: number;
    behaviorCodeReactionId: number;
    scheduledReactionId: number | null;
  }[] = [];
  public reactionEntriesLoaded: { [reactionEntryId: number]: boolean } = {};

  public takenScheduledReactionIds: number[] = [];

  constructor(
    public toastService: AppToastManagerService,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    privilegesService: AppPrivilegesService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<BehaviorHistoryEditModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      behaviorCodeEntryId: number;
      onClose?: () => void;
    }
  ) {
    super({ privilegesService });
  }

  public async ngOnInit(): Promise<void> {
    await this.loadData(this.data.behaviorCodeEntryId);
  }

  public ngOnDestroy(): void {
    this.behaviorCodeFormSubscription?.unsubscribe();
  }

  public async loadData(behaviorCodeEntryId: number): Promise<void> {
    this.isLoading = true;
    this.behaviorEntry =
      await this.behaviorCodeEntryService.getBehaviorCodeEntry(
        behaviorCodeEntryId
      );

    if (this.behaviorEntry.reactionEntries.length)
      for (const reactionEntry of this.behaviorEntry.reactionEntries) {
        this.reactionEntriesLoaded[reactionEntry.id] = false;
      }

    this.notesControl = new FormControl<string>(this.behaviorEntry.notes);

    this.behaviorCodeControl = new FormControl<number>(
      this.behaviorEntry.behaviorCodeId
    );
    this.behaviorCodeFormSubscription =
      this.behaviorCodeControl.valueChanges.subscribe(async (value) => {
        this.behaviorCodeChanged = true;
        this.needsToConfirm = false;
        await this.loadBehaviorCodeReactionOptions(value);
      });

    const currentSchoolId =
      this.StorageManager.getLoggedInUser().currentSchoolId;

    const [behaviorCodeOptions, behaviorCodeReactionOptions] =
      await Promise.all([
        this.behaviorCodeEntryService.getBehaviorCodeOptions(currentSchoolId),
        this.behaviorCodeEntryService.getReactionOptions(
          this.behaviorEntry.behaviorCodeId,
          this.behaviorEntry.student.id
        )
      ]);

    this.behaviorCodeOptions = behaviorCodeOptions;
    this.behaviorCodeReactionOptions = behaviorCodeReactionOptions.map(
      (option) => ({
        value: option.id,
        display: option.name
      })
    );

    this.takenScheduledReactionIds = this.behaviorEntry.reactionEntries.reduce(
      (acc, entry) => {
        if (entry.scheduledReaction) {
          acc.push(entry.scheduledReaction.id);
        }
        return acc;
      },
      []
    );
    this.behaviorCodeChanged = false;
    this.reactionEntriesToUpdate = [];

    if (Object.values(this.reactionEntriesLoaded).length === 0) {
      this.isLoading = false;
    }
  }

  public handleReactionEntryEditLoading(
    reactionId: number,
    isLoaded: boolean
  ): void {
    this.reactionEntriesLoaded[reactionId] = isLoaded;
    if (Object.values(this.reactionEntriesLoaded).every((loaded) => loaded)) {
      this.isLoading = false;
    }
  }

  public async loadBehaviorCodeReactionOptions(
    behaviorCodeId: number
  ): Promise<void> {
    this.behaviorCodeReactionOptions = await this.behaviorCodeEntryService
      .getReactionOptions(behaviorCodeId, this.behaviorEntry.student.id)
      .then((options) =>
        options.map((option) => ({
          value: option.id,
          display: option.name
        }))
      );
  }

  public reactionEntryUpdated({
    reactionEntryId,
    behaviorCodeReactionId,
    scheduledReactionId
  }: IReactionEntryUpdatedPayload): void {
    this.reactionEntryChanged = true;
    const index = this.reactionEntriesToUpdate.findIndex(
      (entry) => entry.reactionEntryId === reactionEntryId
    );

    if (index === -1) {
      this.reactionEntriesToUpdate.push({
        reactionEntryId,
        behaviorCodeReactionId,
        scheduledReactionId
      });
    } else {
      this.reactionEntriesToUpdate[index] = {
        reactionEntryId,
        behaviorCodeReactionId,
        scheduledReactionId
      };
    }
  }

  /**
   * If they are confirming the behavior entry then they have already updated and saved the change so we just need to close the popup
   * We already updated the table behind the popup when we saved the behavior code update
   */
  public async confirmBehaviorEntry(): Promise<void> {
    this.dialogRef.close();
  }
  public async submitBehaviorEntry(): Promise<void> {
    this.isLoading = true;
    //the behavior code is changed
    if (this.behaviorCodeChanged) {
      const canEditNotes = this.hasPrivilege(PrivilegeEnum.EDIT_BEHAVIOR_NOTES);
      const updateDto = new BehaviorCodeEntryUpdateDto({
        behaviorCodeId: this.behaviorCodeControl.value,
        schoolId: this.StorageManager.getLoggedInUser().currentSchoolId,
        ...(canEditNotes ? { notes: this.notesControl.value } : {})
      });
      const behaviorEntry =
        await this.behaviorCodeEntryService.updateBehaviorCodeEntry(
          updateDto,
          this.behaviorEntry.id
        );

      await this.loadData(behaviorEntry.id);
      this.reactionEntryChanged = false;
      this.needsToConfirm = true;
      this.toastService.success(
        'Behavior entry updated successfully! Please confirm changes.'
      );
      this.data.onClose?.();
    } else {
      // the reaction on the behavior code was changed
      this.needsToConfirm = false;
      this.reactionEntryChanged = false;
      await Promise.all(
        this.reactionEntriesToUpdate.map(
          ({
            reactionEntryId,
            behaviorCodeReactionId,
            scheduledReactionId
          }) => {
            const dto = new ReactionEntriesUpdateDto({
              behaviorCodeReactionId,
              scheduledReactionId
            });
            return this.behaviorCodeEntryService.updateReactionEntry(
              reactionEntryId,
              dto
            );
          }
        )
      );
      this.toastService.success('Behavior entry updated successfully!');
      this.data.onClose?.();
      this.dialogRef.close();
      this.isLoading = false;
    }
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
