<mat-card  appearance="outlined" class="outer-container-card">
  <mat-card-content
    class="dropzone"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    [class.active]="dragActive">
    <h2 *ngIf="!(fileName || errorMessage)" mat-card-title>Upload File {{ maxMBFileSize ? 'up to ' + maxMBFileSize + ' MB' : ''}}</h2>
    <h2 *ngIf="fileName && !errorMessage" mat-card-title>{{ fileName }} selected</h2>
    <h2 *ngIf="errorMessage" mat-card-title class="error-message">{{ errorMessage }}</h2>
    <p>
      Drag & drop your file to import or click the button below to select one
      from your computer
    </p>
    <input
      type="file"
      id="fileInput"
      (change)="onFileSelected($event)"
      [accept]="allowedExtensions.join(',')"
      #fileInput
      hidden
      aria-label="File Input" />
    <button
      aria-label="Choose File"
      mat-flat-button
      class="standard-button upload-file-button"
      color="primary"
      (click)="fileInput.click()">
      Choose File
    </button>
  </mat-card-content>
</mat-card>
