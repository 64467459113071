import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BellScheduleResponseDto } from '@whetstoneeducation/hero-common';
import { AppBellSchedulesService } from '../bell-schedules.service';

@Injectable({ providedIn: 'root' })
export class AppBellSchedulesCreateEditResolver extends BaseResolver<BellScheduleResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private bellSchedulesService: AppBellSchedulesService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<BellScheduleResponseDto> {
    const id = route.paramMap.get('id');
    if (id === 'new') {
      return new BellScheduleResponseDto();
    } else {
      return this.bellSchedulesService.getBellScheduleById(+id);
    }
  }
}
