import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BaseService } from '../base-classes/base.service';
import { logger } from '../logger';

/**
 * Service used to limit the number of errors we throw in (don't crash our app)
 */
@Injectable({
  providedIn: 'root',
})
export class AppErrorThrottlerService extends BaseService {
  /**
   * Tracks whenever we want to add an error message
   */
  private errorMessageAdded: Subject<string>;

  /**
   * List of error messages that we have already logged
   */
  private alreadyLoggedErrorMessages: string[];

  public constructor() {
    super();
    this.errorMessageAdded = new Subject<string>();
    this.alreadyLoggedErrorMessages = [];
    this.getNewErrorMessages().subscribe((errorMessage) => {
      logger.error(errorMessage);
    });
  }

  /**
   * Returns an observable containing error message that the calling component can then log
   */
  public getNewErrorMessages(): Observable<string> {
    return this.errorMessageAdded.pipe(
      // Skip messages that have already been logged
      filter((message) => !this.alreadyLoggedErrorMessages.includes(message)),
      // Add new keys to the bad key list and pass on the bad key
      tap((message) => this.alreadyLoggedErrorMessages.push(message))
    );
  }

  /**
   * Used to reset our list of already logged error messages. Should be called on route change events.
   */
  public resetAlreadyLoggedErrors(): void {
    this.alreadyLoggedErrorMessages = [];
  }

  /**
   * Add an error message to our list of items to be logged
   */
  public addErrorMessage(message: string) {
    this.errorMessageAdded.next(message);
  }
}
