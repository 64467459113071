"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserBasicResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const enums_1 = require("../../enums");
const class_transformer_1 = require("class-transformer");
const nested_dto_config_1 = require("../../nested-dto-config");
const transform_config_1 = require("../../transform-config");
class UserBasicResponseDto extends mappable_base_1.MappableBase {
    constructor(responseOptions) {
        super();
        if (!responseOptions)
            return;
        this.id = responseOptions.id;
        this.email = responseOptions.email;
        this.firstName = responseOptions.firstName;
        this.lastName = responseOptions.lastName;
        this.type = responseOptions.type;
        this.roles = responseOptions.roles;
        this.instructorCode = responseOptions.instructorCode;
    }
    id;
    email;
    firstName;
    lastName;
    type;
    roles;
    schools;
    schoolGroup;
    active;
    lastLogin;
    instructorCode;
    /** mandatory implementation of superclass abstract
     * @function getFields
     * @returns {string[]} - the array of field names that should be mapped to this dto
     */
    getFields() {
        return [
            'id',
            'email',
            'firstName',
            'lastName',
            'type',
            'roles',
            'active',
            'lastLogin',
            'instructorCode'
        ];
    }
    getNested() {
        return [nested_dto_config_1.NestedDtoConfig.relationConfig('schoolGroup', 'schoolGroup')];
    }
    getTransforms() {
        return [
            {
                destination: 'schools',
                transform: transform_config_1.TransformConfig.transformSchoolsToRelations()
            }
        ];
    }
    /**
     * @public
     * @static
     * @function mapUsers
     * @param entities {MappableBase[]}
     * @returns {UserBasicResponseDto[]} - an array of user dtos with fields mapped from the input user array
     */
    static mapUsers(entities) {
        return mappable_base_1.MappableBase.mapAll(entities, UserBasicResponseDto);
    }
    mapFields(user) {
        super.mapFields(user);
        return this;
    }
}
exports.UserBasicResponseDto = UserBasicResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], UserBasicResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], UserBasicResponseDto.prototype, "email", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], UserBasicResponseDto.prototype, "firstName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], UserBasicResponseDto.prototype, "lastName", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], UserBasicResponseDto.prototype, "type", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], UserBasicResponseDto.prototype, "roles", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], UserBasicResponseDto.prototype, "schools", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], UserBasicResponseDto.prototype, "schoolGroup", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Boolean)
], UserBasicResponseDto.prototype, "active", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Date)
], UserBasicResponseDto.prototype, "lastLogin", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], UserBasicResponseDto.prototype, "instructorCode", void 0);
