"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentOptionsResponseDto = void 0;
const enums_1 = require("../enums");
const mappable_base_1 = require("../mappable.base");
class IncidentOptionsResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return Object.keys(enums_1.DefaultOptionCategoriesIncidentEnum);
    }
    [enums_1.DefaultOptionCategoriesIncidentEnum.BULLYING];
    [enums_1.DefaultOptionCategoriesIncidentEnum.STATE_DISPOSITION];
    [enums_1.DefaultOptionCategoriesIncidentEnum.INCIDENT_ROLES_AND_REFERRALS];
    [enums_1.DefaultOptionCategoriesIncidentEnum.HERO_DISPOSITION];
    [enums_1.DefaultOptionCategoriesIncidentEnum.INCIDENT_PLACES];
    [enums_1.DefaultOptionCategoriesIncidentEnum.MOTIVATION];
    [enums_1.DefaultOptionCategoriesIncidentEnum.OTHERS_INVOLVED];
    [enums_1.DefaultOptionCategoriesIncidentEnum.PREVIOUS_ACTION_TAKEN];
    [enums_1.DefaultOptionCategoriesIncidentEnum.STUDENT_WITH_SPECIAL_NEEDS];
    [enums_1.DefaultOptionCategoriesIncidentEnum.BASIS_OF_BULLYING];
    [enums_1.DefaultOptionCategoriesIncidentEnum.LOCAL_DISPOSITION];
    [enums_1.DefaultOptionCategoriesIncidentEnum.INCIDENT_TYPES];
}
exports.IncidentOptionsResponseDto = IncidentOptionsResponseDto;
