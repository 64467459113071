import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '../directives/directive.module';
import { AppFilterResultsInputComponent } from './filter-results-input/filter-results-input.component';
import { AppMaterialModule } from '../../app-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AppUtcTimeInputComponent } from './utc-time-input.component';

@NgModule({
  declarations: [AppFilterResultsInputComponent, AppUtcTimeInputComponent],
  providers: [],
  exports: [AppFilterResultsInputComponent, AppUtcTimeInputComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    DirectiveModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class AppInputsModule {}
