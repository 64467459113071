"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationOptionsDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
class PaginationOptionsDto extends mappable_base_1.MappableBase {
    constructor(paginationOptions) {
        super();
        this.page = 1;
        this.itemsPerPage = 25;
        if (paginationOptions?.page)
            this.page = paginationOptions.page;
        if (paginationOptions?.itemsPerPage)
            this.itemsPerPage = paginationOptions.itemsPerPage;
        this.lastId = paginationOptions?.lastId ?? 0;
    }
    getFields() {
        return ['page', 'itemsPerPage', 'lastId'];
    }
    page = 1;
    itemsPerPage = 25;
    lastId = 0;
    get skip() {
        const page = this.page || 1;
        const itemsPerPage = this.itemsPerPage || 25;
        return (page - 1) * itemsPerPage;
    }
}
exports.PaginationOptionsDto = PaginationOptionsDto;
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PaginationOptionsDto.prototype, "page", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    (0, class_validator_1.Max)(1000),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PaginationOptionsDto.prototype, "itemsPerPage", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], PaginationOptionsDto.prototype, "lastId", void 0);
