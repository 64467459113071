"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolResponseDto = void 0;
const class_transformer_1 = require("class-transformer");
const mappable_base_1 = require("../../mappable.base");
const transform_config_1 = require("../../transform-config");
class SchoolResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.id = options.id;
        this.currentAcademicYear = options.currentAcademicYear;
        this.lastDeactivationDate = options.lastDeactivationDate;
        this.name = options.name;
        this.referenceId = options.referenceId;
        this.schoolGroupId = options.schoolGroupId;
        this.schoolGroup_name = options.schoolGroup_name;
    }
    getFields() {
        // response objects should include the id field in mapping
        return [
            'id',
            'currentAcademicYear',
            'lastDeactivationDate',
            'name',
            'referenceId',
            'schoolGroupId',
            'schoolGroup_name'
        ];
    }
    mapFields(source) {
        return super.mapFields(source);
    }
    getTransforms() {
        return [
            {
                destination: 'activeTerms',
                transform: transform_config_1.TransformConfig.transformArray('terms', (source) => {
                    return source
                        .filter((term) => term.active)
                        .map((term) => {
                        return term.id;
                    });
                })
            }
        ];
    }
    id;
    currentAcademicYear;
    activeTerms;
    lastDeactivationDate;
    name;
    referenceId;
    schoolGroupId;
    schoolGroup_name;
}
exports.SchoolResponseDto = SchoolResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], SchoolResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], SchoolResponseDto.prototype, "currentAcademicYear", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], SchoolResponseDto.prototype, "activeTerms", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Date)
], SchoolResponseDto.prototype, "lastDeactivationDate", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], SchoolResponseDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], SchoolResponseDto.prototype, "referenceId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], SchoolResponseDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], SchoolResponseDto.prototype, "schoolGroup_name", void 0);
