<div class="profile-photo-container">
  <img [width]="imageWidth" [height]="imageHeight" [ngSrc]="selectedImage ? selectedImage : 'assets/img/ghost-user-icon.png'" tabindex="0" alt="Student Photo" aria-label="Student photo" />
  <button type="button" class="change-photo-button" (click)="fileInput.click()" *ngIf="!hideProfileImageSelector">
    <fa-icon [icon]="['fas', 'camera']"></fa-icon>
  </button>
  <input
    type="file"
    #fileInput
    accept=".jpg,.jpeg,.png"
    (change)="onFileSelected($event)"
    style="display: none;"
  />
</div>
