export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  /**
   * The API Gateway URL that we will use to connect to our backend API.
   */
  public apiGatewayUrl: string;

  public constructor() {
    this.apiGatewayUrl = '';
  }
}
