"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HtmlParser = void 0;
class HtmlParser {
    /**
     * Strip text from HTML. Getting rid of all of the tags/classes/html stuff
     */
    static stripTextFromHTML(input, insertLineBreaks) {
        let output = input;
        // double " according to rfc4180
        const singleQuoteRegexp = new RegExp(/["]/g);
        output = output.replace(singleQuoteRegexp, '""');
        // replace &nbsp; => space
        output = output.replace(/&nbsp;/gi, " ");
        // Remove angular's reflect-html property since it messes up our parsing
        const reflectHtmlRegex = new RegExp(/ng\-reflect\-html\=\"\"((?!\")(.|\n))*\"\"/g);
        output = output.replace(reflectHtmlRegex, "");
        // Remove any html comments
        const htmlCommentsRegexp = new RegExp(/\<!\-\-((?!\-\-\>)(.|\n))*\-\-\>/g);
        output = output.replace(htmlCommentsRegexp, "");
        // Get rid of HTML Tags (<a> <html> and any styling/classes in between)
        const htmlTagsRegexp = new RegExp(/\<((?!\>).)*\>/g);
        const multipleSpacesRegexp = new RegExp(/\s{3,}/g);
        // If trying to pretty display HTML, we replace divs with linebreaks since usually they are on different lines
        if (insertLineBreaks) {
            output = output.replace(htmlTagsRegexp, "<br>");
            output = output.replace(multipleSpacesRegexp, "<br>");
            // Now we get rid of all multiple line breaks and replace them with just one
            const multipleLineBreaksRegexp = new RegExp(/(\s*<br>\s*)+/g);
            output = output.replace(multipleLineBreaksRegexp, "<br>");
            // Don't start with linebreak
            output = output.replace(/^<br>/, "");
        }
        else {
            // Normally, we just replace tags with spaces
            output = output.replace(htmlTagsRegexp, " ");
            output = output.replace(multipleSpacesRegexp, " ");
        }
        // Get rid of pre/post spaces
        return output.trim();
    }
}
exports.HtmlParser = HtmlParser;
