<div class="account-create">
  <h2>
    Create SFTP Account
    <fa-icon
      *ngIf="filtersReady"
      [icon]="['fas', 'sync-alt']"
      (click)="findExistingAccount()"
      title="Refresh"
      class="refresh-icon"></fa-icon>
  </h2>
  <!-- refresh icon -->
  <div class="sftp-account-form-container">
    <form [formGroup]="filterForm" novalidate>
      <!-- form fields for schoolGroupId and schoolId select boxes,
      also a slide toggle for groupLevel, no apply or submit button -->
      <mat-slide-toggle formControlName="groupLevel">
        Group Level
      </mat-slide-toggle>
      <mat-form-field class="full-width-input">
        <mat-label>School Group</mat-label>
        <mat-select formControlName="schoolGroupId">
          <mat-option
            *ngFor="let schoolGroup of schoolGroups"
            [value]="schoolGroup.id">
            {{ schoolGroup.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="!accountFilters.groupLevel && accountFilters.schoolGroupId"
        class="full-width-input">
        <mat-label>School</mat-label>
        <mat-select formControlName="schoolId">
          <mat-option [value]="">None</mat-option>
          <mat-option *ngFor="let school of schools" [value]="school.id">
            {{ school.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="!isLoading && filtersReady && !accountCredentials">
    <div *ngIf="accountExists" title="Create SFTP Account">
      An account for this school or group already exists.
    </div>
    <div *ngIf="!accountExists" title="Create SFTP Account">
      <button
        mat-flat-button
        color="primary"
        class="standard-button create-account-button"
        (click)="createAccount()">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
        Create
      </button>
    </div>
  </div>
  <div *ngIf="accountCredentials?.accountId" class="account-credentials">
    <div class="sftp-host">Host: {{ sftpHost }}</div>
    <div class="sftp-port">Port: {{ sftpPort }}</div>
    <div class="account-id">Account ID: {{ accountCredentials.accountId }}</div>
    <div class="account-password">
      Password: {{ accountCredentials.accountPassword }}
    </div>
    <button
      mat-flat-button
      color="primary"
      class="standard-button"
      (click)="copyCredentials()">
      <fa-icon [icon]="['fas', 'copy']"></fa-icon>
      Copy
    </button>
    <button
      mat-flat-button
      color="primary"
      class="standard-button"
      (click)="downloadCredentials()">
      <fa-icon [icon]="['fas', 'download']"></fa-icon>
      Download
    </button>
    <button
      *ngIf="credentialsSaved"
      mat-flat-button
      color="warn"
      class="standard-button"
      (click)="closeDialog()">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
      Close
    </button>
  </div>
</div>
