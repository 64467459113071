import { DateUtil } from "@whetstoneeducation/hero-common";
import { ZplCommands } from "../../../shared/printer/commands/zpl";
import { StudentInfoPrintBuilder } from "./student-info-print-builder";

export class StudentInfoEzplBuilder extends StudentInfoPrintBuilder {

  constructor() {
    super();
  }

  public startCommand(): string {
    return `
^Q90,3
^W90
^H5
^P1
^S2
^AD
^C1
^R0
~Q+0
^O0
^D0
^E12
~R255
^XSET,ROTATION,0
^L
Dy2-me-dd
Th:m:s
Dy2-me-dd
Th:m:s`;
  }

  public finishCommand(): string {
    return `
E`;
  }

  public buildStudentName(): StudentInfoPrintBuilder {
    this.addCommand(`
AB,49,483,1,1,0,0,First Name: ${this.data.firstName}
AB,352,481,1,1,0,0,Last name: ${this.data.lastName}`);
    return this;
  }

  public buildStudentIdGrade(): StudentInfoPrintBuilder {
    this.addCommand(`
AB,49,462,1,1,0,0,Student ID#: ${this.getStudentId()}
AB,352,458,1,1,0,0,Grade: ${this.data.grade}`);
    return this;
  }

  public buildBarcode(): StudentInfoPrintBuilder {
    const studentExtId = this.data.studentExtId;

    if (studentExtId && studentExtId.length) {
      this.addCommand(`
BQ,281,553,1,3,80,0,0,${studentExtId}`);
    }

    return this;
  }

}
