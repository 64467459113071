<div class="table-container mat-elevation-z2">
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="card-container">
    <div class="student-group-card" *ngFor="let group of studentGroups">
      <div class="student-group-card-content">
        <div class="group-info">
          <h5 class="name-text">{{ group.name }}</h5>
          <h6 class="card-text">Group ID: {{ group.referenceId }}</h6>
          <h6 class="card-text">Students: {{ group.students?.length }}</h6>

          <div class="menu">
            <button mat-button [matMenuTriggerFor]="menu">
              <fa-icon class="clickable" [icon]="['fas', 'ellipsis-vertical']">
              </fa-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                [routerLink]="'/student-groups/' + group.id">
                Manage Group
              </button>
              <button
                mat-menu-item
                (click)="studentGroupRemoved.emit(group)"
                [disabled]="studentGroups.length === 1">
                Delete Group
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
