import { NgModule } from '@angular/core';
import { AppCustomRostersViewComponent } from './custom-rosters-view/custom-rosters-view.component';
import { AppMenuComponent } from '../../shared/menu/menu.component';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { AppStudentCardModule } from '../../shared/student-card/student-card.module';
import { NgForOf, NgIf } from '@angular/common';
import { AppStudentsModule } from '../students/students.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AppCustomRosterManageComponent } from './custom-roster-manage/custom-roster-manage.component';
import { AddStudentsModalComponent } from './custom-roster-manage/add-students-modal/add-students-modal.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppCreateEditCustomRosterModalComponent } from './create-edit-custom-roster-modal/create-edit-custom-roster-modal.component';

@NgModule({
  declarations: [
    AppCustomRostersViewComponent,
    AppCreateEditCustomRosterModalComponent,
    AppCustomRosterManageComponent,
    AddStudentsModalComponent
  ],
  exports: [],
  imports: [
    AppMenuComponent,
    AppPaginationModule,
    AppStudentCardModule,
    NgForOf,
    NgIf,
    AppStudentsModule,
    FontAwesomeModule,
    MatProgressBarModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    DirectiveModule
  ]
})
export class AppCustomRostersModule {}
