import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.template.html',
  styleUrls: ['./teacher-dashboard.scss']
})
export class AppTeacherDashboardComponent extends BaseComponent {
  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    super();
  }

  async ngOnInit() {
    await this.loadData();
  }

  public async loadData() {
    const { noCalendar } = this.route.snapshot.data.data;
    if (noCalendar) {
      await this.router.navigate(['/contact-support']);
    }
  }
}
