import { ITableFilters } from './table-filters.interface';
import { TableFiltersPageKeyEnum } from './table-filters-page-key.enum';

interface ITableFiltersConfig {
  itemsPerPage: number;
  pageSizeOptions?: number[];
  lastId?: number;
  active: string;
  direction: string;
}

const defaultPageFilters: {
  [key in TableFiltersPageKeyEnum]: ITableFiltersConfig;
} = {
  [TableFiltersPageKeyEnum.USERS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.SCHOOLS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BEHAVIOR_CODE_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BEHAVIOR_CODE_REACTIONS]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BEHAVIOR_CODE_INTERVENTIONS]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BEHAVIOR_CODE_NOTIFICATIONS]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BELL_SCHEDULES_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.CALENDAR_DAY_VIEW]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.REACTIONS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.INTERVENTIONS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.SCHEDULED_REACTIONS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Date',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.ROLES_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.SCHOOL_GROUPS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.STUDENTS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100, 200],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.SFTP_ACCOUNTS_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.SFTP_SETTINGS_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.EXPORT_SETTINGS_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc'
  },
  [TableFiltersPageKeyEnum.SFTP_IMPORTS_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.NOTIFICATION_SETTINGS_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.NOTIFICATION_TEMPLATE_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.COMPLIANCE_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.COMPLIANCE_BY_SCHEDULED_REACTION_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BEHAVIOR_CODE_ENTRIES_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.BEHAVIOR_CODE_ACCRUED_REPORT]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.STUDENT_BEHAVIOR_HISTORY_LIST]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Date',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.STUDENT_GROUP_LIST]: {
    itemsPerPage: 25,
    pageSizeOptions: [25, 50, 100],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.SCHOOL_ACTIVITY_REPORT]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.STUDENT_COURSE_SCHEDULE_REPORT]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc'
  },
  [TableFiltersPageKeyEnum.SCHOOLWIDE_POINTS_REPORT]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25, 50],
    active: 'Name',
    direction: 'asc',
    lastId: 0
  },
  [TableFiltersPageKeyEnum.COMMUNICATIONS_REPORT]: {
    itemsPerPage: 10,
    pageSizeOptions: [10, 25],
    active: 'Name',
    direction: 'asc'
  }
};

/**
 * Used for all pagination data needs
 */
export class TableFilterOptions {
  /**
   * Returns the default pagination object for a given page
   * @param page string defining what page you are trying to get options for
   */
  public static getPageDefault(page: TableFiltersPageKeyEnum): ITableFilters {
    return {
      count: 0,
      page: 1,
      ...defaultPageFilters[page]
    };
  }

  public static resetTableFilters(
    page: TableFiltersPageKeyEnum,
    tableFilters: ITableFilters
  ): ITableFilters {
    return {
      ...defaultPageFilters[page],
      itemsPerPage: tableFilters.itemsPerPage,
      count: tableFilters.count,
      page: 1
    };
  }
}
