import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  CalendarDayCreateDto,
  CalendarDayResponseDto,
} from '@whetstoneeducation/hero-common';
import { BaseResolver } from 'src/app/shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from 'src/app/shared/services/resolver-error-handler.service';
import { AppCalendarService } from '../../calendar.service';
import { ICalendarDayCreateEditResolverData } from '../calendar-day-models/calendar-day-create-edit-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppCalendarCreateEditResolver extends BaseResolver<ICalendarDayCreateEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public calendarService: AppCalendarService
  ) {
    super(resolverErrorHandler);
  }
  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<ICalendarDayCreateEditResolverData> {
    const id = route.paramMap.get('id');
    return this.calendarService.getCalendarDayCreateEditResolverData(+id);
  }
}
