"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCreateDto = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const enums_1 = require("../../enums");
const mappable_base_1 = require("../../mappable.base");
const _1 = require(".");
const transform_config_1 = require("../../transform-config");
const boolean_transform_1 = require("../../constants/boolean.transform");
/**
 * @class UserCreateDto
 * @extends MappableBase
 * @description
 * Contains typical user fields plus password and password confirm.
 * Omits ID.
 */
class UserCreateDto extends mappable_base_1.MappableBase {
    constructor(createUserOptions) {
        super();
        if (!createUserOptions)
            return;
        this.firstName = createUserOptions.firstName;
        this.lastName = createUserOptions.lastName;
        this.email = createUserOptions.email;
        this.schoolGroupId = createUserOptions.schoolGroupId;
        this.schools = createUserOptions.schools;
        this.active = createUserOptions.active;
        this.type = createUserOptions.type;
        this.roles = createUserOptions.roles;
        this.instructorCode = createUserOptions.instructorCode;
    }
    email;
    firstName;
    lastName;
    schoolGroupId;
    schools;
    address;
    city;
    state;
    zip;
    phone;
    type;
    active;
    roles = [];
    instructorCode;
    passwordInfo;
    getFields() {
        return [
            'email',
            'active',
            'type',
            'firstName',
            'lastName',
            'address',
            'city',
            'state',
            'zip',
            'phone',
            'schoolGroupId',
            'instructorCode'
        ];
    }
    getTransforms() {
        return [
            {
                destination: 'roles',
                transform: transform_config_1.TransformConfig.transformArray('roles', (source) => {
                    return source.map((role) => {
                        return role.id;
                    });
                })
            },
            {
                destination: 'schools',
                transform: transform_config_1.TransformConfig.mapIdsFromSchools()
            },
            {
                destination: 'schoolGroupId',
                transform: transform_config_1.TransformConfig.mapIdFromSchoolGroup()
            }
        ];
    }
    mapFields(userDetailResponse) {
        super.mapFields(userDetailResponse);
        return this;
    }
    mapStudentFields(studentCreatDto) {
        super.mapFields(studentCreatDto);
        return this;
    }
}
exports.UserCreateDto = UserCreateDto;
__decorate([
    (0, class_validator_1.IsEmail)(undefined, { message: 'A valid email must be provided' }),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], UserCreateDto.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsNotEmpty)({ message: 'First Name is required' }),
    __metadata("design:type", String)
], UserCreateDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsNotEmpty)({ message: 'Last Name is required' }),
    __metadata("design:type", String)
], UserCreateDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], UserCreateDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    __metadata("design:type", Array)
], UserCreateDto.prototype, "schools", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserCreateDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserCreateDto.prototype, "city", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.StateEnum),
    __metadata("design:type", String)
], UserCreateDto.prototype, "state", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserCreateDto.prototype, "zip", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserCreateDto.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.UserTypeEnum),
    __metadata("design:type", String)
], UserCreateDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], UserCreateDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], UserCreateDto.prototype, "roles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserCreateDto.prototype, "instructorCode", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => _1.UserPasswordDto),
    (0, class_validator_1.ValidateNested)({ message: 'password info validation failed' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", _1.UserPasswordDto)
], UserCreateDto.prototype, "passwordInfo", void 0);
