const exportFieldList = [
    {
        header: "School Information", 
        fields: [
            {label: 'School ID', value: 'behaviorCodeEntry.schoolId', enabled: true}
        ]
    },
    {
        header: "Student Information", 
        fields: [
            {label: 'Student ID', value: 'student.id', enabled: true}, 
            {label: 'Student First Name', value: 'student.firstName', enabled: true}, 
            {label: 'Student Last Name', value: 'student.lastName', enabled: true}, 
            {label: 'Student Grade', value: 'student.grade', enabled: true}
        ]
    },
    {
        header: "Student Schedule Info", 
        fields: [
            {label: 'Course Number', value: 'courseSchedule.courseCode', enabled: true}, 
            {label: 'Section', value: 'courseSchedule.section', enabled: true},
            {label: 'Term', value: 'courseSchedule.term', enabled: true},
            {label: 'Instructor First Name', value: 'courseSchedule.instructorFirstName', enabled: true},
            {label: 'Instructor Last Name', value: 'courseSchedule.instructorLastName', enabled: true},
            {label: 'Instructor Code', value: 'courseSchedule.instructorCode', enabled: true},
        ]
    },
    {
        header: "Behavior Info", 
        fields: [
            {label: 'Behavior Code Name', value: 'behaviorCode.code', enabled: true}, 
            {label: 'Behavior Code Description', value: 'behaviorCode.description', enabled: true},
            {label: 'Behavior Reference Code', value: 'behaviorCode.externalReferenceCode', enabled: true},
            {label: 'Behavior Entry Date', value: 'behaviorCodeEntry.time', enabled: true},
            {label: 'Behavior Entry Time', value: 'behaviorCodeEntry.time', enabled: true},
            {label: 'Unique Behavior Entry ID', value: 'behaviorCodeEntry.id', enabled: true},
        ]
    },
    {
        header: "Reaction Info", 
        fields: [
            {label: 'Reaction Name', value: 'reaction.name', enabled: true}, 
            {label: 'Reaction Comply Date', value: 'calendarDay.date', enabled: true},
        ]
    },
    {
        header: "Bell Schedule Info", 
        fields: [
            {label: 'Bell Schedule Name', value: 'bellSchedule.name', enabled: true}, 
            {label: 'Bell Schedule Description', value: 'bellSchedule.description', enabled: true},
            {label: 'Period Name', value: 'bellSchedulePeriod.name', enabled: true},
            {label: 'Period Reference Code', value: 'bellSchedulePeriod.referenceCode', enabled: true},
            {label: 'Period External Code', value: 'bellSchedulePeriod.extPeriodCode', enabled: true},
        ]
    },
    {
        header: "User Info", 
        fields: [
            {label: 'User ID', value: 'user.id', enabled: true}, 
            {label: 'First Name', value: 'user.firstName', enabled: true},
            {label: 'Last Name', value: 'user.lastName', enabled: true},
            {label: 'Instructor Code', value: 'user.instructorCode', enabled: true},
            {label: 'User Code', value: 'user.email', enabled: true},
        ]
    },
  ];

export { exportFieldList };