<div class="calendar-view-filters">
  <!-- Update Button -->
  <div class="form-group mat-elevation-z4 w-100">
    <mat-form-field class="full-width-input">
      <mat-label>Type</mat-label>
      <mat-select [(value)]="filters.showReactions">
        <mat-option
          *ngFor="let type of reactionViewOptions"
          [value]="type.value"
          options-id="codeTypes"
          [option]="constantize(type.display)"
          (onSelectionChange)="filters.showReactions = $event.source.value; updateFilters()">
          {{type.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="filters.showReactions" class="form-group mat-elevation-z4">
    <div class="form-group">
      <label for="ReactionOptions">Reactions</label>
      <app-multiple-select
        id="ReactionOptions"
        allSelectedString="All Reactions"
        [useListStyle]="true"
        [scrollOverflow]="false"
        [useAutocomplete]="false"
        [showListControlButtons]="false"
        [allByDefault]="true"
        [allSelectedEqualsNull]="false"
        [options]="dropdownData.reactionOptions"
        [selected]="filters.reactionIds"
        (selectedUpdated)="filters.reactionIds = $event.map(parseStringIdToNumber); updateFilters()" />
    </div>
  </div>

  <div *ngIf="!filters.showReactions" class="form-group mat-elevation-z4">
    <div class="form-group">
      <label for="BellScheduleOptions">Bell Schedules</label>
      <app-multiple-select
        id="BellScheduleOptions"
        allSelectedString="All Bell Schedules"
        [useListStyle]="true"
        [scrollOverflow]="false"
        [useAutocomplete]="false"
        [showListControlButtons]="false"
        [allByDefault]="true"
        [allSelectedEqualsNull]="false"
        [options]="dropdownData.bellScheduleOptions"
        [selected]="filters.bellScheduleIds"
        (selectedUpdated)="filters.bellScheduleIds = $event.map(parseStringIdToNumber); updateFilters()" />
    </div>
    <div class="form-group">
      <label for="BellScheduleOptions">Student Groups</label>
      <app-multiple-select
        id="StudentGroupsOptions"
        allSelectedString="All Student Groups"
        [useListStyle]="true"
        [scrollOverflow]="false"
        [useAutocomplete]="false"
        [showListControlButtons]="false"
        [allByDefault]="true"
        [allSelectedEqualsNull]="false"
        [options]="dropdownData.studentGroupOptions"
        [selected]="filters.studentGroupIds"
        (selectedUpdated)="filters.studentGroupIds = $event.map(parseStringIdToNumber); updateFilters()" />
    </div>
  </div>
</div>
