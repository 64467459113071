import { ResolveFn } from '@angular/router';
import {
  PaginationResultsDto,
  SchoolActivityReportResponseDto
} from '@whetstoneeducation/hero-common';
import { inject } from '@angular/core';
import { AppReportsService } from '../reports.service';

export const schoolwideReportResolver: ResolveFn<
  PaginationResultsDto<any>
> = async (route, state) => {
  return inject(
    AppReportsService
  ).getSchoolwidePointsReport();
};
