import { AppUserCreateEditComponent } from './user-create-edit.component';
import { AppUserResolver } from './user.resolver';
import { AuthGuard } from '../../route-guards/auth.guard';
import { ICustomRoute } from '../../app-routing.module';
import { AppUsersListComponent } from './users-list/users-list.component';
import { AppUsersListResolver } from './users-list/users-list.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

export const AppUsersRoutes: ICustomRoute[] = [
  {
    path: 'users/:id',
    title: 'User',
    component: AppUserCreateEditComponent,
    resolve: { data: AppUserResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'User',
      description: 'User',
      icon: 'user',
      buttons: [
        {
          // save button
          name: 'Save',
          action: HeaderButtonAction.SAVE,
          style: 'success-button'
        }
      ]
    }
  },
  {
    path: 'users',
    title: 'School Users',
    component: AppUsersListComponent,
    resolve: { data: AppUsersListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'School Users',
      description:
        'All school-based users that exist in your school/group - can be teachers, principals, etc.',
      icon: 'users',
      buttons: [
        {
          name: 'Update Passwords',
          normalizedRoute: HeaderButtonAction.UPDATE,
          icon: 'key',
          style: 'outline-button'
        },
        {
          name: 'Import School Users',
          action: HeaderButtonAction.IMPORT,
          icon: 'user-plus',
          style: 'outline-button'
        },
        {
          name: 'Create School User',
          normalizedRoute: '/users/new',
          icon: 'user-plus',
          style: 'secondary-button'
        }
      ]
    }
  }
];
