import { Injectable } from '@angular/core';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppComplianceService } from '../compliance.service';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import {
  PaginationResultsDto,
  ReactionEntriesListResponseDto,
  ScheduledReactionResponseDto
} from '@whetstoneeducation/hero-common';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';

export interface IComplianceByScheduledReactionListResolverData {
  tableFilters: ITableFilters;
  reactionEntries: PaginationResultsDto<ReactionEntriesListResponseDto>;
  scheduledReaction: ScheduledReactionResponseDto;
}

@Injectable({ providedIn: 'root' })
export class AppComplianceByScheduledReactionListResolver extends BaseResolver<IComplianceByScheduledReactionListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public complianceService: AppComplianceService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const scheduledReactionId = Number(route.params.scheduledReactionId);

    return this.complianceService.getComplianceByScheduledReactionListResolverData(
      scheduledReactionId
    );
  }
}
