import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { CustomRostersResponseDto } from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppCustomRostersService } from '../custom-rosters.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppCustomRosterManageResolver extends BaseResolver<CustomRostersResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public customRosterService: AppCustomRostersService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    return this.customRosterService.getCustomRoster(+id);
  }
}
