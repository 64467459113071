import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../shared/logger';
import { IBehaviorCodesListFilters } from './behavior-codes-list/behavior-codes-list-models/behavior-codes-list-filters.interface';
import { IBehaviorCodeListResolverData } from './behavior-codes-list/behavior-codes-list-models/behavior-codes-list-resolver-data.interface';
import {
  BehaviorCodeCreateDto,
  BehaviorCodeEntryDeactivateByDateDto,
  BehaviorCodeFilterUnpaginatedDto,
  BehaviorCodeOrdinalUpdateDto,
  BehaviorCodeResponseDto,
  BehaviorCodeUpdateDto,
  IDisplayData,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodesCreateEditResolverData } from './behavior-codes-create-edit/behavior-codes-create-edit-resolver-data.interface';
import { plainToInstance } from 'class-transformer';
import { AppBehaviorCodeTypesService } from '../behavior-code-types/behavior-code-types.service';
import { AppBehaviorCodeReactionsService } from '../behavior-code-reactions/behavior-code-reactions.service';
import { AppBehaviorCodeInterventionsService } from '../behavior-code-interventions/behavior-code-interventions.service';
import { AppBehaviorCodeNotificationsService } from '../behavior-code-notifications/behavior-code-notifications.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppBehaviorCodesService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private behaviorCodeTypesService: AppBehaviorCodeTypesService,
    private behaviorCodeReactionsService: AppBehaviorCodeReactionsService,
    private behaviorCodeInterventionsService: AppBehaviorCodeInterventionsService,
    private behaviorCodeNotificationsService: AppBehaviorCodeNotificationsService,
    private toastService: AppToastManagerService,
    public router: Router
  ) {
    super();
  }

  public async getBehaviorCodeById(
    id: number
  ): Promise<BehaviorCodeResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeResponseDto>(
      this.GET_ROUTES.BEHAVIOR_CODE_BY_ID,
      {
        pathParams: { id }
      }
    );
  }

  public async getBehaviorCodeList(
    filters: IBehaviorCodesListFilters
  ): Promise<PaginationResultsDto<BehaviorCodeResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<BehaviorCodeResponseDto>
    >(this.GET_ROUTES.BEHAVIOR_CODES_LIST, {
      queryParams: {
        active: !filters.inactive,
        ...(filters.searchInput ? { search: filters.searchInput } : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {}),
        ...(filters.schoolId ? { schoolId: filters.schoolId } : {})
      }
    });
  }

  public async getBehaviorCodeListUnpaginated(
    filters: BehaviorCodeFilterUnpaginatedDto
  ): Promise<BehaviorCodeResponseDto[]> {
    return this.appClientDataService.execute<BehaviorCodeResponseDto[]>(
      this.GET_ROUTES.BEHAVIOR_CODES_ALL_LIST,
      {
        queryParams: filters
      }
    );
  }

  public getBehaviorCodesListDefaultFilters(): IBehaviorCodesListFilters {
    const user = this.StorageManager.getLoggedInUser();
    return {
      searchInput: null,
      inactive: false,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.BEHAVIOR_CODE_LIST
      ),
      schoolId: user.currentSchoolId
    };
  }

  public async getBehaviorCodesListPageResolverData(): Promise<IBehaviorCodeListResolverData> {
    try {
      const defaultFilters = this.getBehaviorCodesListDefaultFilters();
      if (defaultFilters.schoolId) {
        return {
          defaultFilters,
          behaviorCodes: await this.getBehaviorCodeList(defaultFilters)
        };
      } else {
        return {
          defaultFilters,
          behaviorCodes: {
            results: [],
            options: {
              page: defaultFilters.tableFilters.page,
              itemsPerPage: defaultFilters.tableFilters.itemsPerPage,
              totalItems: 0
            }
          } as any
        };
      }
    } catch (err) {
      logger.error(err.message);
    }
  }

  public async createBehaviorCode(
    behaviorCode: BehaviorCodeCreateDto
  ): Promise<BehaviorCodeResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeResponseDto>(
      this.POST_ROUTES.CREATE_BEHAVIOR_CODE,
      {
        method: this.METHOD.POST,
        body: behaviorCode
      }
    );
  }

  public async updateBehaviorCode(
    behaviorCode: BehaviorCodeUpdateDto,
    id: number
  ): Promise<any> {
    return this.appClientDataService.execute<BehaviorCodeResponseDto>(
      this.PUT_ROUTES.UPDATE_BEHAVIOR_CODE,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: behaviorCode
      }
    );
  }

  public async massUpdateBehaviorCodeOrdinals(
    schoolId: number,
    ordinals: Partial<BehaviorCodeOrdinalUpdateDto>[]
  ) {
    return this.appClientDataService.execute(
      this.PATCH_ROUTES.BEHAVIOR_CODE_ORDINALS_MASS_UPDATE,
      {
        method: this.METHOD.PATCH,
        pathParams: {
          schoolId
        },
        body: ordinals
      }
    );
  }

  public async getReactionsDisplayData(): Promise<{
    [schoolId: number]: IDisplayData[];
  }> {
    return this.appClientDataService.execute<{
      [schoolId: number]: IDisplayData[];
    }>(this.GET_ROUTES.REACTIONS_LIST_DISPLAY_DATA, {
      method: this.METHOD.GET
    });
  }
  public async getBehaviorCodesByUserSchools(
    schoolIds: number[]
  ): Promise<IDisplayData[]> {
    return await this.appClientDataService.execute<IDisplayData[]>(
      this.GET_ROUTES.BEHAVIOR_CODE_BY_SCHOOLS_OPTIONS,
      {
        method: this.METHOD.GET,
        queryParams: {
          schoolIds
        }
      }
    );
  }

  public async getBehaviorCodeCreateEditResolverData(
    id: string
  ): Promise<IBehaviorCodesCreateEditResolverData> {
    const currentUser = this.StorageManager.getLoggedInUser();
    const behaviorCodeTypeOptionsCall =
      this.behaviorCodeTypesService.getBehaviorCodeTypesListDisplayData(
        this.getSchoolGroupIdOfCurrentSchool()
      );
    if (id === 'new') {
      const behaviorCode = plainToInstance(BehaviorCodeResponseDto, {
        active: true,
        code: '',
        description: '',
        passMessage: '',
        externalReferenceCode: '',
        printPass: true,
        fastTrack: true,
        behaviorCodeTypeId: null,
        schoolId: currentUser.currentSchoolId
      });
      const reactionOptionsCall = this.getReactionsDisplayData();

      return {
        behaviorCode,
        behaviorCodeTypeOptions: await behaviorCodeTypeOptionsCall,
        reactionOptions: await reactionOptionsCall
      };
    } else {
      const behaviorCodeCall = this.getBehaviorCodeById(+id);
      const behaviorCodeReactionsCall =
        this.behaviorCodeReactionsService.getBehaviorCodeReactionsList({
          behaviorCodeId: +id,
          tableFilters: TableFilterOptions.getPageDefault(
            TableFiltersPageKeyEnum.BEHAVIOR_CODE_REACTIONS
          ),
          active: true
        });
      const behaviorCodeInterventionsCall =
        this.behaviorCodeInterventionsService.getBehaviorCodeInterventionsList({
          schoolId: currentUser.currentSchoolId,
          behaviorCodeId: +id,
          tableFilters: TableFilterOptions.getPageDefault(
            TableFiltersPageKeyEnum.BEHAVIOR_CODE_INTERVENTIONS
          )
        });
      const behaviorCodeNotificationsCall =
        this.behaviorCodeNotificationsService.getBehaviorCodeNotificationsList({
          schoolId: currentUser.currentSchoolId,
          behaviorCodeId: +id,
          tableFilters: TableFilterOptions.getPageDefault(
            TableFiltersPageKeyEnum.BEHAVIOR_CODE_NOTIFICATIONS
          )
        });

      const results = await Promise.all([
        {
          behaviorCode: await behaviorCodeCall,
          behaviorCodeTypeOptions: await behaviorCodeTypeOptionsCall,
          behaviorCodeReactions: await behaviorCodeReactionsCall,
          behaviorCodeInterventions: await behaviorCodeInterventionsCall,
          behaviorCodeNotifications: await behaviorCodeNotificationsCall
        }
      ]);
      return results[0];
    }
  }

  public async deactivateRecords(
    dto: BehaviorCodeEntryDeactivateByDateDto
  ): Promise<void> {
    return this.appClientDataService.execute<void>(
      this.PATCH_ROUTES.DEACTIVATE_RECORDS,
      {
        method: this.METHOD.PATCH,
        body: dto
      }
    );
  }
}
