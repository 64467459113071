import { Injectable } from '@angular/core';
import { BaseService } from '../base-classes/base.service';
import { StorageManager } from '../storage/storage.manager';
import { AppSettingsService } from './settings.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

/**
 * Service used to get data for the Timezone component
 */
@Injectable({
  providedIn: 'root',
})
export class AppTimezoneService extends BaseService {
  /**
   * Default Constructor
   */
  public constructor(public settingsService: AppSettingsService) {
    super({ settingsService });
  }

  /**
   * Convert a datetime that should be using a timezone to a local copy
   */
  public convertTimezoneDateToLocalDate(date: Date): Date {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timezoneText = this.getTimezoneText();
    if (timezoneText) {
      const localDate = dayjs();
      const timezoneDate = localDate.tz(timezoneText);
      return timezoneDate.toDate();
    } else {
      return new Date(date);
    }
  }

  /**
   * Convert a datetime with our local timezone to a copy in another timezone
   */
  public convertLocalDateToTimezoneDate(date: Date): Date {
    const timezoneText = this.getTimezoneText();
    if (timezoneText) {
      const localDate = dayjs(date);
      const timezoneMoment = dayjs().tz(timezoneText);
      return this.copyDateFields(localDate, timezoneMoment).toDate();
    } else {
      return new Date(date);
    }
  }

  public convertUTCDateToLocalDate(utcDate: Date): Date {
    const timezoneText = this.getTimezoneText();
    if (timezoneText) {
      const utcMoment = dayjs(utcDate).utc(true);
      const localMoment = dayjs();
      return this.copyDateFields(utcMoment, localMoment).toDate();
    } else {
      return new Date(utcDate);
    }
  }

  public getTimezoneMoment(): dayjs.Dayjs {
    const timezoneText = this.getTimezoneText();
    if (timezoneText) {
      return dayjs().tz(timezoneText);
    } else {
      return dayjs();
    }
  }

  /**
   * Copy all of the date attributes from one moment to another
   */
  public copyDateFields(
    copyFromMoment: dayjs.Dayjs,
    copyToMoment: dayjs.Dayjs
  ): dayjs.Dayjs {
    copyToMoment.set('year', copyFromMoment.get('year'));
    copyToMoment.set('month', copyFromMoment.get('month'));
    copyToMoment.set('date', copyFromMoment.get('date'));
    copyToMoment.set('hour', copyFromMoment.get('hour'));
    copyToMoment.set('minute', copyFromMoment.get('minute'));
    copyToMoment.set('second', copyFromMoment.get('second'));
    return copyToMoment;
  }

  public getTimezoneText(): string {
    return StorageManager.getLoggedInUserTimezone();
  }
}
