import {
  BaseCommonTableComponent,
  ITemplateContext
} from '../../../../shared/tables/common-table/base-common-table.component';
import { BellSchedulePeriodResponseDto } from '@whetstoneeducation/hero-common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AppBellSchedulesService } from '../../bell-schedules.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppPageHeaderService } from '../../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../../shared/page-header/header-button';
import { Subject, takeUntil } from 'rxjs';
import { AppBellSchedulePeriodCreateComponent } from './bell-schedule-period-dialog/bell-schedule-period-create.dialog';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

export enum BellSchedulePeriodTableColumns {
  PERIOD_NAME = 'Period Name',
  REF_CODE = 'Reference Code',
  EXT_CODE = 'External Code',
  ACTIONS = 'Actions'
}

interface IActionsColumnContext {
  row: BellSchedulePeriodResponseDto;
}

@Component({
  selector: 'app-bell-schedule-period-manager',
  templateUrl: './bell-schedule-period-manager.template.html',
  styleUrls: ['./bell-schedule-period-manager.scss']
})
export class AppBellSchedulePeriodManagerComponent
  extends BaseCommonTableComponent<
    BellSchedulePeriodResponseDto,
    BellSchedulePeriodTableColumns
  >
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('actionsColumn')
  public actionsColumn: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  public schoolId: number;
  public columns;
  private destroy$ = new Subject<void>();

  public constructor(
    private confirmationDialog: AppConfirmationDialogService,
    private pageHeaderService: AppPageHeaderService,
    private bellSchedulesService: AppBellSchedulesService,
    private toastManager: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog
  ) {
    super();
    this.templates = {};
    this.tableFilters = {
      active: '',
      count: 1,
      direction: '',
      itemsPerPage: 0,
      page: 0
    };
    this.columns = BellSchedulePeriodTableColumns;
  }

  public ngOnInit() {
    this.loadData();
  }

  public ngAfterViewInit() {
    if (this.actionsColumn) {
      this.templates['actionsColumn'] = this.actionsColumn;
    }
    // subscribe to button action events from the page header service
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action) => {
        switch (action) {
          // go to bell schedules list table
          case HeaderButtonAction.BACK:
            this.router.navigate(['/bell-schedules']);
            break;
          case HeaderButtonAction.CREATE:
            // open dialog to create new period
            this.createPeriod();
            break;
          case HeaderButtonAction.CLEAR:
            this.cleanUpPeriods();
            break;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData() {
    this.isLoading = true;
    this.schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    const data = this.route.snapshot.data.data;
    this.dataArr = data.periods.map((period) => {
      return new BellSchedulePeriodResponseDto(period);
    });
    this.isLoading = false;
  }

  public getColumnValue(
    column: string,
    row: BellSchedulePeriodResponseDto
  ): any {
    switch (column) {
      case BellSchedulePeriodTableColumns.PERIOD_NAME:
        return row.name;
      case BellSchedulePeriodTableColumns.REF_CODE:
        return row.referenceCode;
      case BellSchedulePeriodTableColumns.EXT_CODE:
        return row.extPeriodCode;
      case BellSchedulePeriodTableColumns.ACTIONS:
        return {
          context: {
            row
          },
          template: this.templates.actionsColumn
        };
      default:
        return '';
    }
  }

  public createPeriod() {
    // open dialog to create new period
    const dialogRef = this.dialog.open(AppBellSchedulePeriodCreateComponent, {
      width: '600px',
      height: '500px',
      data: {
        period: new BellSchedulePeriodResponseDto({
          name: '',
          referenceCode: '',
          extPeriodCode: '',
          schoolId: this.StorageManager.getCurrentSchoolId()
        })
      },
      disableClose: true
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        // add new period to data array
        if (result && result.period && result.id) {
          // copy dataArr (dataSource) to new array and assign to dataArr
          // sorted by period name to trigger change detection
          this.dataArr = [...this.dataArr, result.period].sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        }
      });
  }

  public editPeriod(row: BellSchedulePeriodResponseDto) {
    // open dialog to edit period time
    const dialogRef = this.dialog.open(AppBellSchedulePeriodCreateComponent, {
      width: '600px',
      height: '500px',
      data: {
        period: row
      },
      disableClose: true
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (!result || !result.id) return;
        // find and update period in data array
        const index = this.dataArr.findIndex(
          (period) => period.id === result.id
        );
        this.dataArr[index].mapFields(result.period);
      });
  }

  public cleanUpPeriods() {
    const ref = this.confirmationDialog.openDialog({
      title: 'Clean up?',
      content:
        'This will permanently remove all periods that are not associated with other records. Type "CLEAN UP" below to confirm, this action cannot be undone.',
      typeToConfirm: 'CLEAN UP',
      width: '600px'
    });
    ref
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result: string) => {
        if (result === DEFAULT_DIALOG_RESULT.YES) {
          const keptPeriods = await this.bellSchedulesService.cleanUpPeriods(
            this.StorageManager.getCurrentSchoolId()
          );
          this.toastManager.success('Periods cleaned up successfully!');
          // remove periods that were deleted from data array
          this.dataArr = this.dataArr.filter((period) =>
            keptPeriods.includes(period.id)
          );
        } else if (result === DEFAULT_DIALOG_RESULT.FAILED_CONFIRMATION) {
          this.toastManager.error(
            'Confirmation failed, periods not cleaned up.'
          );
        }
      });
  }

  public removePeriod(row: BellSchedulePeriodResponseDto) {
    const id = row.id;
    const ref = this.confirmationDialog.openDialog({
      title: 'Delete Period?',
      content: 'If you delete this period, it cannot be restored'
    });
    ref
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result: string) => {
        if (result === DEFAULT_DIALOG_RESULT.YES) {
          await this.bellSchedulesService.deletePeriod(id);
          this.toastManager.success('Period deleted successfully!');
          // remove period from data array
          this.dataArr = this.dataArr.filter((period) => period.id !== id);
        }
      });
  }
}
