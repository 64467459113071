import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { Component } from '@angular/core';
import { AppAuthService } from './auth.service';
import { AppSessionHandlerService } from './session-handler.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { EnvService } from '../../env.service';
import { LoginResponseDto } from '@whetstoneeducation/hero-common';
import { logger } from '../../shared/logger';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.template.html',
  styleUrls: ['./auth.scss']
})
export class AppAuthComponent extends BaseComponent {
  public email: string = '';

  public password: string = '';
  public hidePassword: boolean = true;

  public constructor(
    public authService: AppAuthService,
    public sessionHandlerService: AppSessionHandlerService,
    public router: Router,
    public toastManager: AppToastManagerService,
    public envService: EnvService
  ) {
    super({ router });
    const browserWindowEnvApiUrl = (window as any)?.__env.apiGatewayUrl || {};
    this.googleLoginUrl = `${browserWindowEnvApiUrl}/auth/google`;
    this.azureLoginUrl = `${browserWindowEnvApiUrl}/auth/azure`;
  }

  public readonly googleLoginUrl;
  public readonly azureLoginUrl;

  public async login(): Promise<void> {
    try {
      const session: LoginResponseDto =
        await this.authService.loginWithEmailPassword(
          this.email,
          this.password
        );
      await this.sessionHandlerService.handleLogin({ session });
    } catch (err) {
      this.toastManager.error('Invalid email or password');
      logger.error(err);
    }
  }

  public async resetPassword(): Promise<void> {
    try {
      const result = await this.authService.resetPassword(this.email);
      this.toastManager.success(result.message);
    } catch (err) {
      this.toastManager.error(err.error.message);
      logger.error(err);
    }
  }
}
