<mat-card appearance="outlined">
  <mat-card-header class="button-card-header">
    <div class="header-title-container">
      <h2>Points Rate of Change By Day</h2>
    </div>
  </mat-card-header>
  <mat-card-content #chartCardContent>
    <apx-chart
      aria-label="Student metrics chart"
      tabindex="0"
      class="points-rate-of-change"
      id="pointsRateOfChange"
      [series]="lineGraphOptions.series"
      [chart]="lineGraphOptions.chart"
      [xaxis]="lineGraphOptions.xaxis"
      [dataLabels]="lineGraphOptions.dataLabels"
      [grid]="lineGraphOptions.grid"
      [stroke]="lineGraphOptions.stroke">
    </apx-chart>
    <!-- wip, needs some more planning -->
    <apx-chart
      hidden="hidden"
      [series]="radarChartOptions.series"
      [chart]="radarChartOptions.chart"
      [xaxis]="radarChartOptions.xaxis"
      [title]="radarChartOptions.title">
    </apx-chart>
  </mat-card-content>
</mat-card>
