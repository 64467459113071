"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeacherRostersResponseDto = void 0;
const class_transformer_1 = require("class-transformer");
const mappable_base_1 = require("../../mappable.base");
const teacher_rosters_filter_options_dto_1 = require("./teacher-rosters-filter-options.dto");
class TeacherRostersResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (options)
            Object.assign(this, options);
    }
    getFields() {
        return ['results', 'options'];
    }
    results;
    options;
}
exports.TeacherRostersResponseDto = TeacherRostersResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], TeacherRostersResponseDto.prototype, "results", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", teacher_rosters_filter_options_dto_1.TeacherRostersFilterOptionsDto)
], TeacherRostersResponseDto.prototype, "options", void 0);
