export enum TableFiltersPageKeyEnum {
  USERS_LIST = 'usersList',
  SCHOOLS_LIST = 'schoolsList',
  BEHAVIOR_CODE_LIST = 'behaviorCodeList',
  BEHAVIOR_CODE_REACTIONS = 'behaviorCodeReactions',
  BEHAVIOR_CODE_INTERVENTIONS = 'behaviorCodeInterventions',
  BEHAVIOR_CODE_NOTIFICATIONS = 'behaviorCodeNotifications',
  BELL_SCHEDULES_LIST = 'bellSchedulesList',
  CALENDAR_DAY_VIEW = 'calendarDayView',
  REACTIONS_LIST = 'reactionsList',
  INTERVENTIONS_LIST = 'interventionsList',
  SCHEDULED_REACTIONS_LIST = 'scheduledReactionsList',
  ROLES_LIST = 'rolesList',
  SCHOOL_GROUPS_LIST = 'schoolGroupsList',
  STUDENTS_LIST = 'studentsList',
  SFTP_ACCOUNTS_LIST = 'sftpAccountsList',
  SFTP_SETTINGS_LIST = 'sftpSettingsList',
  SFTP_IMPORTS_LIST = 'sftpImportsList',
  EXPORT_SETTINGS_LIST = 'exportSettingsList',
  STUDENT_BEHAVIOR_HISTORY_LIST = 'studentBehaviorHistoryList',
  BEHAVIOR_ENTRY_DAILY_ACTIVITY_REPORT = 'behaviorEntryDailyActivityReport',
  COMPLIANCE_LIST = 'complianceList',
  COMPLIANCE_BY_SCHEDULED_REACTION_LIST = 'complianceByScheduledReactionList',
  BEHAVIOR_CODE_ENTRIES_LIST = 'behaviorCodeEntriesList',
  BEHAVIOR_CODE_ACCRUED_REPORT = 'behaviorCodeAccruedReport',
  SCHOOLWIDE_POINTS_REPORT = 'schoolwidePointsReport',
  NOTIFICATION_SETTINGS_LIST = 'notificationSettingsList',
  NOTIFICATION_TEMPLATE_LIST = 'notificationTemplateList',
  STUDENT_GROUP_LIST = 'studentGroupsList',
  SCHOOL_ACTIVITY_REPORT = 'schoolActivityReport',
  COMMUNICATIONS_REPORT = 'communicationsReport',
  STUDENT_COURSE_SCHEDULE_REPORT = 'studentCourseScheduleReport'
}
