import { Injectable } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class AppWindowService {
  get nativeWindow(): any {
    return _window();
  }

  /**
   * Allows navigation using the browser window object--NOTE, this shouldn't be used regularly.
   * Only under certain circumstances. Use router navigation instead!
   */
  public navigate(url: string): void {
    setTimeout(() => {
      this.nativeWindow.location.href = url;
    });
  }

  /**
   * Reloads the application using the native browser window.
   */
  public reload(): void {
    this.nativeWindow.location.reload();
  }

  /**
   * Determines whether or not we are on our production website.
   * We should only send events and track things if we are on the production site so our data is kept pure!
   * // TODO: UNCOMMON-REMOVE - 7/22/2021
   */
  public isProduction(): boolean {
    return this.nativeWindow.location.origin.includes('hero.schoolmint');
  }

  public isStaging(): boolean {
    return this.nativeWindow.location.origin.includes('staging.hero');
  }

  public isQA(): boolean {
    return this.nativeWindow.location.origin.includes('qa.hero');
  }

  public isDev(): boolean {
    return this.nativeWindow.location.origin.includes('dev.hero');
  }

  public isLocal(): boolean {
    return this.nativeWindow.location.origin.includes('localhost');
  }

  /**
   * Determines whether or not we are on our E2E testing website.
   * This is used to bypass some validation on the Access Token Expiration Dates so we don't try to refresh unnecessarily using the legacy tokens.
   */
  public isTesting(): boolean {
    return this.nativeWindow.location.origin.includes('test.hero');
  }
}
