import { Directive, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IDisplayData,
  PrivilegeEnum,
  SettingsName,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { AppAuthService } from '../../pages/auth/auth.service';
import { AppPrivilegesService } from '../../pages/auth/privileges.service';
import { AppUrlParamsService } from '../services/url-params.service';
import { logger } from '../logger';
import { StorageManager } from '../storage/storage.manager';

/**
 * Contains the various properties that are available to pass into the constructor of BaseComponent.
 */
export interface IBaseComponentExtras {
  router?: Router;
  route?: ActivatedRoute;
  urlService?: AppUrlParamsService;
  authService?: AppAuthService;
  privilegesService?: AppPrivilegesService;
}

/**
 * The BaseComponent abstracts a few common methods, properties, and other useful utilities out so they can be made readily available
 * on various pages across the application.
 */
@Directive()
export class BaseComponent {
  /**
   * A full path for where the application should redirect in the event of a router navigation.
   */
  private readonly targetUrl: string;

  /**
   * Provides access to StorageManager in all components.
   */
  public StorageManager = StorageManager;

  /**
   * -----------------------------------------------------------
   *          These get injected / set by the constructor
   * -----------------------------------------------------------
   */

  public router: Router;
  public urlService: AppUrlParamsService;
  public route: ActivatedRoute;
  public authService: AppAuthService;
  public privilegeService: AppPrivilegesService;

  /**
   * Whether or not the super component is loading data.
   * This can be used to show/hide some loading indicators in the parent component.
   * DEFAULT: false
   */
  @Input()
  public isLoading: boolean;

  public P = PrivilegeEnum;

  /**
   * Default Constructor
   * @param extras The possible extras that can be passed into the base component upon initialization.
   */
  constructor(extras?: IBaseComponentExtras) {
    // Extras
    if (extras) {
      this.authService = extras.authService;
      this.route = extras.route;
      this.router = extras.router;
      this.urlService = extras.urlService;
      this.privilegeService = extras.privilegesService;

      if (this.urlService) {
        const query = this.urlService.getQueryParams();
        this.targetUrl = query && query['target'];
      }
    }
  }

  /**
   * Handles navigation for the parent component. If a targetUrl exists for this component--we will force navigation to
   * that route--otherwise it will go to the route the user specified.
   *
   * @param commands The path command values to pass to the angular router.
   * @param options various extra navigation options such as query params, etc.
   */
  public navigate(commands: string[], options: any): void {
    // First we need to make sure that the router and URL service were set
    if (this.router && this.urlService) {
      if (this.targetUrl) {
        // We have to make sure protocol is supplied (example: http://www.)
        window.location.href = this.targetUrl;
      } else {
        this.router.navigate(commands, options ? options : {});
      }
    } else {
      throw new Error(
        'Ooops! It looks like you are trying to use target navigation in the base component, but you have not setup the super call in the constructor correctly!'
      );
    }
  }

  /**
   * Returns the fully qualified HREF path for the location we are at.
   */
  public getCurrentHref(): string {
    return window.location.href;
  }

  /**
   * Does the user have this privilege
   * @param privilegeKey The key of the privilege we are checking for (get it from the enum)
   */
  public hasPrivilege(privilegeKey: PrivilegeEnum): boolean {
    if (this.privilegeService) {
      return this.privilegeService.hasPrivilege(privilegeKey);
    } else {
      logger.error('Add privilege service to the super call!', privilegeKey);
      return false;
    }
  }

  public hasSetting(setting: SettingsName): boolean {
    const settings = StorageManager.getLoggedInUser()?.settings;
    return settings && settings[setting];
  }

  public isUserType(types: UserTypeEnum[]): boolean {
    return this.StorageManager.isUserType(types);
  }

  public getLoggedInUserType(): UserTypeEnum {
    return this.StorageManager.getLoggedInUserType();
  }

  public getLoggedInUserTypesAccess(): UserTypeEnum[] | null {
    return this.StorageManager.getLoggedInUserTypesAccess();
  }

  public hasAccessToType(types: UserTypeEnum[]): boolean {
    return this.StorageManager.hasAccessToType(types);
  }

  public getUserTypeDisplayOptions(): IDisplayData[] {
    return Object.values(UserTypeEnum)
      .map((value) => ({
        value,
        display: value
      }))
      .filter((userType) => this.hasAccessToType([userType.value]));
  }

  public downloadFile(response: any, fileName: string) {
    let data: any;
    if (typeof response === 'object') {
      data = JSON.stringify(response, null, 2); // Convert object/array to string with pretty print
    } else {
      data = response; // Use response directly if it's already a string
    }

    const blob = new Blob([data], { type: 'text' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName; // Set the file name
    document.body.appendChild(a); // Append anchor to body
    a.click(); // Programmatically click the anchor to trigger download
    document.body.removeChild(a); // Remove anchor from body
    window.URL.revokeObjectURL(url); // Revoke the object URL to free up memory
  }

  public constantize(str: string): string {
    return str
      .replace(/\s/g, '_')
      .replace(/([A-Z])/g, '$1')
      .toUpperCase();
  }
}
