import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  BehaviorCodeInterventionCreateDto,
  BehaviorCodeInterventionResponseDto,
  BehaviorCodeInterventionUpdateDto,
  PaginationResultsDto,
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodeInterventionsFilters } from './behavior-code-interventions-models/behavior-code-interventions-filters.interface';
import { AppInterventionsService } from '../interventions/interventions.service';
import { plainToInstance } from 'class-transformer';
import { IBehaviorCodeInterventionsCreateEditResolverData } from './behavior-code-interventions-models/behavior-code-interventions-create-edit-resolver-data.interface';

@Injectable({
  providedIn: 'root',
})
export class AppBehaviorCodeInterventionsService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private interventionsService: AppInterventionsService
  ) {
    super();
  }

  public async getBehaviorCodeInterventionsList(
    filters: IBehaviorCodeInterventionsFilters
  ): Promise<PaginationResultsDto<BehaviorCodeInterventionResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<BehaviorCodeInterventionResponseDto>
    >(this.GET_ROUTES.BEHAVIOR_CODE_INTERVENTIONS_LIST, {
      queryParams: {
        ...(filters.searchInput ? { description: filters.searchInput } : {}),
        ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
        ...(filters.interventionId
          ? { interventionId: filters.interventionId }
          : {}),
        ...(filters.behaviorCodeId
          ? { behaviorCodeId: filters.behaviorCodeId }
          : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {}),
      },
    });
  }

  public async getBehaviorCodeInterventionById(
    id: number
  ): Promise<BehaviorCodeInterventionResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeInterventionResponseDto>(
      this.GET_ROUTES.BEHAVIOR_CODE_INTERVENTION_BY_ID,
      {
        pathParams: { id },
      }
    );
  }

  public async getBehaviorCodeInterventionCreateEditResolverData(
    behaviorCodeId: string,
    behaviorCodeInterventionId: string
  ): Promise<IBehaviorCodeInterventionsCreateEditResolverData> {
    const user = this.StorageManager.getLoggedInUser();
    const interventionsCall = this.interventionsService.getInterventionsList({
      searchInput: null,
      schoolId: user.currentSchoolId,
      tableFilters: {
        itemsPerPage: 1000,
        page: 1,
        count: null,
        active: null,
        direction: 'asc',
      },
      inactive: false,
    });

    const behaviorCodeInterventionCall =
      behaviorCodeInterventionId !== 'new'
        ? this.getBehaviorCodeInterventionById(+behaviorCodeInterventionId)
        : null;

    const interventions = await interventionsCall;
    const behaviorCodeIntervention =
      behaviorCodeInterventionId === 'new'
        ? plainToInstance(BehaviorCodeInterventionResponseDto, {
            description: '',
            triggerCount: 0,
            behaviorCodeId: +behaviorCodeId,
            interventionId: null,
            schoolId: user.currentSchoolId,
          })
        : await behaviorCodeInterventionCall;

    const interventionOptions = interventions.results.map((intervention) => {
      return { display: intervention.name, value: intervention.id };
    });

    return {
      interventionOptions,
      behaviorCodeIntervention,
    };
  }

  public async createBehaviorCodeIntervention(
    behaviorCodeIntervention: BehaviorCodeInterventionCreateDto
  ): Promise<BehaviorCodeInterventionResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeInterventionResponseDto>(
      this.POST_ROUTES.CREATE_BEHAVIOR_CODE_INTERVENTION,
      {
        method: this.METHOD.POST,
        body: behaviorCodeIntervention,
      }
    );
  }

  public async updateBehaviorCodeIntervention(
    behaviorCodeIntervention: BehaviorCodeInterventionUpdateDto,
    id: number
  ): Promise<BehaviorCodeInterventionResponseDto> {
    return this.appClientDataService.execute<BehaviorCodeInterventionResponseDto>(
      this.PUT_ROUTES.UPDATE_BEHAVIOR_CODE_INTERVENTION,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: behaviorCodeIntervention,
      }
    );
  }
}
