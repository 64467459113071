import { Injectable } from '@angular/core';
import { IBehaviorCodeEntryConfirmReactionsResolverData } from './behavior-code-entry-confirm-reaction-models/behavior-code-entry-confirm-reactions-resolver-data.interface';
import { AppBehaviorCodeEntryService } from '../behavior-code-entry.service';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppBehaviorCodeEntriesConfirmReactionsResolver extends BaseResolver<IBehaviorCodeEntryConfirmReactionsResolverData> {
  constructor(
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    public resolverErrorHandler: AppResolverErrorHandlerService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IBehaviorCodeEntryConfirmReactionsResolverData> {
    const queryParams = route.queryParams;
    const behaviorCodeEntryIds = queryParams.behaviorCodeEntryIds
      .split(',')
      .map(Number);
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    if (!schoolId) {
      throw new Error('Please select a school to continue');
    }
    return await this.behaviorCodeEntryService.getBehaviorCodeEntryConfirmReactionsResolverData(
      behaviorCodeEntryIds,
      schoolId,
      true
    );
  }
}
