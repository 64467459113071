import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  BaseCommonTableComponent,
  ITemplateContext
} from '../../../shared/tables/common-table/base-common-table.component';
import {
  DateUtil,
  SftpSettingsUpdateDto,
  LastImportsResponseDto,
} from '@whetstoneeducation/hero-common';
import { Router } from '@angular/router';
import { AppSftpService } from '../sftp.service';
import { TitleCasePipe } from '@angular/common';
import { AppConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { MatDialog } from '@angular/material/dialog';

export enum TopImportsColumns {
  IMPORT_NAME = 'Import Name',
  SCHOOL_GROUP = 'School Group',
  SCHOOL = 'School',
  LAST_RUN = 'Last Run',
  SCHEDULE = 'Schedule',
  ACTIVE = 'Active',
  ACTIONS = 'Actions'
}

interface IActionsColumnContext {
  row: LastImportsResponseDto;
}

@Component({
  selector: 'app-last-imports',
  templateUrl: './last-imports.template.html',
  styleUrls: ['./last-imports.scss']
})
export class AppLastImportsComponent implements OnInit, AfterViewInit
{
  @ViewChild('actionsColumn')
  public actionsColumn: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  @ViewChild('activeSlideToggle')
  public activeSlideToggle: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  public columns: typeof TopImportsColumns = TopImportsColumns;
  public templates: { [key: string]: TemplateRef<any> } = {};
  public isLoading: boolean = false;

  dataArr: LastImportsResponseDto[]

  public constructor(
    public router: Router,
    private readonly sftpService: AppSftpService,
    private readonly toastService: AppToastManagerService,
    public dialog: MatDialog,
  ) {
  }

  public async ngOnInit() {
    await this.loadData();
  }

  public ngAfterViewInit(): void {
    if (this.actionsColumn) {
      this.templates['actionsColumn'] = this.actionsColumn;
    }

    if (this.activeSlideToggle) {
      this.templates['activeSlideToggle'] = this.activeSlideToggle;
    }
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    this.dataArr = await this.sftpService.getTopImports();
    this.isLoading = false;
  }

  public clickEditImport(row: LastImportsResponseDto): void {
    this.router.navigate(['sftp', 'settings', row.settingsId]);
  }

  public async clickDeleteImport(row: LastImportsResponseDto): Promise<void> {
    this.dialog.open(AppConfirmationDialogComponent, {
     data: {
      title: 'Are you sure you want to delete this import?',
      content: 'Deleting this import will permanently remove it. This action cannot be undone.',
      actions: [
        {
          key: 'cancel',
          label: 'Cancel',
          color: 'primary',
          classes: 'modal-delete-button'
        },
        {
          key: 'delete',
          label: 'Yes, I am sure',
          color: 'primary',
          classes: 'modal-submit-button',
          awaitAction: true,
          action: async () => {
            await this.sftpService.deleteImportSettings(row.settingsId);
            await this.loadData();
            this.toastService.success('Import deleted');
          }
        }
      ]
     }
    });
  }

  public getColumnValue(
    column: TopImportsColumns,
    row: LastImportsResponseDto
  ): any {
    switch (column) {
      case TopImportsColumns.IMPORT_NAME:
        return row.importName  || 'N/A';
      case TopImportsColumns.SCHOOL_GROUP:
        return row.schoolGroupName;
      case TopImportsColumns.SCHOOL:
        return row.schoolName;
      case TopImportsColumns.LAST_RUN:
        if (!row.lastRunDate) {
          return 'N/A';
        }

        return `${DateUtil.convertTimestampToReadableDate(+row.lastRunDate)} at ${DateUtil.convertTimestampToDisplayTime(+row.lastRunDate)}`;
      case TopImportsColumns.SCHEDULE:
        const titleCasePipe = new TitleCasePipe();
        return titleCasePipe.transform(row.schedule.replace('-', ' '));
      case TopImportsColumns.ACTIONS:
        return {
          context: {
            row
          },
          template: this.templates.actionsColumn
        };
        case TopImportsColumns.ACTIVE:
        return {
          context: {
            row
          },
          template: this.templates.activeSlideToggle
        };
      default:
        return '';
    }
  }

  async changeActive(row: LastImportsResponseDto): Promise<void> {
    const settings = new SftpSettingsUpdateDto();
    settings.id = row.settingsId;
    settings.active = !row.active;
    await this.sftpService.updateSftpSettings(settings);
  }
}
