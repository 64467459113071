import { Component } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { NotificationTemplateResponseDto } from '@whetstoneeducation/hero-common';
import { NotificationTemplateListColumns } from '../template-list-models/notification-template-list-columns.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-template-list-table',
  templateUrl: './notification-template-list-table.template.html',
  styleUrls: ['./notification-template-list-table.scss']
})
export class AppNotificationTemplateListTableComponent extends BaseTableComponent<NotificationTemplateResponseDto> {
  public get displayedColumns() {
    return Object.values(NotificationTemplateListColumns);
  }

  public columnValues = {
    [NotificationTemplateListColumns.NAME]: ['name'],
    [NotificationTemplateListColumns.IS_GROUP_LEVEL]: ['isSchoolGroupLevel'],
    [NotificationTemplateListColumns.SCHOOL_GROUP]: ['schoolGroup_name'],
    [NotificationTemplateListColumns.SCHOOL]: ['school_name'],
    [NotificationTemplateListColumns.BODY_TYPE]: ['bodyType']
  };

  public columns = NotificationTemplateListColumns;

  constructor(readonly router: Router) {
    super();
  }

  public editTemplate(template: NotificationTemplateResponseDto): void {
    this.router.navigate(['notification-templates', template.id]);
  }

  public deleteTemplate(template: NotificationTemplateResponseDto): void {
    console.log('deleteTemplate', template);
  }
}
