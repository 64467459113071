import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppBehaviorCodesService } from '../../../behavior-codes/behavior-codes.service';
import {
  BehaviorCodeEntryDeactivateByDateDto,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { dtoToFormGroup } from '../../../../shared/validation/validation.util';

@Component({
  selector: 'app-deactivate-records-modal',
  templateUrl: './deactivate-records-modal.component.html',
  styleUrls: ['./deactivate-records-modal.component.scss']
})
export class AppDeactivateRecordsModalComponent
  extends BaseComponent
  implements OnInit
{
  public deactivateRecordsForm: FormGroup;
  public confirmFormControl = new FormControl('');
  public schoolId: number;
  public behaviorCodeOptions: IDisplayData[];
  public isLoading = true;
  public confirmed = false;

  constructor(
    public formBuilder: FormBuilder,
    public toastService: AppToastManagerService,
    public dialogRef: MatDialogRef<AppDeactivateRecordsModalComponent>,
    public behaviorCodesService: AppBehaviorCodesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      schoolId: number;
      onClose?: () => void;
    }
  ) {
    super();
    this.schoolId = this.data.schoolId;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    try {
      const behaviorCodes =
        await this.behaviorCodesService.getBehaviorCodeListUnpaginated({
          schoolId: this.schoolId
        });

      this.behaviorCodeOptions = behaviorCodes.map((behaviorCode) => ({
        value: behaviorCode.id,
        display: behaviorCode.code
      }));

      this.behaviorCodeOptions = [
        {
          value: null,
          display: 'All'
        },
        ...this.behaviorCodeOptions
      ];

      const behaviorCodeEntryDeactivateByDateDto =
        new BehaviorCodeEntryDeactivateByDateDto({
          schoolId: this.schoolId,
          behaviorCodeId: 0,
          preserveReactions: false,
          date: Date.now()
        });

      this.deactivateRecordsForm = dtoToFormGroup(
        behaviorCodeEntryDeactivateByDateDto,
        this.formBuilder
      );

      this.deactivateRecordsForm.controls[
        'behaviorCodeId'
      ].valueChanges.subscribe((value) => {
        this.confirmed = false;
      });

      this.confirmFormControl.valueChanges.subscribe((value) => {
        const behaviorCodeId =
          this.deactivateRecordsForm.get('behaviorCodeId').value;

        const date = this.deactivateRecordsForm.get('date').value;

        if (!date) {
          this.confirmed = false;
          return;
        }

        if (value && behaviorCodeId) {
          const behaviorCodeName = this.behaviorCodeOptions.find(
            (option) => option.value === behaviorCodeId
          ).display;

          if (value === behaviorCodeName) {
            this.confirmed = true;
          }
        } else if (value && !behaviorCodeId) {
          this.confirmed = value === 'DEACTIVATE ALL';
        } else {
          this.confirmed = false;
        }
      });
    } catch (error) {
      this.toastService.error('Error loading data');
      this.dialogRef.close();
    }
    this.isLoading = false;
  }

  public getSelectedBehaviorCodeConfirmPhrase(): string {
    const behaviorCodeId =
      this.deactivateRecordsForm.get('behaviorCodeId')?.value;

    return !!behaviorCodeId
      ? this.behaviorCodeOptions.find(
          (option) => option.value === behaviorCodeId
        ).display
      : 'DEACTIVATE ALL';
  }

  public async submit(): Promise<void> {
    this.isLoading = true;
    try {
      if (!this.confirmed) {
        this.toastService.error('Please confirm deactivation');
        this.isLoading = false;
        return;
      }
      const behaviorCodeEntryDeactivateByDateDto =
        new BehaviorCodeEntryDeactivateByDateDto({
          schoolId: this.schoolId,
          ...(!!this.deactivateRecordsForm.get('behaviorCodeId').value
            ? {
                behaviorCodeId:
                  this.deactivateRecordsForm.get('behaviorCodeId').value
              }
            : {}),
          date: this.deactivateRecordsForm.get('date').value.getTime(),
          preserveReactions:
            this.deactivateRecordsForm.get('preserveReactions').value
        });

      await this.behaviorCodesService.deactivateRecords(
        behaviorCodeEntryDeactivateByDateDto
      );

      this.toastService.success('Records Deactivated');
      this.dialogRef.close();
    } catch (error) {
      this.toastService.error('Error deactivating records');
    }
    this.isLoading = false;
  }
}
