import { Component, Inject, Input } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import {
  BellScheduleResponseDto,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../../shared/logger';
import { AppBellSchedulesService } from '../../../bell-schedules/bell-schedules.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-student-group-add-bell-schedule-pop-up',
  templateUrl: './student-group-add-bell-schedule-pop-up.template.html',
  styleUrls: ['./student-group-add-bell-schedule-pop-up.scss']
})
export class AppStudentGroupAddBellSchedulePopUpComponent extends BaseComponent {
  public bellSchedules: BellScheduleResponseDto[];
  public tableFilters: ITableFilters;
  public schoolId: number;
  public groupName: string;
  public searchInput: string;
  public selectedBellSchedules: BellScheduleResponseDto[];

  constructor(
    public dialogRef: MatDialogRef<AppStudentGroupAddBellSchedulePopUpComponent>,
    public bellSchedulesService: AppBellSchedulesService,
    public toastService: AppToastManagerService,
    @Inject(MAT_DIALOG_DATA)
    public props: {
      bellSchedules: PaginationResultsDto<BellScheduleResponseDto>;
      selectedBellSchedules: BellScheduleResponseDto[];
      groupName?: string;
    }
  ) {
    super();
    this.bellSchedules = props.bellSchedules.results;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.BELL_SCHEDULES_LIST
    );
    this.tableFilters.count = this.props.bellSchedules.options.totalItems;
    this.schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    this.groupName = this.props.groupName;
    this.selectedBellSchedules = this.props.selectedBellSchedules;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.tableFilters = newTableFilters;
    await this.updateBellSchedulesList();
    this.isLoading = false;
  }

  public async updateSearchFilter(searchInput: string): Promise<void> {
    this.isLoading = true;
    this.searchInput = searchInput;
    await this.updateBellSchedulesList();
    this.isLoading = false;
  }

  public async updateBellSchedulesList(): Promise<void> {
    try {
      const newData = await this.bellSchedulesService.getBellSchedulesList(
        this.schoolId,
        this.tableFilters,
        this.searchInput
      );
      this.bellSchedules = newData.results;
      this.tableFilters.count = newData.options?.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving bell schedules!');
      logger.error(err);
    }
  }
}
