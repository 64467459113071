<form [formGroup]="calendarDayForm" novalidate>
  <div class="twoColumnLayout calendar-day-content">
    <div class="column">
      <mat-card appearance="outlined" class="calendar-day-form mat-elevation-z3">
        <mat-card-header class="calendar-day-form-header card-header">
          <mat-card-title>
            Calendar Day Info
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="calendar-day-form-content">
          <div class="form-row">
            <mat-form-field appearance="fill" wh>
              <mat-label>Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                formControlName="date"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <app-error-display
                [form]="calendarDayForm"
                controlName="date"
              ></app-error-display>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="half-width-input">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
              <app-error-display
                [form]="calendarDayForm"
                controlName="name"
              ></app-error-display>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="half-width-input">
              <mat-label>Notes</mat-label>
              <input matInput formControlName="notes" />
              <app-error-display
                [form]="calendarDayForm"
                controlName="notes"
              ></app-error-display>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
