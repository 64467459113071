"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentCreateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
const enums_1 = require("../../enums");
const boolean_transform_1 = require("../../constants/boolean.transform");
const class_transformer_1 = require("class-transformer");
const class_transformer_2 = require("class-transformer");
class StudentCreateDto extends mappable_base_1.MappableBase {
    constructor(createStudentOptions) {
        super();
        if (!createStudentOptions)
            return;
        this.firstName = createStudentOptions.firstName;
        this.lastName = createStudentOptions.lastName;
        this.grade = createStudentOptions.grade;
        this.schoolId = createStudentOptions.schoolId;
        this.middleInitial = createStudentOptions.middleInitial;
        this.referenceId = createStudentOptions.referenceId;
        this.homeroom = createStudentOptions.homeroom;
        this.address = createStudentOptions.address;
        this.city = createStudentOptions.city;
        this.state = createStudentOptions.state;
        this.zip = createStudentOptions.zip;
        this.gender = createStudentOptions.gender;
        this.ethnicGroup = createStudentOptions.ethnicGroup;
        this.studentLanguage = createStudentOptions.studentLanguage;
        this.teacher = createStudentOptions.teacher;
        this.homePhone = createStudentOptions.homePhone;
        this.workPhone = createStudentOptions.workPhone;
        this.contactPhone = createStudentOptions.contactPhone;
        this.guardian = createStudentOptions.guardian;
        this.guardianEmail = createStudentOptions.guardianEmail;
        this.emergencyContact = createStudentOptions.emergencyContact;
        this.notes = createStudentOptions.notes;
        this.studentExtId = createStudentOptions.studentExtId;
        this.flagMessage = createStudentOptions.flagMessage;
        this.sendLettersViaEmail = createStudentOptions.sendLettersViaEmail;
        this.email = createStudentOptions.email;
        this.studentGroupId = createStudentOptions.studentGroupId;
    }
    getFields() {
        return [
            'firstName',
            'lastName',
            'grade',
            'schoolId',
            'middleInitial',
            'referenceId',
            'homeroom',
            'address',
            'city',
            'state',
            'zip',
            'gender',
            'ethnicGroup',
            'studentLanguage',
            'teacher',
            'homePhone',
            'workPhone',
            'contactPhone',
            'guardian',
            'guardianEmail',
            'emergencyContact',
            'notes',
            'studentExtId',
            'flagMessage',
            'sendLettersViaEmail',
            'email',
            'studentGroupId'
        ];
    }
    firstName;
    lastName;
    middleInitial;
    birthDate;
    referenceId;
    homeroom;
    grade;
    schoolId;
    address;
    city;
    state;
    zip;
    homePhone;
    workPhone;
    contactPhone;
    gender;
    ethnicGroup;
    studentLanguage;
    teacher;
    guardian;
    guardianEmail;
    emergencyContact;
    notes;
    studentExtId;
    flagMessage;
    sendLettersViaEmail;
    email;
    studentGroupId;
}
exports.StudentCreateDto = StudentCreateDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "lastName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "middleInitial", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "birthDate", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "referenceId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "homeroom", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "grade", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], StudentCreateDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "city", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.StateEnum),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "state", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "zip", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "homePhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "workPhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "contactPhone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "gender", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "ethnicGroup", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "studentLanguage", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "teacher", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "guardian", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "guardianEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "emergencyContact", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "notes", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "studentExtId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "flagMessage", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], StudentCreateDto.prototype, "sendLettersViaEmail", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], StudentCreateDto.prototype, "email", void 0);
__decorate([
    (0, class_transformer_2.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.Min)(1),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], StudentCreateDto.prototype, "studentGroupId", void 0);
