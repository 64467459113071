import { ZplCommands } from "./commands/zpl";

export class PrintBuilder<TData> {
  private commands: string[] = [];
  protected data?: TData = null;
  protected encoder: any = null;

  constructor(languageType?: string) {
    this.addCommand(this.startCommand());
    this.setEncoder(languageType);
    this.initializeEncoder();
  }

  public getData(): TData {
    return this.data;
  }

  public get useEncoder(): boolean {
    return !!this.encoder;
  }

  public setData(data: TData): PrintBuilder<TData> {
    this.data = data;
    return this;
  }

  public addCommand(commandResult: string): PrintBuilder<TData> {
    this.commands.push(commandResult);
    return this;
  }

  public stringify(): string {
    return this.commands.join('');
  }

  protected setEncoder(languageType?: string): void {
  }

  public finishCommand(): string {
    return '';
  }

  public startCommand(): string {
    return '';
  }

  public initializeEncoder(): void {}

  public getCommandsResult(): string {
    return this.addCommand(this.finishCommand()).stringify();
  }

  public getEncoderResult(): Uint8Array {
    return this.encoder?.encode();
  }
}


