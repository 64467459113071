"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactionEntriesResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class ReactionEntriesResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['id', 'reaction', 'behaviorCodeReactionId', 'scheduledReaction'];
    }
    id;
    reaction;
    behaviorCodeReactionId;
    scheduledReaction;
}
exports.ReactionEntriesResponseDto = ReactionEntriesResponseDto;
