import { Injectable } from '@angular/core';
import { PrivilegeEnum, ValidationUtil } from '@whetstoneeducation/hero-common';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { BaseService } from '../../shared/base-classes/base.service';

@Injectable({
  providedIn: 'root'
})
export class AppPrivilegesService extends BaseService {
  public constructor(public clientDataService: AppClientDataService) {
    super();
  }

  /**
   * Does our user have a specific privilege key
   * @param privilege a specific privilege to check for
   */
  public hasPrivilege(privilege: PrivilegeEnum): boolean {
    const currentUser = this.StorageManager.getLoggedInUser();
    if (!currentUser) return false;
    return ValidationUtil.hasPrivilege(
      privilege,
      currentUser.privileges,
      currentUser.currentSchoolId
    );
  }
}
