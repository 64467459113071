"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactionEditDaysDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class ReactionEditDaysDto extends mappable_base_1.MappableBase {
    days;
    mode;
    constructor(options) {
        super();
        this.days = options?.days || [];
        this.mode = options?.mode || 'add';
    }
    getFields() {
        return ['days', 'mode'];
    }
}
exports.ReactionEditDaysDto = ReactionEditDaysDto;
