import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { AppMaterialModule } from "../../app-material.module";
import { AppPipesModule } from "../pipes/pipes.module";
import { AppAuthModule } from "../../pages/auth/auth.module";
import { AppErrorBoundaryComponent } from "./error-boundary.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    FormsModule,
    AppPipesModule,
    AppAuthModule,
  ],
  declarations: [AppErrorBoundaryComponent],
  exports: [AppErrorBoundaryComponent],
})
export class AppErrorBoundaryModule {}
