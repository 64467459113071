"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudentProfileResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const student_detail_response_dto_1 = require("./student-detail-response.dto");
const users_1 = require("../users");
class StudentProfileResponseDto extends mappable_base_1.MappableBase {
    studentDetail;
    userDetail;
    profileData;
    userId;
    constructor(responseOptions) {
        super();
        if (responseOptions)
            Object.assign(this, responseOptions);
    }
}
exports.StudentProfileResponseDto = StudentProfileResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => student_detail_response_dto_1.StudentDetailResponseDto),
    __metadata("design:type", student_detail_response_dto_1.StudentDetailResponseDto)
], StudentProfileResponseDto.prototype, "studentDetail", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => users_1.UserDetailResponseDto),
    __metadata("design:type", users_1.UserDetailResponseDto)
], StudentProfileResponseDto.prototype, "userDetail", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], StudentProfileResponseDto.prototype, "profileData", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Number)
], StudentProfileResponseDto.prototype, "userId", void 0);
