<mat-card
  class="student-card"
  [class.selected]="studentSelected">
  <div
    class="student-card-content"
    (click)="toggleStudentSelection(student.id)">
    <div class="student-img">
      <img
        width="50"
        height="50"
        [ngSrc]="selectedImage"
        alt="Student Photo" />
    </div>
    <div class="student-info">
      <h5 class="name-text">
        {{ student.firstName }} {{ student.lastName }}
      </h5>
      <h6 class="card-text">ID: {{ student.studentExtId }}</h6>
      <span class="card-text grade-span">Grade: {{ student.grade }}</span>
      <!-- vertical separator -->
      <span
        [ngClass]="{'points-positive': student.points >= 0, 'points-negative': student.points < 0}"
        >{{student.points ?? 0}} pts</span
      >
    </div>
    <div class="view-student-profile">
      <img
        (click)="$event.stopPropagation(); viewStudentProfile(student.id)"
        width="22"
        height="22"
        ngSrc="assets/img/open_in_new.svg"
        alt="view student profile" />
    </div>
  </div>
</mat-card>
