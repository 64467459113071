"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsValidTimestampConstraint = exports.IsValidTimestamp = void 0;
const class_validator_1 = require("class-validator");
const util_1 = require("../../util");
function IsValidTimestamp(validationOptions) {
    return (object, propertyName) => {
        (0, class_validator_1.registerDecorator)({
            target: object.constructor,
            propertyName,
            options: {
                message: `Timestamp must be between (July 10, 2020) and (July 10, 2025)`,
                ...validationOptions
            },
            validator: IsValidTimestampConstraint
        });
    };
}
exports.IsValidTimestamp = IsValidTimestamp;
let IsValidTimestampConstraint = class IsValidTimestampConstraint {
    validate(value) {
        return (typeof value === 'number' &&
            value >= util_1.DateUtil.getEarliestPossibleDate() &&
            value <= util_1.DateUtil.getLatestPossibleDate());
    }
};
exports.IsValidTimestampConstraint = IsValidTimestampConstraint;
exports.IsValidTimestampConstraint = IsValidTimestampConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: 'IsValidTimestamp' })
], IsValidTimestampConstraint);
