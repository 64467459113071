<div class="container">
  <app-school-activity-report-filters
    [loadingItems]="isLoading"
    [filters]="filters"
    (filtersUpdated)="onFiltersUpdated($event)"
    (applyFilters)="applyFilters()">
  </app-school-activity-report-filters>
  <app-school-activity-report-summary-card
    [totalBehaviorsCount]="totalBehaviorsCount"
    [totalReferrals]="totalReferrals"
    [totalPoints]="totalPoints">
  </app-school-activity-report-summary-card>
  <app-school-activity-report-actions
    (filtersUpdated)="onInstantFiltersUpdated($event)"
    [filters]="filters">
  </app-school-activity-report-actions>
  <app-base-common-table-portal [context]="this"></app-base-common-table-portal>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <span *ngIf="!(dataArr.length || isLoading)">
    There are no records to show
  </span>
</div>
