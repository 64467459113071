import { Component } from '@angular/core';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute } from '@angular/router';
import { AppStudentsService } from '../students.service';
import { IStudentsListFilters } from '../students-list-models/students-list-filters.interface';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../shared/logger';
import { MatDialog } from '@angular/material/dialog';
import { IStudentListResolverData } from '../students-list-models/student-list-resolver-data.interface';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { printBulkStudentPasswords } from '../../../shared/printer/browser-print.util';
import { TrackableStudentList } from '../../../shared/trackable-student-list/trackable-student-list.component';
import { AppPrivilegesService } from '../../auth/privileges.service';

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.template.html',
  styleUrls: ['./students-list.scss']
})
export class AppStudentsListComponent extends TrackableStudentList {
  public filters: IStudentsListFilters;
  public selectedStudentsIds: number[] = [];
  public hasSelectedSchool: boolean;

  constructor(
    public toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public studentsService: AppStudentsService,
    public dialog: MatDialog,
    public confirmationDialogService: AppConfirmationDialogService,
    public privilegesService: AppPrivilegesService
  ) {
    super(
      dialog,
      confirmationDialogService,
      toastService,
      studentsService,
      privilegesService
    );
    this.loadData();
  }

  public loadData(): void {
    const { students, validCalendar } = this.route.snapshot.data
      .data as IStudentListResolverData;
    this.students = students.results;

    this.filters = this.studentsService.getStudentsListDefaultFilters({
      ...this.route.snapshot.queryParams
    });
    this.hasSelectedSchool = !!this.filters.schoolId;
    this.filters.tableFilters.count = students.options.totalItems;
    this.filters.tableFilters.pageLastIds = {
      [students.options.page + 1]: students.options.lastId
    };
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    const pageLastIds = this.filters.tableFilters.pageLastIds;
    const page = this.filters.tableFilters.page;
    this.filters.tableFilters.lastId = pageLastIds ? pageLastIds[page] || 0 : 0;
    await this.updateStudentsList(this.filters);
    this.isLoading = false;
  }

  public async updateFilters(newFilters: IStudentsListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.STUDENTS_LIST,
      this.filters.tableFilters
    );
    await this.updateStudentsList(this.filters);
    this.isLoading = false;
  }

  public async updateStudentsList(
    filters: IStudentsListFilters
  ): Promise<void> {
    try {
      const newData = await this.studentsService.getStudentsList(filters);
      this.students = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
      if (this.filters.tableFilters.pageLastIds) {
        this.filters.tableFilters.pageLastIds[newData.options.page + 1] =
          newData.options.lastId;
      }
    } catch (err) {
      this.toastService.error('Error retrieving students!');
      logger.error(err);
    }
  }

  public async showAllPasswordResetModal(): Promise<void> {
    const message = `Are you sure you want to reset the passwords for all ${this.filters.tableFilters.count} students?
    Only users with associated user accounts will have their passwords reset.`;
    const ref = this.confirmationDialogService.openDialog({
      title: 'Reset All Passwords',
      content: message,
      actions: [
        {
          key: 'print-combined',
          label: 'Paper Saver',
          classes: 'standard-button outline-button'
        },
        {
          key: 'print-individual',
          label: 'Print On Separate Pages',
          classes: 'standard-button outline-button'
        },
        {
          key: 'no',
          label: 'No',
          classes: 'standard-button secondary-button'
        }
      ],
      width: '600px',
      height: '260px'
    });
    ref.afterClosed().subscribe(async (result) => {
      if (!result || result === 'no') {
        return;
      }
      const onePerPage = result === 'print-individual';
      this.isLoading = true;
      try {
        this.toastService.success('Password reset initiated!');
        const result = await this.studentsService.updateAllStudentsPasswords(
          this.filters
        );

        const emailPasswordMap = new Map<string, string>();
        // iterate the result keys and set the email and password to the map
        Object.keys(result.newPasswordData).forEach((key) => {
          emailPasswordMap.set(key, result.newPasswordData[key]);
        });
        printBulkStudentPasswords(emailPasswordMap, {
          onePerPage
        });
        this.toastService.success('Passwords reset successfully!');
      } catch (error) {
        if (error.message == 'popups likely blocked') {
          this.toastService.error(
            'Error resetting passwords! Please disable popups and try again.'
          );
        } else {
          this.toastService.error('Error resetting passwords!');
        }
        logger.error(error);
      } finally {
        this.isLoading = false;
      }
    });
  }
}
