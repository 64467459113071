<div class="content">
  <app-schools-list-filters
    [filters]="filters"
    (filtersUpdated)="updateFilters($event)"></app-schools-list-filters>
  <app-schools-list-table
    [dataArr]="schools"
    [isLoading]="isLoading"
    [tableFilters]="filters.tableFilters"
    (tableFiltersUpdated)="updateTableFilters($event)"></app-schools-list-table>
</div>
