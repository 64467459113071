<div class="singleColumnLayout student-group-create-edit-content">
  <mat-card appearance="outlined" class="student-group-form mat-elevation-z3">
    <mat-card-header>
      <mat-card-title class="card-title"> GENERAL INFORMATION </mat-card-title>
    </mat-card-header>
    <mat-card-content class="student-group-form-content">
      <form [formGroup]="studentGroupForm" novalidate>
        <div class="form-row">
          <mat-form-field class="full-width-input">
            <mat-label>Group Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </div>

        <div class="form-row">
          <mat-form-field class="full-width-input">
            <mat-label>Group ID</mat-label>
            <input matInput formControlName="referenceId" />
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div class="singleColumnLayout">
  <mat-form-field class="filter-item input">
    <input
      class="student-group-search-input"
      [placeholder]="'Search Students'"
      data-test-id="GROUP_STUDENT_FILTER_INPUT"
      matInput
      (input)="searchStudents($event.target.value)" />
    <fa-icon
      class="search-icon"
      matSuffix
      [icon]="['fas', 'magnifying-glass']"></fa-icon>
  </mat-form-field>
  <mat-card
    appearance="outlined"
    class="student-group-students mat-elevation-z3">
    <mat-card-header>
      <mat-card-title class="card-title">
        {{selectedStudents?.length || 0 }} {{selectedStudents?.length === 1 ?
        'STUDENT' : ' STUDENTS' }} IN THIS STUDENT GROUP
      </mat-card-title>
      <div class="card-header-buttons">
        <fa-icon
          [icon]="['fas', 'square-plus']"
          size="2x"
          class="clickable"
          (click)="addStudents()"></fa-icon>
      </div>
    </mat-card-header>
    <mat-card-content class="student-group-students-content">
      <app-student-group-student-list
        [dataArr]="selectedStudents"
        [showTableHeader]="false"
        (studentRemoved)="removeStudent($event)"></app-student-group-student-list>
    </mat-card-content>
  </mat-card>
</div>
