import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DEFAULT_DIALOG_RESULT,
  IConfirmationDialogActionItem,
  IConfirmationDialogOptions
} from './confirmation-dialog-options.interface';
import { logger } from '../logger';

/**
 * This dialog is a generic confirmation popup that will display a message and give
 * the user options to take according to the information given.
 */
@Component({
  selector: 'app-confirmation-dialog',
  styleUrls: ['confirmation-dialog.scss'],
  templateUrl: 'confirmation-dialog.template.html'
})
export class AppConfirmationDialogComponent {
  /**
   * The title of the dialog.
   */
  public title: string;

  /**
   * The content of the dialog.
   */
  public content: string;

  /**
   * The action buttons that we want to display on the dialog.
   */
  public actions: IConfirmationDialogActionItem[];

  /**
   * If set to a message the user will have to type that message to confirm the action.
   */
  public typeToConfirm?: string;
  public typedValue = '';

  /**
   * Default Constructor
   * @param dialogRef Reference to the material dialog.
   * @param data The data that gets passed into/out of the dialog.
   */
  constructor(
    public dialogRef: MatDialogRef<AppConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogOptions
  ) {
    if (this.areOptionsValid(data)) {
      this.title = data.title;
      this.content = data.content;
      this.actions = data.actions;
      this.typeToConfirm = data.typeToConfirm;

      if (!this.actions) {
        // If no user-defined actions are passed, then we need to build the default actions.
        this.actions = this.buildDefaultActions();
      }
    } else {
      this.dialogRef.close(null);
    }
  }

  /**
   * Updates the typed value when the user types in the confirmation box.
   */
  public async onTypeToConfirmChange(event: Event): Promise<void> {
    this.typedValue = event.target['value'];
  }

  /**
   * When an action button is pressed in the dialog, it sends the event here to return to
   * the parent so the parent can handle the action type appropriately.
   */
  public async onCheckConfirmAction(event): Promise<void> {
    await this.onAction(DEFAULT_DIALOG_RESULT.CHECK_CONFIRMATION);
  }

  /**
   * When an action button is pressed in the dialog, it sends the event here to return to
   * the parent so the parent can handle the action type appropriately.
   */
  public async onAction(actionKey: string): Promise<void> {
    const action = this.actions.find((action) => action.key === actionKey);
    if (action && action.action) {
      let actionResult;
      if (action.awaitAction) {
        actionResult = await action.action();
      } else {
        actionResult = action.action();
      }
      if (action && actionResult) {
        actionKey = actionResult;
      }
      //action.awaitAction ? await action.action() : action.action();
    }
    this.dialogRef.close(actionKey);
  }

  /**
   * Given an action item, will return the class string that should be applied to the buttons--otherwise,
   * if none are given, will guess a default based on the labels.
   * @param actionItem The classes we want applied to the button.
   */
  public getClasses(actionItem: IConfirmationDialogActionItem): string {
    if (actionItem.classes) {
      return actionItem.classes;
    } else {
      return 'btn btn-primary';
    }
  }

  /**
   * Determines if the options we passed are valid.
   * @param data The options data that was passed into the dialog.
   */
  private areOptionsValid(data: IConfirmationDialogOptions): boolean {
    // Content is required!
    if (!data.content) {
      logger.error(
        'Confirmation Dialog Options Are Invalid! Content is empty!'
      );
      return false;
    }
    // If actions are passed, ensure the fields exist!
    if (data.actions) {
      for (const action of data.actions) {
        // Key is REQUIRED--
        if (!action.key) {
          logger.error(
            'Confirmation Dialog Options Are Invalid! Action does not have key!'
          );
          return false;
        }
      }
    }
    return true;
  }

  private buildDefaultActions(): IConfirmationDialogActionItem[] {
    const actions: IConfirmationDialogActionItem[] = [];
    // use different default actions if typeToConfirm is set
    let confirmAction: IConfirmationDialogActionItem;
    if (this.typeToConfirm) {
      confirmAction = {
        key: DEFAULT_DIALOG_RESULT.CHECK_CONFIRMATION,
        label: 'Confirm',
        color: 'primary',
        classes: 'modal-submit-button',
        awaitAction: true,
        action: async () => {
          if (this.typedValue !== this.typeToConfirm) {
            return DEFAULT_DIALOG_RESULT.FAILED_CONFIRMATION;
          } else {
            return DEFAULT_DIALOG_RESULT.YES;
          }
        }
      };
    } else {
      confirmAction = {
        key: DEFAULT_DIALOG_RESULT.YES,
        label: 'Yes',
        color: 'primary',
        classes: 'modal-submit-button'
      };
    }
    const cancelAction: IConfirmationDialogActionItem = {
      key: DEFAULT_DIALOG_RESULT.CANCEL,
      label: 'Cancel',
      classes: 'modal-delete-button'
    };
    actions.push(cancelAction, confirmAction);
    return actions;
  }

  protected readonly DEFAULT_DIALOG_RESULT = DEFAULT_DIALOG_RESULT;
}
