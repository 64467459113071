import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppResolverErrorHandlerService } from '../../shared/services/resolver-error-handler.service';
import { AppSchoolsServices } from './schools.service';
import { BaseResolver } from '../../shared/base-classes/base.resolver';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { SchoolDetailResponseDto } from '@whetstoneeducation/hero-common';
import { AppSchoolsSubscriptionService } from './schools-subscription.service';

@Injectable({ providedIn: 'root' })
export class AppSchoolResolver extends BaseResolver<SchoolDetailResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public schoolsService: AppSchoolsServices,
    public subscriptionService: AppSchoolsSubscriptionService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SchoolDetailResponseDto> {
    const id = route.paramMap.get('id');
    if (!id) {
      return plainToInstance(
        SchoolDetailResponseDto,
        { schoolGroupId: this.StorageManager.getLoggedInUser().schoolGroupId },
        {
          exposeUnsetFields: true
        }
      );
    } else {
      return await this.schoolsService.getSchoolById(parseInt(id));
    }
  }
}
