import { BaseComponent } from '../../../shared/base-classes/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  DateUtil,
  ReactionEntriesListResponseDto,
  ReactionEntriesUpdateDto,
  RelationDto
} from '@whetstoneeducation/hero-common';
import { AppBehaviorCodeEntryService } from '../behavior-code-entry.service';
import { FormControl } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-behavior-code-entry-edit-reaction-modal',
  templateUrl: './behavior-code-entry-edit-reaction-modal.template.html',
  styleUrls: ['./behavior-code-entry-edit-reaction-modal.scss']
})
export class AppBehaviorCodeEntryEditReactionModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public isLoading = false;
  public reactionEntry: ReactionEntriesListResponseDto;
  public reactionOptions: RelationDto[];
  public scheduledReactionOptions: RelationDto[];
  public behaviorCodeReactionControl: FormControl<number>;
  public selectedScheduledReactionControl: FormControl<number | null>;
  public onClose: () => void;
  public behaviorCodeReactionControlValueSubscription: Subscription;

  constructor(
    public toastService: AppToastManagerService,
    public behaviorCodeEntryService: AppBehaviorCodeEntryService,
    public dialogRef: MatDialogRef<AppBehaviorCodeEntryEditReactionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reactionEntry: ReactionEntriesListResponseDto;
      onClose?: () => void;
    }
  ) {
    super();
    this.reactionEntry = data.reactionEntry;
    this.behaviorCodeReactionControl = new FormControl<number>(
      this.reactionEntry.behaviorCodeReactionId
    );
    this.selectedScheduledReactionControl = new FormControl<number | null>(
      this.reactionEntry.scheduledReactionId
    );

    this.onClose = data.onClose;
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const reactionOptionsCall =
      this.behaviorCodeEntryService.getReactionOptions(
        this.reactionEntry.behaviorCodeId,
        this.reactionEntry.studentId
      );

    const scheduledReactionOptionsCall =
      this.behaviorCodeEntryService.getScheduledReactionsOptions(
        this.reactionEntry.behaviorCodeReactionId,
        this.reactionEntry.studentId
      );

    const [reactionOptions, scheduledReactionOptions] = await Promise.all([
      reactionOptionsCall,
      scheduledReactionOptionsCall
    ]);

    const currentScheduledReaction = new RelationDto({
      id: this.reactionEntry.scheduledReactionId,
      name: DateUtil.convertTimestampToDisplayDate(
        this.reactionEntry.reactionDate
      )
    });

    this.reactionOptions = reactionOptions;
    this.scheduledReactionOptions = [
      ...scheduledReactionOptions,
      currentScheduledReaction
    ];

    this.behaviorCodeReactionControlValueSubscription =
      this.behaviorCodeReactionControl.valueChanges.subscribe(
        async (value: number) => {
          this.isLoading = true;
          this.scheduledReactionOptions =
            await this.behaviorCodeEntryService.getScheduledReactionsOptions(
              value,
              this.reactionEntry.studentId
            );

          this.selectedScheduledReactionControl.setValue(null);
          this.isLoading = false;
        }
      );

    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    this.behaviorCodeReactionControlValueSubscription.unsubscribe();
  }

  protected readonly DateUtil = DateUtil;

  public async updateReaction(): Promise<void> {
    this.isLoading = true;

    if (
      this.behaviorCodeReactionControl.value &&
      !this.selectedScheduledReactionControl.value &&
      this.scheduledReactionOptions.length > 0
    ) {
      this.toastService.error('Please select a scheduled reaction');
      this.isLoading = false;
      return;
    }

    const updateDto = new ReactionEntriesUpdateDto({
      behaviorCodeReactionId: this.behaviorCodeReactionControl.value,
      scheduledReactionId: this.selectedScheduledReactionControl.value
    });

    await this.behaviorCodeEntryService.updateReactionEntry(
      this.reactionEntry.id,
      updateDto
    );
    this.toastService.success('Reaction updated successfully');
    this.isLoading = false;
    this.onClose();
  }

  close(): void {
    this.dialogRef.close();
  }
}
