"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeEntryFilterDto = void 0;
const class_validator_1 = require("class-validator");
const shared_1 = require("../shared");
const isValidDate_decorator_1 = require("../shared/isValidDate.decorator");
const class_transformer_1 = require("class-transformer");
const boolean_transform_1 = require("../../constants/boolean.transform");
class BehaviorCodeEntryFilterDto extends shared_1.PaginationOptionsDto {
    constructor(behaviorCodeEntryFilterOptions) {
        super();
        if (!behaviorCodeEntryFilterOptions)
            return;
        this.calendarDayId = behaviorCodeEntryFilterOptions.calendarDayId;
        this.time = behaviorCodeEntryFilterOptions.time;
        this.notes = behaviorCodeEntryFilterOptions.notes;
        this.externalCode = behaviorCodeEntryFilterOptions.externalCode;
        this.rollbackDate = behaviorCodeEntryFilterOptions.rollbackDate;
        this.exported = behaviorCodeEntryFilterOptions.exported;
        this.behaviorCodeId = behaviorCodeEntryFilterOptions.behaviorCodeId;
        this.studentId = behaviorCodeEntryFilterOptions.studentId;
        this.reporterId = behaviorCodeEntryFilterOptions.reporterId;
        this.schoolId = behaviorCodeEntryFilterOptions.schoolId;
    }
    calendarDayId;
    time;
    notes;
    externalCode;
    rollbackDate;
    exported;
    behaviorCodeId;
    studentId;
    reporterId;
    schoolId;
}
exports.BehaviorCodeEntryFilterDto = BehaviorCodeEntryFilterDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryFilterDto.prototype, "calendarDayId", void 0);
__decorate([
    (0, isValidDate_decorator_1.IsValidTimestamp)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryFilterDto.prototype, "time", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(200),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BehaviorCodeEntryFilterDto.prototype, "notes", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BehaviorCodeEntryFilterDto.prototype, "externalCode", void 0);
__decorate([
    (0, isValidDate_decorator_1.IsValidTimestamp)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryFilterDto.prototype, "rollbackDate", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], BehaviorCodeEntryFilterDto.prototype, "exported", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryFilterDto.prototype, "behaviorCodeId", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryFilterDto.prototype, "studentId", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryFilterDto.prototype, "reporterId", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], BehaviorCodeEntryFilterDto.prototype, "schoolId", void 0);
