"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserUpdateDto = void 0;
const class_validator_1 = require("class-validator");
const user_password_dto_1 = require("./user-password.dto");
const class_transformer_1 = require("class-transformer");
const enums_1 = require("../../enums");
const mappable_base_1 = require("../../mappable.base");
const transform_config_1 = require("../../transform-config");
const boolean_transform_1 = require("../../constants/boolean.transform");
/**
 * @class UserUpdateDto
 * @extends MappableBase
 * @description
 * Contains typical user fields plus password and password confirm.
 */
class UserUpdateDto extends mappable_base_1.MappableBase {
    constructor(updateUserOptions) {
        super();
        if (!updateUserOptions)
            return;
        this.firstName = updateUserOptions.firstName;
        this.lastName = updateUserOptions.lastName;
        this.email = updateUserOptions.email;
        this.address = updateUserOptions.address;
        this.city = updateUserOptions.city;
        this.state = updateUserOptions.state;
        this.zip = updateUserOptions.zip;
        this.phone = updateUserOptions.phone;
        this.passwordInfo = updateUserOptions.passwordInfo;
        this.active = updateUserOptions.active;
        this.type = updateUserOptions.type;
        this.instructorCode = updateUserOptions.instructorCode;
        this.schoolGroupId = updateUserOptions.schoolGroupId;
    }
    email;
    firstName;
    lastName;
    passwordInfo;
    address;
    city;
    state;
    zip;
    phone;
    type;
    active;
    roles;
    instructorCode;
    schoolGroupId;
    schools;
    getFields() {
        return [
            'email',
            'active',
            'type',
            'firstName',
            'lastName',
            'address',
            'city',
            'state',
            'zip',
            'phone',
            'instructorCode'
        ];
    }
    getTransforms() {
        return [
            {
                destination: 'roles',
                transform: transform_config_1.TransformConfig.transformArray('roles', (source) => {
                    return source.map((role) => { return role.id; });
                })
            },
            {
                destination: 'schools',
                transform: transform_config_1.TransformConfig.mapIdsFromSchools()
            },
            {
                destination: 'schoolGroupId',
                transform: transform_config_1.TransformConfig.mapIdFromSchoolGroup()
            }
        ];
    }
    mapFields(userDetailResponse) {
        super.mapFields(userDetailResponse);
        return this;
    }
}
exports.UserUpdateDto = UserUpdateDto;
__decorate([
    (0, class_validator_1.IsEmail)(),
    (0, class_validator_1.MinLength)(9),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "firstName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(100),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "lastName", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => user_password_dto_1.UserPasswordDto),
    (0, class_validator_1.ValidateNested)({ message: 'password info validation failed' }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", user_password_dto_1.UserPasswordDto)
], UserUpdateDto.prototype, "passwordInfo", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "city", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.StateEnum),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "state", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "zip", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.UserTypeEnum),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], UserUpdateDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], UserUpdateDto.prototype, "roles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], UserUpdateDto.prototype, "instructorCode", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.Min)(1),
    __metadata("design:type", Number)
], UserUpdateDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayUnique)(),
    __metadata("design:type", Array)
], UserUpdateDto.prototype, "schools", void 0);
