"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactionEntriesListResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class ReactionEntriesListResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return [
            'id',
            'reaction',
            'compliedDate',
            'studentName',
            'studentId',
            'studentExtId',
            'studentGrade',
            'behaviorEntryDate',
            'behaviorCodeName',
            'reactionName',
            'reactionDate',
            'behaviorCodeEntryId',
            'behaviorCodeReactionId',
            'scheduledReactionId',
            'behaviorCodeId',
            'issuedBy',
            'schoolName',
            'behaviorReactionDate',
            'behaviorReactionName',
            'behaviorEntries',
            'reactionPoints',
            'studentTotalPoints',
            'period',
            'printCopies'
        ];
    }
    id;
    reaction;
    compliedDate;
    studentName;
    studentId;
    studentExtId;
    studentGrade;
    behaviorEntryDate;
    behaviorCodeName;
    behaviorCodeId;
    reactionName;
    reactionDate;
    scheduledReactionId;
    behaviorCodeReactionId;
    behaviorCodeEntryId;
    issuedBy;
    schoolName;
    behaviorReactionDate;
    behaviorReactionName;
    behaviorEntries;
    reactionPoints;
    studentTotalPoints;
    period;
    profilePhotoUrl;
    printCopies;
}
exports.ReactionEntriesListResponseDto = ReactionEntriesListResponseDto;
