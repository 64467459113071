import { Component, Input } from '@angular/core';
import { BaseCommonTableComponent } from './base-common-table.component';


/**
 * Common Table Portal Component.
 * This is a portal component for the common table component.
 * Use this component in the parent component to render the common table
 * and pass the component instance itself as the context input.
 * @example <app-base-common-table-portal [context]="this"></app-base-common-table-portal>
 */
@Component({
  selector: 'app-base-common-table-portal',
  templateUrl: './base-common-table.template.html',
  styleUrls: ['./common-table.scss']
})
export class BaseCommonTablePortalComponent<R, C> {
  @Input() context: BaseCommonTableComponent<R, C>;
}
