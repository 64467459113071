import { logger } from 'src/app/shared/logger';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { EnvService } from '../../../env.service';
import { AppToastManagerService } from '../toast-manager.service';
import { ClassConstructor, plainToInstance } from 'class-transformer';

/**
 * Used as a standard way to make all HTTP calls for the application.
 * From here we can make sure we are passing the same default headers, etc.
 * It also allows for us to be able to make custom calls outside our normal
 * API by using this method.
 */
@Injectable({
  providedIn: 'root'
})
export class AppClientDataService {
  /**
   * The base host URL (gateway) that defines where our API lives.
   */
  private readonly API_GATEWAY_URL: string;

  /**
   * Default Constructor
   * @param http A reference to the Angular HTTPClient
   * @param toastService A reference to the Toast Manager Service
   * @param envService The environment service that contains the API Gateway URL
   */
  constructor(
    private http: HttpClient,
    private toastService: AppToastManagerService,
    private envService: EnvService
  ) {
    this.API_GATEWAY_URL = this.envService.apiGatewayUrl;
  }

  /**
   * Used to execute an HTTPRequest against our internal API.
   * This method will handle figuring out everything that needs
   * to be done as far as headers and which client should be used.
   *
   * If you need to communicate with an external API for any reason--
   * please implement your own HTTPClient in the service where it is needed.
   * @param rawPartialUrl A partial URL that doesn't include the host. Refers to a matching route within the API.
   * @param options Refers to an object that may contain method, queryParams, pathParams, body content, etc
   * @param cls This is a DTO class that will be used to transform the response into an object.
   */
  public execute<T>(
    rawPartialUrl: string,
    options?: IClientDataServiceOptions,
    cls?: ClassConstructor<unknown>
  ): Promise<T> {
    return lastValueFrom(
      this.executeObservable<T>(rawPartialUrl, options, cls)
    );
  }

  public executeObservable<T>(
    rawPartialUrl: string,
    options?: IClientDataServiceOptions,
    cls?: ClassConstructor<unknown>
  ): Observable<T> {
    try {
      // Building query params--if any.
      const queryParams: HttpParams = this.getUrlQueryParameters(
        options && options.queryParams ? options.queryParams : null
      );
      // Parsing any path params if necessary
      const partialUrl: string =
        options && options.pathParams
          ? this.setUrlPathParameters(rawPartialUrl, options.pathParams)
          : rawPartialUrl;
      // Finally build the Final URL by placing the host/origin gateway in front of the partial.
      const finalUrl = `${this.API_GATEWAY_URL}${partialUrl}`;

      // Build the httpOptions object--
      const httpOptions: any = {};
      // Always set default headers
      httpOptions.headers = this.getDefaultHeaders(
        options ? options.headers : null
      );
      // This enforces that auth cookies are sent with every request.
      httpOptions.withCredentials = true;
      // Only set the query params if they are not null.
      if (queryParams) {
        httpOptions.params = queryParams;
      }
      if (options && options.fullResponse) {
        // Tells the HTTPClient to return the full response, so we can get any extra information that we want such as Status Codes, etc.
        httpOptions.observe = 'response';
      }

      // Setting the Verb Method we want to use to make the request--ues GET by default.
      const VERB: string =
        options && options.method ? options.method.toUpperCase() : 'GET';

      let httpCall;

      // Based on the verb--let's switch on it and decide how we should route this request.
      switch (VERB) {
        case 'GET':
          httpCall = this.http.get(finalUrl, httpOptions);
          break;

        case 'POST':
          httpCall = this.http.post(
            finalUrl,
            options && options.body ? options.body : null,
            httpOptions
          );
          break;

        case 'PUT':
          httpCall = this.http.put(
            finalUrl,
            options && options.body ? options.body : null,
            httpOptions
          );
          break;
        case 'PATCH':
          httpCall = this.http.patch(
            finalUrl,
            options && options.body ? options.body : null,
            httpOptions
          );
          break;

        case 'DELETE':
          httpCall = this.http.delete(finalUrl, httpOptions);
          break;

        default:
          httpCall = this.http.get(finalUrl, httpOptions);
      }

      return httpCall.pipe(
        map((value) => {
          if (cls) {
            if (value instanceof HttpResponse) value = value.body;
            value = plainToInstance(cls, value, {
              enableImplicitConversion: true,
              exposeDefaultValues: true
            });
          }
          return value;
        })
      );
    } catch (err) {
      const error = err.error || err;
      if ((options && !options.fullResponse) || !options) {
        // If Full Response was returned--we will rethrow the error and let calling service handle showing error messages.
        // This is useful if you are making a call where you expect something to fail and want to show specific messages to the user.
        logger.error(`Error on URL: ${rawPartialUrl}`, error);
        this.toastService.error(`${error.message}`);
      }
      throw error;
    }
  }

  /**
   * Allows making an HTTP call to a fully qualified URL for custom usage such as Google Auth or any other external API, etc.
   * @param fullUrl The fully qualified URL that you want to request including query and path params. EXAMPLE: https://api.example.com/1?include=true
   * @param options Allows to specify the VERB method, defaults to GET. Body and additional headers can also be set.
   */
  public async custom(
    fullUrl: string,
    options?: { method?: string; body?: any; headers?: any }
  ): Promise<any> {
    try {
      // Build the httpOptions object--
      const httpOptions: any = {};
      // Always set default headers
      httpOptions.headers = options && options.headers ? options.headers : null;
      // This enforces that auth cookies are sent with every request.
      httpOptions.withCredentials = true;
      // Tells the HTTPClient to return the full response, so we can get any extra information that we want such as Status Codes, etc.
      httpOptions.observe = 'response';

      // Setting the Verb Method we want to use to make the request--ues GET by default.
      const VERB: string =
        options && options.method ? options.method.toUpperCase() : 'GET';

      // Based on the verb--let's switch on it and decide how we should route this request.
      switch (VERB) {
        case 'GET':
          const getRes: any = await this.http.get(fullUrl, httpOptions);
          return lastValueFrom(getRes);

        case 'POST':
          const postRes: any = await this.http.post(
            fullUrl,
            options && options.body ? options.body : null,
            httpOptions
          );
          return lastValueFrom(postRes);

        case 'PUT':
          const putRes: any = await this.http.put(
            fullUrl,
            options && options.body ? options.body : null,
            httpOptions
          );
          return lastValueFrom(putRes);

        case 'DELETE':
          const deleteRes: any = await this.http.delete(fullUrl, httpOptions);
          return lastValueFrom(deleteRes);

        default:
          const getResDefault: any = await this.http.get(fullUrl, httpOptions);
          return lastValueFrom(getResDefault);
      }
    } catch (err) {
      const error = err.error || err;
      // Show us the error in the console--but let the invoking method handle anything outside here for custom usage.
      logger.error(err);
      throw error;
    }
  }

  /**
   * Returns the default headers that all requests should have.
   */
  private getDefaultHeaders(additionalHeaders: any = null): HttpHeaders {
    const headers: { [prop: string]: string } = {
      'Content-Type': 'application/json',
      Version: '1',
      ...(additionalHeaders && additionalHeaders)
    };

    return new HttpHeaders(headers);
  }

  /**
   * Sets Path Parameters that are present in the URL if passed in the request.
   * @param rawUrl The original URL as received from the ROUTES CONFIG
   * @param pathParams An object that represents the key/value pairs to replace in the URL path.
   *
   * EXAMPLE:
   * rawUrl: "/api/users/:id"
   *
   * pathParams:
   * {
   *    id: 1234567890
   * }
   *
   * Returns "/api/users/1234567890"
   */
  public setUrlPathParameters(rawUrl: string, pathParams: any): string {
    // Only attempt to set the path params if they were passed in.
    if (pathParams) {
      // Loop through each key, so we can match what to replace in the rawUrl.
      for (const key of Object.keys(pathParams)) {
        // Only attempt if the key is actually present in the URL.
        if (rawUrl.indexOf(`:${key}`) && pathParams[key]) {
          rawUrl = rawUrl.replace(`:${key}`, pathParams[key].toString());
        }
      }
      return rawUrl;
    } else {
      // If the rawUrl contains a colon--it means that path parameters were required--throw an error.
      // Otherwise, return the string as-is.
      if (rawUrl.indexOf(':') > -1) {
        this.toastService.warn(
          'This route requires a path param that was not supplied!'
        );
        logger.error(
          'This URL requires a path parameter! Please ensure you are passing in all required fields for the request to be made!'
        );
        return null;
      } else {
        return rawUrl.toString();
      }
    }
  }

  /**
   * Returns the HttpParams object given the queryParams that were passed.
   * @param queryParams An Object that represents the queryParams that we need to build for.
   */
  private getUrlQueryParameters(queryParams: any): HttpParams {
    // First check if we need to add default query params
    if (queryParams) {
      return new HttpParams({
        fromObject: queryParams
      });
    } else {
      return null;
    }
  }
}

/**
 * Defines an object of options that can be supplied with every request.
 */
export interface IClientDataServiceOptions {
  /**
   * OPTIONS: GET,POST,PUT,DELETE
   * GET is the default, used for data retrieval.
   * POST is used for creating new records.
   * PUT is used for updating existing records.
   * PATCH is used for partial updates ie update password only.
   * DELETE is used for deleting or deactivating records.
   */
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

  /**
   * Query Params are parsed and appended to the end of the request automatically.
   */
  queryParams?: any;

  /**
   * Path Params are replaced with the corresponding name as defined in the config file.
   */
  pathParams?: any;

  /**
   * Optional headers that we want to pass in with the request.
   */
  headers?: any;

  /**
   * Body type to be sent as a payload to the server.
   */
  body?: any;

  /**
   * If TRUE, will return the response as a full object including status code, etc.
   * FALSE will return just the data you want.
   *
   * DEFAULT = FALSE
   */
  fullResponse?: boolean;
}
