import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { PrivilegeEnum, ValidationUtil } from '@whetstoneeducation/hero-common';
import { CalendarDayBellScheduleManageModalComponent } from '../../../calendar/calendar-day-bell-schedule-manage-modal/calendar-day-bell-schedule-manage-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-today-schedule',
  templateUrl: './today-schedule.component.html',
  styleUrls: ['./today-schedule.component.scss']
})
export class TodayScheduleComponent extends BaseComponent {
  // TODO: rework schedules after school groups - THOR-380
  @Input() todaySchedules: any[];

  @Output() scheduleChanged: EventEmitter<boolean>;

  public canEdit: boolean;
  constructor(
    public dialog: MatDialog
  ) {
    super();
    const { privileges, currentSchoolId } =
      this.StorageManager.getLoggedInUser();
    this.canEdit = ValidationUtil.hasPrivilege(
      PrivilegeEnum.SET_ACTIVE_BELL_SCHEDULE,
      privileges,
      currentSchoolId
    );
    this.scheduleChanged = new EventEmitter<boolean>();
  }

  public openChangeSchedule() {
    this.dialog.open(CalendarDayBellScheduleManageModalComponent, {
      width: '568px',
      panelClass: 'default-dialog',
      data: {
        timestamp: new Date().getTime(),
        onClose: () => {
          // refetch todays schedule
          this.scheduleChanged.emit(true);
        }
      }
    });
  }
}
