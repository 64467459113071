import { Component, EventEmitter } from '@angular/core';
import { INotificationTemplateFilters } from '../template-list-models/notification-template-list-filters.interface';
import { NotificationsSharedListFilters } from '../../shared/notifications-shared-list-filters.directive';
import { NotificationTemplateBodyType } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-notification-template-list-filters',
  templateUrl: './notification-template-list-filters.template.html',
  styleUrls: ['./notification-template-list-filters.scss']
})
export class AppNotificationTemplateListFilters extends NotificationsSharedListFilters<INotificationTemplateFilters> {
  public bodyTypes = Object.values(NotificationTemplateBodyType);

  constructor() {
    super();
    this.filtersUpdated = new EventEmitter<INotificationTemplateFilters>();
  }
}
