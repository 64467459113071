<app-common-table
  tabindex="0"
  aria-label="Student behavior history table"
  *ngIf="dataArr.length"
  [getColumnValue]="getColumnValue"
  [columns]="displayedColumns"
  [tableFilters]="tableFilters"
  [templates]="templates"
  [dataArr]="dataArr"
  (tableFiltersUpdated)="updateTableFilters($event)">
</app-common-table>

<ng-template #actionButtons let-behaviorEntryId="behaviorEntryId">
  <button
    type="button"
    *ngIf="canDelete"
    mat-flat-button
    class="delete-button secondary-button icon-button"
    (click)="openDeleteModal(behaviorEntryId)"
    options-id="DELETE_ENTRY">
    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
  </button>
  <button
    type="button"
    *ngIf="canEdit"
    mat-flat-button
    class="secondary-button icon-button"
    (click)="openEditModal(behaviorEntryId)"
    options-id="EDIT_ENTRY">
    <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
  </button>
  <!-- view notes button -->
  <button
    *ngIf="hasNotes(behaviorEntryId) && (canEditNotes || canView)"
    type="button"
    mat-flat-button
    (click)="onViewNotes(behaviorEntryId)"
    class="outline-button icon-button"
    options-id="VIEW_NOTES_REACTION_ENTRY">
    <fa-icon [icon]="['fas', 'note-sticky']"></fa-icon>
  </button>
</ng-template>
