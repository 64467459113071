import { EventEmitter, Injectable } from "@angular/core";
import { SaveBarAction } from "./save-bar-action.enum";
import { ISaveBarButton } from "./save-bar-button.interface";
import { SaveStatusEnum } from "./save-status.enum";

export interface ISaveBarOptions {
  hideAutoSave: boolean;
}

/**
 * Service used to handle save bar operations
 */
@Injectable({
  providedIn: "root",
})
export class AppSaveBarService {
  /**
   * Emits on show/hide. Passes show and options as a param
   */
  public visibilityChanged = new EventEmitter<{
    show: boolean;
    buttons: ISaveBarButton[];
    options?: ISaveBarOptions;
  }>();

  /**
   * Emits on any save bar button pressed. (Emits a button type from the enum)
   */
  public buttonPressed = new EventEmitter<SaveBarAction>();

  /**
   * Emits on save start/success/failure. Pass saveStatus as a param
   */
  public saveStatusUpdated = new EventEmitter<SaveStatusEnum>();

  /**
   * Shows the save bar with optional options
   */
  public show(buttons: ISaveBarButton[], options?: ISaveBarOptions): void {
    this.visibilityChanged.emit({
      show: true,
      buttons,
      options,
    });
  }

  /**
   * Hides the save bar
   */
  public hide(): void {
    this.visibilityChanged.emit({
      show: false,
      buttons: [],
      options: null,
    });
  }

  /**
   * Tell our saveBar that the save has started
   */
  public saveStarted(): void {
    this.saveStatusUpdated.emit(SaveStatusEnum.saving);
  }

  /**
   * Tell our saveBar that the save has worked
   */
  public saveWorked(): void {
    this.saveStatusUpdated.emit(SaveStatusEnum.success);
  }

  /**
   * Tell our saveBar that the save has failed (also passes the error)
   */
  public saveFailed(error: ProgressEvent): void {
    this.saveStatusUpdated.emit(this.getErrorType(error));
  }

  /**
   * What type of error did our API fail on
   */
  private getErrorType(error): SaveStatusEnum {
    if (error.target && (error.target as XMLHttpRequest).status === 0) {
      return SaveStatusEnum.connection_failure;
    }
    return SaveStatusEnum.save_failure;
  }
}
