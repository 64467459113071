import { BaseComponent } from '../../../shared/base-classes/base.component';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  IDisplayData,
  ISharedNotificationFilters,
  LoginResponseDto,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { StorageManager } from '../../../shared/storage/storage.manager';
import { MatSelectChange } from '@angular/material/select';

@Directive()
export class NotificationsSharedListFilters<
  T extends ISharedNotificationFilters
> extends BaseComponent {
  @Input() filters: T;
  @Output() filtersUpdated: EventEmitter<T>;
  @Input()
  public schoolGroupOptions: IDisplayData[] = [];
  @Input()
  public schoolOptions: IDisplayData[] = [];
  public currentUser: LoginResponseDto = StorageManager.getLoggedInUser();

  constructor() {
    super();
  }

  public updateSchoolGroup(event: MatSelectChange) {
    this.filters.schoolGroupId = event.value;
    const schoolGroupId = event.value;
    const schools = this.currentUser.schoolGroups.find(
      (sg) => sg.id === schoolGroupId
    ).schools;
    this.schoolOptions = schools.map((s) => {
      return {
        value: s.id,
        display: s.name
      };
    });
    this.filtersUpdated.emit(this.filters);
  }

  // This is a workaround to make the constant available in
  // the template context. Otherwise we would have to import
  // the enum in every component that uses this directive.
  UserTypeEnum = UserTypeEnum;
}
