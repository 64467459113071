import {
  AfterViewInit,
  Component,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BehaviorHistoryTableColumnsEnum } from './behavior-history-table-columns.enum';
import { BaseTableComponent } from '../../../../../shared/tables/base-table/base-table';
import {
  PrivilegeEnum,
  StudentBehaviorHistoryResponseDto,
  ValidationUtil
} from '@whetstoneeducation/hero-common';
import { AppPrivilegesService } from '../../../../auth/privileges.service';

@Component({
  selector: 'app-behavior-history-table',
  templateUrl: './behavior-history-table.component.html',
  styleUrls: ['./behavior-history-table.component.scss']
})
export class BehaviorHistoryTableComponent
  extends BaseTableComponent<StudentBehaviorHistoryResponseDto>
  implements AfterViewInit
{
  @ViewChild('actionButtons') actionButtons!: TemplateRef<any>;
  @Input() public openDeleteModal: (behaviorCodeId: number) => void;
  @Input() public openEditModal: (behaviorCodeId: number) => void;
  @Input() public onViewNotes: (behaviorCodeId: number) => void;
  @Input() public hasNotes: (behaviorCodeId: number) => boolean;

  public templates: { [key: string]: TemplateRef<any> } = {};

  canDelete: boolean;
  canEdit: boolean;
  canView: boolean;
  canEditNotes: boolean;
  constructor(privilegesService: AppPrivilegesService) {
    super({ privilegesService });
    const { privileges, currentSchoolId } =
      this.StorageManager.getLoggedInUser();
    this.canDelete = ValidationUtil.hasPrivilege(
      PrivilegeEnum.DELETE_TRACKED_BEHAVIOR,
      privileges,
      currentSchoolId
    );
    this.canEdit = ValidationUtil.hasPrivilege(
      PrivilegeEnum.EDIT_TRACKED_BEHAVIOR,
      privileges,
      currentSchoolId
    );
    this.canView = ValidationUtil.hasPrivilege(
      PrivilegeEnum.VIEW_BEHAVIOR_NOTES,
      privileges,
      currentSchoolId
    );
    this.canEditNotes = ValidationUtil.hasPrivilege(
      PrivilegeEnum.EDIT_BEHAVIOR_NOTES,
      privileges,
      currentSchoolId
    );
  }
  ngAfterViewInit(): void {
    if (this.actionButtons) {
      this.templates['actionButtons'] = this.actionButtons;
    }
  }

  public columns = BehaviorHistoryTableColumnsEnum;

  public columnValues = {
    [BehaviorHistoryTableColumnsEnum.DATE]: ['date'],
    [BehaviorHistoryTableColumnsEnum.BEHAVIOR]: ['behaviorCodeName'],
    [BehaviorHistoryTableColumnsEnum.ACTION]: ['reactionName'],
    [BehaviorHistoryTableColumnsEnum.ACTION_DATE]: ['reactionDate'],
    [BehaviorHistoryTableColumnsEnum.COMPLIED]: ['complied'],
    [BehaviorHistoryTableColumnsEnum.POINTS]: ['points'],
    [BehaviorHistoryTableColumnsEnum.ISSUED_BY]: ['reporter'],
    [BehaviorHistoryTableColumnsEnum.ACTIVE]: ['active']
  };

  public get displayedColumns() {
    const removeActions = !(
      this.canDelete ||
      this.canEdit ||
      this.canView ||
      this.canEditNotes
    )
      ? [BehaviorHistoryTableColumnsEnum.ACTIONS]
      : [];

    return Object.values(BehaviorHistoryTableColumnsEnum).filter(
      (value) => !removeActions.includes(value)
    );
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case BehaviorHistoryTableColumnsEnum.DATE:
        return `${this.getFormattedDate(row.date)} ${this.getFormattedTime(
          row.date
        )}`;
      case BehaviorHistoryTableColumnsEnum.BEHAVIOR:
        return row.behaviorCodeName;
      case BehaviorHistoryTableColumnsEnum.ACTION:
        return row.reactionName;
      case BehaviorHistoryTableColumnsEnum.ACTION_DATE:
        return row.compliedRequired
          ? row?.reactionDate
            ? this.getFormattedDate(row.date)
            : 'no date'
          : 'N/A';
      case BehaviorHistoryTableColumnsEnum.COMPLIED:
        return row.cancelled
          ? 'cancelled'
          : row.compliedRequired
            ? row.compliedDate
              ? 'yes'
              : 'no'
            : 'N/A';
      case BehaviorHistoryTableColumnsEnum.POINTS:
        return row.points;
      case BehaviorHistoryTableColumnsEnum.ISSUED_BY:
        return row.reporter;
      case BehaviorHistoryTableColumnsEnum.ACTIONS:
        return {
          context: {
            behaviorEntryId: row.behaviorEntryId
          },
          template: this.templates.actionButtons
        };
      case BehaviorHistoryTableColumnsEnum.ACTIVE:
        return row.active ? 'Active' : 'Inactive';
      default:
        return null;
    }
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
