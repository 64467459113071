import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../app-material.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { AppLinkDropdownComponent } from './link-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    FormsModule,
    AppPipesModule
  ],
  declarations: [AppLinkDropdownComponent],
  exports: [AppLinkDropdownComponent]
})
export class AppLinkDropdownModule {}
