import { DateUtil } from "@whetstoneeducation/hero-common";
import { ReactionPrintBuilder } from "./behavior-code-reaction-print-builder";
import { ZplCommands } from "../../../shared/printer/commands/zpl";

export class ReactionPrintEzplBuilder extends ReactionPrintBuilder {

  constructor() {
    super();
  }

  public startCommand(): string {
    return `
^Q90,3
^W90
^H5
^P1
^S2
^AD
^C1
^R0
~Q+0
^O0
^D0
^E12
~R255
^XSET,ROTATION,0
^L
Dy2-me-dd
Th:m:s
Dy2-me-dd
Th:m:s`;
  }

  public finishCommand(): string {
    return `
E`;
  }

  public buildHeader(): ReactionPrintBuilder {
    this.addCommand(`
AD,178,194,1,1,0,0,${this.data.schoolName}`);
    return this;
  }

  public buildSubHeader(): ReactionPrintBuilder {
    this.addCommand(`
AD,162,234,1,1,0,0,${this.data.behaviorCodeName}`);
    return this;
  }

  public buildStudentIdEntries(): ReactionPrintBuilder {
    this.addCommand(`
AB,43,300,1,1,0,0,Student ID#: ${this.getStudentId()}
AB,348,298,1,1,0,0,Entries: ${this.data.behaviorEntries}`);
    return this;
  }

  public buildStudentName(): ReactionPrintBuilder {
    const [firstName, lastName] = this.getStudentNames();

    this.addCommand(`
AB,44,321,1,1,0,0,First Name: ${firstName}
AB,347,319,1,1,0,0,Last Name: ${lastName}`);
    return this;
  }

  public buildEntryDateTime(): ReactionPrintBuilder {
    this.addCommand(`
AB,44,348,1,1,0,0,Entry Time: ${DateUtil.convertTimestampToDisplayTime(this.data.behaviorEntryDate)}
AB,346,344,1,1,0,0,Entry Date: ${DateUtil.convertTimestampToReadableDate(this.data.behaviorEntryDate)}`);
    return this;
  }

  public buildPeriod(): ReactionPrintBuilder {
    this.addCommand(`
AB,44,374,1,1,0,0,During: ${this.data.period ?? 'N/A'}`);
    return this;
  }

  public buildActionTypeDate(): ReactionPrintBuilder {
    this.addCommand(`
AB,44,398,1,1,0,0,Action Type: ${this.data.behaviorReactionName}
AB,43,423,1,1,0,0,Action Date: ${DateUtil.convertTimestampToReadableDate(this.data.behaviorReactionDate)}`)
    return this;
  }

  public buildPoints(): ReactionPrintBuilder {
    this.addCommand(`
AB,113,509,1,1,0,0,Points: ${this.data.reactionPoints}
AB,341,509,1,1,0,0,Total Points: ${this.data.studentTotalPoints}`);
    return this;
  }

  public buildBarcode(): ReactionPrintBuilder {
    const studentExtId = this.data.studentExtId;

    if (studentExtId && studentExtId.length) {
      this.addCommand(`
BQ,276,550,1,3,80,0,0,${studentExtId}`)
    }

    return this;
  }

}
