import { catchError, Observable, of, Subject, takeUntil } from 'rxjs';
import { Component, Input, OnDestroy } from '@angular/core';
import { AppToastManagerService } from '../services/toast-manager.service';
import { AppStudentsService } from '../../pages/students/students.service';

@Component({
  selector: 'app-student-profile-photo',
  templateUrl: './student-profile-photo.template.html',
  styleUrls: ['./student-profile-photo.scss']
})
export class AppStudentProfilePhotoComponent implements OnDestroy {
  @Input()
  public studentId?: number;

  @Input()
  selectedImage: string | ArrayBuffer | null;

  @Input()
  hideProfileImageSelector: boolean = false;

  @Input()
  imageWidth: number = 80;

  @Input()
  imageHeight: number = 80;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly studentsService: AppStudentsService,
    private readonly toastService: AppToastManagerService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = async (e) => {
        const uploadResult: Observable<Object> =
          await this.studentsService.uploadStudentPhoto(this.studentId, file);

        if (uploadResult) {
          uploadResult
            .pipe(
              takeUntil(this.destroy$),
              catchError((error) => {
                // Handle the error here
                const message = error?.error?.message;
                if (message === 'File too large') {
                  this.toastService.error(
                    'You can only upload images up to 10MB'
                  );
                }
                return of(null);
              })
            )
            .subscribe((result) => {
              this.selectedImage = result['profilePhotoUrl'];
              if (result['status'] === 'success') {
                this.toastService.success(
                  'Profile image uploaded successfully'
                );
              } else {
                this.toastService.error('Error uploading profile image');
              }
            });
        } else {
          this.toastService.error('Error uploading profile image');
        }
      };

      reader.readAsDataURL(file);
    }
  }
}
