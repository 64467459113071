import { AppSchoolGroupCreateEditComponent } from './school-group-create-edit.component';
import { AppSchoolGroupResolver } from './school-group.resolver';
import { ICustomRoute } from '../../app-routing.module';
import { AuthGuard } from '../../route-guards/auth.guard';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { AppSchoolGroupsListComponent } from './school-groups-list/school-groups-list.component';
import { AppSchoolGroupsListResolver } from './school-groups-list/school-groups-list.resolver';
import { UserTypeEnum } from '@whetstoneeducation/hero-common';

export const AppSchoolGroupRoutes: ICustomRoute[] = [
  {
    path: 'school-groups/:id',
    title: 'School Group',
    component: AppSchoolGroupCreateEditComponent,
    resolve: { data: AppSchoolGroupResolver },
    data: {
      title: 'School Group',
      description: 'Create or edit a school group',
      buttons: [
        {
          name: 'Save',
          action: HeaderButtonAction.SAVE,
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'school-groups',
    title: 'School Groups',
    component: AppSchoolGroupsListComponent,
    resolve: { data: AppSchoolGroupsListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'School Groups',
      description: 'All school groups',
      icon: 'school',
      buttons: [
        {
          name: 'Create School Group',
          action: HeaderButtonAction.CREATE,
          icon: 'plus-circle',
          style: 'secondary-button',
          userType: UserTypeEnum.INTERNAL_ADMIN
        }
      ]
    }
  }
];
