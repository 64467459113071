import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../../../app-material.module';
import { AppPaginationComponent } from './pagination.component';
import { AppMySettingsModule } from '../../../pages/my-settings/my-settings.module';
import { DirectiveModule } from '../../directives/directive.module';
import { CustomPaginationDirective } from './custom-pagination.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AppMaterialModule,
    FormsModule,
    AppMySettingsModule,
    DirectiveModule,
    CustomPaginationDirective
  ],
  declarations: [AppPaginationComponent],
  exports: [AppPaginationComponent]
})
export class AppPaginationModule {}
