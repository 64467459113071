"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeUpdateDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const boolean_transform_1 = require("../../constants/boolean.transform");
class BehaviorCodeUpdateDto extends mappable_base_1.MappableBase {
    constructor(updateBehaviorCodeOptions) {
        super();
        if (!updateBehaviorCodeOptions)
            return;
        this.code = updateBehaviorCodeOptions.code;
        this.description = updateBehaviorCodeOptions.description;
        this.passMessage = updateBehaviorCodeOptions.passMessage;
        this.printPass = updateBehaviorCodeOptions.printPass;
        this.active = updateBehaviorCodeOptions.active;
        this.behaviorCodeTypeId = updateBehaviorCodeOptions.behaviorCodeTypeId;
        this.externalReferenceCode =
            updateBehaviorCodeOptions.externalReferenceCode;
    }
    getFields() {
        return [
            'id',
            'code',
            'description',
            'passMessage',
            'printPass',
            'notificationTrigger',
            'active',
            'fastTrack',
            'behaviorCodeTypeId',
            'externalReferenceCode'
        ];
    }
    code;
    description;
    passMessage;
    printPass;
    notificationTrigger;
    fastTrack;
    active;
    behaviorCodeTypeId;
    externalReferenceCode;
}
exports.BehaviorCodeUpdateDto = BehaviorCodeUpdateDto;
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(50),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BehaviorCodeUpdateDto.prototype, "code", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BehaviorCodeUpdateDto.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(250),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BehaviorCodeUpdateDto.prototype, "passMessage", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], BehaviorCodeUpdateDto.prototype, "printPass", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeUpdateDto.prototype, "notificationTrigger", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], BehaviorCodeUpdateDto.prototype, "fastTrack", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], BehaviorCodeUpdateDto.prototype, "active", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], BehaviorCodeUpdateDto.prototype, "behaviorCodeTypeId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BehaviorCodeUpdateDto.prototype, "externalReferenceCode", void 0);
