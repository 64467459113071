import { Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute } from '@angular/router';
import { IComplianceListResolverData } from './compliance-list-models/compliance-list-resolver-data.interface';
import { IComplianceListFilters } from './compliance-list-models/compliance-list-filters.interface';
import {
  IDisplayData,
  ReactionEntriesListResponseDto,
  ReactionEntriesResponseDto
} from '@whetstoneeducation/hero-common';
import { AppComplianceService } from '../compliance.service';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { Subject, takeUntil } from 'rxjs';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { DEFAULT_DIALOG_RESULT } from '../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { AppReportsService } from '../../reports/reports.service';

@Component({
  selector: 'app-compliance-list',
  templateUrl: './compliance-list.template.html',
  styleUrls: ['./compliance-list.scss']
})
export class AppComplianceListComponent
  extends BaseComponent
  implements OnDestroy
{
  public reactionEntries: ReactionEntriesListResponseDto[];
  public filters: IComplianceListFilters;
  public dropdownData: {
    reactions: IDisplayData[];
  };
  public hasSelectedSchool: boolean;

  public startDate: Date;
  public endDate: Date;
  public destroy$ = new Subject<void>();

  constructor(
    private toastService: AppToastManagerService,
    private appComplianceService: AppComplianceService,
    public pageHeaderService: AppPageHeaderService,
    public confirmationDialog: AppConfirmationDialogService,
    public reportService: AppReportsService,
    public route: ActivatedRoute
  ) {
    super();
    this.loadData();

    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: string) => {
        if (action === HeaderButtonAction.EXPORT) {
          const ref = this.confirmationDialog.openDialog({
            title: `Daily Activity Report Export`,
            content:
              'Are you sure you want to export the daily activity report?'
          });
          ref.afterClosed().subscribe(async (result: string) => {
            if (result === DEFAULT_DIALOG_RESULT.YES) {
              await this.reportService.exportComplianceReport(this.filters);
              this.toastService.success(
                'Export started, we will email you with a link to the file'
              );
            }
          });
        }
      });
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as IComplianceListResolverData;
    this.reactionEntries = data.reactionEntries.results;
    this.filters = data.defaultFilters;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.dropdownData = data.dropdownData;
    this.hasSelectedSchool =
      !!this.StorageManager.getLoggedInUser().currentSchoolId;
    this.isLoading = false;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateReactionEntries(this.filters);
    this.isLoading = false;
  }

  public async updateReactionEntries(
    filters: IComplianceListFilters
  ): Promise<void> {
    try {
      const reactionEntries =
        await this.appComplianceService.getReactionEntries(filters);
      this.reactionEntries = reactionEntries.results;
      this.filters.tableFilters.count = reactionEntries.options.totalItems;
    } catch (error) {
      this.toastService.error('Error updating reaction entries');
    }
  }

  public async updateFilters(filters: IComplianceListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = filters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.COMPLIANCE_LIST,
      this.filters.tableFilters
    );
    await this.updateReactionEntries(this.filters);

    this.isLoading = false;
  }

  public async reloadTable() {
    await this.updateReactionEntries(this.filters);
  }
}
