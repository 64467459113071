import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import {
  BehaviorCodeOrdinalUpdateDto,
  BehaviorCodeResponseDto
} from '@whetstoneeducation/hero-common';
import { BehaviorCodesListColumns } from '../behavior-codes-list-models/behavior-codes-list-columns.enum';
import { AppPrivilegesService } from '../../../auth/privileges.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { logger } from '../../../../shared/logger';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppBehaviorCodesService } from '../../behavior-codes.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-behavior-codes-table',
  templateUrl: './behavior-codes-list-table.template.html',
  styleUrls: ['./behavior-codes-list-table.scss']
})
export class AppBehaviorCodesListTableComponent extends BaseTableComponent<BehaviorCodeResponseDto> {
  public get displayedColumns() {
    let columns = Object.values(BehaviorCodesListColumns);

    if (!this.reorderMode) {
      columns = columns.filter(
        (column) => column !== BehaviorCodesListColumns.REDORDER
      );
    }
    if (!this.hasSelectedSchool) {
      columns = columns.filter(
        (column) => column !== BehaviorCodesListColumns.ORDER
      );
    }
    return columns;
  }

  public columnValues = {
    [BehaviorCodesListColumns.ORDER]: ['ordinal'],
    [BehaviorCodesListColumns.CODE]: ['code'],
    [BehaviorCodesListColumns.DESCRIPTION]: ['description'],
    [BehaviorCodesListColumns.SCHOOL]: ['school', 'name'],
    [BehaviorCodesListColumns.PRINT_PASS]: ['printPass'],
    [BehaviorCodesListColumns.ACTIVE]: ['active']
  };

  @Input() public hasSelectedSchool: boolean;

  public columns = BehaviorCodesListColumns;

  public reorderMode: boolean = false;

  @Output() reorderModeUpdated: EventEmitter<boolean>;

  public updatedOrdinals: Partial<BehaviorCodeOrdinalUpdateDto>[];

  constructor(
    public privilegesService: AppPrivilegesService,
    public toastManager: AppToastManagerService,
    public behaviorCodesService: AppBehaviorCodesService,
    public router: Router,
    private confirmationDialog: AppConfirmationDialogService
  ) {
    super({ privilegesService });
    this.reorderModeUpdated = new EventEmitter<boolean>();
  }

  public reorder(): void {
    if (!this.hasSelectedSchool) {
      const ref = this.confirmationDialog.openDialog({
        title: 'School must be selected!',
        content:
          'Behavior Codes can only be reordered for a single school. Please switch to a school and try again!',
        actions: [
          {
            key: 'SETTINGS',
            label: 'Go To My Settings',
            classes: 'success-button'
          },
          {
            key: 'CANCEL',
            label: 'Cancel',
            icon: 'times-circle'
          }
        ]
      });
      ref.afterClosed().subscribe((result: string) => {
        if (result === 'SETTINGS') {
          this.router.navigate(['my-settings']);
        }
      });
    } else {
      this.reorderMode = !this.reorderMode && this.hasSelectedSchool;
      this.reorderModeUpdated.emit(this.reorderMode);
    }
  }

  /**
   * Move our item to where it was dropped, then re-render our table
  //  */
  public async dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataArr, event.previousIndex, event.currentIndex);
    this.updateListOrder();

    this.table.renderRows();
  }

  public updateListOrder() {
    this.dataArr.forEach((behaviorCode, index) => {
      behaviorCode.ordinal = index;
    });

    this.updatedOrdinals = this.dataArr.map(({ id, ordinal }) => {
      return {
        id,
        ordinal
      };
    });
  }

  public async saveOrder() {
    this.isLoading = true;
    try {
      const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
      if (schoolId) {
        const updatedOrdinals =
          await this.behaviorCodesService.massUpdateBehaviorCodeOrdinals(
            schoolId,
            this.updatedOrdinals
          );
        if (updatedOrdinals) {
          this.reorder();
        }
      } else {
        this.toastManager.error(
          'School must be selected to reorder Behavior Codes!'
        );
      }
    } catch (err) {
      this.toastManager.error('Unable to update order, please try again!');
      logger.error(err);
    }
    this.isLoading = false;
  }
}
