import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AppStudentsService } from '../../../students/students.service';
import { AppCustomRostersService } from '../../custom-rosters.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CustomRostersResponseDto,
  CustomRostersUpdateDto,
  DateUtil,
  StudentBasicResponseDto,
  StudentFilterDto,
  StudentPerformanceDto
} from '@whetstoneeducation/hero-common';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AddStudentColumnsEnum } from '../../custom-roster-models/add-student-columns.enum';
import { IStudentsListFilters } from '../../../students/students-list-models/students-list-filters.interface';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import { logger } from '../../../../shared/logger';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';

@Component({
  selector: 'app-add-students-modal',
  templateUrl: './add-students-modal.component.html',
  styleUrls: ['./add-students-modal.component.scss']
})
export class AddStudentsModalComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public customRoster: CustomRostersResponseDto;
  public search: string;
  public grade: string;
  public filters: IStudentsListFilters;
  studentsAdded: number[] = [];
  students: StudentPerformanceDto[] = [];
  constructor(
    public toastManager: AppToastManagerService,
    public customRosterService: AppCustomRostersService,
    public studentsService: AppStudentsService,
    public dialogRef: MatDialogRef<AddStudentsModalComponent>,
    public toastService: AppToastManagerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customRoster: CustomRostersResponseDto;
      onClose: () => Promise<void>;
    }
  ) {
    super();
  }

  async ngOnDestroy() {
    await this.data.onClose();
  }

  public get displayedColumns() {
    return Object.values(AddStudentColumnsEnum);
  }

  public columnValues = {
    [AddStudentColumnsEnum.STUDENT_NAME]: ['Student Name'],
    [AddStudentColumnsEnum.STUDENT_ID]: ['Student ID'],
    [AddStudentColumnsEnum.GRADE]: ['Grade'],
    [AddStudentColumnsEnum.ACTION]: ['Add']
  };

  async ngOnInit() {
    this.isLoading = true;
    this.customRoster = this.data.customRoster;
    const currentSchoolId = this.StorageManager.getCurrentSchoolId();
    if (!currentSchoolId) {
      this.toastManager.error('Please select a school first');
      this.dialogRef.close();
      this.isLoading = false;

      return;
    }

    this.filters = this.studentsService.getStudentsListDefaultFilters({});
    this.filters.customRosterId = this.customRoster.id;

    const studentResult = await this.studentsService.getStudentsList(
      this.filters
    );

    this.students = studentResult.results;

    this.filters.tableFilters.count = studentResult.options.totalItems;
    this.filters.tableFilters.pageLastIds = {
      [studentResult.options.page + 1]: studentResult.options.lastId
    };

    this.isLoading = false;
  }

  async addStudent(studentId: number) {
    this.isLoading = true;
    try {
      await this.customRosterService.updateCustomRoster(
        this.data.customRoster.id,
        new CustomRostersUpdateDto({ studentId })
      );
      this.studentsAdded.push(studentId);
      await this.updateStudentsList(this.filters);
    } catch (error) {
      this.toastManager.error('Failed to add student');
    }
    this.isLoading = false;
  }

  async handleClose() {
    await this.data.onClose();
    this.dialogRef.close();
  }

  public async updateFilters(newFilters: IStudentsListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.STUDENTS_LIST,
      this.filters.tableFilters
    );
    await this.updateStudentsList(this.filters);
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    const pageLastIds = this.filters.tableFilters.pageLastIds;
    const page = this.filters.tableFilters.page;
    this.filters.tableFilters.lastId = pageLastIds ? pageLastIds[page] || 0 : 0;
    await this.updateStudentsList(this.filters);
    this.isLoading = false;
  }

  public async updateStudentsList(
    filters: IStudentsListFilters
  ): Promise<void> {
    try {
      const newData = await this.studentsService.getStudentsList(filters);
      this.students = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
      if (this.filters.tableFilters.pageLastIds) {
        this.filters.tableFilters.pageLastIds[newData.options.page + 1] =
          newData.options.lastId;
      }
    } catch (err) {
      this.toastService.error('Error retrieving students!');
      logger.error(err);
    }
  }

  protected readonly columns = AddStudentColumnsEnum;
  protected readonly DateUtil = DateUtil;
}
