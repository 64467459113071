import { NgModule } from '@angular/core';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { AppMaterialModule } from '../../app-material.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { AppBehaviorCodeEntryModule } from '../behavior-code-entry/behavior-code-entry.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AppStudentCardModule } from '../../shared/student-card/student-card.module';
import { AppTeacherRostersListComponent } from './teacher-rosters-list/teacher-rosters-list.component';
import { AppTeacherRostersListCard } from './teacher-rosters-list/teacher-rosters-list-card/teacher-rosters-list-card.component';
import { AppTeacherRostersListFilters } from './teacher-rosters-list/teacher-rosters-list-filters/teacher-rosters-list-filters.component';
import { AppMenuComponent } from '../../shared/menu/menu.component';

@NgModule({
  declarations: [
    AppTeacherRostersListComponent,
    AppTeacherRostersListCard,
    AppTeacherRostersListFilters
  ],
  exports: [AppTeacherRostersListComponent],
  imports: [
    AppErrorDisplayComponent,
    AppMaterialModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    AppPaginationModule,
    FontAwesomeModule,
    DirectiveModule,
    FormsModule,
    AppBehaviorCodeEntryModule,
    AppStudentCardModule,
    NgApexchartsModule,
    NgOptimizedImage,
    AppMenuComponent
  ]
})
export class AppTeacherRostersModule {}
