import { Injectable, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppSettingsService } from 'src/app/shared/services/settings.service';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { AppWindowService } from 'src/app/shared/services/app-window.service';
import { BaseService } from 'src/app/shared/base-classes/base.service';
import { AppLoadingBarService } from 'src/app/shared/services/loading-bar.service';
import { AppHomeService } from '../../pages/home/home.service';
import { AppUsersServices } from '../../pages/users/users.service';
import { NavbarActions } from './navbar-models/navbar-actions.enum';
import { AppNavbarService } from './navbar.service';
import { MatDialog } from '@angular/material/dialog';
import { AppSessionHandlerService } from '../../pages/auth/session-handler.service';
import { PrinterService } from '../printer/printer.service';

/**
 * Service used to handle navbar actions
 */
@Injectable({
  providedIn: 'root',
})
export class AppNavbarActionsService extends BaseService {
  public constructor(
    public dialog: MatDialog,
    public navbarService: AppNavbarService,
    public window: AppWindowService,
    public loadingBar: AppLoadingBarService,
    public toastManager: AppToastManagerService,
    public sessionHandlerService: AppSessionHandlerService,
    public homeService: AppHomeService,
    public settingsService: AppSettingsService,
    public usersService: AppUsersServices,
    public printerService: PrinterService,
  ) {
    super({ settingsService });
  }

  /**
   * Handle all of the actions from our navbar buttons
   */
  public handleNavbarActions(): Subscription {
    return this.navbarService.navbarActionPressed.subscribe(
      (action: NavbarActions) => {
        switch (action) {
          case NavbarActions.GO_HOME:
            return this.goHome();
          case NavbarActions.LOGOUT:
            return this.sessionHandlerService.handleLogout();
          case NavbarActions.SELECT_USB_DEVICE:
            return this.printerService.connectUsbDeviceDialog();
          case NavbarActions.OPEN_LANGUAGES:
            return this.openTranslations();
        }
      }
    );
  }

  /**
   * Send our user to their home page and close the navbar
   */
  public goHome(): void {
    void this.homeService.goHome();
    this.navbarService.closeNavbar();
  }

  public openTranslations(): void {
    const translateElement = document.querySelector('.VIpgJd-ZVi9od-xl07Ob-lTBxed') as HTMLSelectElement;

    if (translateElement) {
      translateElement.removeAttribute('href');
      translateElement.click();
    }
  }
}
