<div class="table-container mat-elevation-z2">
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-top">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
  <div class="mat-table">
    <mat-table
      class="interventions-list-table"
      [dataSource]="dataSource"
      matSort
      matSortDisableClear>
      <!-- Name Column -->
      <ng-container matColumnDef="{{columns.NAME}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.NAME}}
        </mat-header-cell>
        <mat-cell *matCellDef="let intervention">
          {{ intervention.name }}
        </mat-cell>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="{{columns.DESCRIPTION}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columns.DESCRIPTION}}
        </mat-header-cell>
        <mat-cell *matCellDef="let intervention">
          {{ intervention.description }}
        </mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="{{columns.ACTIONS}}">
        <mat-header-cell
          class="action-column-header"
          *matHeaderCellDef
          mat-sort-header>
          {{columns.ACTIONS}}
        </mat-header-cell>
        <mat-cell class="action-column-header" *matCellDef="let intervention">
          <button
            mat-flat-button
            class="action-button icon-button"
            [routerLink]="'/interventions/' + intervention?.id?.toString()"
            options-id="EDIT_INTERVENTION"
            [option]="intervention?.id?.toString()">
            <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
          </button>
          <button
            mat-flat-button
            class="action-button icon-button"
            (click)="clone(intervention)"
            options-id="CLONE_INTERVENTION"
            [option]="intervention?.id?.toString()">
            <fa-icon [icon]="['fas', 'clone']"></fa-icon>
          </button>
          <button
            *ngIf="intervention.active"
            mat-flat-button
            class="action-button icon-button"
            (click)="toggleActive(intervention, false)"
            options-id="DELETE_INTERVENTION"
            [option]="intervention?.id?.toString()">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
          <button
            *ngIf="!intervention.active"
            mat-flat-button
            class="action-button icon-button"
            (click)="toggleActive(intervention, true)">
            <fa-icon [icon]="['fas', 'rotate']"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
      <mat-row *matRowDef="let reaction; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
  <div
    *ngIf="tableFilters.count > tableFilters.itemsPerPage"
    class="table-filters-bottom">
    <app-pagination
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"></app-pagination>
  </div>
</div>
