import { Injectable } from '@angular/core';
import { AppNotificationService } from '../notification.service';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { NotificationTemplateResponseDto } from '@whetstoneeducation/hero-common';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppNotificationTemplateResolver extends BaseResolver<NotificationTemplateResponseDto> {
  constructor(
    private notificationService: AppNotificationService,
    resolverErrorHandler: AppResolverErrorHandlerService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<NotificationTemplateResponseDto> {
    const id = route.params.id;
    if (id === 'new') {
      return new NotificationTemplateResponseDto({
        isGlobal: false,
        isSchoolGroupLevel: false
      });
    }
    return this.notificationService.getTemplateById(id);
  }
}
