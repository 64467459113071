import { Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { ReactionResponseDto } from '@whetstoneeducation/hero-common';
import { IReactionsListFilters } from './reactions-list-models/reactions-list-filters.interface';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute } from '@angular/router';
import { AppReactionsService } from '../reactions.service';
import { IReactionsListResolverData } from './reactions-list-models/reactions-list-resolver-data.interface';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { logger } from '../../../shared/logger';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';

@Component({
  selector: 'app-reactions-list',
  templateUrl: './reactions-list.template.html',
  styleUrls: ['./reactions-list.scss'],
})
export class AppReactionsListComponent extends BaseComponent {
  public reactions: ReactionResponseDto[];
  public filters: IReactionsListFilters;
  public hasSelectedSchool: boolean;
  public behaviorCodeId: number;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public reactionsService: AppReactionsService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as IReactionsListResolverData;
    this.behaviorCodeId = this.route.snapshot.params.id;
    this.reactions = data.reactions.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.reactions.options.totalItems;
    this.hasSelectedSchool = !!this.filters.schoolId;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateReactionsList(this.filters);
    this.isLoading = false;
  }

  public async updateFilters(newFilters: IReactionsListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.REACTIONS_LIST,
      this.filters.tableFilters
    );
    await this.updateReactionsList(this.filters);
    this.isLoading = false;
  }

  public async updateReactionsList(
    filters: IReactionsListFilters
  ): Promise<void> {
    try {
      const newData = await this.reactionsService.getReactionsList(filters);
      this.reactions = newData.results;
      this.filters.tableFilters.count = newData.options.totalItems;
    } catch (err) {
      this.toastService.error('Error retrieving reactions!');
      logger.error(err);
    }
  }

  public async reloadTable(): Promise<void> {
    this.isLoading = true;
    await this.updateReactionsList(this.filters);
    this.isLoading = false;
  }
}
