import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * Service used to deal with routes query/path params, updating, and getting
 */
@Injectable({
  providedIn: 'root',
})
export class AppUrlParamsService {
  public routeParams: Params;

  /**
   * Default Constructor
   */
  constructor(private router: Router, private route: ActivatedRoute) {
    this.subscribeToRouterParams();
  }

  /**
   * Method called to update current routes query params
   * @param queryParams New Query Params
   * @param replaceUrl Should replace the url instead of adding new url to history
   * @param queryParamsHandling How should we be handling our query params
   */
  public setQueryParams(
    queryParams: any,
    replaceUrl = true,
    queryParamsHandling: 'merge' | 'preserve' | '' = ''
  ) {
    try {
      this.router.navigate([], {
        queryParams,
        replaceUrl,
        queryParamsHandling,
      });
    } catch (error) {}
  }

  /**
   * Returns the current routes query params
   */
  public getQueryParams(): Params {
    try {
      return this.router.parseUrl(this.router.url).queryParams;
    } catch (error) {}
  }

  public getPathParam(key: string) {
    try {
      const param = this.routeParams[key];
      if (param === undefined) {
        return null;
      }
      return param;
    } catch (err) {
      return null;
    }
  }

  private subscribeToRouterParams() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let active = this.route;
        while (active.firstChild) {
          active = active.firstChild;
        }
        active.params.subscribe((params: Params) => {
          this.routeParams = params;
        });
      });
  }
}
