import { PrinterConfigurationResponseDto } from '@whetstoneeducation/hero-common';
import { BuilderFactory } from '../../../shared/printer/builder.factory';
import { StudentInfoPrintBuilder } from './student-info-print-builder';
import { StudentInfoZplBuilder } from './student-info-print-zpl-builder';
import { StudentInfoReceiptBuilder } from './student-info-print-receipt-builder';
import { StudentInfoDymoBuilder } from './student-info-print-dymo-builder';
import { StudentInfoEzplBuilder } from './student-info-print-ezpl-builder';

const buildersMapper = {
  'star-prnt': () => new StudentInfoReceiptBuilder('star-prnt'),
  'zpl': () => new StudentInfoZplBuilder(),
  'ezpl': () => new StudentInfoEzplBuilder(),
  'esc-pos': () => new StudentInfoReceiptBuilder('esc-pos'),
  'dymo': () => new StudentInfoDymoBuilder(),
};

export class StudentInfoPrintBuilderFactory extends BuilderFactory<StudentInfoPrintBuilder> {

  constructor(printerData?: PrinterConfigurationResponseDto) {
    super(printerData);
    this.buildersMapper = buildersMapper;
  }
}
