"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateEnum = void 0;
var StateEnum;
(function (StateEnum) {
    StateEnum["AL"] = "AL";
    StateEnum["AK"] = "AK";
    StateEnum["AZ"] = "AZ";
    StateEnum["AR"] = "AR";
    StateEnum["CA"] = "CA";
    StateEnum["CO"] = "CO";
    StateEnum["CT"] = "CT";
    StateEnum["DE"] = "DE";
    StateEnum["DC"] = "DC";
    StateEnum["FL"] = "FL";
    StateEnum["GA"] = "GA";
    StateEnum["HI"] = "HI";
    StateEnum["ID"] = "ID";
    StateEnum["IL"] = "IL";
    StateEnum["IN"] = "IN";
    StateEnum["IA"] = "IA";
    StateEnum["KS"] = "KS";
    StateEnum["KY"] = "KY";
    StateEnum["LA"] = "LA";
    StateEnum["ME"] = "ME";
    StateEnum["MD"] = "MD";
    StateEnum["MA"] = "MA";
    StateEnum["MI"] = "MI";
    StateEnum["MN"] = "MN";
    StateEnum["MS"] = "MS";
    StateEnum["MO"] = "MO";
    StateEnum["MT"] = "MT";
    StateEnum["NE"] = "NE";
    StateEnum["NV"] = "NV";
    StateEnum["NH"] = "NH";
    StateEnum["NJ"] = "NJ";
    StateEnum["NM"] = "NM";
    StateEnum["NY"] = "NY";
    StateEnum["NC"] = "NC";
    StateEnum["ND"] = "ND";
    StateEnum["OH"] = "OH";
    StateEnum["OK"] = "OK";
    StateEnum["OR"] = "OR";
    StateEnum["PA"] = "PA";
    StateEnum["RI"] = "RI";
    StateEnum["SC"] = "SC";
    StateEnum["SD"] = "SD";
    StateEnum["TN"] = "TN";
    StateEnum["TX"] = "TX";
    StateEnum["UT"] = "UT";
    StateEnum["VT"] = "VT";
    StateEnum["VA"] = "VA";
    StateEnum["WA"] = "WA";
    StateEnum["WV"] = "WV";
    StateEnum["WI"] = "WI";
    StateEnum["WY"] = "WY";
})(StateEnum || (exports.StateEnum = StateEnum = {}));
