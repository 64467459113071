<div class="singleColumnLayout">
  <form [formGroup]="setUpYearForm" novalidate>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Set Up Academic Year</mat-card-title>
      </mat-card-header>
      <mat-card-content class="mat-elevation-z3">
        <mat-form-field class="full-width-input">
          <mat-label>Start Date</mat-label>
          <input
            formControlName="startDate"
            matInput
            [matDatepicker]="startDatePicker"
            type="text" />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <app-error-display
            [form]="setUpYearForm"
            controlName="startDate"></app-error-display>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <mat-label>End Date</mat-label>
          <input
            formControlName="endDate"
            matInput
            [matDatepicker]="endDatePicker"
            type="text" />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <app-error-display
            [form]="setUpYearForm"
            controlName="endDate"></app-error-display>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </form>
</div>
