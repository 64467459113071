import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import {
  PaginationResultsDto,
  StudentBasicResponseDto
} from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppStudentsService } from '../students.service';
import { IStudentsListFilters } from '../students-list-models/students-list-filters.interface';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { IStudentListResolverData } from '../students-list-models/student-list-resolver-data.interface';

@Injectable({ providedIn: 'root' })
export class AppStudentsListResolver extends BaseResolver<IStudentListResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private studentsService: AppStudentsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<IStudentListResolverData> {
    const defaultFilters: IStudentsListFilters =
      this.studentsService.getStudentsListDefaultFilters(route.queryParams);

    const data =
      await this.studentsService.getStudentListResolverData(defaultFilters);
    if (!data.validCalendar) {
      await this.router.navigate(['/calendar']);
      return null;
    }
    return data;
  }
}
