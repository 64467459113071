import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderButtonAction, IHeaderButton } from '../header-button';
import { BaseComponent } from '../../base-classes/base.component';

/**
 * The PageHeaderButton Component
 */
@Component({
  selector: 'app-page-header-button',
  templateUrl: './page-header-button.template.html',
  styleUrls: ['./page-header-button.scss']
})
export class AppPageHeaderButtonComponent extends BaseComponent {
  @Input()
  public button: IHeaderButton;

  @Output()
  public emitHeaderEvent = new EventEmitter<HeaderButtonAction>();

  constructor() {
    super();
  }

  public hasIconPrefix(button: IHeaderButton): boolean {
    return button.icon && Array.isArray(button.icon);
  }
}
