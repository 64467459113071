export enum UsersListColumns {
  ID = 'ID',
  EMAIL = 'Email',
  LAST = 'Last',
  FIRST = 'First',
  SCHOOL = 'School',
  SCHOOL_GROUP = 'School Group',
  ROLE = 'Roles',
  INSTRUCTOR_CODE = 'Instructor Code',
  ACTIONS = 'Actions',
}
