import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../../../shared/base-classes/base.component';
import {
  BellSchedulePeriodCreateDto,
  BellSchedulePeriodResponseDto,
  BellSchedulePeriodUpdateDto
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppToastManagerService } from '../../../../../shared/services/toast-manager.service';
import { AppPageHeaderService } from '../../../../../shared/page-header/page-header.service';
import { AppBellSchedulesService } from '../../../bell-schedules.service';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../../../shared/validation/validation.util';
import { HeaderButtonAction } from '../../../../../shared/page-header/header-button';
import { logger } from '../../../../../shared/logger';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bell-schedule-period-create',
  templateUrl: './bell-schedule-period-create.template.html',
  styleUrls: ['./bell-schedule-period-create.scss']
})
export class AppBellSchedulePeriodCreateComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public period: BellSchedulePeriodCreateDto | BellSchedulePeriodUpdateDto;
  public periodForm: FormGroup;
  public isCreating: boolean;
  private destroy$ = new Subject<void>();
  public id: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    private pageHeaderService: AppPageHeaderService,
    private bellScheduleService: AppBellSchedulesService,
    private dialogRef: MatDialogRef<AppBellSchedulePeriodCreateComponent>,
    @Inject(MAT_DIALOG_DATA) data: { period: BellSchedulePeriodResponseDto }
  ) {
    super();
    this.loadData(data);
  }

  public loadData(data: { period: BellSchedulePeriodResponseDto }) {
    if (data && data.period) {
      this.id = data.period.id;
    }
    if (!this.id) {
      this.period = new BellSchedulePeriodCreateDto().mapFields(data.period);
    } else {
      this.period = new BellSchedulePeriodUpdateDto().mapFields(data.period);
    }
    this.isCreating = !this.id;
    this.periodForm = dtoToFormGroup(this.period, this.formBuilder);
  }

  public ngAfterViewInit() {
    this.periodForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (this.isCreating) {
          this.period = await validateAndGetValue<BellSchedulePeriodCreateDto>(
            this.periodForm,
            BellSchedulePeriodCreateDto
          );
        } else {
          this.period = await validateAndGetValue<BellSchedulePeriodUpdateDto>(
            this.periodForm,
            BellSchedulePeriodUpdateDto
          );
        }
      });

    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: HeaderButtonAction) => {
        if (action === HeaderButtonAction.SAVE) {
          await this.saveChanges();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async saveChanges() {
    if (formCanSave(this.periodForm, this.toastManager)) {
      let response = new BellSchedulePeriodResponseDto().mapFields(this.period);
      try {
        if (this.period instanceof BellSchedulePeriodCreateDto) {
          response = (await this.bellScheduleService.createPeriod(
            this.period
          )) as BellSchedulePeriodResponseDto;
          this.toastManager.success('Period created successfully!');
        } else {
          response = await this.bellScheduleService.updatePeriod(
            this.id,
            this.period
          );
          this.toastManager.success('Period updated successfully!');
        }
        this.dialogRef.close({ period: response, id: response.id });
      } catch (err) {
        logger.error(err);
        if (err.status && err.status === 409) {
          this.toastManager.error(err.error.message);
        } else {
          this.toastManager.error(
            'There was an error saving Period, please try again!'
          );
        }
      }
    }
  }

  public async onSave() {
    await this.saveChanges();
  }

  public async onClose() {
    this.dialogRef.close();
  }
}
