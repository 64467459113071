import { ResolveFn } from '@angular/router';
import {
  BehaviorEntryDailyActivityReportResponseDto,
  IDisplayData,
  PaginationResultsDto
} from '@whetstoneeducation/hero-common';
import { inject } from '@angular/core';
import { AppReportsService } from '../reports.service';

export interface IDailyActivityReportResolverData {
  defaultFilters: any;
  behaviorEntries: PaginationResultsDto<BehaviorEntryDailyActivityReportResponseDto>;
  userOptions: IDisplayData[];
  behaviorCodeOptions: IDisplayData[];
}

export const dailyActivityReportResolver: ResolveFn<
  IDailyActivityReportResolverData
> = async (route, state) => {
  return inject(
    AppReportsService
  ).getBehaviorEntryDailyActivityReportResolverData();
};
