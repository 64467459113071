import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { PeriodListTableColumns } from '../../bell-schedules-models/period-list-table-columns.enum';
import { BellSchedulePeriodTimeResponseDto } from '@whetstoneeducation/hero-common';
import dayjs from 'dayjs';
import { AppBellSchedulePeriodTimeComponent } from '../../bell-schedule-period-time-dialog/bell-schedule-period-time.dialog';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AppBellSchedulesService } from '../../bell-schedules.service';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-periods-list-table',
  templateUrl: './periods-list-table.template.html',
  styleUrls: ['./periods-list-table.scss']
})
export class AppPeriodsListTableComponent
  extends BaseTableComponent<BellSchedulePeriodTimeResponseDto>
  implements OnDestroy
{
  //event when a period is deleted
  @Output() public periodDeleted =
    new EventEmitter<BellSchedulePeriodTimeResponseDto>();

  private destroy$ = new Subject<void>();

  public get displayedColumns() {
    return Object.values(PeriodListTableColumns);
  }

  public columnValues = {
    [PeriodListTableColumns.PERIOD_NAME]: ['bellSchedulePeriod', 'name'],
    [PeriodListTableColumns.START_TIME]: ['start'],
    [PeriodListTableColumns.END_TIME]: ['end'],
    [PeriodListTableColumns.REF_CODE]: ['bellSchedulePeriod', 'referenceCode'],
    [PeriodListTableColumns.EXT_CODE]: ['bellSchedulePeriod', 'extPeriodCode']
  };

  public columns = PeriodListTableColumns;

  constructor(
    private dialog: MatDialog,
    private bellSchedulesService: AppBellSchedulesService,
    private confirmationDialog: AppConfirmationDialogService
  ) {
    super();
    this.tableFilters = {
      count: 0,
      itemsPerPage: 0,
      page: 0,
      active: PeriodListTableColumns.START_TIME,
      direction: 'asc'
    };
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async onEdit(row: BellSchedulePeriodTimeResponseDto): Promise<void> {
    // get available periods
    let periods = await this.bellSchedulesService.getPeriodsForSchool(
      this.StorageManager.getCurrentSchoolId()
    );
    // filter out the periods already in the bell schedule
    periods = periods.filter(
      (period) =>
        !this.dataArr.find((row) => row.bellSchedulePeriodId === period.id)
    );
    // open dialog to edit the period
    const dialogRef = this.dialog.open(AppBellSchedulePeriodTimeComponent, {
      data: {
        periods: periods,
        periodTime: row,
        bellScheduleId: row.bellScheduleId
      },
      width: '600px',
      height: '400px'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result) => {
        if (result) {
          // update the period
          const periodTime =
            await this.bellSchedulesService.updateBellSchedulePeriodTime(
              result.id,
              result.periodTime
            );

          // find the period in the list and update it
          const index = this.dataArr.findIndex(
            (period) => period.id === result.id
          );
          this.dataArr[index] = periodTime;
          this.dataArr = [...this.dataArr];
        }
      });
  }

  public async onDelete(row: BellSchedulePeriodTimeResponseDto): Promise<void> {
    // open dialog to confirm deletion
    const ref = this.confirmationDialog.openDialog({
      title: 'Delete Period',
      content: `Are you sure you want to delete the period ${row.bellSchedulePeriod.name}?`,
      width: '600px'
    });

    // handle dialog close
    ref
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (result) => {
        if (result === DEFAULT_DIALOG_RESULT.YES) {
          // delete the period
          await this.bellSchedulesService.deleteBellSchedulePeriodTime(row.id);
          // remove the period from the list
          this.dataArr = this.dataArr.filter(
            (period) =>
              period.bellSchedulePeriod.referenceCode !==
              row.bellSchedulePeriod.referenceCode
          );
          // emit event
          this.periodDeleted.emit(row);
        }
      });
  }

  // format time from 24h like 14:30:00 to 12h like 2:30 PM in the users timezone
  public formatTime(time: string): string {
    const timezone = this.StorageManager.getLoggedInUserTimezone();

    // Combine the current date with the provided time string
    const currentDate = dayjs().format('YYYY-MM-DD');
    const fullDateTime = `${currentDate} ${time}`;

    return dayjs.tz(fullDateTime, timezone).format('h:mm A');
  }
}
