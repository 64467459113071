import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  ReactionCreateDto,
  IDisplayData,
  ReactionUpdateDto
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { plainToInstance } from 'class-transformer';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { logger } from '../../../shared/logger';
import { AppReactionsService } from '../reactions.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';

@Component({
  selector: 'app-reaction-create',
  templateUrl: './reactions-create.template.html',
  styleUrls: ['./reactions-create.scss']
})
export class AppReactionsCreateComponent
  extends BaseComponent
  implements AfterViewInit
{
  public reaction: ReactionCreateDto | ReactionUpdateDto;
  public reactionForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public daysOptions: IDisplayData[];
  public reactionId: number;
  public behaviorCodeId: number;
  public pageHeaderSubscription: Subscription;
  public dailyCapacityRequired = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public reactionsService: AppReactionsService,
    private pageHeaderService: AppPageHeaderService
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    const reaction = this.route.snapshot.data.data;
    this.reactionId = +this.route.snapshot.params.reactionId;
    this.behaviorCodeId = +this.route.snapshot.params.id;

    this.reaction = plainToInstance(
      ReactionCreateDto,
      { ...reaction },
      {
        exposeUnsetFields: true
      }
    );

    this.reactionForm = dtoToFormGroup(this.reaction, this.formBuilder, {
      mapId: false
    });
    this.setDaysOptions();
    this.isLoading = false;
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.reactionForm.valueChanges.subscribe(async (value) => {
        this.reaction = await validateAndGetValue<ReactionCreateDto>(
          this.reactionForm,
          ReactionCreateDto
        );
      })
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public async saveChanges(): Promise<void> {
    if (formCanSave(this.reactionForm, this.toastManager)) {
      try {
        if (this.reaction instanceof ReactionCreateDto) {
          if (
            this.reaction.dailyCapacity === 0 ||
            this.dailyCapacityRequired === false
          ) {
            this.reaction.dailyCapacity = null;
          }

          const reaction = await this.reactionsService.createReaction(
            this.reaction
          );
          this.reaction = plainToInstance(ReactionUpdateDto, reaction);
          await this.router.navigate(['reactions']);
        } else {
          const response = await this.reactionsService.updateReaction(
            this.reaction,
            this.reactionId
          );
          this.reaction = new ReactionUpdateDto();
          this.reaction.mapFields(response);
        }
        this.toastManager.success('Reaction saved successfully!');
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving Reaction, please try again!'
        );
      }
    }
  }

  public setDaysOptions(): void {
    this.daysOptions = [
      { display: 'Sunday', value: 1 },
      { display: 'Monday', value: 2 },
      { display: 'Tuesday', value: 3 },
      { display: 'Wednesday', value: 4 },
      { display: 'Thursday', value: 5 },
      { display: 'Friday', value: 6 },
      { display: 'Saturday', value: 7 }
    ];
  }

  public toggleDailyCapacityRequired(): void {
    this.dailyCapacityRequired = !this.dailyCapacityRequired;
    this.reactionForm.markAsTouched();
  }
}
