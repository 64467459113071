import { AppHomeComponent } from './pages/home/home.component';
import { AppMySettingsResolver } from './pages/my-settings/my-settings.resolver';
import { AuthGuard } from './route-guards/auth.guard';
import { IRouteData } from './shared/route-data.interface';
import { Injectable, NgModule } from '@angular/core';
import {
  Route,
  RouterModule,
  RouterStateSnapshot,
  TitleStrategy
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppSchoolGroupRoutes } from './pages/school-groups/school-group-routes';
import { AppRolesRoutes } from './pages/roles/roles.routes';
import { AppBehaviorCodesRoutes } from './pages/behavior-codes/behavior-codes.routes';
import { AppCalendarDayRoutes } from './pages/calendar/calendar.routes';
import { AppReactionsRoutes } from './pages/reactions/reactions.routes';
import { AppInterventionsRoutes } from './pages/interventions/interventions.routes';
import { AppBellSchedulesRoutes } from './pages/bell-schedules/bell-schedules.routes';
import { AppStudentsRoutes } from './pages/students/students.routes';
import { AppImportRoutes } from './pages/imports/import.routes';
import { AppExportRoutes } from './pages/exports/export.routes';
import { AppReportRoutes } from './pages/reports/report.routes';
import { AppDashboardRoutes } from './pages/dashboards/dashboard.routes';
import { AppComplianceRoutes } from './pages/compliance/compliance.routes';
import { AppBehaviorEntryRoutes } from './pages/behavior-code-entry/behavior-code-entry-routes';
import { AppNotificationRoutes } from './pages/notifications/notification.routes';
import { AppMySettingsRoutes } from './pages/my-settings/my-settings.routes';
import { AppSchoolsRoutes } from './pages/schools/schools.routes';
import { AppUsersRoutes } from './pages/users/users.routes';
import { AppAuthRoutes } from './pages/auth/auth.routes';
import { AppStudentGroupsRoutes } from './pages/student-groups/student-groups.routes';
import { AppTeachersRostersRoutes } from './pages/teacher-rosters/teacher-rosters.routes';
import { AppLastImportsExportsRoutes } from './pages/imports-exports/last-imports-exports.routes';
import { AppCustomRostersRoutes } from './pages/custom-rosters/custom-rosters.routes';
import { AppIncidentRoutes } from './pages/incident/incident.routes';

export interface ICustomRoute extends Route {
  title?: string;
  data?: IRouteData;
  children?: ICustomRoute[];
}

const routes: ICustomRoute[] = [
  ...AppAuthRoutes,
  ...AppMySettingsRoutes,
  ...AppRolesRoutes,
  ...AppSchoolsRoutes,
  ...AppSchoolGroupRoutes,
  ...AppUsersRoutes,
  ...AppBehaviorCodesRoutes,
  ...AppCalendarDayRoutes,
  ...AppReactionsRoutes,
  ...AppInterventionsRoutes,
  ...AppBellSchedulesRoutes,
  ...AppReportRoutes,
  ...AppDashboardRoutes,
  ...AppStudentsRoutes,
  ...AppImportRoutes,
  ...AppComplianceRoutes,
  ...AppBehaviorEntryRoutes,
  ...AppNotificationRoutes,
  ...AppStudentGroupsRoutes,
  ...AppTeachersRostersRoutes,
  ...AppExportRoutes,
  ...AppLastImportsExportsRoutes,
  ...AppCustomRostersRoutes,
  ...AppIncidentRoutes,
  {
    path: '**',
    component: AppHomeComponent,
    canActivate: [AuthGuard],
    title: 'Home'
  }
];

/**
 * Overrides Angular's built-in title strategy to add custom prefix to page title.
 */
@Injectable()
export class CustomPageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`Hero | ${title}`);
    }
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    AppMySettingsResolver,
    {
      provide: TitleStrategy,
      useClass: CustomPageTitleStrategy
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
