import { Injectable } from "@angular/core";
import { PrinterService } from "../../../shared/printer/printer.service";
import { ReactionEntriesListResponseDto } from "@whetstoneeducation/hero-common";
import { BasePrintService } from "../../../shared/printer/base-print.service";
import { ReactionPrintBuilder } from "./behavior-code-reaction-print-builder";
import { BehaviorCodeReactionPrintBuilderFactory } from "./behavior-code-reaction-print-builder.factory";
import ThermalPrinterEncoder from 'thermal-printer-encoder';


@Injectable({
  providedIn: 'root'
})
export class ReactionPrintService extends BasePrintService<ReactionPrintBuilder, ReactionEntriesListResponseDto> {

  constructor(
    private printerService: PrinterService
  ) {
    super({
      printer: printerService,
      factory: new BehaviorCodeReactionPrintBuilderFactory(),
    });
  }

  protected getBuildPrintData(): ReactionPrintBuilder {
    return this.builder
      .buildHeader()
      .buildSubHeader()
      .buildStudentIdEntries()
      .buildStudentName()
      .buildEntryDateTime()
      .buildPeriod()
      .buildActionTypeDate()
      .buildPoints()
      .buildBarcode();
  }
}
