export const putRoutes = {
  UPDATE_USER: '/users/:id',
  REACTIVATE_USER: '/users/:id/reactivate',
  UPDATE_SCHOOL: '/schools/:id',
  UPDATE_SCHOOL_GROUP: '/school-groups/:id',
  UPDATE_ROLE: '/roles/:id',
  UPDATE_BEHAVIOR_CODE: '/behavior-codes/:id',
  UPDATE_BEHAVIOR_CODE_ENTRY: '/behavior-code-entries/:id',
  UPDATE_BEHAVIOR_CODE_ENTRY_NOTES: '/behavior-code-entries/update-notes/:id',
  UPDATE_BEHAVIOR_CODE_REACTION: '/behavior-code-reactions/:id',
  UPDATE_BEHAVIOR_CODE_INTERVENTION: '/behavior-code-interventions/:id',
  UPDATE_BEHAVIOR_CODE_NOTIFICATION: '/behavior-code-notifications/:id',
  UPDATE_BELL_SCHEDULE_PERIOD: '/bell-schedule-periods/:id',
  UPDATE_CALENDAR_DAY: '/calendar-days/:id',
  UPDATE_REACTION: '/reactions/:id',
  UPDATE_REACTION_ENTRY: '/reaction-entry/:id',
  UPDATE_STUDENT: '/students/:id',
  UPDATE_STUDENT_GROUP: '/student-groups/:id',
  UPDATE_SUBSCRIPTION: '/subscriptions/:schoolId',
  UPDATE_SCHEDULE: '/calendar-days/schedule',
  UPDATE_NOTIFICATION_TEMPLATE: '/notification/templates/:id',
  UPDATE_NOTIFICATION_SETTINGS: '/notification/settings/:id',
  UPDATE_STUDENT_GROUP_BELL_SCHEDULE_CALENDAR_DAYS:
    '/student-group-bell-schedule-calendar-days/batch'
};
export type PutRoutes = typeof putRoutes;
