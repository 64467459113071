"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateVariableMapping = void 0;
const dtos_1 = require("../dtos");
exports.TemplateVariableMapping = {
    [dtos_1.NotificationType.STUDENT_BULK_PHOTO_UPLOAD]: [
        'school_name',
        'imagesAdded',
        'imagesFound',
        'filesFound',
        'invalidFilesFormat',
        'studentsNotFound',
        'readErrors',
        'failedCounter'
    ],
    [dtos_1.NotificationType.BEHAVIOR_TRACKING]: [
        'schoolGroup_name',
        'school_name',
        'firstName',
        'lastName',
        'historyReportTable'
    ],
    [dtos_1.NotificationType.IMPORT_NOTIFICATION]: ['schoolGroup_name', 'school_name'],
    [dtos_1.NotificationType.PASSWORD_RESET]: [
        'firstName',
        'lastName',
        'email',
        'schoolGroup_name',
        'school_name',
        'link'
    ],
    [dtos_1.NotificationType.USER_INVITE]: [
        'firstName',
        'lastName',
        'email',
        'schoolGroup_name',
        'school_name',
        'link'
    ],
    [dtos_1.NotificationType.GUARDIAN_INVITE]: [
        'student_firstName',
        'student_lastName',
        'email',
        'schoolGroup_name',
        'school_name',
        'link'
    ],
    [dtos_1.NotificationType.SECURE_DOWNLOAD]: [
        'schoolGroup_name',
        'school_name',
        'fileDescription',
        'link'
    ]
};
