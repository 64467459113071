import {
  Component,
} from '@angular/core';

import { BaseComponent } from '../../shared/base-classes/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-last-imports-exports',
  templateUrl: './last-imports-exports.template.html',
  styleUrls: ['./last-imports-exports.scss']
})
export class AppLastImportsExportsComponent extends BaseComponent
{
  public constructor(
    public router: Router,
  ) {
    super();
  }

  public loadData(): void {
    this.isLoading = true;

    this.isLoading = false;
  }

  public clickAddNewImport(): void {
    this.router.navigate(['sftp', 'settings', 'new']);
  }

  public clickAddNewExport(): void {
    this.router.navigate(['export', 'settings', 'new']);
  }
}
