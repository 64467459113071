import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CalendarDayResponseDto,
  DateConverter,
  DateFormats,
} from '@whetstoneeducation/hero-common';
import { AppPrivilegesService } from 'src/app/pages/auth/privileges.service';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { BaseTableComponent } from 'src/app/shared/tables/base-table/base-table';
import { AppCalendarService } from '../../calendar.service';
import { CalendarViewColumns } from '../calendar-view-models/calendar-view-columns.enum';

@Component({
  selector: 'app-calendar-view-table',
  templateUrl: './calendar-view-table.template.html',
  styleUrls: ['./calendar-view-table.scss'],
})
export class AppCalendarViewTableComponent
  extends BaseTableComponent<CalendarDayResponseDto>
  implements OnInit
{
  public get displayedColumns() {
    let columns = Object.values(CalendarViewColumns);
    return columns;
  }

  public showPagination: boolean;

  public columns = CalendarViewColumns;

  public columnValues = {
    [CalendarViewColumns.DATE]: ['date'],
    [CalendarViewColumns.NAME]: ['name'],
    [CalendarViewColumns.NOTES]: ['notes'],
  };

  constructor(
    public privilegesService: AppPrivilegesService,
    public toastManager: AppToastManagerService,
    public calendarService: AppCalendarService,
    public router: Router
  ) {
    super();
  }

  public ngOnInit() {
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
  }
}
