import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AppStudentsService } from '../../../students/students.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import { SchoolWideReportFiltersDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-schoolwide-report-filters',
  templateUrl: './schoolwide-report-filters.template.html',
  styleUrls: ['./schoolwide-report-filters.scss']
})
export class AppSchoolwideReportFilters extends BaseComponent implements OnInit {
  @Input()
  public filters: SchoolWideReportFiltersDto;
  @Output()
  private filtersUpdated: EventEmitter<SchoolWideReportFiltersDto> = new EventEmitter<SchoolWideReportFiltersDto>();
  @Output()
  private applyFilters: EventEmitter<void> = new EventEmitter();
  @Input()
  public loadingItems: boolean;
  public isLoading: boolean = true;
  public studentsGrades: number[] = [];

  public constructor(
    private readonly studentsService: AppStudentsService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    const { currentSchoolId: schoolId } = this.StorageManager.getLoggedInUser();
    this.studentsGrades = await this.studentsService.getStudentGrades({ schoolId });

    this.isLoading = false;
  }

  public onFilterUpdated(field: string, data?: MatSelectChange | number): void {
    const value = data instanceof MatSelectChange ? data.value : data;
    this.filters[field] = value || undefined;
    this.filtersUpdated.emit(this.filters);
  }

  /**
   * Sets the hours to end of the day if it is endDate and to start of the day if it is startDate
   * If the value is null, then the date is null and no hours are set
   * @param field can be either startDate or endDate
   * @param event
   */
  public onDateUpdated(field: string, event: MatDatepickerInputEvent<Date>): void {
    const value = event.target.value;
    const date = !value ? null
    : field === 'endDate'
    ? value.setHours(23, 59, 59, 999)
    : value.setHours(0, 0, 0, 0);

    this.filters[field] = date ? value.getTime() : null;
    this.filtersUpdated.emit(this.filters);
  }

  public onApplyFilters(): void {
    this.applyFilters.emit();
  }
}
