import { Component, Input } from "@angular/core";
import { StudentProfileResponseDto } from "@whetstoneeducation/hero-common";

@Component({
  selector: 'app-student-profile-info',
  templateUrl: './student-profile-info.template.html',
  styleUrls: ['./student-profile-info.scss'],
})
export class StudentProfileInfoComponent {

  @Input()
  public profile: StudentProfileResponseDto;

  public getStudentDetailedAddress() {
    return `${this.profile.studentDetail.city || 'N/A'}, ${this.profile.studentDetail.state || 'N/A'}, ${this.profile.studentDetail.zip || 'N/A'}`
  }

}
