import { Component, OnInit } from '@angular/core';
import { BaseTableComponent } from '../../../shared/tables/base-table/base-table';
import { BehaviorCodeReactionsTableColumnsEnum } from '../behavior-code-reactions-models/behavior-code-reactions-table-columns.enum';

@Component({
  selector: 'app-behavior-codes-reactions-table',
  templateUrl: './behavior-codes-reactions-table.template.html',
  styleUrls: ['./behavior-codes-reactions-table.scss']
})
export class AppBehaviorCodesReactionsTableComponent
  extends BaseTableComponent<any>
  implements OnInit
{
  public get displayedColumns() {
    return Object.values(BehaviorCodeReactionsTableColumnsEnum);
  }

  public columnValues = {
    [BehaviorCodeReactionsTableColumnsEnum.NAME]: ['reaction', 'name'],
    [BehaviorCodeReactionsTableColumnsEnum.POINTS]: ['points'],
    [BehaviorCodeReactionsTableColumnsEnum.TRIGGER_AT]: ['triggerCount'],
    [BehaviorCodeReactionsTableColumnsEnum.PRINT_COPIES]: ['printCopies'],
    [BehaviorCodeReactionsTableColumnsEnum.ACTION_REPEAT]: [
      'reactionMultiplier'
    ]
  };

  public columns = BehaviorCodeReactionsTableColumnsEnum;

  public showPagination: boolean;

  constructor() {
    super();
  }

  public ngOnInit() {
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
  }
}
