<div class="bell-schedules-list-content">
  <div class="no-school" *ngIf="!hasSelectedSchool">
    The Bell Schedules configuration page can only be accessed for an individual
    school. Please select one and try again!
  </div>
  <div class="content" *ngIf="hasSelectedSchool">
    <app-bell-schedules-list-table
      [dataArr]="bellSchedules"
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"
    ></app-bell-schedules-list-table>
  </div>
</div>
