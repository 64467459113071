<mat-card appearance="outlined" class="dashboard-container mat-elevation-z3">
  <mat-card-header>
    <mat-card-title>Top Performers</mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-container-content">
    <ng-container *ngFor="let student of topPerformers">
      <div class="student-performance-container">
        <div class="student-img">
          <app-student-profile-photo
            [selectedImage]="student.profilePhotoUrl"
            [studentId]="student.id"
            [hideProfileImageSelector]="true"
            [imageWidth]="30"
            [imageHeight]="30">
          </app-student-profile-photo>
        </div>
        <div class="student-performance-content">
          <div class="student-performance-name">
            {{ student.firstName }} {{ student.lastName }}
          </div>
          <div class="student-performance-points">
            {{ student.points ? student.points : 0 }}
          </div>
          <div class="student-performance-text">points</div>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
