import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ITableFilters } from '../table-filters/table-filters.interface';

/**
 * App Pagination Component for handling the pagination of various iterables in the app that can be displayed in data tables and such.
 */
@Component({
  selector: 'app-pagination',
  template: `
    <mat-paginator
      appCustomPagination
      *ngIf="tableFilters && tableFilters.count > tableFilters.itemsPerPage"
      class="app-pagination-component"
      [showFirstLastButtons]="true"
      [length]="tableFilters.count"
      [pageIndex]="tableFilters.page - 1"
      [pageSize]="tableFilters.itemsPerPage"
      [pageSizeOptions]="tableFilters.pageSizeOptions"
      (page)="pageChange($event)"></mat-paginator>
  `
})
export class AppPaginationComponent {
  /**
   * Filters
   */
  @Input()
  public tableFilters: ITableFilters;

  /**
   * When filters are updated, they get passed back to the parent.
   */
  @Output()
  public tableFiltersUpdated: EventEmitter<ITableFilters>;

  /**
   * Default Constructor
   */
  constructor() {
    this.tableFiltersUpdated = new EventEmitter<ITableFilters>();
  }

  /**
   * Page Change handler
   * @param pageEvent Event is dispatched from the component
   */
  public pageChange(pageEvent: PageEvent): void {
    const { previousPageIndex, pageIndex, pageSize } = pageEvent;

    const pageChanged = previousPageIndex !== pageIndex;
    const sizeChanged = pageSize !== Number(this.tableFilters.itemsPerPage);

    // If our page size changed, just update pageSize
    if (sizeChanged) {
      this.tableFilters.itemsPerPage = pageSize;
      this.tableFilters.page = 1;
      this.tableFiltersUpdated.emit(this.tableFilters);
    } else if (pageChanged) {
      // Otherwise, if we just changed pages, then just change skip
      this.tableFilters.page = pageIndex + 1;
      this.tableFiltersUpdated.emit(this.tableFilters);
    }
  }
}
