import { Component, Inject } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IOptionResponse,
  OptionsCreateDto,
  OptionsUpdateDto
} from '@whetstoneeducation/hero-common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { AppIncidentService } from '../../incident.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-option-create-edit-modal',
  templateUrl: './option-create-edit-modal.component.html',
  styleUrls: ['./option-create-edit-modal.component.scss']
})
export class AppOptionCreateEditModalComponent extends BaseComponent {
  public nameControl = new FormControl<string>(null, [
    Validators.minLength(1),
    Validators.maxLength(50)
  ]);

  constructor(
    public formBuilder: FormBuilder,
    public incidentService: AppIncidentService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      option?: IOptionResponse;
      categoryId: number;
      onClose: () => Promise<void>;
    },
    public dialogRef: MatDialogRef<AppOptionCreateEditModalComponent>,
    public toastService: AppToastManagerService
  ) {
    super();
    this.nameControl.setValue(data ? data?.option?.name : '');
  }

  public async submit() {
    this.isLoading = true;
    try {
      if (this.data?.option) {
        await this.incidentService.updateOption(
          this.data.option.id,
          new OptionsUpdateDto({
            name: this.nameControl.value
          })
        );
      } else {
        await this.incidentService.createOption(
          new OptionsCreateDto({
            name: this.nameControl.value,
            optionCategoryId: this.data.categoryId
          })
        );
      }
      await this.data.onClose();
      this.dialogRef.close();
    } catch (error) {
      this.toastService.error('Failed to save option. Please try again.');
    }

    this.isLoading = false;
  }
}
