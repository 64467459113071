<div class="list-filters-content">
  <mat-form-field class="filter-item search-input">
    <mat-label>Student</mat-label>
    <fa-icon matPrefix [icon]="['fas', 'magnifying-glass']"></fa-icon>
    <input
      data-test-id="SEARCH_FILTER_INPUT"
      matInput
      (keyup.enter)="filters.searchInput = $event.target.value; filtersUpdated.emit(filters)" />
  </mat-form-field>
  <mat-form-field class="filter-item">
    <mat-select placeholder="Complied">
      <mat-option
        *ngFor="let option of complianceOptions"
        (onSelectionChange)="onFiltersUpdated('complied',$event)"
        [value]="option.value">
        {{option.display}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="filter-item">
    <mat-select placeholder="Reactions">
      <mat-option
        *ngFor="let option of reactionOptions"
        (onSelectionChange)="onFiltersUpdated('reactionId',$event)"
        [value]="option.value">
        {{option.display}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="filter-item">
    <mat-label>Start Date</mat-label>
    <input
      matInput
      [matDatepicker]="startDatePicker"
      type="text"
      [value]="startDate"
      (dateChange)="onStartDateUpdated($event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="filter-item">
    <mat-label>End Date</mat-label>
    <input
      matInput
      [matDatepicker]="endDatePicker"
      type="text"
      [value]="endDate"
      (dateChange)="onEndDateUpdated($event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>
</div>
