import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFormError } from '../../../../shared/validation/form-error.interface';
import { plainToInstance } from 'class-transformer';
import { Subscription } from 'rxjs';
import {
  dtoToFormGroup,
  mapApiErrorResponse,
  validateAndGetValue
} from '../../../../shared/validation/validation.util';
import {
  SchoolUpdateDto,
  SubscriptionCreateDto,
  SubscriptionResponseDto,
  SubscriptionUpdateDto
} from '@whetstoneeducation/hero-common';
import { AppSchoolsSubscriptionService } from '../../schools-subscription.service';
import { AppPageHeaderService } from '../../../../shared/page-header/page-header.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  styleUrls: ['./subscription-create-edit.scss'],
  selector: 'app-subscription-create-edit',
  templateUrl: './subscription-create-edit.template.html'
})
export class AppSubscriptionCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  private readonly CREATE_OPTIONS = {
    enableImplicitConversion: true,
    exposeUnsetFields: true
  };
  private readonly UPDATE_OPTIONS = {
    enableImplicitConversion: true,
    exposeUnsetFields: true,
    excludeExtraneousValues: true
  };
  public errors: IFormError[] = [];
  public schoolSubscription: SubscriptionCreateDto | SubscriptionUpdateDto;
  public subscriptionForm: FormGroup;
  public pageHeaderSubscription: Subscription;
  public valueChangesSubscriptions: Subscription;
  public isCreating: boolean;
  public schoolId: number;
  public isLoading = true;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public schoolsSubscriptionService: AppSchoolsSubscriptionService,
    public pageHeaderService: AppPageHeaderService,
    public dialogRef: MatDialogRef<AppSubscriptionCreateEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      schoolId: number;
      onClose?: () => void;
    }
  ) {
    super({ route, router });
  }

  async ngOnInit() {
    const data =
      await this.schoolsSubscriptionService.getSubscriptionBySchoolId(
        this.data.schoolId
      );
    await this.loadData(data);
  }

  isUpdateSubscription(): boolean {
    return this.schoolSubscription instanceof SchoolUpdateDto;
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.pageHeaderSubscription.unsubscribe();
    this.valueChangesSubscriptions.unsubscribe();
  }

  public async loadData(data: SubscriptionResponseDto) {
    const schoolSubscription = data;
    try {
      this.schoolId = schoolSubscription.schoolId;
      if (!schoolSubscription.id) {
        this.schoolSubscription = plainToInstance(
          SubscriptionCreateDto,
          {
            ...schoolSubscription,
            schoolId: schoolSubscription.schoolId,
            canCreateInterventions: false,
            canCreateBehaviorCodes: false,
            canImportStudents: false,
            canImportUsers: false,
            active: true
          },
          this.CREATE_OPTIONS
        );
        this.subscriptionForm = dtoToFormGroup(
          this.schoolSubscription,
          this.formBuilder
        );
      } else {
        this.schoolSubscription = plainToInstance(
          SubscriptionUpdateDto,
          schoolSubscription,
          this.UPDATE_OPTIONS
        );
      }
      this.subscriptionForm = dtoToFormGroup(
        this.schoolSubscription,
        this.formBuilder,
        {
          exclude: ['id']
        }
      );
    } catch (error) {
      this.toastService.error('An error occurred while to loading the school!');
    }

    this.valueChangesSubscriptions =
      this.subscriptionForm.valueChanges.subscribe(async () => {
        if (this.schoolSubscription instanceof SubscriptionUpdateDto) {
          this.schoolSubscription =
            await validateAndGetValue<SubscriptionUpdateDto>(
              this.subscriptionForm,
              SubscriptionUpdateDto
            );
        } else {
          this.schoolSubscription =
            await validateAndGetValue<SubscriptionCreateDto>(
              this.subscriptionForm,
              SubscriptionCreateDto
            );
        }
      });
    this.isLoading = false;
  }

  public async handleSave() {
    let result: SubscriptionResponseDto;
    this.isLoading = true;
    try {
      if (this.schoolSubscription instanceof SubscriptionCreateDto) {
        const subscriptionResult =
          await this.schoolsSubscriptionService.createSubscription(
            this.schoolSubscription
          );
        this.schoolSubscription = plainToInstance(
          SubscriptionUpdateDto,
          subscriptionResult,
          this.CREATE_OPTIONS
        );
        result = subscriptionResult;
      } else if (this.schoolSubscription instanceof SubscriptionUpdateDto) {
        result = await this.schoolsSubscriptionService.updateSubscription(
          this.schoolId,
          this.schoolSubscription
        );
      }
      this.toastService.success('Subscription saved successfully!');
    } catch (errorResponse) {
      this.errors = mapApiErrorResponse(errorResponse);
      this.toastService.error(
        'An error occurred when attempting to save your subscription!'
      );
    }
    this.isLoading = false;
    if (this.data.onClose) {
      this.data.onClose();
    }
    this.dialogRef.close();
    return result;
  }
}
