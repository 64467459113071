import { Component, Input } from '@angular/core';
import { StudentPerformanceDto } from '@whetstoneeducation/hero-common';

@Component({
  selector: 'app-student-top-performers',
  templateUrl: './student-top-performers.component.html',
  styleUrls: ['./student-top-performers.component.scss']
})
export class StudentTopPerformersComponent {
  @Input() public topPerformers: StudentPerformanceDto[];
}
