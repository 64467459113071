"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationTemplateFilterDto = void 0;
const shared_1 = require("../shared");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const boolean_transform_1 = require("../../constants/boolean.transform");
const notification_template_create_dto_1 = require("./notification-template-create.dto");
class NotificationTemplateFilterDto extends shared_1.PaginationOptionsDto {
    isSchoolGroupLevel;
    isGlobal;
    schoolGroupId;
    schoolId;
    name;
    bodyType;
    constructor(notificationTemplateFilterOptions) {
        super();
        if (notificationTemplateFilterOptions)
            Object.assign(this, notificationTemplateFilterOptions);
    }
    getFields() {
        return [
            'isSchoolGroupLevel',
            'isGlobal',
            'schoolGroupId',
            'schoolId',
            'name',
            'bodyType',
            ...super.getFields()
        ];
    }
}
exports.NotificationTemplateFilterDto = NotificationTemplateFilterDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], NotificationTemplateFilterDto.prototype, "isSchoolGroupLevel", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Boolean)
], NotificationTemplateFilterDto.prototype, "isGlobal", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], NotificationTemplateFilterDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], NotificationTemplateFilterDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], NotificationTemplateFilterDto.prototype, "name", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], NotificationTemplateFilterDto.prototype, "bodyType", void 0);
