import { AfterViewChecked, Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { BaseComponent, IBaseComponentExtras } from '../../base-classes/base.component';
import { ITableFilters } from '../table-filters/table-filters.interface';
import { MatFooterRowDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DateConverter, DateFormats, MathUtil, ObjectUtil } from '@whetstoneeducation/hero-common';
import { logger } from '../../logger';
import { MatTable2CSV } from './table-to-csv';

/**
 * @class BaseTableComponent<T>
 * @description Abstracts Table for you.
 * This component is not meant to be used directly.
 * It is meant to be extended by other table components. If possible, use
 * BaseCommonTableComponent instead as it requires less boilerplate code.
 * @template T: Type of row data
 * @extends BaseComponent
 *
 */
@Directive()
export class BaseTableComponent<T>
  extends BaseComponent
  implements AfterViewChecked
{
  /* -------------- PASSED IN/OUT VALUES ----------------- */
  /**
   * Returns the data stored currently in the table
   */
  get dataArr() {
    return this.dataSource.data;
  }

  /**
   * Value inputted to base table.
   * The data we should display inside our table
   */
  @Input()
  set dataArr(newData: T[]) {
    // If we need to attach our paginator, don't attach our data array until we render our component
    // We need to give our paginator time to hook into our dataSource before rendering so it can paginate our data first
    if (this.attachPaginator) {
      if (this.hasAttachedPaginator) {
        this.originalData = newData;
        this.dataSource.data = newData;
      } else {
        this.attachingDataArr = newData;
      }
    } else {
      this.originalData = newData;
      this.dataSource.data = newData;
    }
  }

  /**
   * Object that stores all pagination & sorting.
   * Should be initiated in the top level component, and passed down.
   */
  @Input()
  public tableFilters: ITableFilters;

  /**
   * Called when pagination or sorting updates
   */
  @Output()
  public tableFiltersUpdated: EventEmitter<ITableFilters>;

  /**
   * Should we attach paginator on next opportunity
   */
  @Input()
  public attachPaginator: boolean;
  public hasAttachedPaginator: boolean;
  public attachingDataArr: T[];

  /* -------------- OVERWRITTEN VALUES ---------------- */
  /**
   * Value that should be overwritten. How we get value we should sort by for a column key.
   */
  public columnValues: {
    [key: string]: string[] | ((data: any) => any);
  };

  /**
   * Value that should be overwritten. Tells us what columns/order to display
   */
  public get displayedColumns(): string[] {
    return [];
  }

  /* -------------- OPTIONAL HTML ELEMENTS ----------- */

  /**
   * A reference to the sorting in our table to detect sort changes.
   */
  @ViewChild(MatSort)
  public sort: MatSort;

  /**
   * A reference to our footer.
   */
  @ViewChild(MatFooterRowDef)
  public footer: MatFooterRowDef;

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  /* -------------- REQUIRED HTML ELEMENTS ----------- */

  /**
   * A reference to our table. Used for adding / removing footer
   */
  @ViewChild(MatTable)
  public table: MatTable<any[]>;

  /* -------------- LOCAL VARIABLES ------------------ */

  /**
   * Value that should be overwritten. Data held in our data, used for sorting.
   */
  public dataSource: MatTableDataSource<T>;

  /**
   * A reference to the original version of our data arr.
   * Used so we can filter objects and reset without an api call
   */
  public originalData: any[];

  /**
   * Should we attach our empty footer on next valid render
   */
  public attachEmptyFooter = false;

  /**
   * Have we attached our sorting view child yet
   */
  public sortAttached = false;

  constructor(public extras?: IBaseComponentExtras) {
    super(extras);
    this.tableFiltersUpdated = new EventEmitter<ITableFilters>();
    this.dataSource = new MatTableDataSource<T>();
    this.attachPaginator = false;
  }

  ngAfterViewChecked() {
    if (this.paginator && !this.hasAttachedPaginator) {
      this.dataSource.paginator = this.paginator;
      this.hasAttachedPaginator = true;
      if (this.attachingDataArr) {
        this.dataArr = this.attachingDataArr;
        this.attachingDataArr = null;
      }
    }
    if (this.sort && !this.sortAttached) {
      this.sort.sortChange.subscribe(() => {
        this.sortData();
        this.updateSortKey();
      });
      this.sortAttached = true;
    }
  }

  /* ---------- SORTING/FILTERING METHODS ----------- */
  /**
   * Using the column key we want to sort, we get the values for each row using columnPropsArray
   * We drill down the row with each element of the array, being careful to handle null elements
   * @param row One of the objects from our dataArr
   * @param columnKey The key resembling what column we want to change
   */
  private getValue(row: T, columnKey): any {
    const propertyAccessor = this.columnValues[columnKey];
    if (typeof propertyAccessor === 'function') {
      return propertyAccessor(row);
    } else if (Array.isArray(propertyAccessor)) {
      return ObjectUtil.getNestedPropValue(row, propertyAccessor);
    } else {
      return null;
    }
  }

  /**
   * Depending on which type of values we are comparing, changes how we handle
   * @param val1 value we want to compare from row1
   * @param val2 value we want to compare from row2
   * @param direction Ascending or Descending
   */
  private compareValues(val1: any, val2: any, direction: string): number {
    const flipMultiplier = direction === 'asc' ? -1 : 1;

    /**
     * If one our values is null, always put that item at the bottom of the list
     */
    if ([null, undefined].includes(val1) || [null, undefined].includes(val2)) {
      /**
       * If we are sorting boolean values, null/undefined should be considered as false
       */
      if (typeof val1 === 'boolean' || typeof val2 === 'boolean') {
        if (typeof val1 !== 'boolean') {
          val1 = false;
        }
        if (typeof val2 !== 'boolean') {
          val2 = false;
        }
      } else {
        if (val1 === val2) {
          return 0;
        }
        if ([null, undefined].includes(val1)) {
          return 1;
        }
        if ([null, undefined].includes(val2)) {
          return -1;
        }
      }
    }

    if (typeof val1 !== typeof val2) {
      logger.error(
        'Base table cannot handle sorting two different types of values'
      );
      return 0;
    }

    if (typeof val1 === 'string') {
      return val2.localeCompare(val1) * flipMultiplier;
    }

    if (MathUtil.isNumber(val1) && MathUtil.isNumber(val2)) {
      return (val2 - val1) * flipMultiplier;
    }

    if (typeof val1 === 'boolean') {
      let val;
      if (val1 === val2) {
        val = 0;
      }
      if (val1) {
        val = 1;
      } else {
        val = -1;
      }
      return val * flipMultiplier;
    }

    if (typeof val1 === 'object') {
      if (Array.isArray(val1) && Array.isArray(val2)) {
        // Get the best item out of each array, and compare them
        const arrayOneTop = val1.sort((v1, v2) =>
          this.compareValues(v1, v2, direction)
        );
        const arrayTwoTop = val2.sort((v1, v2) =>
          this.compareValues(v1, v2, direction)
        );
        return this.compareValues(arrayOneTop[0], arrayTwoTop[0], direction);
      } else {
        logger.error(
          'Base table cannot sort by object, try making a more descriptive prop array'
        );
        return 0;
      }
    }
    logger.error('Base table does not have handler for that type of value');
    return 0;
  }

  /**
   * Sorts our data based on columnValues object. And updates our datasource.
   */
  public sortData(): void {
    const { active, direction } = this.sort;
    try {
      this.dataSource.data = this.dataSource.data.sort((row1, row2) =>
        this.compareValues(
          this.getValue(row1, active),
          this.getValue(row2, active),
          direction
        )
      );
    } catch (error) {
      logger.error('Error sorting values', error);
    }
  }

  /**
   * Updates our tableFilters Object with new sorting filters.
   * Currently useless.
   */
  public updateSortKey(): void {
    this.tableFilters = {
      ...this.tableFilters,
      active: this.sort.active,
      direction: this.sort.direction
    };
  }

  /**
   * Method called from pagination and sorting when changed.
   * Pagination.change => BaseTable.filtersUpdated.emit => TopLevel.getNewData
   * Could also hijack this method if no top level component
   * @param tableFilters A reference to our paging and sorting data object
   */
  public updateTableFilters(tableFilters: ITableFilters): void {
    this.tableFilters = tableFilters;
    this.tableFiltersUpdated.emit(tableFilters);
  }
  /**
   * Export our table based on our displayedColumns, columnData, and dataArr variables
   */
  public async exportTable() {
    try {
      const converter = new MatTable2CSV();
      await converter.convertToCSV(this.table);
    } catch (error) {
      logger.error(error);
    }
  }

  public getFormattedDate(date: number) {
    const dateObj = new Date(date);
    return DateConverter.convertDate(dateObj, DateFormats.SHORT_DATE);
  }

  public getFormattedTime(date: number) {
    const dateObj = new Date(date);
    return DateConverter.convertDate(dateObj, DateFormats.SHORT_TIME);
  }
}
