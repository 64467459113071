"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeReactionPossibleNextReactionResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class BehaviorCodeReactionPossibleNextReactionResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['reactions', 'reaction'];
    }
    reaction;
    scheduledReaction;
}
exports.BehaviorCodeReactionPossibleNextReactionResponseDto = BehaviorCodeReactionPossibleNextReactionResponseDto;
