import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';
/**
 * @description - This is the centralized location for storing generalized animations
 * that can be reused throughout the project. To use an animation, import this constant
 * and call the desired animation factory function with the appropriate parameters.
 */
export const AppAnimationFactory = {
  /**
   * Returns a fade-in and fade-out animation that is triggered on element enter and leave.
   * @param {number} inDelay - The delay for the fade-in animation in milliseconds.
   * @param {number} outDelay - The delay for the fade-out animation in milliseconds.
   * @example Usage:
   * import { AppAnimationFactory } from 'src/app/shared/animation.factory';
   * ...
   *_@Component({
   *   ...
   *   animations: [
   *     AppAnimationFactory.fadeInOut(500, 500) // 500ms fade-in delay, 500ms fade-out delay
   *   ]
   * })
   * export class MyComponent { ... }
   */
  fadeInOut: (inDelay: number, outDelay: number): AnimationTriggerMetadata => {
    return trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(inDelay, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(outDelay, style({ opacity: 0 }))
      ])
    ])
  },
  /**
   * Returns a slide-down and slide-up animation that is triggered on element enter and leave.
   * @param {string} inDelay - The delay for the slide-down animation in milliseconds.
   * @param {string} outDelay - The delay for the slide-up animation in milliseconds.
   * @example Usage:
   * import { AppAnimationFactory } from 'src/app/shared/animation.factory';
   * ...
   *_@Component({
   *   ...
   *   animations: [
   *    AppAnimationFactory.slideUpDown('300ms', '300ms')
   *   ]
   * })
   * export class MyComponent { ... }
   */
  slideDownUp: (inDelay: string, outDelay: string): AnimationTriggerMetadata => {
    return trigger('slideDownUp', [
      transition(':enter', [
        style({ maxHeight: 0, overflow: 'hidden' }),
        animate(`${inDelay} ease-in-out`, style({ maxHeight: '*' }))
      ]),
      transition(':leave', [
        animate(`${outDelay} ease-in-out`, style({ maxHeight: 0, overflow: 'hidden' }))
      ])
    ])
  }
}


