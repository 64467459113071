"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationsReportFiltersDto = void 0;
const shared_1 = require("../shared");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const enums_1 = require("../../enums");
class CommunicationsReportFiltersDto extends shared_1.PaginationOptionsDto {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return [
            ...super.getFields(),
            'startDate',
            'endDate',
            'status',
            'behaviorCodeId',
            'searchString',
            'schoolId'
        ];
    }
    schoolId;
    behaviorCodeId;
    searchString;
    startDate;
    endDate;
    status;
}
exports.CommunicationsReportFiltersDto = CommunicationsReportFiltersDto;
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], CommunicationsReportFiltersDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Number),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Number)
], CommunicationsReportFiltersDto.prototype, "behaviorCodeId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], CommunicationsReportFiltersDto.prototype, "searchString", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Date),
    (0, class_transformer_1.Transform)(transformStartDate, { toClassOnly: true }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Date)
], CommunicationsReportFiltersDto.prototype, "startDate", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => Date),
    (0, class_transformer_1.Transform)(transformEndDate, { toClassOnly: true }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Date)
], CommunicationsReportFiltersDto.prototype, "endDate", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], CommunicationsReportFiltersDto.prototype, "status", void 0);
function transformStartDate(value) {
    return transformDate('startDate', value);
}
function transformEndDate(value) {
    return transformDate('endDate', value);
}
function transformDate(name, value) {
    // This cast is only needed because the transform annotation
    // expects and any type, but we know what the actual type is
    const val = value;
    const obj = val?.obj;
    if (obj && obj[name]) {
        return new Date(parseInt(obj[name]));
    }
    return new Date();
}
