<!-- Save Bar Container -->
<div *ngIf="show" class="save-bar-buffer"></div>
<div
  *ngIf="show"
  class="save-bar"
  [class.hidden-save-bar]="options?.hideAutoSave"
>
  <!-- Save Buttons -->
  <div class="save-bar-buttons">
    <button
      mat-flat-button
      *ngFor="let button of buttons"
      (click)="buttonPressed(button.action)"
      [color]="button.style"
      [disabled]="button.disabled"
    >
      <mat-icon [fontIcon]="button.icon"></mat-icon>
      <span
        class="button-text"
        [class.hide-button-text-mobile]="button.hideTextMobile"
      >
        {{ button.name }}
      </span>
    </button>
  </div>
</div>
