<div class="reactions-list-content">
  <app-reactions-list-filters
    [filters]="filters"
    (filtersUpdated)="updateFilters($event)"
  ></app-reactions-list-filters>
  <app-reactions-list-table
    [dataArr]="reactions"
    [behaviorCodeId]="behaviorCodeId"
    [reloadTable]="reloadTable.bind(this)"
    [isLoading]="isLoading"
    [tableFilters]="filters.tableFilters"
    (tableFiltersUpdated)="updateTableFilters($event)"
  ></app-reactions-list-table>
</div>
