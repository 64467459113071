"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpImportFilterDto = void 0;
const shared_1 = require("../shared");
const class_validator_1 = require("class-validator");
const enums_1 = require("../../enums");
class SftpImportFilterDto extends shared_1.PaginationOptionsDto {
    constructor(sftpImportFilterOptions) {
        super();
        if (sftpImportFilterOptions && sftpImportFilterOptions.schoolId)
            this.schoolId = sftpImportFilterOptions.schoolId;
        if (sftpImportFilterOptions && sftpImportFilterOptions.schoolGroupId)
            this.schoolGroupId = sftpImportFilterOptions.schoolGroupId;
        if (sftpImportFilterOptions && sftpImportFilterOptions.importSubject)
            this.importSubject = sftpImportFilterOptions.importSubject;
        if (sftpImportFilterOptions && sftpImportFilterOptions.startDate)
            this.startDate = sftpImportFilterOptions.startDate;
        if (sftpImportFilterOptions && sftpImportFilterOptions.endDate)
            this.endDate = sftpImportFilterOptions.endDate;
        if (sftpImportFilterOptions && sftpImportFilterOptions.status)
            this.status = sftpImportFilterOptions.status;
    }
    schoolId;
    schoolGroupId;
    importSubject;
    status;
    startDate;
    endDate;
}
exports.SftpImportFilterDto = SftpImportFilterDto;
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportFilterDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportFilterDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.ImportSubject),
    __metadata("design:type", String)
], SftpImportFilterDto.prototype, "importSubject", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEnum)(enums_1.ImportStatus),
    __metadata("design:type", String)
], SftpImportFilterDto.prototype, "status", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", String)
], SftpImportFilterDto.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", String)
], SftpImportFilterDto.prototype, "endDate", void 0);
