import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/base-classes/base.component';
import { AppSessionHandlerService } from './session-handler.service';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginResponseDto } from '@whetstoneeducation/hero-common';
import { AppPasswordDialogComponent } from '../../shared/password-dialog/password-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AppAuthService } from './auth.service';
import { AppMySettingsService } from '../my-settings/my-settings.service';
import { logger } from '../../shared/logger';

@Component({
  selector: 'app-verified-link',
  templateUrl: './verified-link.template.html',
  styleUrls: ['./verified-link.scss']
})
export class AppVerifiedLinkComponent extends BaseComponent implements OnInit {
  public userSession: LoginResponseDto;

  public constructor(
    private mySettingsService: AppMySettingsService,
    private sessionHandlerService: AppSessionHandlerService,
    private toastManager: AppToastManagerService,
    public passwordDialog: MatDialog,
    public authService: AppAuthService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    super({ route });
  }

  public ngOnInit(): void {
    this.verifyLink();
  }

  public async verifyLink(): Promise<void> {
    // get link from query params
    const link = this.route.snapshot.queryParams.link;
    if (!link) {
      this.toastManager.error('No link found in URL');
      void this.router.navigate(['/login']);
      return;
    } else {
      try {
        // call verify link endpoint
        this.userSession = await this.authService.verifyLink(link);
        if (!this.userSession) {
          this.toastManager.error('Error verifying link');
          return;
        }
        const downloadFileUrl = this.userSession.downloadFileUrl;
        // once downloaded no need it in the session
        delete this.userSession.downloadFileUrl;

        // load session data
        await this.sessionHandlerService.loadLoggedInStateAndData(
          this.userSession
        );
        // do password reset
        if (this.userSession.passwordReset) {
          // password reset dialog
          this.passwordDialog
            .open(AppPasswordDialogComponent, {
              width: '500px',
              autoFocus: true,
              disableClose: true,
              data: {
                email: this.userSession.email,
                firstName: this.userSession.firstName,
                lastName: this.userSession.lastName
              }
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.mySettingsService
                  .setPassword(this.userSession.id, result)
                  .then(() => {
                    this.toastManager.success('Password updated successfully!');
                    // confirm verified link complete
                    this.authService
                      .completeLink(link)
                      .then(() => {
                        logger.debug('Link completed');
                      })
                      .catch(() => {
                        logger.error('Error completing link');
                      })
                      .finally(() => {
                        // user must login with fresh session and their new password
                        this.sessionHandlerService.handleLogout();
                      });
                  })
                  .catch(() => {
                    this.toastManager.error(
                      'An error occurred when attempting to update your password!'
                    );
                  });
              }
            });
        } else if (downloadFileUrl && downloadFileUrl.length) {
          window.location.href = downloadFileUrl;
          void this.router.navigate(['/dashboard']);
        }
        logger.debug('Link verified');
      } catch (error) {
        await this.toastManager.error(
          'Error verifying link. If you already have a Hero account please log in using this page otherwise contact your administrator.'
        );
        void this.router.navigate(['/login']);
      }
    }
  }

  downloadFile(url: string, filename: string) {}
}
