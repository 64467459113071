import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { Subscription } from 'rxjs';
import { ChartOptions } from '../../../shared/chart-options.type';
import { ChartComponent } from 'ng-apexcharts';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AppSftpService } from '../sftp.service';
import {
  AppBasicStateService,
  RegisteredStates
} from '../../../shared/services/basic-state.service';
import { ISftpImportListFilters } from '../sftp-import-list/sftp-import-list-models/sftp-import-list-filters.interface';
import {
  ImportSubject,
  SftpImportStatsDto
} from '@whetstoneeducation/hero-common';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sftp-summary',
  templateUrl: './sftp-summary.template.html',
  styleUrls: ['./sftp-summary.scss']
})
export class AppSftpSummaryComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('sftpUserSummaryChart')
  public sftpUserSummaryChart: ChartComponent;
  @ViewChild('sftpStudentSummaryChart')
  public sftpStudentSummaryChart: ChartComponent;
  @ViewChild('sftpScheduleSummaryChart')
  public sftpScheduleSummaryChart: ChartComponent;

  public selectedTab = 0;
  public sftpSummaryData: SftpImportStatsDto[];
  public userChartOptions: Partial<ChartOptions>;
  public studentChartOptions: Partial<ChartOptions>;
  public scheduleChartOptions: Partial<ChartOptions>;
  public subscriptions: Subscription[] = [];
  public isLoading = false;
  public currentFilters: ISftpImportListFilters;

  constructor(
    private readonly sftpService: AppSftpService,
    private readonly basicStateService: AppBasicStateService,
    private readonly pageHeaderService: AppPageHeaderService,
    public readonly router: Router,
    private readonly datePipe: DatePipe
  ) {
    super();
    this.loadData();
  }

  public loadData(): void {
    this.isLoading = true;
    this.getSftpSummaryData();
    this.subscriptions.push(
      this.pageHeaderService.buttonAction$.subscribe((action) => {
        if (action === HeaderButtonAction.LIST_ACCOUNTS) {
          this.router.navigate(['sftp', 'accounts']);
        }
        if (action === HeaderButtonAction.LIST_SETTINGS) {
          this.router.navigate(['sftp', 'settings']);
        }
      })
    );
    this.isLoading = false;
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTab = event.index;
    switch (this.selectedTab) {
      case 0:
        this.currentFilters.importSubject = ImportSubject.STUDENT_IMPORT;
        break;
      case 1:
        this.currentFilters.importSubject = ImportSubject.USER_IMPORT;
        break;
      case 2:
        this.currentFilters.importSubject = ImportSubject.SCHEDULE_IMPORT;
        break;
    }
    this.basicStateService.setState(
      RegisteredStates.SFTP_IMPORT_LIST_FILTERS,
      this.currentFilters
    );
  }

  private formatChartData(data: SftpImportStatsDto[]): Partial<ChartOptions> {
    const categories = data.map((item) => this.datePipe.transform(item.date));
    const updatedSeries = data.map((item) => item.updated);
    const addedSeries = data.map((item) => item.added);
    const removedSeries = data.map((item) => item.removed);
    const errorsSeries = data.map((item) => item.errors);

    const defaultChartOptions = this.defaultChartOptions();
    return {
      ...defaultChartOptions,
      xaxis: {
        type: 'category',
        categories: categories
      },
      series: [
        { name: 'Updated', data: updatedSeries },
        { name: 'Added', data: addedSeries },
        { name: 'Removed', data: removedSeries },
        { name: 'Errors', data: errorsSeries }
      ]
    };
  }

  getSftpSummaryData(): void {
    this.userChartOptions = this.defaultChartOptions();
    this.studentChartOptions = this.defaultChartOptions();
    this.scheduleChartOptions = this.defaultChartOptions();
    this.sftpService.getImportStats(ImportSubject.USER_IMPORT).then((data) => {
      this.userChartOptions = this.formatChartData(data);
    });
    this.sftpService
      .getImportStats(ImportSubject.STUDENT_IMPORT)
      .then((data) => {
        this.studentChartOptions = this.formatChartData(data);
      });
    this.sftpService
      .getImportStats(ImportSubject.SCHEDULE_IMPORT)
      .then((data) => {
        this.scheduleChartOptions = this.formatChartData(data);
      });
  }

  private defaultChartOptions(): Partial<ChartOptions> {
    return {
      series: [
        {
          name: 'Updated',
          data: [1]
        },
        {
          name: 'Added',
          data: [1]
        },
        {
          name: 'Removed',
          data: [1]
        },
        {
          name: 'Errors',
          data: [1]
        }
      ],
      chart: {
        type: 'bar',
        height: 550,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 400,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        type: 'category',
        categories: []
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.basicStateService
        .getState(RegisteredStates.SFTP_IMPORT_LIST_FILTERS)
        .subscribe(async (newFilter) => {
          if (newFilter) {
            this.currentFilters = newFilter;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
