import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../shared/base-classes/base.component';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss']
})
export class AppStudentDashboardComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    public router: Router,
    public route: ActivatedRoute
  ) {
    super();
  }

  async ngOnInit() {
    await this.loadData();
  }

  public async loadData() {
    const { noCalendar } = this.route.snapshot.data.data;
    if (noCalendar) {
      await this.router.navigate(['/contact-support']);
    }
  }
}
