<form [formGroup]="behaviorCodeForm" novalidate>
  <div class="twoColumnLayout behavior-code-content">
    <div class="column">
      <mat-card
        appearance="outlined"
        class="behavior-code-form mat-elevation-z3">
        <mat-card-header>
          <mat-card-title> Behavior Code Info </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="form-row">
            <mat-form-field class="half-width-input">
              <mat-label>Code (short name)</mat-label>
              <input matInput formControlName="code" />
              <app-error-display
                [form]="behaviorCodeForm"
                controlName="code"></app-error-display>
            </mat-form-field>

            <mat-form-field class="half-width-input">
              <mat-label>Description</mat-label>
              <input matInput formControlName="description" />
            </mat-form-field>
          </div>

          <mat-form-field class="full-width-input">
            <mat-label>Message on Pass</mat-label>
            <input matInput formControlName="passMessage" />
          </mat-form-field>

          <mat-form-field class="full-width-input">
            <mat-label>External Reference Code</mat-label>
            <input matInput formControlName="externalReferenceCode" />
          </mat-form-field>

          <mat-form-field class="full-width-input">
            <mat-label>Type</mat-label>
            <mat-select formControlName="behaviorCodeTypeId">
              <mat-option
                *ngFor="let type of behaviorCodeTypeOptions"
                [value]="type.value"
                options-id="codeTypes"
                [option]="constantize(type.display)">
                {{type.display}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="form-section">
            <mat-label>Options</mat-label>
            <mat-slide-toggle
              [color]="'primary'"
              class="filter-item"
              formControlName="active">
              Active
            </mat-slide-toggle>
            <mat-slide-toggle
              [color]="'primary'"
              class="filter-item"
              formControlName="printPass">
              Print Pass
            </mat-slide-toggle>
            <mat-slide-toggle
              [color]="'primary'"
              class="filter-item"
              formControlName="fastTrack">
              Fast Track
            </mat-slide-toggle>
          </div>

          <!-- This still does not have any functionality, so it is better to hide it om the meantime -->
          <!-- <div class="form-section">
            <mat-label>Positive Reinforcement</mat-label>
            <mat-slide-toggle
              [color]="'primary'"
              class="filter-item" unique-id>
              Active
            </mat-slide-toggle>
            <small>
              Turning on this will enable negative behavior tracks to be rolled
              back for students who meet certain criteria. Configure that
              criteria after enabling this.
            </small>
          </div> -->

          <!-- <div class="form-section">
            <mat-label>Excluded Days</mat-label>
          </div> -->
        </mat-card-content>
      </mat-card>
    </div>
    <div class="column" *ngIf="isCreating">
      <ng-container *ngFor="let school of schoolsDefaultReactionForms">
        <app-default-reaction
          *ngIf="school.form && reactionOptions && reactionOptions[school.id]"
          [defaultBehaviorCodeReactionForm]="school.form"
          [reactionOptions]="reactionOptions[school.id]"
          [title]="school.title"></app-default-reaction>
      </ng-container>
    </div>

    <div class="column tables-column" *ngIf="!isCreating">
      <mat-card appearance="outlined" class="reactions mat-elevation-z3">
        <mat-card-header class="reactions-header card-header">
          <mat-card-title>
            <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
            Reactions
          </mat-card-title>
          <div class="header-action-buttons">
            <button
              mat-flat-button
              class="secondary-button header-action-button small-button"
              [routerLink]="'/reactions'"
              unique-id>
              Manage Reactions
            </button>
            <button
              mat-flat-button
              class="success-button header-action-button small-button"
              [routerLink]="'/behavior-codes/' + behaviorCode?.id?.toString() + '/behavior-code-reaction/new'"
              unique-id>
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add
            </button>
          </div>
        </mat-card-header>
        <mat-slide-toggle
          [color]="'primary'"
          class="filter-item inactive-toggle"
          (toggleChange)="showInactiveToggle()"
        >
          Show Inactive
        </mat-slide-toggle>
        <app-behavior-codes-reactions-table
          [dataArr]="behaviorCodeReactions"
          [isLoading]="isLoading"
          [tableFilters]="behaviorCodeReactionsTableFilters"
          (tableFiltersUpdated)="updateReactionsTableFilters($event)"></app-behavior-codes-reactions-table>
      </mat-card>
      <mat-card appearance="outlined" class="interventions mat-elevation-z3">
        <mat-card-header class="interventions-header card-header">
          <mat-card-title>
            <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
            Interventions
          </mat-card-title>
          <div class="header-action-buttons">
            <button
              mat-flat-button
              class="secondary-button header-action-button small-button"
              [routerLink]="'/interventions'"
              unique-id>
              Manage Interventions
            </button>
            <button
              mat-flat-button
              class="success-button header-action-button small-button"
              [routerLink]="'/behavior-codes/' + behaviorCode?.id?.toString() + '/behavior-code-interventions/new'"
              unique-id>
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add
            </button>
          </div>
        </mat-card-header>
        <app-behavior-code-interventions-table
          [dataArr]="behaviorCodeInterventions"
          [isLoading]="isLoading"
          [tableFilters]="behaviorCodeInterventionsTableFilters"
          (tableFiltersUpdated)="updateInterventionsTableFilters($event)"></app-behavior-code-interventions-table>
      </mat-card>
      <mat-card appearance="outlined" class="notifications mat-elevation-z3">
        <mat-card-header class="notifications-header card-header">
          <mat-card-title>
            <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
            Notifications
          </mat-card-title>
          <div class="header-action-buttons">
            <button
              mat-flat-button
              class="secondary-button header-action-button small-button"
              [routerLink]="'/notification-templates'"
              unique-id>
              Manage Notifications
            </button>
            <button
              mat-flat-button
              class="success-button header-action-button small-button"
              [routerLink]="'/behavior-codes/' + behaviorCode?.id?.toString() + '/behavior-code-notifications/new'"
              unique-id>
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon> Add
            </button>
          </div>
        </mat-card-header>
        <app-behavior-code-notifications-table
          [dataArr]="behaviorCodeNotifications"
          [isLoading]="isLoading"
          [tableFilters]="behaviorCodeNotificationsTableFilters"
          (tableFiltersUpdated)="updateNotificationsTableFilters($event)"></app-behavior-code-notifications-table>
      </mat-card>
    </div>
  </div>
</form>
