import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppCalendarService } from '../calendar.service';
import {
  CalendarDayResponseDto,
  DateUtil,
  IRelation,
  StudentGroupBellScheduleCalendarDayCreate,
  StudentGroupBellScheduleCalendarDayUpdate,
  IDisplayData
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-calendar-day-bell-schedule-manage-modal',
  templateUrl: './calendar-day-bell-schedule-manage-modal.component.html',
  styleUrls: ['./calendar-day-bell-schedule-manage-modal.component.scss']
})
export class CalendarDayBellScheduleManageModalComponent
  extends BaseComponent
  implements OnInit
{
  public calendarDay: CalendarDayResponseDto;
  public bellScheduleOptions: IDisplayData[];
  public studentGroupBellScheduleCalendarDayForm: FormGroup;
  public studentGroupOptions: IDisplayData[];
  constructor(
    public calendarService: AppCalendarService,
    public dialogRef: MatDialogRef<CalendarDayBellScheduleManageModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      timestamp: number;
      onClose?: () => void;
    },
    public formBuilder: FormBuilder,
    public toastService: AppToastManagerService
  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    const user = this.StorageManager.getLoggedInUser();

    const [calendarDay, dropdownData] = await Promise.all([
      await this.calendarService.getCalendarDayByTimestamp(this.data.timestamp),
      await this.calendarService.getDropdownData(user.currentSchoolId)
    ]);

    this.calendarDay = calendarDay;
    this.bellScheduleOptions = dropdownData.bellScheduleOptions;
    this.studentGroupOptions = dropdownData.studentGroupOptions;

    this.studentGroupBellScheduleCalendarDayForm = new FormGroup({});
    dropdownData.studentGroupOptions.map((studentGroup) => {
      const bellSchedule =
        calendarDay.studentGroupBellScheduleCalendarDays.find(
          (studentGroupBellScheduleCalendarDay) =>
            studentGroup.value.toString() ===
            studentGroupBellScheduleCalendarDay.studentGroup.id.toString()
        );

      this.studentGroupBellScheduleCalendarDayForm.addControl(
        studentGroup.value.toString(),
        new FormControl<any>(
          bellSchedule ? bellSchedule.bellSchedule.id : undefined
        )
      );
    });

    this.isLoading = false;
  }

  async handleSubmit() {
    try {
      this.isLoading = true;
      const updateDtos: StudentGroupBellScheduleCalendarDayUpdate[] = [];
      const createDtos: StudentGroupBellScheduleCalendarDayCreate[] = [];

      Object.keys(this.studentGroupBellScheduleCalendarDayForm.controls).map(
        (studentGroupId) => {
          const control =
            this.studentGroupBellScheduleCalendarDayForm.get(studentGroupId);

          if (control.dirty && control.invalid) {
            this.isLoading = false;
            this.toastService.error(
              'Please select a bell schedule for all student groups'
            );
            return;
          } else if (control.dirty && control.valid) {
            const studentGroupBellScheduleCalendarDay =
              this.calendarDay.studentGroupBellScheduleCalendarDays.find(
                (studentGroupBellScheduleCalendarDay) =>
                  studentGroupBellScheduleCalendarDay.studentGroup.id ===
                  +studentGroupId
              );

            const bellScheduleId =
              this.studentGroupBellScheduleCalendarDayForm.get(
                studentGroupId
              ).value;

            if (studentGroupBellScheduleCalendarDay) {
              updateDtos.push(
                new StudentGroupBellScheduleCalendarDayUpdate({
                  id: studentGroupBellScheduleCalendarDay.id,
                  bellScheduleId
                })
              );
            } else
              createDtos.push(
                new StudentGroupBellScheduleCalendarDayCreate({
                  studentGroupId: +studentGroupId,
                  calendarDayId: this.calendarDay.id,
                  bellScheduleId
                })
              );
          }
        }
      );

      const calls = [];

      if (updateDtos.length) {
        calls.push(
          this.calendarService.updateStudentGroupBellScheduleCalendarDays(
            updateDtos
          )
        );
      }

      if (createDtos.length) {
        calls.push(
          this.calendarService.createStudentGroupBellScheduleCalendarDays(
            createDtos
          )
        );
      }

      await Promise.all(calls);
      this.toastService.success('Bell schedules updated successfully');
      this.isLoading = false;
      this.data.onClose && this.data.onClose();
      this.dialogRef.close();
    } catch (e) {
      this.isLoading = false;
      this.toastService.error(
        'An error occurred while updating bell schedules'
      );
    }
  }

  protected readonly DateUtil = DateUtil;
}
