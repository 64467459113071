import { Component } from '@angular/core';

@Component({
  selector: 'app-behavior-code-entry-edit-reaction',
  templateUrl: './behavior-code-entry-edit-reaction.component.html',
  styleUrls: ['./behavior-code-entry-edit-reaction.component.scss']
})
export class BehaviorCodeEntryEditReactionComponent {

}
