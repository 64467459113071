import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateConverter, DateFormats } from '@whetstoneeducation/hero-common';

import { AppTimezoneService } from '../services/timezone.service';

/**
 * Date Ago Pipe. Useful for smaller dates on mobile
 */
@Pipe({
  name: 'date',
})
export class AppExtendedDatePipe extends DatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    public timezoneService: AppTimezoneService
  ) {
    super(locale);
  }

  /**
   * Overridden pipe function that transform our date value into our wanted value
   */
  // @ts-ignore
  public transform(
    value,
    format: DateFormats | string,
    timezone?: string,
    locale?: string
  ) {
    timezone = this.timezoneService.getTimezoneText();
    return DateConverter.convertDate(value, format, timezone);
  }
}
