import { SftpAccountResponseDto } from '@whetstoneeducation/hero-common';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { Component } from '@angular/core';
import { SftpAccountListColumns } from '../sftp-account-list-models/sftp-account-list-columns.enum';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppSftpAccountViewComponent } from '../../sftp-account-view/sftp-account-view.component';

@Component({
  selector: 'app-sftp-account-list-table',
  templateUrl: './sftp-account-list-table.template.html',
  styleUrls: ['./sftp-account-list-table.scss']
})
export class AppSftpAccountListTableComponent extends BaseTableComponent<SftpAccountResponseDto> {
  public get displayedColumns() {
    return Object.values(SftpAccountListColumns);
  }

  public columnValues = {
    [SftpAccountListColumns.SCHOOL_GROUP]: ['schoolGroup', 'name'],
    [SftpAccountListColumns.SCHOOL]: ['school', 'name'],
    [SftpAccountListColumns.GROUP_LEVEL]: ['isSchoolGroupLevel']
  };

  public columns = SftpAccountListColumns;

  constructor(
    readonly router: Router,
    public matDialog: MatDialog
  ) {
    super();
  }

  public async viewSftpAccount(sftpAccount: SftpAccountResponseDto) {
    this.matDialog
      .open(AppSftpAccountViewComponent, {
        width: '500px',
        data: sftpAccount
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          console.log(result);
        }
      });
  }

  protected readonly JSON = JSON;
}
