import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { BaseComponent } from 'src/app/shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFormError } from '../../../shared/validation/form-error.interface';
import { plainToInstance } from 'class-transformer';
import { Subscription } from 'rxjs';
import {
  dtoToFormGroup,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import {
  ReactionCreateDto,
} from '@whetstoneeducation/hero-common';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppReactionsService } from '../reactions.service';

@Component({
  styleUrls: ['./duplicate-reaction.scss'],
  selector: 'app-duplicate-reaction',
  templateUrl: './duplicate-reaction.template.html'
})
export class AppDuplicateReactionComponent
  extends BaseComponent
{
  
  public errors: IFormError[] = [];
  public reaction: ReactionCreateDto;
  public reactionForm: FormGroup;
  public subscriptions: Subscription[] = [];
  public isLoading = true;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public reactionService: AppReactionsService,
    public pageHeaderService: AppPageHeaderService,
    public dialogRef: MatDialogRef<AppDuplicateReactionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      newReaction: ReactionCreateDto;
      onClose?: () => void;
    }
  ) {
    super({ route, router });
    this.loadData();
    
  }

  public loadData() {
    this.isLoading = true;
    this.reaction = this.data.newReaction;
    this.reactionForm = dtoToFormGroup(this.reaction, this.formBuilder, {
      mapId: false
    });
    this.isLoading = false;
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.reactionForm.valueChanges.subscribe(async (value) => {
        this.reaction = await validateAndGetValue<ReactionCreateDto>(
          this.reactionForm,
          ReactionCreateDto
        );
      })
    );
  }

  public async handleDuplicate() {
    this.isLoading = true;
    try {
      const newReaction = plainToInstance(ReactionCreateDto, this.reaction, {
        exposeUnsetFields: true
      });
      const savedReaction =
        await this.reactionService.createReaction(newReaction);
    } catch (err) {
      this.toastService.error(
        'There was an error cloning this reaction, please try again!'
      );
    }
    this.isLoading = false;
    if (this.data.onClose) {
      this.data.onClose();
    }
    this.dialogRef.close();
  }
}
