import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../../app-material.module';
import { AppSupportButtons } from './support-buttons.component';

@NgModule({
  imports: [CommonModule, RouterModule, AppMaterialModule, FormsModule],
  declarations: [AppSupportButtons],
  exports: [AppSupportButtons]
})
export class AppUserlaneModule {}
