import { Component, Input, OnDestroy } from '@angular/core';
import {
  IContactInformation,
  PrivilegeEnum,
  StudentProfileResponseDto
} from '@whetstoneeducation/hero-common';
import { AppStudentsService } from '../../students.service';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AppGuardianDialog } from '../guardian-dialog/guardian.dialog';
import { MatDialog } from '@angular/material/dialog';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AppPrivilegesService } from '../../../auth/privileges.service';

@Component({
  selector: 'app-student-guardian-contacts',
  templateUrl: './student-guardian-contacts.template.html',
  styleUrls: ['./student-guardian-contacts.scss']
})
export class StudentGuardianContactsComponent
  extends BaseComponent
  implements OnDestroy
{
  @Input()
  public profile: StudentProfileResponseDto;

  @Input()
  public isStudent: boolean;

  public destroy$: Subscription;

  constructor(
    private studentsService: AppStudentsService,
    private toastService: AppToastManagerService,
    private confirmDialog: AppConfirmationDialogService,
    privilegesService: AppPrivilegesService,
    public router: Router,
    private dialog: MatDialog
  ) {
    super({ privilegesService });
  }

  ngOnDestroy(): void {
    if (this.destroy$) this.destroy$.unsubscribe();
  }

  public viewGuardian(guardianId: number): void {
    this.router.navigate(['users', guardianId]);
  }

  public addGuardian(): void {
    const dialogRef = this.dialog.open(AppGuardianDialog, {
      width: '600px',
      panelClass: 'default-dialog',
      disableClose: true
    });

    this.destroy$ = dialogRef.afterClosed().subscribe(async (guardianEmail) => {
      if (guardianEmail) {
        try {
          const result = await this.studentsService.assignGuardian(
            this.profile.studentDetail.id,
            guardianEmail
          );
          if (result) {
            this.toastService.success('Guardian added successfully');
            this.profile.studentDetail.guardians = result.guardians;
          } else {
            this.toastService.error('Failed to add guardian');
          }
        } catch (error) {
          if (error.status === 404) {
            if (this.hasPrivilege(PrivilegeEnum.MANAGE_USERS)) {
              const ref = this.confirmDialog.openDialog({
                title: 'Guardian not found!',
                content: `There is no guardian with the email ${guardianEmail}.
                Would you like to create a new guardian account for this student
                with this email?`,
                actions: [
                  {
                    key: 'CREATE',
                    label: 'Create',
                    classes: 'success-button'
                  },
                  {
                    key: 'CANCEL',
                    label: 'Cancel',
                    icon: 'times-circle'
                  }
                ]
              });
              ref.afterClosed().subscribe(async (result: string) => {
                if (result === 'CREATE') {
                  const result =
                    await this.studentsService.createStudentGuardian(
                      this.profile.studentDetail.id,
                      guardianEmail
                    );
                  if (result) {
                    this.toastService.success('Guardian created successfully');
                    this.profile.studentDetail.guardians = result.guardians;
                  } else {
                    this.toastService.error('Failed to add guardian');
                  }
                }
              });
            } else {
              const ref = this.confirmDialog.openDialog({
                title: 'Guardian not found!',
                content:
                  'Please contact your administrator to create a new guardian for this student with this email.',
                actions: [
                  {
                    key: 'OK',
                    label: 'OK'
                  }
                ]
              });
            }
          } else {
            this.toastService.error('Failed to add guardian');
          }
        }
      }
    });
  }

  public async removeGuardian(guardianId: number): Promise<void> {
    try {
      const result = await this.studentsService.removeGuardian(
        this.profile.studentDetail.id,
        guardianId
      );
      if (result) {
        this.toastService.success('Guardian removed successfully');
        this.profile.studentDetail.guardians = result.guardians;
      } else {
        this.toastService.error('Failed to remove guardian');
      }
    } catch (error) {
      this.toastService.error('Failed to remove guardian');
    }
  }

  public getGuardianAddress(guardian: IContactInformation): string {
    return `${guardian.address || 'N/A'} ${guardian.city || 'N/A'}, ${guardian.state || 'N/A'} ${guardian.zip || 'N/A'}`;
  }
}
