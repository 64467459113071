"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BehaviorCodeEntryByIdsFilterDto = void 0;
const shared_1 = require("../shared");
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
class BehaviorCodeEntryByIdsFilterDto extends shared_1.PaginationOptionsDto {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['ids', 'schoolId', 'addPrintInfo'];
    }
    ids;
    schoolId;
    addPrintInfo;
}
exports.BehaviorCodeEntryByIdsFilterDto = BehaviorCodeEntryByIdsFilterDto;
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsNumber)({}, { each: true }),
    (0, class_transformer_1.Type)(() => Number),
    (0, class_transformer_1.Transform)((value) => {
        if (typeof value.value === 'number') {
            return [value.value];
        }
        return value.value;
    }),
    __metadata("design:type", Array)
], BehaviorCodeEntryByIdsFilterDto.prototype, "ids", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], BehaviorCodeEntryByIdsFilterDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Transform)(({ value }) => value === 'true'),
    __metadata("design:type", Boolean)
], BehaviorCodeEntryByIdsFilterDto.prototype, "addPrintInfo", void 0);
