import { Directive, ElementRef, AfterViewInit, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[list-id]'
})
export class ListIdDirective implements OnInit {
  @Input('list-id') groupId: string;
  @Input() index: number;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (environment.environmentName === "prod") return;
    if (this.groupId && this.index != null) {
      this.el.nativeElement.setAttribute('data-test-id', `${this.groupId}_${this.index}`);
    } else {
      console.error('list-id directive requires a group id and index');
    }
  }
}
