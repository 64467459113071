import { logger } from 'src/app/shared/logger';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HeaderButtonAction, IHeaderButton } from './header-button';
import { IBreadcrumb } from './breadcrumb.interface';

/**
 * Service used to change page header dynamically
 */
@Injectable({
  providedIn: 'root'
})
export class AppPageHeaderService {
  @Output() titleChanged: EventEmitter<string>;
  @Output() subtitleChanged: EventEmitter<string>;
  @Output() descriptionChanged: EventEmitter<string>;
  @Output() buttonsChanged: EventEmitter<IHeaderButton[]>;
  @Output() breadcrumbsChanged: EventEmitter<IBreadcrumb[]>;
  public buttonAction$: Subject<HeaderButtonAction>;

  /**
   * Default Constructor
   * @param route Reference to the active route.
   * @param router Reference to the angular router.
   */
  constructor(
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.titleChanged = new EventEmitter<string>();
    this.subtitleChanged = new EventEmitter<string>();
    this.descriptionChanged = new EventEmitter<string>();
    this.buttonsChanged = new EventEmitter<IHeaderButton[]>();
    this.breadcrumbsChanged = new EventEmitter<IBreadcrumb[]>();
    this.buttonAction$ = new Subject<HeaderButtonAction>();
  }

  /**
   * Dispatches an event to change the page title.
   * @param title The title we want the page to be.
   */
  public changeTitle(title: string): void {
    try {
      this.titleChanged.emit(title);
    } catch (error) {
      logger.error(error);
    }
  }

  /**
   * Dispatches an event to change the page subtitle.
   * @param subtitle The subtitle we want the page to be.
   */
  public changeSubtitle(subtitle: string): void {
    try {
      this.subtitleChanged.emit(subtitle);
    } catch (error) {
      logger.error(error);
    }
  }

  /**
   * Dispatches an event to change the page description.
   * @param description The title we want the page to be.
   */
  public changeDescription(description: string): void {
    try {
      this.descriptionChanged.emit(description);
    } catch (error) {
      logger.error(error);
    }
  }

  /**
   * Dispatches an event to change the page header buttons
   */
  public changeButtons(buttons: IHeaderButton[]) {
    try {
      this.buttonsChanged.emit(buttons);
    } catch (error) {
      logger.error(error);
    }
  }

  public changeBreadcrumbs(breadcrumbs: IBreadcrumb[]) {
    try {
      this.breadcrumbsChanged.emit(breadcrumbs);
    } catch (error) {
      logger.error(error);
    }
  }

  /**
   * Emits the event that a button action has been triggered to the handler.
   * @param action The action we want to perform.
   */
  public buttonAction(action: HeaderButtonAction) {
    this.buttonAction$.next(action);
  }
}
