import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import { BaseService } from '../../shared/base-classes/base.service';
import { Injectable } from '@angular/core';
import {
  LoginResponseDto,
  VerifiedLinkResponseDto
} from '@whetstoneeducation/hero-common';

/**
 * Used to make all auth-related HTTP calls
 */
@Injectable({
  providedIn: 'root'
})
export class AppAuthService extends BaseService {
  public constructor(public clientDataService: AppClientDataService) {
    super();
  }

  public async resetPassword(email: string): Promise<{ message: string }> {
    return await this.clientDataService.execute(
      this.GET_ROUTES.RESET_PASSWORD,
      {
        method: this.METHOD.GET,
        queryParams: {
          email
        }
      }
    );
  }

  public async getActiveSession(): Promise<LoginResponseDto> {
    return this.clientDataService.execute<LoginResponseDto>(
      this.GET_ROUTES.AUTH_VERIFY,
      {},
      LoginResponseDto
    );
  }

  public async verifyLink(link: string): Promise<LoginResponseDto> {
    return await this.clientDataService.execute<LoginResponseDto>(
      this.GET_ROUTES.VERIFY_LINK,
      {
        method: this.METHOD.GET,
        queryParams: {
          link
        }
      },
      LoginResponseDto
    );
  }

  public async completeLink(link: string): Promise<VerifiedLinkResponseDto> {
    return await this.clientDataService.execute<VerifiedLinkResponseDto>(
      this.GET_ROUTES.COMPLETE_LINK,
      {
        method: this.METHOD.GET,
        queryParams: {
          link
        }
      },
      VerifiedLinkResponseDto
    );
  }

  public async logout(): Promise<LoginResponseDto> {
    return this.clientDataService.execute(this.DELETE_ROUTES.LOGOUT, {
      method: this.METHOD.DELETE
    });
  }

  public async loginWithEmailPassword(
    email: string,
    password: string
  ): Promise<LoginResponseDto> {
    return this.clientDataService.execute<LoginResponseDto>(
      this.POST_ROUTES.LOGIN,
      {
        method: this.METHOD.POST,
        body: {
          email,
          password
        }
      },
      LoginResponseDto
    );
  }

  public async updateActiveSchool(schoolId: number): Promise<LoginResponseDto> {
    const updatedUser = await this.clientDataService.execute<LoginResponseDto>(
      this.PATCH_ROUTES.EDIT_ACTIVE_SCHOOL,
      {
        method: this.METHOD.PATCH,
        queryParams: {
          schoolId
        }
      },
      LoginResponseDto
    );
    this.StorageManager.saveUserInfo(updatedUser);
    return updatedUser;
  }

  public async updateActiveSchoolGroup(
    schoolGroupId: number
  ): Promise<LoginResponseDto> {
    const updatedUser = await this.clientDataService.execute<LoginResponseDto>(
      this.PATCH_ROUTES.EDIT_ACTIVE_SCHOOL_GROUP,
      {
        method: this.METHOD.PATCH,
        queryParams: {
          schoolGroupId
        }
      },
      LoginResponseDto
    );
    this.StorageManager.saveUserInfo(updatedUser);
    return updatedUser;
  }
}
