import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { InterventionResponseDto } from '@whetstoneeducation/hero-common';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppInterventionsService } from '../interventions.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { plainToInstance } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class AppInterventionCreateEditResolver extends BaseResolver<InterventionResponseDto> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private interventionsService: AppInterventionsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<InterventionResponseDto> {
    const id = route.paramMap.get('interventionId');
    if (id === 'new') {
      const user = this.StorageManager.getLoggedInUser();
      return plainToInstance(InterventionResponseDto, {
        name: null,
        description: '',
        schoolId: user.currentSchoolId,
      });
    } else {
      return this.interventionsService.getInterventionById(+id);
    }
  }
}
