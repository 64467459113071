import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppReportsService, ICommunicationsReportResolverData } from '../reports.service';


@Injectable({ providedIn: 'root' })
export class AppCommunicationsReportResolver extends BaseResolver<ICommunicationsReportResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public reportService: AppReportsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(): Promise<ICommunicationsReportResolverData> {
    return await this.reportService.getCommunicationsReportResolverData();
  }
}
