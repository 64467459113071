import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppStudentProfilePhotoComponent } from './student-profile-photo.component';

@NgModule({
  imports: [CommonModule, AppMaterialModule, FontAwesomeModule, NgOptimizedImage],
  declarations: [AppStudentProfilePhotoComponent],
  exports: [AppStudentProfilePhotoComponent],
})
export class AppStudentProfilePhotoModule {}
