import { CronDaysOfWeekEnum } from '@whetstoneeducation/hero-common';

export const CronDaysOfWeek = [
    {value: CronDaysOfWeekEnum.SUNDAY, label: 'Sunday'}, 
    {value: CronDaysOfWeekEnum.MONDAY, label: 'Monday'}, 
    {value: CronDaysOfWeekEnum.TUESDAY, label: 'Tuesday'}, 
    {value: CronDaysOfWeekEnum.WEDNESDAY, label: 'Wednesday'}, 
    {value: CronDaysOfWeekEnum.THURSDAY, label: 'Thursday'}, 
    {value: CronDaysOfWeekEnum.FRIDAY, label: 'Friday'}, 
    {value: CronDaysOfWeekEnum.SATURDAY, label: 'Saturday'}
];