import { ICustomRoute } from '../../app-routing.module';
import { AppIncidentConfigResolver } from './incident-config/incident-config.resolver';
import { AppIncidentConfigComponent } from './incident-config/incident-config.component';
import { AuthGuard } from '../../route-guards/auth.guard';

export const AppIncidentRoutes: ICustomRoute[] = [
  {
    path: 'incident/config',
    title: 'Incident Config',
    resolve: { data: AppIncidentConfigResolver },
    component: AppIncidentConfigComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Incident Config'
    }
  }
];
