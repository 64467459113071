import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-guardian-dialog',
  templateUrl: './guardian.template.html',
  styleUrls: ['./guardian.dialog.scss']
})
export class AppGuardianDialog extends BaseComponent {
  @ViewChild('emailInput') emailInput!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AppGuardianDialog>
  ) {
    super();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveGuardian(): void {
    const email = this.emailInput.nativeElement.value;
    this.dialogRef.close(email);
  }
}
