<div class="mat-dialog-header">
  <div *ngIf="!isLoading" class="mat-dialog-title">
    {{ DateUtil.convertTimestampToDisplayDate(calendarDay.date) }}:
    <span class="title-emphasis">Bell Schedules</span>
  </div>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <ng-container *ngIf="!isLoading">
    <div class="manage-actions">
      <button
        type="button"
        mat-button
        class="manage-actions-buttons"
        [routerLink]="['/student-groups']">
        MANAGE STUDENT GROUPS
      </button>
      <button
        type="button"
        mat-button
        class="manage-actions-buttons"
        [routerLink]="['/bell-schedules']">
        MANAGE BELL SCHEDULES
      </button>
    </div>
    <div class="directions">Select a bell schedule for each group:</div>

    <ng-container *ngFor="let studentGroup of studentGroupOptions">
      <mat-form-field class="full-width-input">
        <mat-label>
          {{ studentGroup.display }}
        </mat-label>
        <mat-select
          [formControl]="
            $any(
              studentGroupBellScheduleCalendarDayForm.controls[
                studentGroup.value.toString()
              ]
            )
          ">
          <mat-option
            *ngFor="let bellSchedule of bellScheduleOptions"
            [value]="bellSchedule.value">
            {{ bellSchedule.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>

  <div class="modal-action-row">
    <button
      type="button"
      mat-button
      class="modal-cancel-button"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      type="submit"
      mat-button
      class="modal-submit-button"
      (click)="handleSubmit()">
      Update
    </button>
  </div>
</div>
