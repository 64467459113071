<div class="container">
  <app-accrued-report-filters
    [loadingItems]="isLoading"
    [filters]="filters"
    (filtersUpdated)="onFiltersUpdated($event)"
    (applyFilters)="applyFilters()">
  </app-accrued-report-filters>
  <app-accrued-report-summary-card
    [studentsCount]="studentsCount"
    [totalCount]="totalCount">
  </app-accrued-report-summary-card>

  <div class="actions-container">
    <div>
      <app-filter-results-input
        (filterUpdated)="searchFilterUpdated($event)"></app-filter-results-input>
      <mat-slide-toggle
        hideIcon
        [checked]="!this.filters.active"
        (toggleChange)="onToggleFilterUpdated()"
        [color]="'primary'">
        Show Deactivated Records
      </mat-slide-toggle>
    </div>
  </div>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <app-base-common-table-portal [context]="this"></app-base-common-table-portal>

  <span *ngIf="!(dataArr.length || isLoading)">
    There are no records to show
  </span>
</div>
