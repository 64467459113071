"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyLinksSkipAccessCount = exports.VerifiedLinkType = void 0;
var VerifiedLinkType;
(function (VerifiedLinkType) {
    VerifiedLinkType["UserInvite"] = "user-invite";
    VerifiedLinkType["PasswordReset"] = "password-reset";
    VerifiedLinkType["SecureDownload"] = "secure-download";
})(VerifiedLinkType || (exports.VerifiedLinkType = VerifiedLinkType = {}));
exports.verifyLinksSkipAccessCount = [VerifiedLinkType.SecureDownload];
