import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';

import { StudentPerformanceDto } from '@whetstoneeducation/hero-common';
import { AppDashboardService } from '../dashboard.service';

// TODO: rework schedules after school groups - THOR-380
export const districtDashResolver: ResolveFn<{
  topPerformers: StudentPerformanceDto[];
  todaySchedules: any[];
  noCalendar?: boolean;
}> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AppDashboardService).getDistrictDashboardResolverData();
};
