import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { Injectable } from '@angular/core';
import { SftpSettingsResponseDto } from '@whetstoneeducation/hero-common';
import { AppSftpService } from '../sftp.service';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppSftpSettingsResolver extends BaseResolver<SftpSettingsResponseDto> {
  constructor(
    private readonly sftpService: AppSftpService,
    resolverErrorHandler: AppResolverErrorHandlerService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SftpSettingsResponseDto> {
    const id = route.paramMap.get('id');
    if (!id) {
      return new SftpSettingsResponseDto({});
    } else {
      return await this.sftpService.getSftpSettings(parseInt(id));
    }
  }
}
