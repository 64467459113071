const ZplCommandsMapValues = {
  field_block: {
    left: 'L',
    center: 'C',
    right: 'R',
    justify: 'J',
  },
};

export const ZplCommands = {
  start: '^XA', // We always need to start
  finish: '^XZ', // We always need to finish
  field_separator: '^FS', // After adding field data, we need to indicate that it has reached the end of the data
  field_origin: (x_axis?: number, y_axis?: number) => `^FO${x_axis ?? ''},${y_axis ?? ''}`, // starting position for the printing field (coordinates)
  field_data: (data: any) => `^FD${data}`, // Printing field data,
  change_font: (font: string | number, height: number, width?: number) => `^CF${font},${height}${width ? `,${width}`: ''}`,
  comment: (comment: string) => `^FX ${comment}`,
  label_width: (dots: number) => `^PW${dots}`,
  barcode: (height: number) => `^BC,${height}`,
  field_block: (
    justify: 'left' | 'center' | 'right' | 'justify',
    width?: number,
    lines?: number,
    space?: number,
    ) => {
      return `^FB${width ?? ''},${lines ?? ''},${space ?? ''},${ZplCommandsMapValues.field_block[justify]}`
    },
  // JUST FOR STRING FORMAT
  space: ' ',
  endline: '\n',
};
