import { Injectable } from "@angular/core";
import { BaseService } from "../../shared/base-classes/base.service";
import { AppClientDataService } from "../../shared/services/client-data-service/app-client-data.service";

@Injectable({
  providedIn: 'root'
})
export class AppGuardiansService extends BaseService {


  constructor(private appClientDataService: AppClientDataService) {
    super();
  }

  async getGuardianStudentsProfileInfo(
    guardianId: number) {
      return this.appClientDataService.execute<any>(
        this.GET_ROUTES.GUARDIAN_STUDENTS,
        { pathParams: { id: guardianId } }
      );
    }
}
