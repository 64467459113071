import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.template.html',
  styleUrls: ['./file-upload.scss']
})
export class AppFileUploadComponent {
  fileName: string;
  errorMessage?: string = null;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() fileChanged = new EventEmitter<File>();
  dragActive = false;

  @Input()
  allowedExtensions: string[] = ["*/*"];

  @Input()
  maxMBFileSize?: number = null;

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.emitFile(file);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragActive = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragActive = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragActive = false;
    const file = event.dataTransfer.files[0];
    this.emitFile(file);
  }

  private emitFile(file: File): void {
    this.errorMessage = null;
    if (file) {
      if (this.maxMBFileSize) {
        const maxFileSize = this.maxMBFileSize * 1024 * 1024;
        if (file.size > maxFileSize) {
          this.errorMessage = `File size must be less than ${this.maxMBFileSize} MB`;
          this.fileName = null;
          this.fileChanged.emit(null);
          return;
        }
      }
      this.fileName = file.name;
      this.fileChanged.emit(file);
    }
  }
}
