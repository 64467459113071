import { Component } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { RoleResponseDto, UserTypeEnum } from '@whetstoneeducation/hero-common';
import { RolesListColumns } from '../roles-list-models/roles-list-columns.enum';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-roles-list-table',
  templateUrl: './roles-list-table.template.html',
  styleUrls: ['./roles-list-table.scss']
})
export class AppRolesListTableComponent extends BaseTableComponent<RoleResponseDto> {
  public get displayedColumns() {
    return Object.values(RolesListColumns);
  }

  public columnValues = {
    [RolesListColumns.NAME]: ['name'],
    [RolesListColumns.SCHOOL]: ['school_name']
  };

  public columns = RolesListColumns;

  public notEditableUserTypes: UserTypeEnum[] = [
    UserTypeEnum.STUDENT,
    UserTypeEnum.GUARDIAN
  ];

  constructor(public toastService: AppToastManagerService) {
    super();
  }
}
