"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccruedReportResponseDto = exports.AccruedReportRowResponseDto = void 0;
const class_validator_1 = require("class-validator");
const shared_1 = require("../shared");
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
class AccruedReportRowResponseDto extends shared_1.PaginationOptionsDto {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['studentId', 'studentName', 'grade', 'behaviorCodeDescription', 'count'];
    }
    studentId;
    studentName;
    grade;
    behaviorCodeDescription;
    count;
}
exports.AccruedReportRowResponseDto = AccruedReportRowResponseDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], AccruedReportRowResponseDto.prototype, "studentId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], AccruedReportRowResponseDto.prototype, "studentName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], AccruedReportRowResponseDto.prototype, "grade", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], AccruedReportRowResponseDto.prototype, "behaviorCodeDescription", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], AccruedReportRowResponseDto.prototype, "count", void 0);
class AccruedReportResponseDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        Object.assign(this, options);
    }
    getFields() {
        return ['results', 'totalCount', 'studentsCount'];
    }
    results;
    totalCount;
    studentsCount;
}
exports.AccruedReportResponseDto = AccruedReportResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Array)
], AccruedReportResponseDto.prototype, "results", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], AccruedReportResponseDto.prototype, "totalCount", void 0);
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], AccruedReportResponseDto.prototype, "studentsCount", void 0);
