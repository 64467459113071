import { Component, Input } from '@angular/core';

/**
 * The LinkDropdown Component
 */
@Component({
  selector: 'app-link-dropdown',
  templateUrl: './link-dropdown.template.html',
  styleUrls: ['./link-dropdown.scss']
})
export class AppLinkDropdownComponent {
  /**
   * What text should our link contain
   */
  @Input()
  public text: string;

  /**
   * Should we just show the content passed in, and not use our link dropdown features
   */
  @Input()
  public justShowContent: boolean;

  /**
   * Have we toggled our content dropdown open or closed
   */
  public isOpen: boolean;

  /**
   * Default Constructor
   */
  constructor() {}
}
