import { Injectable } from '@angular/core';
import { BaseResolver } from 'src/app/shared/base-classes/base.resolver';
import { AppResolverErrorHandlerService } from 'src/app/shared/services/resolver-error-handler.service';
import { AppCalendarService } from '../calendar.service';
import { ICalendarViewResolverData } from './calendar-view-models/calendar-view-resolver.data.interface';

@Injectable({ providedIn: 'root' })
export class AppCalendarViewResolver extends BaseResolver<ICalendarViewResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    public calendarService: AppCalendarService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(route): Promise<ICalendarViewResolverData> {
    return this.calendarService.getCalendarViewResolverData();
  }
}
