<div class="schools-list-filters-content">
  <!-- Search -->
  <mat-form-field class="filter-item search-input">
    <mat-label>Search by name</mat-label>
    <fa-icon matPrefix [icon]="['fas', 'magnifying-glass']"></fa-icon>
    <input
      matInput
      (keyup.enter)="filters.searchInput = $event.target.value; filtersUpdated.emit(filters)"
    />
  </mat-form-field>
</div>
