import {
  ISetting,
  MappableBase,
  PrivilegeEnum,
  SettingsName,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { AppAuthService } from '../../pages/auth/auth.service';
import { APIRoutesConfig } from '../services/client-data-service/routes-config/api-routes.config';
import { AppSettingsService } from '../services/settings.service';
import { logger } from '../logger';
import { StorageManager } from '../storage/storage.manager';
import { GetRoutes } from '../services/client-data-service/routes-config/get.routes';
import { PostRoutes } from '../services/client-data-service/routes-config/post.routes';
import { PutRoutes } from '../services/client-data-service/routes-config/put.routes';
import { DeleteRoutes } from '../services/client-data-service/routes-config/delete.routes';
import { PatchRoutes } from '../services/client-data-service/routes-config/patch.routes';
import { AppPrivilegesService } from '../../pages/auth/privileges.service';

export interface IBaseServiceExtras {
  authService?: AppAuthService;
  settingsService?: AppSettingsService;
  privilegesService?: AppPrivilegesService;
}

/**
 * Contains common things that all service classes should have access to.
 */
export class BaseService {
  /**
   * Defines an object that maps all the Whetstone API Routes by standardized key name.
   */
  public readonly GET_ROUTES: GetRoutes;
  public readonly POST_ROUTES: PostRoutes;
  public readonly PUT_ROUTES: PutRoutes;
  public readonly DELETE_ROUTES: DeleteRoutes;
  public readonly PATCH_ROUTES: PatchRoutes;

  /**
   * Fake enum object so we can refer to method verbs from these preset strings.
   */
  public readonly METHOD: any;

  /**
   * Provides access to StorageManager in all services.
   */
  public StorageManager = StorageManager;

  public authService: AppAuthService;
  public settingsService: AppSettingsService;
  public privilegesService: AppPrivilegesService;

  /**
   * Default Constructor
   */
  constructor(extras?: IBaseServiceExtras) {
    if (extras) {
      this.authService = extras.authService;
      this.settingsService = extras.settingsService;
      this.privilegesService = extras.privilegesService;
    }
    this.GET_ROUTES = APIRoutesConfig.getRoutes();
    this.POST_ROUTES = APIRoutesConfig.postRoutes();
    this.PUT_ROUTES = APIRoutesConfig.putRoutes();
    this.DELETE_ROUTES = APIRoutesConfig.deleteRoutes();
    this.PATCH_ROUTES = APIRoutesConfig.patchRoutes();

    this.METHOD = {
      GET: 'GET',
      POST: 'POST',
      PUT: 'PUT',
      PATCH: 'PATCH',
      DELETE: 'DELETE'
    };
  }

  public get systemSettings(): ISetting {
    return this.getSystemSettings();
  }

  protected buildQueryParams(filters: MappableBase, fieldList: string[]) {
    const queryParams = {};
    fieldList.forEach((field) => {
      if (filters[field] !== undefined && filters[field] !== null) {
        queryParams[field] = filters[field];
      }
    });
    return queryParams;
  }

  /**
   * Returns the current system settings for the active district.
   */
  public getSystemSettings(): ISetting {
    if (this.settingsService) {
      return this.settingsService.getSettings();
    } else {
      logger.error(
        'You are attempting to retrieve system settings but none were found. Are you sure that the Settings Service was injected into the super() call?'
      );
      return null;
    }
  }

  public hasPrivilege(key: PrivilegeEnum): boolean {
    if (this.privilegesService) {
      return this.privilegesService.hasPrivilege(key);
    } else {
      logger.error('Add privileges service to the super call!');
    }
  }

  public hasSetting(setting: SettingsName): boolean {
    const settings = StorageManager.getLoggedInUser()?.settings;
    return settings && settings[setting];
  }

  public hasAnyPrivilege(privileges: PrivilegeEnum[]): boolean {
    if (this.privilegesService) {
      return privileges.some((p) => this.privilegesService.hasPrivilege(p));
    } else {
      logger.error('Add privileges service to the super call!');
    }
  }

  public isUserType(types: UserTypeEnum[]): boolean {
    return this.StorageManager.isUserType(types);
  }

  public getSchoolGroupIdOfCurrentSchool(): number {
    const user = this.StorageManager.getLoggedInUser();
    if (
      user &&
      user.schoolGroups &&
      user.schoolGroups.length &&
      user.currentSchoolId
    ) {
      const schoolGroup = user.schoolGroups.find((sg) =>
        sg.schools.some((s) => s.id === user.currentSchoolId)
      );
      return schoolGroup ? schoolGroup.id : null;
    }
    return null;
  }

  public getCurrentSchoolId(): number {
    return this.StorageManager.getCurrentSchoolId();
  }

  public hasSchoolSet(): boolean {
    const user = this.StorageManager.getLoggedInUser();
    return !!user?.currentSchoolId;
  }

  public hasSchoolGroupSet(): boolean {
    return !!this.getSchoolGroupIdOfCurrentSchool();
  }
}
