"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidTimeConstraint = exports.ValidTime = void 0;
const class_validator_1 = require("class-validator");
function ValidTime(validationOptions) {
    return (object, propertyName) => {
        (0, class_validator_1.registerDecorator)({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            validator: ValidTimeConstraint
        });
    };
}
exports.ValidTime = ValidTime;
/**
 * Custom validator for time in HH:mm or HH:mm:ss format
 * @param value - The time string to validate
 * @usageNotes
 * ```ts
 * @ValidTime()
 * time: string;
 * ```
 */
let ValidTimeConstraint = class ValidTimeConstraint {
    validate(value) {
        // Check if the input is a valid time in HH:mm or HH:mm:ss format
        const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/;
        return timeRegex.test(value);
    }
    defaultMessage() {
        return 'Invalid time. Must be in HH:MM AM/PM format.';
    }
};
exports.ValidTimeConstraint = ValidTimeConstraint;
exports.ValidTimeConstraint = ValidTimeConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: 'ValidTime' })
], ValidTimeConstraint);
