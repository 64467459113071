import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  ImportSubject,
  SftpImportResponseDto
} from '@whetstoneeducation/hero-common';
import { ISftpImportListFilters } from './sftp-import-list-models/sftp-import-list-filters.interface';
import { Subject, takeUntil } from 'rxjs';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSftpService } from '../sftp.service';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../../shared/logger';
import {
  AppBasicStateService,
  RegisteredStates
} from '../../../shared/services/basic-state.service';

@Component({
  selector: 'app-sftp-import-list',
  templateUrl: './sftp-import-list.template.html',
  styleUrls: ['./sftp-import-list.scss']
})
export class AppSftpImportListComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public isLoading = false;
  public sftpImports: SftpImportResponseDto[];
  public filters: ISftpImportListFilters = {
    importSubject: ImportSubject.STUDENT_IMPORT,
    tableFilters: TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.SFTP_IMPORTS_LIST
    )
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public sftpService: AppSftpService,
    private basicStateService: AppBasicStateService
  ) {
    super();
    this.loadData();
    this.basicStateService.initState(
      RegisteredStates.SFTP_IMPORT_LIST_FILTERS,
      this.filters
    );
  }

  public ngAfterViewInit(): void {
    this.basicStateService
      .getState(RegisteredStates.SFTP_IMPORT_LIST_FILTERS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (newFilter) => {
        if (newFilter) {
          await this.updateFilters(newFilter);
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.basicStateService.unregisterState(
      RegisteredStates.SFTP_IMPORT_LIST_FILTERS
    );
  }

  public loadData(): void {
    this.isLoading = true;
    const data = this.route.snapshot.data.data;
    this.sftpImports = data.sftpImports.results;
    this.filters = data.defaultFilters;
    this.filters.tableFilters.count = data.sftpImports.options.totalItems;
    this.isLoading = false;
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters.tableFilters = newTableFilters;
    await this.updateSftpImportList(this.filters);
    this.isLoading = false;
  }

  public async updateSftpImportList(
    filters: ISftpImportListFilters
  ): Promise<void> {
    try {
      const sftpImports = await this.sftpService.getSftpImportsList(filters);
      this.sftpImports = sftpImports.results;
      this.filters.tableFilters.count = sftpImports.options.totalItems;
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading SFTP imports');
    }
  }

  public async updateFilters(
    newFilters: ISftpImportListFilters
  ): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.filters.tableFilters = TableFilterOptions.resetTableFilters(
      TableFiltersPageKeyEnum.SFTP_IMPORTS_LIST,
      this.filters.tableFilters
    );
    await this.updateSftpImportList(this.filters);
    this.isLoading = false;
  }
}
