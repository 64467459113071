import { ICustomRoute } from '../../app-routing.module';
import { AppDistrictDashComponent } from './district-dashboard/district-dash.component';
import { AuthGuard } from '../../route-guards/auth.guard';
import { districtDashResolver } from './district-dashboard/district-dash.resolver';
import { AppDashboardComponent } from './dashboard/dashboard.component';
import { AppGuardianDashboardComponent } from './guardian-dashboard/guardian-dashboard.component';
import { genericDashboardResolver } from './generic-dashboard.resolver';
import { AppContactSupportComponent } from './contact-support/contact-support.component';
import { HeaderButtonAction } from '../../shared/page-header/header-button';

export const AppDashboardRoutes: ICustomRoute[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    component: AppDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/school',
    title: 'District Dashboard',
    component: AppDistrictDashComponent,
    canActivate: [AuthGuard],
    resolve: { data: districtDashResolver },
    data: {
      title: 'Dashboard',
      buttons: [
        {
          name: 'Kiosk Mode',
          style: 'outline-button',
          action: HeaderButtonAction.NAVIGATE
        },
        {
          name: 'Calendar',
          style: 'secondary-button',
          route: '/calendar'
        }
      ]
    }
  },
  {
    path: 'dashboard/guardian',
    title: 'Welcome',
    component: AppGuardianDashboardComponent,
    canActivate: [AuthGuard],
    resolve: { data: genericDashboardResolver },
    data: {
      title: 'Welcome',
      pageHeaderClass: 'report-page-header'
    }
  },
  {
    path: 'contact-support',
    title: 'Contact Support',
    component: AppContactSupportComponent,
    canActivate: [AuthGuard]
  }
];
