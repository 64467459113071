import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppSftpService } from '../sftp.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { SftpAccountResponseDto } from '@whetstoneeducation/hero-common';
import { SftpAccountCredentialsBase } from '../sftp-account-credentials.base';

@Component({
  selector: 'app-sftp-account-view',
  templateUrl: './sftp-account-view.template.html',
  styleUrls: ['./sftp-account-view.scss']
})
export class AppSftpAccountViewComponent extends SftpAccountCredentialsBase {
  public isLoading = false;
  public sftpAccount: SftpAccountResponseDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AppSftpAccountViewComponent>,
    readonly sftpService: AppSftpService,
    private readonly toastService: AppToastManagerService
  ) {
    super(sftpService);
    this.sftpAccount = data;
  }

  public async resetAccountPassword(): Promise<void> {
    this.isLoading = true;
    try {
      this.accountCredentials = await this.sftpService.resetAccountPassword(
        this.sftpAccount.id
      );
      this.toastService.success('Password reset successfully.');
    } catch (error) {
      this.toastService.error('Error resetting password');
    } finally {
      this.isLoading = false;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
