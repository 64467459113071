import { BaseComponent } from '../../../shared/base-classes/base.component';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BehaviorCodeInterventionResponseDto,
  BehaviorCodeNotificationResponseDto,
  BehaviorCodeReactionResponseDto,
  BehaviorCodeUpdateDto,
  BehaviorCodeCreateDto,
  IDisplayData,
  DefaultBehaviorCodeReactionCreateDto,
  PrivilegeEnum
} from '@whetstoneeducation/hero-common';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  dtoToFormGroup,
  formCanSave,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import { IBehaviorCodesCreateEditResolverData } from './behavior-codes-create-edit-resolver-data.interface';
import { Subscription } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { logger } from 'src/app/shared/logger';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { AppBehaviorCodesService } from '../behavior-codes.service';
import { filter } from 'rxjs/operators';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { AppBehaviorCodeReactionsService } from '../../behavior-code-reactions/behavior-code-reactions.service';
import { AppBehaviorCodeInterventionsService } from '../../behavior-code-interventions/behavior-code-interventions.service';
import { AppBehaviorCodeNotificationsService } from '../../behavior-code-notifications/behavior-code-notifications.service';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { AppPrivilegesService } from '../../auth/privileges.service';

@Component({
  selector: 'app-behavior-code-create-edit',
  templateUrl: './behavior-codes-create-edit.template.html',
  styleUrls: ['./behavior-codes-create-edit.scss']
})
export class AppBehaviorCodesCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  public behaviorCode: BehaviorCodeUpdateDto | BehaviorCodeCreateDto;
  public behaviorCodeTypeOptions: IDisplayData[];
  public reactionOptions: { [schoolId: number]: IDisplayData[] };
  public isCreating: boolean;
  public behaviorCodeForm: FormGroup;
  public schoolsDefaultReactionForms: {
    form: FormGroup;
    title: string;
    id: number;
  }[];
  public subscriptions: Subscription[] = [];
  public routeSubscription: Subscription;
  public id: number;
  public behaviorCodeInterventions: BehaviorCodeInterventionResponseDto[];
  public behaviorCodeNotifications: BehaviorCodeNotificationResponseDto[];
  public behaviorCodeReactions: BehaviorCodeReactionResponseDto[];
  public behaviorCodeInterventionsTableFilters: ITableFilters;
  public behaviorCodeNotificationsTableFilters: ITableFilters;
  public behaviorCodeReactionsTableFilters: ITableFilters;
  public pageHeaderSubscription: Subscription;
  public user = this.StorageManager.getLoggedInUser();
  public showInactiveBehaviorCodeReactions = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public toastManager: AppToastManagerService,
    public behaviorCodeService: AppBehaviorCodesService,
    public behaviorCodeReactionsService: AppBehaviorCodeReactionsService,
    public behaviorCodeInterventionsService: AppBehaviorCodeInterventionsService,
    public behaviorCodeNotificationsService: AppBehaviorCodeNotificationsService,
    private pageHeaderService: AppPageHeaderService,
    private privilegesService: AppPrivilegesService
  ) {
    super({ privilegesService });
    const resolverData: IBehaviorCodesCreateEditResolverData =
      this.route.snapshot.data.data;
    this.loadData(resolverData);
    this.handleRouteChanges();
    this.behaviorCodeForm = dtoToFormGroup(this.behaviorCode, formBuilder, {
      mapId: !this.isCreating,
      disable: !this.hasPrivilege(PrivilegeEnum.MANAGE_BEHAVIOR_CODE)
        ? [
            'code',
            'description',
            'passMessage',
            'externalReferenceCode',
            'behaviorCodeTypeId',
            'active',
            'printPass',
            'fastTrack',
            'positiveReinforcement'
          ]
        : []
    });
    if (this.user.currentSchoolId) {
      this.schoolsDefaultReactionForms = [
        {
          form: dtoToFormGroup(
            new DefaultBehaviorCodeReactionCreateDto(),
            formBuilder
          ),
          id: this.user.currentSchoolId,
          title: 'Add Default Reaction to Behavior Code'
        }
      ];
    } else {
      const schools = this.user.schoolGroups.find(
        (sg) => sg.id === this.user.schoolGroupId
      ).schools;
      this.schoolsDefaultReactionForms = schools.map((s) => {
        return {
          form: dtoToFormGroup(
            new DefaultBehaviorCodeReactionCreateDto(),
            formBuilder
          ),
          id: s.id,
          title: 'Add Default Reaction to Behavior Code for ' + s.name
        };
      });
    }
  }

  public ngAfterViewInit() {
    this.subscriptions.push(
      this.behaviorCodeForm.valueChanges.subscribe(async () => {
        if (!this.isCreating) {
          this.behaviorCode = await validateAndGetValue<BehaviorCodeUpdateDto>(
            this.behaviorCodeForm,
            BehaviorCodeUpdateDto
          );
        } else {
          this.behaviorCode = await validateAndGetValue<BehaviorCodeCreateDto>(
            this.behaviorCodeForm,
            BehaviorCodeCreateDto
          );
        }
      })
    );

    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.SAVE) {
            await this.saveChanges();
          }
        }
      );
  }

  public ngOnDestroy() {
    this.routeSubscription?.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.pageHeaderSubscription.unsubscribe();
  }

  public loadData(resolverData: IBehaviorCodesCreateEditResolverData): void {
    this.isLoading = true;
    this.id = +this.route.snapshot.params.id;
    if (!this.id) {
      this.behaviorCode = plainToInstance(
        BehaviorCodeCreateDto,
        resolverData.behaviorCode,
        {
          exposeUnsetFields: true
        }
      );
      this.reactionOptions = resolverData.reactionOptions;
    } else {
      this.behaviorCode = plainToInstance(
        BehaviorCodeUpdateDto,
        resolverData.behaviorCode
      );
    }
    this.behaviorCodeTypeOptions = resolverData.behaviorCodeTypeOptions;
    const reactions = resolverData.behaviorCodeReactions;
    this.behaviorCodeReactions = reactions?.results;
    this.behaviorCodeReactionsTableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.BEHAVIOR_CODE_REACTIONS
    );
    this.behaviorCodeReactionsTableFilters.count =
      reactions?.options?.totalItems;
    const interventions = resolverData.behaviorCodeInterventions;
    this.behaviorCodeInterventions = interventions?.results;
    this.behaviorCodeInterventionsTableFilters =
      TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.BEHAVIOR_CODE_INTERVENTIONS
      );
    this.behaviorCodeInterventionsTableFilters.count =
      interventions?.options?.totalItems;

    const notifications = resolverData.behaviorCodeNotifications;
    this.behaviorCodeNotifications = notifications?.results;
    this.behaviorCodeNotificationsTableFilters =
      TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.BEHAVIOR_CODE_NOTIFICATIONS
      );
    this.behaviorCodeNotificationsTableFilters.count =
      notifications?.options?.totalItems;
    this.isCreating = !this.id;
    this.isLoading = false;
  }

  public handleRouteChanges() {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const resolverData: IBehaviorCodesCreateEditResolverData =
          this.route.snapshot.data.data;
        this.loadData(resolverData);
      });
  }

  public async saveChanges() {
    if (formCanSave(this.behaviorCodeForm, this.toastManager)) {
      const savePromises = [];
      try {
        if (this.behaviorCode instanceof BehaviorCodeCreateDto) {
          for (const school of this.schoolsDefaultReactionForms) {
            const schoolForm = school.form;
            if (schoolForm.value.reactionId) {
              if (formCanSave(schoolForm, this.toastManager)) {
                const behaviorCodeCreateDto = new BehaviorCodeCreateDto();
                behaviorCodeCreateDto.mapFields(this.behaviorCode);

                behaviorCodeCreateDto.defaultBehaviorCodeReaction =
                  await validateAndGetValue<DefaultBehaviorCodeReactionCreateDto>(
                    school.form,
                    DefaultBehaviorCodeReactionCreateDto
                  );

                behaviorCodeCreateDto.schoolId = school.id;

                savePromises.push(
                  this.behaviorCodeService.createBehaviorCode(
                    behaviorCodeCreateDto
                  )
                );
              }
            } else {
              this.toastManager.error(
                'There was an error saving Behavior Code, complete default reaction!'
              );
              return;
            }

            const data = await Promise.all(savePromises);

            if (data.length === 1) {
              await this.router.navigate(['/behavior-codes', data[0].id]);
            } else {
              await this.router.navigate(['/behavior-codes']);
            }
          }
        } else {
          this.behaviorCode = await this.behaviorCodeService.updateBehaviorCode(
            this.behaviorCode,
            this.id
          );
        }
        this.toastManager.success('Behavior Code saved successfully!');
      } catch (err) {
        logger.error(err);
        this.toastManager.error(
          'There was an error saving Behavior Code, please try again!'
        );
      }
    }
  }

  public async loadBehaviorCodeReactions() {
    const reactions =
      await this.behaviorCodeReactionsService.getBehaviorCodeReactionsList({
        behaviorCodeId: this.id,
        tableFilters: this.behaviorCodeReactionsTableFilters,
        ...(this.showInactiveBehaviorCodeReactions
          ? { active: false }
          : { active: true })
      });
    this.behaviorCodeReactions = reactions.results;
    this.behaviorCodeReactionsTableFilters.count = reactions.options.totalItems;
  }

  public async showInactiveToggle() {
    this.showInactiveBehaviorCodeReactions =
      !this.showInactiveBehaviorCodeReactions;
    try {
      await this.loadBehaviorCodeReactions();
    } catch (err) {
      logger.error(err);
      this.toastManager.error('Unable to get reactions, please try again!');
    }
  }

  public async updateReactionsTableFilters(tableFilters: ITableFilters) {
    this.isLoading = true;
    try {
      this.behaviorCodeReactionsTableFilters = tableFilters;
      await this.loadBehaviorCodeReactions();
    } catch (err) {
      logger.error(err);
      this.toastManager.error('Unable to get reactions, please try again!');
    }
    this.isLoading = false;
  }

  public async updateInterventionsTableFilters(tableFilters: ITableFilters) {
    this.isLoading = true;
    try {
      this.behaviorCodeInterventionsTableFilters = tableFilters;
      const interventions =
        await this.behaviorCodeInterventionsService.getBehaviorCodeInterventionsList(
          {
            behaviorCodeId: this.id,
            schoolId: this.user.currentSchoolId,
            tableFilters: this.behaviorCodeInterventionsTableFilters
          }
        );
      this.behaviorCodeInterventions = interventions.results;
      this.behaviorCodeInterventionsTableFilters.count =
        interventions.options.totalItems;
    } catch (err) {
      logger.error(err);
      this.toastManager.error('Unable to get interventions, please try again!');
    }
    this.isLoading = false;
  }

  public async updateNotificationsTableFilters(tableFilters: ITableFilters) {
    this.isLoading = true;
    try {
      this.behaviorCodeNotificationsTableFilters = tableFilters;
      const notifications =
        await this.behaviorCodeNotificationsService.getBehaviorCodeNotificationsList(
          {
            behaviorCodeId: this.id,
            schoolId: this.user.currentSchoolId,
            tableFilters: this.behaviorCodeNotificationsTableFilters
          }
        );
      this.behaviorCodeNotifications = notifications.results;
      this.behaviorCodeNotificationsTableFilters.count =
        notifications.options.totalItems;
    } catch (err) {
      logger.error(err);
      this.toastManager.error('Unable to get notifications, please try again!');
    }
    this.isLoading = false;
  }
}
