import { AppAuthComponent } from './auth.component';
import { AppMaterialModule } from '../../app-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppVerifiedLinkComponent } from './verified-link.component';

@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule
  ],
  declarations: [AppAuthComponent, AppVerifiedLinkComponent],
  exports: [AppAuthComponent]
})
export class AppAuthModule {}
