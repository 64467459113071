import { PrinterConfigurationResponseDto } from "@whetstoneeducation/hero-common";

export abstract class BuilderFactory<TBuilder> {

  private printerData: PrinterConfigurationResponseDto;
  protected buildersMapper: Record<string, () => TBuilder> = {};

  constructor(printerData?: PrinterConfigurationResponseDto) {
    this.printerData = printerData;
  }

  public setPrinterData(printerData: PrinterConfigurationResponseDto) {
    this.printerData = printerData;
  }

  public get configuration(): PrinterConfigurationResponseDto {
    return this.printerData;
  }

  getBuilder(): TBuilder | null {
    const languageType = this.configuration?.languageType;
    return languageType ? this.buildersMapper[languageType]() : null;
  }
}
