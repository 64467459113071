<div class="table-container">
  <app-pagination
    *ngIf="context.showPagination"
    [tableFilters]="context.tableFilters"
    (tableFiltersUpdated)="context.updateTableFilters($event)"></app-pagination>
  <mat-table
    class="mat-elevation-z2"
    [dataSource]="context.dataSource"
    matSortDisableClear
    matSort>
    <ng-container
      *ngFor="let column of context.columnKeys"
      matColumnDef="{{column}}">
      <mat-header-cell *matHeaderCellDef>
        <ng-container
          *ngIf="context.getColumnTitle && context.getColumnTitle(column)?.template"
          [ngTemplateOutlet]="context.getColumnTitle(column).template"
          [ngTemplateOutletContext]="context.getColumnTitle(column).context"></ng-container>
        <ng-container
          *ngIf="!context.getColumnTitle || !context.getColumnTitle(column)?.template"
          >{{context.getColumnTitle ? context.getColumnTitle(column) :
          column}}</ng-container
        >
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container
          *ngIf="context.getColumnValue && context.getColumnValue(column, row)?.template"
          [ngTemplateOutlet]="context.getColumnValue(column, row).template"
          [ngTemplateOutletContext]="context.getColumnValue(column, row).context"></ng-container>
        <ng-container
          *ngIf="!context.getColumnValue || !context.getColumnValue(column, row)?.template"
          >{{context.getColumnValue ? context.getColumnValue(column, row) :
          column}}</ng-container
        >
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="context.displayedColumns;">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: context.displayedColumns;">
    </mat-row>
  </mat-table>
</div>
<div
  *ngIf="context.tableFilters.count > context.tableFilters.itemsPerPage"
  class="table-filters-bottom">
  <app-pagination
    *ngIf="context.showPagination"
    [tableFilters]="context.tableFilters"
    (tableFiltersUpdated)="context.updateTableFilters($event)"></app-pagination>
</div>
