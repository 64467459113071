import { Injectable } from '@angular/core';
import { BaseResolver } from '../../../shared/base-classes/base.resolver';
import { IBehaviorCodeNotificationsCreateEditResolverData } from '../behavior-code-notifications-models/behavior-code-notifications-create-edit-resolver-data.interface';
import { AppResolverErrorHandlerService } from '../../../shared/services/resolver-error-handler.service';
import { AppBehaviorCodeNotificationsService } from '../behavior-code-notifications.service';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AppBehaviorCodeNotificationCreateEditResolver extends BaseResolver<IBehaviorCodeNotificationsCreateEditResolverData> {
  constructor(
    public resolverErrorHandler: AppResolverErrorHandlerService,
    private behaviorCodeNotificationsService: AppBehaviorCodeNotificationsService
  ) {
    super(resolverErrorHandler);
  }

  async handleResolve(
    route: ActivatedRouteSnapshot
  ): Promise<IBehaviorCodeNotificationsCreateEditResolverData> {
    const behaviorCodeId = route.paramMap.get('id');
    const behaviorCodeNotificationId = route.paramMap.get(
      'behaviorCodeNotificationId'
    );
    return this.behaviorCodeNotificationsService.getBehaviorCodeNotificationCreateEditResolverData(
      behaviorCodeId,
      behaviorCodeNotificationId
    );
  }
}
