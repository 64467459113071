"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerifiedLinkResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
class VerifiedLinkResponseDto extends mappable_base_1.MappableBase {
    constructor(verifiedLinkResponseOptions) {
        super();
        Object.assign(this, verifiedLinkResponseOptions);
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getFields() {
        return [
            'link',
            'totp',
            'accessCount',
            'complete',
            'completedAt',
            'expiresAt',
            'verifiedLinkType'
        ];
    }
    link;
    accessCount;
    complete;
    completedAt;
    expiresAt;
    verifiedLinkType;
}
exports.VerifiedLinkResponseDto = VerifiedLinkResponseDto;
