import { Component, EventEmitter, Output } from '@angular/core';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import {
  ExportField
} from '@whetstoneeducation/hero-common';
import { ExportBuilderColumns } from '../../export-models/export-builder-columns.enum';
import { AppPrivilegesService } from '../../../auth/privileges.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-export-builder-table',
  templateUrl: './export-builder-table.template.html',
  styleUrls: ['./export-builder-table.scss']
})
export class AppExportBuilderTableComponent extends BaseTableComponent<ExportField> {
  public get displayedColumns() {
    let columns = Object.values(ExportBuilderColumns);
    return columns;
  }

  public columnValues = {
    [ExportBuilderColumns.POSITION]: ['order'],
    [ExportBuilderColumns.FIELDNAME]: ['field'],
    [ExportBuilderColumns.COLUMNHEADER]: ['header'],
    //[ExportBuilderColumns.DATAMODEL]: [''],
  };

  public columns = ExportBuilderColumns;

  @Output() fieldRemoved: EventEmitter<ExportField>;

  constructor(
    public privilegesService: AppPrivilegesService,
    public toastManager: AppToastManagerService,
    // public behaviorCodesService: AppBehaviorCodesService,
    public router: Router,
    private confirmationDialog: AppConfirmationDialogService
  ) {
    super({ privilegesService });
    this.fieldRemoved = new EventEmitter<ExportField>();
  }

  public removeField(field: ExportField): void {
    this.dataArr = this.dataArr.filter((exportField) => {
      exportField != field;
   });
    this.fieldRemoved.emit(field);
    this.updateListOrder();
  }

  /**
   * Move our item to where it was dropped, then re-render our table
  //  */
  public async dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataArr, event.previousIndex, event.currentIndex);
    this.updateListOrder();

    this.table.renderRows();
  }

  public updateListOrder() {
    this.dataArr.forEach((field, index) => {
       field.order = index + 1;
    });
  }
}
