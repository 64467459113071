import { Injectable } from '@angular/core';
import {
  CalendarDayCreateDto,
  SetUpYearDto,
  CalendarDayResponseDto,
  CalendarDayUpdateDto,
  DateUtil,
  ICalenderViewDropdownData,
  IRelation,
  PaginationResultsDto,
  IDisplayData,
  StudentGroupBellScheduleCalendarDayUpdate,
  StudentGroupBellScheduleCalendarDayCreate,
  StudentGroupBellScheduleCalendarDayResponse,
  PaginationDetailsDto
} from '@whetstoneeducation/hero-common';
import { BaseService } from 'src/app/shared/base-classes/base.service';
import { logger } from 'src/app/shared/logger';
import { AppClientDataService } from 'src/app/shared/services/client-data-service/app-client-data.service';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { TableFilterOptions } from 'src/app/shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from 'src/app/shared/tables/table-filters/table-filters-page-key.enum';
import { ICalendarDayCreateEditResolverData } from './calendar-day/calendar-day-models/calendar-day-create-edit-resolver-data.interface';
import { ICalendarViewFilters } from './calendar-view/calendar-view-models/calendar-view-filters.interface';
import { ICalendarViewResolverData } from './calendar-view/calendar-view-models/calendar-view-resolver.data.interface';
import { EventContentArg } from '@fullcalendar/core';

@Injectable({
  providedIn: 'root'
})
export class AppCalendarService extends BaseService {
  constructor(
    private appClientDataService: AppClientDataService,
    private toastService: AppToastManagerService
  ) {
    super();
  }

  public async getCalendarDayById(id: number): Promise<CalendarDayResponseDto> {
    return this.appClientDataService.execute<CalendarDayResponseDto>(
      this.GET_ROUTES.CALENDAR_DAY_BY_ID,
      {
        pathParams: { id }
      }
    );
  }

  public async getCalendarDayByTimestamp(
    timestamp: number
  ): Promise<CalendarDayResponseDto> {
    return this.appClientDataService.execute<CalendarDayResponseDto>(
      this.GET_ROUTES.CALENDAR_DAY_BY_TIMESTAMP,
      {
        pathParams: { timestamp }
      }
    );
  }

  public async getCalendarDayList(
    filters: ICalendarViewFilters
  ): Promise<PaginationResultsDto<CalendarDayResponseDto>> {
    return this.appClientDataService.execute<
      PaginationResultsDto<CalendarDayResponseDto>
    >(this.GET_ROUTES.CALENDAR_DAY_LIST, {
      queryParams: {
        active: !filters.inactive,
        ...(filters.searchInput
          ? {
              name: filters.searchInput,
              notes: filters.searchInput
            }
          : {}),
        ...(filters.tableFilters.itemsPerPage
          ? { itemsPerPage: filters.tableFilters.itemsPerPage }
          : {}),
        ...(filters.tableFilters.page
          ? { page: filters.tableFilters.page }
          : {}),
        ...(filters.startDate ? { startDate: filters.startDate } : {}),
        ...(filters.endDate ? { endDate: filters.endDate } : {}),
        schoolId: filters.schoolId
      }
    });
  }

  public async getCalendar(
    schoolId: number
  ): Promise<CalendarDayResponseDto[]> {
    return this.appClientDataService.execute<CalendarDayResponseDto[]>(
      this.GET_ROUTES.CALENDAR,
      {
        pathParams: {
          schoolId
        }
      }
    );
  }

  public getCalendarViewDefaultFilters(): ICalendarViewFilters {
    const user = this.StorageManager.getLoggedInUser();
    return {
      searchInput: '',
      inactive: false,
      tableFilters: TableFilterOptions.getPageDefault(
        TableFiltersPageKeyEnum.CALENDAR_DAY_VIEW
      ),
      schoolId: user.currentSchoolId,
      ...DateUtil.getMonthStartAndEndDates(new Date()),
      showReactions: false
    };
  }

  public async getCalendarViewResolverData(): Promise<ICalendarViewResolverData> {
    try {
      const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
      const calendarExists = await this.checkIfValidCalendarExists(schoolId);

      if (!calendarExists) {
        return {
          defaultFilters: this.getCalendarViewDefaultFilters(),
          calendarDays: [],
          dropdownData: {
            bellScheduleOptions: [],
            reactionOptions: [],
            studentGroupOptions: []
          },
          noAcademicCalendar: true
        };
      }

      const defaultFilters = this.getCalendarViewDefaultFilters();
      const calendarDayCall = this.getCalendar(schoolId);
      const dropdownDataCall = this.getDropdownData(defaultFilters.schoolId);

      const [calendarDays, dropdownData] = await Promise.all([
        calendarDayCall,
        dropdownDataCall
      ]);

      if (defaultFilters.schoolId) {
        return {
          defaultFilters,
          calendarDays,
          dropdownData
        };
      }
    } catch (error) {
      this.toastService.error('Error loading calendar view');
      logger.error(error);
    }
  }

  public async getCalendarDayCreateEditResolverData(
    id?: number
  ): Promise<ICalendarDayCreateEditResolverData> {
    try {
      const calendarDay = !!id
        ? await this.getCalendarDayById(+id)
        : new CalendarDayResponseDto();
      const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
      return {
        calendarDay,
        schoolId
      };
    } catch (error) {
      this.toastService.error('Error loading calendar day');
      logger.error(error);
    }
  }

  public async createCalendarDay(
    calendarDay: CalendarDayCreateDto
  ): Promise<CalendarDayResponseDto> {
    return this.appClientDataService.execute<CalendarDayResponseDto>(
      this.POST_ROUTES.CREATE_CALENDAR_DAY,
      {
        method: this.METHOD.POST,
        body: calendarDay
      }
    );
  }

  public async updateCalendarDay(
    calendarDay: CalendarDayUpdateDto,
    id: number
  ): Promise<CalendarDayResponseDto> {
    return this.appClientDataService.execute<CalendarDayResponseDto>(
      this.PUT_ROUTES.UPDATE_CALENDAR_DAY,
      {
        method: this.METHOD.PUT,
        pathParams: { id },
        body: calendarDay
      }
    );
  }

  // TODO: rework schedules after school groups - THOR-380
  public async getEditScheduleResolverData(): Promise<{
    bellSchedules: IDisplayData[];
    todaySchedules: any[];
  }> {
    const user = this.StorageManager.getLoggedInUser();
    const [dropdownData, todaySchedules] = await Promise.all([
      this.getDropdownData(user.currentSchoolId),
      this.getTodaySchedule(user.currentSchoolId)
    ]);
    return {
      bellSchedules: dropdownData.bellScheduleOptions,
      todaySchedules
    };
  }

  public async getDropdownData(
    schoolId: number
  ): Promise<ICalenderViewDropdownData> {
    return this.appClientDataService.execute<ICalenderViewDropdownData>(
      this.GET_ROUTES.CALENDAR_DAY_DROPDOWN_DATA,
      {
        pathParams: {
          schoolId
        }
      }
    );
  }

  public async setUpYear(
    massCreateDto: SetUpYearDto
  ): Promise<{ message: string }> {
    return this.appClientDataService.execute<{ message: string }>(
      this.POST_ROUTES.SET_UP_YEAR,
      {
        method: this.METHOD.POST,
        body: massCreateDto
      }
    );
  }

  public async checkIfValidCalendarExists(schoolId: number): Promise<boolean> {
    return this.appClientDataService.execute<boolean>(
      this.GET_ROUTES.CALENDAR_EXISTS,
      {
        pathParams: { schoolId }
      }
    );
  }

  public calendarDayToFullCalendarEvents(
    calendarDay: CalendarDayResponseDto,
    filters: ICalendarViewFilters
  ) {
    const events = [];
    if (calendarDay.scheduledReactions && filters.showReactions) {
      calendarDay.scheduledReactions.forEach((scheduledReaction) => {
        if (
          filters.reactionIds &&
          !filters.reactionIds.includes(scheduledReaction.reaction.id)
        )
          return;

        events.push(
          this.scheduledReactionToFullCalendarEventContentArg(
            calendarDay,
            {
              id: scheduledReaction.id,
              name: scheduledReaction.reaction.name
            },
            events.length + 1
          )
        );
      });
    }

    if (
      !filters.showReactions &&
      calendarDay.studentGroupBellScheduleCalendarDays
    ) {
      for (const studentGroupBellScheduleCalendarDay of calendarDay.studentGroupBellScheduleCalendarDays) {
        if (
          filters.bellScheduleIds &&
          !filters.bellScheduleIds.includes(
            studentGroupBellScheduleCalendarDay.bellSchedule.id
          )
        )
          continue;

        if (
          filters.studentGroupIds &&
          !filters.studentGroupIds?.includes(
            studentGroupBellScheduleCalendarDay.studentGroup.id
          )
        )
          continue;

        events.push(
          this.studentBellScheduleToFullCalendarEventContentArg(
            calendarDay,
            {
              id: studentGroupBellScheduleCalendarDay.id,
              name: `${studentGroupBellScheduleCalendarDay.studentGroup.name}: ${studentGroupBellScheduleCalendarDay.bellSchedule.name}`
            },
            events.length + 1
          )
        );
      }
    }
    return events;
  }

  public async updateStudentGroupBellScheduleCalendarDays(
    updateDtos: StudentGroupBellScheduleCalendarDayUpdate[]
  ) {
    return this.appClientDataService.execute(
      this.PUT_ROUTES.UPDATE_STUDENT_GROUP_BELL_SCHEDULE_CALENDAR_DAYS,
      {
        method: this.METHOD.PUT,
        body: updateDtos
      }
    );
  }

  public async createStudentGroupBellScheduleCalendarDays(
    createDtos: StudentGroupBellScheduleCalendarDayCreate[]
  ) {
    return this.appClientDataService.execute(
      this.POST_ROUTES.CREATE_STUDENT_GROUP_BELL_SCHEDULE_CALENDAR_DAYS,
      {
        method: this.METHOD.POST,
        body: createDtos
      }
    );
  }

  // TODO: rework schedules after school groups - THOR-380
  public async getTodaySchedule(schoolId: number): Promise<any> {
    return this.appClientDataService.execute<CalendarDayResponseDto>(
      this.GET_ROUTES.TODAY_SCHEDULE,
      {
        pathParams: {
          schoolId
        }
      }
    );
  }

  // TODO: rework schedules after school groups - THOR-380
  public async editSchedule(schedules: any[]) {
    // return this.appClientDataService.execute(this.PUT_ROUTES.UPDATE_SCHEDULE, {
    //   method: this.METHOD.PUT,
    //   body: {
    //     schedules
    //   }
    // });
    return [];
  }

  public async deleteScheduledReaction(id: number) {
    return this.appClientDataService.execute(
      this.DELETE_ROUTES.DELETE_SCHEDULED_REACTION,
      {
        method: this.METHOD.DELETE,
        pathParams: {
          id
        }
      }
    );
  }
  private getEventColors = (index) => {
    return ['#D4E6F2', '#DBDBF5', '#C0E7D0'][index % 3];
  };

  private studentBellScheduleToFullCalendarEventContentArg(
    calendarDay: CalendarDayResponseDto,
    studentBellSchedule: IRelation,
    index: number
  ) {
    return {
      id: studentBellSchedule.id,
      title: studentBellSchedule.name,
      date: new Date(calendarDay.date),
      allDay: true,
      backgroundColor: this.getEventColors(index),
      border: '#FFFFFF',
      textColor: '#3b3f44',
      extendedProps: {
        type: 'bellSchedule',
        data: studentBellSchedule
      }
    };
  }

  private scheduledReactionToFullCalendarEventContentArg(
    calendarDay: CalendarDayResponseDto,
    scheduledReaction: IRelation,
    index: number
  ) {
    const date = new Date(calendarDay.date);
    // set hours to end of day so for timezone conversions should still be same day
    // also exact time does not matter here since its just about the day
    date.setUTCHours(23);
    return {
      id: scheduledReaction.id,
      title: scheduledReaction.name,
      date: date.toISOString(),
      allDay: true,
      backgroundColor: this.getEventColors(index),
      textColor: '#3b3f44',
      extendedProps: {
        type: 'scheduledReaction',
        data: scheduledReaction
      }
    };
  }
}
