<div class="mat-dialog-header">
  <div class="mat-dialog-title">
    Behavior Tracking:&nbsp; {{reactionEntry.behaviorCodeName}}
  </div>
  <fa-icon (click)="dialogRef.close()" [icon]="['fas', 'close']"></fa-icon>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div mat-dialog-content>
  <div class="student-card">
    <app-student-profile-photo
      [selectedImage]="reactionEntry.profilePhotoUrl"
      [studentId]="reactionEntry.studentId"
      [hideProfileImageSelector]="true"
      [imageWidth]="50"
      [imageHeight]="50">
    </app-student-profile-photo>
    <div class="reaction-info">
      <h4>{{reactionEntry.studentName}}</h4>
    </div>
  </div>
  <mat-form-field
    class="full-width-input"
    *ngIf="!isLoading && reactionOptions.length > 0">
    <mat-label>Update Reaction</mat-label>
    <mat-select
      *ngIf="!isLoading"
      placeholder="Select an option"
      [formControl]="behaviorCodeReactionControl">
      <mat-option *ngFor="let option of reactionOptions" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
    class="full-width-input"
    *ngIf="!isLoading && scheduledReactionOptions.length > 0">
    <mat-label>Update Date</mat-label>
    <mat-select
      placeholder="Select an option"
      [formControl]="selectedScheduledReactionControl">
      <mat-option
        *ngFor="let option of scheduledReactionOptions"
        [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-select>
    <ng-container *ngIf="scheduledReactionOptions.length === 0">
      <p>No scheduled reactions available</p>
    </ng-container>
  </mat-form-field>

  <div class="actions">
    <button mat-button (click)="close()">Cancel</button>
    <button
      [disabled]="reactionEntry.behaviorCodeReactionId === behaviorCodeReactionControl.value && reactionEntry.scheduledReactionId === selectedScheduledReactionControl.value"
      type="submit"
      mat-flat-button
      color="primary"
      (click)="updateReaction()">
      Save
    </button>
  </div>
</div>
