import { MappableBase } from '@whetstoneeducation/hero-common';
import { IsDate, IsNotEmpty, IsString } from 'class-validator';

export class CalendarDayEditCreateFormDto extends MappableBase {
  constructor(calendarDayEditCreateFormDtoOptions: {
    date: Date;
    name: string;
    notes: string;
  }) {
    super();
    if (!calendarDayEditCreateFormDtoOptions) return;
    this.date = calendarDayEditCreateFormDtoOptions.date;
    this.name = calendarDayEditCreateFormDtoOptions.name;
    this.notes = calendarDayEditCreateFormDtoOptions.notes;
  }

  getFields(): string[] {
    return ['date', 'name', 'notes'];
  }

  @IsDate()
  date: Date;

  @IsString()
  name: string;

  @IsString()
  notes: string;
}
