"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterOptionsDto = exports.FilterOption = void 0;
const class_validator_1 = require("class-validator");
const pagination_options_dto_1 = require("./pagination-options.dto");
class FilterOption {
    constructor(field, value) {
        this.field = field;
        this.value = value;
    }
    field;
    value;
}
exports.FilterOption = FilterOption;
class FilterOptionsDto {
    constructor(options) {
        if (!options)
            return;
        this.filterOptions = options.filterOptions;
        this.paginationOptions = options.paginationOptions;
    }
    filterOptions = [];
    paginationOptions;
}
exports.FilterOptionsDto = FilterOptionsDto;
__decorate([
    (0, class_validator_1.IsArray)(),
    __metadata("design:type", Array)
], FilterOptionsDto.prototype, "filterOptions", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    __metadata("design:type", pagination_options_dto_1.PaginationOptionsDto)
], FilterOptionsDto.prototype, "paginationOptions", void 0);
