"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchoolGroupUpdateDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_validator_1 = require("class-validator");
const school_group_settings_dto_1 = require("./school-group-settings.dto");
const nested_dto_config_1 = require("../../nested-dto-config");
const transform_config_1 = require("../../transform-config");
const class_transformer_1 = require("class-transformer");
class SchoolGroupUpdateDto extends mappable_base_1.MappableBase {
    constructor(options) {
        super();
        if (!options)
            return;
        this.name = options.name;
    }
    getFields() {
        return ['id', 'name'];
    }
    getNested() {
        return [nested_dto_config_1.NestedDtoConfig.relationConfig('settings', 'settings')];
    }
    getTransforms() {
        return [
            {
                destination: 'settings',
                transform: transform_config_1.TransformConfig.transformArray('settings', (source) => {
                    const settings = source.reduce((acc, setting) => {
                        acc[setting.setting] = setting.value;
                        return acc;
                    }, {});
                    return new school_group_settings_dto_1.SchoolGroupSettings(settings);
                })
            }
        ];
    }
    id;
    name;
    settings;
}
exports.SchoolGroupUpdateDto = SchoolGroupUpdateDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SchoolGroupUpdateDto.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], SchoolGroupUpdateDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsObject)(),
    (0, class_transformer_1.Type)(() => school_group_settings_dto_1.SchoolGroupSettings),
    __metadata("design:type", school_group_settings_dto_1.SchoolGroupSettings)
], SchoolGroupUpdateDto.prototype, "settings", void 0);
