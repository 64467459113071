"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleUpdateDto = void 0;
const class_validator_1 = require("class-validator");
const mappable_base_1 = require("../../mappable.base");
const enums_1 = require("../../enums");
const role_create_dto_1 = require("./role-create.dto");
class RoleUpdateDto extends mappable_base_1.MappableBase {
    constructor(roleOptions) {
        super();
        if (!roleOptions)
            return;
        Object.assign(this, roleOptions);
    }
    getFields() {
        return ['id', 'name', 'privileges', 'schoolId'];
    }
    getTransforms() {
        return [
            {
                sourceFields: ['behaviorCodeTypes'],
                destination: 'behaviorCodeTypes',
                transform: (source) => {
                    if (source instanceof role_create_dto_1.RoleCreateDto ||
                        source instanceof RoleUpdateDto) {
                        return source.behaviorCodeTypes;
                    }
                    return source.behaviorCodeTypes?.map((type) => type.id);
                }
            }
        ];
    }
    mapFields(source, skipNested = false, skipTransforms = false) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    id;
    name;
    privileges;
    behaviorCodeTypes;
}
exports.RoleUpdateDto = RoleUpdateDto;
__decorate([
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], RoleUpdateDto.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RoleUpdateDto.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.ArrayUnique)(),
    (0, class_validator_1.IsEnum)(enums_1.PrivilegeEnum, { each: true }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], RoleUpdateDto.prototype, "privileges", void 0);
__decorate([
    (0, class_validator_1.IsNumber)({}, { each: true }),
    (0, class_validator_1.IsPositive)({ each: true }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], RoleUpdateDto.prototype, "behaviorCodeTypes", void 0);
