import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  SchoolActivityReportFiltersDto,
  SchoolActivityReportRowResponseDto,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import {
  BaseCommonTableComponent,
  ITemplateContext
} from '../../../../shared/tables/common-table/base-common-table.component';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppReportsService } from '../../reports.service';
import { AppPageHeaderService } from '../../../../shared/page-header/page-header.service';
import { AppConfirmationDialogService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { HeaderButtonAction } from '../../../../shared/page-header/header-button';
import { DEFAULT_DIALOG_RESULT } from '../../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { TableFilterOptions } from '../../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../../shared/tables/table-filters/table-filters-page-key.enum';

export enum SchoolActivityReportTableColumns {
  USER = 'User',
  BEHAVIORS_TRACKED = 'Behaviors Tracked',
  POINTS_ISSUED = 'Points Issued',
  REFERRALS = 'Referrals'
}

interface IActionsColumnContext {
  row: SchoolActivityReportRowResponseDto;
}

@Component({
  selector: 'app-school-activity-report-table',
  templateUrl: './school-activity-report-table.template.html',
  styleUrls: ['school-activity-report.scss']
})
export class AppSchoolActivityReportTable extends BaseCommonTableComponent<
  SchoolActivityReportRowResponseDto,
  SchoolActivityReportTableColumns
> {
  @ViewChild('actionsColumn')
  public actionsColumn: TemplateRef<ITemplateContext<IActionsColumnContext>>;
  public columns;
  public filters: SchoolActivityReportFiltersDto;
  public isInstructor: boolean = false;
  public totalBehaviorsCount: { name: string; totalBehaviors: number }[] = [];
  public totalReferrals: number = 0;
  public totalPoints: number = 0;
  private destroy$ = new Subject<void>();

  public constructor(
    public route: ActivatedRoute,
    public reportService: AppReportsService,
    private pageHeaderService: AppPageHeaderService,
    public confirmationDialog: AppConfirmationDialogService,
    public toastService: AppToastManagerService
  ) {
    super();
    this.templates = {};
    this.columns = SchoolActivityReportTableColumns;
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case SchoolActivityReportTableColumns.USER:
        return row.userName;
      case SchoolActivityReportTableColumns.BEHAVIORS_TRACKED:
        return row.behaviorsTracked || 0;
      case SchoolActivityReportTableColumns.POINTS_ISSUED:
        return row.pointsIssued || 0;
      case SchoolActivityReportTableColumns.REFERRALS:
        return row.referrals || 0;
      default:
        return null;
    }
  }

  public ngOnInit() {
    this.loadData();
    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: string) => {
        if (action === HeaderButtonAction.EXPORT) {
          const ref = this.confirmationDialog.openDialog({
            title: `School Activity Export`,
            content:
              'Are you sure you want to export the school activity report?'
          });
          ref.afterClosed().subscribe(async (result: string) => {
            if (result === DEFAULT_DIALOG_RESULT.YES) {
              await this.reportService.getSchoolActivityReport(
                this.filters,
                true
              );
              this.toastService.success(
                'Export started, we will email you with a link to the file'
              );
            }
          });
        }
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    this.isLoading = true;
    const currentUser = this.StorageManager.getLoggedInUser();
    const schoolId = currentUser.currentSchoolId;
    const userId = this.isUserType([UserTypeEnum.TEACHER])
      ? currentUser.id
      : null;

    if (userId) {
      this.pageHeaderService.changeSubtitle('My Activity');
    }

    this.isInstructor = !!userId;
    const data = this.route.snapshot.data.data;
    this.dataArr = data.results[0].results;
    this.totalBehaviorsCount = data.results[0].totalBehaviors;
    this.totalReferrals = data.results[0].totalReferrals;
    this.totalPoints = data.results[0].totalPoints;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.SCHOOL_ACTIVITY_REPORT
    );
    this.filters = this.reportService.getSchoolActivityReportDefaultFilters(
      schoolId,
      userId
    );
    this.tableFilters.count = data.options.totalItems;
    this.showPagination =
      this.tableFilters.itemsPerPage < this.tableFilters.count;
    this.isLoading = false;
  }

  public onFiltersUpdated(filters: SchoolActivityReportFiltersDto) {
    this.filters.mapFields(filters);
  }

  public onInstantFiltersUpdated(filters: SchoolActivityReportFiltersDto) {
    this.onFiltersUpdated(filters);
    this.applyFilters();
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.filters = new SchoolActivityReportFiltersDto({
      ...this.filters,
      ...newTableFilters,
      active: true,
      noActivityUsers: false
    });

    await this.applyFilters();
  }

  public async applyFilters(): Promise<void> {
    this.isLoading = true;
    const response = await this.reportService.getSchoolActivityReport(
      this.filters
    );
    const data = response.results[0];
    this.dataArr = data.results;
    this.totalBehaviorsCount = data.totalBehaviors;
    this.totalReferrals = data.totalReferrals;
    this.totalPoints = data.totalPoints;
    this.tableFilters.count = response.options.totalItems;
    this.showPagination =
      this.tableFilters.itemsPerPage < this.tableFilters.count;
    this.isLoading = false;
  }
}
