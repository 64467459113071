import { logger } from '../../shared/logger';
import { SftpAccountCredentialsDto } from '@whetstoneeducation/hero-common';
import { SftpAccountExistingBase } from './sftp-account-existing-base';

export class SftpAccountCredentialsBase extends SftpAccountExistingBase {
  public accountCredentials: SftpAccountCredentialsDto;
  public credentialsSaved = false;
  public sftpHost = 'sftp.hero.schoolmint.com';
  public sftpPort = '2022';

  copyCredentials(): void {
    const textToCopy = `Host: sftp://${this.sftpHost}\nPort: ${this.sftpPort}\nAccount ID: ${this.accountCredentials.accountId}\nPassword: ${this.accountCredentials.accountPassword}`;
    this.copyTextToClipboard(textToCopy);
    this.credentialsSaved = true;
  }

  copyTextToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        logger.log('Text copied to clipboard.');
      })
      .catch((err) => {
        logger.error('Could not copy text: ', err);
      });
  }

  downloadCredentials(): void {
    const textToDownload = `Host: sftp://${this.sftpHost}\nPort: ${this.sftpPort}\nAccount ID: ${this.accountCredentials.accountId}\nPassword: ${this.accountCredentials.accountPassword}`;
    this.downloadAsTextFile('credentials.txt', textToDownload);
    this.credentialsSaved = true;
  }

  downloadAsTextFile(filename: string, text: string): void {
    const blob = new Blob([text], { type: 'text/plain' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    URL.revokeObjectURL(a.href);
  }
}
