"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportFileErrorsEnum = void 0;
var ImportFileErrorsEnum;
(function (ImportFileErrorsEnum) {
    ImportFileErrorsEnum["STUDENT_VALIDATION_ERROR"] = "STUDENT_VALIDATION_ERROR";
    ImportFileErrorsEnum["SQL_ERROR"] = "SQL_ERROR";
    ImportFileErrorsEnum["USER_TYPE_VALIDATION_ERROR"] = "USER_TYPE_VALIDATION_ERROR";
    ImportFileErrorsEnum["USER_VALIDATION_ERROR"] = "USER_VALIDATION_ERROR";
    ImportFileErrorsEnum["STUDENT_NOT_FOUND"] = "STUDENT_NOT_FOUND";
    ImportFileErrorsEnum["PERIOD_NOT_FOUND"] = "PERIOD_NOT_FOUND";
    ImportFileErrorsEnum["TERM_NOT_FOUND"] = "TERM_NOT_FOUND";
    ImportFileErrorsEnum["STUDENT_ID_NOT_FOUND"] = "STUDENT_ID_NOT_FOUND";
    ImportFileErrorsEnum["GUARDIAN_ROLE_NOT_FOUND"] = "GUARDIAN_ROLE_NOT_FOUND";
    ImportFileErrorsEnum["GUARDIAN_ASSOCIATION_ERROR"] = "GUARDIAN_ASSOCIATION_ERROR";
    ImportFileErrorsEnum["STUDENT_GROUP_ASSOCIATION_ERROR"] = "STUDENT_GROUP_ASSOCIATION_ERROR";
    ImportFileErrorsEnum["SCHOOL_NOT_FOUND"] = "SCHOOL_NOT_FOUND";
    ImportFileErrorsEnum["SCHOOL_MISMATCH"] = "SCHOOL_MISMATCH";
    ImportFileErrorsEnum["USER_IMPORT_ERROR"] = "USER_IMPORT_ERROR";
    ImportFileErrorsEnum["COURSE_SCHEDULE_IMPORT_ERROR"] = "COURSE_SCHEDULE_IMPORT_ERROR";
    ImportFileErrorsEnum["STUDENT_IMPORT_ERROR"] = "STUDENT_IMPORT_ERROR";
})(ImportFileErrorsEnum || (exports.ImportFileErrorsEnum = ImportFileErrorsEnum = {}));
