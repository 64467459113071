import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'app-warning-banner-component',
  templateUrl: './warning-banner.template.html',
  styleUrls: ['./warning-banner.scss', './warning-banner-import.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WarningBannerComponent {

  @Input()
  public showBannerIcon?: boolean = true;

  @Input()
  public bannerClass?: string = null;

  get bannerCustomClass() {
    return `banner-container ${this.bannerClass ?? ''}`.trim();
  }

}
