<div class="mat-dialog-container">
  <div class="mat-dialog-header default-dialog-header">
    <div class="mat-dialog-title">View Communication</div>
  </div>
  <mat-dialog-content>
    <div class="view-communication-dialog">
      <div class="view-communication-subject">
        <div [innerHTML]="subject"></div>
        <div [innerHTML]="body"></div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button color="primary" (click)="onPrint()">
      <i class="fa fa-times"></i>
      Print
    </button>
    <button mat-flat-button color="primary" (click)="onClose()">
      <i class="fa fa-times"></i>
      Close
    </button>
  </mat-dialog-actions>
</div>

