import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CalendarDayResponseDto,
  ICalenderViewDropdownData
} from '@whetstoneeducation/hero-common';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { logger } from '../../../shared/logger';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { AppCalendarService } from '../calendar.service';
import { ICalendarViewFilters } from './calendar-view-models/calendar-view-filters.interface';
import { ICalendarViewResolverData } from './calendar-view-models/calendar-view-resolver.data.interface';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDayBellScheduleManageModalComponent } from '../calendar-day-bell-schedule-manage-modal/calendar-day-bell-schedule-manage-modal.component';
import { CalendarDayScheduledReactionManageModalComponent } from '../calendar-day-scheduled-reaction-manage-modal/calendar-day-scheduled-reaction-manage-modal.component';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.template.html',
  styleUrls: ['./calendar-view.scss']
})
export class AppCalendarViewComponent extends BaseComponent implements OnInit {
  public calendarDays: CalendarDayResponseDto[];

  public dropdownData: ICalenderViewDropdownData;

  public filters: ICalendarViewFilters;

  isLoading = true;

  constructor(
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public calendarService: AppCalendarService,
    public router: Router,
    public dialog: MatDialog
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  public async loadData() {
    this.isLoading = true;
    const data = this.route.snapshot.data.data as ICalendarViewResolverData;
    if (data.noAcademicCalendar) {
      this.toastService.error('Academic calendar not found');
      return await this.router.navigate(['/calendar/set-up-year']);
    }

    this.dropdownData = data.dropdownData;
    this.calendarDays = data.calendarDays;
    this.filters = data.defaultFilters;
    this.isLoading = false;
  }

  public async updateCalendarList(): Promise<void> {
    try {
      const schoolId = this.StorageManager.getCurrentSchoolId();
      this.calendarDays = await this.calendarService.getCalendar(schoolId);
    } catch (error) {
      this.toastService.error('Error retrieving calendar days');
      logger.error(error);
    }
  }

  public async updateFilters(newFilters: ICalendarViewFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    this.isLoading = false;
  }

  public async handleDateSelected(timestamp: number): Promise<void> {
    if (!this.filters.showReactions) {
      this.dialog.open(CalendarDayBellScheduleManageModalComponent, {
        width: '568px',
        panelClass: 'default-dialog',
        autoFocus: false,
        data: {
          timestamp,
          onClose: async () => {
            await this.updateCalendarList();
          }
        }
      });
    } else {
      this.dialog.open(CalendarDayScheduledReactionManageModalComponent, {
        width: '568px',
        panelClass: 'default-dialog',
        autoFocus: false,
        data: {
          timestamp,
          onClose: async () => {
            await this.updateCalendarList();
          }
        }
      });
    }
  }
}
