import {
  Component,
  DoCheck,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CustomRostersResponseDto,
  IRelation,
  PrivilegeEnum
} from '@whetstoneeducation/hero-common';
import { IStudentsListFilters } from '../../students/students-list-models/students-list-filters.interface';
import { logger } from '../../../shared/logger';
import { AppCustomRostersService } from '../custom-rosters.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { ICustomRostersResolverData } from '../custom-roster-models/custom-rosters-resolver-data.interface';
import { MatDialog } from '@angular/material/dialog';
import { AppCreateEditCustomRosterModalComponent } from '../create-edit-custom-roster-modal/create-edit-custom-roster-modal.component';
import { MenuAction } from '../../../shared/menu/menu-action.interface';
import { AppStudentsService } from '../../students/students.service';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { AppPrivilegesService } from '../../auth/privileges.service';
import { TrackableStudentList } from '../../../shared/trackable-student-list/trackable-student-list.component';

@Component({
  selector: 'app-custom-rosters-view',
  templateUrl: './custom-rosters-view.component.html',
  styleUrls: ['./custom-rosters-view.component.scss']
})
export class AppCustomRostersViewComponent
  extends TrackableStudentList
  implements DoCheck, OnInit, OnDestroy
{
  public customRosterOptions: IRelation[];
  public customRoster: CustomRostersResponseDto | null;
  public filters: IStudentsListFilters = {};
  public selectStudent = new EventEmitter<number>();
  public sortActionButtons: MenuAction[] = [];
  public bulkActionButtons: MenuAction[] = [];
  constructor(
    public route: ActivatedRoute,
    public customRosterService: AppCustomRostersService,
    public toastService: AppToastManagerService,
    public studentsService: AppStudentsService,
    public confirmationDialogService: AppConfirmationDialogService,
    public router: Router,
    public dialog: MatDialog,
    public privilegesService: AppPrivilegesService
  ) {
    super(
      dialog,
      confirmationDialogService,
      toastService,
      studentsService,
      privilegesService
    );
  }

  ngOnInit() {
    this.loadData();
    this.selectStudent.subscribe((studentId) => {
      this.toggleStudentSelected(studentId);
    });
  }

  ngDoCheck() {
    this.bulkActionButtons = [
      {
        key: 'RESET_PASSWORDS',
        title: `Reset Passwords (${this.selectedStudentsIds.length})`,
        hide:
          !this.hasPrivilege(PrivilegeEnum.RESET_STUDENT_PASSWORDS) ||
          !this.selectedStudentsIds?.length,
        disabled: !this.selectedStudentsIds?.length,
        onClick: () => this.showPasswordResetModal()
      }
    ];
    this.sortActionButtons = [
      {
        key: 'SORT_FIRST_NAME',
        title: 'First Name',
        onClick: () => this.setSortField('firstName')
      },
      {
        key: 'SORT_LAST_NAME',
        title: 'Last Name',
        onClick: () => this.setSortField('lastName')
      },
      {
        key: 'SORT_EXT_ID',
        title: 'Student ID',
        onClick: () => this.setSortField('studentExtId')
      }
    ];
  }

  ngOnDestroy() {
    this.selectStudent.unsubscribe();
  }

  loadData() {
    const data = this.route.snapshot.data.data as ICustomRostersResolverData;
    this.customRosterOptions = data.customRosters;
    this.customRoster = data.customRoster;
    this.students = data.customRoster?.students || [];
  }

  public async updateFilters(newFilters: IStudentsListFilters): Promise<void> {
    this.isLoading = true;
    this.filters = newFilters;
    await this.updateCustomRoster(this.customRoster.id);
    this.isLoading = false;
  }

  public async updateCustomRoster(customRosterId: number): Promise<void> {
    try {
      this.customRoster = await this.customRosterService.getCustomRoster(
        customRosterId,
        this.filters
      );
    } catch (err) {
      this.toastService.error('Error retrieving roster!');
      logger.error(err);
    }
  }

  public async updateCustomRosters(): Promise<void> {
    this.isLoading = true;
    try {
      this.customRosterOptions =
        await this.customRosterService.getCustomRosters();
      if (this.customRosterOptions.length > 0)
        this.customRoster = await this.customRosterService.getCustomRoster(
          this.customRosterOptions[0].id
        );
    } catch (err) {
      this.toastService.error('Error retrieving rosters!');
      logger.error(err);
    }
    this.isLoading = false;
  }

  public showCreateCustomRosterModal() {
    this.dialog
      .open(AppCreateEditCustomRosterModalComponent, {
        width: '600px',
        panelClass: 'default-dialog',
        data: {
          onClose: this.updateCustomRosters.bind(this)
        }
      })
      .afterClosed()
      .subscribe(async () => {
        this.isLoading = true;
        await this.updateCustomRosters();
        this.isLoading = false;
      });
  }

  public async handleSelectRoster(rosterId: number) {
    this.isLoading = true;
    this.filters = {};
    await this.updateCustomRoster(rosterId);
    this.isLoading = false;
  }
}
