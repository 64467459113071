import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IStudentCodeBreakdown, IStudentRecentPerformance, StudentProfileResponseDto } from "@whetstoneeducation/hero-common";
import ApexCharts from "apexcharts";
import { ApexStroke, ApexTitleSubtitle, ChartType } from "ng-apexcharts";


interface ILineGraphSeries {
  name: string;
  data: number[];
}

interface ILineGraphOptions {
  series: ILineGraphSeries[];
  chart: {
    id: string;
    height: number;
    width: number;
    type: ChartType;
    zoom: {
      enabled: boolean;
    };
  };
  dataLabels: {
    enabled: boolean;
  };
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  grid: {
    row: {
      colors: string[];
      opacity: number;
    };
  };
  xaxis: {
    categories: string[];
  };
}

interface IRadarChartSeries {
  name: string;
  data: number[];
}

// this is the interface for the radar chart options
// which is currently wip and not complete yet
interface IRadarChartOptions {
  series: IRadarChartSeries[];
  chart: {
    height: number;
    type: ChartType;
  };
  title: ApexTitleSubtitle;
  xaxis: {
    categories: string[];
  };
}

@Component({
  selector: 'app-student-profile-metrics',
  templateUrl: './student-profile-metrics.template.html',
  styleUrls: ['./student-profile-metrics.scss']
})
export class StudentProfileMetricsComponent implements AfterViewInit, OnDestroy, OnInit {


  public radarChartOptions: IRadarChartOptions;
  public lineGraphOptions: ILineGraphOptions;
  private resizeObserver: ResizeObserver;

  @ViewChild('chartCardContent', { static: false })
  chartCardContent: ElementRef;

  @Input()
  public profile: StudentProfileResponseDto;


  constructor(
    private ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  public ngAfterViewInit(): void {
    this.lineGraphOptions.chart.width = this.getChartWidth();
  }

  public ngOnDestroy(): void {
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  private loadData(): void {
    this.lineGraphOptions = this.prepareLineGraphData(this.profile.profileData);
    this.radarChartOptions = this.prepareRadarChartData([]);
  }

  private getChartWidth(): number {
    this.watchContainerResize();
    return this.chartCardContent.nativeElement.offsetWidth - 50;
  }

  private watchContainerResize() {
    this.resizeObserver = new ResizeObserver((entries) => {
      let lastWidth = 0;
      for (let entry of entries) {
        lastWidth = entry.contentRect.width;
      }

      this.updateChartWidth(lastWidth);
    });

    this.resizeObserver.observe(this.chartCardContent.nativeElement);
  }

  private prepareLineGraphData(
    profileData: IStudentRecentPerformance[]
  ): ILineGraphOptions {
    // Convert points to numbers and compliedDate to date strings
    const dataWithParsedPoints = profileData.map((data) => ({
      ...data,
      points: data.points ? +data.points : 0,
      compliedDate: data.compliedDate
        ? new Date(data.compliedDate).toLocaleDateString()
        : null
    }));

    // Group and sum points by compliedDate
    const pointsSumByDate: { [key: string]: number } = {};
    dataWithParsedPoints.forEach((data) => {
      if (data.compliedDate) {
        pointsSumByDate[data.compliedDate] =
          (pointsSumByDate[data.compliedDate] || 0) + data.points;
      }
    });

    const categories = Object.keys(pointsSumByDate);
    const dataPoints = Object.values(pointsSumByDate);

    // return the options object
    return {
      series: [
        {
          name: 'Points Change',
          data: dataPoints
        }
      ],
      chart: {
        id: 'pointsRateOfChange',
        width: 500,
        height: 325,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'Points Rate of Change by Day',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: categories
      }
    };
  }

  private prepareRadarChartData(
    profileData: IStudentCodeBreakdown[]
  ): IRadarChartOptions {
    // Convert points to numbers
    const dataWithParsedPoints = profileData.map((data) => ({
      ...data,
      points: data.points ? +data.points : 0 // Assuming points can be null and defaulting to 0
    }));

    // Group and sum points by behaviorCodeType
    const pointsSumByBehavior: { [key: string]: number } = {};
    dataWithParsedPoints.forEach((data) => {
      const { behaviorCodeType, points } = data;
      pointsSumByBehavior[behaviorCodeType] =
        (pointsSumByBehavior[behaviorCodeType] || 0) + points;
    });

    const categories = Object.keys(pointsSumByBehavior);
    const dataPoints = Object.values(pointsSumByBehavior);

    // Prepare the options object for ApexCharts
    return {
      series: [
        {
          name: 'Behavior Points',
          data: dataPoints
        }
      ],
      chart: {
        height: 500,
        type: 'radar'
      },
      title: {
        text: 'Behavior Points Distribution'
      },
      xaxis: {
        categories: categories
      }
    };
  }

  private debounce(func: Function, wait: number): Function {
    let timeout;
    return (...args) => {
      const later = () => {
        clearTimeout(timeout);
        // run in the angular execution context
        this.ngZone.run(() => {
          // Ensures change detection is triggered
          func.apply(this, args);
        });
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  private updateChartWidth = this.debounce((lastWidth: number) => {
    this.lineGraphOptions.chart.width = lastWidth;
    ApexCharts.exec(
      'pointsRateOfChange',
      'updateOptions',
      { chart: { width: lastWidth } },
      false,
      true
    );
  }, 250);

}
