"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateUtil = void 0;
class DateUtil {
    static readableDateOptions = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    };
    static daysInTheFuture(days) {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }
    static convertTimestampToDisplayDate(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }
    static convertTimestampToDisplayTime(timestamp) {
        const date = new Date(timestamp);
        const hour = date.getHours();
        const minute = `0${date.getMinutes()}`.slice(-2);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minute} ${ampm}`;
    }
    static convertTimestampToReadableDate(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', this.readableDateOptions);
    }
    static setTime(date, hour, minute) {
        date.setHours(hour);
        date.setMinutes(minute);
        return date;
    }
    // generates date objects for x days in the future
    static generateDates(days) {
        const dates = [];
        for (let i = 0; i < days; i++) {
            dates.push(this.daysInTheFuture(i));
        }
        return dates;
    }
    // gets timestamp of beginning and end of month and returns them in an object { startDate: number, endDate: number }
    static getMonthStartAndEndDates(date) {
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
        return { startDate, endDate };
    }
    // TODO - pick actual dates
    static getEarliestPossibleDate() {
        return 1594364400000;
    }
    static getLatestPossibleDate() {
        return 1752189905726;
    }
}
exports.DateUtil = DateUtil;
