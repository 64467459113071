<div class="mat-dialog-container">
  <div class="mat-dialog-header default-dialog-header">
    <div class="mat-dialog-title">Bell Schedule Period Time</div>
  </div>
  <mat-dialog-content class="period-form-content">
    <form [formGroup]="periodForm" novalidate>
      <div class="period-content">
        <!-- hidden inputs for bellScheduleId and bellSchedulePeriodId -->
        <input
          *ngIf="isCreating"
          type="hidden"
          formControlName="bellScheduleId"
          [value]="periodTime.bellScheduleId" />
        <input
          *ngIf="isCreating"
          type="hidden"
          formControlName="bellSchedulePeriodId"
          [value]="periodTime.bellSchedulePeriodId" />
        <!-- Type ahead period select dropdown -->
        <div class="form-row" *ngIf="isCreating">
          <mat-form-field class="full-width-input">
            <input
              matInput
              [formControl]="periodRefCode"
              placeholder="Period Ref. Code"
              [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                (onSelectionChange)="onPeriodSelected($event)"
                *ngFor="let period of filteredPeriods | async"
                [value]="period.value">
                {{ period.display }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="full-width-input">
            <mat-label>Start Time</mat-label>
            <input matInput type="time" formControlName="start" />
            <app-error-display
              [form]="periodForm"
              controlName="start"></app-error-display>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field class="full-width-input">
            <mat-label>End Time</mat-label>
            <input matInput type="time" formControlName="end" />
            <app-error-display
              [form]="periodForm"
              controlName="end"></app-error-display>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="mat-dialog-actions">
    <button mat-flat-button color="primary" (click)="onSave()">
      <i class="fa fa-save"></i>
      Save
    </button>
    <button mat-flat-button color="primary" (click)="onClose()">
      <i class="fa fa-times"></i>
      Close
    </button>
  </mat-dialog-actions>
</div>
