<div class="container">
  <div class="no-school" *ngIf="!schoolId">
    Bell Schedule Period Manager page can only be accessed for an individual
    school. Please select one and try again!
  </div>
  <!-- This table does not need filters -->
  <app-base-common-table-portal [context]="this"></app-base-common-table-portal>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <span *ngIf="!(dataArr.length || isLoading)">
    There are no records to show
  </span>

  <ng-template #actionsColumn let-row="row">
    <button
      mat-flat-button
      class="outline-button icon-button"
      (click)="editPeriod(row)">
      <fa-icon [icon]="['fas', 'pencil']"></fa-icon>
    </button>
    <button
      mat-flat-button
      class="outline-button icon-button"
      (click)="removePeriod(row)">
      <fa-icon [icon]="['fas', 'trash']"></fa-icon>
    </button>
  </ng-template>
</div>
