import { environment } from '../../environments/environment';

class Logger {
  public static debug(message?: any, ...optionalParams: any[]) {
    if (environment.production) {
      return;
    }
    console.debug(message, ...optionalParams);
    // By default, don't send debug logs to the API.
    // this can be uncommented if we want to send debug logs to the API.
    // Logger.sendAPILog('debug', message);
  }
  public static log(message?: any, ...optionalParams: any[]) {
    console.log(message, ...optionalParams);
    Logger.sendAPILog('log', message);
  }

  public static error(message?: any, ...optionalParams: any[]) {
    console.error(message, ...optionalParams);
    Logger.sendAPILog('error', message);
  }

  public static warn(message?: any, ...optionalParams: any[]) {
    console.warn(message, ...optionalParams);
    Logger.sendAPILog('warn', message);
  }

  public static async sendAPILog(level: string, message: string) {
    // Don't log when running locally...
    if (!environment.production) {
      return;
    }

    const browserWindowEnvApiUrl = (window as any)?.__env || environment.apiGatewayURL || {};
    const url = `${browserWindowEnvApiUrl}/logs/web`;

    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          level,
          message
        })
      } as any);
    } catch (error) {
      console.error('Failed to send log detailing this error to API', error);
    }
  }
}

export const logger = Logger;
