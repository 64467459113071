import { AppDashboardService } from './dashboard.service';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';

export const genericDashboardResolver: ResolveFn<{
  noCalendar?: boolean;
}> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AppDashboardService).getGenericDashboardResolverData();
};
