"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportDaysConstraint = exports.ExportDays = void 0;
const class_validator_1 = require("class-validator");
const enums_1 = require("../../enums");
function ExportDays(frequencyProperty, validationOptions) {
    return (object, propertyName) => {
        (0, class_validator_1.registerDecorator)({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [frequencyProperty],
            validator: ExportDaysConstraint
        });
    };
}
exports.ExportDays = ExportDays;
let ExportDaysConstraint = class ExportDaysConstraint {
    validate(value, args) {
        const [frequencyPropertyName] = args.constraints;
        const frequencyValue = args.object[frequencyPropertyName];
        let valid = true;
        if ([enums_1.ExportFrequency.Hourly, enums_1.ExportFrequency.Weekly].includes(frequencyValue)) {
            valid = this.validateDays(value);
        }
        return valid;
    }
    validateDays(value) {
        let isValid = true;
        if (!(0, class_validator_1.isArray)(value))
            return false;
        value.forEach((day) => {
            if (day < 0 || day > 6)
                isValid = false;
        });
        if (value.length == 0)
            isValid = false;
        return isValid;
    }
    defaultMessage(args) {
        const options = args.constraints[1];
        return options?.message || 'Days are invalid';
    }
};
exports.ExportDaysConstraint = ExportDaysConstraint;
exports.ExportDaysConstraint = ExportDaysConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: 'ExportDays' })
], ExportDaysConstraint);
