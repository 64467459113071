"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpImportResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const nested_dto_config_1 = require("../../nested-dto-config");
const shared_1 = require("../shared");
const enums_1 = require("../../enums");
class SftpImportResponseDto extends mappable_base_1.MappableBase {
    constructor(sftpImportResponseOptions) {
        super();
        Object.assign(this, sftpImportResponseOptions);
    }
    getFields() {
        return [
            'id',
            'schoolId',
            'schoolGroupId',
            'sftpAccountId',
            'sftpSettingsId',
            'importSubject',
            'status',
            'recordsFound',
            'recordsImported',
            'recordsRemoved',
            'recordsFailed',
            'recordsUpdated',
            'recordsCreated',
            'errorsReported',
            'createdAt',
            'isLatest'
        ];
    }
    mapFields(source, skipNested, skipTransforms) {
        super.mapFields(source, skipNested, skipTransforms);
        return this;
    }
    getNested() {
        return [
            nested_dto_config_1.NestedDtoConfig.relationConfig('schoolGroup', 'schoolGroup'),
            nested_dto_config_1.NestedDtoConfig.relationConfig('school', 'school')
        ];
    }
    id;
    schoolId;
    school;
    schoolGroupId;
    schoolGroup;
    sftpAccountId;
    sftpSettingsId;
    importSubject;
    status;
    recordsFound;
    recordsImported;
    recordsRemoved;
    recordsUpdated;
    recordsCreated;
    recordsFailed;
    errorsReported;
    createdAt;
    isLatest;
}
exports.SftpImportResponseDto = SftpImportResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", shared_1.RelationDto)
], SftpImportResponseDto.prototype, "school", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", shared_1.RelationDto)
], SftpImportResponseDto.prototype, "schoolGroup", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "sftpAccountId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "sftpSettingsId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", String)
], SftpImportResponseDto.prototype, "importSubject", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", String)
], SftpImportResponseDto.prototype, "status", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "recordsFound", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "recordsImported", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "recordsRemoved", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "recordsUpdated", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "recordsCreated", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], SftpImportResponseDto.prototype, "recordsFailed", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], SftpImportResponseDto.prototype, "errorsReported", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsDate)(),
    __metadata("design:type", Date)
], SftpImportResponseDto.prototype, "createdAt", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    __metadata("design:type", Boolean)
], SftpImportResponseDto.prototype, "isLatest", void 0);
